import { format, formatDistanceToNow, Locale } from 'date-fns';
import en from 'date-fns/locale/en-GB';
import ru from 'date-fns/locale/ru';
import { getCurrentLanguage } from 'infrastructure/i18n';
import i18n, { AvailableLanguages } from 'infrastructure/i18n/i18n';

export class DateService {
  private readonly locales: Record<AvailableLanguages, Locale> = {
    ru: ru,
    en: en,
  };
  private readonly dateFormat = 'dd.MM.yyyy';

  public getLocale(): Locale {
    return this.locales[getCurrentLanguage(i18n)];
  }

  public format(value: unknown): string {
    if (!this.canParseDate(value)) {
      return '';
    }

    return format(this.getDateObject(value), this.dateFormat, {
      locale: this.getLocale(),
    });
  }

  public formatDistanceToNow(value: unknown): string {
    if (!this.canParseDate(value)) {
      return '';
    }

    return formatDistanceToNow(this.getDateObject(value), {
      locale: this.getLocale(),
      includeSeconds: true,
    });
  }

  private getDateObject(value: unknown): Date {
    if (this.canParseDate(value)) {
      return new Date(value);
    }

    throw new Error('Incorrect value type');
  }

  private canParseDate(value: unknown): value is Date {
    return value instanceof Date || typeof value === 'string' || typeof value === 'number';
  }
}

export const dateService = new DateService();
