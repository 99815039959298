import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FolderPartition } from 'features/folders';
import { DEFAULT_PARTITION_TYPE } from './constants';
import { toggleMuseumService } from './services';

export type ToggleMuseumState = {
  partition: FolderPartition;
};

const initialState: ToggleMuseumState = {
  partition: toggleMuseumService.activePartition ?? DEFAULT_PARTITION_TYPE,
};

const toggleMuseumSlice = createSlice({
  name: 'toggleMuseum',
  initialState,
  reducers: {
    changePartition: (state, action: PayloadAction<FolderPartition>) => {
      state.partition = action.payload;
    },
    dispose: (state) => {
      state.partition = DEFAULT_PARTITION_TYPE;
    },
  },
});

export const toggleMuseumActions = toggleMuseumSlice.actions;
export const toggleMuseumReducer = toggleMuseumSlice.reducer;
