import { FunctionComponent } from 'react';
import { FolderPartition, FolderResponseDto } from 'features/folders';
import { ImageResponseDto } from 'features/images';
import { LayoutResponseDto } from 'features/layouts';
import { SearchScreenResultTab, useSearchQueryParams } from 'features/search';
import { useTranslateEnum } from 'infrastructure/i18n';
import { ListApiResponse } from 'infrastructure/network';
import { getSearchResultItem } from 'screens/search/utils/getSearchResultItem';
import { Menu } from 'ui-library/components/Menu';
import { Tab } from 'ui-library/components/Tab';
import styles from './SearchScreenSelectType.module.scss';
import { SearchScreenSelectTypeProps } from './SearchScreenSelectTypeProps';

type Category = {
  tab: SearchScreenResultTab;
  count: number;
  className?: string;
};

export const SearchScreenSelectType: FunctionComponent<SearchScreenSelectTypeProps> = (props) => {
  const { selectedIndex, onSelectIndex, result } = props;
  const { translateValue } = useTranslateEnum('SearchScreenResultTab', SearchScreenResultTab);
  const { partition } = useSearchQueryParams();

  const categories: Array<Category> = [
    {
      tab: SearchScreenResultTab.IMAGES,
      count: getSearchResultItem<ListApiResponse<ImageResponseDto>>(result.data?.items, 'image')?.totalCount ?? 0,
    },
    {
      tab: SearchScreenResultTab.ROOT_FOLDERS,
      count: getSearchResultItem<ListApiResponse<FolderResponseDto>>(result.data?.items, 'topic')?.totalCount ?? 0,
    },
    {
      tab: SearchScreenResultTab.FOLDERS,
      count: getSearchResultItem<ListApiResponse<FolderResponseDto>>(result.data?.items, 'folder')?.totalCount ?? 0,
    },
    {
      tab: SearchScreenResultTab.LAYOUTS,
      count: getSearchResultItem<ListApiResponse<LayoutResponseDto>>(result.data?.items, 'layout')?.totalCount ?? 0,
      className: partition === FolderPartition.MUSEUM ? styles.hidden : undefined,
    },
  ];

  const selectedCategory = categories[selectedIndex];

  return (
    <>
      <Menu className={styles.menu}>
        <Menu.ArrowButton className={styles.menuButton}>
          {translateValue(selectedCategory.tab)} ({selectedCategory.count})
        </Menu.ArrowButton>
        <Menu.Items>
          {categories.map((category, index) => (
            <Menu.Item
              className={category.className}
              key={index}
              onClick={() => onSelectIndex(index)}
              active={selectedIndex === index}
            >
              {translateValue(category.tab)} ({category.count})
            </Menu.Item>
          ))}
        </Menu.Items>
      </Menu>
      <Tab.List className={styles.tabs}>
        {categories.map((category, index) => (
          <Tab className={category.className} key={index} onClick={() => onSelectIndex(index)}>
            <>
              {translateValue(category.tab)} ({category.count})
            </>
          </Tab>
        ))}
      </Tab.List>
    </>
  );
};
