import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getListSortReducers, ListLayout, ListState } from 'features/list';
import { getListPaginationReducers } from 'features/list/listReducers';
import { getDefaultPaginationState } from 'features/pagination';
import { SortDirection } from 'features/sorting';
import { LayoutResponseDto } from '../layoutEntity';

type EntityType = LayoutResponseDto;
export type LayoutListState = ListState<EntityType> & {
  layoutType: ListLayout;
};

const initialState: LayoutListState = {
  layoutType: ListLayout.CARD,
  sortingInfo: {
    sortField: 'number',
    sortDirection: SortDirection.ASC,
  },
  paginationData: getDefaultPaginationState(),
};

const layoutListSlice = createSlice({
  name: 'layoutList',
  initialState,
  reducers: {
    ...getListSortReducers<LayoutListState, EntityType>(),
    ...getListPaginationReducers<LayoutListState>(),
    changeLayoutType: (state, action: PayloadAction<ListLayout>) => {
      state.layoutType = action.payload;
    },
  },
});

export const layoutListActions = layoutListSlice.actions;
export const layoutListReducer = layoutListSlice.reducer;
