import { createSlice } from '@reduxjs/toolkit';
import { getDefaultModalState, getModalReducers, ModalState } from 'features/modal';

export type UserCreateState = ModalState;

const initialState: UserCreateState = getDefaultModalState();

const userCreateSlice = createSlice({
  name: 'userCreate',
  initialState,
  reducers: getModalReducers<UserCreateState>((state) => state),
});

export const userCreateActions = userCreateSlice.actions;
export const userCreateReducer = userCreateSlice.reducer;
