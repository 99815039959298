import { FunctionComponent } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ImageEditFormFields } from 'features/images/imageEntity';
import { FormCol, FormRow } from 'ui-library/components/Form';
import { TextareaField } from 'ui-library/components/TextareaField';
import styles from './ImageEditFormMaterialsTab.module.scss';

const additionalInformationEnField: keyof ImageEditFormFields = 'additionalInformationEn';
const additionalInformationRuField: keyof ImageEditFormFields = 'additionalInformationRu';

export const ImageEditFormMaterialsTab: FunctionComponent = () => {
  const { t } = useTranslation();

  const {
    register,
    formState: { errors },
  } = useFormContext<ImageEditFormFields>();

  return (
    <div className={styles.root}>
      <FormRow>
        <FormCol>
          <TextareaField
            error={errors[additionalInformationRuField]?.message}
            label={t('imageEditForm.additionalInformationRu.label')}
            TextareaProps={{
              ...register(additionalInformationRuField),
              placeholder: t('imageEditForm.common.textPlaceholder'),
            }}
          />
        </FormCol>
      </FormRow>
      <FormRow>
        <FormCol>
          <TextareaField
            error={errors[additionalInformationEnField]?.message}
            label={t('imageEditForm.additionalInformationEn.label')}
            TextareaProps={{
              ...register(additionalInformationEnField),
              placeholder: t('imageEditForm.common.textPlaceholder'),
            }}
          />
        </FormCol>
      </FormRow>
    </div>
  );
};
