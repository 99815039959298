import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Routes } from 'react-router';
import { Route } from 'use-react-router-breadcrumbs';
import { getLinkBreadcrumb } from 'features/breadcrumbs';
import {
  favoritesAccessRuleService,
  fileAccessRuleService,
  folderAccessRuleService,
  imageAccessRuleService,
  layoutAccessRuleService,
  usersAccessRuleService,
} from 'features/role';
import { SignInScreen } from 'screens/auth';
import { NotFoundScreen } from 'screens/common';
import { DownloadDetailScreen, DownloadsScreen } from 'screens/downloads';
import { FavoriteGroupDetailScreen, FavoriteGroupsScreen, FavoritesScreen } from 'screens/favorites';
import { FolderDetailBreadcrumb, FolderDetailScreen } from 'screens/folderDetail';
import { GalleryScreen } from 'screens/gallery';
import { HomeScreen } from 'screens/home';
import { ImageDetailScreen } from 'screens/imageDetail';
import { LayoutDetailScreen } from 'screens/layoutDetail';
import { AuthorScreen } from 'screens/referenceBook/author';
import { SearchScreen } from 'screens/search';
import { UsersScreen } from 'screens/users';
import { ROUTE_PATHS } from './paths';
import styles from './useRouteConfig.module.scss';
import { AnonymousRouteWrapper } from './wrappers/anonymousRoute/anonymousRouteWrapper';
import { AnonymousRouteWrapperProps } from './wrappers/anonymousRoute/anonymousRouteWrapperProps';
import { ProtectedRouteWrapper } from './wrappers/protectedRoute/protectedRouteWrapper';
import { ProtectedRouteWrapperProps } from './wrappers/protectedRoute/protectedRouteWrapperProps';
import { PublicRouteWrapper } from './wrappers/publicRoute/publicRouteWrapper';
import { PublicRouteWrapperProps } from './wrappers/publicRoute/publicRouteWrapperProps';

export const useRouteConfig = () => {
  const { t } = useTranslation();
  /*
    Роуты, которые доступны только неавторизованному пользователю
   */
  const anonymousRoutes = useMemo<Array<AnonymousRouteWrapperProps>>(() => {
    return [
      {
        path: ROUTE_PATHS.AUTH.SIGN_IN,
        screen: <SignInScreen />,
      },
    ];
  }, []);

  /*
  Роуты, которые доступны любым пользователям
 */
  const publicRoutes = useMemo<Array<PublicRouteWrapperProps>>(() => {
    return [
      {
        path: '*',
        screen: <NotFoundScreen />,
      },
      {
        path: ROUTE_PATHS.DOWNLOADS.DETAIL,
        screen: <DownloadDetailScreen />,
      },
    ];
  }, []);

  /*
  Роуты, которые доступны только авторизованным пользователям
 */
  const protectedRoutes = useMemo<Array<ProtectedRouteWrapperProps>>(() => {
    return [
      {
        path: ROUTE_PATHS.HOME,
        screen: <HomeScreen />,
        hasAccess: () => true,
      },
      {
        path: ROUTE_PATHS.GALLERY,
        screen: <GalleryScreen />,
        hasAccess: () => true,
        breadcrumb: getLinkBreadcrumb(t('breadcrumb.gallery')),
        className: styles.layout,
      },
      {
        path: ROUTE_PATHS.AUTHORS,
        screen: <AuthorScreen />,
        hasAccess: () => usersAccessRuleService.canEditReferences(),
        className: styles.layout,
      },
      {
        path: ROUTE_PATHS.FOLDER_DETAIL,
        screen: <FolderDetailScreen />,
        breadcrumb: FolderDetailBreadcrumb,
        hasAccess: () => folderAccessRuleService.canView(),
        className: styles.layout,
      },
      {
        path: ROUTE_PATHS.LAYOUT_DETAIL,
        screen: <LayoutDetailScreen />,
        hasAccess: () => layoutAccessRuleService.canView(),
        Layout: null,
      },
      {
        path: ROUTE_PATHS.IMAGE_DETAIL,
        screen: <ImageDetailScreen />,
        hasAccess: () => imageAccessRuleService.canView(),
        Layout: null,
      },
      {
        path: ROUTE_PATHS.SEARCH,
        screen: <SearchScreen />,
        hasAccess: () => true,
        Layout: null,
        className: styles.layout,
      },
      {
        path: ROUTE_PATHS.FAVORITES.ROOT,
        screen: <FavoritesScreen />,
        hasAccess: () => favoritesAccessRuleService.canView(),
        breadcrumb: getLinkBreadcrumb(t('breadcrumb.favorites')),
        className: styles.layout,
      },
      {
        path: ROUTE_PATHS.FAVORITES.GROUPS,
        screen: <FavoriteGroupsScreen />,
        hasAccess: () => favoritesAccessRuleService.canView(),
        breadcrumb: getLinkBreadcrumb(t('favorites.group.title')),
        className: styles.layout,
      },
      {
        path: ROUTE_PATHS.FAVORITES.GROUP_DETAIL,
        screen: <FavoriteGroupDetailScreen />,
        hasAccess: () => favoritesAccessRuleService.canView(),
        className: styles.layout,
      },
      {
        path: ROUTE_PATHS.USERS,
        screen: <UsersScreen />,
        hasAccess: () => usersAccessRuleService.canView(),
        className: styles.layout,
      },
      {
        path: ROUTE_PATHS.DOWNLOADS.ROOT,
        screen: <DownloadsScreen />,
        hasAccess: () => fileAccessRuleService.canView(),
        className: styles.layout,
      },
    ];
  }, [t]);

  const buildRoutes = useCallback(() => {
    return (
      <Routes>
        {publicRoutes.map((route) => (
          <Route key={route.path} path={route.path} element={<PublicRouteWrapper {...route} />} />
        ))}
        {anonymousRoutes.map((route) => (
          <Route key={route.path} path={route.path} element={<AnonymousRouteWrapper {...route} />} />
        ))}
        {protectedRoutes.map((route) => (
          <Route key={route.path} path={route.path} element={<ProtectedRouteWrapper {...route} />} />
        ))}
      </Routes>
    );
  }, [anonymousRoutes, protectedRoutes, publicRoutes]);

  return { publicRoutes, protectedRoutes, anonymousRoutes, buildRoutes };
};
