import { ReactNode } from 'react';
import { ListItemProps } from '../List/ListItemProps';

export enum ActionListItemVariant {
  DEFAULT,
  DANGER,
}

export type ActionListItem = {
  icon?: ReactNode;
  label: string;
  hasAccess?: () => boolean;
  onClick?: ListItemProps['onClick'];
  kind?: ListItemProps['kind'];
  variant?: ActionListItemVariant;
};

export type ActionListProps = {
  className?: string;
  actions: Array<ActionListItem>;
  onClose: () => void;
};
