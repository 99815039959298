import { FunctionComponent } from 'react';
import { CardActionsMenu } from 'ui-library/components/CardActionsMenu';
import { useFavoriteGroupActions } from '../../hooks/useFavoriteGroupActions';
import { FavoriteGroupCardActionsProps } from './FavoriteGroupCardActionsProps';

export const FavoriteGroupCardActions: FunctionComponent<FavoriteGroupCardActionsProps> = (props) => {
  const { group, className } = props;
  const { actions } = useFavoriteGroupActions(group);

  return <CardActionsMenu className={className} actions={actions} portal={true} />;
};
