import cn from 'classnames';
import React, { FunctionComponent } from 'react';
import { ListLayout } from 'features/list';
import { useTranslateEnum } from 'infrastructure/i18n';
import { ButtonKind } from 'ui-library/components/Button';
import { Icon } from 'ui-library/components/Icon';
import { IconButton, IconButtonVariant } from 'ui-library/components/IconButton';
import styles from './ListLayoutButtonGroup.module.scss';
import { ListLayoutButtonGroupProps } from './ListLayoutButtonGroupProps';

export const ListLayoutButtonGroup: FunctionComponent<ListLayoutButtonGroupProps> = (props) => {
  const { onLayoutChanged, active } = props;
  const { translateValue } = useTranslateEnum('ListLayout', ListLayout);

  const handleCardViewTypeClick = () => {
    onLayoutChanged(ListLayout.CARD);
  };

  const handleTableViewTypeClick = () => {
    onLayoutChanged(ListLayout.TABLE);
  };

  return (
    <div className={styles.root}>
      <IconButton
        as={ButtonKind.BUTTON}
        variant={IconButtonVariant.TRANSPARENT}
        size='small'
        onClick={handleCardViewTypeClick}
        title={translateValue(ListLayout.CARD)}
        className={cn({
          [styles.active]: active === ListLayout.CARD,
        })}
      >
        <Icon name='grid' />
      </IconButton>
      <IconButton
        as={ButtonKind.BUTTON}
        variant={IconButtonVariant.TRANSPARENT}
        size='small'
        onClick={handleTableViewTypeClick}
        title={translateValue(ListLayout.TABLE)}
        className={cn({
          [styles.active]: active === ListLayout.TABLE,
        })}
      >
        <Icon name='menu' />
      </IconButton>
    </div>
  );
};
