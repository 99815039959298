import { ChangeEvent, FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useModalState } from 'features/modal';
import { Button, ButtonKind, ButtonVariant } from 'ui-library/components/Button';
import { CheckboxField } from 'ui-library/components/CheckboxField';
import { FileDownloadAgreementViewInfo } from '../FileDownloadAgreementViewInfo/FileDownloadAgreementViewInfo';
import styles from './FileDownloadDetailAgreementActions.module.scss';
import { FileDownloadDetailAgreementActionsProps } from './FileDownloadDetailAgreementActionsProps';

export const FileDownloadDetailAgreementActions: FunctionComponent<FileDownloadDetailAgreementActionsProps> = (
  props,
) => {
  const { onDone } = props;
  const [isAgree, setAgree] = useState(false);
  const { open, isOpen, close } = useModalState();
  const { t } = useTranslation();

  const handleClick = () => {
    onDone();
  };

  const handleLinkClick = () => {
    open();
  };

  const getLabel = () => {
    return (
      <>
        {t('files.downloadDetail.agreeWithTheConditions')}
        &nbsp;
        <Button
          as={ButtonKind.BUTTON}
          variant={ButtonVariant.TRANSPARENT}
          className={styles.link}
          onClick={handleLinkClick}
        >
          {t('files.downloadDetail.downloadAgreement')}
        </Button>
      </>
    );
  };

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAgree(event.target.checked);
  };

  return (
    <div className={styles.root}>
      <CheckboxField label={getLabel()} CheckboxProps={{ onChange }} />
      <Button className={styles.button} disabled={!isAgree} onClick={handleClick} as={ButtonKind.BUTTON}>
        {t('actions.download')}
      </Button>
      <FileDownloadAgreementViewInfo isOpen={isOpen} close={close} />
    </div>
  );
};
