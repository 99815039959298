import cn from 'classnames';
import { forwardRef } from 'react';
import styles from './InputLayout.module.scss';
import { InputLayoutProps, InputLayoutVariant } from './InputLayoutProps';

export const InputLayout = forwardRef<HTMLDivElement, InputLayoutProps>((props, ref) => {
  const {
    className,
    error,
    startAdornment,
    startAdornmentProps = {},
    endAdornment,
    endAdornmentProps = {},
    variant = InputLayoutVariant.FILLED,
    renderControl,
    disabled,
    ControlProps: controlProps,
    InputProps: inputProps = {},
    ...restProps
  } = props;

  const { className: inputClassName, ...restInputProps } = inputProps;
  const { className: startAdornmentClassName, ...restStartAdornmentProps } = startAdornmentProps;
  const { className: endAdornmentClassName, ...restEndAdornmentProps } = endAdornmentProps;

  const control = renderControl({ ...controlProps, disabled });

  return (
    <div ref={ref} className={cn(className, styles.root)} {...restProps}>
      {startAdornment && (
        <div
          className={cn(styles.adornment, styles.startAdornment, startAdornmentClassName)}
          {...restStartAdornmentProps}
        >
          {startAdornment}
        </div>
      )}
      <div
        className={cn(inputClassName, styles.input, styles[variant], {
          [styles.error]: !!error,
          [styles.disabled]: disabled,
          [styles.adornedEnd]: !!endAdornment,
          [styles.adornedStart]: !!startAdornment,
        })}
        {...restInputProps}
      >
        {control}
      </div>
      {endAdornment && (
        <div className={cn(styles.adornment, styles.endAdornment, endAdornmentClassName)} {...restEndAdornmentProps}>
          {endAdornment}
        </div>
      )}
    </div>
  );
});
