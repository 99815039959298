import { FileBox, FileResponseDto } from 'features/file';
import { FolderResponseDto } from 'features/folders';
import { LayoutResponseDto } from 'features/layouts';
import { ReferenceBook } from 'features/referenceBook';
import { TagResponseDto } from 'features/tags';
import { AvailableLanguages } from 'infrastructure/i18n/i18n';
import { ListApiFetchArgs, ListApiRequestPaginationParams, PaginationApiResponse } from 'infrastructure/network';

export type ImageResponseDto = {
  id: string;
  foldersCount: number;
  archiveName?: string;
  captionRu: string;
  captionEn: string;
  centuryHalf: ImageCenturyHalf;
  centuryQuarter: number;
  dateDetailed: string;
  dimensionsRu: string;
  dimensionsEn: string;
  additionalInformationRu: string;
  additionalInformationEn: string;
  additionalInformationOriginal: string;
  relatedMaterial: string;
  status: ImageStatus;
  author?: ReferenceBook;
  century: ReferenceBook;
  year?: ReferenceBook;
  artForm?: ReferenceBook;
  materialsTechnics?: ReferenceBook;
  file?: FileResponseDto;
  isArchived: boolean;
  isFavourite: boolean;
  tags: Array<TagResponseDto>;
  order: number;
};

export enum ImageStatus {
  DRAFT = 'draft',
  DONE = 'done',
}

export type FetchImageFoldersQueryData = ListApiFetchArgs<FolderResponseDto> & {
  imageID: string;
};

export type FetchFolderImagesQueryData = ListApiFetchArgs<ImageResponseDto> & {
  folderID: string;
};

export enum ImageCenturyHalf {
  ONE = 1,
  // eslint-disable-next-line no-magic-numbers
  TWO = 2,
}

export type ImageEditFormFields = {
  fileBox: FileBox;
  archiveName?: ImageResponseDto['archiveName'];
  captionRu: ImageResponseDto['captionRu'];
  captionEn: ImageResponseDto['captionEn'];
  centuryHalf?: ImageResponseDto['centuryHalf'];
  dimensionsRu?: ImageResponseDto['dimensionsRu'];
  dimensionsEn?: ImageResponseDto['dimensionsEn'];
  additionalInformationRu?: ImageResponseDto['additionalInformationRu'];
  additionalInformationEn?: ImageResponseDto['additionalInformationEn'];
  additionalInformationOriginal?: ImageResponseDto['additionalInformationOriginal'];
  relatedMaterial?: ImageResponseDto['relatedMaterial'];
  author?: ReferenceBook;
  century?: ReferenceBook;
  year?: ReferenceBook;
  artForm?: ReferenceBook;
  materialsTechnics?: ReferenceBook;
  status: ImageStatus;
  tags?: ImageResponseDto['tags'];
  layouts: Array<LayoutResponseDto>;
  folders: Array<FolderResponseDto>;
};

export type FetchLayoutImagesQueryData = ListApiFetchArgs<ImageResponseDto> & {
  layoutID: string;
};

export type FetchGalleryQueryData = ListApiRequestPaginationParams & {
  folderID: string;
  language: AvailableLanguages;
  maxFolding?: number;
};

export type GalleryResponseDto = PaginationApiResponse & {
  content: GalleryResponseItemDto;
};

export type GalleryResponseItemDto = GalleryFolderItemDto & {
  children?: Array<GalleryResponseItemDto>;
};

export type GalleryFolderItemDto = {
  id: FolderResponseDto['id'];
  captionRu: FolderResponseDto['captionRu'];
  captionEn: FolderResponseDto['captionEn'];
  parentID: FolderResponseDto['parentID'];
  level: number;
  isRoot: FolderResponseDto['isRoot'];
  images?: Array<GalleryImageItemDto>;
};

export type GalleryImageItemDto = {
  id: ImageResponseDto['id'];
  file?: ImageResponseDto['file'];
  isFavourite: ImageResponseDto['isFavourite'];
  foldersCount: ImageResponseDto['foldersCount'];
  captionRu: ImageResponseDto['captionRu'];
  captionEn: ImageResponseDto['captionEn'];
  author?: ImageResponseDto['author'];
  order: ImageResponseDto['order'];
};

export type ImageGalleryFlatListItem = {
  id: string;
  captionRu: string;
  captionEn: string;
  images: Array<GalleryImageItemDto>;
  level: number;
  isRoot: boolean;
  parent?: ImageGalleryFlatListItem;
};

export type ImageEditRequestDto = {
  fileID?: string;
  fileClear?: boolean;
  imageID: string;
  folderIDsToAdd?: Array<string>;
  folderIDsToRemove?: Array<string>;
  layoutIDsToAdd?: Array<string>;
  layoutIDsToRemove?: Array<string>;
  filename?: string;
  captionRu?: ImageResponseDto['captionRu'];
  captionEn?: ImageResponseDto['captionEn'];
  centuryHalf?: ImageResponseDto['centuryHalf'];
  dimensionsRu?: ImageResponseDto['dimensionsRu'];
  dimensionsEn?: ImageResponseDto['dimensionsEn'];
  additionalInformationRu?: ImageResponseDto['additionalInformationRu'];
  additionalInformationEn?: ImageResponseDto['additionalInformationEn'];
  additionalInformationOriginal?: ImageResponseDto['additionalInformationOriginal'];
  relatedMaterial?: ImageResponseDto['relatedMaterial'];
  authorID?: string;
  centuryID?: string;
  yearID?: string;
  artFormID?: string;
  materialsTechnicsID?: string;
  status?: ImageStatus;
  tags?: Array<string>;
};

export type ImageCreateRequestDto = Omit<ImageEditRequestDto, 'imageID'>;

export type ImagesMetadataResponseDto = {
  taskID: string;
};

export type ImagesDeleteRequestDto = {
  imageID: string;
};

export type ImagesMetadataRequestDto = {
  folderIDs: Array<string>;
  foldersWithSubfolders: boolean;
  language: AvailableLanguages;
};

export type ImageFileUploadRequestDto = {
  file: File;
};

export type ImageFileResponseDto = {
  taskId: string;
};
