import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { imageCreateActions } from 'features/createArtifact';
import { FileType, useUploadFile } from 'features/file';
import { FolderResponseDto, foldersApi } from 'features/folders';
import { ImageEditForm } from 'features/images';
import { ImageEditTab } from 'features/images/components/ImageEditForm/ImageEditTab';
import { useCreateImage } from 'features/images/hooks';
import { ImageEditFormFields, ImageStatus } from 'features/images/imageEntity';
import { imagesApi, useCreateImageMutation } from 'features/images/imagesApi';
import { LayoutResponseDto, layoutsApi } from 'features/layouts';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux';
import { Modal } from 'ui-library/components/Modal';

export const ImageCreate: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const { isOpen, parentFolderId, selectedTab } = useAppSelector((state) => state.imageCreate);
  const uploadFile = useUploadFile(FileType.IMAGE);
  const [createImageMutation] = useCreateImageMutation();

  const { t } = useTranslation();
  const { handleCreateImage, handleCreateImageError } = useCreateImage();

  const handleClose = () => {
    dispatch(imageCreateActions.close());
    dispatch(imageCreateActions.selectTab(ImageEditTab.INFORMATION));
  };

  const getFolderIDsToAdd = (values: Array<FolderResponseDto>) => {
    return values.map((x) => x.id).concat([parentFolderId ? parentFolderId : '']);
  };

  const getLayoutIDsToAdd = (values: Array<LayoutResponseDto>) => {
    return values.map((x) => x.id);
  };

  const onSubmit = async (values: ImageEditFormFields) => {
    const { fileBox } = values;

    let fileID;
    if (fileBox.file) {
      fileID = await uploadFile(fileBox.file);
    }

    try {
      await createImageMutation({
        fileID,
        captionRu: values.captionRu,
        captionEn: values.captionEn,
        centuryHalf: values.centuryHalf || undefined,
        dimensionsRu: values.dimensionsRu,
        dimensionsEn: values.dimensionsEn,
        additionalInformationRu: values.additionalInformationRu,
        additionalInformationEn: values.additionalInformationEn,
        additionalInformationOriginal: values.additionalInformationOriginal,
        relatedMaterial: values.relatedMaterial,
        authorID: values.author?.id,
        centuryID: values.century?.id,
        yearID: values.year?.id,
        artFormID: values.artForm?.id,
        materialsTechnicsID: values.materialsTechnics?.id,
        status: values.status,
        tags: values.tags?.map((tag) => tag.id),
        folderIDsToAdd: getFolderIDsToAdd(values.folders),
        layoutIDsToAdd: getLayoutIDsToAdd(values.layouts),
      }).unwrap();

      dispatch(foldersApi.util.invalidateTags(['ImageFolders']));
      dispatch(layoutsApi.util.invalidateTags(['ImageLayouts']));
      dispatch(imagesApi.util.invalidateTags(['ImageGallery']));
      handleCreateImage();
    } catch (e) {
      handleCreateImageError();
    }

    handleClose();
  };

  const defaultValues: ImageEditFormFields = {
    fileBox: {},
    captionRu: '',
    captionEn: '',
    status: ImageStatus.DRAFT,
    layouts: [],
    folders: [],
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Modal.Title>{t('createArtifacts.createImage')}</Modal.Title>
      <ImageEditForm
        isCreate
        defaultValues={defaultValues}
        selectedTab={selectedTab}
        onTabChange={(tab) => dispatch(imageCreateActions.selectTab(tab))}
        onSubmit={onSubmit}
        onClose={handleClose}
      />
    </Modal>
  );
};
