import * as yup from 'yup';
import { PasswordValidations } from '../types';

const minLen = 8;

const hasLowercaseLetter = (value: string) => /^(?=.*[a-z])/.test(value);
const hasUppercaseLetter = (value: string) => /^(?=.*[A-Z])/.test(value);
const hasOneDigit = (value: string) => /^(?=.*[0-9])/.test(value);

export const getStrongPasswordValidation = () => {
  return yup
    .string()
    .required()
    .min(minLen)
    .test({
      name: 'strongPasswordTest',
      test: (value, testContext) => {
        const { path, createError } = testContext;

        if (!value) {
          return false;
        }

        switch (Boolean(value)) {
          case !hasLowercaseLetter(value):
            return createError({
              path,
              message: 'validation.password.lowercaseLetter',
            });
          case !hasUppercaseLetter(value):
            return createError({
              path,
              message: 'validation.password.uppercaseLetter',
            });
          case !hasOneDigit(value):
            return createError({
              path,
              message: 'validation.password.oneDigit',
            });
          default:
            return true;
        }
      },
    });
};

export const getPassedValidations = (value: string | undefined): Array<PasswordValidations> => {
  if (!value) {
    return [];
  }

  const res: Array<PasswordValidations> = [];

  if (value.length >= minLen) {
    res.push(PasswordValidations.MIN_LEN);
  }

  if (hasLowercaseLetter(value)) {
    res.push(PasswordValidations.LOWERCASE_LETTER);
  }

  if (hasUppercaseLetter(value)) {
    res.push(PasswordValidations.UPPERCASE_LETTER);
  }

  if (hasOneDigit(value)) {
    res.push(PasswordValidations.ONE_DIGIT);
  }

  return res;
};
