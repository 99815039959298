import { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { NotDefinedParamException } from 'infrastructure/exceptions';
import { useAppDispatch } from 'infrastructure/redux';
import { ActionListItem } from 'ui-library/components/ActionList';
import { userBlockActions, userEditActions, userUnblockActions } from '../slices';
import { UserResponseDto } from '../userEntity';

export const useUserActions = (user: UserResponseDto | undefined) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleEdit = (event: SyntheticEvent) => {
    event.stopPropagation();
    event.preventDefault();

    if (!user) {
      throw new NotDefinedParamException('user');
    }

    dispatch(userEditActions.setUser(user));
    dispatch(userEditActions.open());
  };

  const handleBlock = (event: SyntheticEvent) => {
    event.stopPropagation();
    event.preventDefault();

    if (!user) {
      throw new NotDefinedParamException('user');
    }

    dispatch(userBlockActions.setUser(user));
    dispatch(userBlockActions.open());
  };

  const handleUnblock = (event: SyntheticEvent) => {
    event.stopPropagation();
    event.preventDefault();

    if (!user) {
      throw new NotDefinedParamException('user');
    }

    dispatch(userUnblockActions.setUser(user));
    dispatch(userUnblockActions.open());
  };

  const actions: Array<ActionListItem> = [
    {
      label: t('actions.edit'),
      onClick: handleEdit,
    },
    {
      label: user?.isBlocked ? t('actions.unblock') : t('actions.block'),
      onClick: user?.isBlocked ? handleUnblock : handleBlock,
    },
  ];

  return { actions };
};
