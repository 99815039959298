import cn from 'classnames';
import React, { FunctionComponent } from 'react';
import { FilePreviewLabel, ImageFullViewer } from 'features/file';
import { useModalState } from 'features/modal';
import { ButtonKind } from 'ui-library/components/Button';
import { Icon } from 'ui-library/components/Icon';
import { IconButton, IconButtonVariant } from 'ui-library/components/IconButton';
import styles from './ImageDetailImageButtonGroup.module.scss';
import { ImageDetailImageButtonGroupProps } from './ImageDetailImageButtonGroupProps';

export const ImageDetailImageButtonGroup: FunctionComponent<ImageDetailImageButtonGroupProps> = (props) => {
  const { open, ...restModalProps } = useModalState();
  const { className, image } = props;

  const fullScreenPreview = image?.file?.previews?.find((x) => x.label === FilePreviewLabel.IMAGE_FULLSCREEN);

  return (
    <div className={cn(styles.root, className)}>
      <IconButton className={styles.button} as={ButtonKind.BUTTON} variant={IconButtonVariant.SECONDARY} onClick={open}>
        <Icon name='expand' />
      </IconButton>
      <ImageFullViewer src={fullScreenPreview?.url} {...restModalProps} />
    </div>
  );
};
