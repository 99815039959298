import { FunctionComponent } from 'react';
import { TableRowActions } from 'ui-library/components/Table';
import { useFolderActions } from '../../../hooks/useFolderActions';
import { FolderTableActionsProps } from './FolderTableActionsProps';

export const FolderTableActions: FunctionComponent<FolderTableActionsProps> = (props) => {
  const { folder, groupId, className } = props;
  const { actions } = useFolderActions({
    folder: folder,
    groupId: groupId,
  });

  return <TableRowActions actions={actions} className={className} />;
};
