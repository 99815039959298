import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ErrorBoundaryState = {
  httpStatusCode: number | undefined;
};

const initialState: ErrorBoundaryState = {
  httpStatusCode: undefined,
};

export const errorBoundarySlice = createSlice({
  name: 'errorBoundary',
  initialState,
  reducers: {
    setHttpStatusCode(state, action: PayloadAction<number | undefined>) {
      state.httpStatusCode = action.payload;
    },
  },
});

export const errorBoundaryActions = errorBoundarySlice.actions;
export const errorBoundaryReducer = errorBoundarySlice.reducer;
