import { useTranslation } from 'react-i18next';
import { ReferenceBookType } from 'features/referenceBook';
import { SearchFilterFormFields } from 'features/search/types';
import { ReferenceBookFormController } from 'infrastructure/form';
import { FormCol } from 'ui-library/components/Form';
import styles from './ImageFilter.module.scss';

const authorField: keyof SearchFilterFormFields = 'author';
const yearField: keyof SearchFilterFormFields = 'year';
const materialsTechnicsField: keyof SearchFilterFormFields = 'materialsTechnics';
const artFormField: keyof SearchFilterFormFields = 'artForm';

export const ImageFilter = () => {
  const { t } = useTranslation();

  return (
    <>
      <FormCol className={styles.formCol}>
        <ReferenceBookFormController
          name={authorField}
          type={ReferenceBookType.AUTHORS}
          AutocompleteFieldProps={{
            label: t('search.filter.labels.author'),
            placeholder: t('search.filter.placeholder'),
            hideHelperText: true,
            AutocompleteProps: {
              InputProps: {
                className: styles.control,
              },
            },
          }}
        />
      </FormCol>
      <FormCol className={styles.formCol}>
        <ReferenceBookFormController
          name={yearField}
          type={ReferenceBookType.YEARS}
          AutocompleteFieldProps={{
            label: t('search.filter.labels.year'),
            placeholder: t('search.filter.placeholder'),
            hideHelperText: true,
            AutocompleteProps: {
              InputProps: {
                className: styles.control,
              },
            },
          }}
        />
      </FormCol>

      <FormCol className={styles.formCol}>
        <ReferenceBookFormController
          name={materialsTechnicsField}
          type={ReferenceBookType.MATERIAL_TECHNIQUE}
          AutocompleteFieldProps={{
            label: t('search.filter.labels.materialsTechnics'),
            placeholder: t('search.filter.placeholder'),
            hideHelperText: true,
            AutocompleteProps: {
              InputProps: {
                className: styles.control,
              },
            },
          }}
        />
      </FormCol>

      <FormCol className={styles.formCol}>
        <ReferenceBookFormController
          name={artFormField}
          type={ReferenceBookType.KINDS_ART}
          AutocompleteFieldProps={{
            label: t('search.filter.labels.artForm'),
            placeholder: t('search.filter.placeholder'),
            hideHelperText: true,
            AutocompleteProps: {
              InputProps: {
                className: styles.control,
              },
            },
          }}
        />
      </FormCol>
    </>
  );
};
