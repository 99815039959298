import { FunctionComponent } from 'react';
import { Table, TableBody, TableHead } from 'ui-library/components/Table';
import { AuthorsEmptyList } from '../AuthorsEmptyList/AuthorsEmptyList';
import { AuthorsTableActions } from '../AuthorsTableActions/AuthorsTableActions';
import { AuthorsTableAddRow } from '../AuthorsTableAddRow/AuthorsTableAddRow';
import { AuthorsTableSkeleton } from '../AuthorsTableSkeleton/AuthorsTableSkeleton';
import { useAuthorTableColumns } from '../hooks/useAuthorTableColumns/useAuthorTableColumns';
import { AuthorsTableProps } from './AuthorsTableProps';

export const AuthorsTable: FunctionComponent<AuthorsTableProps> = (props) => {
  const { isError, data, onCreate, isFetching } = props;
  const columns = useAuthorTableColumns();

  const isEmpty = !isError && data?.totalCount === 0;

  if (isEmpty) {
    return <AuthorsEmptyList onCreate={onCreate} />;
  }

  return (
    <Table>
      <TableHead columns={columns} />
      {isFetching ? (
        <AuthorsTableSkeleton />
      ) : (
        <>
          <AuthorsTableAddRow onClick={onCreate} />

          <TableBody
            columns={columns}
            items={data?.content ?? []}
            getRowActions={(x, options) => <AuthorsTableActions className={options?.className} author={x} />}
          />
        </>
      )}
    </Table>
  );
};
