export type ReferenceBookEditFormFields = {
  captionRu: string;
  captionEn: string;
};

export enum CenturyPeriod {
  POSITIVE = 'positive',
  NEGATIVE = 'negative',
}

export type ReferenceBookEditFormCenturyFormFields = {
  century: string;
  period: CenturyPeriod;
};
