import { useEffect, useState } from 'react';
import { GalleryResponseDto, GalleryResponseItemDto, ImageGalleryFlatListItem } from 'features/images/imageEntity';

const getLinearizedTree = (
  nodes: Array<GalleryResponseItemDto>,
  list: Array<ImageGalleryFlatListItem>,
  parent?: ImageGalleryFlatListItem,
) => {
  for (const node of nodes) {
    const images = node.images ?? [];
    const currentFolder: ImageGalleryFlatListItem = {
      captionRu: node.captionRu,
      captionEn: node.captionEn,
      id: node.id,
      parent,
      level: node.level,
      isRoot: node.isRoot,
      images,
    };

    // добавляем только непустые подтемы
    if (images.length > 0) {
      list.push(currentFolder);
    }

    const children = node.children ?? [];

    getLinearizedTree(children, list, currentFolder);
  }
};
export const useImageGalleryFlatListItems = (
  data: GalleryResponseDto | undefined,
): Array<ImageGalleryFlatListItem> | undefined => {
  const [items, setItems] = useState<Array<ImageGalleryFlatListItem> | undefined>(undefined);

  useEffect(() => {
    if (!data) {
      setItems(undefined);
      return;
    }

    const items: Array<ImageGalleryFlatListItem> = [];

    getLinearizedTree([data.content], items);
    setItems(items);
  }, [data]);

  return items;
};
