import { i18n as i18nInstance } from 'i18next';
import { Leaves } from 'types/utils';
import { AvailableLanguages, TranslateKeys } from '../i18n';

export function matchLanguageProp<T>(currentLang: AvailableLanguages, prop: T, propEng: T): T {
  return currentLang === 'ru' ? prop : propEng || prop;
}

export const invertLanguage = (lng: AvailableLanguages) => {
  return lng === 'ru' ? 'en' : 'ru';
};

export const translateKey = <T extends Leaves<TranslateKeys['translation']>>(name: T) => {
  return name;
};

export const getCurrentLanguage = (instance: i18nInstance): AvailableLanguages => {
  return instance.resolvedLanguage as AvailableLanguages;
};
