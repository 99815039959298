import { skipToken } from '@reduxjs/toolkit/dist/query';
import { FunctionComponent, useEffect } from 'react';
import { createSearchParams, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { FavoriteRouteSearchQueryParams } from 'features/favorites/types';
import { hasNestedInfo } from 'features/folders';
import { FIRST_PAGE } from 'features/pagination';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux';
import { Tab } from 'ui-library/components/Tab';
import { nameof } from 'utils';
import { useFetchGroupQuery } from '../../favoritesApi';
import {
  favoriteContentActions,
  favoriteFolderListActions,
  favoriteImageListActions,
  favoriteLayoutListActions,
  favoriteRootFolderListActions,
} from '../../slices';
import { FavoriteContentDataView } from '../FavoriteContentDataView/FavoriteContentDataView';
import { FavoriteContentHeader } from '../FavoriteContentHeader/FavoriteContentHeader';
import styles from './FavoriteContent.module.scss';
import { FavoriteContentProps } from './FavoriteContentProps';
import { FavoriteContentTab } from './FavoriteContentTab';

export const FavoriteContent: FunctionComponent<FavoriteContentProps> = (props) => {
  const { groupId } = props;
  const dispatch = useAppDispatch();
  const currentGroupId = useAppSelector((state) => state.favoriteContent.currentGroupId);
  const { data } = useFetchGroupQuery(groupId ?? skipToken);
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get(nameof<FavoriteRouteSearchQueryParams>('tab'));

  const doNavigateToTab = (params: FavoriteRouteSearchQueryParams) => {
    navigate(
      {
        pathname: location.pathname,
        search: `?${createSearchParams(params)}`,
      },
      { replace: true },
    );
  };

  const handleTabChange = (tab: FavoriteContentTab) => {
    const params: FavoriteRouteSearchQueryParams = {
      tab: String(tab) ?? '',
    };
    navigate(
      {
        pathname: location.pathname,
        search: `?${createSearchParams(params)}`,
      },
      { replace: false },
    );
  };

  useEffect(() => {
    const isSameDirectory = currentGroupId === groupId;
    if (isSameDirectory) {
      return;
    }

    dispatch(favoriteRootFolderListActions.pageChanged({ page: FIRST_PAGE }));
    dispatch(favoriteFolderListActions.pageChanged({ page: FIRST_PAGE }));
    dispatch(favoriteLayoutListActions.pageChanged({ page: FIRST_PAGE }));
    dispatch(favoriteImageListActions.pageChanged({ page: FIRST_PAGE }));
    dispatch(favoriteContentActions.setCurrentGroupId(groupId));
  }, [dispatch, groupId, currentGroupId]);

  useEffect(() => {
    const setInitialTab = () => {
      if (groupId === undefined) {
        const params: FavoriteRouteSearchQueryParams = {
          tab: tab === null ? String(FavoriteContentTab.ROOT_FOLDERS) : String(tab),
        };
        doNavigateToTab(params);
        return;
      }

      if (tab === null) {
        let params: FavoriteRouteSearchQueryParams | undefined;

        if (hasNestedInfo(data?.topicCount)) {
          params = {
            tab: String(FavoriteContentTab.ROOT_FOLDERS),
          };
        } else if (hasNestedInfo(data?.folderCount)) {
          params = {
            tab: String(FavoriteContentTab.FOLDERS),
          };
        } else if (hasNestedInfo(data?.layoutCount)) {
          params = {
            tab: String(FavoriteContentTab.LAYOUTS),
          };
        } else if (hasNestedInfo(data?.imageCount)) {
          params = {
            tab: String(FavoriteContentTab.IMAGES),
          };
        }

        if (params) {
          doNavigateToTab(params);
        }
      } else {
        const params: FavoriteRouteSearchQueryParams = {
          tab: String(tab) ?? '',
        };
        if (hasNestedInfo(data?.topicCount) && Number(tab) === FavoriteContentTab.ROOT_FOLDERS) {
          doNavigateToTab(params);
        } else if (hasNestedInfo(data?.folderCount) && Number(tab) === FavoriteContentTab.FOLDERS) {
          doNavigateToTab(params);
        } else if (hasNestedInfo(data?.layoutCount) && Number(tab) === FavoriteContentTab.LAYOUTS) {
          doNavigateToTab(params);
        } else if (hasNestedInfo(data?.imageCount) && Number(tab) === FavoriteContentTab.IMAGES) {
          doNavigateToTab(params);
        } else {
          navigate(
            {
              pathname: location.pathname,
            },
            { replace: true },
          );
        }
      }
    };

    setInitialTab();
  }, [data?.folderCount, data?.imageCount, data?.layoutCount, data?.topicCount, groupId, tab]);

  return (
    <div className={styles.root}>
      <Tab.Group as='div' selectedIndex={Number(tab)} onChange={handleTabChange}>
        <FavoriteContentHeader group={data} selectedTab={Number(tab)} />
        <FavoriteContentDataView group={data} />
      </Tab.Group>
    </div>
  );
};
