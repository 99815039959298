import { skipToken } from '@reduxjs/toolkit/dist/query';
import cn from 'classnames';
import { FunctionComponent, useMemo } from 'react';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { LayoutDetailRouteSearchQueryParams, useFetchFolderLayoutsQuery } from 'features/layouts';
import { NavigationArrowButton } from 'features/navigation';
import { useAppSelector } from 'infrastructure/redux';
import { getLayoutDetailRoute } from 'infrastructure/routes/paths';
import { ButtonKind } from 'ui-library/components/Button';
import { Icon } from 'ui-library/components/Icon';
import { IconButton, IconButtonVariant } from 'ui-library/components/IconButton';
import { nameof } from 'utils';
import styles from './LayoutArrowNavigation.module.scss';
import { LayoutArrowNavigationProps } from './LayoutArrowNavigationProps';

const LAYOUT_COUNT = Number.MAX_SAFE_INTEGER;

export const LayoutArrowNavigation: FunctionComponent<LayoutArrowNavigationProps> = (props) => {
  const { layout } = props;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const folderId = searchParams.get(nameof<LayoutDetailRouteSearchQueryParams>('folderId'));
  const redirectUrl = searchParams.get(nameof<LayoutDetailRouteSearchQueryParams>('redirectUrl'));
  const params: LayoutDetailRouteSearchQueryParams = {
    redirectUrl: redirectUrl ?? undefined,
    folderId: folderId ?? undefined,
  };
  const { sortDirection, sortField } = useAppSelector((state) => state.layoutList.sortingInfo);
  const { data } = useFetchFolderLayoutsQuery(
    folderId
      ? {
          folderID: folderId,
          currentPage: 1,
          pageSize: LAYOUT_COUNT,
          sortFields: [sortField],
          sortDirection,
        }
      : skipToken,
  );
  const { children } = props;

  const currentIndex = useMemo(() => data?.content.findIndex((x) => x.id === layout?.id) ?? -1, [data, layout]);
  const totalCount = data?.totalCount ?? 0;

  const canGoNext = currentIndex !== -1 && currentIndex + 1 < totalCount;
  const canGoPrevious = currentIndex !== -1 && currentIndex - 1 >= 0;

  const handleClose = () => {
    if (!redirectUrl) {
      return;
    }

    navigate(redirectUrl);
  };

  const handleGoNext = () => {
    if (!layout || !data) {
      return;
    }

    navigate(getLayoutDetailRoute(data.content[currentIndex + 1].id) + `?${createSearchParams(params)}`);
  };

  const handleGoPrevious = () => {
    if (!layout || !data) {
      return;
    }

    navigate(getLayoutDetailRoute(data.content[currentIndex - 1].id) + `?${createSearchParams(params)}`);
  };

  const ArrowLayout = (
    <>
      <NavigationArrowButton
        className={styles.navLeftButton}
        direction='left'
        disabled={!canGoPrevious}
        onClick={handleGoPrevious}
      />
      {children}
      <NavigationArrowButton
        direction='right'
        className={styles.navRightButton}
        disabled={!canGoNext}
        onClick={handleGoNext}
      />
    </>
  );
  const showArrowLayout = !!folderId;

  return (
    <div className={cn(styles.root, !showArrowLayout ? styles.placeholder : undefined)}>
      <IconButton
        className={cn(styles.closeButton)}
        as={ButtonKind.BUTTON}
        variant={IconButtonVariant.TRANSPARENT}
        onClick={handleClose}
      >
        <Icon name='cross' className={styles.icon} />
      </IconButton>
      {showArrowLayout ? ArrowLayout : children}
    </div>
  );
};
