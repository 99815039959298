import { skipToken } from '@reduxjs/toolkit/dist/query';
import React, { FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';
import { Breadcrumbs } from 'features/breadcrumbs';
import {
  FavoriteContent,
  FavoriteGroupDetailRouteQueryParams,
  FavoriteGroupHeaderInfo,
  useFetchGroupQuery,
} from 'features/favorites';
import styles from './FavoriteGroupDetailScreen.module.scss';

export const FavoriteGroupDetailScreen: FunctionComponent = () => {
  const { groupId } = useParams<FavoriteGroupDetailRouteQueryParams>();
  const { data } = useFetchGroupQuery(groupId ?? skipToken);

  return (
    <div className={styles.root}>
      <Breadcrumbs className={styles.breadcrumbs} />
      <FavoriteGroupHeaderInfo className={styles.header} group={data} />
      <FavoriteContent groupId={groupId} />
    </div>
  );
};
