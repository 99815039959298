import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { alertActions } from 'features/alert/slice';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux';
import { Button, ButtonKind, ButtonVariant } from 'ui-library/components/Button';
import { Modal } from 'ui-library/components/Modal';
import styles from './Alert.module.scss';

export const Alert: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { isOpen, message } = useAppSelector((state) => state.alert);

  const handleClose = () => {
    dispatch(alertActions.setMessage(undefined));
    dispatch(alertActions.close());
  };

  return (
    <>
      <Modal variant='popup' open={isOpen} onClose={handleClose} closeIconName='cross'>
        <Modal.Title>{t('file.alert.title')}</Modal.Title>
        <Modal.Content className={styles.root}>
          <span className={styles.message}>{message}</span>
          <Button
            as={ButtonKind.BUTTON}
            variant={ButtonVariant.MAIN}
            className={styles.button}
            size='normal'
            onClick={handleClose}
          >
            {t('file.alert.button')}
          </Button>
        </Modal.Content>
      </Modal>
    </>
  );
};
