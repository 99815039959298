import { FunctionComponent } from 'react';
import { ListItem, ListItemKind } from 'ui-library/components/List';
import { Skeleton } from 'ui-library/components/Skeleton';
import { ImageListBase } from '../ImageListBase/ImageListBase';
import styles from './ImageListSkeleton.module.scss';

const amountOfCards = 4;

export const ImageListSkeleton: FunctionComponent = () => {
  return (
    <ImageListBase>
      {[...Array(amountOfCards)].map((_, idx) => (
        <ListItem key={idx} className={styles.root} kind={ListItemKind.LI} disableGutters padding={'none'}>
          <Skeleton className={styles.image} />
          <div className={styles.lines}>
            <Skeleton width='100%' />
            <Skeleton width='100%' />
            <Skeleton width='100%' />
          </div>
        </ListItem>
      ))}
    </ImageListBase>
  );
};
