import cn from 'classnames';
import React, { forwardRef, PropsWithChildren } from 'react';
import { NavLink } from 'react-router-dom';
import styles from './Link.module.scss';
import { InnerLinkProps, LinkKind, LinkProps, LinkVariant, OuterLinkProps } from './LinkProps';

/**
 * @deprecated
 */
export const Link = forwardRef<HTMLAnchorElement, PropsWithChildren<LinkProps>>((props, ref) => {
  const { variant = LinkVariant.DEFAULT, className, ...rest } = props;

  const finalClassName = cn(className, styles[variant]);

  switch (props.as) {
    case LinkKind.OUTER: {
      const { children, as: _, ...linkProps } = rest as OuterLinkProps;

      return (
        <a className={finalClassName} target='_blank' rel='noopener noreferrer' ref={ref} {...linkProps}>
          {children}
        </a>
      );
    }
    case LinkKind.INNER: {
      const { children, as: _, ...linkProps } = rest as InnerLinkProps;

      return (
        <NavLink className={finalClassName} ref={ref} {...linkProps}>
          {children}
        </NavLink>
      );
    }
    default:
      return null;
  }
});

Link.displayName = 'Link';
