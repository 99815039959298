import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { FolderResponseDto, foldersApi } from 'features/folders';
import { LayoutResponseDto } from 'features/layouts/layoutEntity';
import { layoutsApi } from 'features/layouts/layoutsApi';
import { searchApi } from 'features/search';
import { useLanguageRelevantData } from 'infrastructure/i18n';
import { useAppDispatch } from 'infrastructure/redux';
import styles from './useUnlinkLayout.module.scss';

export const useUnlinkLayout = () => {
  const { matchProp } = useLanguageRelevantData();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const buildSuccessToast = (content: JSX.Element) => {
    toast.success(<div className={styles.notification}>{content}</div>);
  };

  const buildErrorToast = (content: JSX.Element) => {
    toast.error(<div className={styles.notification}>{content}</div>);
  };

  const handleUnlinkLayout = (layout: LayoutResponseDto, selectedFolderData: FolderResponseDto) => {
    if (!selectedFolderData) {
      throw new Error('Destination folder is not selected');
    }
    const isRoot = selectedFolderData.isRoot;

    dispatch(searchApi.util.invalidateTags(['SearchResult']));

    dispatch(foldersApi.util.invalidateTags(['Folder', 'FolderChildren', 'RootFolders']));
    dispatch(layoutsApi.util.invalidateTags([{ type: 'FolderLayouts', id: selectedFolderData.id }]));

    buildSuccessToast(
      <>
        {t(isRoot ? 'unlinkArtifact.success.unlinkLayoutFromRoot' : 'unlinkArtifact.success.unlinkLayoutFromFolder', {
          layout: t('layouts.properties.number', {
            value: layout?.archiveName,
          }),
        })}
        &nbsp;«{matchProp(selectedFolderData.captionRu, selectedFolderData.captionEn)}»
      </>,
    );
  };

  const handleUnlinkLayoutError = () => {
    buildErrorToast(<>{t('unlinkArtifact.error.unlinkErrorLayout')}</>);
  };

  return {
    handleUnlinkLayout,
    handleUnlinkLayoutError,
  };
};
