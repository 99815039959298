import { translateKey } from 'infrastructure/i18n';
import { ApiErrorCode, ApiErrorResponse } from 'infrastructure/network';

export const apiErrorMessageMatcher = (error: ApiErrorResponse | undefined) => {
  if (!error) {
    return undefined;
  }

  const messageByCode: Partial<Record<ApiErrorCode, string>> = {
    [ApiErrorCode.WRONG_FORMAT]: translateKey('validation.file.type'),
    [ApiErrorCode.FILE_INCORRECT]: translateKey('validation.file.type'),
    [ApiErrorCode.FILE_DUPLICATES]: translateKey('validation.file.duplicate'),
    [ApiErrorCode.FILE_ALREADY_EXISTS]: translateKey('validation.file.alreadyExists'),
    [ApiErrorCode.FILE_ALREADY_BOUND]: translateKey('validation.file.alreadyBound'),
  };

  return messageByCode[error.code];
};
