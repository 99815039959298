import { TableColumn } from '../../TableColumn';
import { TableBody } from '../TableBody/TableBody';
import { TableBodySkeletonProps } from './TableBodySkeletonProps';

export const TableBodySkeleton = (props: TableBodySkeletonProps) => {
  const { rows, columns, className, rowClassName } = props;

  return (
    <TableBody
      className={className}
      rowClassName={rowClassName}
      hover={false}
      columns={columns as unknown as Array<TableColumn<object>>}
      items={[...Array(rows)].map(() => ({}))}
    />
  );
};
