import { createSlice } from '@reduxjs/toolkit';
import { ListPaginationState } from 'features/list';
import { getListPaginationReducers } from 'features/list/listReducers';
import { getDefaultPaginationState } from 'features/pagination';

export type AuthorListState = ListPaginationState;

const initialState: AuthorListState = {
  paginationData: getDefaultPaginationState(),
};

const authorListSlice = createSlice({
  name: 'authorList',
  initialState,
  reducers: {
    ...getListPaginationReducers<AuthorListState>(),
    disposePagination: (state) => {
      state.paginationData = getDefaultPaginationState();
    },
  },
});

export const authorListActions = authorListSlice.actions;
export const authorListReducer = authorListSlice.reducer;
