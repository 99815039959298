import { useTranslation } from 'react-i18next';
import { MaterialsKind } from 'features/folders';
import {
  SearchFilterFormFields,
  SearchEntityStatus,
  SearchScreenResultTab,
  useSearchQueryParams,
} from 'features/search';
import { useEnumOptions } from 'features/select';
import { SelectFormController } from 'infrastructure/form';
import { FormCol, FormRow } from 'ui-library/components/Form';
import styles from './MainFilter.module.scss';

const statusField: keyof SearchFilterFormFields = 'status';
const materialsKindsField: keyof SearchFilterFormFields = 'materialsKinds';

export const MainFilter = () => {
  const { t } = useTranslation();
  const { options: entityStatusOptions } = useEnumOptions('SearchEntityStatus', SearchEntityStatus);
  const { options: materialKindsOptions } = useEnumOptions('MaterialsKind', MaterialsKind);

  const { tab } = useSearchQueryParams();

  return (
    <>
      <FormCol className={styles.formCol}>
        <SelectFormController
          name={materialsKindsField}
          label={t('search.filter.labels.materialsKinds')}
          placeholder={t('search.filter.placeholder')}
          options={materialKindsOptions}
          getOptionLabel={(option) => option.label}
          getOptionValue={(option) => option.value}
          hideHelperText
          disabled={tab === SearchScreenResultTab.IMAGES}
          SelectProps={{
            multiple: true,
            InputProps: {
              className: styles.control,
            },
          }}
        />
      </FormCol>
      <FormCol className={styles.formCol}>
        <SelectFormController
          name={statusField}
          label={t('search.filter.labels.status')}
          placeholder={t('search.filter.placeholder')}
          options={entityStatusOptions}
          getOptionLabel={(option) => option.label}
          getOptionValue={(option) => option.value}
          hideHelperText
          SelectProps={{
            canUnselect: true,
            InputProps: {
              className: styles.control,
            },
          }}
        />
      </FormCol>
    </>
  );
};
