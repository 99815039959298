import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getSortReducers, SortDirection, SortState } from 'features/sorting';
import { getCurrentLanguage, matchLanguageProp } from 'infrastructure/i18n';
import i18n from 'infrastructure/i18n/i18n';
import { ListLayout } from '../../list/listLayout';
import { FolderResponseDto } from '../folderEntity';

type EntityType = FolderResponseDto;
export type FolderArchiveListState = {
  layoutType: ListLayout;
  sortingInfo: SortState<EntityType>;
};

const initialState: FolderArchiveListState = {
  layoutType: ListLayout.CARD,
  sortingInfo: {
    sortField: matchLanguageProp<keyof EntityType>(getCurrentLanguage(i18n), 'captionRu', 'captionEn'),
    sortDirection: SortDirection.ASC,
  },
};

const folderArchiveListSlice = createSlice({
  name: 'folderArchiveList',
  initialState,
  reducers: {
    ...getSortReducers<FolderArchiveListState, EntityType>((state) => state.sortingInfo),
    changeLayoutType: (state, action: PayloadAction<ListLayout>) => {
      state.layoutType = action.payload;
    },
  },
});

export const folderArchiveListActions = folderArchiveListSlice.actions;
export const folderArchiveListReducer = folderArchiveListSlice.reducer;
