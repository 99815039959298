import { isNil } from 'lodash';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useHumanReadableFileSize } from 'features/file';
import { Icon } from 'ui-library/components/Icon';
import styles from './FileDownloadDetailHeader.module.scss';
import { FileDownloadDetailHeaderProps } from './FileDownloadDetailHeaderProps';

export const FileDownloadDetailHeader: FunctionComponent<FileDownloadDetailHeaderProps> = (props) => {
  const { task } = props;
  const { t } = useTranslation();
  const { convertToHumanReadable } = useHumanReadableFileSize();

  return (
    <div className={styles.root}>
      <Icon className={styles.icon} name='folderAlt' />
      <h1 className={styles.title}>{task?.label}</h1>
      {!isNil(task?.size) && (
        <span className={styles.fileSize}>
          {t('files.downloadDetail.fileSize', {
            size: convertToHumanReadable(task?.size),
          })}
        </span>
      )}
    </div>
  );
};
