import cn from 'classnames';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { ActiveFileDownloadTaskState } from 'features/file/components/ActiveFileDownloadTasks/ActiveFileDownloadTaskState';
import { useAppSelector } from 'infrastructure/redux';
import { ButtonKind } from 'ui-library/components/Button';
import { Icon } from 'ui-library/components/Icon';
import { IconButton } from 'ui-library/v1/components/IconButton';
import styles from './AnchorButton.module.scss';

export const AnchorButton: FunctionComponent = () => {
  const windowState = useAppSelector((state) => state.activeFileDownloadTasks.windowState);
  const ANCHOR_ID = useRef(nanoid()).current;
  const [isVisible, setVisible] = useState(false);
  const [isBottomScreen, setIsBottomScreen] = useState(false);

  const handleScroll = () => {
    if (window.innerHeight + window.scrollY >= document.body.scrollHeight) {
      setIsBottomScreen(true);
    } else {
      setIsBottomScreen(false);
    }
  };

  const debounceHandleScroll = _.debounce(handleScroll, 100);

  useEffect(() => {
    window.addEventListener('scroll', debounceHandleScroll);
    return () => window.removeEventListener('scroll', debounceHandleScroll);
  }, [debounceHandleScroll]);

  debounceHandleScroll.cancel();

  const onClick = () => {
    const el = document.getElementById(ANCHOR_ID);

    el?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    const el = document.getElementById(ANCHOR_ID);

    if (!el) {
      return;
    }

    const observer = new IntersectionObserver((entries) => {
      if (entries[0].boundingClientRect.y < 0) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    });

    observer.observe(el);

    return () => {
      observer.unobserve(el);
    };
  }, [ANCHOR_ID]);

  return (
    <>
      <div id={ANCHOR_ID}></div>
      {isVisible && (
        <IconButton
          className={cn({
            [styles.root]: !isBottomScreen,
            [styles.anchorBottom]: isBottomScreen,
            [styles.downloadModalCollapsed]: windowState === ActiveFileDownloadTaskState.COLLAPSED,
            [styles.downloadModalOpened]: windowState === ActiveFileDownloadTaskState.OPENED,
          })}
          as={ButtonKind.BUTTON}
          onClick={onClick}
        >
          <Icon name='arrowUpLong' />
        </IconButton>
      )}
    </>
  );
};
