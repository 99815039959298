import cn from 'classnames';
import { forwardRef } from 'react';
import { Icon } from 'ui-library/components/Icon';
import styles from './Checkbox.module.scss';
import { CheckboxProps } from './CheckboxProps';

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>((props, ref) => {
  const { className } = props;

  return (
    <span className={cn(styles.root, className)}>
      <input {...props} className={styles.input} ref={ref} type='checkbox' onClick={(e) => e.stopPropagation()} />
      <span className={styles.tick}>
        <Icon className={styles.check} name='check' size='none' />
      </span>
    </span>
  );
});
