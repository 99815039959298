import { AvailableLanguages } from 'infrastructure/i18n/i18n';
import { ListApiRequest, ListApiResponse } from 'infrastructure/network';

export type FileResponseDto = {
  id: string;
  artifactID: string;
  name: string;
  url?: string;
  type: string;
  previews?: Array<FilePreview>;
};

type FilePreview = {
  fileID: string;
  id: string;
  name: string;
  url: string;
  label: FilePreviewLabel;
};

export enum FilePreviewLabel {
  FOLDER_ICON = 'folder-icon',
  FOLDER_PREVIEW = 'folder-preview',
  FOLDER_SEARCH = 'folder-search',
  IMAGE_ORIGINAL_JPEG = 'image-original-jpeg',
  IMAGE_ICON = 'image-icon',
  IMAGE_PREVIEW = 'image-preview',
  IMAGE_FULLSCREEN = 'image-fullscreen',
  IMAGE_SEARCH = 'image-search',
  LAYOUT_ICON = 'layout-icon',
  LAYOUT_CROPPED_ORIGINAL = 'layout-cropped-original',
  LAYOUT_CROPPED_JPEG = 'layout-cropped-jpeg',
  LAYOUT_MAIN = 'layout-main',
  LAYOUT_PREVIEW = 'layout-preview',
  LAYOUT_SEARCH = 'layout-search',
  LAYOUT_FULLSCREEN = 'layout-fullscreen',
}

export enum FileType {
  FOLDER = 'folder',
  LAYOUT = 'layout',
  IMAGE = 'image',
}

export type FileUploadRequestDto = {
  fileType: FileType;
  file: File;
};

export type FileBox = {
  previewUrl?: string;
  file?: File;
};

export type FileDownloadResponseDto = {
  ID: string;
  label: string;
  status: FileDownloadStatus;
  arg?: {
    ID: string;
    format: FileDownloadImageFormat;
    language: AvailableLanguages;
    type: FileArtifactEntityType;
  };
  name?: FileDownloadViewRequestTypesTask;
  ownerID: string;
  URL: string;
  createTime: string;
  validThrough?: string;
  size?: number;
};

export enum FileDownloadStatus {
  WORKING = 'working',
  DONE = 'done',
  FAILED = 'failed',
  CANCELLED = 'cancelled',
}

export type FileDownloadViewResponseItemDto = {
  active: ListApiResponse<FileDownloadResponseDto>;
  archive: ListApiResponse<FileDownloadResponseDto>;
};

export type FileDownloadViewRequestDto = {
  items: Array<FileDownloadViewRequestItemDto>;
};

export type FileDownloadViewRequestItemDto = ListApiRequest<FileDownloadResponseDto> & {
  section: 'active' | 'archive';
  taskFilter?: Array<FileDownloadViewRequestTypesTask>;
};

export enum FileDownloadViewRequestTypesTask {
  FILE_DOWNLOAD = 'file-bulk-downloads',
  IMAGE_METADATA = 'image-metadata-downloads',
  IMAGE_BULK = 'image-bulk-update-reports',
}

export type FileDownloadViewResponseDto = {
  items: Array<FileDownloadViewResponseItemDto>;
};

export type FileDownloadTaskRequestDto = {
  artifactType: FileArtifactEntityType;
  artifactID: string;
  imageFormat: FileDownloadImageFormat;
  fetch: Array<FileDownloadTaskRequestFetchType>;
  language: AvailableLanguages;
};

export enum FileDownloadImageFormat {
  JPEG = 'jpeg',
  TIF = 'tiff',
}

type FileDownloadTaskRequestFetchType = 'file' | 'metadata_excel';

export type FileDownloadTaskResponseDto = {
  taskID: string;
};
export type FileDownloadTaskRetryResponseDto = {
  taskID: string;
};

export type FileArtifactEntityType = 'folder' | 'favourites_group';

export type FileArtifactEntity = {
  entityId: string;
  type: FileArtifactEntityType;
};

export type FileDownloadAgreementFormFields = {
  doNotShowAnymore: boolean;
};
