import { FunctionComponent } from 'react';
import { useSearchParams } from 'react-router-dom';
import { FolderPartition } from 'features/folders';
import { SearchRouteQueryParams } from 'features/search';
import { SearchHeader } from 'features/v1/search/SearchHeader';
import { nameof } from 'utils';
import { SearchScreenResult } from '../SearchScreenResult/SearchScreenResult';
import { SearchScreenResultAI } from '../SearchScreenResultAI/SearchScreenResultAI';
import styles from './SearchScreenContainer.module.scss';

export const SearchScreenContainer: FunctionComponent = () => {
  const [searchParams] = useSearchParams();
  const query = searchParams.get(nameof<SearchRouteQueryParams>('query'));
  const partition = searchParams.get(nameof<SearchRouteQueryParams>('partition'));

  return (
    <div className={styles.root}>
      <SearchHeader />
      <div className={styles.container}>
        {partition === FolderPartition.AI ? (
          <SearchScreenResultAI query={query} />
        ) : (
          <SearchScreenResult query={query} />
        )}
      </div>
    </div>
  );
};
