import { FavoriteGroupDetailRouteQueryParams } from 'features/favorites';
import { DownloadDetailRouteQueryParams } from 'features/file';
import { FolderDetailRouteQueryParams, FolderPartition } from 'features/folders';
import { ImageDetailRouteQueryParams } from 'features/images';
import { LayoutDetailRouteQueryParams } from 'features/layouts';
import { SearchRouteQueryParams } from 'features/search';
import { ToggleMuseumRouteQueryParams } from 'features/toggleMuseum/types';
import { RoutePathBuilder } from './shared/routePathBuilder';

const homeRoute = '/';

const favoritesRoute = '/favorites';
const baseFavoriteGroupsRoute = `${favoritesRoute}/groups`;
const favoriteGroupListRoute = RoutePathBuilder.buildListRoute(baseFavoriteGroupsRoute);
const favoriteGroupDetailRoute = RoutePathBuilder.buildRouteWithParamNames<FavoriteGroupDetailRouteQueryParams>(
  baseFavoriteGroupsRoute,
  ['groupId'],
);

const signInRoute = '/sign-in';

const baseGalleryRoute = '/gallery';
const galleryRoute = RoutePathBuilder.buildRouteWithParamNames<ToggleMuseumRouteQueryParams>(baseGalleryRoute, [
  'partition',
]);

const getBaseFolderRoute = (baseRoute: string) => `${baseRoute}`;
const folderDetailRoute = RoutePathBuilder.buildRouteWithParamNames<FolderDetailRouteQueryParams>(
  getBaseFolderRoute(galleryRoute),
  ['folderId'],
);

const baseLayoutRoute = '/layout';
const layoutDetailRoute = RoutePathBuilder.buildRouteWithParamNames<LayoutDetailRouteQueryParams>(baseLayoutRoute, [
  'layoutId',
]);

const baseImageRoute = '/image';
const imageDetailRoute = RoutePathBuilder.buildRouteWithParamNames<ImageDetailRouteQueryParams>(baseImageRoute, [
  'imageId',
]);

const searchRoute = '/search';

const usersRoute = '/users';

const referenceBookAuthorsRoute = '/authors';

const baseDownloadsRoute = '/downloads';
const downloadListRoute = RoutePathBuilder.buildListRoute(baseDownloadsRoute);
const downloadDetailRoute = RoutePathBuilder.buildRouteWithParamNames<DownloadDetailRouteQueryParams>(
  baseDownloadsRoute,
  ['taskId'],
);

export const ROUTE_PATHS = {
  HOME: homeRoute,
  GALLERY: galleryRoute,
  AUTHORS: referenceBookAuthorsRoute,
  FAVORITES: {
    ROOT: favoritesRoute,
    GROUP_DETAIL: favoriteGroupDetailRoute,
    GROUPS: favoriteGroupListRoute,
  },
  FOLDER_DETAIL: folderDetailRoute,
  LAYOUT_DETAIL: layoutDetailRoute,
  IMAGE_DETAIL: imageDetailRoute,
  AUTH: {
    SIGN_IN: signInRoute,
  },
  SEARCH: searchRoute,
  USERS: usersRoute,
  DOWNLOADS: {
    ROOT: downloadListRoute,
    DETAIL: downloadDetailRoute,
  },
};

/* ROUTE HELPERS */
export const getGalleryRoute = (partition: FolderPartition) =>
  RoutePathBuilder.buildRouteWithParams(baseGalleryRoute, [partition]);

export const getFolderDetailRoute = (folderId: string, partition: FolderPartition) =>
  RoutePathBuilder.buildRouteWithParams(getBaseFolderRoute(getGalleryRoute(partition)), [folderId]);

export const getLayoutDetailRoute = (layoutId: string) =>
  RoutePathBuilder.buildRouteWithParams(baseLayoutRoute, [layoutId]);

export const getImageDetailRoute = (imageId: string) =>
  RoutePathBuilder.buildRouteWithParams(baseImageRoute, [imageId]);

export const getSearchRoute = (params: Partial<SearchRouteQueryParams>) =>
  RoutePathBuilder.buildRouteWithQueryParams<Partial<SearchRouteQueryParams>>(searchRoute, params);

export const getFavoriteGroupDetailRoute = (groupId: string) =>
  RoutePathBuilder.buildRouteWithParams(baseFavoriteGroupsRoute, [groupId]);

export const getDownloadDetailRoute = (taskId: string) =>
  RoutePathBuilder.buildRouteWithParams(baseDownloadsRoute, [taskId]);
