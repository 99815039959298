import { FunctionComponent } from 'react';
import { ImageView } from 'features/images';
import styles from './ImageDetailInfoPanel.module.scss';
import { ImageDetailInfoPanelProps } from './ImageDetailInfoPanelProps';

export const ImageDetailInfoPanel: FunctionComponent<ImageDetailInfoPanelProps> = (props) => {
  const { image } = props;

  return (
    <div className={styles.root}>
      <ImageView className={styles.info} image={image} />
    </div>
  );
};
