import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getDefaultModalState, getModalReducers, ModalState } from 'features/modal';

export type FavoriteGroupEditState = ModalState & {
  selectedGroupId?: string;
};

const initialState: FavoriteGroupEditState = {
  ...getDefaultModalState(),
  selectedGroupId: undefined,
};

const favoriteGroupEditSlice = createSlice({
  name: 'favoriteGroupEditSlice',
  initialState,
  reducers: {
    ...getModalReducers<FavoriteGroupEditState>((state) => state),
    selectGroup: (state, action: PayloadAction<string | undefined>) => {
      state.selectedGroupId = action.payload;
    },
  },
});

export const favoriteGroupEditActions = favoriteGroupEditSlice.actions;
export const favoriteGroupEditReducer = favoriteGroupEditSlice.reducer;
