import { skipToken } from '@reduxjs/toolkit/dist/query';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FolderLinks,
  FolderPartition,
  FolderStatus,
  MaterialsKind,
  useFetchLayoutFoldersQuery,
} from 'features/folders';
import { layoutAccessRuleService } from 'features/role';
import { SearchScreenResultTab } from 'features/search';
import { TagsSearchLinks } from 'features/tags';
import { useCurrentLanguage, useLanguageRelevantData, useTranslateEnum } from 'infrastructure/i18n';
import { PropertyInfo } from 'ui-library/components/PropertyInfo';
import styles from './LayoutViewInfo.module.scss';
import { LayoutViewInfoProps } from './LayoutViewInfoProps';

export const LayoutViewInfo: FunctionComponent<LayoutViewInfoProps> = (props) => {
  const { t } = useTranslation();
  const { matchProp } = useLanguageRelevantData();
  const currentLang = useCurrentLanguage();
  const { translateValue: translateFolderStatusValue } = useTranslateEnum('FolderStatus', FolderStatus);
  const { translateValue: translateMaterialsKindValue } = useTranslateEnum('MaterialsKind', MaterialsKind);
  const { layout } = props;
  const { data: foldersData } = useFetchLayoutFoldersQuery(
    layout ? { layoutID: layout.id, pageSize: Number.MAX_SAFE_INTEGER, currentPage: 1 } : skipToken,
  );

  return (
    <div className={styles.root}>
      {((layout?.descriptionRu && currentLang === 'ru') || (layout?.descriptionEn && currentLang === 'en')) && (
        <PropertyInfo
          title={t('layouts.properties.title')}
          value={matchProp(layout?.descriptionRu, layout?.descriptionEn)}
        />
      )}
      {layoutAccessRuleService.canViewUI() && (
        <PropertyInfo title={t('layouts.properties.status')} value={translateFolderStatusValue(layout?.status)} />
      )}
      <PropertyInfo
        title={t('layouts.properties.materialsKind')}
        value={(layout?.materialsKinds ?? []).map((x) => translateMaterialsKindValue(x)).join(', ')}
      />
      {layoutAccessRuleService.canViewOriginalInfo() && (
        <PropertyInfo title={t('layouts.properties.archiveName')} value={layout?.archiveName} />
      )}
      {layoutAccessRuleService.canViewOriginalInfo() && (
        <PropertyInfo
          title={t('layouts.properties.tags')}
          value={
            layout?.tags?.length ? (
              <TagsSearchLinks
                tags={layout?.tags}
                tab={SearchScreenResultTab.LAYOUTS}
                partition={FolderPartition.ARCHIVE}
              />
            ) : null
          }
        />
      )}
      <PropertyInfo title={t('layouts.properties.themes')} value={<FolderLinks folders={foldersData?.content} />} />
    </div>
  );
};
