import cn from 'classnames';
import { FunctionComponent } from 'react';
import { useLanguageRelevantData } from 'infrastructure/i18n';
import { FolderDetailButtonGroup } from '../FolderDetailButtonGroup/FolderDetailButtonGroup';
import styles from './FolderDetailHeaderInfo.module.scss';
import { FolderDetailHeaderInfoProps } from './FolderDetailHeaderInfoProps';

export const FolderDetailHeaderInfo: FunctionComponent<FolderDetailHeaderInfoProps> = (props) => {
  const { matchProp } = useLanguageRelevantData();
  const { folder, className, partition } = props;

  return (
    <div className={cn(styles.root, className)}>
      <h1 className={styles.title}>{matchProp(folder?.captionRu, folder?.captionEn)}</h1>
      <FolderDetailButtonGroup folder={folder} className={styles.buttonGroup} partition={partition} />
    </div>
  );
};
