import { Menu as BuiltinMenu } from '@headlessui/react';
import cn from 'classnames';
import { FunctionComponent } from 'react';
import styles from './MenuItem.module.scss';
import { MenuItemProps } from './MenuItemProps';

export const MenuItem: FunctionComponent<MenuItemProps> = (props) => {
  const { children, className, active, ...restProps } = props;

  return (
    <BuiltinMenu.Item
      className={cn(styles.root, className, {
        [styles.active]: active,
      })}
      as='li'
      {...restProps}
    >
      {children}
    </BuiltinMenu.Item>
  );
};
