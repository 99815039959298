import { FunctionComponent } from 'react';
import { createSearchParams, useLocation, useSearchParams } from 'react-router-dom';
import { FolderDetailQuerySearchParams } from 'features/folders';
import { LayoutDetailRouteSearchQueryParams, layoutListActions, useFetchFolderLayoutsQuery } from 'features/layouts';
import { ListLayout } from 'features/list';
import { FIRST_PAGE } from 'features/pagination';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux';
import { Pagination } from 'ui-library/components/Pagination';
import { nameof } from 'utils';
import { LayoutCardList } from '../LayoutCardList/LayoutCardList';
import { LayoutLoadingError } from '../LayoutLoadingError/LayoutLoadingError';
import styles from './LayoutListDataView.module.scss';
import { LayoutListDataViewProps } from './LayoutListDataViewProps';

export const LayoutListDataView: FunctionComponent<LayoutListDataViewProps> = (props) => {
  const { folderId } = props;
  const dispatch = useAppDispatch();
  const layoutType = useAppSelector((state) => state.layoutList.layoutType);
  const { sortDirection, sortField } = useAppSelector((state) => state.layoutList.sortingInfo);
  const { currentPage, pageSize } = useAppSelector((state) => state.layoutList.paginationData);
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get(nameof<FolderDetailQuerySearchParams>('tab'));
  const page = searchParams.get(nameof<FolderDetailQuerySearchParams>('page'));
  const querySearchParam: FolderDetailQuerySearchParams = {
    tab: tab ?? '',
    page: page ?? String(FIRST_PAGE),
  };
  const params: LayoutDetailRouteSearchQueryParams = {
    redirectUrl: location.pathname + `?${createSearchParams(querySearchParam)}`,
    folderId,
  };

  const query = useFetchFolderLayoutsQuery({
    folderID: folderId,
    currentPage,
    pageSize,
    sortDirection,
    sortFields: [sortField],
  });

  const layoutView = (
    <>{layoutType === ListLayout.CARD && <LayoutCardList folderId={folderId} params={params} {...query} />}</>
  );

  return (
    <div className={styles.root}>
      <div className={styles.listWrap}>
        {query.isError ? <LayoutLoadingError onReload={query.refetch} /> : layoutView}
      </div>
      <div className={styles.paginationWrap}>
        <Pagination
          totalItems={query.data?.totalCount}
          pageSize={pageSize}
          onPageChanged={(page) => dispatch(layoutListActions.pageChanged({ page }))}
          currentPage={currentPage}
          scrollToStartAfterPageChanged
        />
      </div>
    </div>
  );
};
