import { FunctionComponent, SyntheticEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { FolderEmptyChildren, FolderResponseDto, FolderTableSkeleton, useFolderTableColumns } from 'features/folders';
import { getFolderDetailRoute } from 'infrastructure/routes/paths';
import { Table, TableBody, TableHead } from 'ui-library/components/Table';
import { FolderTableActions } from '../FolderTableActions/FolderTableActions';
import { FolderTableProps } from './FolderTableProps';

export const FolderTable: FunctionComponent<FolderTableProps> = (props) => {
  const { data, groupId } = props;
  const navigate = useNavigate();

  const columns = useFolderTableColumns();

  const handleRowClick = (event: SyntheticEvent, item: FolderResponseDto) => {
    navigate(getFolderDetailRoute(item.id, item.partition));
  };

  const isEmpty = data?.totalCount === 0;

  if (isEmpty) {
    return <FolderEmptyChildren />;
  }

  return (
    <Table>
      <TableHead columns={columns} />
      {props.isFetching ? (
        <FolderTableSkeleton />
      ) : (
        <TableBody
          columns={columns}
          items={data?.content ?? []}
          getRowActions={(x, options) => (
            <FolderTableActions folder={x} groupId={groupId} className={options?.className} />
          )}
          onRowClick={handleRowClick}
        />
      )}
    </Table>
  );
};
