import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { UserEditFormFields } from 'features/users';
import { PasswordRules } from 'features/users/components/PasswordRules/PasswordRules';
import { FormCol, FormRow } from 'ui-library/components/Form';
import { PasswordTextField } from 'ui-library/components/TextField';
import styles from './UserEditFormPasswordTab.module.scss';

const passwordCurrentField: keyof UserEditFormFields = 'passwordCurrent';
const passwordField: keyof UserEditFormFields = 'password';
const passwordConfirmField: keyof UserEditFormFields = 'passwordConfirm';

export const UserEditFormPasswordTab = () => {
  const { t } = useTranslation();

  const {
    register,
    getValues,
    watch,
    formState: { errors },
  } = useFormContext<UserEditFormFields>();
  watch('password');

  return (
    <div className={styles.root}>
      <FormRow>
        <FormCol>
          <PasswordTextField
            error={errors[passwordCurrentField]?.message}
            label={t('users.edit.currentPassword.label')}
            required
            InputProps={{
              ...register(passwordCurrentField),
              placeholder: t('users.edit.currentPassword.placeholder'),
            }}
          />
        </FormCol>
        <FormCol></FormCol>
      </FormRow>
      <FormRow>
        <FormCol>
          <PasswordTextField
            error={errors[passwordField]?.message}
            label={t('users.edit.newPassword.label')}
            InputProps={{
              ...register(passwordField),
              placeholder: t('users.edit.newPassword.placeholder'),
            }}
          />
        </FormCol>
        <FormCol>
          <PasswordTextField
            error={errors[passwordConfirmField]?.message}
            label={t('users.edit.confirmNewPassword.label')}
            InputProps={{
              ...register(passwordConfirmField),
              placeholder: t('users.edit.confirmNewPassword.placeholder'),
            }}
          />
        </FormCol>
      </FormRow>
      <FormRow>
        <FormCol>
          <PasswordRules value={getValues(passwordField)} />
        </FormCol>
        <FormCol></FormCol>
      </FormRow>
    </div>
  );
};
