import { FunctionComponent } from 'react';
import { ScrollSeekPlaceholderProps } from 'react-virtuoso';
import { ImageCardSkeleton } from '../ImageCardSkeleton/ImageCardSkeleton';
import { ImageGalleryGrid } from '../ImageGalleryGrid/ImageGalleryGrid';

const amountOfCards = 16;

export const ImageGalleryScrollSeekPlaceholder: FunctionComponent<ScrollSeekPlaceholderProps> = ({ height, index }) => {
  return (
    <div
      style={{
        height,
      }}
    >
      <ImageGalleryGrid>
        {[...Array(amountOfCards)].map((_, idx) => (
          <ImageCardSkeleton key={idx} />
        ))}
      </ImageGalleryGrid>
    </div>
  );
};
