import cn from 'classnames';
import { useEffect, useState } from 'react';
import { Image } from 'ui-library/components/Image';
import styles from './ImagePreview.module.scss';
import { ImagePreviewProps } from './ImagePreviewProps';

export const ImagePreview = (props: ImagePreviewProps) => {
  const { onLoad, src, ...rest } = props;

  const [ready, setReady] = useState(false);

  useEffect(() => {
    setReady(false);
  }, [src]);

  return (
    <div className={cn(styles.preview, { [styles.ready]: ready })}>
      <Image
        {...rest}
        src={src}
        onLoad={(event) => {
          setReady(true);
          onLoad?.(event);
        }}
      />
    </div>
  );
};
