import { FileBox, FileResponseDto } from 'features/file';
import { TagResponseDto } from 'features/tags';
import { AvailableLanguages } from 'infrastructure/i18n/i18n';
import { ListApiFetchArgs } from 'infrastructure/network';

export type FolderResponseDto = {
  id: string;
  parentID?: string;
  isRoot: boolean;
  file?: FileResponseDto;
  captionRu: string;
  captionEn: string;
  boxName: string;
  descriptionRu: string;
  descriptionEn: string;
  status: FolderStatus;
  layoutCount: number;
  childrenCount: number;
  imageCount: number;
  materialsKinds: Array<MaterialsKind>;
  tags: Array<TagResponseDto>;
  isArchived: boolean;
  isFavourite: boolean;
  partition: FolderPartition;
  order: number;
};

export enum FolderStatus {
  DRAFT = 'draft',
  DONE = 'done',
}

export enum MaterialsKind {
  RAW_MATERIAL = 'raw_material',
  COMPARATIVE_OPUS = 'comparative_opus',
}

export type FetchRootFoldersQueryData = ListApiFetchArgs<FolderResponseDto> & {
  partition: FolderPartition;
};

export type FetchLayoutFoldersQueryData = ListApiFetchArgs<FolderResponseDto> & {
  layoutID: string;
};

export type FetchChildrenFolderQueryData = ListApiFetchArgs<FolderResponseDto> & {
  folderId: string;
  partition: FolderPartition;
};

export type FolderResponseDtoGrouped = Record<string, Array<FolderResponseDto>>;

export type FolderEditFormFields = {
  fileBox: FileBox;
  captionRu?: FolderResponseDto['captionRu'];
  captionEn?: FolderResponseDto['captionEn'];
  descriptionRu?: FolderResponseDto['descriptionRu'];
  descriptionEn?: FolderResponseDto['descriptionEn'];
  status?: FolderResponseDto['status'];
  materialsKinds?: FolderResponseDto['materialsKinds'];
  tags?: FolderResponseDto['tags'];
  tagsExtend?: Array<string>;
};

export type RootFolderCreateRequestDto = Omit<FolderEditFormFields, 'fileBox' | 'tags'> & {
  fileID?: string;
  tags?: Array<string>;
};

export type ChildFolderCreateRequestDto = Omit<FolderEditFormFields, 'tags'> & {
  parentID: string;
  tags?: Array<string>;
};

export type FolderCreateRequestDto = (RootFolderCreateRequestDto | ChildFolderCreateRequestDto) & {
  partition: FolderPartition;
};

type FolderEditMainRequestFields = {
  folderID: FolderResponseDto['id'];
  fileID?: string;
  fileClear?: boolean;
  isRoot?: FolderResponseDto['isRoot'];
  isArchived?: FolderResponseDto['isArchived'];
};

export type RootFolderEditRequestDto = Omit<RootFolderCreateRequestDto, keyof FolderEditMainRequestFields> &
  FolderEditMainRequestFields;

export type ChildFolderEditRequestDto = ChildFolderCreateRequestDto & FolderEditMainRequestFields;

export type FolderEditRequestDto = RootFolderEditRequestDto | ChildFolderEditRequestDto;

export type FolderEditLinkedImagesRequestDto = {
  folderID: string;
  correspondingFolderID: string;
  imageIDs: Array<string>;
  unlinkFromCorrespondingFolder?: boolean;
};

export type FolderSearchRequestDto = ListApiFetchArgs<FolderResponseDto> & {
  query: string;
  language: AvailableLanguages;
  parentID?: string;
  partition: FolderPartition;
};

export type FolderDeleteRequestDto = {
  folderID: FolderResponseDto['id'];
  parentID: FolderResponseDto['parentID'];
};

export enum FolderPartition {
  MUSEUM = 'museum',
  ARCHIVE = 'archive',
  AI = 'ai',
}
