import cn from 'classnames';
import { FunctionComponent } from 'react';
import styles from './NumLabel.module.scss';
import { NumLabelProps } from './NumLabelProps';

export const NumLabel: FunctionComponent<NumLabelProps> = (props) => {
  const { num, position = 'topLeft', staticPosition } = props;
  return (
    <span
      className={cn(styles.index, {
        [styles.topLeft]: position === 'topLeft',
        [styles.rightBottom]: position === 'rightBottom',
        [styles.static]: staticPosition,
      })}
    >
      {num}
    </span>
  );
};
