import { isNil } from 'lodash';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { FolderPartition } from 'features/folders';
import { DEFAULT_PARTITION_TYPE } from 'features/toggleMuseum';
import { addSearchParams } from 'infrastructure/routes';
import { nameof } from 'utils';
import { SearchScreenResultTab } from '../enums';
import { SearchFilterFormFields, SearchRouteFilterQueryParams, SearchRouteQueryParams } from '../types';

export const useSearchQueryParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const tab: SearchScreenResultTab = useMemo(() => {
    const queryTab = searchParams.get(nameof<SearchRouteQueryParams>('tab'));

    return queryTab !== null ? +queryTab : SearchScreenResultTab.IMAGES;
  }, [searchParams]);

  const query = searchParams.get(nameof<SearchRouteQueryParams>('query')) || '';
  const partition = searchParams.get(nameof<SearchRouteQueryParams>('partition')) || DEFAULT_PARTITION_TYPE;
  const tagIds = useMemo(() => searchParams.getAll(nameof<SearchRouteQueryParams>('tags')), [searchParams]);
  const filterData: SearchRouteFilterQueryParams = useMemo(() => {
    return {
      status: searchParams.get(nameof<SearchRouteQueryParams>('status')) as SearchRouteQueryParams['status'],
      materialsKinds: searchParams.getAll(
        nameof<SearchRouteQueryParams>('materialsKinds'),
      ) as SearchRouteQueryParams['materialsKinds'],
      authorID: searchParams.get(nameof<SearchRouteQueryParams>('authorID')),
      centuryID: searchParams.get(nameof<SearchRouteQueryParams>('centuryID')),
      yearID: searchParams.get(nameof<SearchRouteQueryParams>('yearID')),
      artFormID: searchParams.get(nameof<SearchRouteQueryParams>('artFormID')),
      centuryHalf: searchParams.get(nameof<SearchRouteQueryParams>('centuryHalf')),
      materialsTechnicsID: searchParams.get(nameof<SearchRouteQueryParams>('materialsTechnicsID')),
    };
  }, [searchParams]);

  const addCurrentTab = (newSearchParams: URLSearchParams) => {
    addSearchParams({ [nameof<SearchRouteQueryParams>('tab')]: tab.toString() }, newSearchParams);
  };

  const addCurrentQuery = (newSearchParams: URLSearchParams) => {
    addSearchParams({ [nameof<SearchRouteQueryParams>('query')]: query }, newSearchParams);
  };

  const addCurrentPartition = (newSearchParams: URLSearchParams) => {
    addSearchParams({ [nameof<SearchRouteQueryParams>('partition')]: partition }, newSearchParams);
  };

  const addCurrentTags = (newSearchParams: URLSearchParams) => {
    addSearchParams({ [nameof<SearchRouteQueryParams>('tags')]: tagIds }, newSearchParams);
  };

  const addCurrentFilterData = (newSearchParams: URLSearchParams) => {
    addSearchParams(filterData, newSearchParams);
  };

  const updateParams = (params: Partial<Pick<SearchRouteQueryParams, 'tab' | 'partition' | 'tags'>>) => {
    const newSearchParams = new URLSearchParams();
    const { tab, partition, tags } = params;

    if (!isNil(tab)) {
      addSearchParams({ [nameof<SearchRouteQueryParams>('tab')]: tab.toString() }, newSearchParams);
    } else {
      addCurrentTab(newSearchParams);
    }

    if (!isNil(partition)) {
      addSearchParams({ [nameof<SearchRouteQueryParams>('partition')]: partition }, newSearchParams);
    } else {
      addCurrentPartition(newSearchParams);
    }

    if (!isNil(tags)) {
      addSearchParams({ [nameof<SearchRouteQueryParams>('tags')]: tags }, newSearchParams);
    } else {
      addCurrentTags(newSearchParams);
    }

    addCurrentFilterData(newSearchParams);
    addCurrentQuery(newSearchParams);
    setSearchParams(newSearchParams);
  };

  const updateFilterData = (formFields: Partial<SearchFilterFormFields>) => {
    const newSearchParams = new URLSearchParams();

    const result: SearchRouteFilterQueryParams = {
      status: formFields.status,
      materialsKinds: formFields.materialsKinds,
      authorID: formFields.author?.id || null,
      centuryID: formFields.century?.id || null,
      yearID: formFields.year?.id || null,
      artFormID: formFields.artForm?.id || null,
      centuryHalf: formFields.centuryHalf ? formFields.centuryHalf.toString() : null,
      materialsTechnicsID: formFields.materialsTechnics?.id || null,
    };

    addSearchParams(result, newSearchParams);

    addCurrentTab(newSearchParams);
    addCurrentQuery(newSearchParams);
    addCurrentTags(newSearchParams);
    addCurrentPartition(newSearchParams);

    setSearchParams(newSearchParams);
  };

  return {
    tab,
    query,
    partition: partition as FolderPartition,
    tagIds,
    filterData,
    updateFilterData,
    updateParams,
  };
};
