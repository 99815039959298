import cn from 'classnames';
import { forwardRef } from 'react';
import styles from './TagsChipLabel.module.scss';
import { TagsChipLabelProps } from './TagsChipLabelProps';

export const TagsChipLabel = forwardRef<HTMLSpanElement, TagsChipLabelProps>((props, ref) => {
  const { children, focused, extended, removed, withAction, ...restProps } = props;

  return (
    <span
      ref={ref}
      className={cn(styles.root, {
        [styles.focused]: focused,
        [styles.extended]: extended,
        [styles.removed]: removed,
        [styles.withAction]: withAction,
      })}
      {...restProps}
    >
      {children}
    </span>
  );
});
