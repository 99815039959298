import { FunctionComponent } from 'react';
import { LayoutCardBase } from 'features/layouts';
import { useLayoutActions } from '../../../hooks/useLayoutActions';
import { LayoutCardExternalProps } from './LayoutCardProps';

export const LayoutCard: FunctionComponent<LayoutCardExternalProps> = (props) => {
  const { layout } = props;
  const { actions } = useLayoutActions({ layout });

  return <LayoutCardBase layout={layout} actions={actions} />;
};
