import { skipToken } from '@reduxjs/toolkit/dist/query';
import { FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';
import { ImageArrowNavigation, ImageDetailRouteQueryParams, useFetchImageDetailQuery } from 'features/images';
import { ImageDetailImagePanel } from '../ImageDetailImagePanel/ImageDetailImagePanel';
import { ImageDetailInfoPanel } from '../ImageDetailInfoPanel/ImageDetailInfoPanel';

export const ImageDetailDataView: FunctionComponent = () => {
  const { imageId } = useParams<ImageDetailRouteQueryParams>();
  const { data } = useFetchImageDetailQuery(imageId ?? skipToken);

  return (
    <ImageArrowNavigation image={data}>
      <ImageDetailImagePanel image={data} />
      <ImageDetailInfoPanel image={data} />
    </ImageArrowNavigation>
  );
};
