import { Tab as BuiltinTab } from '@headlessui/react';
import cn from 'classnames';
import { FunctionComponent } from 'react';
import styles from './TabGroup.module.scss';

export const TabGroup: FunctionComponent<Parameters<typeof BuiltinTab['Group']>[0]> = (props) => {
  const { className, ...restProps } = props;

  return <BuiltinTab.Group className={cn(styles.root, className)} {...restProps} />;
};
