import cn from 'classnames';
import { CheckboxField } from 'ui-library/components/CheckboxField';
import { BaseSelectOptionProps } from '../OptionContainer/BaseSelectOptionContainerProps';
import styles from './BaseSelectCheckboxOption.module.scss';

export const BaseSelectCheckboxOption = <Option, OptionValue>(props: BaseSelectOptionProps<Option, OptionValue>) => {
  const { getOptionLabel, option, active, selected, ...restProps } = props;

  return (
    <li
      className={cn(styles.root, {
        [styles.active]: active,
      })}
      {...restProps}
    >
      <CheckboxField
        className={styles.checkboxField}
        label={getOptionLabel(option)}
        CheckboxProps={{
          checked: selected,
        }}
        LabelProps={{
          htmlFor: undefined,
        }}
      />
    </li>
  );
};
