import React, { FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';
import { DownloadDetailRouteQueryParams, FileDownloadDetailDataView } from 'features/file';
import { PublicHeader } from 'features/layout';
import styles from './DownloadDetailScreen.module.scss';

export const DownloadDetailScreen: FunctionComponent = () => {
  const { taskId } = useParams<DownloadDetailRouteQueryParams>();

  return (
    <div className={styles.root}>
      <PublicHeader />
      <div className={styles.detailInfo}>
        <FileDownloadDetailDataView taskId={taskId} />
      </div>
    </div>
  );
};
