import { FunctionComponent } from 'react';
import { favoriteGroupCreateActions } from 'features/favorites/slices';
import { favoritesAccessRuleService } from 'features/role';
import { useAppDispatch } from 'infrastructure/redux';
import { getFavoriteGroupDetailRoute } from 'infrastructure/routes/paths';
import { Link, LinkKind } from 'ui-library/components/Link';
import { FavoriteGroupAddNewCard } from '../FavoriteGroupAddNewCard/FavoriteGroupAddNewCard';
import { FavoriteGroupCard } from '../FavoriteGroupCard/FavoriteGroupCard';
import { FavoriteGroupCardListSkeleton } from '../FavoriteGroupCardListSkeleton/FavoriteGroupCardListSkeleton';
import { FavoriteGroupLoadingError } from '../FavoriteGroupLoadingError/FavoriteGroupLoadingError';
import styles from './FavoriteGroupCardList.module.scss';
import { FavoriteGroupCardListProps } from './FavoriteGroupCardListProps';

export const FavoriteGroupCardList: FunctionComponent<FavoriteGroupCardListProps> = (props) => {
  const dispatch = useAppDispatch();
  const { data, isFetching, isError, refetch } = props;

  const handleCreate = () => {
    dispatch(favoriteGroupCreateActions.open());
  };

  const getContent = () => {
    if (isFetching) {
      return <FavoriteGroupCardListSkeleton />;
    }

    return (
      <>
        {favoritesAccessRuleService.canCreate() && <FavoriteGroupAddNewCard onClick={handleCreate} />}
        {data?.content.map((x) => (
          <Link as={LinkKind.INNER} key={x.id} to={getFavoriteGroupDetailRoute(x.id)}>
            <FavoriteGroupCard group={x} />
          </Link>
        ))}
      </>
    );
  };

  if (isError) {
    return <FavoriteGroupLoadingError onReload={refetch} />;
  }

  return <div className={styles.wrap}>{getContent()}</div>;
};
