import { FunctionComponent } from 'react';
import { createSearchParams, useLocation } from 'react-router-dom';
import { FilePreviewLabel } from 'features/file';
import { ImageDetailRouteSearchQueryParams, ImageListBase, ImageListItem, ImageListSkeleton } from 'features/images';
import { getImageDetailRoute } from 'infrastructure/routes/paths';
import { ImageListProps } from './ImageListProps';

export const ImageList: FunctionComponent<ImageListProps> = (props) => {
  const { data, isFetching } = props;
  const location = useLocation();
  const params: ImageDetailRouteSearchQueryParams = { redirectUrl: location.pathname + location.search };

  return isFetching ? (
    <ImageListSkeleton />
  ) : (
    <ImageListBase>
      {data?.content.map((x) => (
        <ImageListItem
          key={x.id}
          image={x}
          imageLink={getImageDetailRoute(x.id) + `?${createSearchParams(params)}`}
          previewLabel={FilePreviewLabel.IMAGE_SEARCH}
          view='search'
        />
      ))}
    </ImageListBase>
  );
};
