import { useTranslateEnum } from 'infrastructure/i18n';
import { EnumSelectOption } from '../types';

type ReturnProps<T> = {
  options: Array<EnumSelectOption<T>>;
};

export const useEnumOptions = <T extends number | string>(
  enumName: string,
  enumObj: Record<string, T>,
): ReturnProps<T> => {
  const { translateValue } = useTranslateEnum(enumName, enumObj);

  const options: Array<EnumSelectOption<T>> = Object.values(enumObj).map((value) => ({
    value,
    label: translateValue(value),
  }));

  return { options };
};
