import { Controller, useFormContext } from 'react-hook-form';
import { ImageResponseDto } from 'features/images/imageEntity';
import { LayoutEditFormFields } from 'features/layouts/layoutEntity';
import { ImageEditList } from '../ImageEditList/ImageEditList';
import { ImagesAutocomplete } from '../ImagesAutocomplete';
import styles from './ImagesFormController.module.scss';
import { ImagesFormControllerProps } from './ImagesFormControllerProps';

export const ImagesFormController = (props: ImagesFormControllerProps) => {
  const { name } = props;
  const { control } = useFormContext();
  const { setValue, getValues } = useFormContext<LayoutEditFormFields>();

  const addFolderListItem = (value: ImageResponseDto) => {
    const concatArrayImages = getValues()?.images ?? [];
    setValue('images', [value].concat(concatArrayImages));
  };

  const removeFolderListItem = (image: ImageResponseDto) => {
    return setValue(
      'images',
      getValues()?.images?.filter((x) => x.id !== image.id),
    );
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value } }) => (
        <div className={styles.root}>
          <ImagesAutocomplete selectedImages={value} onInternalChange={addFolderListItem} />
          {value.map((x: ImageResponseDto) => (
            <ImageEditList image={x} key={x.id} onRemoveItem={removeFolderListItem} />
          ))}
        </div>
      )}
    />
  );
};
