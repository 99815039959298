import cn from 'classnames';
import React from 'react';
import { ICONS_CONFIG } from './Icon.config';
import styles from './Icon.module.scss';
import { IconProps } from './IconProps';

export const Icon = ({ name, title, tabIndex, size = 'normal', className, onClick }: IconProps) => {
  const classes = cn(styles.icon, className, styles[size]);

  const Component = ICONS_CONFIG[name];
  return <Component tabIndex={tabIndex} className={classes} title={title} onClick={onClick} />;
};
