import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from 'ui-library/components/Icon';
import { TableCell } from 'ui-library/components/Table/components/TableCell/TableCell';
import { TableRow } from 'ui-library/components/Table/components/TableRow/TableRow';
import styles from './AuthorsTableAddRow.module.scss';
import { AuthorsTableAddRowProps } from './AuthorsTableAddRowProps';

export const AuthorsTableAddRow: FunctionComponent<AuthorsTableAddRowProps> = (props) => {
  const { onClick } = props;
  const { t } = useTranslation();

  return (
    <tbody>
      <TableRow hover={true} className={styles.row}>
        <TableCell colSpan={5}>
          <div className={styles.root} onClick={onClick}>
            <Icon name='plus' />
            <span>{t('actions.add')}</span>
          </div>
        </TableCell>
      </TableRow>
    </tbody>
  );
};
