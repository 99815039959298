import React, { FunctionComponent } from 'react';
import { FolderCardBase } from 'features/v1/folder/FolderCardBase/FolderCardBase';
import { useRootFolderActions } from '../../hooks';
import { FolderCardBaseExternalProps } from '../FolderCardBase/FolderCardBaseProps';

export const FolderCard: FunctionComponent<FolderCardBaseExternalProps> = (props) => {
  const { folder } = props;
  const { actions } = useRootFolderActions(folder);

  return <FolderCardBase folder={folder} actions={actions} />;
};
