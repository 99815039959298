/* eslint-disable no-magic-numbers */
import { FunctionComponent } from 'react';
import { VirtuosoGrid } from 'react-virtuoso';
import { FolderArtifactListFooter } from 'features/fileNavigator/folders';
import { ArtifactLayoutList, ArtifactLayoutListItem } from 'features/fileNavigator/layouts';
import {
  FetchFolderLayoutsQueryData,
  LayoutCardSkeleton,
  LayoutEmptyChildren,
  LayoutLoadingError,
  LayoutResponseDto,
  LayoutScaleCard,
  useFetchFolderLayoutsQuery,
} from 'features/layouts';
import { useInfiniteLoading } from 'features/pagination';
import { SortDirection } from 'features/sorting';
import { NotDefinedParamException } from 'infrastructure/exceptions';
import { ListApiResponse } from 'infrastructure/network';
import { useAppSelector } from 'infrastructure/redux';
import styles from './LinkingArtifactLayoutsTab.module.scss';

export const LinkingArtifactLayoutsTab: FunctionComponent = () => {
  const { selectedFolderId } = useAppSelector((state) => state.linkingArtifacts);
  if (!selectedFolderId) {
    throw new NotDefinedParamException('Folder should be selected');
  }

  const { readMore, isError, isFetching, isLoading, refresh, data } = useInfiniteLoading<
    LayoutResponseDto,
    ListApiResponse<LayoutResponseDto>,
    FetchFolderLayoutsQueryData
  >(
    useFetchFolderLayoutsQuery,
    (state) => ({
      ...state,
      folderID: selectedFolderId,
      sortFields: ['number'],
      sortDirection: SortDirection.ASC,
    }),
    (result) => result,
    {
      pageSize: 40,
    },
    {
      skip: !selectedFolderId,
    },
  );

  const handleRefresh = () => {
    refresh();
  };

  const fetchData = data ?? [];
  const isEmpty = !isError && fetchData.length === 0 && !isFetching;

  if (isLoading) {
    return <FolderArtifactListFooter className={styles.root} />;
  }

  if (isEmpty) {
    return <LayoutEmptyChildren />;
  }

  return isError ? (
    <LayoutLoadingError onReload={handleRefresh} />
  ) : (
    <VirtuosoGrid
      className={styles.root}
      totalCount={fetchData.length}
      overscan={200}
      endReached={readMore}
      components={{
        Item: ArtifactLayoutListItem,
        List: ArtifactLayoutList,
        ScrollSeekPlaceholder: LayoutCardSkeleton,
      }}
      itemContent={(index) => <LayoutScaleCard layout={fetchData[index]} />}
      scrollSeekConfiguration={{
        enter: (velocity) => Math.abs(velocity) > 200,
        exit: (velocity) => Math.abs(velocity) < 30,
      }}
    />
  );
};
