import cn from 'classnames';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useFolderActionHandlers, useFolderDetailsActions } from 'features/folders';
import { ChooseCreateArtifactButton } from 'features/folders/components/ChooseCreateArtifactButton/ChooseCreateArtifactButton';
import { folderAccessRuleService } from 'features/role';
import { ActionsMenu } from 'ui-library/components/ActionsMenu';
import { ButtonKind } from 'ui-library/components/Button';
import { Icon } from 'ui-library/components/Icon';
import { IconButton } from 'ui-library/components/IconButton';
import { FolderFavoriteButtonGroup } from '../FolderFavoriteButtonGroup/FolderFavoriteButtonGroup';
import styles from './FolderDetailButtonGroup.module.scss';
import { FolderDetailButtonGroupProps } from './FolderDetailButtonGroupProps';

export const FolderDetailButtonGroup: FunctionComponent<FolderDetailButtonGroupProps> = (props) => {
  const { folder, className, partition } = props;
  const { t } = useTranslation();
  const { handleView, handleEdit, handleCreate, handleCreateImage, handleCreateLayout } =
    useFolderActionHandlers(folder);
  const { actions } = useFolderDetailsActions(folder, partition);

  return (
    <div className={cn(styles.root, className)}>
      <div className={styles.buttonGroup}>
        {folderAccessRuleService.canView() && <FolderFavoriteButtonGroup folder={folder} />}
        {folderAccessRuleService.canCreate() && (
          <ChooseCreateArtifactButton
            onCreateFolder={handleCreate}
            onCreateImage={handleCreateImage}
            onCreateLayout={handleCreateLayout}
            partition={partition}
          />
        )}
        {folderAccessRuleService.canView() && (
          <IconButton as={ButtonKind.BUTTON} title={t('actions.view')} onClick={handleView}>
            <Icon name='info' />
          </IconButton>
        )}
        {folderAccessRuleService.canEdit() && (
          <IconButton as={ButtonKind.BUTTON} title={t('actions.edit')} onClick={handleEdit}>
            <Icon name='edit' />
          </IconButton>
        )}
      </div>
      <ActionsMenu menuClassName={styles.actionsMenu} actions={actions} />
    </div>
  );
};
