import cn from 'classnames';
import React, { FunctionComponent } from 'react';
import { Card, CardProps } from 'ui-library/components/Card';
import styles from './FolderCardRoot.module.scss';

export const FolderCardRoot: FunctionComponent<CardProps> = (props) => {
  const { className, padding = 'none', ...rest } = props;

  return <Card className={cn(styles.root, className)} padding={padding} {...rest} />;
};
