import { Combobox } from '@headlessui/react';
import cn from 'classnames';
import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NotDefinedParamException } from 'infrastructure/exceptions';
import { AutocompleteEmptySearch } from '../Autocomplete/AutocompleteEmptySearch/AutocompleteEmptySearch';
import { BaseSelectOptionProps, getOptionValueDefault, MultiValue, OnChangeValue } from '../BaseSelect';
import { Spinner } from '../Spinner';
import styles from './Autocomplete.module.scss';
import { AutocompleteProps } from './AutocompleteProps';
import { CreateOptionLabel } from './CreateOptionLabel/CreateOptionLabel';
import { getDefaultComponents } from './getDefaultComponents';
import { MultiValueContainer } from './MultiValueContainer/MultiValueContainer';
import { SingleValueContainer } from './SingleValueContainer/SingleValueContainer';

const isCreateOption = Symbol('isCreateOption');

export const Autocomplete = <Option, OptionValue, IsMulti extends boolean = false>(
  props: AutocompleteProps<Option, OptionValue, IsMulti>,
) => {
  const { t } = useTranslation();
  const {
    className,
    value,
    options,
    placeholder,
    getOptionLabel,
    getOptionValue = getOptionValueDefault,
    onChange = () => {},
    onChangeInput,
    InputProps,
    loading,
    noOptionsMessage = t('uiLibrary.autocomplete.noOptionsMessage'),
    showNoOptionsMessage,
    multiple,
    disabled,
    hideOptions,
    components: componentOverrides,
    variant,
    oneLine,
    canUnselect,
    chipsData,
    onCreate,
    createLabel,
    hideArrowIcon,
    comboboxClassName,
    ...restProps
  } = props;

  if (onCreate && !createLabel) {
    throw new NotDefinedParamException('You must define a createLabel');
  }

  const components = useRef(getDefaultComponents(componentOverrides));
  const OptionComponent = components.current.Option;

  const [inputValue, setInputValue] = useState('');
  const onChangeInputInternal = (value: string) => {
    setInputValue(value);
    onChangeInput?.(value);
  };

  const onChangeInternal = (value: OptionValue | typeof isCreateOption) => {
    if (value === isCreateOption) {
      onCreate?.();
    } else {
      onChange(value as OnChangeValue<OptionValue, IsMulti>);

      if (multiple) {
        document.body.click(); // Скрываем блок Options
      }
    }
  };

  const filteredOptions = useMemo(() => {
    if (multiple) {
      return options.filter((option) => {
        return !(value as Array<OptionValue>).includes(getOptionValue(option) as OptionValue);
      });
    } else {
      return options;
    }
  }, [getOptionValue, multiple, options, value]);

  const existsOptions = filteredOptions.length > 0;
  const canCreate = onCreate !== undefined;
  const showOptions = !loading && (existsOptions || canCreate);

  return (
    <Combobox
      value={value}
      onChange={onChangeInternal as never}
      multiple={multiple as never}
      disabled={disabled}
      {...restProps}
    >
      {({ open }) => (
        <div className={cn(styles.root, className)}>
          {multiple ? (
            <MultiValueContainer
              disabled={disabled}
              options={options}
              placeholder={placeholder}
              onChange={onChange as BaseSelectOptionProps<Option, OptionValue>['onChange']}
              getOptionLabel={getOptionLabel}
              getOptionValue={getOptionValue}
              onChangeInput={onChangeInputInternal}
              open={open}
              value={value as MultiValue<OptionValue>}
              variant={variant}
              oneLine={oneLine}
              chipsData={chipsData}
            />
          ) : (
            <SingleValueContainer
              disabled={disabled}
              placeholder={placeholder}
              options={options}
              getOptionLabel={getOptionLabel}
              getOptionValue={getOptionValue}
              onChangeInput={onChangeInputInternal}
              open={open}
              variant={variant}
              InputProps={InputProps}
              hideArrowIcon={hideArrowIcon}
            />
          )}
          {!hideOptions && (
            <Combobox.Options static={loading} className={cn(styles.options, comboboxClassName)}>
              {loading && inputValue && (
                <div className={styles.loader}>
                  <Spinner color='secondary' />
                </div>
              )}

              {showOptions && (
                <>
                  {onCreate && (
                    <OptionComponent
                      option={null as never}
                      getOptionLabel={() => <CreateOptionLabel label={createLabel as string} />}
                      getOptionValue={() => isCreateOption}
                    />
                  )}
                  {canUnselect && (
                    <OptionComponent
                      option={null as never}
                      getOptionLabel={() => t('form.unselect')}
                      getOptionValue={() => null}
                    />
                  )}
                  {filteredOptions.map((option, index) => (
                    <OptionComponent
                      key={index}
                      option={option}
                      getOptionLabel={getOptionLabel}
                      getOptionValue={getOptionValue}
                    />
                  ))}
                </>
              )}

              {showNoOptionsMessage && !loading && filteredOptions.length === 0 && inputValue && (
                <div className={styles.noOptionsMessage}>{<AutocompleteEmptySearch message={noOptionsMessage} />}</div>
              )}
            </Combobox.Options>
          )}
        </div>
      )}
    </Combobox>
  );
};
