import { SyntheticEvent } from 'react';
import { addToFavoritesActions, removeFromFavoritesActions } from 'features/favorites';
import { createFileDownloadTaskActions, shareFileDownloadTaskActions } from 'features/file';
import { folderDeleteEmptyActions, folderEditActions, FolderResponseDto, folderViewActions } from 'features/folders';
import { movingArtifactsActions } from 'features/movingArtifacts';
import { useAppDispatch } from 'infrastructure/redux';

type UseRootFolderActionHandlersOptions = {
  folder: FolderResponseDto;
  parentId: string | undefined;
};

export const useRootFolderActionHandlers = (options: UseRootFolderActionHandlersOptions) => {
  const { folder, parentId } = options;
  const dispatch = useAppDispatch();

  const handleEdit = (event: SyntheticEvent) => {
    event.stopPropagation();
    event.preventDefault();

    dispatch(folderEditActions.selectFolder(folder?.id));
    dispatch(folderEditActions.open());
  };

  const handleMove = (event: SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    dispatch(
      movingArtifactsActions.setMovingArtifacts([
        {
          entityId: folder.id,
          parentID: folder.parentID,
        },
      ]),
    );
    dispatch(movingArtifactsActions.setMovingType('rootFolder'));
    dispatch(movingArtifactsActions.selectFolderId(undefined));
    dispatch(movingArtifactsActions.selectPartition(folder.partition));
    dispatch(movingArtifactsActions.open());
  };

  const handleView = (event: SyntheticEvent) => {
    event.stopPropagation();
    event.preventDefault();

    dispatch(folderViewActions.selectFolder(folder?.id));
    dispatch(folderViewActions.open());
  };

  const handleAddToFavorite = (event: SyntheticEvent) => {
    event.stopPropagation();
    event.preventDefault();

    dispatch(
      addToFavoritesActions.setFavoriteArtifact({
        entityId: folder.id,
        type: 'folder',
        parentId,
        isFavorite: folder.isFavourite,
      }),
    );
    dispatch(addToFavoritesActions.open());
  };

  const handleRemoveFromFavorite = (event: SyntheticEvent) => {
    event.stopPropagation();
    event.preventDefault();

    dispatch(
      removeFromFavoritesActions.setFavoriteArtifact({
        entityId: folder.id,
        type: 'folder',
        parentId,
        isFavorite: folder.isFavourite,
      }),
    );
    dispatch(removeFromFavoritesActions.open());
  };

  const handleDelete = (event: SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    dispatch(folderDeleteEmptyActions.setFolderForDeletion(folder));
    dispatch(folderDeleteEmptyActions.open());
  };

  const handleDownload = (event: SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    dispatch(
      createFileDownloadTaskActions.setFileArtifact({
        entityId: folder.id,
        type: 'folder',
      }),
    );
    dispatch(createFileDownloadTaskActions.open());
  };

  const handleShare = (event: SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    dispatch(
      shareFileDownloadTaskActions.setFileArtifact({
        entityId: folder.id,
        type: 'folder',
      }),
    );
    dispatch(shareFileDownloadTaskActions.open());
  };

  return {
    handleShare,
    handleDownload,
    handleDelete,
    handleMove,
    handleEdit,
    handleAddToFavorite,
    handleRemoveFromFavorite,
    handleView,
  };
};
