import cn from 'classnames';
import { FunctionComponent, PropsWithChildren, useRef } from 'react';
import { InputLayoutVariant } from 'ui-library/components/InputLayout';
import { useShadowCorner } from '../hooks/useShadowCorner';
import styles from './BaseSelectMultiValueContainer.module.scss';
import { BaseSelectMultiValueContainerProps } from './BaseSelectMultiValueContainerProps';

export const BaseSelectMultiValueContainer: FunctionComponent<PropsWithChildren<BaseSelectMultiValueContainerProps>> = (
  props,
) => {
  const { oneLine, variant, children } = props;

  const controlRef = useRef<HTMLDivElement>(null);
  const shadowed = useShadowCorner(controlRef);

  return (
    <div
      ref={controlRef}
      className={cn(styles.control, {
        [styles.controlMultiline]: !oneLine,
        [styles.controlOneLine]: oneLine,
        [styles.controlOneLineStandard]: oneLine && variant === InputLayoutVariant.STANDARD,
        [styles.controlOneLineStandardShadowed]: oneLine && variant === InputLayoutVariant.STANDARD && shadowed,
      })}
    >
      {children}
    </div>
  );
};
