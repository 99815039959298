import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'ui-library/components/Modal';
import { FileDownloadAgreementContent } from '../FileDownloadAgreementContent/FileDownloadAgreementContent';
import { FileDownloadAgreementViewInfoProps } from './FileDownloadAgreementViewInfoProps';

export const FileDownloadAgreementViewInfo: FunctionComponent<FileDownloadAgreementViewInfoProps> = (props) => {
  const { isOpen, close } = props;
  const { t } = useTranslation();

  return (
    <Modal open={isOpen} onClose={close}>
      <Modal.Title>{t('files.downloadAgreement.title')}</Modal.Title>
      <Modal.Content>
        <FileDownloadAgreementContent />
      </Modal.Content>
    </Modal>
  );
};
