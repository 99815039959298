import cn from 'classnames';
import { useContext, useState } from 'react';
import { TagsManageContext } from 'features/tags/contexts';
import { useTagActions } from 'features/tags/hooks';
import { useLanguageRelevantData } from 'infrastructure/i18n';
import { ActionList } from 'ui-library/components/ActionList';
import { Popover, PopoverContent, PopoverTrigger } from 'ui-library/components/Popover';
import { TagsChipLabel } from '../TagsChipLabel';
import styles from './TagsChip.module.scss';
import { TagsChipProps } from './TagsChipProps';

export const TagsChip = (props: TagsChipProps) => {
  const { tag, disableActions: originDisableActions } = props;
  const [isOpen, setIsOpen] = useState(false);
  const { matchProp } = useLanguageRelevantData();

  const { actions } = useTagActions(tag);
  const { isExtended, isRemoved } = useContext(TagsManageContext);

  const disableActions = originDisableActions;

  return (
    <div
      className={cn(styles.root, {
        [styles.focused]: isOpen,
        [styles.extended]: isExtended(tag),
        [styles.removed]: isRemoved(tag),
      })}
    >
      {disableActions ? (
        <TagsChipLabel focused={isOpen} extended={isExtended(tag)} removed={isRemoved(tag)}>
          {matchProp(tag.nameRu, tag.nameEn)}
        </TagsChipLabel>
      ) : (
        <Popover
          behavior='click'
          placement='bottom-start'
          offset={{
            mainAxis: 8,
            crossAxis: -8,
          }}
          onOpenChange={setIsOpen}
          open={isOpen}
        >
          <PopoverTrigger>
            <TagsChipLabel withAction focused={isOpen} extended={isExtended(tag)} removed={isRemoved(tag)}>
              {matchProp(tag.nameRu, tag.nameEn)}
            </TagsChipLabel>
          </PopoverTrigger>
          <PopoverContent className={styles.menu}>
            {({ close }) => <ActionList actions={actions} onClose={close} />}
          </PopoverContent>
        </Popover>
      )}
    </div>
  );
};
