import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { FileType, useUploadFile } from 'features/file';
import {
  FolderCreateRequestDto,
  FolderEditForm,
  FolderEditFormFields,
  foldersApi,
  useCreateFolderMutation,
} from 'features/folders';
import { ModalSubmitActions } from 'features/modal';
import { NotDefinedParamException } from 'infrastructure/exceptions';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux';
import { Modal } from 'ui-library/components/Modal';
import { folderCreateActions } from '../../slices/folderCreateSlice';

export const FolderCreate: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const { isOpen, parentFolderId, selectedPartition } = useAppSelector((state) => state.folderCreate);
  const uploadFile = useUploadFile(FileType.FOLDER);
  const [createFolderMutation] = useCreateFolderMutation();

  const { t } = useTranslation();

  const handleClose = () => {
    dispatch(folderCreateActions.close());
    dispatch(folderCreateActions.selectPartition(undefined));
  };

  const onSubmit = async (values: FolderEditFormFields) => {
    const { fileBox } = values;

    if (!selectedPartition) {
      throw new NotDefinedParamException('Folder Partition');
    }
    const fileID = await uploadFile(fileBox.file);

    const dto: FolderCreateRequestDto = {
      fileID,
      captionRu: values.captionRu,
      captionEn: values.captionEn,
      descriptionRu: values.descriptionRu,
      descriptionEn: values.descriptionEn,
      status: values.status,
      tags: values.tags?.map((item) => item.id),
      tagsExtend: values.tagsExtend,
      parentID: parentFolderId,
      materialsKinds: values.materialsKinds,
      partition: selectedPartition,
    };

    await createFolderMutation(dto).unwrap();

    dispatch(
      foldersApi.util.invalidateTags([
        {
          type: 'FolderChildren',
          id: parentFolderId,
        },
      ]),
    );

    handleClose();
  };

  const defaultValues: Partial<FolderEditFormFields> = {
    materialsKinds: [],
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Modal.Title>{t('folders.createFolder')}</Modal.Title>
      <FolderEditForm
        isCreate
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        renderActions={({ isSubmitting }) => <ModalSubmitActions loading={isSubmitting} onCancel={handleClose} />}
      />
    </Modal>
  );
};
