import * as yup from 'yup';
import { buildLocalization } from 'infrastructure/validation/yupLocale';
import { validateFileSize, validateFileType } from './fileValidation';

export const yupTestValidateFileSize = (maxFileSizeMB: number): yup.TestConfig<File | null | undefined> => {
  return {
    name: 'fileSize',
    message: buildLocalization('validation.file.size', {
      sizeMB: maxFileSizeMB,
    }),
    test: (value) => {
      if (!value) return false;

      return validateFileSize(value, maxFileSizeMB);
    },
  };
};

export const yupTestValidateFileType = (
  supportedMimeTypes: Record<string, string>,
): yup.TestConfig<File | null | undefined> => {
  return {
    name: 'fileType',
    message: buildLocalization('validation.file.type'),
    test: (value) => {
      if (!value) return false;

      return validateFileType(value, supportedMimeTypes);
    },
  };
};
