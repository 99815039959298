export type PaginationState = {
  currentPage: number;
  pageSize: number;
};

export const FIRST_PAGE = 1;
export const DEFAULT_PAGE_SIZE = 50;

export const getDefaultPaginationState = (): PaginationState => ({
  currentPage: FIRST_PAGE,
  pageSize: DEFAULT_PAGE_SIZE,
});
