import cn from 'classnames';
import React, { FunctionComponent } from 'react';
import styles from './ListItemIcon.module.scss';
import { ListItemIconProps } from './ListItemIconProps';

/**
 * @deprecated
 */
export const ListItemIcon: FunctionComponent<ListItemIconProps> = (props) => {
  const { iconPosition = 'center', children, className } = props;
  return <span className={cn(styles.icon, styles[`icon-${iconPosition}`], className)}>{children}</span>;
};
