import React, { FunctionComponent } from 'react';
import { CardVariant } from 'ui-library/components/Card';
import { Skeleton } from 'ui-library/components/Skeleton';
import { ImageCardRoot } from '../ImagesCardRoot/ImageCardRoot';
import styles from './ImageCardSkeleton.module.scss';

export const ImageCardSkeleton: FunctionComponent = () => {
  return (
    <ImageCardRoot className={styles.card} variant={CardVariant.TRANSPARENT}>
      <Skeleton className={styles.skeleton} width='100%' />
    </ImageCardRoot>
  );
};
