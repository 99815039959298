import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getDefaultModalState, getModalReducers, ModalState } from 'features/modal';
import { ReferenceBook } from 'features/referenceBook/referenceBookEntity';

export type AuthorEditState = ModalState & {
  author?: ReferenceBook;
};

const initialState: AuthorEditState = {
  ...getDefaultModalState(),
};

const authorEditSlice = createSlice({
  name: 'authorEdit',
  initialState,
  reducers: {
    ...getModalReducers<AuthorEditState>((state) => state),
    setAuthor: (state, action: PayloadAction<ReferenceBook | undefined>) => {
      state.author = action.payload;
    },
  },
});

export const authorEditActions = authorEditSlice.actions;
export const authorEditReducer = authorEditSlice.reducer;
