import cn from 'classnames';
import { ForwardedRef, forwardRef } from 'react';
import { BaseSelectOptionProps } from '../OptionContainer/BaseSelectOptionContainerProps';
import styles from './BaseSelectLabelOption.module.scss';

/**
 * @deprecated
 */
const BaseSelectLabelOptionWithoutRef = <Option, OptionValue>(
  props: BaseSelectOptionProps<Option, OptionValue>,
  ref: ForwardedRef<HTMLLIElement>,
) => {
  const { getOptionLabel, option, active, selected, ...restProps } = props;

  return (
    <li
      ref={ref}
      className={cn(styles.root, {
        [styles.active]: active,
        [styles.selected]: selected && option,
      })}
      {...restProps}
    >
      {getOptionLabel(option)}
    </li>
  );
};

export const BaseSelectLabelOption = forwardRef(BaseSelectLabelOptionWithoutRef);
