import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'ui-library/components/Modal';
import { FileDownloadAgreementForm } from '../FileDownloadAgreementForm/FileDownloadAgreementForm';
import { FileDownloadAgreementProps } from './FileDownloadAgreementProps';

export const FileDownloadAgreement: FunctionComponent<FileDownloadAgreementProps> = (props) => {
  const { t } = useTranslation();
  const { isOpen, handleAgree, close } = props;

  const handleClose = () => {
    close();
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Modal.Title>{t('files.downloadAgreement.title')}</Modal.Title>
      <FileDownloadAgreementForm handleAgree={handleAgree} handleClose={handleClose} />
    </Modal>
  );
};
