import { skipToken } from '@reduxjs/toolkit/dist/query';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { foldersApi, useFetchFolderDetailQuery } from 'features/folders';
import { layoutsApi } from 'features/layouts/layoutsApi';
import { searchApi } from 'features/search';
import { NotDefinedParamException } from 'infrastructure/exceptions';
import { useLanguageRelevantData } from 'infrastructure/i18n';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux';
import { getFolderDetailRoute } from 'infrastructure/routes/paths';
import { Link, LinkKind, LinkVariant } from 'ui-library/v1/components/Link';
import styles from './useCreateLayout.module.scss';

export const useCreateLayout = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { matchProp } = useLanguageRelevantData();
  const { parentFolderId } = useAppSelector((state) => state.layoutCreate);
  const { data: selectedFolderData } = useFetchFolderDetailQuery(parentFolderId ?? skipToken);

  const buildSuccessToast = (content: JSX.Element) => {
    toast.success(<div className={styles.notification}>{content}</div>);
  };

  const buildErrorToast = (content: JSX.Element) => {
    toast.error(<div className={styles.notification}>{content}</div>);
  };

  const getToastLink = () => {
    if (!selectedFolderData) {
      throw new NotDefinedParamException('Folder');
    }

    return (
      <Link
        as={LinkKind.INNER}
        variant={LinkVariant.STYLED}
        className={styles.link}
        to={getFolderDetailRoute(selectedFolderData?.id, selectedFolderData?.partition)}
      >
        «{matchProp(selectedFolderData?.captionRu, selectedFolderData?.captionEn)}»
      </Link>
    );
  };

  const handleCreateLayout = () => {
    dispatch(foldersApi.util.invalidateTags(['SearchFolder']));
    dispatch(searchApi.util.invalidateTags(['SearchResult']));

    dispatch(
      foldersApi.util.invalidateTags([
        {
          type: 'Folder',
          id: selectedFolderData?.id,
        },
        {
          type: 'Folder',
          id: selectedFolderData?.parentID,
        },
        {
          type: 'FolderChildren',
          id: selectedFolderData?.id,
        },
      ]),
    );

    dispatch(layoutsApi.util.invalidateTags([{ type: 'Layout', id: selectedFolderData?.parentID }]));

    if (selectedFolderData?.isRoot) {
      buildSuccessToast(
        <>
          {t('createArtifacts.success.createInRootLayout')}
          &nbsp; {getToastLink()}
        </>,
      );
    } else {
      buildSuccessToast(
        <>
          {t('createArtifacts.success.createInFolderLayout')}
          &nbsp; {getToastLink()}
        </>,
      );
    }
  };

  const handleCreateLayoutError = () => {
    buildErrorToast(<>{t('createArtifacts.error.errorCreateLayout')}</>);
  };

  return {
    handleCreateLayout,
    handleCreateLayoutError,
  };
};
