import { FunctionComponent } from 'react';
import { UserTable, UserLoadingError, useGetUsersQuery, userListActions, userCreateActions } from 'features/users';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux';
import { Pagination } from 'ui-library/components/Pagination';
import styles from './UsersDataView.module.scss';

export const UsersDataView: FunctionComponent = () => {
  const { currentPage, pageSize } = useAppSelector((state) => state.userList.paginationData);
  const dispatch = useAppDispatch();
  const query = useGetUsersQuery({
    currentPage,
    pageSize,
  });

  const handleCreate = () => {
    dispatch(userCreateActions.open());
  };

  return (
    <div className={styles.root}>
      <div className={styles.listWrap}>
        {query.isError ? (
          <UserLoadingError onReload={query.refetch} />
        ) : (
          <UserTable onCreate={handleCreate} {...query} />
        )}
      </div>
      <div className={styles.paginationWrap}>
        <Pagination
          totalItems={query.data?.totalCount}
          pageSize={pageSize}
          onPageChanged={(page) => dispatch(userListActions.pageChanged({ page }))}
          currentPage={currentPage}
          scrollToStartAfterPageChanged
        />
      </div>
    </div>
  );
};
