import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { userAccessRuleService } from 'features/role';
import { UserBlock, UserCreate, UserEdit, UserUnblock } from 'features/users';
import { UsersDataView } from './components';
import styles from './UsersScreen.module.scss';

export const UsersScreen: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.wrap}>
        <h1 className={styles.title}>{t('users.title')}</h1>
        {userAccessRuleService.canViewUI() && <UsersDataView />}
      </div>
      <UserBlock />
      <UserUnblock />
      <UserCreate />
      <UserEdit />
    </>
  );
};
