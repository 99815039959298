import { createApi } from '@reduxjs/toolkit/query/react';
import jwt_decode from 'jwt-decode';
import { authService } from 'features/auth';
import { activeFileDownloadTasksActions } from 'features/file';
import { fileDownloadService } from 'features/file/services';
import { toggleMuseumActions, toggleMuseumService } from 'features/toggleMuseum';
import { UserResponseDto } from 'features/users';
import { fetchBaseAuthQuery, fetchBaseQuery, HTTP_METHODS, URL_FACTORY } from 'infrastructure/network';
import { AuthRequestDto, AuthResponseDto, AuthTokenData, RefreshRequestDto } from './authEntity';
import { authActions } from './authSlice';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery(),
  endpoints: (builder) => ({
    signIn: builder.mutation<AuthResponseDto, AuthRequestDto>({
      query: (data) => ({
        url: URL_FACTORY.AUTH,
        method: HTTP_METHODS.POST,
        body: data,
      }),
      async onQueryStarted(body, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        const decodedToken = jwt_decode<AuthTokenData>(data.token);
        authService.setAccessToken(data.token);
        authService.setRefreshToken(data.refreshToken);
        authService.setTokenData(decodedToken);
        dispatch(authActions.signIn());
      },
    }),
    refreshToken: builder.mutation<AuthResponseDto, RefreshRequestDto>({
      query: (data) => ({
        url: URL_FACTORY.REFRESH,
        method: HTTP_METHODS.POST,
        body: data,
      }),
      async onQueryStarted(body, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        const decodedToken = jwt_decode<AuthTokenData>(data.token);

        authService.setAccessToken(data.token);
        authService.setRefreshToken(data.refreshToken);
        authService.setTokenData(decodedToken);
      },
    }),
    getCurrentUser: builder.query<UserResponseDto, void>({
      queryFn: (_, api, extraOptions) =>
        fetchBaseAuthQuery<UserResponseDto>()(
          {
            url: `${URL_FACTORY.USERS.ROOT}/${authService.tokenData?.user_id}`,
          },
          api,
          extraOptions,
        ),
    }),
    signOut: builder.query<unknown, void>({
      queryFn: (_, api, extraOptions) =>
        fetchBaseAuthQuery()(
          {
            url: URL_FACTORY.AUTH,
            method: HTTP_METHODS.DELETE,
          },
          api,
          extraOptions,
        ),
      async onQueryStarted(body, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
        } finally {
          authService.clear();
          fileDownloadService.clear();
          toggleMuseumService.clear();
          dispatch(activeFileDownloadTasksActions.close());
          dispatch(toggleMuseumActions.dispose());
        }
      },
    }),
  }),
});

export const {
  useSignInMutation,
  useSignOutQuery,
  useRefreshTokenMutation,
  useGetCurrentUserQuery,
  useLazyGetCurrentUserQuery,
  useLazySignOutQuery,
} = authApi;
