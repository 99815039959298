import { FunctionComponent } from 'react';
import { Spinner } from '../../Spinner';
import { ButtonVariant } from '../ButtonProps';
import { buttonSpinnerColor } from '../ButtonSpinnerColor';
import styles from './ButtonContent.module.scss';
import { ButtonContentProps } from './ButtonContentProps';

export const ButtonContent: FunctionComponent<ButtonContentProps> = (props) => {
  const {
    variant = ButtonVariant.MAIN,
    startIcon: startIconProp,
    children,
    loading,
    loadingVariant = 'replace',
  } = props;

  let startIcon = startIconProp && <span className={styles.startIcon}>{startIconProp}</span>;

  if (loading) {
    if (loadingVariant === 'replace') {
      return <Spinner color={buttonSpinnerColor[variant]} />;
    } else if (loadingVariant === 'startIcon') {
      startIcon = (
        <span className={styles.startIcon}>
          <Spinner color={buttonSpinnerColor[variant]} />
        </span>
      );
    }
  }

  return (
    <>
      {startIcon}
      {children}
    </>
  );
};
