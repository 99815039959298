import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getDefaultModalState, getModalReducers, ModalState } from 'features/modal';

export type AlertState = ModalState & {
  message: string | undefined;
};

const initialState: AlertState = {
  ...getDefaultModalState(),
  message: undefined,
};

const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    ...getModalReducers<AlertState>((state) => state),
    setMessage: (state, action: PayloadAction<string | undefined>) => {
      state.message = action.payload;
    },
  },
});

export const alertActions = alertSlice.actions;
export const alertReducer = alertSlice.reducer;
