import { FunctionComponent } from 'react';
import { useLocation } from 'react-router';
import { authService } from 'features/auth';
import { ProtectedLayout } from 'features/layout/v1/components/Layout';
import { useAppSelector } from 'infrastructure/redux/reduxHooks';
import { SignOutScreen } from 'screens/auth';
import { AccessDeniedScreen } from 'screens/common';
import { ProtectedRouteWrapperProps } from './protectedRouteWrapperProps';

export const ProtectedRouteWrapper: FunctionComponent<ProtectedRouteWrapperProps> = (props) => {
  const { Layout = ProtectedLayout, screen, className } = props;
  const isAuthorized = useAppSelector((state) => state.auth.isAuthorized);
  const location = useLocation();

  const saveHistoryPath = (): void => authService.setLastAuthorizedPath(location.pathname ?? '');

  if (!isAuthorized) {
    saveHistoryPath();

    return <SignOutScreen />;
  }

  if (!props.hasAccess()) {
    saveHistoryPath();

    return <AccessDeniedScreen />;
  }

  return Layout ? <Layout className={className}>{screen}</Layout> : <>{screen}</>;
};
