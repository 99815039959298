import cn from 'classnames';
import { FunctionComponent } from 'react';
import { useTranslateEnum } from 'infrastructure/i18n';
import styles from './EnumCellRenderer.module.scss';
import { EnumCellRendererProps } from './EnumCellRendererProps';

export const EnumCellRenderer: FunctionComponent<EnumCellRendererProps> = (props) => {
  const { value, enumObject, enumName, className, textClassName } = props;
  const { translateValue } = useTranslateEnum(enumName, enumObject);

  return (
    <div className={cn(styles.root, className)}>
      <span className={cn(styles.text, textClassName)}>{translateValue(value)}</span>
    </div>
  );
};
