import { ChangeEvent, FunctionComponent, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { uploadMaxFileSizeMB, uploadSupportedMimeImageTypes, validateFile } from 'features/file';
import { FileInputField } from 'ui-library/components/FileInputField';
import { FilePreview } from '../FilePreview/FilePreview';
import styles from './FileUploader.module.scss';
import { FileUploaderProps } from './FileUploaderProps';

export const FileUploader: FunctionComponent<FileUploaderProps> = (props) => {
  const {
    previewUrl,
    defaultSelectedFile,
    onChange,
    onError,
    error,
    maxFileSizeMB = uploadMaxFileSizeMB,
    supportedMimeTypes = uploadSupportedMimeImageTypes,
  } = props;
  const { t } = useTranslation();
  const [selectedFile, setSelectedFile] = useState<File | undefined>(defaultSelectedFile);
  const [previewLoading, setPreviewLoading] = useState(defaultSelectedFile === undefined ? false : true);

  const onSelectFile = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (!files || (files && !files[0])) {
      return;
    }

    const file = files[0];

    onChange(file);

    const isValidFile = validateFile(supportedMimeTypes, maxFileSizeMB);

    if (isValidFile(file)) {
      setPreviewLoading(true);
      setSelectedFile(file);
    } else {
      toast.error(t('file.alert.errorUpload'));
      setSelectedFile(undefined);
    }
  };

  const handleError = () => {
    setPreviewLoading(false);
    setSelectedFile(undefined);
    onChange(undefined);
    onError?.();
  };

  const inputAcceptTypes = Object.values(uploadSupportedMimeImageTypes).join(',');
  const isFileSelected = selectedFile || previewUrl;
  const hasPreview = isFileSelected && !previewLoading;

  return (
    <>
      <div className={styles.root}>
        <FilePreview
          file={selectedFile}
          previewUrl={previewUrl}
          onReady={() => setPreviewLoading(false)}
          onError={handleError}
        />

        <FileInputField
          error={error}
          label={hasPreview ? t('file.uploader.changeLabel') : t('file.uploader.label')}
          FileInputProps={{
            variant: hasPreview ? 'vertical' : 'horizontal',
            description: t('file.uploader.requirements', {
              sizeMB: maxFileSizeMB,
            }),
            InputProps: {
              onChange: onSelectFile,
              accept: inputAcceptTypes,
              title: isFileSelected ? undefined : t('file.uploader.empty'),
            },
            ButtonProps: {
              loading: previewLoading,
              loadingVariant: 'startIcon',
            },
          }}
        />
      </div>
    </>
  );
};
