import cn from 'classnames';
import React, { FunctionComponent } from 'react';
import { Card, CardProps, CardVariant } from 'ui-library/components/Card';
import styles from './ImageCardRoot.module.scss';

export const CARD_IMAGE_HEIGHT = 322;

export const ImageCardRoot: FunctionComponent<CardProps> = (props) => {
  const { className, ...rest } = props;

  return <Card className={cn(styles.root, className)} variant={CardVariant.TRANSPARENT} {...rest} padding={'none'} />;
};
