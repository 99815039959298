import { FunctionComponent } from 'react';
import { favoriteFolderListActions, useFetchFavoritesQuery } from 'features/favorites';
import { FolderLoadingError } from 'features/folders';
import { ListLayout } from 'features/list';
import { calculateOffset } from 'features/pagination';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux';
import { Pagination } from 'ui-library/components/Pagination';
import { FolderCardList } from '../FolderCardList/FolderCardList';
import { FolderTable } from '../FolderTable/FolderTable';
import styles from './FolderDataView.module.scss';
import { FolderDataViewProps } from './FolderDataViewProps';

export const FolderDataView: FunctionComponent<FolderDataViewProps> = (props) => {
  const { groupId } = props;
  const dispatch = useAppDispatch();
  const layoutType = useAppSelector((state) => state.favoriteFolderList.layoutType);
  const { sortDirection, sortField } = useAppSelector((state) => state.favoriteFolderList.sortingInfo);
  const { currentPage, pageSize } = useAppSelector((state) => state.favoriteFolderList.paginationData);

  const query = useFetchFavoritesQuery({
    groupID: groupId,
    items: [
      {
        type: 'folder',
        limit: pageSize,
        sortDirection,
        sortFields: [sortField],
        offset: calculateOffset(currentPage, pageSize),
      },
    ],
  });
  const listData = query.data?.items[0].folder;
  const totalCount = listData?.totalCount;

  const getContent = () => {
    if (query.isError) {
      return <FolderLoadingError onReload={query.refetch} />;
    }

    return (
      <>
        {layoutType === ListLayout.CARD && <FolderCardList {...query} data={listData} groupId={groupId} />}
        {layoutType === ListLayout.TABLE && <FolderTable {...query} data={listData} groupId={groupId} />}
      </>
    );
  };

  return (
    <div className={styles.root}>
      <div className={styles.listWrap}>{getContent()}</div>
      <div className={styles.paginationWrap}>
        <Pagination
          totalItems={totalCount}
          pageSize={pageSize}
          onPageChanged={(page) => dispatch(favoriteFolderListActions.pageChanged({ page }))}
          currentPage={currentPage}
          scrollToStartAfterPageChanged
        />
      </div>
    </div>
  );
};
