import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { EmptySearch } from 'features/search';
import styles from './SearchScreenEmptyQuery.module.scss';

export const SearchScreenEmptyQuery: FunctionComponent = () => {
  const { t } = useTranslation();

  return <EmptySearch className={styles.placeholder} message={t('search.emptyQuery')} />;
};
