import { createApi } from '@reduxjs/toolkit/query/react';
import { listQuery } from 'features/list';
import {
  fetchBaseAuthQuery,
  HTTP_METHODS,
  ListApiFetchArgs,
  ListApiResponse,
  URL_FACTORY,
} from 'infrastructure/network';
import { UserCreateRequestDto, UserEditRequestDto, UserResponseDto } from './userEntity';

export const usersApi = createApi({
  reducerPath: 'usersApi',
  tagTypes: ['UserList'],
  baseQuery: fetchBaseAuthQuery(),
  endpoints: (builder) => ({
    getUser: builder.query<UserResponseDto, string>({
      query: (userID) => URL_FACTORY.USERS.DETAIL(userID),
    }),
    getUsers: builder.query<ListApiResponse<UserResponseDto>, ListApiFetchArgs<UserResponseDto>>({
      query: (params) => listQuery({ url: URL_FACTORY.USERS.ROOT, params }),
      providesTags: ['UserList'],
    }),
    editUser: builder.mutation<UserResponseDto, UserEditRequestDto>({
      query: ({ userID, ...restData }) => ({
        url: URL_FACTORY.USERS.DETAIL(userID),
        method: HTTP_METHODS.PATCH,
        body: restData,
      }),
      invalidatesTags: (arg, error) => (error ? [] : ['UserList']),
    }),
    createUser: builder.mutation<UserResponseDto, UserCreateRequestDto>({
      query: (params) => ({
        url: URL_FACTORY.USERS.ROOT,
        method: HTTP_METHODS.POST,
        body: params,
      }),
      invalidatesTags: (arg, error) => (error ? [] : ['UserList']),
    }),
  }),
});

export const { useGetUserQuery, useGetUsersQuery, useEditUserMutation, useCreateUserMutation } = usersApi;
