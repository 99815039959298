import { FunctionComponent } from 'react';
import { ImageDetailDataView } from './components/ImageDetailDataView/ImageDetailDataView';

export const ImageDetailScreen: FunctionComponent = () => {
  return (
    <>
      <ImageDetailDataView />
    </>
  );
};
