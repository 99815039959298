import { FileBox, FileResponseDto } from 'features/file';
import { FolderResponseDto, MaterialsKind } from 'features/folders';
import { ImageResponseDto } from 'features/images';
import { TagResponseDto } from 'features/tags';
import { ListApiFetchArgs } from 'infrastructure/network';

export type LayoutResponseDto = {
  id: string;
  foldersCount: number;
  number: number;
  archiveName?: string;
  file?: FileResponseDto;
  descriptionRu: string;
  descriptionEn: string;
  status: LayoutStatus;
  materialsKinds: Array<MaterialsKind>;
  isArchived: boolean;
  isFavourite: boolean;
  tags: Array<TagResponseDto>;
};

export enum LayoutStatus {
  DRAFT = 'draft',
  DONE = 'done',
}

export type FetchFolderLayoutsQueryData = ListApiFetchArgs<LayoutResponseDto> & {
  folderID: string;
};

export type FetchImageLayoutsQueryData = ListApiFetchArgs<LayoutResponseDto> & {
  imageID: string;
};

export type LayoutEditFormFields = {
  layoutNumber?: number;
  descriptionRu?: LayoutResponseDto['descriptionRu'];
  descriptionEn?: LayoutResponseDto['descriptionEn'];
  status?: LayoutStatus;
  materialsKinds?: LayoutResponseDto['materialsKinds'];
  tags?: LayoutResponseDto['tags'];
  archiveName?: LayoutResponseDto['archiveName'];
  fileBox: FileBox;
  images: Array<ImageResponseDto>;
  folders: Array<FolderResponseDto>;
};

export type LayoutCreateRequestDto = {
  descriptionRu?: LayoutResponseDto['descriptionRu'];
  descriptionEn?: LayoutResponseDto['descriptionEn'];
  status?: LayoutStatus;
  materialsKinds?: LayoutResponseDto['materialsKinds'];
  folderIDsToAdd?: Array<string>;
  imageIDsToAdd?: Array<string>;
  tags?: Array<string>;
  fileID?: string;
};

export type LayoutDeleteRequestDto = {
  layoutID: string;
};

export type LayoutEditRequestDto = Omit<
  LayoutEditFormFields,
  'fileBox' | 'layoutNumber' | 'archiveName' | 'tags' | 'images' | 'folders'
> & {
  tags?: Array<string>;
  fileID?: string;
  fileClear?: string;
  layoutID: string;
  folderIDsToAdd?: Array<string>;
  folderIDsToRemove?: Array<string>;
  imageIDsToAdd?: Array<string>;
  imageIDsToRemove?: Array<string>;
  isArchived?: boolean;
};
