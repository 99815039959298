import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getListSortReducers, ListLayout, ListState } from 'features/list';
import { getListPaginationReducers } from 'features/list/listReducers';
import { getDefaultPaginationState } from 'features/pagination';
import { SortDirection } from 'features/sorting';
import { getCurrentLanguage, matchLanguageProp } from 'infrastructure/i18n';
import i18n from 'infrastructure/i18n/i18n';
import { FolderResponseDto } from '../../folders/folderEntity';

type EntityType = FolderResponseDto;
export type RootFolderListState = ListState<EntityType> & {
  layoutType: ListLayout;
};

const initialState: RootFolderListState = {
  layoutType: ListLayout.CARD,
  sortingInfo: {
    sortField: matchLanguageProp<keyof EntityType>(getCurrentLanguage(i18n), 'captionRu', 'captionEn'),
    sortDirection: SortDirection.ASC,
  },
  paginationData: getDefaultPaginationState(),
};

const rootFolderListSlice = createSlice({
  name: 'rootFolderList',
  initialState,
  reducers: {
    ...getListSortReducers<RootFolderListState, EntityType>(),
    ...getListPaginationReducers<RootFolderListState>(),
    changeLayoutType: (state, action: PayloadAction<ListLayout>) => {
      state.layoutType = action.payload;
    },
    dispose: (state) => {
      state.paginationData = getDefaultPaginationState();
    },
  },
});

export const rootFolderListActions = rootFolderListSlice.actions;
export const rootFolderListReducer = rootFolderListSlice.reducer;
