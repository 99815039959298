import cn from 'classnames';
import React, { FunctionComponent } from 'react';
import styles from './FolderCardCaption.module.scss';
import { FolderCardCaptionProps } from './FolderCardCaptionProps';

export const FolderCardCaption: FunctionComponent<FolderCardCaptionProps> = (props) => {
  const { text, icon, className } = props;

  return (
    <div className={cn(styles.container, className)}>
      <span className={styles.icon}>{icon}</span>
      <span className={styles.text}>{text}</span>
    </div>
  );
};
