import { FormProvider } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { apiErrorMessageMatcher } from 'features/file';
import { FolderEditDtoValidationSchema } from 'features/folders/folderEditValidation';
import { FolderEditFormFields } from 'features/folders/folderEntity';
import { TagsManageContext, useTagsManage } from 'features/tags';
import { useForm } from 'infrastructure/form';
import { useTranslateEnum } from 'infrastructure/i18n';
import { parseApiError } from 'infrastructure/network';
import { Modal } from 'ui-library/components/Modal';
import { Tab } from 'ui-library/components/Tab';
import { FolderEditFormInfoTab } from './FolderEditFormInfoTab/FolderEditFormInfoTab';
import { FolderEditFormProps } from './FolderEditFormProps';
import { FolderEditFormTab } from './FolderEditFormTab';

export const FolderEditForm = (props: FolderEditFormProps) => {
  const {
    renderActions,
    onSubmit,
    defaultValues = {
      tags: [],
      materialsKinds: [],
    },
    isCreate,
  } = props;
  const { t } = useTranslation();
  const { translateValue } = useTranslateEnum('FolderEditFormTab', FolderEditFormTab);

  const methods = useForm<FolderEditFormFields>({
    defaultValues,
    schema: FolderEditDtoValidationSchema(),
  });

  const { handleSubmit, formState, setError } = methods;

  const tagsManage = useTagsManage({
    initialTags: defaultValues.tags || [],
  });
  const { tags, extendedTagIds, isRemoved } = tagsManage;

  const submit = async (values: FolderEditFormFields) => {
    return onSubmit({
      ...values,
      tags: tags.filter((currentTag) => !isRemoved(currentTag)),
      tagsExtend: extendedTagIds,
    }).catch((e) => {
      const error = parseApiError(e);
      const messageKey = apiErrorMessageMatcher(error);

      if (messageKey) {
        setError('fileBox.file', {
          message: t(messageKey as never),
        });
      } else {
        if (isCreate) {
          toast.error('folderEditForm.errors.create');
        } else {
          toast.error('folderEditForm.errors.edit');
        }
      }
    });
  };

  return (
    <FormProvider {...methods}>
      <TagsManageContext.Provider value={tagsManage}>
        <Modal.Form onSubmit={handleSubmit(submit)}>
          <Modal.Content>
            <Tab.Group as='div'>
              <Tab.List>
                <Tab>{translateValue(FolderEditFormTab.INFORMATION)}</Tab>
              </Tab.List>
              <Tab.Panels>
                <Tab.Panel>
                  <FolderEditFormInfoTab />
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          </Modal.Content>
          <Modal.Actions>{renderActions(formState)}</Modal.Actions>
        </Modal.Form>
      </TagsManageContext.Provider>
    </FormProvider>
  );
};
