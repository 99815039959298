import cn from 'classnames';
import { useCombobox } from 'downshift';
import { KeyboardEvent, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonKind } from 'ui-library/components/Button';
import { Form } from 'ui-library/components/Form';
import { Icon } from 'ui-library/components/Icon';
import { Spinner } from 'ui-library/components/Spinner';
import { IconButton, IconButtonVariant } from 'ui-library/v1/components/IconButton';
import { Input } from 'ui-library/v1/components/Input';
import styles from './SearchAutocomplete.module.scss';
import { SearchAutocompleteProps } from './SearchAutocompleteProps';

export const SearchAutocomplete = ({
  setSearchQuery,
  filteredOptions,
  query,
  onSearch,
  isLoading,
  handleChangeOpiton,
}: SearchAutocompleteProps) => {
  const { t } = useTranslation();

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    onSearch();
  };

  const { isOpen, getInputProps, getMenuProps, getItemProps, highlightedIndex } = useCombobox({
    items: filteredOptions,
    initialInputValue: query,
    onInputValueChange: ({ inputValue }) => {
      setSearchQuery(inputValue ?? '');
    },
    itemToString: (item) => (item ? item.label : ''),
    onSelectedItemChange: ({ selectedItem: newSelectedItem }) => {
      newSelectedItem && handleChangeOpiton(newSelectedItem);
    },
  });

  const onKeyDownInput = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.code === 'Enter') {
      onSearch();
    }
  };

  return (
    <div className={styles.root}>
      <Form onSubmit={handleSubmit} className={styles.form}>
        <Input
          LayoutProps={{
            className: styles.inputLayout,
            InputProps: { className: styles.inputBox, onKeyDown: onKeyDownInput },
          }}
          placeholder={t('header.search')}
          name='search'
          {...getInputProps()}
          autoComplete='off'
        />
        <IconButton
          as={ButtonKind.BUTTON}
          variant={IconButtonVariant.TRANSPARENT}
          className={styles.icon}
          size={'small'}
          type='submit'
        >
          <Icon name='search' />
        </IconButton>
      </Form>
      <ul
        {...getMenuProps()}
        className={cn(styles.combobox, { [styles.hidden]: !isOpen || (!isLoading && filteredOptions.length === 0) })}
      >
        {isOpen &&
          filteredOptions.map((option, index) => (
            <li
              key={`${option.label}${index}`}
              {...getItemProps({ item: option, index })}
              className={cn(styles.item, { [styles.itemHighlighted]: highlightedIndex === index })}
            >
              {option.label}
            </li>
          ))}
        {isLoading && (
          <li className={styles.loader}>
            <Spinner color='secondary' />
          </li>
        )}
      </ul>
    </div>
  );
};
