import { FunctionComponent, useState } from 'react';
import { useSearchReferenceBookQuery, ReferenceBook } from 'features/referenceBook';
import { useDebounce } from 'features/search';
import { SortDirection } from 'features/sorting';
import { useCurrentLanguage, useLanguageRelevantData } from 'infrastructure/i18n';
import { AutocompleteField } from 'ui-library/components/AutocompleteField';
import { nameof } from 'utils';
import { ReferenceBookSelectFieldProps } from './ReferenceBookSelectFieldProps';

export const ReferenceBookSelectField: FunctionComponent<ReferenceBookSelectFieldProps> = (props) => {
  const { type, AutocompleteFieldProps, getOptionLabel } = props;

  const { matchProp } = useLanguageRelevantData();
  const currentLanguage = useCurrentLanguage();
  const [searchQuery, setSearchQuery] = useState('');
  const debouncedSearchQuery = useDebounce(searchQuery);

  const { data, isLoading } = useSearchReferenceBookQuery({
    query: debouncedSearchQuery,
    type,
    language: currentLanguage,
    sortDirection: SortDirection.ASC,
    sortFields: [matchProp('captionRu', 'captionEn')],
  });

  const { AutocompleteProps, ...otherAutocompleteFieldProps } = AutocompleteFieldProps;

  const onInputBlur = () => {
    setSearchQuery('');
  };

  return (
    <AutocompleteField
      {...otherAutocompleteFieldProps}
      getOptionLabel={getOptionLabel ? getOptionLabel : (option) => matchProp(option.captionRu, option.captionEn)}
      onChangeInput={setSearchQuery}
      options={data?.content || []}
      AutocompleteProps={{
        ...AutocompleteProps,
        by: nameof<ReferenceBook>('id'),
        loading: isLoading,
        InputProps: {
          onBlur: onInputBlur,
          ...AutocompleteProps?.InputProps,
        },
      }}
    />
  );
};
