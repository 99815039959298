import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { FileType, useUploadFile } from 'features/file';
import {
  FolderCreateRequestDto,
  FolderEditForm,
  FolderEditFormFields,
  foldersApi,
  useCreateFolderMutation,
} from 'features/folders';
import { ModalSubmitActions } from 'features/modal';
import { rootFolderCreationActions } from 'features/rootFolders';
import { NotDefinedParamException } from 'infrastructure/exceptions';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux';
import { Modal } from 'ui-library/components/Modal';

export const CreateRootFolder: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const { isOpen, selectedPartition } = useAppSelector((state) => state.rootFolderCreation);
  const [createFolderMutation] = useCreateFolderMutation();
  const uploadFile = useUploadFile(FileType.FOLDER);
  const { t } = useTranslation();

  const handleClose = () => {
    dispatch(rootFolderCreationActions.close());
    dispatch(rootFolderCreationActions.selectPartition(undefined));
  };

  const onSubmit = async (values: FolderEditFormFields) => {
    const { fileBox } = values;

    if (!selectedPartition) {
      throw new NotDefinedParamException('Folder partition');
    }

    const fileID = await uploadFile(fileBox.file);

    const dto: FolderCreateRequestDto = {
      fileID,
      tags: values.tags?.map((item) => item.id),
      tagsExtend: values.tagsExtend,
      captionRu: values.captionRu,
      captionEn: values.captionEn,
      descriptionRu: values.descriptionRu,
      descriptionEn: values.descriptionEn,
      status: values.status,
      materialsKinds: values.materialsKinds,
      partition: selectedPartition,
    };

    await createFolderMutation(dto).unwrap();

    dispatch(foldersApi.util.invalidateTags(['RootFolders']));

    handleClose();
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Modal.Title>{t('folders.createRootFolder')}</Modal.Title>
      <FolderEditForm
        isCreate
        onSubmit={onSubmit}
        renderActions={({ isSubmitting }) => <ModalSubmitActions loading={isSubmitting} onCancel={handleClose} />}
      />
    </Modal>
  );
};
