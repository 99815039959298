import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useEffect, useMemo, useRef } from 'react';
import { FolderPartition } from 'features/folders';
import { calculateOffset, FIRST_PAGE } from 'features/pagination';
import {
  ImageSearchFilterFields,
  MainSearchFilterFields,
  searchFolderActions,
  searchImageActions,
  searchLayoutActions,
  SearchRequestItemDto,
  searchRootFolderActions,
  useSearchQuery,
  useSearchQueryParams,
} from 'features/search';
import { useCurrentLanguage } from 'infrastructure/i18n';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux';

const QUERY_CACHE_LIFETIME_SECONDS = 5;

export const useSearchResult = (query: string | null) => {
  const currentLang = useCurrentLanguage();
  const dispatch = useAppDispatch();
  const prevQuery = useRef<string | null>(null);

  useEffect(() => {
    if (query !== prevQuery.current) {
      prevQuery.current = query;

      dispatch(searchRootFolderActions.pageChanged({ page: FIRST_PAGE }));
      dispatch(searchFolderActions.pageChanged({ page: FIRST_PAGE }));
      dispatch(searchLayoutActions.pageChanged({ page: FIRST_PAGE }));
      dispatch(searchImageActions.pageChanged({ page: FIRST_PAGE }));
    }
  }, [dispatch, query]);

  const rootFolderPaginationData = useAppSelector((state) => state.searchRootFolder.paginationData);
  const folderPaginationData = useAppSelector((state) => state.searchFolder.paginationData);
  const layoutPaginationData = useAppSelector((state) => state.searchLayout.paginationData);
  const imagePaginationData = useAppSelector((state) => state.searchImage.paginationData);
  const { tagIds, filterData, partition } = useSearchQueryParams();

  const filters = useMemo(() => {
    const mainSearchFilterFields: MainSearchFilterFields = {
      status: filterData.status || undefined,
      materialsKinds: filterData.materialsKinds,
    };

    const imageSearchFilterFields: ImageSearchFilterFields = {
      authorID: filterData.authorID || undefined,
      centuryID: filterData.centuryID || undefined,
      yearID: filterData.yearID || undefined,
      artFormID: filterData.artFormID || undefined,
      centuryHalf: filterData.centuryHalf ? +filterData.centuryHalf : undefined,
      materialsTechnicsID: filterData.materialsTechnicsID || undefined,
    };

    return {
      ...mainSearchFilterFields,
      ...imageSearchFilterFields,
    };
  }, [filterData]);

  const isFilledParams = useMemo(() => {
    const isFilledAnyFilter = Object.values(filters).some(
      (item) => (Array.isArray(item) && item.length > 0) || (Array.isArray(item) === false && item),
    );

    return query || tagIds.length > 0 || isFilledAnyFilter;
  }, [filters, query, tagIds.length]);

  const archiveParameterArray: Array<SearchRequestItemDto> = [
    {
      type: 'topic',
      limit: rootFolderPaginationData.pageSize,
      offset: calculateOffset(rootFolderPaginationData.currentPage, rootFolderPaginationData.pageSize),
    },
    {
      type: 'folder',
      limit: folderPaginationData.pageSize,
      offset: calculateOffset(folderPaginationData.currentPage, folderPaginationData.pageSize),
    },
    {
      type: 'layout',
      limit: layoutPaginationData.pageSize,
      offset: calculateOffset(layoutPaginationData.currentPage, layoutPaginationData.pageSize),
    },
    {
      type: 'image',
      limit: imagePaginationData.pageSize,
      offset: calculateOffset(imagePaginationData.currentPage, imagePaginationData.pageSize),
    },
  ];

  const museumParameterArray: Array<SearchRequestItemDto> = [
    {
      type: 'topic',
      limit: rootFolderPaginationData.pageSize,
      offset: calculateOffset(rootFolderPaginationData.currentPage, rootFolderPaginationData.pageSize),
    },
    {
      type: 'folder',
      limit: folderPaginationData.pageSize,
      offset: calculateOffset(folderPaginationData.currentPage, folderPaginationData.pageSize),
    },
    {
      type: 'image',
      limit: imagePaginationData.pageSize,
      offset: calculateOffset(imagePaginationData.currentPage, imagePaginationData.pageSize),
    },
  ];
  const items = partition === FolderPartition.ARCHIVE ? archiveParameterArray : museumParameterArray;

  const result = useSearchQuery(
    isFilledParams
      ? {
          items: items,
          partition: partition,
          language: currentLang,
          query: query ?? '',
          tags: tagIds,
          ...filters,
        }
      : skipToken,
    {
      refetchOnMountOrArgChange: QUERY_CACHE_LIFETIME_SECONDS,
    },
  );

  return { result, isFilledParams };
};
