import { useTranslation } from 'react-i18next';
import { GalleryImageItemDto } from 'features/images';
import { useImageActionHandlers } from 'features/images/hooks';
import { favoritesAccessRuleService, imageAccessRuleService } from 'features/role';
import { Icon } from 'ui-library/components/Icon';
import { ActionListItem } from 'ui-library/v1/components/ActionList';

export type UseImageActionsOptions = {
  image: GalleryImageItemDto;
  parentId: string | undefined;
};

export const useImageActions = (options: UseImageActionsOptions) => {
  const { image, parentId } = options;
  const { handleEdit, handleDownload, handleAddToFavorite, handleRemoveFromFavorite } = useImageActionHandlers({
    image,
    parentId,
  });
  const { t } = useTranslation();

  const actions: Array<ActionListItem> = [];

  if (image?.isFavourite) {
    actions.push({
      label: t('actions.removeFromFavorite'),
      onClick: handleRemoveFromFavorite,
      hasAccess: () => favoritesAccessRuleService.canDelete(),
      startAdornment: <Icon name='favoriteDelete' />,
    });
  } else {
    actions.push({
      label: t('actions.addToFavorite'),
      onClick: handleAddToFavorite,
      hasAccess: () => favoritesAccessRuleService.canCreate(),
      startAdornment: <Icon name='favorite' />,
    });
  }

  actions.push(
    {
      label: t('actions.edit'),
      onClick: handleEdit,
      hasAccess: () => imageAccessRuleService.canEdit(),
      startAdornment: <Icon name='edit' />,
    },
    {
      label: t('actions.download'),
      onClick: handleDownload,
      startAdornment: <Icon name='share' />,
    },
  );

  return { actions };
};
