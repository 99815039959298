import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ModalSubmitActions } from 'features/modal';
import { useEnumOptions } from 'features/select';
import { UserCreateFormFields, UserRole } from 'features/users';
import { UserCreateDtoValidationSchema } from 'features/users/userCreateValidation';
import { useForm } from 'infrastructure/form';
import { SelectFormController } from 'infrastructure/form';
import { FormCol, FormRow } from 'ui-library/components/Form';
import { Modal } from 'ui-library/components/Modal';
import { PasswordTextField, TextField } from 'ui-library/components/TextField';
import { PasswordRules } from '../../PasswordRules/PasswordRules';
import styles from './UserCreateForm.module.scss';
import { UserCreateFormProps } from './UserCreateFormProps';

const nameField: keyof UserCreateFormFields = 'name';
const surnameField: keyof UserCreateFormFields = 'surname';
const roleField: keyof UserCreateFormFields = 'role';
const emailField: keyof UserCreateFormFields = 'email';
const passwordField: keyof UserCreateFormFields = 'password';

export const UserCreateForm = (props: UserCreateFormProps) => {
  const { onClose, onSubmit } = props;
  const { t } = useTranslation();
  const { options: userRoleOptions } = useEnumOptions('UserRole', UserRole);

  const methods = useForm<UserCreateFormFields>({
    schema: UserCreateDtoValidationSchema(),
  });

  const {
    handleSubmit,
    register,
    getValues,
    formState: { errors, isSubmitting },
    watch,
  } = methods;

  watch('password');

  return (
    <FormProvider {...methods}>
      <Modal.Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Content>
          <div className={styles.root}>
            <FormRow>
              <FormCol>
                <TextField
                  error={errors[nameField]?.message}
                  label={t('users.create.name.label')}
                  required
                  InputProps={{
                    ...register(nameField),
                    placeholder: t('users.create.name.placeholder'),
                  }}
                />
              </FormCol>
              <FormCol>
                <TextField
                  error={errors[surnameField]?.message}
                  label={t('users.create.surname.label')}
                  InputProps={{
                    ...register(surnameField),
                    placeholder: t('users.create.surname.placeholder'),
                  }}
                />
              </FormCol>
            </FormRow>
            <FormRow>
              <FormCol>
                <SelectFormController
                  name={roleField}
                  label={t('users.create.role.label')}
                  placeholder={t('users.create.role.placeholder')}
                  options={userRoleOptions}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                  required
                />
              </FormCol>
              <FormCol>
                <TextField
                  error={errors[emailField]?.message}
                  label={t('users.create.email.label')}
                  required
                  InputProps={{
                    ...register(emailField),
                    type: 'email',
                    placeholder: t('users.create.email.placeholder'),
                  }}
                />
              </FormCol>
            </FormRow>
            <FormRow>
              <FormCol>
                <PasswordTextField
                  error={errors[passwordField]?.message}
                  label={t('users.create.password.label')}
                  required
                  InputProps={{
                    ...register(passwordField),
                    placeholder: t('users.create.password.placeholder'),
                  }}
                />
              </FormCol>
              <FormCol></FormCol>
            </FormRow>
            <FormRow>
              <FormCol>
                <PasswordRules value={getValues(passwordField)} />
              </FormCol>
              <FormCol></FormCol>
            </FormRow>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <ModalSubmitActions loading={isSubmitting} onCancel={onClose} />
        </Modal.Actions>
      </Modal.Form>
    </FormProvider>
  );
};
