import cn from 'classnames';
import { FunctionComponent } from 'react';
import { Footer } from 'features/layout/v1/components/Footer';
import { Header } from '../../Header/Header';
import styles from './ProtectedLayout.module.scss';
import { ProtectedLayoutProps } from './ProtectedLayoutProps';

export const ProtectedLayout: FunctionComponent<ProtectedLayoutProps> = (props) => {
  return (
    <div className={cn(styles.root, props.className)}>
      <main className={styles.main}>
        <Header />
        {props.children}
      </main>
      <Footer />
    </div>
  );
};
