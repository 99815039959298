import { skipToken } from '@reduxjs/toolkit/dist/query';
import { FunctionComponent } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import {
  favoriteGroupEditActions,
  FavoriteGroupRequestBody,
  useEditGroupMutation,
  useFetchGroupQuery,
} from 'features/favorites';
import { ModalSubmitActions } from 'features/modal';
import { parseApiError } from 'infrastructure/network';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux';
import { Modal } from 'ui-library/components/Modal';
import { FavoriteGroupForm } from '../FavoriteGroupForm/FavoriteGroupForm';

export const FavoriteGroupEdit: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const { isOpen, selectedGroupId } = useAppSelector((state) => state.favoriteGroupEdit);
  const { data } = useFetchGroupQuery(selectedGroupId ?? skipToken);

  const [editGroupMutation] = useEditGroupMutation();

  const { t } = useTranslation();

  const handleClose = () => {
    dispatch(favoriteGroupEditActions.close());
  };

  const onSubmit = async (values: FavoriteGroupRequestBody) => {
    try {
      await editGroupMutation({
        groupID: selectedGroupId as string,
        captionRu: values.captionRu,
        captionEn: values.captionEn,
        descriptionRu: values.descriptionRu,
        descriptionEn: values.descriptionEn,
      }).unwrap();

      handleClose();
    } catch (e) {
      const error = parseApiError(e);

      toast.error(error?.message || t('favorites.group.editFailed'));
    }
  };

  const defaultValues: FavoriteGroupRequestBody = {
    captionEn: data?.captionEn ?? '',
    captionRu: data?.captionRu ?? '',
    descriptionEn: data?.descriptionEn,
    descriptionRu: data?.descriptionRu,
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Modal.Title>{t('favorites.group.edit')}</Modal.Title>
      {data?.id === selectedGroupId && (
        <FavoriteGroupForm
          defaultValues={defaultValues}
          onSubmit={onSubmit}
          renderActions={({ isSubmitting }) => <ModalSubmitActions loading={isSubmitting} onCancel={handleClose} />}
        />
      )}
    </Modal>
  );
};
