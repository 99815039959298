import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { nameof } from 'utils';
import en_locale from './locales/en.json';
import ru_locale from './locales/ru.json';

export const I18N_RESOURCES = {
  ru: {
    translation: ru_locale,
  },
  en: {
    translation: en_locale,
  },
} as const;

export type AvailableLanguages = keyof typeof I18N_RESOURCES;
export type TranslateKeys = typeof I18N_RESOURCES['ru'] | typeof I18N_RESOURCES['en'];

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: I18N_RESOURCES,
    fallbackLng: nameof<typeof I18N_RESOURCES>('ru'),
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
