import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getDefaultModalState, getModalReducers, ModalState } from 'features/modal';
import { FileArtifactEntity } from '../fileEntity';

export type CreateFileDownloadTaskState = ModalState & {
  selectedArtifact: FileArtifactEntity | undefined;
};

const initialState: CreateFileDownloadTaskState = {
  ...getDefaultModalState(),
  selectedArtifact: undefined,
};

const createFileDownloadTaskSlice = createSlice({
  name: 'createFileDownloadTask',
  initialState,
  reducers: {
    ...getModalReducers<CreateFileDownloadTaskState>((state) => state),
    setFileArtifact: (state, action: PayloadAction<FileArtifactEntity | undefined>) => {
      state.selectedArtifact = action.payload;
    },
  },
});

export const createFileDownloadTaskActions = createFileDownloadTaskSlice.actions;
export const createFileDownloadTaskReducer = createFileDownloadTaskSlice.reducer;
