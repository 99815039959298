import cn from 'classnames';
import { Button, ButtonKind, ButtonVariant } from '../Button';
import { Icon } from '../Icon';
import styles from './FileInput.module.scss';
import { FileInputProps } from './FileInputProps';

export const FileInput = (props: FileInputProps) => {
  const { className, label, description, variant, InputProps, ButtonProps } = props;

  return (
    <div
      className={cn(styles.root, className, {
        [styles.vertical]: variant === 'vertical',
      })}
    >
      <Button
        as={ButtonKind.BUTTON}
        variant={ButtonVariant.SECONDARY}
        startIcon={<Icon name={'plus'} />}
        size='small'
        {...ButtonProps}
      >
        <input className={styles.input} type='file' {...InputProps} />

        {label}
      </Button>

      {description && <p className={styles.description}>{description}</p>}
    </div>
  );
};
