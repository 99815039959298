import { Listbox } from '@headlessui/react';
import cn from 'classnames';
import { Fragment, useMemo } from 'react';
import { Icon } from 'ui-library/components/Icon';
import { InputLayout, InputLayoutVariant } from 'ui-library/v1/components/InputLayout';
import styles from './SingleValueContainer.module.scss';
import { SingleValueContainerProps } from './SingleValueContainerProps';

export const SingleValueContainer = <Option, OptionValue = Option>(
  props: SingleValueContainerProps<Option, OptionValue>,
) => {
  const { error, disabled, options, value, open, placeholder, getOptionLabel, getOptionValue, InputProps } = props;

  const option = useMemo(
    () => options.find((option) => getOptionValue(option) === value),
    [getOptionValue, options, value],
  );

  const isEmptyValue = value === null || value === undefined;

  return (
    <Listbox.Button as={Fragment}>
      <InputLayout
        error={error}
        disabled={disabled}
        renderControl={() => (
          <span className={styles.value}>{isEmptyValue || !option ? placeholder : getOptionLabel(option)}</span>
        )}
        variant={InputLayoutVariant.STANDARD}
        endAdornment={disabled ? null : <Icon name={open ? 'arrowUp' : 'arrowDown'} />}
        endAdornmentProps={{
          className: styles.endAdornment,
        }}
        InputProps={{
          ...InputProps,
          className: cn(styles.button, InputProps?.className, {
            [styles.active]: open,
            [styles.placeholder]: isEmptyValue,
          }),
        }}
        tabIndex={0}
      />
    </Listbox.Button>
  );
};
