import { skipToken } from '@reduxjs/toolkit/query';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { FileType, useUploadFile } from 'features/file';
import { FolderResponseDto, foldersApi, useFetchLayoutFoldersQuery } from 'features/folders';
import { ImageResponseDto, imagesApi, useFetchLayoutImagesQuery } from 'features/images';
import {
  layoutEditActions,
  LayoutEditFormFields,
  LayoutEditRequestDto,
  layoutsApi,
  useEditLayoutMutation,
  useFetchLayoutDetailQuery,
} from 'features/layouts';
import { useMapLayoutEntityToFormValues } from 'features/layouts/hooks';
import { ModalSubmitActions } from 'features/modal';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux';
import { Modal } from 'ui-library/components/Modal';
import { LayoutEditForm } from '../LayoutEditForm/LayoutEditForm';
import { LayoutViewTab } from '../LayoutView/LayoutViewTab';

export const LayoutEdit: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const { isOpen, selectedLayoutId, selectedTab } = useAppSelector((state) => state.layoutEdit);
  const { t } = useTranslation();
  const { data } = useFetchLayoutDetailQuery(selectedLayoutId ?? skipToken);
  const { data: layoutFolders, isFetching: isFetchingFolders } = useFetchLayoutFoldersQuery(
    selectedLayoutId ? { layoutID: selectedLayoutId, pageSize: Number.MAX_SAFE_INTEGER, currentPage: 1 } : skipToken,
  );
  const { data: layoutImages, isFetching: isFetchingImages } = useFetchLayoutImagesQuery(
    selectedLayoutId ? { layoutID: selectedLayoutId, currentPage: 1, pageSize: Number.MAX_SAFE_INTEGER } : skipToken,
  );

  const handleClose = () => {
    dispatch(layoutEditActions.close());
    dispatch(layoutEditActions.selectLayout(undefined));
    dispatch(layoutEditActions.selectTab(LayoutViewTab.INFORMATION));
  };

  const defaultValues = useMapLayoutEntityToFormValues(data, layoutImages, layoutFolders);
  const [editLayoutMutation] = useEditLayoutMutation();
  const uploadFile = useUploadFile(FileType.LAYOUT);

  const getFolderIDsToAdd = (values: Array<FolderResponseDto>) => {
    const folderToAdd = values?.filter((folder) => layoutFolders?.content.every((item) => item.id !== folder.id));
    return folderToAdd?.map((x) => x.id);
  };

  const getImageIDsToAdd = (values: Array<ImageResponseDto>) => {
    const imagesToAdd = values?.filter((image) => layoutImages?.content.every((item) => item.id !== image.id));
    return imagesToAdd?.map((x) => x.id);
  };

  const getFolderIDsToRemove = (values: Array<FolderResponseDto>) => {
    const folderIDsToRemove: Array<string> = [];
    layoutFolders?.content.forEach((item) => {
      if (values?.find((folders) => folders.id === item.id) === undefined) {
        folderIDsToRemove.push(item.id);
      }
    });
    return folderIDsToRemove;
  };

  const getImageIDsToRemove = (values: Array<ImageResponseDto>) => {
    const imagesIDsToRemove: Array<string> = [];
    layoutImages?.content.forEach((item) => {
      if (values?.find((image) => image.id === item.id) === undefined) {
        imagesIDsToRemove.push(item.id);
      }
    });
    return imagesIDsToRemove;
  };

  const onSubmit = async (values: LayoutEditFormFields) => {
    const { fileBox } = values;

    let fileID;
    if (fileBox.file) {
      fileID = await uploadFile(fileBox.file);
    }

    const dto: LayoutEditRequestDto = {
      fileID,
      status: values.status,
      layoutID: selectedLayoutId as string,
      descriptionRu: values.descriptionRu,
      descriptionEn: values.descriptionEn,
      materialsKinds: values.materialsKinds,
      tags: values.tags?.map((tag) => tag.id),
      imageIDsToAdd: getImageIDsToAdd(values.images),
      imageIDsToRemove: getImageIDsToRemove(values.images),
      folderIDsToAdd: getFolderIDsToAdd(values.folders),
      folderIDsToRemove: getFolderIDsToRemove(values.folders),
    };

    await editLayoutMutation(dto).unwrap();

    dispatch(layoutsApi.util.invalidateTags([{ type: 'Layout' as const, id: selectedLayoutId }]));
    dispatch(foldersApi.util.invalidateTags(['LayoutFolders']));
    dispatch(imagesApi.util.invalidateTags(['LayoutImages']));

    handleClose();
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Modal.Title>{t('layouts.editLayout')}</Modal.Title>
      {data?.id === selectedLayoutId && !isFetchingFolders && !isFetchingImages && (
        <LayoutEditForm
          onTabChange={(tab) => dispatch(layoutEditActions.selectTab(tab))}
          selectedTab={selectedTab}
          defaultValues={defaultValues}
          onSubmit={onSubmit}
          renderActions={({ isSubmitting, isValid }) => (
            <ModalSubmitActions loading={isSubmitting} disabled={!isValid} onCancel={handleClose} />
          )}
        />
      )}
    </Modal>
  );
};
