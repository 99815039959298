import { nanoid } from 'nanoid';
import { useRef } from 'react';
import { ErrorMessage } from 'infrastructure/form/components';
import { FormControl } from '../FormControl';
import { FormHelperText } from '../FormHelperText';
import { Label } from '../Label';
import { Select } from '../Select/Select';
import { SelectFieldProps } from './SelectFieldProps';

export const SelectField = <Option, OptionValue = Option, IsMulti extends boolean = false>(
  props: SelectFieldProps<Option, OptionValue, IsMulti>,
) => {
  const {
    className,
    id,
    error,
    disabled,
    label,
    placeholder,
    required,
    value,
    onChange,
    getOptionLabel,
    getOptionValue,
    options,
    hideHelperText,
    FormHelperTextProps,
    SelectProps,
    LabelProps,
  } = props;
  const inputId = useRef(id ?? nanoid()).current;
  const helperTextId = useRef(nanoid()).current;

  return (
    <FormControl className={className}>
      <Label disabled={disabled} htmlFor={inputId} label={label} required={required} {...LabelProps} />
      <Select
        disabled={disabled}
        required={required}
        placeholder={placeholder}
        id={inputId}
        error={!!error}
        value={value}
        onChange={onChange}
        options={options}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        aria-describedby={helperTextId}
        aria-invalid={error ? 'true' : 'false'}
        {...SelectProps}
      />

      {!hideHelperText && (
        <FormHelperText id={helperTextId} error={!!error} {...FormHelperTextProps}>
          {error && <ErrorMessage error={error} />}
        </FormHelperText>
      )}
    </FormControl>
  );
};
