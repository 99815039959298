import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { ImageList, ImageLoadingError } from 'features/images';
import { ListLayout } from 'features/list';
import { searchImageActions } from 'features/search/slices';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux';
import { Pagination } from 'ui-library/components/Pagination';
import { EmptySearch } from '../EmptySearch/EmptySearch';
import { ImageMasonry } from './ImageMasonry/ImageMasonry';
import styles from './ImagesResult.module.scss';
import { ImagesResultProps } from './ImagesResultProps';

export const ImagesResult: FunctionComponent<ImagesResultProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { currentPage, pageSize } = useAppSelector((state) => state.searchImage.paginationData);

  const { query, layoutType } = props;
  const isEmpty = !query.isError && query.data?.totalCount === 0;

  const layoutView = layoutType === ListLayout.CARD ? <ImageMasonry {...query} /> : <ImageList {...query} />;

  return (
    <div className={styles.root}>
      <div className={styles.listWrap}>
        <>
          {isEmpty ? <EmptySearch className={styles.placeholder} message={t('search.emptyImages')} /> : layoutView}
          {query.isError && <ImageLoadingError onReload={query.refetch} />}
        </>
      </div>
      <div className={styles.paginationWrap}>
        <Pagination
          totalItems={query.data?.totalCount}
          pageSize={pageSize}
          onPageChanged={(page) => dispatch(searchImageActions.pageChanged({ page }))}
          currentPage={currentPage}
          scrollToStartAfterPageChanged
        />
      </div>
    </div>
  );
};
