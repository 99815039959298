import { skipToken } from '@reduxjs/toolkit/query';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { FilePreviewLabel } from 'features/file';
import { FileNavigatorFolderBreadcrumbs } from 'features/fileNavigator/folders';
import { FolderResponseDto, useFetchFolderDetailQuery } from 'features/folders';
import { useFetchImageDetailQuery } from 'features/images';
import { useFetchLayoutDetailQuery } from 'features/layouts';
import { linkingArtifactActions } from 'features/linking';
import { useLanguageRelevantData } from 'infrastructure/i18n';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux';
import { Icon } from 'ui-library/components/Icon';
import { Image } from 'ui-library/components/Image';
import { Modal } from 'ui-library/components/Modal';
import styles from './LinkingArtifactNavigatorHeader.module.scss';

export const LinkingArtifactNavigatorHeader: FunctionComponent = () => {
  const { t } = useTranslation();
  const { matchProp } = useLanguageRelevantData();
  const { linkingArtifacts, type, selectedFolderId } = useAppSelector((state) => state.linkingArtifacts);
  const dispatch = useAppDispatch();
  const singleArtifactId = linkingArtifacts.length === 1 && linkingArtifacts[0].entityId;
  const { data: selectedFolderData } = useFetchFolderDetailQuery(selectedFolderId ?? skipToken);
  const { data: layoutData } = useFetchLayoutDetailQuery((type === 'layout' && singleArtifactId) || skipToken);
  const { data: imageData } = useFetchImageDetailQuery((type === 'image' && singleArtifactId) || skipToken);

  const getTitle = () => {
    if (linkingArtifacts.length === 1) {
      if (layoutData) {
        return t('layouts.properties.number', { value: layoutData.archiveName });
      } else if (imageData) {
        return matchProp(imageData.captionRu, imageData.captionEn);
      }
    } else if (linkingArtifacts.length > 1) {
      if (type === 'image') {
        return t('linkingArtifacts.header.image', { count: linkingArtifacts.length });
      }
    }

    return '';
  };

  const getIcon = () => {
    if (linkingArtifacts.length === 1) {
      if (layoutData) {
        const preview = layoutData.file?.previews?.find((x) => x.label === FilePreviewLabel.LAYOUT_ICON);

        return <Image className={styles.previewIcon} src={preview?.url} loading='eager' />;
      } else if (imageData) {
        const preview = imageData.file?.previews?.find((x) => x.label === FilePreviewLabel.IMAGE_ICON);

        return <Image className={styles.previewIcon} src={preview?.url} loading='eager' />;
      }
    } else if (linkingArtifacts.length > 1) {
      if (type === 'image') {
        return <Icon className={styles.previewIcon} name='imageMulti' />;
      }
    }

    return undefined;
  };

  const handleBreadcrumbClick = (x: FolderResponseDto) => {
    dispatch(linkingArtifactActions.selectFolderId(x.id));
  };

  const handleGalleryClick = () => {
    dispatch(linkingArtifactActions.selectFolderId(undefined));
  };

  const handleBackClick = () => {
    dispatch(linkingArtifactActions.selectFolderId(selectedFolderData?.parentID));
  };

  return (
    <Modal.Title className={styles.root}>
      <>
        <div className={styles.header}>
          <span className={styles.icon}>{getIcon()}</span>
          <span className={styles.title}>{getTitle()}</span>
        </div>
        <FileNavigatorFolderBreadcrumbs
          selectedFolderId={selectedFolderId}
          onClickBreadcrumb={handleBreadcrumbClick}
          onClickBack={handleBackClick}
          onClickGallery={handleGalleryClick}
        />
      </>
    </Modal.Title>
  );
};
