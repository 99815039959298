import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type FavoriteContentListState = {
  currentGroupId: string | undefined;
};

const initialState: FavoriteContentListState = {
  currentGroupId: undefined,
};

const favoriteContentSlice = createSlice({
  name: 'favoriteContent',
  initialState,
  reducers: {
    setCurrentGroupId: (state, action: PayloadAction<string | undefined>) => {
      state.currentGroupId = action.payload;
    },
  },
});

export const favoriteContentActions = favoriteContentSlice.actions;
export const favoriteContentReducer = favoriteContentSlice.reducer;
