import { FunctionComponent } from 'react';
import { TableRowActions } from 'ui-library/components/Table';
import { useAuthorActions } from '../hooks/useAuthorActions';
import { AuthorsTableActionsProps } from './AuthorsTableActionsProps';

export const AuthorsTableActions: FunctionComponent<AuthorsTableActionsProps> = (props) => {
  const { author, className } = props;
  const { actions } = useAuthorActions(author);

  return <TableRowActions actions={actions} className={className} />;
};
