import { FunctionComponent } from 'react';
import { TableBodySkeleton } from 'ui-library/components/Table';
import { TableColumnSkeleton } from 'ui-library/components/Table/TableColumnSkeleton';
import { Skeleton } from 'ui-library/v1/components/Skeleton';
import styles from './FileHistoryTableSkeleton.module.scss';

const columns: Array<TableColumnSkeleton> = [
  {
    cellRenderer: () => (
      <div className={styles.label}>
        <Skeleton width={24} radius={1} />
        <Skeleton width={300} />
      </div>
    ),
  },
  {
    cellRenderer: () => <Skeleton width={50} radius={6} />,
  },
  {
    cellRenderer: () => <Skeleton width={100} radius={6} />,
  },
];

export const FileHistoryTableSkeleton: FunctionComponent = () => {
  return <TableBodySkeleton columns={columns} rows={10} />;
};
