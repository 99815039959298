import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getDefaultModalState, getModalReducers, ModalState } from 'features/modal';

type DeleteImageState = ModalState & {
  imageID?: string;
};

const initialState: DeleteImageState = {
  ...getDefaultModalState(),
  imageID: undefined,
};

const imageDeleteSlice = createSlice({
  name: 'imageDelete',
  initialState,
  reducers: {
    ...getModalReducers<DeleteImageState>((state) => state),
    setImageForDeletion: (state, action: PayloadAction<string | undefined>) => {
      state.imageID = action.payload;
    },
  },
});

export const imageDeleteActions = imageDeleteSlice.actions;
export const imageDeleteReducer = imageDeleteSlice.reducer;
