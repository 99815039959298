import cn from 'classnames';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { FileDownloadStatus } from 'features/file/fileEntity';
import { Icon } from 'ui-library/components/Icon';
import { Spinner } from 'ui-library/components/Spinner';
import styles from './FileDownloadTaskDetailItem.module.scss';
import { FileDownloadTaskDetailItemProps } from './FileDownloadTaskDetailItemProps';

const ERROR_STATUSES = [FileDownloadStatus.FAILED, FileDownloadStatus.CANCELLED];

export const FileDownloadTaskDetailItem: FunctionComponent<FileDownloadTaskDetailItemProps> = (props) => {
  const { t } = useTranslation();
  const { task } = props;

  const getIcon = () => {
    if (!task) {
      return null;
    }

    if (task.status === FileDownloadStatus.DONE) {
      return <Icon className={styles.done} name='doneThin' />;
    } else if (task.status === FileDownloadStatus.WORKING) {
      return <Spinner className={styles.working} size='small' />;
    } else if (ERROR_STATUSES.includes(task.status)) {
      return <Icon className={styles.failed} name='error' />;
    }

    return null;
  };

  const getText = () => {
    if (task?.status === FileDownloadStatus.DONE) {
      return t('files.downloadDetail.forming.done', {
        format: task.arg?.format,
      });
    } else if (task?.status === FileDownloadStatus.WORKING) {
      return t('files.downloadDetail.forming.working');
    } else if (task?.status === FileDownloadStatus.FAILED) {
      return t('files.downloadDetail.forming.failed');
    } else if (task?.status === FileDownloadStatus.CANCELLED) {
      return t('files.downloadDetail.forming.cancelled');
    }

    return null;
  };

  return (
    <div className={styles.root}>
      {getIcon()}
      <span
        className={cn(styles.label, {
          [styles.failed]: task && ERROR_STATUSES.includes(task.status),
        })}
      >
        {getText()}
      </span>
    </div>
  );
};
