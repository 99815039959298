import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getDefaultModalState, getModalReducers, ModalState } from 'features/modal';
import { ChangeOrderImageEntity, ChangeOrderFolderEntity } from './changeOrderEntity';

export type ChangedArtifactsState = undefined | ChangeOrderImageEntity | ChangeOrderFolderEntity;

export type ChangeOrderState = ModalState & {
  isShowOrderMode: boolean;
  changedArtifacts: ChangedArtifactsState;
};

const initialState: ChangeOrderState = {
  ...getDefaultModalState(),
  isShowOrderMode: false,
  changedArtifacts: undefined,
};

const changeOrderSlice = createSlice({
  name: 'changeOrder',
  initialState,
  reducers: {
    ...getModalReducers<ChangeOrderState>((state) => state),
    setShowOrderMode: (state, action: PayloadAction<boolean>) => {
      state.isShowOrderMode = action.payload;
    },
    setChangeArtifact: (state, action: PayloadAction<ChangedArtifactsState>) => {
      state.changedArtifacts = action.payload;
    },
    dispose: (state) => {
      state.isOpen = false;
      state.changedArtifacts = undefined;
    },
  },
});

export const changeOrderActions = changeOrderSlice.actions;
export const changeOrderReducer = changeOrderSlice.reducer;
