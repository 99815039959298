import cn from 'classnames';
import { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrentLanguage } from 'infrastructure/i18n';
import { Icon } from 'ui-library/components/Icon';
import { ActionList, ActionListItem } from 'ui-library/v1/components/ActionList';
import { ButtonKind } from 'ui-library/v1/components/Button';
import { IconButton, IconButtonVariant } from 'ui-library/v1/components/IconButton';
import { Popover, PopoverContent, PopoverTrigger } from 'ui-library/v1/components/Popover';
import styles from './ToggleLanguageButton.module.scss';
import { ToggleLanguageButtonProps } from './ToggleLanguageButtonProps';

export const ToggleLanguageButton: FunctionComponent<ToggleLanguageButtonProps> = (props) => {
  const { theme, className } = props;
  const { i18n } = useTranslation();
  const currentLang = useCurrentLanguage();
  const [isOpen, setOpen] = useState(false);
  const { t } = useTranslation();

  const handleClickEng = () => {
    i18n.changeLanguage('en');
  };

  const handleClickRu = () => {
    i18n.changeLanguage('ru');
  };

  const actions: Array<ActionListItem> = [
    {
      label: t('language.rus'),
      onClick: handleClickRu,
      endAdornment: currentLang === 'ru' ? <Icon name='done' /> : undefined,
    },
    {
      onClick: handleClickEng,
      label: t('language.eng'),
      endAdornment: currentLang === 'en' ? <Icon name='done' /> : undefined,
    },
  ];

  return (
    <Popover behavior='click' placement={'top-end'} offset={8} open={isOpen} onOpenChange={setOpen}>
      <PopoverTrigger>
        <IconButton
          className={cn(styles.button, className, {
            [styles.themeLight]: theme === 'light',
          })}
          as={ButtonKind.BUTTON}
          variant={IconButtonVariant.TRANSPARENT}
        >
          {currentLang}
        </IconButton>
      </PopoverTrigger>
      <PopoverContent portal>
        {({ close }) => (
          <div>
            <ActionList actions={actions} onClose={close} className={styles.action} />
          </div>
        )}
      </PopoverContent>
    </Popover>
  );
};
