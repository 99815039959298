import { useUploadFileMutation } from '../fileApi';
import { FileType } from '../fileEntity';

export const useUploadFile = (fileType: FileType) => {
  const [uploadFileMutation] = useUploadFileMutation();

  return async (file: File | undefined) => {
    if (file) {
      const result = await uploadFileMutation({
        file,
        fileType,
      }).unwrap();

      return result.id;
    } else {
      return undefined;
    }
  };
};
