import { FunctionComponent } from 'react';
import { useLanguageRelevantData } from 'infrastructure/i18n';
import { CardVariant } from 'ui-library/components/Card';
import { FavoriteGroupCardActions } from '../FavoriteGroupCardActions/FavoriteGroupCardActions';
import { FavoriteGroupCardRoot } from '../FavoriteGroupCardRoot/FavoriteGroupCardRoot';
import styles from './FavoriteGroupCard.module.scss';
import { FavoriteGroupCardProps } from './FavoriteGroupCardProps';

export const FavoriteGroupCard: FunctionComponent<FavoriteGroupCardProps> = (props) => {
  const { matchProp } = useLanguageRelevantData();
  const { group } = props;

  const caption = matchProp(group.captionRu, group.captionEn);

  return (
    <FavoriteGroupCardRoot className={styles.root} variant={CardVariant.OUTLINED}>
      <FavoriteGroupCardActions className={styles.actions} group={group} />
      <span className={styles.caption}>{caption}</span>
    </FavoriteGroupCardRoot>
  );
};
