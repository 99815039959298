import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useEnumOptions } from 'features/select';
import { UserEditFormFields, UserRole } from 'features/users';
import { SwitchFormController, SelectFormController } from 'infrastructure/form';
import { FormCol, FormRow } from 'ui-library/components/Form';
import { TextField } from 'ui-library/components/TextField';
import styles from './UserEditFormInfoTab.module.scss';

const nameField: keyof UserEditFormFields = 'name';
const surnameField: keyof UserEditFormFields = 'surname';
const roleField: keyof UserEditFormFields = 'role';
const emailField: keyof UserEditFormFields = 'email';
const isBlockedField: keyof UserEditFormFields = 'isBlocked';

export const UserEditFormInfoTab = () => {
  const { t } = useTranslation();

  const { options: userRoleOptions } = useEnumOptions('UserRole', UserRole);

  const {
    register,
    formState: { errors },
  } = useFormContext<UserEditFormFields>();

  return (
    <div className={styles.root}>
      <FormRow>
        <FormCol>
          <TextField
            error={errors[nameField]?.message}
            label={t('users.create.name.label')}
            required
            InputProps={{
              ...register(nameField),
              placeholder: t('users.create.name.placeholder'),
            }}
          />
        </FormCol>
        <FormCol>
          <TextField
            error={errors[surnameField]?.message}
            label={t('users.create.surname.label')}
            InputProps={{
              ...register(surnameField),
              placeholder: t('users.create.surname.placeholder'),
            }}
          />
        </FormCol>
      </FormRow>
      <FormRow>
        <FormCol>
          <SelectFormController
            name={roleField}
            label={t('users.create.role.label')}
            placeholder={t('users.create.role.placeholder')}
            options={userRoleOptions}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
            required
          />
        </FormCol>
        <FormCol>
          <TextField
            error={errors[emailField]?.message}
            label={t('users.create.email.label')}
            disabled
            InputProps={{
              ...register(emailField),
              type: 'email',
              placeholder: t('users.create.email.placeholder'),
            }}
          />
        </FormCol>
      </FormRow>
      <FormRow>
        <FormCol>
          <SwitchFormController name={isBlockedField} label={t('users.edit.switchBlock')} />
        </FormCol>
      </FormRow>
    </div>
  );
};
