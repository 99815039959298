export type TagResponseDto = {
  id: string;
  nameRu: string;
  nameEn: string;
  origin: TagsOrigin;
};

export enum TagsOrigin {
  TAG_AI = 'rdl',
  TAG_USER = 'user',
}

export type SearchTagsRequestData = {
  query: string;
};

export type GetTagsListRequestData = {
  IDs: Array<string>;
};

export type TagsCreateRequestDto = Array<{
  nameRu: TagResponseDto['nameRu'];
  nameEn: TagResponseDto['nameEn'];
}>;

export type TagEditRequestDto = {
  id: TagResponseDto['id'];
  nameRu: TagResponseDto['nameRu'];
  nameEn: TagResponseDto['nameEn'];
  origin: TagResponseDto['origin'];
};

export type TagDeleteRequestDto = {
  id: TagResponseDto['id'];
};
