import { FunctionComponent } from 'react';
import { createSearchParams } from 'react-router-dom';
import { ImageMasonryLayout, ImageMasonrySkeleton } from 'features/images';
import { getImageDetailRoute } from 'infrastructure/routes/paths';
import { ImageMasonryItem } from '../ImageMasonryItem/ImageMasonryItem';
import { ImageMasonryProps } from './ImageMasonryProps';

export const ImageMasonry: FunctionComponent<ImageMasonryProps> = (props) => {
  const { data, isFetching, groupId, params } = props;

  return isFetching ? (
    <ImageMasonrySkeleton />
  ) : (
    <ImageMasonryLayout>
      {data?.content.map((x) => (
        <ImageMasonryItem
          key={x.id}
          image={x}
          imageLink={getImageDetailRoute(x.id) + `?${createSearchParams(params)}`}
          groupId={groupId}
        />
      ))}
    </ImageMasonryLayout>
  );
};
