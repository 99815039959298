import { ButtonHTMLAttributes } from 'react';
import { InnerLinkProps, OuterLinkProps } from '../Link';

export enum ButtonKind {
  INNER_ANCHOR = 'inner_anchor',
  OUTER_ANCHOR = 'outer_anchor',
  BUTTON = 'button',
}

export type ButtonKindProps = {
  as: ButtonKind.BUTTON;
} & ButtonHTMLAttributes<HTMLButtonElement>;
export type OuterAnchorKindProps = {
  as: ButtonKind.OUTER_ANCHOR;
} & Omit<OuterLinkProps, 'as'>;
export type InnerAnchorKindProps = {
  as: ButtonKind.INNER_ANCHOR;
} & Omit<InnerLinkProps, 'as'>;
