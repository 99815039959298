import { Controller, useFormContext } from 'react-hook-form';
import { ImageEditFormFields } from 'features/images';
import { LayoutResponseDto } from 'features/layouts/layoutEntity';
import { LayoutEditList } from '../LayoutEditList/LayoutEditList';
import { LayoutsAutocomplete } from '../LayoutsAutocomplete';
import styles from './LayoutsFormController.module.scss';
import { LayoutsFormControllerProps } from './LayoutsFormControllerProps';

export const LayoutsFormController = (props: LayoutsFormControllerProps) => {
  const { name } = props;
  const { control } = useFormContext();
  const { setValue, getValues } = useFormContext<ImageEditFormFields>();

  const addFolderListItem = (value: LayoutResponseDto) => {
    const concatArrayLayouts = getValues()?.layouts ?? [];
    setValue('layouts', [value].concat(concatArrayLayouts));
  };

  const removeFolderListItem = (layout: LayoutResponseDto) => {
    return setValue(
      'layouts',
      getValues()?.layouts?.filter((x) => x.id !== layout.id),
    );
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value } }) => (
        <div className={styles.root}>
          <LayoutsAutocomplete selectedLayouts={value} onInternalChange={addFolderListItem} />
          {value.map((x: LayoutResponseDto) => (
            <LayoutEditList layout={x} key={x.id} onRemoveItem={removeFolderListItem} />
          ))}
        </div>
      )}
    />
  );
};
