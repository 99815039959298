import { FunctionComponent, useEffect, useState } from 'react';
import { useLazyGetReferenceBookListQuery } from 'features/referenceBook';
import { SearchFilterFormFields, useSearchQueryParams } from 'features/search';
import { SearchScreenFilterForm } from '../SearchScreenFilterForm/SearchScreenFilterForm';

const defaultSearchFilterValue: SearchFilterFormFields = {
  status: null,
  materialsKinds: [],
  centuryHalf: null,
  author: null,
  century: null,
  year: null,
  artForm: null,
  materialsTechnics: null,
};

export const SearchScreenFilter: FunctionComponent = () => {
  const [getReferenceBookList] = useLazyGetReferenceBookListQuery();
  const { filterData } = useSearchQueryParams();
  const [defaultValues, setDefaultValues] = useState<Partial<SearchFilterFormFields>>({
    status: filterData.status,
    materialsKinds: filterData.materialsKinds,
    centuryHalf: filterData.centuryHalf ? +filterData.centuryHalf : undefined,
  });

  useEffect(() => {
    const requestIdRecord: Record<
      Exclude<keyof SearchFilterFormFields, 'status' | 'materialsKinds' | 'centuryHalf'>,
      string | null
    > = {
      author: filterData.authorID,
      century: filterData.centuryID,
      year: filterData.yearID,
      artForm: filterData.artFormID,
      materialsTechnics: filterData.materialsTechnicsID,
    };

    const requestIds = Object.values(requestIdRecord).filter(Boolean) as Array<string>;
    const requestIdKeys = Object.keys(requestIdRecord) as Array<keyof typeof requestIdRecord>;

    const loadInitialReferenceBookList = async () => {
      const response = await getReferenceBookList({ IDs: requestIds }).unwrap();

      const data = response.reduce((result, referenceBook) => {
        const key = requestIdKeys.find((key) => requestIdRecord[key] === referenceBook.id);

        if (key) {
          return {
            ...result,
            [key]: referenceBook,
          };
        } else {
          return result;
        }
      }, {});

      setDefaultValues((prevState) => ({ ...prevState, ...data }));
    };

    if (requestIds.length > 0) {
      loadInitialReferenceBookList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Object.values(filterData).every((val) => !val || val?.length === 0)) {
      setDefaultValues(defaultSearchFilterValue);
    }
  }, [filterData]);

  return <SearchScreenFilterForm defaultValues={defaultValues} />;
};
