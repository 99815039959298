import { Tab as BuiltinTab } from '@headlessui/react';
import cn from 'classnames';
import { forwardRef } from 'react';
import styles from './TabList.module.scss';
import { TabListProps } from './TabListProps';

export const TabList = forwardRef<HTMLDivElement, TabListProps>((props, ref) => {
  const { className, ...restProps } = props;

  return <BuiltinTab.List ref={ref} as='div' className={cn(styles.root, className)} {...restProps} />;
});
