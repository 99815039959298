import { ActiveFileDownloadTaskState } from '../components/ActiveFileDownloadTasks/ActiveFileDownloadTaskState';

class FileDownloadService {
  private readonly userFileDownloadAgreementKey = 'user_file_download_agreement';
  private readonly activeDownloadTaskModalStateKey = 'active_download_task_modal_state';

  get isUserAgree(): boolean {
    const value = localStorage.getItem(this.userFileDownloadAgreementKey);

    if (!value) {
      return false;
    }

    return JSON.parse(value);
  }

  public setUserAgreement(state: boolean): void {
    localStorage.setItem(this.userFileDownloadAgreementKey, JSON.stringify(state));
  }

  public setActiveTaskModalState(state: ActiveFileDownloadTaskState): void {
    localStorage.setItem(this.activeDownloadTaskModalStateKey, JSON.stringify(state));
  }

  get activeTaskModalState(): ActiveFileDownloadTaskState | null {
    const value = localStorage.getItem(this.activeDownloadTaskModalStateKey);

    if (!value) {
      return null;
    }

    return JSON.parse(value);
  }

  public clear(): void {
    localStorage.removeItem(this.userFileDownloadAgreementKey);
    localStorage.removeItem(this.activeDownloadTaskModalStateKey);
  }
}

export const fileDownloadService = new FileDownloadService();
