import { createSlice } from '@reduxjs/toolkit';
import { ListPaginationState } from 'features/list';
import { getListPaginationReducers } from 'features/list/listReducers';
import { getDefaultPaginationState } from 'features/pagination';

export type SearchImageState = ListPaginationState;

const initialState: SearchImageState = {
  paginationData: getDefaultPaginationState(),
};

const searchImageSlice = createSlice({
  name: 'searchImage',
  initialState,
  reducers: getListPaginationReducers<SearchImageState>(),
});

export const searchImageActions = searchImageSlice.actions;
export const searchImageReducer = searchImageSlice.reducer;
