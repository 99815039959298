import { FunctionComponent } from 'react';
import { Header } from 'features/layout/v1/components/Header';
import { SearchBar } from '../SearchBar';
import styles from './SearchHeader.module.scss';

export const SearchHeader: FunctionComponent = () => {
  return (
    <>
      <Header />
      <div className={styles.root}>
        <SearchBar InputProps={{ autoFocus: true }} />
      </div>
    </>
  );
};
