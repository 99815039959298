import { AnchorHTMLAttributes } from 'react';
import { LinkProps as RouterLinkProps } from 'react-router-dom';

export enum LinkKind {
  OUTER = 'OUTER',
  INNER = 'INNER',
}

export enum LinkVariant {
  DEFAULT = 'default',
  STYLED = 'styled',
}

export type LinkProps = (OuterLinkProps | InnerLinkProps) & CommonProps;

export type OuterLinkProps = {
  as: LinkKind.OUTER;
} & AnchorHTMLAttributes<HTMLAnchorElement>;

export type InnerLinkProps = {
  as: LinkKind.INNER;
} & RouterLinkProps;

type CommonProps = {
  variant?: LinkVariant;
  className?: string;
};
