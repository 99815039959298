import cn from 'classnames';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { UserResponseDto, UserRole } from 'features/users';
import { Icon } from 'ui-library/components/Icon';
import { EnumCellRenderer, TextCellRenderer } from 'ui-library/components/Table';
import { TableColumn } from 'ui-library/components/Table/TableColumn';
import { nameof } from 'utils';
import styles from './useUserTableColumns.module.scss';

export const useUserTableColumns = () => {
  const { t } = useTranslation();

  const columns = useMemo<Array<TableColumn<UserResponseDto>>>(
    () => [
      {
        fieldName: nameof<UserResponseDto>('name'),
        width: '35%',
        cellRenderer: (data) => (
          <TextCellRenderer
            textClassName={cn({
              [styles.textUserBlocked]: data.isBlocked,
            })}
            value={`${data.name ?? ''} ${data.surname ?? ''}`}
          />
        ),
      },
      {
        width: '20%',
        fieldName: nameof<UserResponseDto>('email'),
        cellRenderer: (data) => (
          <TextCellRenderer
            textClassName={cn(styles.text, {
              [styles.textUserBlocked]: data.isBlocked,
            })}
            value={data.email}
          />
        ),
      },
      {
        fieldName: nameof<UserResponseDto>('role'),
        width: '20%',
        cellRenderer: (data) => (
          <EnumCellRenderer
            enumName='UserRole'
            enumObject={UserRole}
            value={data.role}
            textClassName={cn(styles.text, {
              [styles.textUserBlocked]: data.isBlocked,
            })}
          />
        ),
      },
      {
        fieldName: nameof<UserResponseDto>('isBlocked'),
        width: '18%',
        cellRenderer: (data) =>
          data.isBlocked ? (
            <TextCellRenderer
              textClassName={cn(styles.text, styles.textUserBlocked)}
              iconClassName={styles.cellIcon}
              value={t('users.status.blocked')}
              icon={<Icon className={cn(styles.icon, styles.iconBlocked)} name='blocked' />}
            />
          ) : (
            <TextCellRenderer
              textClassName={cn(styles.text)}
              iconClassName={styles.cellIcon}
              value={t('users.status.active')}
              icon={<Icon className={cn(styles.icon, styles.iconActive)} name='done' />}
            />
          ),
      },
    ],
    [t],
  );

  return columns;
};
