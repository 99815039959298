import { Tab as BuiltinTab } from '@headlessui/react';
import cn from 'classnames';
import { FunctionComponent } from 'react';
import styles from './TabPanels.module.scss';

export const TabPanels: FunctionComponent<Parameters<typeof BuiltinTab['Panels']>[0]> = (props) => {
  const { className, ...restProps } = props;

  return <BuiltinTab.Panels className={cn(styles.root, className)} {...restProps} />;
};
