import React, { FunctionComponent } from 'react';
import { HTTP_CODES } from 'infrastructure/network';
import { useAppSelector } from 'infrastructure/redux';
import { NotFoundScreen } from 'screens/common';
import { ErrorBoundaryProps } from './ErrorBoundaryProps';

export const ErrorBoundary: FunctionComponent<ErrorBoundaryProps> = (props) => {
  const { children } = props;
  const { httpStatusCode } = useAppSelector((state) => state.errorBoundary);

  switch (httpStatusCode) {
    case HTTP_CODES.NOT_FOUND: {
      return <NotFoundScreen />;
    }
    default: {
      return children;
    }
  }
};
