import { skipToken } from '@reduxjs/toolkit/query/react';
import cn from 'classnames';
import { FunctionComponent } from 'react';
import { folderViewActions, useFetchFolderDetailQuery } from 'features/folders';
import { useTranslateEnum } from 'infrastructure/i18n';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux';
import { Modal } from 'ui-library/components/Modal';
import { Tab } from 'ui-library/components/Tab';
import styles from './FolderView.module.scss';
import { FolderViewHeader } from './FolderViewHeader/FolderViewHeader';
import { FolderViewInfoTab } from './FolderViewInfoTab/FolderViewInfoTab';
import { FolderViewTab } from './FolderViewTab';

export const FolderView: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const { isOpen: isEditOpen } = useAppSelector((state) => state.folderEdit);
  const { isOpen, selectedFolderId } = useAppSelector((state) => state.folderView);
  const { translateValue } = useTranslateEnum('FolderViewTab', FolderViewTab);
  const { data } = useFetchFolderDetailQuery(selectedFolderId ?? skipToken);

  const handleClose = () => {
    dispatch(folderViewActions.close());
    dispatch(folderViewActions.selectFolder(undefined));
  };

  return (
    <Modal open={isOpen} onClose={handleClose} closeOnChangeLocation>
      <Modal.Content className={cn(styles.root, isEditOpen ? styles.childModalOpened : undefined)}>
        <FolderViewHeader folder={data} />
        <Tab.Group as='div' className={styles.tabs}>
          <Tab.List>
            <Tab>{translateValue(FolderViewTab.INFORMATION)}</Tab>
          </Tab.List>
          <Tab.Panels>
            <Tab.Panel>
              <FolderViewInfoTab folder={data} />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </Modal.Content>
    </Modal>
  );
};
