import { PayloadAction } from '@reduxjs/toolkit';
import { PageChangedPayload } from './paginationPayloads';
import { PaginationState } from './paginationState';

export const getPaginationReducers = <TState>(selector: (state: TState) => PaginationState) => ({
  pageChanged: (state: TState, action: PayloadAction<PageChangedPayload>) => {
    const pagination = selector(state);

    pagination.currentPage = action.payload.page;
    if (action.payload.pageSize != null) {
      pagination.pageSize = action.payload.pageSize;
    }
  },
});
