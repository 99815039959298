import cn from 'classnames';
import { FunctionComponent, PropsWithChildren, useRef } from 'react';
import { useIsSticky } from 'features/tracking';
import styles from './ModalActions.module.scss';
import { ModalActionsProps } from './ModalActionsProps';

export const ModalActions: FunctionComponent<PropsWithChildren<ModalActionsProps>> = (props) => {
  const { children, className } = props;

  const rootRef = useRef<HTMLDivElement | null>(null);
  const isSticky = useIsSticky(rootRef);

  return (
    <div
      ref={rootRef}
      className={cn(styles.root, className, {
        [styles.sticky]: isSticky,
      })}
    >
      {children}
    </div>
  );
};
