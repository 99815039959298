import cn from 'classnames';
import { FunctionComponent } from 'react';
import { FolderPartition } from 'features/folders';
import { useLanguageRelevantData } from 'infrastructure/i18n';
import { getFolderDetailRoute } from 'infrastructure/routes/paths';
import { Link, LinkKind } from 'ui-library/components/Link';
import styles from './ImageGalleryFolderHeader.module.scss';
import { ImageGalleryFolderHeaderProps } from './ImageGalleryFolderHeaderProps';

export const ImageGalleryFolderHeader: FunctionComponent<ImageGalleryFolderHeaderProps> = (props) => {
  const { folder } = props;
  const { matchProp } = useLanguageRelevantData();

  if (folder.parent === undefined) {
    return null;
  }

  const caption = matchProp(folder.captionRu, folder.captionEn);

  return (
    <div className={styles.root}>
      <Link as={LinkKind.INNER} to={getFolderDetailRoute(folder.id, FolderPartition.MUSEUM)}>
        <span
          className={cn(styles.folder, {
            [styles['level0']]: folder.level === 0,
            [styles['level1']]: folder.level > 0,
          })}
        >
          {caption}
        </span>
      </Link>

      <span className={styles.parent}>{matchProp(folder.parent.captionRu, folder.parent.captionEn)}</span>
    </div>
  );
};
