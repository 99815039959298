import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { createSearchParams, Link, useLocation } from 'react-router-dom';
import { FilePreviewLabel } from 'features/file';
import { LayoutCardListGrid, LayoutCardListSkeleton, LayoutDetailRouteSearchQueryParams } from 'features/layouts';
import { getLayoutDetailRoute } from 'infrastructure/routes/paths';
import { EmptySearch } from '../../EmptySearch/EmptySearch';
import { LayoutCard } from '../LayoutCard/LayoutCard';
import styles from './LayoutCardList.module.scss';
import { LayoutCardListProps } from './LayoutCardListProps';

export const LayoutCardList: FunctionComponent<LayoutCardListProps> = (props) => {
  const { t } = useTranslation();
  const { data, isFetching } = props;
  const location = useLocation();
  const params: LayoutDetailRouteSearchQueryParams = { redirectUrl: location.pathname + location.search };
  const isEmpty = data?.totalCount === 0;

  if (isEmpty) {
    return <EmptySearch className={styles.placeholder} message={t('search.emptyLayouts')} />;
  }

  return (
    <LayoutCardListGrid>
      {isFetching ? (
        <LayoutCardListSkeleton />
      ) : (
        data?.content.map((x) => (
          <Link key={x.id} to={getLayoutDetailRoute(x.id) + `?${createSearchParams(params)}`}>
            <LayoutCard layout={x} previewLabel={FilePreviewLabel.LAYOUT_SEARCH} />
          </Link>
        ))
      )}
    </LayoutCardListGrid>
  );
};
