import { useTranslation } from 'react-i18next';
import { NumberFormatter } from 'utils';

const CHUNK_SIZE = 1024;

export const useHumanReadableFileSize = () => {
  const { t } = useTranslation();

  const convertToHumanReadable = (size: number | undefined) => {
    if (!size) {
      return '';
    }

    const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'].map((x) => t(`files.size.${x}` as never));
    let newSize = size;
    let i = 0;

    while (newSize >= CHUNK_SIZE) {
      newSize /= CHUNK_SIZE;
      ++i;
    }

    const formattedValue = NumberFormatter.getFormattedNumberString(newSize) + ' ' + units[i];

    return formattedValue;
  };

  return { convertToHumanReadable };
};
