import { translateKey } from 'infrastructure/i18n';
import { ReferenceBookType } from '../referenceBookEntity';

export class FormTranslationsService {
  public getTranslations(type: ReferenceBookType) {
    return {
      createTitle: translateKey(`referenceBook.form.${type}.createTitle`),
      editTitle: translateKey(`referenceBook.form.${type}.editTitle`),
      deleteTitle: translateKey(`referenceBook.form.${type}.deleteTitle`),
      editDescription: translateKey(`referenceBook.form.${type}.editDescription`),
      deleteDescription: translateKey(`referenceBook.form.${type}.deleteDescription`),
      captionRuLabel: translateKey(`referenceBook.form.${type}.captionRuLabel`),
      captionEnLabel: translateKey(`referenceBook.form.${type}.captionEnLabel`),
      captionPlaceholder: translateKey(`referenceBook.form.${type}.captionPlaceholder`),
      createSuccessToastMessage: translateKey(`referenceBook.form.${type}.createSuccessToastMessage`),
      editSuccessToastMessage: translateKey(`referenceBook.form.${type}.editSuccessToastMessage`),
      createFailedToastMessage: translateKey(`referenceBook.form.${type}.createFailedToastMessage`),
      editFailedToastMessage: translateKey(`referenceBook.form.${type}.editFailedToastMessage`),
      editSuccessDeleteToastMessage: translateKey(`referenceBook.form.${type}.editSuccessDeleteToastMessage`),
      editFailedDeleteToastMessage: translateKey(`referenceBook.form.${type}.editFailedDeleteToastMessage`),
      createConflictToastMessage: translateKey(`referenceBook.form.${type}.createConflictToastMessage`),
    };
  }
}

export const formTranslationsService = new FormTranslationsService();
