import { nanoid } from 'nanoid';
import { forwardRef, useRef } from 'react';
import { ErrorMessage } from 'infrastructure/form/components';
import { FormControl } from '../FormControl';
import { FormHelperText } from '../FormHelperText';
import { Input } from '../Input';
import { Label } from '../Label';
import { TextFieldProps } from './TextFieldProps';

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>((props, ref) => {
  const { id, error, disabled, readOnly, label, required, FormHelperTextProps, InputProps, LabelProps, LayoutProps } =
    props;
  const inputId = useRef(id ?? nanoid()).current;
  const helperTextId = useRef(nanoid()).current;

  return (
    <FormControl>
      {label && (
        <Label disabled={disabled || readOnly} htmlFor={inputId} label={label} required={required} {...LabelProps} />
      )}
      <Input
        LayoutProps={{
          ...LayoutProps,
          error: !!error,
        }}
        disabled={disabled}
        readOnly={readOnly}
        required={required}
        id={inputId}
        ref={ref}
        aria-describedby={helperTextId}
        aria-invalid={error ? 'true' : 'false'}
        {...InputProps}
      />
      <FormHelperText id={helperTextId} error={!!error} {...FormHelperTextProps}>
        {error && <ErrorMessage error={error} />}
      </FormHelperText>
    </FormControl>
  );
});
