import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getDefaultModalState, getModalReducers, ModalState } from 'features/modal';
import { ImageEditTab } from '../components/ImageEditForm/ImageEditTab';

export type ImageEditState = ModalState & {
  selectedImageId?: string;
  selectedTab: ImageEditTab;
};

const initialState: ImageEditState = {
  ...getDefaultModalState(),
  selectedImageId: undefined,
  selectedTab: ImageEditTab.INFORMATION,
};

const imageEditSlice = createSlice({
  name: 'imageEdit',
  initialState,
  reducers: {
    ...getModalReducers<ImageEditState>((state) => state),
    selectImage: (state, action: PayloadAction<string | undefined>) => {
      state.selectedImageId = action.payload;
    },
    selectTab: (state, action: PayloadAction<ImageEditTab>) => {
      state.selectedTab = action.payload;
    },
  },
});

export const imageEditActions = imageEditSlice.actions;
export const imageEditReducer = imageEditSlice.reducer;
