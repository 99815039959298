import { FunctionComponent } from 'react';
import { CardVariant } from 'ui-library/components/Card';
import { Skeleton } from 'ui-library/v1/components/Skeleton';
import { FavoriteGroupCardRoot } from '../FavoriteGroupCardRoot/FavoriteGroupCardRoot';
import styles from './FavoriteGroupCardListSkeleton.module.scss';

const amountOfCards = 4;

export const FavoriteGroupCardListSkeleton: FunctionComponent = () => {
  return (
    <>
      {[...Array(amountOfCards)].map((_, idx) => (
        <FavoriteGroupCardRoot key={idx} className={styles.card} variant={CardVariant.TRANSPARENT}>
          <Skeleton width={160} className={styles.row} />
        </FavoriteGroupCardRoot>
      ))}
    </>
  );
};
