import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { FilePreviewLabel } from 'features/file';
import { Image } from 'ui-library/v1/components/Image';
import Placeholder from 'ui-library/v1/components/Image/assets/PlaceholderFill.svg';
import { LayoutDetailImageButtonGroup } from '../LayoutDetailImageButtonGroup/LayoutDetailImageButtonGroup';
import styles from './LayoutDetailImagePanel.module.scss';
import { LayoutDetailImagePanelProps } from './LayoutDetailImagePanelProps';

const FALLBACK_IMAGE = Placeholder;
export const LayoutDetailImagePanel: FunctionComponent<LayoutDetailImagePanelProps> = (props) => {
  const { t } = useTranslation();
  const { layout } = props;

  const preview = layout?.file?.previews?.find((x) => x.label === FilePreviewLabel.LAYOUT_PREVIEW);

  return (
    <div className={styles.root}>
      <LayoutDetailImageButtonGroup className={styles.buttonGroup} layout={layout} />

      <Image className={styles.image} src={preview?.url} alt={t('layouts.imageAlt')} fallbackSrc={FALLBACK_IMAGE} />
    </div>
  );
};
