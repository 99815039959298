import cn from 'classnames';
import { CSSProperties, FunctionComponent } from 'react';
import styles from './Skeleton.module.scss';
import { SkeletonProps } from './SkeletonProps';

/**
 * @deprecated
 */
export const Skeleton: FunctionComponent<SkeletonProps> = (props) => {
  const { color = 'light', radius, width, height, className } = props;

  return (
    <div
      style={
        {
          '--border-radius': radius,
          '--width': width,
          '--height': height,
        } as CSSProperties
      }
      className={cn(className, styles.line, {
        [styles.light]: color === 'light',
        [styles.dark]: color === 'dark',
      })}
    ></div>
  );
};
