import { FunctionComponent } from 'react';
import { LayoutDetailDataView } from './components/LayoutDetailDataView/LayoutDetailDataView';

export const LayoutDetailScreen: FunctionComponent = () => {
  return (
    <>
      <LayoutDetailDataView />
    </>
  );
};
