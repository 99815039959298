import React, { FunctionComponent } from 'react';
import { FormProvider } from 'react-hook-form';
import { useForm } from 'infrastructure/form';
import { Modal } from 'ui-library/components/Modal';
import { FileDownloadAgreementFormFields } from '../../fileEntity';
import { fileDownloadService } from '../../services';
import { FileDownloadAgreementActions } from '../FileDownloadAgreementActions/FileDownloadAgreementActions';
import { FileDownloadAgreementContent } from '../FileDownloadAgreementContent/FileDownloadAgreementContent';
import { FileDownloadAgreementFormProps } from './FileDownloadAgreementFormProps';

export const FileDownloadAgreementForm: FunctionComponent<FileDownloadAgreementFormProps> = (props) => {
  const { handleAgree, handleClose } = props;
  const methods = useForm<FileDownloadAgreementFormFields>({
    defaultValues: {
      doNotShowAnymore: false,
    },
  });
  const { handleSubmit } = methods;

  const submit = (values: FileDownloadAgreementFormFields) => {
    fileDownloadService.setUserAgreement(values.doNotShowAnymore);

    handleAgree();
    handleClose();
  };

  return (
    <FormProvider {...methods}>
      <Modal.Form onSubmit={handleSubmit(submit)}>
        <Modal.Content>
          <FileDownloadAgreementContent />
        </Modal.Content>
        <Modal.Actions>
          <FileDownloadAgreementActions handleClose={handleClose} />
        </Modal.Actions>
      </Modal.Form>
    </FormProvider>
  );
};
