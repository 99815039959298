import cn from 'classnames';
import React, { FunctionComponent } from 'react';
import styles from './ListItemText.module.scss';
import { ListItemTextProps } from './ListItemTextProps';

export const ListItemText: FunctionComponent<ListItemTextProps> = (props) => {
  const { primary, classes, inset, className } = props;

  return (
    <div className={cn(styles.root, className, { [styles.inset]: inset })}>
      <div className={cn(styles.primary, classes?.primary)}>{primary}</div>
    </div>
  );
};
