import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from 'ui-library/components/Icon';
import { ZeroScreenWrapper } from 'ui-library/components/ZeroScreen';
import { FileTaskListEmptyProps } from './FileTaskListEmptyProps';

export const FileTaskListEmpty: FunctionComponent<FileTaskListEmptyProps> = (props) => {
  const { className } = props;
  const { t } = useTranslation();

  return (
    <ZeroScreenWrapper
      className={className}
      theme='dark'
      renderIcon={(props) => <Icon name='documentIllustration' size='none' {...props} />}
      message={t('files.downloads.empty')}
    />
  );
};
