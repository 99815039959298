import React, { FunctionComponent } from 'react';
import { CardVariant } from 'ui-library/components/Card';
import { Skeleton } from 'ui-library/v1/components/Skeleton';
import { LayoutCardRoot } from '../LayoutCardRoot/LayoutCardRoot';
import styles from './LayoutCardSkeleton.module.scss';

export const LayoutCardSkeleton: FunctionComponent = () => {
  return (
    <LayoutCardRoot className={styles.card} variant={CardVariant.TRANSPARENT}>
      <Skeleton className={styles.skeleton} width='100%' />
    </LayoutCardRoot>
  );
};
