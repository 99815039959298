import cn from 'classnames';
import React, { FunctionComponent } from 'react';
import styles from './FormRow.module.scss';
import { FormRowProps } from './FormRowProps';

export const FormRow: FunctionComponent<FormRowProps> = (props) => {
  const { className, ...rest } = props;

  return <div {...rest} className={cn(styles.formRow, className)} />;
};
