import { createApi } from '@reduxjs/toolkit/query/react';
import { fetchBaseAuthQuery, HTTP_METHODS, URL_FACTORY } from 'infrastructure/network';
import { ChangeOrderFolderRequestParams, ChangeOrderImageRequestParams } from './changeOrderEntity';

export const changeOrderApi = createApi({
  reducerPath: 'changeOrderApi',
  baseQuery: fetchBaseAuthQuery(),
  endpoints: (builder) => ({
    updateImageOrder: builder.mutation<unknown, ChangeOrderImageRequestParams>({
      query: ({ folderID, ...rest }) => ({
        url: URL_FACTORY.FOLDERS.GALLERY(folderID),
        method: HTTP_METHODS.PATCH,
        body: rest,
      }),
    }),
    updateFolderOrder: builder.mutation<unknown, ChangeOrderFolderRequestParams>({
      query: ({ parentID, ...rest }) => ({
        url: URL_FACTORY.FOLDERS.CHILDREN(parentID),
        method: HTTP_METHODS.PATCH,
        body: rest,
      }),
    }),
  }),
});

export const { useUpdateImageOrderMutation, useUpdateFolderOrderMutation } = changeOrderApi;
