import React, { FunctionComponent } from 'react';
import { Spinner } from 'ui-library/components/Spinner';
import styles from './FavoriteGroupListFooter.module.scss';

export const FavoriteGroupListFooter: FunctionComponent = () => {
  return (
    <div className={styles.root}>
      <Spinner size='small' />
    </div>
  );
};
