import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Image } from 'ui-library/components/Image';
import styles from './Logo.module.scss';

export const Logo: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <Image className={styles.icon} loading='eager' src={process.env.PUBLIC_URL + '/favicon-32×32.png'} />
      <span className={styles.title}>{t('header.logoText')}</span>
    </div>
  );
};
