import { skipToken } from '@reduxjs/toolkit/query';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FolderEditLinkedImagesRequestDto,
  useEditFolderLinkedImagesMutation,
  useFetchFolderDetailQuery,
} from 'features/folders';
import { useFetchFolderImagesQuery } from 'features/images';
import { LayoutEditRequestDto, useEditLayoutMutation, useFetchFolderLayoutsQuery } from 'features/layouts';
import { linkingArtifactActions } from 'features/linking';
import { useLinkingArtifact } from 'features/linking/hooks';
import { NotDefinedParamException } from 'infrastructure/exceptions';
import { useLanguageRelevantData } from 'infrastructure/i18n';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux';
import { ActionButton } from 'ui-library/components/ActionButton';
import { ButtonKind, ButtonVariant } from 'ui-library/components/Button';
import styles from './LinkingArtifactNavigatorActions.module.scss';

export const LinkingArtifactNavigatorActions = () => {
  const dispatch = useAppDispatch();
  const { selectedFolderId, linkingArtifacts, type } = useAppSelector((state) => state.linkingArtifacts);
  const { data: selectedFolderData } = useFetchFolderDetailQuery(selectedFolderId ?? skipToken);
  const { t } = useTranslation();
  const {
    handleLinkingLayoutSuccessful,
    handleLinkingLayoutError,
    handleLinkingImageSuccessful,
    handleLinkingImageError,
  } = useLinkingArtifact();
  const { matchProp } = useLanguageRelevantData();
  const [editLayoutMutation] = useEditLayoutMutation();
  const [editFolderLinkedImagesMutation] = useEditFolderLinkedImagesMutation();

  const shouldFetchLinkedLayoutsData = type === 'layout' && selectedFolderId;
  const shouldFetchLinkedImagesData = type === 'image' && selectedFolderId;
  const singleArtifact = linkingArtifacts.length === 1 && linkingArtifacts[0];

  const { data: linkedLayoutsData } = useFetchFolderLayoutsQuery(
    shouldFetchLinkedLayoutsData
      ? { folderID: selectedFolderId, pageSize: Number.MAX_SAFE_INTEGER, currentPage: 1 }
      : skipToken,
  );
  const { data: linkedImagesData } = useFetchFolderImagesQuery(
    shouldFetchLinkedImagesData
      ? { folderID: selectedFolderId, pageSize: Number.MAX_SAFE_INTEGER, currentPage: 1 }
      : skipToken,
  );

  const handleCancel = () => {
    dispatch(linkingArtifactActions.dispose());
  };

  const areLayoutsAlreadyLinked = useMemo(
    () => linkingArtifacts.every((x) => linkedLayoutsData?.content.some((layout) => layout.id === x.entityId)),
    [linkedLayoutsData?.content, linkingArtifacts],
  );
  const areImagesAlreadyLinked = useMemo(
    () => linkingArtifacts.every((x) => linkedImagesData?.content.some((layout) => layout.id === x.entityId)),
    [linkedImagesData?.content, linkingArtifacts],
  );

  const canSubmit = () => {
    const isAlreadyLinked = areLayoutsAlreadyLinked || areImagesAlreadyLinked;

    return selectedFolderId && !isAlreadyLinked;
  };

  const handleLinkLayout = async () => {
    if (!singleArtifact) {
      throw new NotDefinedParamException('Layout');
    }

    const dtoLayout: LayoutEditRequestDto = {
      layoutID: singleArtifact.entityId,
      folderIDsToAdd: selectedFolderId ? [selectedFolderId] : [],
    };

    try {
      await editLayoutMutation(dtoLayout).unwrap();
      handleLinkingLayoutSuccessful();
    } catch (e) {
      handleLinkingLayoutError();
    }
  };

  const handleLinkImage = async () => {
    if (!linkingArtifacts || linkingArtifacts.length === 0) {
      throw new NotDefinedParamException('images');
    }

    if (!linkingArtifacts[0].parentID) {
      throw new NotDefinedParamException('parent ID');
    }

    if (!selectedFolderId) {
      throw new NotDefinedParamException('selected folder');
    }

    const dtoImage: FolderEditLinkedImagesRequestDto = {
      imageIDs: linkingArtifacts.map((x) => x.entityId),
      folderID: selectedFolderId,
      correspondingFolderID: linkingArtifacts[0].parentID,
    };

    try {
      await editFolderLinkedImagesMutation(dtoImage).unwrap();
      handleLinkingImageSuccessful();
    } catch (e) {
      handleLinkingImageError();
    }
  };

  const handleLink = async () => {
    if (type === 'layout') {
      await handleLinkLayout();
    } else if (type === 'image') {
      await handleLinkImage();
    }
  };

  return (
    <div className={styles.root}>
      {areLayoutsAlreadyLinked && (
        <span className={styles.text}>
          {t(
            selectedFolderData?.isRoot
              ? 'linkingArtifacts.actions.alreadyLinkLayoutToRoot'
              : 'linkingArtifacts.actions.alreadyLinkLayoutToFolder',
            {
              name: matchProp(selectedFolderData?.captionRu, selectedFolderData?.captionEn),
              count: linkingArtifacts.length,
            },
          )}
        </span>
      )}
      {areImagesAlreadyLinked && (
        <span className={styles.text}>
          {t(
            selectedFolderData?.isRoot
              ? 'linkingArtifacts.actions.alreadyLinkImageToRoot'
              : 'linkingArtifacts.actions.alreadyLinkImageToFolder',
            {
              name: matchProp(selectedFolderData?.captionRu, selectedFolderData?.captionEn),
              count: linkingArtifacts.length,
            },
          )}
        </span>
      )}
      <div className={styles.buttonGroup}>
        <ActionButton as={ButtonKind.BUTTON} disabled={!canSubmit()} onClick={handleLink}>
          {t('linkingArtifacts.actions.link')}
        </ActionButton>
        <ActionButton as={ButtonKind.BUTTON} variant={ButtonVariant.SECONDARY} onClick={handleCancel}>
          {t('actions.cancel')}
        </ActionButton>
      </div>
    </div>
  );
};
