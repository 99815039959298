import { FunctionComponent } from 'react';
import { FolderCardBase } from 'features/v1/folder/FolderCardBase/FolderCardBase';
import { useFolderActions } from '../../../hooks/useFolderActions';
import { FolderCardExternalProps } from './FolderCardProps';

export const FolderCard: FunctionComponent<FolderCardExternalProps> = (props) => {
  const { folder, groupId } = props;
  const { actions } = useFolderActions({ folder, groupId });

  return <FolderCardBase folder={folder} actions={actions} />;
};
