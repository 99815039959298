import { Menu as BuiltinMenu } from '@headlessui/react';
import { FunctionComponent } from 'react';
import styles from './MenuItems.module.scss';
import { MenuItemsProps } from './MenuItemsProps';

export const MenuItems: FunctionComponent<MenuItemsProps> = (props) => {
  const { children } = props;

  return (
    <BuiltinMenu.Items className={styles.root} as='ul'>
      {children}
    </BuiltinMenu.Items>
  );
};
