import { Dialog } from '@headlessui/react';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonKind } from 'ui-library/components/Button';
import { Icon } from 'ui-library/components/Icon';
import { IconButton } from 'ui-library/components/IconButton';
import { Image } from 'ui-library/components/Image';
import styles from './ImageFullViewer.module.scss';
import { ImageFullViewerProps } from './ImageFullViewerProps';

export const ImageFullViewer: FunctionComponent<ImageFullViewerProps> = (props) => {
  const { t } = useTranslation();
  const { src, isOpen, close } = props;

  return (
    <Dialog open={isOpen} onClose={close} className={styles.root}>
      <div className={styles.backdrop} />
      <div className={styles.wrap}>
        <IconButton as={ButtonKind.BUTTON} onClick={close} title={t('actions.close')} className={styles.close}>
          <Icon name='cross' />
        </IconButton>
        <Dialog.Panel className={styles.panel}>
          <Image className={styles.image} src={src} />
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};
