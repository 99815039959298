import { Switch as BaseSwitch } from '@headlessui/react';
import cn from 'classnames';
import { forwardRef } from 'react';
import styles from './Switch.module.scss';
import { SwitchProps } from './SwitchProps';

const SwitchRoot = forwardRef<HTMLButtonElement, SwitchProps>((props, ref) => {
  const { checked, onChange } = props;

  return (
    <BaseSwitch
      className={cn(styles.root, {
        [styles.checked]: checked,
      })}
      checked={checked}
      onChange={onChange as never}
      ref={ref}
    >
      <div
        className={cn(styles.slider, {
          [styles.checked]: checked,
        })}
        aria-hidden='true'
      />
    </BaseSwitch>
  );
});

export const Switch = Object.assign(SwitchRoot, {
  Group: BaseSwitch.Group,
  Label: BaseSwitch.Label,
});
