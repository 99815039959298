import React, { FunctionComponent } from 'react';
import { ToggleLanguageButton } from 'features/localization';
import { Logo } from './Logo/Logo';
import styles from './PublicHeader.module.scss';

export const PublicHeader: FunctionComponent = () => {
  return (
    <div className={styles.root}>
      <Logo />
      <ToggleLanguageButton theme='light' />
    </div>
  );
};
