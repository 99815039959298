import { Transition } from '@headlessui/react';
import { FunctionComponent, useEffect } from 'react';
import { fileDownloadService } from 'features/file/services/fileDownloadService';
import { activeFileDownloadTasksActions } from 'features/file/slices';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux';
import { ActiveFileDownloadTasksContent } from '../ActiveFileDownloadTasksContent/ActiveFileDownloadTasksContent';
import { ActiveFileDownloadTasksHeader } from '../ActiveFileDownloadTasksHeader/ActiveFileDownloadTasksHeader';
import styles from './ActiveFileDownloadTasks.module.scss';
import { ActiveFileDownloadTaskState } from './ActiveFileDownloadTaskState';

export const ActiveFileDownloadTasks: FunctionComponent = () => {
  const windowState = useAppSelector((state) => state.activeFileDownloadTasks.windowState);
  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(activeFileDownloadTasksActions.close());
  };

  const handleToggle = () => {
    dispatch(activeFileDownloadTasksActions.toggle());
  };

  useEffect(() => {
    fileDownloadService.setActiveTaskModalState(windowState);
  }, [windowState]);

  if (windowState === ActiveFileDownloadTaskState.CLOSED) {
    return null;
  }

  return (
    <div className={styles.root}>
      <ActiveFileDownloadTasksHeader
        onCloseClick={handleClose}
        onToggleClick={handleToggle}
        windowState={windowState}
      />
      <Transition
        show={windowState === ActiveFileDownloadTaskState.OPENED}
        enter={styles.animation}
        enterFrom={styles.animationFrom}
        enterTo={styles.animationTo}
        leave={styles.animation}
        leaveFrom={styles.animationTo}
        leaveTo={styles.animationFrom}
      >
        <ActiveFileDownloadTasksContent />
      </Transition>
    </div>
  );
};
