import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getDefaultModalState, getModalReducers, ModalState } from 'features/modal';
import { ReferenceBook } from 'features/referenceBook/referenceBookEntity';

export type AuthorDeleteState = ModalState & {
  author?: ReferenceBook;
};

const initialState: AuthorDeleteState = {
  ...getDefaultModalState(),
};

const authorDeleteSlice = createSlice({
  name: 'authorDelete',
  initialState,
  reducers: {
    ...getModalReducers<AuthorDeleteState>((state) => state),
    setAuthor: (state, action: PayloadAction<ReferenceBook | undefined>) => {
      state.author = action.payload;
    },
  },
});

export const authorDeleteActions = authorDeleteSlice.actions;
export const authorDeleteReducer = authorDeleteSlice.reducer;
