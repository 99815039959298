import cn from 'classnames';
import React, { ButtonHTMLAttributes, forwardRef, PropsWithChildren } from 'react';
import { InnerLinkProps, Link, LinkKind, OuterLinkProps } from '../Link';
import styles from './Button.module.scss';
import { ButtonContent } from './ButtonContent/ButtonContent';
import { ButtonContentProps } from './ButtonContent/ButtonContentProps';
import { ButtonKind } from './ButtonKind';
import { ButtonProps, ButtonVariant } from './ButtonProps';

export const Button = forwardRef<HTMLAnchorElement | HTMLButtonElement, PropsWithChildren<ButtonProps>>(
  (props, ref) => {
    const {
      variant = ButtonVariant.MAIN,
      size = 'normal',
      className,
      as,
      loading,
      loadingVariant,
      startIcon,
      children,
      theme = 'default',
      ...restProps
    } = props;

    const totalClassName = cn(styles.button, styles[variant], styles[size], styles[`theme-${theme}`], className, {
      [styles.loading]: loading,
    });

    const buttonContentProps: ButtonContentProps = {
      variant,
      startIcon,
      children,
      loading,
      loadingVariant,
    };

    switch (as) {
      case ButtonKind.BUTTON: {
        const { disabled, ...btnRestProps } = restProps as ButtonHTMLAttributes<HTMLButtonElement>;

        return (
          <button
            className={totalClassName}
            ref={ref as React.ForwardedRef<HTMLButtonElement>}
            type='button'
            disabled={disabled || loading}
            {...btnRestProps}
          >
            <ButtonContent {...buttonContentProps} />
          </button>
        );
      }
      case ButtonKind.OUTER_ANCHOR:
        return (
          <Link
            as={LinkKind.OUTER}
            className={totalClassName}
            ref={ref as React.ForwardedRef<HTMLAnchorElement>}
            {...(restProps as Omit<OuterLinkProps, 'as'>)}
          >
            <ButtonContent {...buttonContentProps} />
          </Link>
        );
      case ButtonKind.INNER_ANCHOR:
        return (
          <Link
            as={LinkKind.INNER}
            className={totalClassName}
            ref={ref as React.ForwardedRef<HTMLAnchorElement>}
            {...(restProps as Omit<InnerLinkProps, 'as'>)}
          >
            <ButtonContent {...buttonContentProps} />
          </Link>
        );
    }
  },
);
