import { skipToken } from '@reduxjs/toolkit/dist/query';
import { FunctionComponent, useEffect } from 'react';
import { FolderPartition, useFetchFolderDetailQuery } from 'features/folders';
import { movingArtifactsActions } from 'features/movingArtifacts/movingArtifactsSlice';
import { useTranslateEnum } from 'infrastructure/i18n';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux';
import { Modal } from 'ui-library/components/Modal';
import { Tab } from 'ui-library/components/Tab';
import { MovingArtifactFoldersTab } from '../MovingArtifactFoldersTab/MovingArtifactFoldersTab';
import { MovingArtifactImagesTab } from '../MovingArtifactImagesTab/MovingArtifactImagesTab';
import { MovingArtifactLayoutsTab } from '../MovingArtifactLayoutsTab/MovingArtifactLayoutsTab';
import { MovingArtifactNavigatorActions } from '../MovingArtifactNavigatorActions/MovingArtifactNavigatorActions';
import styles from './MovingArtifactNavigator.module.scss';
import { MovingArtifactNavigatorTab } from './MovingArtifactNavigatorTab';

export const MovingArtifactNavigator: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const selectedFolderId = useAppSelector((state) => state.movingArtifacts.selectedFolderId);
  const selectedTab = useAppSelector((state) => state.movingArtifacts.tab);
  const selectedPartition = useAppSelector((state) => state.movingArtifacts.selectedPartition);
  const { data } = useFetchFolderDetailQuery(selectedFolderId ?? skipToken);
  const { translateValue } = useTranslateEnum('MovingArtifactNavigatorTab', MovingArtifactNavigatorTab);
  const shouldShowLayoutTab = data && data.layoutCount > 0;
  const shouldShowImageTab = data && data.imageCount > 0;
  const shouldShowFolderTab = data && data.childrenCount > 0;

  const handleTabChange = (selectedTab: MovingArtifactNavigatorTab) => {
    dispatch(movingArtifactsActions.changeTab(selectedTab));
  };

  const tabView = (
    <Tab.Group as='div' selectedIndex={selectedTab} onChange={handleTabChange}>
      <Tab.List>
        <Tab>{translateValue(MovingArtifactNavigatorTab.FOLDERS)}</Tab>
        {shouldShowLayoutTab && <Tab>{translateValue(MovingArtifactNavigatorTab.LAYOUTS)}</Tab>}
        {shouldShowImageTab && <Tab>{translateValue(MovingArtifactNavigatorTab.IMAGES)}</Tab>}
      </Tab.List>
      <Tab.Panels>
        <Tab.Panel>
          <MovingArtifactFoldersTab partition={selectedPartition as FolderPartition} />
        </Tab.Panel>
        {shouldShowLayoutTab && (
          <Tab.Panel>
            <MovingArtifactLayoutsTab />
          </Tab.Panel>
        )}
        {shouldShowImageTab && (
          <Tab.Panel>
            <MovingArtifactImagesTab />
          </Tab.Panel>
        )}
      </Tab.Panels>
    </Tab.Group>
  );

  const getContent = () => {
    if (!selectedPartition) {
      return null;
    }

    if (selectedFolderId && data) {
      if (shouldShowFolderTab && (shouldShowLayoutTab || shouldShowImageTab)) {
        return tabView;
      } else if (shouldShowFolderTab) {
        return <MovingArtifactFoldersTab partition={selectedPartition} />;
      } else if (shouldShowLayoutTab) {
        return <MovingArtifactLayoutsTab />;
      } else if (shouldShowImageTab) {
        return <MovingArtifactImagesTab />;
      }

      return <MovingArtifactFoldersTab partition={selectedPartition} />;
    }

    return <MovingArtifactFoldersTab partition={selectedPartition} />;
  };

  useEffect(() => {
    dispatch(movingArtifactsActions.changeTab(MovingArtifactNavigatorTab.FOLDERS));
  }, [dispatch, selectedFolderId]);

  return (
    <>
      <Modal.Content className={styles.root}>{getContent()}</Modal.Content>
      <Modal.Actions>
        <MovingArtifactNavigatorActions />
      </Modal.Actions>
    </>
  );
};
