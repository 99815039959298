import { FunctionComponent } from 'react';
import { FolderResponseDto } from 'features/folders/folderEntity';
import { ListLayout, ListLayoutButtonGroup } from 'features/list';
import { rootFolderListActions } from 'features/rootFolders/slices/rootFolderListSlice';
import { SortButton } from 'features/sorting';
import { useLanguageRelevantData } from 'infrastructure/i18n';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux';
import styles from './FolderMainListHeaderButtonGroup.module.scss';

export const FolderMainListHeaderButtonGroup: FunctionComponent = () => {
  const { matchProp } = useLanguageRelevantData();
  const { sortDirection } = useAppSelector((state) => state.rootFolderList.sortingInfo);
  const viewType = useAppSelector((state) => state.rootFolderList.layoutType);
  const dispatch = useAppDispatch();

  const toggleSort = () => {
    dispatch(
      rootFolderListActions.sortChanged({
        sortField: matchProp<keyof FolderResponseDto>('captionRu', 'captionEn'),
      }),
    );
  };

  const handleChangeLayout = (layout: ListLayout) => {
    dispatch(rootFolderListActions.changeLayoutType(layout));
  };

  return (
    <div className={styles.root}>
      <SortButton className={styles.active} direction={sortDirection} toggleSort={toggleSort} />

      <ListLayoutButtonGroup active={viewType} onLayoutChanged={handleChangeLayout} />
    </div>
  );
};
