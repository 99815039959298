import { skipToken } from '@reduxjs/toolkit/dist/query';
import { FunctionComponent, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'features/search';
import { TagResponseDto, useSearchTagsQuery } from 'features/tags';
import { TagsManageContext } from 'features/tags/contexts';
import { useLanguageRelevantData } from 'infrastructure/i18n';
import { AutocompleteField } from 'ui-library/components/AutocompleteField';
import { Icon } from 'ui-library/components/Icon';
import { Tooltip } from 'ui-library/components/Tooltip';
import styles from './TagsAutocomplete.module.scss';
import { TagsAutocompleteProps } from './TagsAutocompleteProps';

export const TagsAutocomplete: FunctionComponent<TagsAutocompleteProps> = (props) => {
  const { onCreate, selectedTags } = props;
  const { t } = useTranslation();
  const { matchProp } = useLanguageRelevantData();
  const [searchQuery, setSearchQuery] = useState('');
  const debouncedSearchQuery = useDebounce(searchQuery);
  const { data = [], isFetching } = useSearchTagsQuery(
    debouncedSearchQuery
      ? {
          query: debouncedSearchQuery,
        }
      : skipToken,
  );

  const { addTags } = useContext(TagsManageContext);

  const onInternalChange = (value: TagResponseDto) => {
    addTags([value]);
  };

  const filteredOptions = useMemo(() => {
    return data.filter((tag) => {
      const foundTag = selectedTags.find((currentTag) => currentTag.id === tag.id);

      return foundTag === undefined;
    });
  }, [data, selectedTags]);

  return (
    <AutocompleteField
      value={undefined}
      onChange={onInternalChange as never}
      label={t('header.searchTags')}
      LabelProps={{
        endAdornment: (
          <Tooltip className={styles.popover} placement='top-end' message={t('tags.form.tooltip')}>
            <Icon className={styles.infoIcon} name='info' />
          </Tooltip>
        ),
      }}
      placeholder={t('header.searchTags')}
      className={styles.root}
      getOptionLabel={(option) => matchProp(option.nameRu, option.nameEn)}
      onChangeInput={setSearchQuery}
      options={filteredOptions}
      AutocompleteProps={{
        loading: isFetching,
        hideArrowIcon: true,
        onCreate,
        createLabel: t('actions.createNewTag'),
      }}
    />
  );
};
