import { Menu as BuiltinMenu } from '@headlessui/react';
import cn from 'classnames';
import { Fragment, FunctionComponent, ReactNode } from 'react';
import styles from './MenuButton.module.scss';
import { MenuButtonProps } from './MenuButtonProps';

export const MenuButton: FunctionComponent<MenuButtonProps> = (props) => {
  const { children, className } = props;

  return (
    <BuiltinMenu.Button as={Fragment}>
      <span className={cn(styles.root, className)}>{children as ReactNode}</span>
    </BuiltinMenu.Button>
  );
};
