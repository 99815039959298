import { FunctionComponent, useRef } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { imagesApi } from 'features/images';
import { ModalSubmitActions } from 'features/modal';
import {
  referenceBookApi,
  ReferenceBookEditFormFields,
  ReferenceBookType,
  useEditReferenceBookMutation,
} from 'features/referenceBook';
import { formTranslationsService } from 'features/referenceBook/services';
import { searchApi } from 'features/search';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux';
import { Modal } from 'ui-library/components/Modal';
import { ReferenceBookEditForm } from '../ReferenceBookEditForm';
import { ReferenceBookEditProps } from './ReferenceBookEditProps';

export const ReferenceBookEdit: FunctionComponent<ReferenceBookEditProps> = (props) => {
  const { type, onCreated, onClose, isOpen, defaultValues } = props;
  const { author } = useAppSelector((state) => state.authorEdit);
  const [editReferenceBookMutation] = useEditReferenceBookMutation();
  const dispatch = useAppDispatch();

  const { t } = useTranslation();
  const translations = useRef(formTranslationsService.getTranslations(type)).current;

  const onSubmit = async (values: ReferenceBookEditFormFields) => {
    try {
      const result = await editReferenceBookMutation({
        id: author?.id || '',
        captionRu: values.captionRu,
        captionEn: values.captionEn,
        type: type,
      }).unwrap();

      onCreated(result);
      dispatch(
        referenceBookApi.util.invalidateTags([
          {
            type: 'ReferenceBooks',
            id: ReferenceBookType.AUTHORS,
          },
        ]),
      );
      dispatch(imagesApi.util.invalidateTags(['Image', 'ImageGallery']));
      dispatch(searchApi.util.invalidateTags(['SearchResult']));
      toast.success(t(translations.editSuccessToastMessage));
      onClose();
    } catch (e) {
      toast.error(t(translations.editFailedToastMessage));
    }
  };

  return (
    <Modal hideBackdrop open={isOpen} onClose={onClose}>
      <Modal.Title>{t(translations.editTitle)}</Modal.Title>
      <ReferenceBookEditForm
        defaultValues={defaultValues}
        type={type}
        onSubmit={onSubmit}
        renderActions={({ isSubmitting }) => <ModalSubmitActions loading={isSubmitting} onCancel={onClose} />}
      />
    </Modal>
  );
};
