import { FilePreviewLabel } from 'features/file';
import { FolderEditFormFields, FolderResponseDto } from './folderEntity';

export const mapFolderDtoToFolderFormFields = (dto: FolderResponseDto | undefined): FolderEditFormFields => ({
  captionRu: dto?.captionRu as string,
  captionEn: dto?.captionEn as string,
  descriptionRu: dto?.descriptionRu,
  descriptionEn: dto?.descriptionEn,
  status: dto?.status,
  materialsKinds: dto?.materialsKinds || [],
  tags: dto?.tags,
  fileBox: {
    previewUrl: dto?.file?.previews?.find((x) => x.label === FilePreviewLabel.FOLDER_PREVIEW)?.url,
  },
});
