import { skipToken } from '@reduxjs/toolkit/dist/query';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useFetchFolderDetailQuery } from 'features/folders';
import { useFetchImageDetailQuery } from 'features/images';
import { useFetchLayoutDetailQuery } from 'features/layouts';
import { useLanguageRelevantData } from 'infrastructure/i18n';
import { FavoriteArtifactEntity } from '../favoriteEntity';

export const useRemoveArtifactFromFavoriteToast = (favoriteArtifact: FavoriteArtifactEntity | undefined) => {
  const { t } = useTranslation();
  const { matchProp } = useLanguageRelevantData();
  const entityId = favoriteArtifact?.entityId;
  const entityType = favoriteArtifact?.type;

  const { data: layoutData } = useFetchLayoutDetailQuery(entityType === 'layout' && entityId ? entityId : skipToken);
  const { data: folderData } = useFetchFolderDetailQuery(entityType === 'folder' && entityId ? entityId : skipToken);
  const { data: imageData } = useFetchImageDetailQuery(entityType === 'image' && entityId ? entityId : skipToken);

  const showToast = (type: 'Success' | 'Failed') => {
    let message = '';

    if (favoriteArtifact?.type === 'folder') {
      if (folderData?.isRoot) {
        message = t(`favorites.removeFromFavorite.removeThemeFromFavorite${type}`, {
          name: matchProp(folderData?.captionRu, folderData?.captionEn),
        });
      } else {
        message = t(`favorites.removeFromFavorite.removeFolderFromFavorite${type}`, {
          name: matchProp(folderData?.captionRu, folderData?.captionEn),
        });
      }
    } else if (favoriteArtifact?.type === 'layout') {
      message = t(`favorites.removeFromFavorite.removeLayoutFromFavorite${type}`, {
        name: t('layouts.properties.number', { value: layoutData?.archiveName }),
      });
    } else if (favoriteArtifact?.type === 'image') {
      message = t(`favorites.removeFromFavorite.removeImageFromFavorite${type}`, {
        name: matchProp(imageData?.captionRu, imageData?.captionEn),
      });
    }

    if (type === 'Success') {
      toast.success(message);
    } else if (type === 'Failed') {
      toast.error(message);
    }
  };

  return { showToast };
};
