import { FetchArgs } from '@reduxjs/toolkit/dist/query';
import isNil from 'lodash/isNil';
import { calculateOffset } from 'features/pagination';
import { ListApiRequest } from 'infrastructure/network';
import { ListApiFetchArgs } from 'infrastructure/network/types/requestTypes';

export function listQuery(args: FetchArgs): FetchArgs {
  const { currentPage, pageSize, sortFields, ...restArgs } = args.params as ListApiFetchArgs<unknown>;
  let offset: number | undefined;
  let limit: number | undefined;

  if (!isNil(currentPage) && !isNil(pageSize)) {
    offset = calculateOffset(currentPage, pageSize);
    limit = pageSize;
  }

  return {
    ...args,
    params: {
      ...restArgs,
      sortFields,
      offset,
      limit,
    } as ListApiRequest<unknown>,
  };
}
