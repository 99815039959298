import { ReactNode } from 'react';
import { ButtonKindProps, InnerAnchorKindProps, OuterAnchorKindProps } from './ButtonKind';

export enum ButtonVariant {
  MAIN = 'main',
  SECONDARY = 'secondary',
  TRANSPARENT = 'transparent',
}

export type ButtonSize = 'normal' | 'small';

export type ButtonProps = (ButtonKindProps | OuterAnchorKindProps | InnerAnchorKindProps) & ButtonCommonProps;

export type ButtonCommonProps = {
  loading?: boolean;
  loadingVariant?: 'replace' | 'startIcon';
  variant?: ButtonVariant;
  size?: ButtonSize;
  theme?: 'default' | 'secondary';
  startIcon?: ReactNode;
};
