import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getDefaultModalState, getModalReducers, ModalState } from 'features/modal';
import { UserResponseDto } from '../userEntity';

export type UserBlockState = ModalState & {
  user?: UserResponseDto;
};

const initialState: UserBlockState = {
  ...getDefaultModalState(),
};

const userBlockSlice = createSlice({
  name: 'userBlock',
  initialState,
  reducers: {
    ...getModalReducers<UserBlockState>((state) => state),
    setUser: (state, action: PayloadAction<UserResponseDto | undefined>) => {
      state.user = action.payload;
    },
  },
});

export const userBlockActions = userBlockSlice.actions;
export const userBlockReducer = userBlockSlice.reducer;
