import { FunctionComponent } from 'react';
import { FolderCardRoot } from 'features/folders';
import { CardVariant } from 'ui-library/components/Card';
import { Skeleton } from 'ui-library/components/Skeleton';
import { FolderCardListLayout } from '../FolderCardListLayout/FolderCardListLayout';
import styles from './FolderCardListSkeleton.module.scss';

const amountOfCards = 12;

export const FolderCardListSkeleton: FunctionComponent = () => {
  return (
    <FolderCardListLayout letter='&nbsp;'>
      {[...Array(amountOfCards)].map((_, idx) => (
        <div>
          <FolderCardRoot key={idx} className={styles.card} variant={CardVariant.TRANSPARENT}>
            <span></span>
          </FolderCardRoot>
          <Skeleton width={160} className={styles.secondRow} />
          <Skeleton className={styles.secondRow} width={80} />
        </div>
      ))}
    </FolderCardListLayout>
  );
};
