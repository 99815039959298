import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetchRootFoldersQuery } from 'features/folders';
import { ListLayout } from 'features/list';
import { AnchorButton } from 'features/navigation';
import { DEFAULT_PAGE_SIZE } from 'features/pagination';
import {
  EmptyRoot,
  ErrorLoadingRoot,
  FolderCardList,
  FolderMainListHeader,
  FolderTable,
  rootFolderCreationActions,
  rootFolderListActions,
} from 'features/rootFolders';
import { useCurrentLanguage } from 'infrastructure/i18n';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux';
import { Button, ButtonKind, ButtonVariant } from 'ui-library/v1/components/Button';
import { Pagination } from 'ui-library/v1/components/Pagination';
import styles from './RootFolderDataView.module.scss';
import { RootFolderDataViewProps } from './RootFolderDataViewProps';

export const RootFolderDataView: FunctionComponent<RootFolderDataViewProps> = (props) => {
  const { partition } = props;
  const { t } = useTranslation();
  const currentLang = useCurrentLanguage();
  const { sortDirection, sortField } = useAppSelector((state) => state.rootFolderList.sortingInfo);
  const { currentPage, pageSize } = useAppSelector((state) => state.rootFolderList.paginationData);
  const dispatch = useAppDispatch();
  const query = useFetchRootFoldersQuery({
    currentPage,
    pageSize,
    sortDirection,
    sortFields: currentLang === 'ru' ? ['captionRu'] : ['captionEn'],
    partition,
  });

  const layoutType = useAppSelector((state) => state.rootFolderList.layoutType);

  const isEmpty = query.data?.totalCount === 0;

  const layoutView = layoutType === ListLayout.CARD ? <FolderCardList {...query} /> : <FolderTable {...query} />;

  const onChangeLoadData = () => {
    if (pageSize === DEFAULT_PAGE_SIZE) {
      dispatch(rootFolderListActions.pageChanged({ page: 1, pageSize: Number.MAX_SAFE_INTEGER }));
    } else {
      dispatch(rootFolderListActions.dispose());
    }
  };

  const getContent = () => {
    if (query.isError) {
      return <ErrorLoadingRoot className={styles.error} onReload={query.refetch} />;
    }

    return isEmpty ? <EmptyRoot className={styles.error} partition={partition} /> : layoutView;
  };

  const handleCreate = () => {
    dispatch(rootFolderCreationActions.open());
    dispatch(rootFolderCreationActions.selectPartition(partition));
  };

  return (
    <div className={styles.root}>
      <AnchorButton />
      <FolderMainListHeader onCreate={handleCreate} />
      <div className={styles.listWrap}>
        <>{getContent()}</>
      </div>
      {(!query.isError || isEmpty) && (
        <Button
          as={ButtonKind.BUTTON}
          variant={ButtonVariant.SECONDARY}
          onClick={onChangeLoadData}
          size='small'
          className={styles.button}
        >
          {pageSize === DEFAULT_PAGE_SIZE ? t('actions.addData') : t('actions.hideData')}
        </Button>
      )}
      <div className={styles.paginationWrap}>
        <Pagination
          totalItems={query.data?.totalCount}
          pageSize={pageSize}
          onPageChanged={(page) => dispatch(rootFolderListActions.pageChanged({ page }))}
          currentPage={currentPage}
          scrollToStartAfterPageChanged
        />
      </div>
    </div>
  );
};
