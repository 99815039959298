import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum FolderArchiveDetailViewType {
  FOLDERS,
  LAYOUTS,
}

export type FolderArchiveDetailState = {
  currentFolderId: string | undefined;
};

const initialState: FolderArchiveDetailState = {
  currentFolderId: undefined,
};

const folderArchiveDetailSlice = createSlice({
  name: 'folderArchiveDetail',
  initialState,
  reducers: {
    setCurrentFolderId: (state, action: PayloadAction<string | undefined>) => {
      state.currentFolderId = action.payload;
    },
  },
});

export const folderArchiveDetailActions = folderArchiveDetailSlice.actions;
export const folderArchiveDetailReducer = folderArchiveDetailSlice.reducer;
