import { FunctionComponent } from 'react';

import styles from './FolderCardListGrid.module.scss';
import { FolderCardListGridProps } from './FolderCardListGridProps';

export const FolderCardListGrid: FunctionComponent<FolderCardListGridProps> = (props) => {
  const { children } = props;

  return <div className={styles.container}>{children}</div>;
};
