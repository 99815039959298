import cn from 'classnames';
import { forwardRef, useMemo } from 'react';
import { FilePreviewLabel } from 'features/file';
import { CardVariant } from 'ui-library/components/Card';
import { CardActionsMenu } from 'ui-library/components/CardActionsMenu';
import { Icon } from 'ui-library/components/Icon';
import { Image } from 'ui-library/components/Image';
import { LayoutCardRoot } from '../LayoutCardRoot/LayoutCardRoot';
import styles from './LayoutCardBase.module.scss';
import { LayoutCardBaseProps } from './LayoutCardBaseProps';

export const LayoutCardBase = forwardRef<HTMLDivElement, LayoutCardBaseProps>((props, ref) => {
  const { layout, className, previewLabel = FilePreviewLabel.LAYOUT_MAIN, actions } = props;

  const preview = useMemo(() => layout.file?.previews?.find((x) => x.label === previewLabel), [layout, previewLabel]);

  return (
    <LayoutCardRoot className={cn(styles.root, className)} variant={CardVariant.TRANSPARENT} ref={ref}>
      {actions && <CardActionsMenu className={styles.actions} actions={actions} />}
      {layout.isFavourite && <Icon className={styles.favorite} name='favoriteFilled' />}
      <Image className={styles.image} src={preview?.url} alt='' />
    </LayoutCardRoot>
  );
});
