import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useModalState } from 'features/modal';
import { ReferenceBookCreate, ReferenceBookEditFormFields, ReferenceBookType } from 'features/referenceBook';
import { AuthorDelete, AuthorsDataView, AuthorsSearchBar, ReferenceBookEdit } from 'features/referenceBook/components';
import { authorEditActions } from 'features/referenceBook/components/AuthorsReferenceBook';
import { userAccessRuleService } from 'features/role';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux';
import { useScrollToTop } from 'infrastructure/routes';
import styles from './AuthorScreen.module.scss';

export const AuthorScreen: FunctionComponent = () => {
  const { t } = useTranslation();
  useScrollToTop();
  const dispatch = useAppDispatch();
  const { authorsQuery } = useAppSelector((state) => state.authorListQuery);
  const createModalState = useModalState();
  const { isOpen, author } = useAppSelector((state) => state.authorEdit);
  const defaultValues: ReferenceBookEditFormFields = {
    captionRu: author?.captionRu || '',
    captionEn: author?.captionEn || '',
  };

  return (
    <>
      <div className={styles.wrap}>
        <h1 className={styles.title}>{t('referenceBook.title.authors')}</h1>
        <p className={styles.description}>{t('referenceBook.description.authors')}</p>
        <AuthorsSearchBar />
        {userAccessRuleService.canViewUI() && (
          <AuthorsDataView authorsQuery={authorsQuery} isOpen={createModalState.open} />
        )}
      </div>
      <ReferenceBookCreate
        isOpen={createModalState.isOpen}
        type={ReferenceBookType.AUTHORS}
        onClose={createModalState.close}
        onCreated={() => {}}
      />
      <ReferenceBookEdit
        isOpen={isOpen}
        type={ReferenceBookType.AUTHORS}
        onClose={() => dispatch(authorEditActions.close())}
        onCreated={() => {}}
        defaultValues={defaultValues}
      />
      <AuthorDelete />
    </>
  );
};
