import cn from 'classnames';
import { FunctionComponent } from 'react';
import { useCurrentLanguage, useTranslateEnum } from 'infrastructure/i18n';
import styles from './ImageView.module.scss';
import { ImageViewHeader } from './ImageViewHeader/ImageViewHeader';
import { ImageViewInfo } from './ImageViewInfoTab/ImageViewInfo';
import { ImageViewLayouts } from './ImageViewLayouts/ImageViewLayouts';
import { ImageViewMaterials } from './ImageViewMaterials/ImageViewMaterials';
import { ImageViewProps } from './ImageViewProps';
import { ImageViewTab } from './ImageViewTab';

export const ImageView: FunctionComponent<ImageViewProps> = (props) => {
  const { image, className } = props;
  const currentLang = useCurrentLanguage();

  const { translateValue } = useTranslateEnum('ImageViewTab', ImageViewTab);

  return (
    <div className={cn(styles.root, className)}>
      <ImageViewHeader image={image} />
      <div className={styles.title}>{translateValue(ImageViewTab.INFORMATION)}</div>
      <ImageViewInfo image={image} />
      <div className={styles.imageTitle}>{translateValue(ImageViewTab.LAYOUTS)}</div>
      <ImageViewLayouts image={image} />
      {((image?.additionalInformationRu && currentLang === 'ru') ||
        (image?.additionalInformationEn && currentLang === 'en')) && (
        <>
          <div className={styles.title}>{translateValue(ImageViewTab.MATERIALS)}</div>
          <ImageViewMaterials image={image} />
        </>
      )}
    </div>
  );
};
