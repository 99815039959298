import cn from 'classnames';
import { useCallback } from 'react';
import { Renderable, resolveValue, Toast, ToastBar, Toaster } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Icon } from 'ui-library/components/Icon';
import styles from './Notifications.module.scss';

const TOAST_DURATION_MS = 5000;

export const Notifications = () => {
  const { t } = useTranslation();

  const getIcon = useCallback(
    (toast: Toast) => {
      if (toast.icon) {
        return toast.icon;
      }

      if (toast.type === 'error') {
        return <Icon title={t('error.altIcon')} name='error' />;
      }

      if (toast.type === 'success') {
        return <Icon name='done' />;
      }

      return null;
    },
    [t],
  );

  const getMessage = useCallback(
    (message: Renderable) => {
      if (typeof message === 'string') {
        return t(message as never);
      }

      return message;
    },
    [t],
  );

  return (
    <Toaster
      toastOptions={{
        className: styles.toast,
        error: { className: cn(styles.toast, styles.error) },
        success: { className: cn(styles.toast, styles.success) },
        loading: { className: cn(styles.toast) },
        blank: { className: cn(styles.toast) },
        duration: TOAST_DURATION_MS,
      }}
      position='top-right'
      containerStyle={{ inset: 24 }}
      containerClassName={styles.root}
      gutter={8}
    >
      {(toast) => {
        const icon = getIcon(toast);

        return (
          <ToastBar toast={toast}>
            {() => (
              <>
                {icon && <div className={styles.icon}>{icon}</div>}
                {getMessage(resolveValue(toast.message, toast))}
              </>
            )}
          </ToastBar>
        );
      }}
    </Toaster>
  );
};
