import { useTranslation } from 'react-i18next';
import { Button, ButtonKind, ButtonVariant } from 'ui-library/components/Button';
import { Icon } from 'ui-library/components/Icon';
import { ZeroScreenWrapper } from 'ui-library/components/ZeroScreen';
import { FileDownloadDetailLoadingErrorProps } from './FileDownloadDetailLoadingErrorProps';

export const FileDownloadDetailLoadingError = (props: FileDownloadDetailLoadingErrorProps) => {
  const { onReload } = props;
  const { t } = useTranslation();

  return (
    <ZeroScreenWrapper
      theme={'dark'}
      renderIcon={(props) => <Icon name='documentIllustration' size='none' {...props} />}
      renderButton={(props) => (
        <Button
          as={ButtonKind.BUTTON}
          variant={ButtonVariant.SECONDARY}
          onClick={onReload}
          size='small'
          theme={'default'}
          {...props}
        >
          {t('actions.refresh')}
        </Button>
      )}
      message={t('files.downloadDetail.errorLoading')}
    />
  );
};
