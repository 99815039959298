import { ReactComponent as ArrowBread } from './assets/Arrow_bread.svg';
import { ReactComponent as ArrowDown } from './assets/Arrow_down.svg';
import { ReactComponent as ArrowLeft } from './assets/Arrow_left.svg';
import { ReactComponent as ArrowUp } from './assets/Arrow_up.svg';
import { ReactComponent as ArrowUpLong } from './assets/Arrow_up_long.svg';
import { ReactComponent as Blocked } from './assets/blocked.svg';
import { ReactComponent as Book } from './assets/book.svg';
import { ReactComponent as Brush } from './assets/brush.svg';
import { ReactComponent as ChangeOrder } from './assets/change_order.svg';
import { ReactComponent as Check } from './assets/check.svg';
import { ReactComponent as ChevronLeft } from './assets/chevron_left.svg';
import { ReactComponent as ChevronRight } from './assets/chevron_right.svg';
import { ReactComponent as ChevronThinLeft } from './assets/chevron_thin_left.svg';
import { ReactComponent as ChevronThinRight } from './assets/chevron_thin_right.svg';
import { ReactComponent as CollectionDelete } from './assets/collection_delete.svg';
import { ReactComponent as Copy } from './assets/copy.svg';
import { ReactComponent as Cross } from './assets/cross.svg';
import { ReactComponent as Doc } from './assets/doc.svg';
import { ReactComponent as DocumentIllustration } from './assets/document_illustration.svg';
import { ReactComponent as Done } from './assets/done.svg';
import { ReactComponent as DoneThin } from './assets/done_thin.svg';
import { ReactComponent as Dots } from './assets/dots.svg';
import { ReactComponent as DoubleArrowLeft } from './assets/double-arrow-left.svg';
import { ReactComponent as Edit } from './assets/edit.svg';
import { ReactComponent as Error } from './assets/error.svg';
import { ReactComponent as Error401 } from './assets/error_401.svg';
import { ReactComponent as Error403 } from './assets/error_403.svg';
import { ReactComponent as Error404 } from './assets/error_404.svg';
import { ReactComponent as Exit } from './assets/exit.svg';
import { ReactComponent as Expand } from './assets/expand.svg';
import { ReactComponent as Favorite } from './assets/favorite.svg';
import { ReactComponent as FavoriteDelete } from './assets/favorite_delete.svg';
import { ReactComponent as FavoriteFilled } from './assets/favorite_filled.svg';
import { ReactComponent as Folder } from './assets/folder.svg';
import { ReactComponent as FolderAlt } from './assets/folder_alt.svg';
import { ReactComponent as Grid } from './assets/grid.svg';
import { ReactComponent as Illustrations } from './assets/Illustrations.svg';
import { ReactComponent as Image } from './assets/image.svg';
import { ReactComponent as ImageMulti } from './assets/image_multi.svg';
import { ReactComponent as ImagesErrorIllustration } from './assets/images_error_illustration.svg';
import { ReactComponent as ImagesIllustration } from './assets/images_illustration.svg';
import { ReactComponent as Info } from './assets/info.svg';
import { ReactComponent as JPEGDoc } from './assets/jpeg_doc.svg';
import { ReactComponent as Layout } from './assets/layout.svg';
import { ReactComponent as Link } from './assets/link.svg';
import { ReactComponent as LogoHeader } from './assets/logo_header.svg';
import { ReactComponent as LogoNew } from './assets/logo_new.svg';
import { ReactComponent as Menu } from './assets/menu.svg';
import { ReactComponent as Placeholder } from './assets/Placeholder.svg';
import { ReactComponent as PlaceholderTheme } from './assets/placeholder_theme.svg';
import { ReactComponent as Plus } from './assets/plus.svg';
import { ReactComponent as Refresh } from './assets/refresh.svg';
import { ReactComponent as Search } from './assets/search.svg';
import { ReactComponent as SearchEmptyIllustration } from './assets/search_empty_illustration.svg';
import { ReactComponent as DownloadAlt } from './assets/share-2.svg';
import { ReactComponent as Share } from './assets/share-3.svg';
import { ReactComponent as Download } from './assets/share.svg';
import { ReactComponent as SortA } from './assets/sort-A.svg';
import { ReactComponent as SortZ } from './assets/sort-Z.svg';
import { ReactComponent as TifDoc } from './assets/tif_doc.svg';
import { ReactComponent as ToFolder } from './assets/to-folder.svg';
import { ReactComponent as Trash } from './assets/trash.svg';
import { ReactComponent as Undo } from './assets/undo.svg';
import { ReactComponent as UnLink } from './assets/unlink.svg';
import { ReactComponent as Users } from './assets/users.svg';
import { ReactComponent as VisibilityOff } from './assets/Visibility_off.svg';
import { ReactComponent as VisibilityOn } from './assets/Visibility_on.svg';

export const ICONS_CONFIG = {
  arrowUp: ArrowUp,
  arrowUpLong: ArrowUpLong,
  arrowDown: ArrowDown,
  arrowLeft: ArrowLeft,
  arrowBread: ArrowBread,
  brush: Brush,
  changeOrder: ChangeOrder,
  chevronLeft: ChevronLeft,
  chevronRight: ChevronRight,
  chevronThinRight: ChevronThinRight,
  chevronThinLeft: ChevronThinLeft,
  cross: Cross,
  expand: Expand,
  exit: Exit,
  placeholder: Placeholder,
  dots: Dots,
  done: Done,
  doneThin: DoneThin,
  favorite: Favorite,
  favoriteFilled: FavoriteFilled,
  favoriteDelete: FavoriteDelete,
  plus: Plus,
  refresh: Refresh,
  info: Info,
  imageMulti: ImageMulti,
  menu: Menu,
  edit: Edit,
  toFolder: ToFolder,
  visibilityOn: VisibilityOn,
  visibilityOff: VisibilityOff,
  error: Error,
  doubleArrowLeft: DoubleArrowLeft,
  placeholderTheme: PlaceholderTheme,
  logoNew: LogoNew,
  logoHeader: LogoHeader,
  sortA: SortA,
  sortZ: SortZ,
  search: Search,
  folder: Folder,
  folderAlt: FolderAlt,
  book: Book,
  users: Users,
  image: Image,
  doc: Doc,
  tifDoc: TifDoc,
  jpegDoc: JPEGDoc,
  layout: Layout,
  grid: Grid,
  trash: Trash,
  illustrations: Illustrations,
  download: Download,
  downloadAlt: DownloadAlt,
  share: Share,
  imagesIllustration: ImagesIllustration,
  imagesErrorIllustration: ImagesErrorIllustration,
  error404: Error404,
  error401: Error401,
  error403: Error403,
  documentIllustration: DocumentIllustration,
  check: Check,
  searchEmptyIllustration: SearchEmptyIllustration,
  blocked: Blocked,
  undo: Undo,
  copy: Copy,
  collectionDelete: CollectionDelete,
  link: Link,
  unLink: UnLink,
};
