import { BaseSelectLabelOption, BaseSelectOptionProps } from 'ui-library/components/BaseSelect';
import { AutocompleteOptionContainer } from '../OptionContainer/AutocompleteOptionContainer';

export const AutocompleteLabelOption = <Option, OptionValue>(props: BaseSelectOptionProps<Option, OptionValue>) => {
  const { getOptionLabel, getOptionValue, option } = props;

  return (
    <AutocompleteOptionContainer option={option} getOptionValue={getOptionValue}>
      {(stateProps) => <BaseSelectLabelOption getOptionLabel={getOptionLabel} option={option} {...stateProps} />}
    </AutocompleteOptionContainer>
  );
};
