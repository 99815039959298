import { skipToken } from '@reduxjs/toolkit/query/react';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { FilePreviewLabel, FileType, useUploadFile } from 'features/file';
import { FolderResponseDto, foldersApi, useFetchImageFoldersQuery } from 'features/folders';
import {
  imageEditActions,
  ImageEditFormFields,
  ImageStatus,
  useEditImageMutation,
  useFetchImageDetailQuery,
} from 'features/images';
import { LayoutResponseDto, layoutsApi, useFetchImageLayoutsQuery } from 'features/layouts';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux';
import { Modal } from 'ui-library/components/Modal';
import { ImageEditForm } from '../ImageEditForm/ImageEditForm';
import { ImageEditTab } from '../ImageEditForm/ImageEditTab';

export const ImageEdit: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const { isOpen, selectedImageId, selectedTab } = useAppSelector((state) => state.imageEdit);
  const [editImageMutation] = useEditImageMutation();
  const uploadFile = useUploadFile(FileType.IMAGE);

  const { t } = useTranslation();
  const { data: imageFolders, isFetching: isFetchingFolders } = useFetchImageFoldersQuery(
    selectedImageId ? { imageID: selectedImageId, currentPage: 1, pageSize: Number.MAX_SAFE_INTEGER } : skipToken,
  );
  const { data: imageLayouts, isFetching: isFetchingLayouts } = useFetchImageLayoutsQuery(
    selectedImageId ? { imageID: selectedImageId, currentPage: 1, pageSize: Number.MAX_SAFE_INTEGER } : skipToken,
  );
  const { data } = useFetchImageDetailQuery(selectedImageId ?? skipToken);

  const handleClose = () => {
    dispatch(imageEditActions.close());
    dispatch(imageEditActions.selectImage(undefined));
    dispatch(imageEditActions.selectTab(ImageEditTab.INFORMATION));
  };

  const getFolderIDsToAdd = (values: Array<FolderResponseDto>) => {
    const folderToAdd = values?.filter((folder) => imageFolders?.content.every((item) => item.id !== folder.id));
    return folderToAdd?.map((x) => x.id);
  };

  const getLayoutIDsToAdd = (values: Array<LayoutResponseDto>) => {
    const layoutToAdd = values?.filter((layout) => imageLayouts?.content.every((item) => item.id !== layout.id));
    return layoutToAdd?.map((x) => x.id);
  };

  const getFolderIDsToRemove = (values: Array<FolderResponseDto>) => {
    const folderIDsToRemove: Array<string> = [];
    imageFolders?.content.forEach((item) => {
      if (values?.find((folders) => folders.id === item.id) === undefined) {
        folderIDsToRemove.push(item.id);
      }
    });
    return folderIDsToRemove;
  };

  const getLayoutIDsToRemove = (values: Array<LayoutResponseDto>) => {
    const layoutIDsToRemove: Array<string> = [];
    imageLayouts?.content.forEach((item) => {
      if (values?.find((layouts) => layouts.id === item.id) === undefined) {
        layoutIDsToRemove.push(item.id);
      }
    });
    return layoutIDsToRemove;
  };

  const onSubmit = async (values: ImageEditFormFields) => {
    const { fileBox } = values;

    let fileID;
    if (fileBox.file) {
      fileID = await uploadFile(fileBox.file);
    }

    await editImageMutation({
      fileID,
      imageID: selectedImageId ?? '',
      captionRu: values.captionRu,
      captionEn: values.captionEn,
      centuryHalf: values.centuryHalf || 0,
      dimensionsRu: values.dimensionsRu,
      dimensionsEn: values.dimensionsEn,
      additionalInformationRu: values.additionalInformationRu,
      additionalInformationEn: values.additionalInformationEn,
      additionalInformationOriginal: values.additionalInformationOriginal,
      relatedMaterial: values.relatedMaterial,
      authorID: values.author?.id || '',
      centuryID: values.century?.id || '',
      yearID: values.year?.id || '',
      artFormID: values.artForm?.id || '',
      materialsTechnicsID: values.materialsTechnics?.id || '',
      status: values.status,
      tags: values.tags?.map((tag) => tag.id),
      folderIDsToAdd: getFolderIDsToAdd(values.folders),
      folderIDsToRemove: getFolderIDsToRemove(values.folders),
      layoutIDsToAdd: getLayoutIDsToAdd(values.layouts),
      layoutIDsToRemove: getLayoutIDsToRemove(values.layouts),
    }).unwrap();

    dispatch(
      foldersApi.util.invalidateTags([
        {
          type: 'ImageFolders',
          id: selectedImageId,
        },
      ]),
    );
    dispatch(
      layoutsApi.util.invalidateTags([
        {
          type: 'ImageLayouts',
          id: selectedImageId,
        },
      ]),
    );

    handleClose();
  };

  const preview = data?.file?.previews?.find((x) => x.label === FilePreviewLabel.IMAGE_PREVIEW);

  const defaultValues: ImageEditFormFields = {
    fileBox: {
      previewUrl: preview?.url,
    },
    archiveName: data?.archiveName,
    captionRu: data?.captionRu as string,
    captionEn: data?.captionEn as string,
    centuryHalf: data?.centuryHalf,
    dimensionsRu: data?.dimensionsRu,
    dimensionsEn: data?.dimensionsEn,
    additionalInformationRu: data?.additionalInformationRu,
    additionalInformationEn: data?.additionalInformationEn,
    additionalInformationOriginal: data?.additionalInformationOriginal,
    relatedMaterial: data?.relatedMaterial,
    author: data?.author,
    century: data?.century,
    year: data?.year,
    artForm: data?.artForm,
    materialsTechnics: data?.materialsTechnics,
    status: data?.status as ImageStatus,
    tags: data?.tags,
    layouts: imageLayouts ? imageLayouts?.content : [],
    folders: imageFolders ? imageFolders?.content : [],
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Modal.Title>{t('images.editImage')}</Modal.Title>
      {data?.id === selectedImageId && !isFetchingFolders && !isFetchingLayouts && (
        <ImageEditForm
          selectedTab={selectedTab}
          onTabChange={(tab) => dispatch(imageEditActions.selectTab(tab))}
          defaultValues={defaultValues}
          onClose={handleClose}
          onSubmit={onSubmit}
        />
      )}
    </Modal>
  );
};
