import { ModalState } from './modalState';

export const getModalReducers = <TState>(selector: (state: TState) => ModalState) => ({
  open: (state: TState) => {
    const modal = selector(state);

    modal.isOpen = true;
  },
  close: (state: TState) => {
    const modal = selector(state);

    modal.isOpen = false;
  },
  toggle: (state: TState) => {
    const modal = selector(state);

    modal.isOpen = !modal.isOpen;
  },
});
