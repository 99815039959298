import { saveAs } from 'file-saver';
import { SyntheticEvent } from 'react';
import { addToFavoritesActions, removeFromFavoritesActions } from 'features/favorites';
import { FilePreviewLabel } from 'features/file';
import { FolderPartition, FolderResponseDto } from 'features/folders';
import { useEditLayoutMutation } from 'features/layouts/layoutsApi';
import { layoutDeleteActions } from 'features/layouts/slices/layoutsDeleteSlice';
import { linkingArtifactActions } from 'features/linking';
import { movingArtifactsActions } from 'features/movingArtifacts';
import { NotDefinedParamException } from 'infrastructure/exceptions';
import { useAppDispatch } from 'infrastructure/redux';
import { useUnlinkLayout } from '../hooks/useUnlinkLayout';
import { LayoutEditRequestDto, LayoutResponseDto } from '../layoutEntity';
import { layoutEditActions } from '../slices/layoutEditSlice';

type UseLayoutActionHandlersOptions = {
  layout: LayoutResponseDto | undefined;
  parentId: string | undefined;
  folder?: FolderResponseDto;
};

export const useLayoutActionHandlers = (options: UseLayoutActionHandlersOptions) => {
  const { layout, parentId, folder } = options;
  const dispatch = useAppDispatch();
  const [unLinkLayoutMutation] = useEditLayoutMutation();
  const { handleUnlinkLayoutError, handleUnlinkLayout } = useUnlinkLayout();

  const handleEdit = (event: SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    dispatch(layoutEditActions.selectLayout(layout?.id));
    dispatch(layoutEditActions.open());
  };

  const handleMove = (event: SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (!layout) {
      throw new NotDefinedParamException('layout');
    }

    dispatch(
      movingArtifactsActions.setMovingArtifacts([
        {
          entityId: layout.id,
          parentID: parentId,
        },
      ]),
    );
    dispatch(movingArtifactsActions.setMovingType('layout'));
    dispatch(movingArtifactsActions.selectFolderId(parentId));
    dispatch(movingArtifactsActions.selectPartition(FolderPartition.ARCHIVE));
    dispatch(movingArtifactsActions.open());
  };

  const handleLink = (event: SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (!layout) {
      throw new NotDefinedParamException('layout');
    }

    dispatch(
      linkingArtifactActions.setLinkingArtifacts([
        {
          entityId: layout.id,
          parentID: parentId,
        },
      ]),
    );
    dispatch(linkingArtifactActions.setLinkingType('layout'));
    dispatch(linkingArtifactActions.selectFolderId(parentId));
    dispatch(linkingArtifactActions.open());
  };

  const handleUnlink = async (event: SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (!layout) {
      throw new NotDefinedParamException('Layout');
    }
    if (!folder) {
      throw new NotDefinedParamException('Folder');
    }

    const dtoLayout: LayoutEditRequestDto = {
      layoutID: layout?.id,
      folderIDsToRemove: parentId ? [parentId] : undefined,
    };

    try {
      await unLinkLayoutMutation(dtoLayout).unwrap();
      handleUnlinkLayout(layout, folder);
    } catch (e) {
      handleUnlinkLayoutError();
    }
  };

  const handleAddToFavorite = (event: SyntheticEvent) => {
    event.stopPropagation();
    event.preventDefault();

    if (!layout) {
      throw new NotDefinedParamException('layout');
    }

    dispatch(
      addToFavoritesActions.setFavoriteArtifact({
        entityId: layout.id,
        type: 'layout',
        parentId: parentId,
        isFavorite: layout.isFavourite,
      }),
    );
    dispatch(addToFavoritesActions.open());
  };

  const handleRemoveFromFavorite = (event: SyntheticEvent) => {
    event.stopPropagation();
    event.preventDefault();

    if (!layout) {
      throw new NotDefinedParamException('layout');
    }

    dispatch(
      removeFromFavoritesActions.setFavoriteArtifact({
        entityId: layout.id,
        type: 'layout',
        parentId: parentId,
        isFavorite: layout.isFavourite,
      }),
    );
    dispatch(removeFromFavoritesActions.open());
  };

  const handleTifDownload = () => {
    const preview = layout?.file?.previews?.find((x) => x.label === FilePreviewLabel.LAYOUT_CROPPED_ORIGINAL);

    if (preview) {
      saveAs(preview.url, preview.name);
    }
  };

  const handleJpegDownload = () => {
    const preview = layout?.file?.previews?.find((x) => x.label === FilePreviewLabel.LAYOUT_CROPPED_JPEG);

    if (preview) {
      saveAs(preview.url, preview.name);
    }
  };

  const handleDelete = (event: SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (!layout) {
      throw new NotDefinedParamException('layout');
    }

    dispatch(layoutDeleteActions.setLayoutForDeletion(layout.id));
    dispatch(layoutDeleteActions.open());
  };

  return {
    handleEdit,
    handleDelete,
    handleMove,
    handleLink,
    handleUnlink,
    handleAddToFavorite,
    handleRemoveFromFavorite,
    handleTifDownload,
    handleJpegDownload,
  };
};
