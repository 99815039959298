import { Tab as BuiltinTab } from '@headlessui/react';
import cn from 'classnames';
import { FunctionComponent } from 'react';
import styles from './TabPanel.module.scss';

export const TabPanel: FunctionComponent<Parameters<typeof BuiltinTab['Panel']>[0]> = (props) => {
  const { className, ...restProps } = props;

  return <BuiltinTab.Panel className={cn(styles.root, className)} {...restProps} />;
};
