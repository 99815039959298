import { FunctionComponent } from 'react';
import { Table, TableBody, TableHead } from 'ui-library/components/Table';
import { useFileTaskHistoryColumns } from '../../hooks';
import { FileHistoryTableSkeleton } from '../FileHistoryTableSkeleton/FileHistoryTableSkeleton';
import { FileTaskHistoryTableProps } from './FileTaskHistoryTableProps';

export const FileTaskHistoryTable: FunctionComponent<FileTaskHistoryTableProps> = (props) => {
  const { data } = props;
  const columns = useFileTaskHistoryColumns();

  return (
    <Table>
      <TableHead columns={columns} />
      {props.isFetching ? <FileHistoryTableSkeleton /> : <TableBody columns={columns} items={data?.content ?? []} />}
    </Table>
  );
};
