import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { EmptySearch } from 'features/search';
import styles from './AuthorsScreenEmptyQuery.module.scss';

export const AuthorsScreenEmptyQuery: FunctionComponent = () => {
  const { t } = useTranslation();

  return <EmptySearch className={styles.placeholder} message={t('referenceBook.searchEmptyMessage.authors')} />;
};
