import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { ModalSubmitActions } from 'features/modal';
import { useEditUserMutation, UserRole } from 'features/users';
import { userBlockActions } from 'features/users/slices';
import { NotDefinedParamException } from 'infrastructure/exceptions';
import { useForm } from 'infrastructure/form';
import { useTranslateEnum } from 'infrastructure/i18n';
import { parseApiError } from 'infrastructure/network';
import { useAppSelector, useAppDispatch } from 'infrastructure/redux';
import { Modal } from 'ui-library/components/Modal';
import styles from './UserBlock.module.scss';

export const UserBlock = () => {
  const dispatch = useAppDispatch();
  const { isOpen, user } = useAppSelector((state) => state.userBlock);
  const [editUserMutation] = useEditUserMutation();
  const { t } = useTranslation();
  const { translateValue } = useTranslateEnum('UserRole', UserRole);

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();

  const handleClose = () => {
    dispatch(userBlockActions.close());
    dispatch(userBlockActions.setUser(undefined));
  };

  const submit = async () => {
    if (!user) {
      throw new NotDefinedParamException('user');
    }

    try {
      await editUserMutation({
        userID: user.id,
        isBlocked: true,
      }).unwrap();

      toast.success(
        t('users.block.successToastMessage', {
          role: translateValue(user.role),
          name: user.name,
          surname: user.surname,
        }),
      );
      handleClose();
    } catch (rawError) {
      const error = parseApiError(rawError);
      toast.error(error?.message || 'users.block.failedToastMessage');
    }
  };

  return (
    <Modal open={isOpen} onClose={handleClose} variant='popup'>
      <Modal.Title>{t('users.block.title')}</Modal.Title>
      <Modal.Form onSubmit={handleSubmit(submit)}>
        <Modal.Content className={styles.text}>{t('users.block.description')}</Modal.Content>
        <Modal.Actions>
          <ModalSubmitActions submitLabel={t('actions.block')} loading={isSubmitting} onCancel={handleClose} />
        </Modal.Actions>
      </Modal.Form>
    </Modal>
  );
};
