import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Accordion } from 'ui-library/components/Accordion';
import { SearchScreenFilter } from '../SearchScreenFilter/SearchScreenFilter';
import styles from './SearchFilterAccordion.module.scss';

export const SearchFilterAccordion: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <Accordion
      defaultOpen={true}
      label={t('search.filter.title')}
      classes={{ label: styles.label, contentOpened: styles.contentOpened }}
    >
      <SearchScreenFilter />
    </Accordion>
  );
};
