import cn from 'classnames';
import React, { ButtonHTMLAttributes, forwardRef, PropsWithChildren } from 'react';
import { ButtonKind } from '../../../components/Button';
import { InnerLinkProps, Link, LinkKind, OuterLinkProps } from '../../../components/Link';
import styles from './IconButton.module.scss';
import { IconButtonProps, IconButtonVariant } from './IconButtonProps';

export const IconButton = forwardRef<HTMLAnchorElement | HTMLButtonElement, PropsWithChildren<IconButtonProps>>(
  (props, ref) => {
    const { variant = IconButtonVariant.MAIN, size = 'normal', className, children, as, ...restProps } = props;
    const totalClassName = cn(styles.button, styles[variant], styles[size], className);

    switch (as) {
      case ButtonKind.BUTTON:
        return (
          <button
            className={totalClassName}
            ref={ref as React.ForwardedRef<HTMLButtonElement>}
            type='button'
            {...(restProps as ButtonHTMLAttributes<HTMLButtonElement>)}
          >
            {children}
          </button>
        );
      case ButtonKind.OUTER_ANCHOR:
        return (
          <Link
            as={LinkKind.OUTER}
            className={totalClassName}
            ref={ref as React.ForwardedRef<HTMLAnchorElement>}
            {...(restProps as Omit<OuterLinkProps, 'as'>)}
          >
            {children}
          </Link>
        );
      case ButtonKind.INNER_ANCHOR:
        return (
          <Link
            as={LinkKind.INNER}
            className={totalClassName}
            ref={ref as React.ForwardedRef<HTMLAnchorElement>}
            {...(restProps as Omit<InnerLinkProps, 'as'>)}
          >
            {children}
          </Link>
        );
    }
  },
);
