import { skipToken } from '@reduxjs/toolkit/dist/query';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { FilePreviewLabel } from 'features/file';
import { FileNavigatorFolderBreadcrumbs } from 'features/fileNavigator/folders';
import { FolderResponseDto, useFetchFolderDetailQuery } from 'features/folders';
import { useFetchImageDetailQuery } from 'features/images';
import { useFetchLayoutDetailQuery } from 'features/layouts';
import { movingArtifactsActions } from 'features/movingArtifacts';
import { useLanguageRelevantData } from 'infrastructure/i18n';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux';
import { Icon } from 'ui-library/components/Icon';
import { Image } from 'ui-library/components/Image';
import { Modal } from 'ui-library/components/Modal';
import styles from './MovingArtifactNavigatorHeader.module.scss';

export const MovingArtifactNavigatorHeader: FunctionComponent = () => {
  const { t } = useTranslation();
  const { matchProp } = useLanguageRelevantData();
  const dispatch = useAppDispatch();
  const { movingArtifacts, selectedFolderId, type } = useAppSelector((state) => state.movingArtifacts);
  const singleArtifactId = movingArtifacts.length === 1 && movingArtifacts[0].entityId;
  const { data: selectedFolderData } = useFetchFolderDetailQuery(selectedFolderId ?? skipToken);
  const { data: layoutData } = useFetchLayoutDetailQuery((type === 'layout' && singleArtifactId) || skipToken);
  const { data: folderData } = useFetchFolderDetailQuery(
    ((type === 'folder' || type === 'rootFolder') && singleArtifactId) || skipToken,
  );
  const { data: imageData } = useFetchImageDetailQuery((type === 'image' && singleArtifactId) || skipToken);

  const getTitle = () => {
    if (movingArtifacts.length === 1) {
      if (layoutData) {
        return t('layouts.properties.number', { value: layoutData.archiveName });
      } else if (imageData) {
        return matchProp(imageData.captionRu, imageData.captionEn);
      } else if (folderData) {
        return matchProp(folderData.captionRu, folderData.captionEn);
      }
    } else if (movingArtifacts.length > 1) {
      if (type === 'image') {
        return t('movingArtifacts.header.image', { count: movingArtifacts.length });
      }
    }

    return '';
  };

  const getIcon = () => {
    if (movingArtifacts.length === 1) {
      if (layoutData) {
        const preview = layoutData.file?.previews?.find((x) => x.label === FilePreviewLabel.LAYOUT_ICON);

        return <Image className={styles.previewIcon} src={preview?.url} loading='eager' />;
      } else if (folderData) {
        const preview = folderData.file?.previews?.find((x) => x.label === FilePreviewLabel.FOLDER_ICON);

        return preview ? (
          <Image className={styles.folderIcon} src={preview?.url} loading='eager' />
        ) : (
          <Icon className={styles.folderIcon} name='folderAlt' />
        );
      } else if (imageData) {
        const preview = imageData.file?.previews?.find((x) => x.label === FilePreviewLabel.IMAGE_ICON);

        return <Image className={styles.previewIcon} src={preview?.url} loading='eager' />;
      }
    } else if (movingArtifacts.length > 1) {
      if (type === 'image') {
        return <Icon className={styles.previewIcon} name='imageMulti' />;
      }
    }

    return undefined;
  };

  const handleBreadcrumbClick = (x: FolderResponseDto) => {
    dispatch(movingArtifactsActions.selectFolderId(x.id));
  };

  const handleGalleryClick = () => {
    dispatch(movingArtifactsActions.selectFolderId(undefined));
  };

  const handleBackClick = () => {
    dispatch(movingArtifactsActions.selectFolderId(selectedFolderData?.parentID));
  };

  return (
    <Modal.Title className={styles.root}>
      <>
        <div className={styles.header}>
          <span className={styles.icon}>{getIcon()}</span>
          <span className={styles.title}>{getTitle()}</span>
        </div>
        <FileNavigatorFolderBreadcrumbs
          selectedFolderId={selectedFolderId}
          onClickBreadcrumb={handleBreadcrumbClick}
          onClickBack={handleBackClick}
          onClickGallery={handleGalleryClick}
        />
      </>
    </Modal.Title>
  );
};
