import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { FileDownloadStatus } from 'features/file';
import styles from './FileDownloadDetailDescription.module.scss';
import { FileDownloadDetailDescriptionProps } from './FileDownloadDetailDescriptionProps';

export const FileDownloadDetailDescription: FunctionComponent<FileDownloadDetailDescriptionProps> = (props) => {
  const { t } = useTranslation();
  const { task } = props;

  const getDescription = () => {
    if (task?.status === FileDownloadStatus.DONE) {
      return t('files.downloadDetail.description.done');
    }

    return t('files.downloadDetail.description.working');
  };

  return <div className={styles.root}>{getDescription()}</div>;
};
