import { SyntheticEvent } from 'react';
import { changeOrderActions } from 'features/changeOrder';
import { folderCreateActions, imageCreateActions, layoutCreateActions } from 'features/createArtifact';
import { addToFavoritesActions, removeFromFavoritesActions } from 'features/favorites';
import {
  activeFileDownloadTasksActions,
  createFileDownloadTaskActions,
  fileApi,
  shareFileDownloadTaskActions,
} from 'features/file';
import { useDownloadImagesMetadataMutation } from 'features/images';
import { movingArtifactsActions } from 'features/movingArtifacts';
import { NotDefinedParamException } from 'infrastructure/exceptions';
import { useCurrentLanguage } from 'infrastructure/i18n';
import { useAppDispatch } from 'infrastructure/redux';
import { FolderResponseDto } from '../folderEntity';
import { folderDeleteEmptyActions, folderEditActions, folderViewActions } from '../slices';

export const useFolderActionHandlers = (folder: FolderResponseDto | undefined) => {
  const dispatch = useAppDispatch();
  const currentLang = useCurrentLanguage();
  const [downloadImagesMetadata] = useDownloadImagesMetadataMutation();

  const handleCreate = () => {
    if (!folder) {
      throw new NotDefinedParamException('folder');
    }

    dispatch(folderCreateActions.selectParentFolder(folder.id));
    dispatch(folderCreateActions.selectPartition(folder.partition));
    dispatch(folderCreateActions.open());
  };

  const handleCreateLayout = () => {
    if (!folder) {
      throw new NotDefinedParamException('folder');
    }

    dispatch(layoutCreateActions.selectParentFolder(folder.id));
    dispatch(layoutCreateActions.open());
  };

  const handleCreateImage = () => {
    if (!folder) {
      throw new NotDefinedParamException('folder');
    }

    dispatch(imageCreateActions.selectParentFolder(folder.id));
    dispatch(imageCreateActions.open());
  };

  const handleView = (event: SyntheticEvent) => {
    event.stopPropagation();
    event.preventDefault();

    dispatch(folderViewActions.selectFolder(folder?.id));
    dispatch(folderViewActions.open());
  };

  const handleEdit = (event: SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    dispatch(folderEditActions.selectFolder(folder?.id));
    dispatch(folderEditActions.open());
  };

  const handleMove = (event: SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (!folder) {
      throw new NotDefinedParamException('folder');
    }

    dispatch(
      movingArtifactsActions.setMovingArtifacts([
        {
          entityId: folder.id,
          parentID: folder.parentID,
        },
      ]),
    );
    dispatch(movingArtifactsActions.setMovingType('folder'));
    dispatch(movingArtifactsActions.selectFolderId(folder.parentID));
    dispatch(movingArtifactsActions.selectPartition(folder.partition));
    dispatch(movingArtifactsActions.open());
  };

  const handleAddToFavorite = (event: SyntheticEvent) => {
    event.stopPropagation();
    event.preventDefault();

    if (!folder) {
      throw new NotDefinedParamException('folder');
    }

    dispatch(
      addToFavoritesActions.setFavoriteArtifact({
        entityId: folder.id,
        type: 'folder',
        parentId: folder.parentID,
        isFavorite: folder.isFavourite,
      }),
    );
    dispatch(addToFavoritesActions.open());
  };

  const handleRemoveFromFavorite = (event: SyntheticEvent) => {
    event.stopPropagation();
    event.preventDefault();

    if (!folder) {
      throw new NotDefinedParamException('folder');
    }

    dispatch(
      removeFromFavoritesActions.setFavoriteArtifact({
        entityId: folder.id,
        type: 'folder',
        parentId: folder.parentID,
        isFavorite: folder.isFavourite,
      }),
    );
    dispatch(removeFromFavoritesActions.open());
  };

  const handleDelete = (event: SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    dispatch(folderDeleteEmptyActions.setFolderForDeletion(folder));
    dispatch(folderDeleteEmptyActions.open());
  };

  const handleDownload = (event: SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (!folder) {
      throw new NotDefinedParamException('folder');
    }

    dispatch(
      createFileDownloadTaskActions.setFileArtifact({
        entityId: folder.id,
        type: 'folder',
      }),
    );
    dispatch(createFileDownloadTaskActions.open());
  };

  const handleDownloadExcel = async (event: SyntheticEvent) => {
    event.stopPropagation();
    event.preventDefault();

    if (!folder) {
      throw new NotDefinedParamException('folder is not selected');
    }

    await downloadImagesMetadata({
      folderIDs: [folder.id],
      language: currentLang,
      foldersWithSubfolders: true,
    }).unwrap();

    dispatch(fileApi.util.invalidateTags(['FileTasks']));
    dispatch(activeFileDownloadTasksActions.open());
  };

  const handleShare = (event: SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (!folder) {
      throw new NotDefinedParamException('folder');
    }

    dispatch(
      shareFileDownloadTaskActions.setFileArtifact({
        entityId: folder.id,
        type: 'folder',
      }),
    );
    dispatch(shareFileDownloadTaskActions.open());
  };

  const handleChangeOrder = (event: SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (!folder) {
      throw new NotDefinedParamException('folder');
    }

    dispatch(changeOrderActions.setShowOrderMode(true));
  };

  return {
    handleView,
    handleCreateLayout,
    handleCreateImage,
    handleCreate,
    handleChangeOrder,
    handleEdit,
    handleMove,
    handleAddToFavorite,
    handleRemoveFromFavorite,
    handleDelete,
    handleDownload,
    handleDownloadExcel,
    handleShare,
  };
};
