import { Fragment, FunctionComponent, SyntheticEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'features/auth';
import { FolderResponseDto, useGetFolderResponseGroupedData } from 'features/folders';
import { ListItemGroupLetter } from 'features/list';
import { useRootFolderTableColumns } from 'features/rootFolders/hooks';
import { UserRole } from 'features/users';
import { getFolderDetailRoute } from 'infrastructure/routes/paths';
import { Table, TableBody, TableHead } from 'ui-library/components/Table';
import { FolderTableActions } from '../FolderTableActions/FolderTableActions';
import { FolderTableSkeleton } from '../FolderTableSkeleton/FolderTableSkeleton';
import styles from './FolderTable.module.scss';
import { FolderTableProps } from './FolderTableProps';

export const FolderTable: FunctionComponent<FolderTableProps> = (props) => {
  const { data, isFetching } = props;
  const groupedData = useGetFolderResponseGroupedData(data?.content);
  const navigate = useNavigate();
  const columns = useRootFolderTableColumns();
  const auth = useAuth();

  const handleRowClick = (event: SyntheticEvent, item: FolderResponseDto) => {
    navigate(getFolderDetailRoute(item.id, item.partition));
  };

  return (
    <>
      {Object.entries(groupedData).map(([key, items]) => (
        <Fragment key={key}>
          {isFetching ? undefined : <ListItemGroupLetter>{key}</ListItemGroupLetter>}
          <Table className={styles.table}>
            <TableHead columns={columns} />
            {isFetching ? (
              <FolderTableSkeleton />
            ) : (
              <TableBody
                className={styles.body}
                rowClassName={styles.row}
                columns={columns}
                items={items}
                getRowActions={(x, options) => (
                  <FolderTableActions
                    folder={x}
                    className={auth.user?.role === UserRole.GUEST ? styles.actionGuest : options?.className}
                  />
                )}
                onRowClick={handleRowClick}
              />
            )}
          </Table>
        </Fragment>
      ))}
    </>
  );
};
