import { Combobox } from '@headlessui/react';
import cn from 'classnames';
import { Fragment } from 'react';
import { Icon } from 'ui-library/components/Icon';
import { Input } from '../../Input';
import styles from './SingleValueContainer.module.scss';
import { SingleValueContainerProps } from './SingleValueContainerProps';

export const SingleValueContainer = <Option, OptionValue = Option>(
  props: SingleValueContainerProps<Option, OptionValue>,
) => {
  const { open, disabled, placeholder, getOptionLabel, onChangeInput, InputProps, variant, hideArrowIcon } = props;

  return (
    <Combobox.Button as={Fragment}>
      <Combobox.Input
        as={Input}
        {...InputProps}
        className={cn(styles.root, {
          [styles.active]: open,
        })}
        displayValue={(option: Option) => {
          // TODO: сделать поддержку getOptionValue
          if (option) {
            if (typeof getOptionLabel(option) === 'string') {
              return getOptionLabel(option) as string;
            } else {
              return '';
            }
          }
          return '';
        }}
        onChange={(event) => onChangeInput?.(event.target.value)}
        placeholder={placeholder}
        LayoutProps={{
          InputProps: {
            className: InputProps?.className,
          },
          variant,
          endAdornment:
            hideArrowIcon || disabled ? null : (
              <Combobox.Button className={styles.endAdornmentBtn} as='span'>
                <Icon name={open ? 'arrowUp' : 'arrowDown'} />
              </Combobox.Button>
            ),
          endAdornmentProps: {
            className: styles.endAdornment,
          },
        }}
      />
    </Combobox.Button>
  );
};
