import { FunctionComponent } from 'react';
import { LayoutCardSkeleton } from '../LayoutCardSkeleton/LayoutCardSkeleton';

const amountOfCards = 21;

export const LayoutCardListSkeleton: FunctionComponent = () => {
  return (
    <>
      {[...Array(amountOfCards)].map((_, idx) => (
        <LayoutCardSkeleton key={idx} />
      ))}
    </>
  );
};
