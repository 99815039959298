import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type SelectedGalleryImage = { id: string; parentId: string };

export type ImageGalleryState = {
  selected: Array<SelectedGalleryImage>;
  isShowSelectMode: boolean;
};

const initialState: ImageGalleryState = {
  selected: [],
  isShowSelectMode: false,
};

const imageGallerySlice = createSlice({
  name: 'imageGallery',
  initialState,
  reducers: {
    selectImage: (state, action: PayloadAction<SelectedGalleryImage>) => {
      state.selected.push(action.payload);
    },
    setShowSelectMode: (state, action: PayloadAction<boolean>) => {
      state.isShowSelectMode = action.payload;
    },
    unselectImage: (state, action: PayloadAction<string>) => {
      state.selected = state.selected.filter((x) => x.id !== action.payload);
      if (state.selected.length === 0) {
        state.isShowSelectMode = false;
      }
    },
    clearSelection: (state) => {
      state.selected = [];
      state.isShowSelectMode = false;
    },
  },
});

export const imageGalleryActions = imageGallerySlice.actions;
export const imageGalleryReducer = imageGallerySlice.reducer;
