import { FILE_FORMATS } from './fileFormats';

export const uploadMaxFileSizeMB = 500;
export const uploadSupportedMimeImageTypes = {
  [FILE_FORMATS.png]: 'image/x-png',
  [FILE_FORMATS.jpg]: 'image/jpg',
  [FILE_FORMATS.jpeg]: 'image/jpeg',
  [FILE_FORMATS.tif]: 'image/tif',
  [FILE_FORMATS.tiff]: 'image/tiff',
};

export const uploadSupportedMimeExcelTypes = {
  [FILE_FORMATS.xlsx]: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
};

export const uploadSupportedExcelTypes = {
  [FILE_FORMATS.xlsx]: 'xlsx',
};
