import { FunctionComponent } from 'react';
import { FavoriteButtonGroup } from 'features/favorites';
import { useImageActionHandlers } from 'features/images/hooks';
import { ImageFavoriteButtonGroupProps } from './ImageFavoriteButtonGroupProps';

export const ImageFavoriteButtonGroup: FunctionComponent<ImageFavoriteButtonGroupProps> = (props) => {
  const { image, ...rest } = props;
  const { handleAddToFavorite, handleRemoveFromFavorite } = useImageActionHandlers({ image, parentId: undefined });

  return (
    <FavoriteButtonGroup
      {...rest}
      onAddToFavorites={handleAddToFavorite}
      onRemoveFromFavorites={handleRemoveFromFavorite}
      isFavorite={!!image?.isFavourite}
    />
  );
};
