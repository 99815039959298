import { createSlice } from '@reduxjs/toolkit';
import { ListPaginationState } from 'features/list';
import { getListPaginationReducers } from 'features/list/listReducers';
import { getDefaultPaginationState } from 'features/pagination';

export type UserListState = ListPaginationState;

const initialState: UserListState = {
  paginationData: getDefaultPaginationState(),
};

const userListSlice = createSlice({
  name: 'userList',
  initialState,
  reducers: {
    ...getListPaginationReducers<UserListState>(),
  },
});

export const userListActions = userListSlice.actions;
export const userListReducer = userListSlice.reducer;
