import * as yup from 'yup';
import { FileBox, FileResponseDto, yupTestValidateFileSize, yupTestValidateFileType } from 'features/file';
import { nameof } from 'utils';

type FileBoxValidatorProps = {
  fileSizeMB: number;
  mimeTypes: Record<string, string>;
  optional?: boolean;
};

export const getFileBoxValidator = (props: FileBoxValidatorProps) => {
  const { fileSizeMB, mimeTypes, optional } = props;
  const fileSchema = yup.mixed<File | undefined>();

  return yup
    .object()
    .shape({
      preview: yup.mixed<FileResponseDto | undefined>().optional(),
      file: yup.lazy<typeof fileSchema>((value) => {
        if (value) {
          return fileSchema
            .defined()
            .test(yupTestValidateFileType(mimeTypes))
            .test(yupTestValidateFileSize(fileSizeMB));
        }

        if (optional) {
          return fileSchema.optional();
        } else {
          return fileSchema.when(nameof<FileBox>('previewUrl'), {
            is: (value?: FileResponseDto) => !value,
            then: fileSchema
              .defined()
              .test(yupTestValidateFileType(mimeTypes))
              .test(yupTestValidateFileSize(fileSizeMB)),
            otherwise: fileSchema.optional(),
          });
        }
      }),
    })
    .required();
};
