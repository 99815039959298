import cn from 'classnames';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useImageActionHandlers } from 'features/images/hooks';
import { favoritesAccessRuleService } from 'features/role';
import { ButtonKind } from 'ui-library/components/Button';
import { Icon } from 'ui-library/components/Icon';
import { IconButton, IconButtonVariant } from 'ui-library/components/IconButton';
import styles from './ImageCardFavoriteButton.module.scss';
import { ImageCardFavoriteButtonProps } from './ImageCardFavoriteButtonProps';

export const ImageCardFavoriteButton: FunctionComponent<ImageCardFavoriteButtonProps> = (props) => {
  const { t } = useTranslation();
  const { image, folderId, className: classNameProp } = props;
  const { handleAddToFavorite, handleRemoveFromFavorite } = useImageActionHandlers({ image, parentId: folderId });
  const isFavorite = image?.isFavourite;
  const className = cn(styles.root, classNameProp);

  return (
    <>
      {isFavorite === false && favoritesAccessRuleService.canCreate() && (
        <IconButton
          className={cn(className, styles.favoriteHover)}
          variant={IconButtonVariant.TRANSPARENT}
          as={ButtonKind.BUTTON}
          title={t('actions.addToFavorite')}
          onClick={handleAddToFavorite}
        >
          <Icon className={styles.favoriteIcon} name='favoriteFilled' />
        </IconButton>
      )}
      {isFavorite === true && favoritesAccessRuleService.canDelete() && (
        <IconButton
          className={cn(className, styles.active)}
          variant={IconButtonVariant.TRANSPARENT}
          as={ButtonKind.BUTTON}
          title={t('actions.removeFromFavorite')}
          onClick={handleRemoveFromFavorite}
        >
          <Icon className={styles.favoriteIconFilled} name='favoriteFilled' />
        </IconButton>
      )}
    </>
  );
};
