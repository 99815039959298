import { FunctionComponent } from 'react';
import { LayoutCardBase } from 'features/layouts';
import { useLayoutActions } from '../../../hooks';
import { LayoutCardExternalProps } from './LayoutCardProps';

export const LayoutCard: FunctionComponent<LayoutCardExternalProps> = (props) => {
  const { layout, groupId } = props;
  const { actions } = useLayoutActions({ layout, groupId });

  return <LayoutCardBase layout={layout} actions={actions} />;
};
