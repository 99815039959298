import { useNavigate } from 'react-router-dom';
import { signOut } from 'features/auth/authActions';
import { useAppDispatch } from 'infrastructure/redux';
import { ROUTE_PATHS } from 'infrastructure/routes';

export const useSignOut = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleSignOut = () => {
    navigate(ROUTE_PATHS.HOME); // затираем последний путь, где пользователь был авторизован
    dispatch(signOut());
  };

  return {
    handleSignOut,
  };
};
