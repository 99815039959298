import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { ModalSubmitActions } from 'features/modal';
import { useEditUserMutation, UserEditFormFields, UserRole } from 'features/users';
import { userEditActions } from 'features/users/slices';
import { NotDefinedParamException } from 'infrastructure/exceptions';
import { useTranslateEnum } from 'infrastructure/i18n';
import { parseApiError } from 'infrastructure/network';
import { useAppSelector, useAppDispatch } from 'infrastructure/redux';
import { Modal } from 'ui-library/components/Modal';
import { UserEditForm } from './UserEditForm/UserEditForm';

export const UserEdit = () => {
  const dispatch = useAppDispatch();
  const { isOpen, user } = useAppSelector((state) => state.userEdit);
  const [editUserMutation] = useEditUserMutation();
  const { translateValue } = useTranslateEnum('UserRole', UserRole);
  const { t } = useTranslation();

  const handleClose = () => {
    dispatch(userEditActions.close());
  };

  const submit = async (values: UserEditFormFields) => {
    try {
      if (!user) {
        throw new NotDefinedParamException('user');
      }

      await editUserMutation({
        userID: user.id,
        surname: values.surname,
        name: values.name,
        email: values.email,
        role: values.role,
        isBlocked: values.isBlocked,
        password: values.password || undefined,
        passwordCurrent: values.passwordCurrent || undefined,
      }).unwrap();

      toast.success(
        t('users.edit.successToastMessage', {
          role: translateValue(user.role),
          name: user.name,
          surname: user.surname,
        }),
      );
      handleClose();
    } catch (rawError) {
      const error = parseApiError(rawError);
      if (!error) {
        toast.error(t('users.edit.failedToastMessage'));
      } else {
        throw rawError;
      }
    }
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Modal.Title>{t('users.edit.title')}</Modal.Title>
      <UserEditForm
        defaultValues={user || {}}
        onSubmit={submit}
        renderActions={({ isSubmitting }) => <ModalSubmitActions loading={isSubmitting} onCancel={handleClose} />}
      />
    </Modal>
  );
};
