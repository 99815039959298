import { skipToken } from '@reduxjs/toolkit/dist/query';
import cn from 'classnames';
import { FunctionComponent, useMemo } from 'react';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { useFetchFolderImagesQuery } from 'features/images/imagesApi';
import { ImageDetailRouteSearchQueryParams } from 'features/images/types';
import { NavigationArrowButton } from 'features/navigation';
import { getImageDetailRoute } from 'infrastructure/routes/paths';
import { ButtonKind } from 'ui-library/components/Button';
import { Icon } from 'ui-library/components/Icon';
import { IconButton, IconButtonVariant } from 'ui-library/components/IconButton';
import { nameof } from 'utils';
import styles from './ImageArrowNavigation.module.scss';
import { ImageArrowNavigationProps } from './ImageArrowNavigationProps';

const IMAGE_COUNT = Number.MAX_SAFE_INTEGER;

export const ImageArrowNavigation: FunctionComponent<ImageArrowNavigationProps> = (props) => {
  const { image } = props;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const folderId = searchParams.get(nameof<ImageDetailRouteSearchQueryParams>('folderId'));
  const redirectUrl = searchParams.get(nameof<ImageDetailRouteSearchQueryParams>('redirectUrl'));
  const params: ImageDetailRouteSearchQueryParams = {
    redirectUrl: redirectUrl ?? undefined,
    folderId: folderId ?? undefined,
  };
  const { data } = useFetchFolderImagesQuery(
    folderId
      ? {
          folderID: folderId,
          currentPage: 1,
          pageSize: IMAGE_COUNT,
        }
      : skipToken,
  );
  const { children } = props;

  const totalCount = data?.totalCount ?? 0;
  const currentOrder = useMemo(() => data?.content.find((x) => x.id === image?.id)?.order ?? 0, [data, image]);

  const canGoNextOrder = currentOrder !== 0 && currentOrder + 1 <= totalCount;
  const canGoPreviousOrder = currentOrder !== 0 && currentOrder - 1 >= 0;

  const handleClose = () => {
    if (!redirectUrl) {
      return;
    }

    navigate(redirectUrl);
  };

  const handleGoNext = () => {
    const nextImage = data?.content.find((x) => x.order === currentOrder + 1);
    if (!image || !data || !nextImage) {
      return;
    }
    navigate(getImageDetailRoute(nextImage.id) + `?${createSearchParams(params)}`);
  };

  const handleGoPrevious = () => {
    const previousImage = data?.content.find((x) => x.order === currentOrder - 1);
    if (!image || !data || !previousImage) {
      return;
    }
    navigate(getImageDetailRoute(previousImage.id) + `?${createSearchParams(params)}`);
  };

  const ArrowLayout = (
    <>
      <NavigationArrowButton
        className={styles.navLeftButton}
        direction='left'
        disabled={!canGoPreviousOrder}
        onClick={handleGoPrevious}
      />
      {children}
      <NavigationArrowButton
        direction='right'
        className={styles.navRightButton}
        disabled={!canGoNextOrder}
        onClick={handleGoNext}
      />
    </>
  );
  const showArrowLayout = !!folderId;

  return (
    <div className={cn(styles.root, !showArrowLayout ? styles.placeholder : undefined)}>
      <IconButton
        className={cn(styles.closeButton)}
        as={ButtonKind.BUTTON}
        variant={IconButtonVariant.TRANSPARENT}
        onClick={handleClose}
      >
        <Icon name='cross' className={styles.icon} />
      </IconButton>
      {showArrowLayout ? ArrowLayout : children}
    </div>
  );
};
