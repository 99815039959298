import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getSortReducers, SortDirection, SortState } from 'features/sorting';
import { ListLayout } from '../../list/listLayout';
import { FolderResponseDto } from '../folderEntity';

type EntityType = FolderResponseDto;
export type FolderMuseumListState = {
  layoutType: ListLayout;
  sortingInfo: SortState<EntityType>;
};

const initialState: FolderMuseumListState = {
  layoutType: ListLayout.CARD,
  sortingInfo: {
    sortField: 'order',
    sortDirection: SortDirection.NULL,
  },
};

const folderMuseumListSlice = createSlice({
  name: 'folderMuseumList',
  initialState,
  reducers: {
    ...getSortReducers<FolderMuseumListState, EntityType>((state) => state.sortingInfo),
    changeLayoutType: (state, action: PayloadAction<ListLayout>) => {
      state.layoutType = action.payload;
    },
  },
});

export const folderMuseumListActions = folderMuseumListSlice.actions;
export const folderMuseumListReducer = folderMuseumListSlice.reducer;
