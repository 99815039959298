import cn from 'classnames';
import React, { FunctionComponent } from 'react';
import styles from './TableRow.module.scss';
import { TableRowProps } from './TableRowProps';

export const TableRow: FunctionComponent<TableRowProps> = (props) => {
  const { hover, className, ...rest } = props;

  return (
    <tr
      className={cn(styles.root, className, {
        [styles.hover]: hover,
      })}
      {...rest}
    />
  );
};
