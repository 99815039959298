export enum UserRole {
  USER = 'user',
  ADMIN = 'admin',
  SUPERADMIN = 'super_admin',
  GUEST = 'guest',
}

export type UserResponseDto = {
  id: string;
  surname: string;
  name: string;
  email: string;
  role: UserRole;
  isBlocked: boolean;
};

export type UserEditRequestDto = {
  userID: UserResponseDto['id'];
} & Partial<{
  surname: UserResponseDto['surname'];
  name: UserResponseDto['name'];
  email: UserResponseDto['email'];
  role: UserResponseDto['role'];
  isBlocked: UserResponseDto['isBlocked'];
  password: string;
  passwordCurrent: string;
}>;

export type UserCreateFormFields = {
  name: UserResponseDto['name'];
  surname?: UserResponseDto['surname'];
  email: UserResponseDto['email'];
  role: UserResponseDto['role'];
  password: string;
};

export type UserCreateRequestDto = UserCreateFormFields;

export type UserEditFormFields = {
  name: UserResponseDto['name'];
  surname?: UserResponseDto['surname'];
  email: UserResponseDto['email'];
  isBlocked: UserResponseDto['isBlocked'];
  role: UserResponseDto['role'];
  password?: UserEditRequestDto['password'];
  passwordConfirm?: UserEditRequestDto['password'];
  passwordCurrent?: UserEditRequestDto['passwordCurrent'];
};
