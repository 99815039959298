import cn from 'classnames';
import { FunctionComponent } from 'react';
import styles from './ModalContent.module.scss';
import { ModalContentProps } from './ModalContentProps';

export const ModalContent: FunctionComponent<ModalContentProps> = (props) => {
  const { children, className } = props;

  return <div className={cn(styles.root, className)}>{children}</div>;
};
