import { FunctionComponent } from 'react';
import { useUserTableColumns } from 'features/users/hooks';
import { Table, TableBody, TableHead } from 'ui-library/components/Table';
import { UserEmptyList } from '../UserEmptyList/UserEmptyList';
import { UserTableActions } from '../UserTableActions/UserTableActions';
import { UserTableAddRow } from '../UserTableAddRow/UserTableAddRow';
import { UserTableSkeleton } from '../UserTableSkeleton/UserTableSkeleton';
import { UserTableProps } from './UserTableProps';

export const UserTable: FunctionComponent<UserTableProps> = (props) => {
  const { isError, data, onCreate, isFetching } = props;
  const columns = useUserTableColumns();

  const isEmpty = !isError && data?.totalCount === 0;

  if (isEmpty) {
    return <UserEmptyList />;
  }

  return (
    <Table>
      <TableHead columns={columns} />
      {isFetching ? (
        <UserTableSkeleton />
      ) : (
        <>
          <UserTableAddRow onClick={onCreate} />

          <TableBody
            columns={columns}
            items={data?.content ?? []}
            getRowActions={(x, options) => <UserTableActions className={options?.className} user={x} />}
          />
        </>
      )}
    </Table>
  );
};
