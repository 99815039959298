import { Fragment, FunctionComponent } from 'react';
import { TableBodySkeleton } from 'ui-library/components/Table';
import { TableColumnSkeleton } from 'ui-library/components/Table/TableColumnSkeleton';
import { Skeleton } from 'ui-library/v1/components/Skeleton';
import styles from './FolderTableSkeleton.module.scss';

// eslint-disable-next-line no-magic-numbers
const groupsAmounts = [7, 4, 5];

const columns: Array<TableColumnSkeleton> = [
  {
    cellRenderer: () => (
      <div className={styles.col1}>
        <Skeleton width={24} radius={1} />
        <Skeleton width={450} />
      </div>
    ),
  },
  {
    cellRenderer: () => <Skeleton width={40} height={20} radius={6} />,
  },
  {
    cellRenderer: () => <Skeleton width={40} height={20} radius={6} />,
  },
];

export const FolderTableSkeleton: FunctionComponent = () => {
  return (
    <>
      {groupsAmounts.map((amount, index) => (
        <Fragment key={index}>
          <TableBodySkeleton className={styles.body} columns={columns} rows={amount} rowClassName={styles.row} />
        </Fragment>
      ))}
    </>
  );
};
