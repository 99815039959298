import cn from 'classnames';
import React, { FunctionComponent } from 'react';
import { Spinner } from 'ui-library/components/Spinner';
import styles from './FileTaskListLoading.module.scss';
import { FileTaskListLoadingProps } from './FileTaskListLoadingProps';

export const FileTaskListLoading: FunctionComponent<FileTaskListLoadingProps> = (props) => {
  const { className } = props;

  return (
    <div className={cn(styles.root, className)}>
      <Spinner className={styles.spinner} />
    </div>
  );
};
