import { FunctionComponent } from 'react';
import { Breadcrumbs } from 'features/breadcrumbs';
import { FavoriteAllGroupsHeaderInfo, FavoriteGroupsDataView } from 'features/favorites';
import styles from './FavoriteGroupsScreen.module.scss';

export const FavoriteGroupsScreen: FunctionComponent = () => {
  return (
    <div className={styles.root}>
      <Breadcrumbs className={styles.breadcrumbs} />
      <FavoriteAllGroupsHeaderInfo className={styles.header} />
      <FavoriteGroupsDataView />
    </div>
  );
};
