import { Disclosure } from '@headlessui/react';
import cn from 'classnames';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '../Icon';
import styles from './Accordion.module.scss';
import { AccordionProps } from './AccordionProps';

export const Accordion: FunctionComponent<AccordionProps> = (props) => {
  const { t } = useTranslation();
  const { label, as = 'div', defaultOpen, children, actions, classes, className } = props;

  return (
    <Disclosure as={as} className={className} defaultOpen={defaultOpen}>
      {({ open }) => (
        <>
          <div className={styles.header}>
            <Disclosure.Button className={cn(styles.label, classes?.label)}>
              {label}
              <Icon
                name='arrowUp'
                title={open ? t('uiLibrary.accordion.fold') : t('uiLibrary.accordion.unfold')}
                className={cn(styles.icon, {
                  [styles.iconClosed]: !open,
                })}
              />
            </Disclosure.Button>
            {actions}
          </div>

          <Disclosure.Panel
            static
            className={cn(styles.content, open ? classes?.contentOpened : undefined, {
              [styles.contentOpened]: open,
            })}
          >
            {children}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};
