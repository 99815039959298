import { FunctionComponent } from 'react';
import { addToFavoritesActions } from 'features/favorites';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux';
import { Modal } from 'ui-library/components/Modal';
import { AddToFavoritesForm } from '../AddToFavoritesForm/AddToFavoritesForm';
import { AddToFavoritesHeader } from '../AddToFavoritesHeader/AddToFavoritesHeader';

export const AddToFavorites: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector((state) => state.addToFavorites.isOpen);

  const handleClose = () => {
    dispatch(addToFavoritesActions.dispose());
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <AddToFavoritesHeader />
      <AddToFavoritesForm />
    </Modal>
  );
};
