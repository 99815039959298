import { FunctionComponent, useEffect } from 'react';
import { authService } from 'features/auth';
import { useLazySignOutQuery } from 'features/auth/authApi';
import { useAppSelector } from 'infrastructure/redux';
import { PublicRouteWrapperProps } from './publicRouteWrapperProps';

export const PublicRouteWrapper: FunctionComponent<PublicRouteWrapperProps> = (props) => {
  const [trigger] = useLazySignOutQuery();
  const isAuthorized = useAppSelector((state) => state.auth.isAuthorized);

  useEffect(() => {
    if (!isAuthorized && authService.accessToken) {
      trigger();
    }
  }, [isAuthorized, trigger]);

  return <>{props.screen}</>;
};
