import cn from 'classnames';
import styles from './ZeroScreenWrapper.module.scss';
import { ZeroScreenWrapperProps } from './ZeroScreenWrapperProps';

export const ZeroScreenWrapper = (props: ZeroScreenWrapperProps) => {
  const { className, theme = 'dark', renderIcon, renderButton, title, message } = props;

  return (
    <div className={cn(styles.root, className, styles[theme])}>
      {renderIcon({ className: styles.icon })}
      {title && <h4 className={styles.title}>{title}</h4>}
      <p className={styles.message}>{message}</p>
      {renderButton?.({ className: styles.button })}
    </div>
  );
};
