import { Controller, useFormContext } from 'react-hook-form';
import { TagsEditField } from 'features/tags';
import { TagsFormControllerProps } from './TagsFormControllerProps';

export const TagsFormController = (props: TagsFormControllerProps) => {
  const { name } = props;

  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => <TagsEditField initialTags={value || []} onChange={onChange} />}
    />
  );
};
