import cn from 'classnames';
import { forwardRef } from 'react';
import styles from './Label.module.scss';
import { LabelProps } from './LabelProps';

export const Label = forwardRef<HTMLLabelElement, LabelProps>((props, ref) => {
  const { className, disabled, label, required, endAdornment, children, ...rest } = props;

  return (
    <div className={styles.root}>
      <label
        className={cn(styles.label, className, {
          [styles.labelInactive]: disabled,
        })}
        ref={ref}
        {...rest}
      >
        {label}&nbsp;{`${required ? '*' : ''}`}
        {children}
      </label>
      {endAdornment}
    </div>
  );
});
