import { Listbox } from '@headlessui/react';
import cn from 'classnames';
import { Fragment, MouseEvent } from 'react';
import { ButtonKind } from 'ui-library/components/Button';
import { Icon } from 'ui-library/components/Icon';
import { IconButton, IconButtonVariant } from 'ui-library/components/IconButton';
import { InputLayout, InputLayoutVariant } from 'ui-library/components/InputLayout';
import styles from './MultiValueContainer.module.scss';
import { MultiValueContainerProps } from './MultiValueContainerProps';
import { MultiValueLabel } from './MultiValueLabel/MultiValueLabel';

export const MultiValueContainer = <Option, OptionValue = Option>(
  props: MultiValueContainerProps<Option, OptionValue>,
) => {
  const { open, options, placeholder, value, getOptionLabel, getOptionValue, onChange, disabled, error, InputProps } =
    props;

  const showPlaceholder = value?.length === 0;

  const onClear = (event: MouseEvent) => {
    event.stopPropagation();
    onChange([]);
  };

  return (
    <Listbox.Button as={Fragment}>
      <InputLayout
        variant={InputLayoutVariant.FILLED}
        error={error}
        tabIndex={0}
        className={styles.root}
        InputProps={{
          ...InputProps,
          className: cn(styles.root, InputProps?.className, {
            [styles.active]: open,
            [styles.disabled]: disabled,
          }),
        }}
        endAdornment={
          <div className={styles.endAdornment}>
            {!showPlaceholder && (
              <IconButton as={ButtonKind.BUTTON} variant={IconButtonVariant.TRANSPARENT} onClick={onClear}>
                <Icon size='small' name='cross' />
              </IconButton>
            )}

            <Icon name={open ? 'arrowUp' : 'arrowDown'} />
          </div>
        }
        renderControl={() => (
          <div className={styles.control}>
            <MultiValueLabel
              options={options}
              value={value}
              getOptionLabel={getOptionLabel}
              getOptionValue={getOptionValue}
            />

            {showPlaceholder && <span className={styles.placeholder}>{placeholder}</span>}
          </div>
        )}
      />
    </Listbox.Button>
  );
};
