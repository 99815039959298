import { FunctionComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FilePreviewLabel } from 'features/file';
import { useLanguageRelevantData } from 'infrastructure/i18n';
import { Icon } from 'ui-library/components/Icon';
import { Image } from 'ui-library/components/Image';
import { ListItem, ListItemKind } from 'ui-library/components/List';
import { ListItemIcon } from 'ui-library/components/List/ItemIcon/ListItemIcon';
import { ListItemText } from 'ui-library/components/List/ItemText/ListItemText';
import styles from './FolderListItem.module.scss';
import { FolderListItemProps } from './FolderListItemProps';

export const FolderListItem: FunctionComponent<FolderListItemProps> = (props) => {
  const { t } = useTranslation();
  const { matchProp } = useLanguageRelevantData();
  const { folder, onClick } = props;

  const preview = useMemo(() => folder.file?.previews?.find((x) => x.label === FilePreviewLabel.FOLDER_ICON), [folder]);

  return (
    <ListItem kind={ListItemKind.LI} onClick={onClick} disableGutters>
      <ListItemIcon>
        {folder.isRoot ? (
          <Image className={styles.icon} src={preview?.url} alt={t('folders.imageAlt')} />
        ) : (
          <Icon name='folderAlt' className={styles.icon} />
        )}
      </ListItemIcon>
      <ListItemText classes={{ primary: styles.primary }} primary={matchProp(folder.captionRu, folder.captionEn)} />
      <Icon className={styles.chevron} name='chevronRight' />
    </ListItem>
  );
};
