import { FunctionComponent } from 'react';
import { useLocation } from 'react-router-dom';
import { useFetchFavoritesQuery } from 'features/favorites';
import { favoriteImageListActions } from 'features/favorites/slices';
import { ImageDetailRouteSearchQueryParams, ImageLoadingError, ImagesEmpty } from 'features/images';
import { calculateOffset } from 'features/pagination';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux';
import { Pagination } from 'ui-library/components/Pagination';
import { ImageMasonry } from '../ImageMasonry/ImageMasonry';
import styles from './ImageDataView.module.scss';
import { ImageDataViewProps } from './ImageDataViewProps';

export const ImageDataView: FunctionComponent<ImageDataViewProps> = (props) => {
  const { groupId } = props;
  const dispatch = useAppDispatch();
  const { paginationData, sortingInfo } = useAppSelector((state) => state.favoriteImageList);
  const { pageSize, currentPage } = paginationData;
  const { sortDirection, sortField } = sortingInfo;
  const query = useFetchFavoritesQuery({
    groupID: groupId,
    items: [
      {
        type: 'image',
        limit: pageSize,
        sortDirection,
        sortFields: [sortField],
        offset: calculateOffset(currentPage, pageSize),
      },
    ],
  });
  const { isError } = query;
  const listData = query.data?.items[0].image;
  const totalCount = listData?.totalCount;
  const isEmpty = totalCount === 0;
  const location = useLocation();
  const params: ImageDetailRouteSearchQueryParams = {
    redirectUrl: location.pathname + location.search,
  };

  const getContent = () => {
    if (isError) {
      return <ImageLoadingError onReload={query.refetch} />;
    }

    if (isEmpty) {
      return <ImagesEmpty />;
    }

    return <ImageMasonry {...query} data={listData} groupId={groupId} params={params} />;
  };

  return (
    <div className={styles.root}>
      <div className={styles.listWrap}>{getContent()}</div>
      <div className={styles.paginationWrap}>
        <Pagination
          totalItems={totalCount}
          pageSize={pageSize}
          onPageChanged={(page) => dispatch(favoriteImageListActions.pageChanged({ page }))}
          currentPage={currentPage}
          scrollToStartAfterPageChanged
        />
      </div>
    </div>
  );
};
