import { createApi } from '@reduxjs/toolkit/query/react';
import { FetchImageFoldersQueryData } from 'features/images';
import { listQuery } from 'features/list';
import { fetchBaseAuthQuery, HTTP_METHODS, ListApiResponse, URL_FACTORY } from 'infrastructure/network';
import {
  FetchChildrenFolderQueryData,
  FetchLayoutFoldersQueryData,
  FetchRootFoldersQueryData,
  FolderCreateRequestDto,
  FolderDeleteRequestDto,
  FolderEditLinkedImagesRequestDto,
  FolderEditRequestDto,
  FolderResponseDto,
  FolderSearchRequestDto,
} from './folderEntity';

export const foldersApi = createApi({
  reducerPath: 'foldersApi',
  baseQuery: fetchBaseAuthQuery(),
  tagTypes: ['RootFolders', 'Folder', 'FolderChildren', 'SearchFolder', 'FolderPath', 'LayoutFolders', 'ImageFolders'],
  endpoints: (builder) => ({
    fetchLayoutFolders: builder.query<ListApiResponse<FolderResponseDto>, FetchLayoutFoldersQueryData>({
      query: ({ layoutID, ...rest }) =>
        listQuery({
          url: URL_FACTORY.LAYOUTS.LINKED_FOLDERS(layoutID),
          params: rest,
        }),
      providesTags: (result, error, { layoutID }) =>
        result
          ? [
              'LayoutFolders',
              { type: 'LayoutFolders', id: layoutID },
              ...result.content.map(({ id }) => ({ type: 'Folder' as const, id })),
            ]
          : ['LayoutFolders', { type: 'LayoutFolders', id: layoutID }],
    }),
    fetchImageFolders: builder.query<ListApiResponse<FolderResponseDto>, FetchImageFoldersQueryData>({
      query: ({ imageID, ...rest }) =>
        listQuery({
          url: URL_FACTORY.IMAGES.LINKED_FOLDERS(imageID),
          params: rest,
        }),
      providesTags: (result, error, { imageID }) =>
        result
          ? [
              'ImageFolders',
              { type: 'ImageFolders', id: imageID },
              ...result.content.map(({ id }) => ({ type: 'Folder' as const, id })),
            ]
          : ['ImageFolders', { type: 'ImageFolders', id: imageID }],
    }),
    fetchRootFolders: builder.query<ListApiResponse<FolderResponseDto>, FetchRootFoldersQueryData>({
      query: (params) => listQuery({ url: URL_FACTORY.FOLDERS.ROOT, params }),
      providesTags: (result) =>
        result
          ? ['RootFolders', ...result.content.map(({ id }) => ({ type: 'Folder' as const, id }))]
          : ['RootFolders'],
    }),
    fetchFolderChildren: builder.query<ListApiResponse<FolderResponseDto>, FetchChildrenFolderQueryData>({
      query: ({ folderId, ...restParams }) =>
        listQuery({
          url: URL_FACTORY.FOLDERS.CHILDREN(folderId),
          params: restParams,
        }),
      providesTags: (result, error, args) => ['FolderChildren', { type: 'FolderChildren' as const, id: args.folderId }],
    }),
    fetchFolderDetail: builder.query<FolderResponseDto, string>({
      query: (folderID) => URL_FACTORY.FOLDERS.DETAIL(folderID),
      providesTags: (result) => (result ? [{ type: 'Folder', id: result.id }, 'Folder'] : []),
    }),
    createFolder: builder.mutation<FolderResponseDto, FolderCreateRequestDto>({
      query: (data) => ({
        url: URL_FACTORY.FOLDERS.ROOT,
        method: HTTP_METHODS.POST,
        body: data,
      }),
      invalidatesTags: (result, error) => (error ? [] : ['SearchFolder']),
    }),
    editFolder: builder.mutation<FolderResponseDto, FolderEditRequestDto>({
      query: ({ folderID, ...restData }) => ({
        url: URL_FACTORY.FOLDERS.DETAIL(folderID),
        method: HTTP_METHODS.PATCH,
        body: restData,
      }),
    }),
    editFolderLinkedImages: builder.mutation<unknown, FolderEditLinkedImagesRequestDto>({
      query: ({ folderID, ...restData }) => ({
        url: URL_FACTORY.FOLDERS.LINKED_IMAGES(folderID),
        method: HTTP_METHODS.POST,
        body: restData,
      }),
    }),
    searchFolder: builder.query<ListApiResponse<FolderResponseDto>, FolderSearchRequestDto>({
      query: ({ currentPage, pageSize, sortFields, sortDirection, ...rest }) =>
        listQuery({
          url: URL_FACTORY.FOLDERS.SEARCH(),
          method: HTTP_METHODS.POST,
          params: { pageSize, currentPage, sortDirection, sortFields },
          body: rest,
        }),
      providesTags: (result) => (result ? ['SearchFolder'] : []),
    }),
    deleteFolder: builder.mutation<unknown, FolderDeleteRequestDto>({
      query: ({ folderID }) => ({
        url: URL_FACTORY.FOLDERS.DETAIL(folderID),
        method: HTTP_METHODS.DELETE,
      }),
      invalidatesTags: (result, error, { parentID }) => {
        if (parentID) {
          return ['SearchFolder', { type: 'FolderChildren', id: parentID }];
        } else {
          return ['RootFolders', 'SearchFolder'];
        }
      },
    }),
    fetchFolderPath: builder.query<Array<FolderResponseDto>, string>({
      query: (folderID) => URL_FACTORY.FOLDERS.PATH(folderID),
      providesTags: (result, error, folderId) => (result ? [{ type: 'FolderPath', id: folderId }] : []),
    }),
  }),
});

export const {
  useFetchFolderChildrenQuery,
  useLazyFetchFolderChildrenQuery,
  useEditFolderLinkedImagesMutation,
  useFetchRootFoldersQuery,
  useLazyFetchRootFoldersQuery,
  useFetchFolderDetailQuery,
  useLazyFetchFolderPathQuery,
  useFetchImageFoldersQuery,
  useLazyFetchImageFoldersQuery,
  useLazyFetchFolderDetailQuery,
  useCreateFolderMutation,
  useEditFolderMutation,
  useSearchFolderQuery,
  useDeleteFolderMutation,
  useFetchFolderPathQuery,
  useFetchLayoutFoldersQuery,
  useLazyFetchLayoutFoldersQuery,
} = foldersApi;
