import React, { FunctionComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FilePreviewLabel } from 'features/file';
import { useLanguageRelevantData } from 'infrastructure/i18n';
import { Button, ButtonKind, ButtonVariant } from 'ui-library/components/Button';
import { Icon } from 'ui-library/components/Icon';
import { Image } from 'ui-library/components/Image';
import { ListItem, ListItemKind } from 'ui-library/components/List';
import { ListItemIcon } from 'ui-library/components/List/ItemIcon/ListItemIcon';
import { ListItemText } from 'ui-library/components/List/ItemText/ListItemText';
import styles from './FolderEditList.module.scss';
import { FolderEditListProps } from './FolderEditListProps';

export const FolderEditList: FunctionComponent<FolderEditListProps> = (props) => {
  const { t } = useTranslation();
  const { matchProp } = useLanguageRelevantData();
  const { folder, onRemoveItem } = props;
  const preview = useMemo(() => folder.file?.previews?.find((x) => x.label === FilePreviewLabel.FOLDER_ICON), [folder]);

  const removeListItem = () => {
    onRemoveItem(folder);
  };

  return (
    <ListItem kind={ListItemKind.LI} disableGutters>
      <ListItemIcon>
        {folder.isRoot ? (
          <Image className={styles.icon} src={preview?.url} alt={t('folders.imageAlt')} />
        ) : (
          <Icon name='folderAlt' className={styles.icon} />
        )}
      </ListItemIcon>
      <div className={styles.folderName}>
        <ListItemText classes={{ primary: styles.primary }} primary={matchProp(folder.captionRu, folder.captionEn)} />
      </div>
      <Button
        className={styles.action}
        as={ButtonKind.BUTTON}
        onClick={removeListItem}
        variant={ButtonVariant.TRANSPARENT}
      >
        <Icon className={styles.cross} name='cross' />
      </Button>
    </ListItem>
  );
};
