import { FunctionComponent } from 'react';
import { ImageCardSkeleton } from '../ImageCardSkeleton/ImageCardSkeleton';
import { ImageGalleryGrid } from '../ImageGalleryGrid/ImageGalleryGrid';

const amountOfCards = 12;

export const ImageGallerySkeleton: FunctionComponent = () => {
  return (
    <ImageGalleryGrid>
      {[...Array(amountOfCards)].map((_, idx) => (
        <ImageCardSkeleton key={idx} />
      ))}
    </ImageGalleryGrid>
  );
};
