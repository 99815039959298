import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LinkingArtifactEntityType, LinkingEntity } from 'features/linking';
import { getDefaultModalState, getModalReducers, ModalState } from 'features/modal';
import { LinkingArtifactNavigatorTab } from './components/LinkingArtifactNavigator/LinkingArtifactNavigatorTab';

export type LinkingArtifactState = ModalState & {
  linkingArtifacts: Array<LinkingEntity>;
  selectedFolderId: string | undefined;
  query: string;
  tab: LinkingArtifactNavigatorTab;
  type?: LinkingArtifactEntityType;
};

const initialState: LinkingArtifactState = {
  ...getDefaultModalState(),
  selectedFolderId: undefined,
  tab: LinkingArtifactNavigatorTab.FOLDERS,
  linkingArtifacts: [],
  type: undefined,
  query: '',
};

const linkingArtifactSlice = createSlice({
  name: 'linkingArtifacts',
  initialState,
  reducers: {
    ...getModalReducers<LinkingArtifactState>((state) => state),
    setLinkingArtifacts: (state, action: PayloadAction<Array<LinkingEntity>>) => {
      state.linkingArtifacts = action.payload;
    },
    selectFolderId: (state, action: PayloadAction<string | undefined>) => {
      state.selectedFolderId = action.payload;
    },
    setLinkingType: (state, action: PayloadAction<LinkingArtifactEntityType>) => {
      state.type = action.payload;
    },
    changeTab: (state, action: PayloadAction<LinkingArtifactNavigatorTab>) => {
      state.tab = action.payload;
    },
    setQuery: (state, action: PayloadAction<string>) => {
      state.query = action.payload;
    },
    dispose: (state) => {
      state.isOpen = false;
      state.tab = LinkingArtifactNavigatorTab.FOLDERS;
      state.linkingArtifacts = [];
      state.selectedFolderId = undefined;
      state.query = '';
      state.type = undefined;
    },
  },
});

export const linkingArtifactActions = linkingArtifactSlice.actions;
export const linkingArtifactReducer = linkingArtifactSlice.reducer;
