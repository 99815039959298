import { createSlice } from '@reduxjs/toolkit';
import { UserResponseDto } from 'features/users';
import { RootState } from 'infrastructure/redux';
import { AUTH__SIGN_OUT } from './authActions';
import { authService } from './services/authService';
import { authApi } from '.';

export type AuthState = {
  isAuthorized: boolean;
  user?: UserResponseDto;
};

const initialState: AuthState = {
  isAuthorized: !!authService.accessToken,
  user: undefined,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signIn(state) {
      state.isAuthorized = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(AUTH__SIGN_OUT, (state) => {
      state.isAuthorized = false;
    });
    builder.addMatcher(authApi.endpoints.getCurrentUser.matchFulfilled, (state, { payload }) => {
      state.user = payload;
    });
  },
});

export const authActions = authSlice.actions;
export const authReducer = authSlice.reducer;
export const selectCurrentUser = (state: RootState) => state.auth.user;
