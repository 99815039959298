import { FunctionComponent } from 'react';
import { Navigate } from 'react-router-dom';
import { useAppSelector } from 'infrastructure/redux';
import { getGalleryRoute } from 'infrastructure/routes/paths';

export const HomeScreen: FunctionComponent = () => {
  const partition = useAppSelector((state) => state.toggleMuseum.partition);

  return <Navigate replace to={getGalleryRoute(partition)} />;
};
