import { matchLanguageProp } from '../utils/i18nUtils';
import { useCurrentLanguage } from './useCurrentLanguage';

export function useLanguageRelevantData() {
  const currentLang = useCurrentLanguage();

  return {
    matchProp: <T>(prop: T, propEng: T) => matchLanguageProp(currentLang, prop, propEng),
  };
}
