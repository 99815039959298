import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './FileDownloadsHeader.module.scss';

export const FileDownloadsHeader: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <h1 className={styles.title}>{t('files.downloads.title')}</h1>
      <p className={styles.description}>{t('files.downloads.description')}</p>
    </div>
  );
};
