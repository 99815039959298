import { createApi } from '@reduxjs/toolkit/query/react';
import { fetchBaseAuthQuery, HTTP_METHODS, URL_FACTORY } from 'infrastructure/network';
import {
  FileDownloadResponseDto,
  FileDownloadTaskRequestDto,
  FileDownloadTaskResponseDto,
  FileDownloadTaskRetryResponseDto,
  FileDownloadViewRequestDto,
  FileDownloadViewResponseDto,
  FileResponseDto,
  FileUploadRequestDto,
} from './fileEntity';

export const fileApi = createApi({
  reducerPath: 'fileApi',
  baseQuery: fetchBaseAuthQuery(),
  tagTypes: ['FileTasks'],
  endpoints: (builder) => ({
    uploadFile: builder.mutation<FileResponseDto, FileUploadRequestDto>({
      query: ({ file, fileType }) => {
        const formData = new FormData();
        formData.append('file', file);

        return {
          url: URL_FACTORY.FILES.UPLOAD(),
          method: HTTP_METHODS.POST,
          params: {
            fileType: fileType,
          },
          body: formData,
        };
      },
    }),
    downloadFiles: builder.mutation<FileDownloadTaskResponseDto, FileDownloadTaskRequestDto>({
      query: (data) => ({
        url: URL_FACTORY.FILES.DOWNLOADS(),
        method: HTTP_METHODS.POST,
        body: data,
      }),
      invalidatesTags: () => ['FileTasks'],
    }),
    fetchViewDownloadFiles: builder.query<FileDownloadViewResponseDto, FileDownloadViewRequestDto>({
      query: (data) => ({
        url: URL_FACTORY.FILES.VIEW_DOWNLOADS(),
        method: HTTP_METHODS.POST,
        body: data,
      }),
      providesTags: ['FileTasks'],
    }),
    fetchFileDownloadTask: builder.query<FileDownloadResponseDto, string>({
      query: (taskID) => ({
        url: URL_FACTORY.FILES.DOWNLOAD_DETAIL(taskID),
        method: HTTP_METHODS.GET,
      }),
    }),
    deleteFileDownloadTask: builder.mutation<unknown, string>({
      query: (taskID) => ({
        url: URL_FACTORY.FILES.DOWNLOAD_DETAIL(taskID),
        method: HTTP_METHODS.DELETE,
      }),
      invalidatesTags: () => ['FileTasks'],
    }),
    retryFileDownloadTask: builder.mutation<FileDownloadTaskRetryResponseDto, string>({
      query: (taskID) => ({
        url: URL_FACTORY.FILES.DOWNLOAD_RETRY(taskID),
        method: HTTP_METHODS.POST,
      }),
      invalidatesTags: () => ['FileTasks'],
    }),
  }),
});

export const {
  useUploadFileMutation,
  useFetchFileDownloadTaskQuery,
  useDeleteFileDownloadTaskMutation,
  useDownloadFilesMutation,
  useRetryFileDownloadTaskMutation,
  useFetchViewDownloadFilesQuery,
} = fileApi;
