import { FunctionComponent, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalSubmitActions } from 'features/modal';
import { TagEditFormFields, TagEditRequestDto, useEditTagMutation } from 'features/tags';
import { TagsManageContext } from 'features/tags/contexts';
import { isSameTagNameEn, isSameTagNameRu } from 'features/tags/utils';
import { ApiLikeErrorException } from 'infrastructure/exceptions';
import { ApiErrorCode } from 'infrastructure/network';
import { Modal } from 'ui-library/components/Modal';
import { TagEditForm } from '../TagEditForm';
import styles from './TagEdit.module.scss';
import { TagsEditProps } from './TagsEditProps';

export const TagsEdit: FunctionComponent<TagsEditProps> = (props) => {
  const { tag, close, isOpen } = props;
  const [editTagsMutation] = useEditTagMutation();
  const { tags, editTag } = useContext(TagsManageContext);
  const { t } = useTranslation();

  const onSubmit = async (values: TagEditFormFields) => {
    const dto: TagEditRequestDto = {
      id: tag.id,
      nameRu: values.nameRu,
      nameEn: values.nameEn,
      origin: tag.origin,
    };

    const existsArtifactTagRu = tags.some((currentTag) => isSameTagNameRu(currentTag, dto));
    const existsArtifactTagEn = tags.some((currentTag) => isSameTagNameEn(currentTag, dto));

    if (existsArtifactTagRu && existsArtifactTagEn) {
      throw new ApiLikeErrorException(ApiErrorCode.VALIDATION_FAILED, t('tags.errors.duplicate'));
    } else {
      await editTagsMutation(dto).unwrap();

      editTag(dto);
      close();
    }
  };

  return (
    <Modal variant='popup' open={isOpen} onClose={close}>
      <Modal.Title>{t('tags.edit.title')}</Modal.Title>
      <p className={styles.description}>{t('tags.edit.description')}</p>
      <TagEditForm
        defaultValues={tag}
        onSubmit={onSubmit}
        renderActions={({ isSubmitting }) => (
          <ModalSubmitActions submitLabel={t('actions.save')} loading={isSubmitting} onCancel={close} />
        )}
      />
    </Modal>
  );
};
