import cn from 'classnames';
import { Icon } from '../Icon';
import styles from './Chip.module.scss';
import { ChipProps } from './ChipProps';

export const Chip = (props: ChipProps) => {
  const { label, labelClassName, onDelete, className } = props;

  return (
    <div className={cn(className, styles.root)}>
      <span className={cn(styles.label, labelClassName)}>{label}</span>

      {onDelete && (
        <span className={styles.icon} onClick={onDelete}>
          <Icon name='cross' size='small' />
        </span>
      )}
    </div>
  );
};
