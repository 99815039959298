import cn from 'classnames';
import { TableCell } from '../TableCell/TableCell';
import { TableRow } from '../TableRow/TableRow';
import { TableBodyProps } from './TableBodyProps';

export const TableBody = <T,>(props: TableBodyProps<T>) => {
  const {
    items,
    columns,
    getRowActions,
    hover = true,
    onRowClick,
    className,
    rowClassName,
    lastColumnCell,
    conditionLastCell,
  } = props;

  return (
    <tbody className={className}>
      {items.map((row, rowIdx) => (
        <TableRow key={rowIdx} hover={hover} className={cn(rowClassName)}>
          {columns.map((col, idx) => {
            return (
              <TableCell key={idx} className={col.className?.(row)}>
                {col.cellRenderer(row)}
              </TableCell>
            );
          })}
          {getRowActions && !conditionLastCell && <td align={'right'}>{getRowActions(row)}</td>}
          {lastColumnCell && conditionLastCell && <td align={'right'}>{lastColumnCell(row)}</td>}
        </TableRow>
      ))}
    </tbody>
  );
};
