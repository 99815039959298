import { FunctionComponent } from 'react';
import { linkingArtifactActions } from 'features/linking';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux';
import { Modal } from 'ui-library/components/Modal';
import { LinkingArtifactNavigator } from '../LinkingArtifactNavigator/LinkingArtifactNavigator';
import { LinkingArtifactNavigatorHeader } from '../LinkingArtifactNavigatorHeader/LinkingArtifactNavigatorHeader';

export const LinkingArtifact: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector((state) => state.linkingArtifacts.isOpen);

  const handleClose = () => {
    dispatch(linkingArtifactActions.dispose());
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <LinkingArtifactNavigatorHeader />
      <LinkingArtifactNavigator />
    </Modal>
  );
};
