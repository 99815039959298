import { ButtonKindProps, InnerAnchorKindProps, OuterAnchorKindProps } from '../../../components/Button';

export enum IconButtonVariant {
  MAIN = 'main',
  SECONDARY = 'secondary',
  TRANSPARENT = 'transparent',
}

export type IconButtonSize = 'normal' | 'small';

export type IconButtonProps = (ButtonKindProps | OuterAnchorKindProps | InnerAnchorKindProps) & CommonProps;

type CommonProps = {
  variant?: IconButtonVariant;
  size?: IconButtonSize;
};
