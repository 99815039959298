import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FolderPartition } from 'features/folders';
import { getDefaultModalState, getModalReducers, ModalState } from 'features/modal';
import { MovingArtifactNavigatorTab } from './components/MovingArtifactNavigator/MovingArtifactNavigatorTab';
import { MovingArtifactEntity, MovingArtifactEntityType } from './movingArtifactsEntity';

export type MovingArtifactsState = ModalState & {
  movingArtifacts: Array<MovingArtifactEntity>;
  selectedFolderId: string | undefined;
  selectedPartition?: FolderPartition;
  tab: MovingArtifactNavigatorTab;
  type?: MovingArtifactEntityType;
  query: string;
};

const initialState: MovingArtifactsState = {
  ...getDefaultModalState(),
  tab: MovingArtifactNavigatorTab.FOLDERS,
  selectedFolderId: undefined,
  movingArtifacts: [],
  type: undefined,
  selectedPartition: undefined,
  query: '',
};

const movingArtifactsSlice = createSlice({
  name: 'movingArtifacts',
  initialState,
  reducers: {
    ...getModalReducers<MovingArtifactsState>((state) => state),
    setMovingArtifacts: (state, action: PayloadAction<Array<MovingArtifactEntity>>) => {
      state.movingArtifacts = action.payload;
    },
    selectFolderId: (state, action: PayloadAction<string | undefined>) => {
      state.selectedFolderId = action.payload;
    },
    changeTab: (state, action: PayloadAction<MovingArtifactNavigatorTab>) => {
      state.tab = action.payload;
    },
    setMovingType: (state, action: PayloadAction<MovingArtifactEntityType>) => {
      state.type = action.payload;
    },
    setQuery: (state, action: PayloadAction<string>) => {
      state.query = action.payload;
    },
    selectPartition: (state, action: PayloadAction<FolderPartition | undefined>) => {
      state.selectedPartition = action.payload;
    },
    dispose: (state) => {
      state.tab = MovingArtifactNavigatorTab.FOLDERS;
      state.isOpen = false;
      state.movingArtifacts = [];
      state.selectedFolderId = undefined;
      state.query = '';
      state.selectedPartition = undefined;
      state.type = undefined;
    },
  },
});

export const movingArtifactsActions = movingArtifactsSlice.actions;
export const movingArtifactsReducer = movingArtifactsSlice.reducer;
