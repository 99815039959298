import { GalleryImageItemDto } from 'features/images/imageEntity';
import { imageAccessRuleService } from 'features/role';

export class ImageApproverService {
  public canUnlink(image?: GalleryImageItemDto) {
    if (!image || !imageAccessRuleService.canUnLink()) {
      return false;
    }

    return image.foldersCount > 1;
  }

  public canSelectImage() {
    return imageAccessRuleService.canLink() || imageAccessRuleService.canUnLink();
  }
}

export const imageApproverService = new ImageApproverService();
