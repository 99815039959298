import { FunctionComponent } from 'react';
import { ImageEditFormFields } from 'features/images/imageEntity';
import { LayoutsFormController } from 'features/layouts';
import { FormCol, FormRow } from 'ui-library/components/Form';
import styles from './ImageEditFormLayoutsTab.module.scss';

const layoutField: keyof ImageEditFormFields = 'layouts';

export const ImageEditFormLayoutsTab: FunctionComponent = () => {
  return (
    <div className={styles.root}>
      <FormRow>
        <FormCol>
          <LayoutsFormController name={layoutField} />
        </FormCol>
      </FormRow>
    </div>
  );
};
