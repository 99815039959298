import React, { FunctionComponent } from 'react';
import { useRootFolderActions } from 'features/rootFolders/hooks/useRootFolderActions';
import { TableRowActions } from 'ui-library/v1/components/TableRowActions';
import { FolderTableActionsProps } from './FolderTableActionsProps';

export const FolderTableActions: FunctionComponent<FolderTableActionsProps> = (props) => {
  const { className, folder } = props;
  const { actions } = useRootFolderActions(folder);

  return <TableRowActions actions={actions} className={className} />;
};
