import { Controller, useFormContext } from 'react-hook-form';
import { SwitchField } from 'ui-library/components/SwitchField';
import { SwitchFormControllerProps } from './SwitchFormControllerProps';

export const SwitchFormController = (props: SwitchFormControllerProps) => {
  const { name, ...restProps } = props;

  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => <SwitchField checked={value} onChange={onChange} {...restProps} />}
    />
  );
};
