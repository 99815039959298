import { createSlice } from '@reduxjs/toolkit';
import { ListPaginationState } from 'features/list';
import { getListPaginationReducers } from 'features/list/listReducers';
import { getDefaultPaginationState } from 'features/pagination';

export type SearchRootFolderState = ListPaginationState;

const initialState: SearchRootFolderState = {
  paginationData: getDefaultPaginationState(),
};

const searchRootFolderSlice = createSlice({
  name: 'searchRootFolder',
  initialState,
  reducers: getListPaginationReducers<SearchRootFolderState>(),
});

export const searchRootFolderActions = searchRootFolderSlice.actions;
export const searchRootFolderReducer = searchRootFolderSlice.reducer;
