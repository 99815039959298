import cn from 'classnames';
import { forwardRef, Fragment } from 'react';
import { FormControl } from '../FormControl';
import { Label } from '../Label';
import { Switch } from '../Switch';
import styles from './SwitchField.module.scss';
import { SwitchFieldProps } from './SwitchFieldProps';

export const SwitchField = forwardRef<HTMLButtonElement, SwitchFieldProps>((props, ref) => {
  const { className, label, disabled, checked, onChange, LabelProps, SwitchProps } = props;

  return (
    <Switch.Group>
      <FormControl className={cn(styles.root, className)}>
        <Switch.Label as={Fragment}>
          <Label disabled={disabled} label={label} className={styles.label} {...LabelProps} />
        </Switch.Label>
        <Switch ref={ref} checked={checked} onChange={onChange} {...SwitchProps} />
      </FormControl>
    </Switch.Group>
  );
});
