import { FunctionComponent } from 'react';
import { createSearchParams, Link } from 'react-router-dom';
import { getLayoutDetailRoute } from 'infrastructure/routes/paths';
import { LayoutCard } from '../LayoutCard/LayoutCard';
import { LayoutCardListGrid } from '../LayoutCardListGrid/LayoutCardListGrid';
import { LayoutCardListSkeleton } from '../LayoutCardListSkeleton/LayoutCardListSkeleton';
import { LayoutEmptyChildren } from '../LayoutEmptyChildren/LayoutEmptyChildren';
import { LayoutCardListProps } from './LayoutCardListProps';

export const LayoutCardList: FunctionComponent<LayoutCardListProps> = (props) => {
  const { data, isFetching, folderId, params } = props;
  const isEmpty = data?.totalCount === 0;

  if (isEmpty) {
    return <LayoutEmptyChildren />;
  }

  return (
    <LayoutCardListGrid>
      {isFetching ? (
        <LayoutCardListSkeleton />
      ) : (
        data?.content.map((x) => (
          <Link key={x.id} to={getLayoutDetailRoute(x.id) + `?${createSearchParams(params)}`}>
            <LayoutCard folderId={folderId} layout={x} />
          </Link>
        ))
      )}
    </LayoutCardListGrid>
  );
};
