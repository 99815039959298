import cn from 'classnames';
import { Fragment, FunctionComponent, MouseEvent } from 'react';
import { List, ListItem, ListItemKind } from '../List';
import { ListItemIcon } from '../List/ItemIcon/ListItemIcon';
import { ListItemText } from '../List/ItemText/ListItemText';
import styles from './ActionList.module.scss';
import { ActionListItem, ActionListItemVariant, ActionListProps } from './ActionListProps';
import { getAvailableListActions } from './actionListUtils';

export const ActionList: FunctionComponent<ActionListProps> = (props) => {
  const { actions, className, onClose } = props;

  const handleClick = (x: ActionListItem, event: MouseEvent<HTMLElement>) => {
    x.onClick?.(event as never);

    onClose();
  };

  return (
    <List className={cn(styles.list, className)}>
      {getAvailableListActions(actions).map((x) => (
        <Fragment key={x.label}>
          {x.hasDivider && <hr className={styles.divider} />}
          <ListItem
            className={cn({
              [styles.listItemDanger]: x.variant === ActionListItemVariant.DANGER,
            })}
            padding='large'
            kind={(x.kind ?? ListItemKind.LI) as never}
            onClick={(event: never) => handleClick(x, event)}
          >
            {x.startAdornment && <ListItemIcon className={styles.startAdornment}>{x.startAdornment}</ListItemIcon>}
            <ListItemText primary={x.label} className={styles.text} classes={{ primary: styles.primary }} />
            {x.endAdornment && <ListItemIcon className={styles.endAdornment}>{x.endAdornment}</ListItemIcon>}
          </ListItem>
        </Fragment>
      ))}
    </List>
  );
};
