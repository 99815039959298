import { PopoverOptions, usePopover } from './hooks/usePopover';
import { PopoverContext } from './hooks/usePopoverState';

/**
 * @deprecated
 */
export function Popover({
  children,
  ...restOptions
}: {
  children: React.ReactNode;
} & PopoverOptions) {
  // This can accept any props as options, e.g. `placement`,
  // or other positioning options.
  const popover = usePopover({ ...restOptions });

  return <PopoverContext.Provider value={popover}>{children}</PopoverContext.Provider>;
}
