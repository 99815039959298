import { HTMLAttributes, ReactHTML, ReactNode } from 'react';

export enum CardVariant {
  MAIN = 'main',
  OUTLINED = 'outlined',
  TRANSPARENT = 'transparent',
}

export type CardProps = {
  as?: keyof ReactHTML;
  variant?: CardVariant;
  children: ReactNode;
  padding?: 'none' | 'normal';
} & HTMLAttributes<HTMLElement>;
