import { BaseQueryFn, fetchBaseQuery as fetchBaseQueryRTK } from '@reduxjs/toolkit/dist/query';
import {
  FetchArgs,
  FetchBaseQueryArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { API_URL } from '../urlFactory';

export const fetchBaseQuery = <T = unknown>(args?: FetchBaseQueryArgs) => {
  return fetchBaseQueryRTK({
    ...args,
    baseUrl: API_URL,
  }) as BaseQueryFn<string | FetchArgs, T, FetchBaseQueryError, Record<string, never>, FetchBaseQueryMeta>;
};
