import { ApiErrorCode, ApiErrorResponse } from 'infrastructure/network';
import { ReferenceBookType } from '../referenceBookEntity';
import { formTranslationsService } from '../services';

export const apiErrorMessageMatcher = (error: ApiErrorResponse | undefined, type: ReferenceBookType) => {
  const translations = formTranslationsService.getTranslations(type);

  if (!error) {
    return translations.createFailedToastMessage;
  }

  const messageByCode: Partial<Record<ApiErrorCode, string>> = {
    [ApiErrorCode.CONFLICT]: translations.createConflictToastMessage,
  };

  return (messageByCode[error.code] as never) || translations.createFailedToastMessage;
};
