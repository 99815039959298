import cn from 'classnames';
import React, { FunctionComponent } from 'react';
import styles from './FormHelperText.module.scss';
import { FormHelperTextProps } from './FormHelperTextProps';

export const FormHelperText: FunctionComponent<FormHelperTextProps> = (props) => {
  const { error, children, className, as = 'p', ...rest } = props;

  return React.createElement(
    as,
    {
      ...rest,
      className: cn(styles.helperText, className, {
        [styles.error]: !!error,
      }),
    },
    children,
  );
};
