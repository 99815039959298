import React, { FunctionComponent } from 'react';
import { useTranslateEnum } from 'infrastructure/i18n';
import { Tab } from 'ui-library/components/Tab';
import { FileActiveDownloadsTab } from '../FileActiveDownloadsTab/FileActiveDownloadsTab';
import { FileHistoryDownloadsTab } from '../FileHistoryDownloadsTab/FileHistoryDownloadsTab';
import styles from './FileDownloadsTabGroup.module.scss';
import { FileDownloadViewTab } from './FileDownloadViewTab';

export const FileDownloadsTabGroup: FunctionComponent = () => {
  const { translateValue } = useTranslateEnum('FileDownloadViewTab', FileDownloadViewTab);

  return (
    <Tab.Group as='div'>
      <Tab.List>
        <Tab>{translateValue(FileDownloadViewTab.ACTIVE)}</Tab>
        <Tab>{translateValue(FileDownloadViewTab.HISTORY)}</Tab>
      </Tab.List>
      <Tab.Panels className={styles.panels}>
        <Tab.Panel>
          <FileActiveDownloadsTab />
        </Tab.Panel>
        <Tab.Panel>
          <FileHistoryDownloadsTab />
        </Tab.Panel>
      </Tab.Panels>
    </Tab.Group>
  );
};
