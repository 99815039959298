import { useTranslation } from 'react-i18next';
import { Icon } from 'ui-library/components/Icon';
import { ZeroScreenWrapper } from 'ui-library/components/ZeroScreen';
import { Button, ButtonKind, ButtonVariant } from 'ui-library/v1/components/Button';
import styles from './LayoutLoadingError.module.scss';
import { LayoutLoadingErrorProps } from './LayoutLoadingErrorProps';

export const LayoutLoadingError = (props: LayoutLoadingErrorProps) => {
  const { onReload } = props;
  const { t } = useTranslation();

  return (
    <ZeroScreenWrapper
      className={styles.root}
      theme={'dark'}
      renderIcon={(props) => <Icon name='documentIllustration' size='none' {...props} />}
      renderButton={(props) => (
        <Button
          as={ButtonKind.BUTTON}
          variant={ButtonVariant.SECONDARY}
          onClick={onReload}
          size='small'
          theme={'default'}
          {...props}
        >
          {t('actions.refresh')}
        </Button>
      )}
      message={t('layouts.errorLoading.message')}
    />
  );
};
