import { Menu as BuiltinMenu } from '@headlessui/react';
import cn from 'classnames';
import { FunctionComponent } from 'react';
import { MenuArrowButton } from './ArrowButton/MenuArrowButton';
import { MenuButton } from './Button/MenuButton';
import { MenuItem } from './Item/MenuItem';
import { MenuItems } from './Items/MenuItems';
import styles from './Menu.module.scss';
import { MenuProps } from './MenuProps';

const MenuRoot: FunctionComponent<MenuProps> = (props) => {
  const { children, className } = props;

  return (
    <BuiltinMenu className={cn(styles.root, className)} as='div'>
      {children}
    </BuiltinMenu>
  );
};

export const Menu = Object.assign(MenuRoot, {
  Item: MenuItem,
  Items: MenuItems,
  Button: MenuButton,
  ArrowButton: MenuArrowButton,
});
