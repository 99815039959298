import { BaseQueryFn, QueryDefinition } from '@reduxjs/toolkit/dist/query';
import { UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { useCallback, useEffect, useState } from 'react';
import { ListApiResponse } from 'infrastructure/network';
import { PaginationState } from './paginationState';
import { calculateTotalPages } from './paginationUtils';

type InfiniteLoadingReturnParams<T> = {
  data: Array<T> | undefined;
  isFetching: boolean;
  isLoading: boolean;
  isError: boolean;
  hasMore: boolean;
  readMore: () => void;
  refresh: () => void;
};

type InfiniteLoadingOptions = {
  pageSize: number;
};

type QueryOptions = {
  skip: boolean;
};

const DEFAULT_PAGE_SIZE = 50;

export function useInfiniteLoading<TDto, TResultType, TOptions>(
  useGetListQuery: UseQuery<QueryDefinition<TOptions, BaseQueryFn, string, TResultType, string>>,
  getQueryParameters: (state: PaginationState) => TOptions,
  listDataGetter: (data?: TResultType) => ListApiResponse<TDto> | undefined,
  options: InfiniteLoadingOptions = { pageSize: DEFAULT_PAGE_SIZE },
  queryOptions: QueryOptions | undefined = undefined,
): InfiniteLoadingReturnParams<TDto> {
  const [currentPage, setCurrentPage] = useState(1);
  const [combinedData, setCombinedData] = useState<Array<TDto> | undefined>(undefined);
  const { pageSize } = options;

  const query = useGetListQuery(getQueryParameters({ pageSize, currentPage }), queryOptions);
  const listData = listDataGetter(query.data);
  const listContent = listData?.content;
  const totalPages = calculateTotalPages(listData?.totalCount ?? 0, pageSize);
  const hasMore = currentPage < totalPages;

  const readMore = useCallback(() => {
    if (!query.isFetching && hasMore) {
      setCurrentPage((page) => page + 1);
    }
  }, [hasMore, query.isFetching]);

  const refresh = useCallback(() => {
    if (currentPage === 1) {
      query.refetch();
    } else {
      setCurrentPage(1);
    }
  }, [currentPage, query]);

  useEffect(() => {
    if (!listContent || !Array.isArray(listContent)) {
      return;
    }

    if (currentPage === 1) {
      setCombinedData(listContent);
    } else {
      setCombinedData((previousData) => [...(previousData ?? []), ...listContent]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listContent]);

  return {
    data: combinedData,
    hasMore,
    isError: query?.isError,
    isFetching: query?.isFetching,
    isLoading: query?.isLoading,
    readMore,
    refresh,
  };
}
