import cn from 'classnames';
import React, { FunctionComponent } from 'react';
import { Icon } from 'ui-library/components/Icon';
import styles from './BreadcrumbDivider.module.scss';
import { BreadcrumbDividerProps } from './BreadcrumbDividerProps';

export const BreadcrumbDivider: FunctionComponent<BreadcrumbDividerProps> = (props) => {
  const { className } = props;

  return <span className={cn(styles.root, className)}>{<Icon name={'arrowBread'} size={'small'} />}</span>;
};
