import React, { FunctionComponent } from 'react';
import { TableRowActions } from 'ui-library/components/Table';
import { useRootFolderActions } from '../../../hooks';
import { RootFolderTableActionsProps } from './RootFolderTableActionsProps';

export const RootFolderTableActions: FunctionComponent<RootFolderTableActionsProps> = (props) => {
  const { folder, groupId, className } = props;
  const { actions } = useRootFolderActions({
    folder: folder,
    groupId: groupId,
  });

  return <TableRowActions actions={actions} className={className} />;
};
