import { FunctionComponent, SyntheticEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { FolderResponseDto, FolderTableActions, FolderTableSkeleton, useFolderTableColumns } from 'features/folders';
import { useFolderTableHeadColumns } from 'features/search/hooks';
import { getFolderDetailRoute } from 'infrastructure/routes/paths';
import { Table, TableBody, TableHead } from 'ui-library/components/Table';
import { FolderTableProps } from './FolderTableProps';

export const FolderTable: FunctionComponent<FolderTableProps> = (props) => {
  const navigate = useNavigate();
  const columns = useFolderTableColumns();
  const headColumns = useFolderTableHeadColumns();

  const handleRowClick = (event: SyntheticEvent, item: FolderResponseDto) => {
    navigate(getFolderDetailRoute(item.id, item.partition));
  };

  return (
    <Table>
      <TableHead columns={headColumns} />
      {props.isFetching ? (
        <FolderTableSkeleton />
      ) : (
        <TableBody
          columns={columns}
          items={props.data?.content || []}
          getRowActions={(x, options) => <FolderTableActions folder={x} className={options?.className} />}
          onRowClick={handleRowClick}
        />
      )}
    </Table>
  );
};
