import React, { FunctionComponent } from 'react';
import { Card, CardVariant } from 'ui-library/components/Card';
import styles from './FileFormatCard.module.scss';
import { FileFormatCardProps } from './FileFormatCardProps';

export const FileFormatCard: FunctionComponent<FileFormatCardProps> = (props) => {
  const { icon, title, description, onClick } = props;

  return (
    <Card variant={CardVariant.OUTLINED} className={styles.root} onClick={onClick}>
      <span className={styles.icon}>{icon}</span>
      <span className={styles.title}>{title}</span>
      <span className={styles.subtitle}>{description}</span>
    </Card>
  );
};
