import { useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useModalState } from 'features/modal';
import { formTranslationsService, ReferenceBook, ReferenceBookSelectField } from 'features/referenceBook';
import { ReferenceBookCreate } from 'features/referenceBook';
import { ReferenceBookFormControllerProps } from './ReferenceBookFormControllerProps';

export const ReferenceBookFormController = (props: ReferenceBookFormControllerProps) => {
  const { name, isCreatable, AutocompleteFieldProps, type, ...restProps } = props;

  const createModalState = useModalState();

  const { t } = useTranslation();
  const translations = useRef(formTranslationsService.getTranslations(type)).current;

  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext();

  const handleCreated = (referenceBook: ReferenceBook) => {
    setValue(name, referenceBook);
  };

  return (
    <>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <ReferenceBookSelectField
            {...restProps}
            type={type}
            AutocompleteFieldProps={{
              ...AutocompleteFieldProps,
              error: errors[name]?.message as string,
              value: value,
              onChange(e) {
                onChange(e);
                if (AutocompleteFieldProps.onChange) {
                  AutocompleteFieldProps.onChange(e);
                }
              },
              AutocompleteProps: {
                ...AutocompleteFieldProps?.AutocompleteProps,
                canUnselect: true,
                onCreate: isCreatable ? createModalState.open : undefined,
                createLabel: t(translations.createTitle as never),
              },
            }}
          />
        )}
      />
      <ReferenceBookCreate
        isOpen={createModalState.isOpen}
        type={type}
        onClose={createModalState.close}
        onCreated={handleCreated}
      />
    </>
  );
};
