import { FunctionComponent } from 'react';
import { Navigate } from 'react-router';
import { authService } from 'features/auth';
import { useSignOutQuery } from 'features/auth/authApi';
import { ROUTE_PATHS } from 'infrastructure/routes';

export const SignOutScreen: FunctionComponent = () => {
  useSignOutQuery(undefined, { skip: !authService.accessToken });

  return <Navigate to={ROUTE_PATHS.AUTH.SIGN_IN} replace />;
};
