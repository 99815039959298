import { skipToken } from '@reduxjs/toolkit/dist/query';
import { FunctionComponent } from 'react';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import { ImageDetailRouteSearchQueryParams } from 'features/images';
import { LayoutCardSkeleton, useFetchImageLayoutsQuery } from 'features/layouts';
import { getLayoutDetailRoute } from 'infrastructure/routes/paths';
import { Link, LinkKind } from 'ui-library/components/Link';
import { nameof } from 'utils';
import styles from './ImageViewLayouts.module.scss';
import { ImageViewLayoutsProps } from './ImageViewLayoutsProps';
import { LayoutCard } from './LayoutCard/LayoutCard';

export const ImageViewLayouts: FunctionComponent<ImageViewLayoutsProps> = (props) => {
  const { image } = props;
  const { data, isFetching } = useFetchImageLayoutsQuery(image ? { imageID: image.id } : skipToken);
  const [searchParams] = useSearchParams();
  const redirectUrl = searchParams.get(nameof<ImageDetailRouteSearchQueryParams>('redirectUrl'));
  const params: ImageDetailRouteSearchQueryParams = { redirectUrl: redirectUrl as string };
  return (
    <div className={styles.root}>
      {isFetching ? (
        <LayoutCardSkeleton />
      ) : (
        data?.content.map((x) => (
          <Link key={x.id} as={LinkKind.INNER} to={getLayoutDetailRoute(x.id) + `?${createSearchParams(params)}`}>
            <LayoutCard className={styles.layout} layout={x} />
          </Link>
        ))
      )}
    </div>
  );
};
