import cn from 'classnames';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { invertLanguage, useCurrentLanguage } from 'infrastructure/i18n';
import { Button, ButtonKind, ButtonVariant } from 'ui-library/components/Button';
import styles from './ToggleLanguageButton.module.scss';
import { ToggleLanguageButtonProps } from './ToggleLanguageButtonProps';

export const ToggleLanguageButton: FunctionComponent<ToggleLanguageButtonProps> = (props) => {
  const { theme } = props;
  const { i18n } = useTranslation();
  const currentLang = useCurrentLanguage();

  const handleClick = () => {
    i18n.changeLanguage(invertLanguage(currentLang));
  };

  return (
    <Button
      as={ButtonKind.BUTTON}
      variant={ButtonVariant.TRANSPARENT}
      onClick={handleClick}
      className={cn(styles.button, {
        [styles.themeLight]: theme === 'light',
      })}
    >
      {invertLanguage(currentLang)}
    </Button>
  );
};
