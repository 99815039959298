import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MultiValueLabelProps } from './MultiValueLabelProps';

export const MultiValueLabel = <Option, OptionValue = Option>(props: MultiValueLabelProps<Option, OptionValue>) => {
  const { options, value, getOptionLabel, getOptionValue } = props;
  const { t } = useTranslation();

  const selectedOptions = useMemo(
    () => options.filter((option) => value.includes(getOptionValue(option) as OptionValue)),
    [getOptionValue, options, value],
  );

  if (value.length === 1) {
    return <>{getOptionLabel(selectedOptions[0])}</>;
  }

  if (value.length === options.length) {
    return <>{t('uiLibrary.select.selectedAll')}</>;
  }

  if (value.length > 1) {
    return <>{t('uiLibrary.select.selectedNum', { num: value.length })}</>;
  }

  return null;
};
