import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FilePreviewLabel } from 'features/file';
import { FolderResponseDto, FolderStatus, hasNestedInfo } from 'features/folders';
import { FolderTableFavoriteButton } from 'features/v1/folder/FolderTableFavotiteButton/FolderTableFavoriteButton';
import { useLanguageRelevantData, useTranslateEnum } from 'infrastructure/i18n';
import { getFolderDetailRoute } from 'infrastructure/routes/paths';
import { Icon } from 'ui-library/components/Icon';
import { Image } from 'ui-library/components/Image';
import { IconCellRenderer, NumberCellRenderer, TextCellRenderer } from 'ui-library/components/Table';
import { TableColumn } from 'ui-library/components/Table/TableColumn';
import { nameof } from 'utils';
import styles from './useRootFolderTableColumns.module.scss';

export const useRootFolderTableColumns = () => {
  const { t } = useTranslation();
  const { matchProp } = useLanguageRelevantData();
  const { translateValue } = useTranslateEnum('FolderStatus', FolderStatus);

  const columns = useMemo<Array<TableColumn<FolderResponseDto>>>(() => {
    const columns: Array<TableColumn<FolderResponseDto>> = [
      {
        fieldName: 'caption',
        width: '65%',
        cellRenderer: (data) => (
          <Link to={getFolderDetailRoute(data.id, data.partition)}>
            <TextCellRenderer
              value={matchProp(data.captionRu, data.captionEn)}
              textClassName={styles.text}
              icon={
                <Image
                  className={styles.image}
                  src={data.file?.previews?.find((x) => x.label === FilePreviewLabel.FOLDER_ICON)?.url}
                  alt={t('folders.imageAlt')}
                />
              }
            />
          </Link>
        ),
      },
      {
        fieldName: nameof<FolderResponseDto>('isFavourite'),
        width: '6%',
        className: (data) => (data.isFavourite ? undefined : styles.hover),
        cellRenderer: (data) => (
          <IconCellRenderer>
            <FolderTableFavoriteButton folder={data} className={styles.favorite} />
          </IconCellRenderer>
        ),
      },
      {
        fieldName: nameof<FolderResponseDto>('childrenCount'),
        className: (data) => (hasNestedInfo(data.childrenCount) ? undefined : styles.hidden),
        width: '12%',
        cellRenderer: (data) => (
          <NumberCellRenderer
            className={styles.count}
            value={data.childrenCount}
            icon={<Icon className={styles.icon} name={'folderAlt'} title={t('folders.title')} />}
          />
        ),
      },
      {
        fieldName: 'artifactCount',
        className: (data) =>
          hasNestedInfo(data.layoutCount) || hasNestedInfo(data.imageCount) ? undefined : styles.hidden,
        width: '12%',
        cellRenderer: (data) => (
          <NumberCellRenderer
            className={styles.count}
            value={hasNestedInfo(data.layoutCount) ? data.layoutCount : data.imageCount}
            icon={
              hasNestedInfo(data.layoutCount) ? (
                <Icon className={styles.icon} name={'layout'} title={t('layouts.title')} />
              ) : (
                <Icon className={styles.icon} name={'image'} title={t('images.title')} />
              )
            }
          />
        ),
      },
    ];

    return columns;
  }, [matchProp, t]);

  return columns;
};
