import { FunctionComponent } from 'react';
import { movingArtifactsActions } from 'features/movingArtifacts';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux';
import { Modal } from 'ui-library/components/Modal';
import { MovingArtifactNavigator } from '../MovingArtifactNavigator/MovingArtifactNavigator';
import { MovingArtifactNavigatorHeader } from '../MovingArtifactNavigatorHeader/MovingArtifactNavigatorHeader';

export const MovingArtifact: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector((state) => state.movingArtifacts.isOpen);

  const handleClose = () => {
    dispatch(movingArtifactsActions.dispose());
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <MovingArtifactNavigatorHeader />
      <MovingArtifactNavigator />
    </Modal>
  );
};
