import { useTranslation } from 'react-i18next';
import { favoritesAccessRuleService, fileAccessRuleService, folderAccessRuleService } from 'features/role';
import { Icon } from 'ui-library/components/Icon';
import { ActionListItem, ActionListItemVariant } from 'ui-library/v1/components/ActionList';
import { FolderResponseDto } from '../folderEntity';
import { folderApproverService } from '../services';
import { useFolderActionHandlers } from './useFolderActionHandlers';

export const useFolderActions = (folder: FolderResponseDto) => {
  const { t } = useTranslation();
  const {
    handleView,
    handleMove,
    handleAddToFavorite,
    handleDelete,
    handleEdit,
    handleRemoveFromFavorite,
    handleDownload,
    handleShare,
  } = useFolderActionHandlers(folder);

  const actions: Array<ActionListItem> = [
    {
      label: t('actions.view'),
      onClick: handleView,
      hasAccess: () => folderAccessRuleService.canView(),
      startAdornment: <Icon name='info' />,
    },
    {
      label: t('actions.edit'),
      onClick: handleEdit,
      hasAccess: () => folderAccessRuleService.canEdit(),
      startAdornment: <Icon name='edit' />,
    },
    {
      label: t('actions.move'),
      onClick: handleMove,
      hasAccess: () => folderAccessRuleService.canMove(),
      startAdornment: <Icon name='toFolder' />,
    },
  ];

  actions.push(
    {
      label: t('actions.remove'),
      onClick: handleDelete,
      variant: ActionListItemVariant.DANGER,
      hasAccess: () => folderApproverService.canRemove(folder),
      startAdornment: <Icon name='trash' />,
    },
    {
      label: t('actions.share'),
      onClick: handleShare,
      hasAccess: () => fileAccessRuleService.canShare(),
      startAdornment: <Icon name='share' />,
    },
    {
      label: t('actions.download'),
      onClick: handleDownload,
      hasAccess: () => fileAccessRuleService.canDownload(),
      startAdornment: <Icon name='downloadAlt' />,
    },
  );
  if (folder?.isFavourite) {
    actions.push({
      label: t('actions.removeFromFavorite'),
      onClick: handleRemoveFromFavorite,
      hasAccess: () => favoritesAccessRuleService.canDelete(),
      startAdornment: <Icon name='favoriteDelete' />,
    });
  } else {
    actions.push({
      label: t('actions.addToFavorite'),
      onClick: handleAddToFavorite,
      hasAccess: () => favoritesAccessRuleService.canCreate(),
      startAdornment: <Icon name='favorite' />,
    });
  }

  return { actions };
};
