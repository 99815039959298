import { useTranslation } from 'react-i18next';
import { FILE_FORMATS } from 'features/file';
import { ActionListItem } from 'ui-library/components/ActionList';
import { LayoutResponseDto } from '../layoutEntity';
import { useLayoutActionHandlers } from './useLayoutActionHandlers';

export type UseLayoutDownloadActionsOptions = {
  layout: LayoutResponseDto | undefined;
};

export const useLayoutDownloadActions = (options: UseLayoutDownloadActionsOptions) => {
  const { handleJpegDownload, handleTifDownload } = useLayoutActionHandlers({
    layout: options.layout,
    parentId: undefined,
  });
  const { t } = useTranslation();

  const actions: Array<ActionListItem> = [
    {
      label: t('files.downloadInFormat', {
        format: FILE_FORMATS.tif,
      }),
      onClick: handleTifDownload,
    },
    {
      label: t('files.downloadInFormat', {
        format: FILE_FORMATS.jpeg,
      }),
      onClick: handleJpegDownload,
    },
  ];

  return { actions };
};
