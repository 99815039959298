import { forwardRef } from 'react';
import { useLayoutActions } from '../../hooks';
import { LayoutCardBase } from '../LayoutCardBase/LayoutCardBase';
import { LayoutCardProps } from './LayoutCardProps';

export const LayoutCard = forwardRef<HTMLDivElement, LayoutCardProps>((props, ref) => {
  const { layout, folderId, ...restProps } = props;
  const { actions } = useLayoutActions({ layout, parentId: folderId });

  return <LayoutCardBase layout={layout} actions={actions} {...restProps} ref={ref} />;
});
