import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { favoriteGroupEditActions, favoriteGroupRemoveActions } from 'features/favorites';
import { favoritesAccessRuleService } from 'features/role';
import { useAppDispatch } from 'infrastructure/redux';
import { ButtonKind } from 'ui-library/components/Button';
import { Icon } from 'ui-library/components/Icon';
import { IconButton, IconButtonVariant } from 'ui-library/components/IconButton';
import styles from './FavoriteGroupButtonGroup.module.scss';
import { FavoriteGroupButtonGroupProps } from './FavoriteGroupButtonGroupProps';

export const FavoriteGroupButtonGroup: FunctionComponent<FavoriteGroupButtonGroupProps> = (props) => {
  const { group } = props;
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleEditClick = () => {
    dispatch(favoriteGroupEditActions.selectGroup(group?.id));
    dispatch(favoriteGroupEditActions.open());
  };

  const handleDeleteClick = () => {
    dispatch(favoriteGroupRemoveActions.selectGroupId(group?.id));
    dispatch(favoriteGroupRemoveActions.open());
  };

  return (
    <div className={styles.root}>
      {favoritesAccessRuleService.canEdit() && (
        <IconButton as={ButtonKind.BUTTON} title={t('actions.edit')} onClick={handleEditClick}>
          <Icon name='edit' />
        </IconButton>
      )}

      {favoritesAccessRuleService.canDelete() && (
        <IconButton
          as={ButtonKind.BUTTON}
          variant={IconButtonVariant.SECONDARY}
          className={styles.icon}
          title={t('actions.remove')}
          onClick={handleDeleteClick}
        >
          <Icon name='trash' />
        </IconButton>
      )}
    </div>
  );
};
