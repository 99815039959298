import { FunctionComponent } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CreateArtifactTypes } from 'features/createArtifact';
import { FileBox, FileUploader, uploadMaxFileSizeMB, uploadSupportedMimeImageTypes } from 'features/file';
import { FoldersFormController } from 'features/folders';
import { CenturyAndHalfReferenceBookField, ReferenceBookType } from 'features/referenceBook';
import { useEnumOptions } from 'features/select';
import { TagsFormController } from 'features/tags';
import { SelectFormController } from 'infrastructure/form';
import { ReferenceBookFormController } from 'infrastructure/form/components';
import { FormCol, FormRow } from 'ui-library/components/Form';
import { TextField } from 'ui-library/components/TextField';
import { nameof } from 'utils';
import { ImageEditFormFields, ImageStatus } from '../../../imageEntity';
import styles from './ImageEditFormInfoTab.module.scss';
import { ImageEditFormInfoTabProps } from './ImageEditFormInfoTabProps';

const fileBoxField: keyof ImageEditFormFields = 'fileBox';
const fileBoxFileField = `${fileBoxField}.${nameof<FileBox>('file')}` as const;
const archiveNameField: keyof ImageEditFormFields = 'archiveName';
const captionRuField: keyof ImageEditFormFields = 'captionRu';
const captionEnField: keyof ImageEditFormFields = 'captionEn';
const authorField: keyof ImageEditFormFields = 'author';
const yearField: keyof ImageEditFormFields = 'year';
const statusField: keyof ImageEditFormFields = 'status';
const centuryField: keyof ImageEditFormFields = 'century';
const centuryHalfField: keyof ImageEditFormFields = 'centuryHalf';
const materialsTechnicsField: keyof ImageEditFormFields = 'materialsTechnics';
const artFormField: keyof ImageEditFormFields = 'artForm';
const dimensionsRuField: keyof ImageEditFormFields = 'dimensionsRu';
const dimensionsEnField: keyof ImageEditFormFields = 'dimensionsEn';
const tagsField: keyof ImageEditFormFields = 'tags';
const foldersField: keyof ImageEditFormFields = 'folders';

export const ImageEditFormInfoTab: FunctionComponent<ImageEditFormInfoTabProps> = (props) => {
  const { isCreate } = props;
  const { t } = useTranslation();
  const { options: imageStatusOptions } = useEnumOptions('ImageStatus', ImageStatus);

  const {
    control,
    getValues,
    formState: { errors },
    register,
    setError,
    clearErrors,
  } = useFormContext<ImageEditFormFields>();

  return (
    <div className={styles.root}>
      <FormRow>
        <FormCol>
          <Controller
            control={control}
            name={fileBoxFileField}
            render={({ field: { value, onChange } }) => (
              <FileUploader
                previewUrl={getValues().fileBox?.previewUrl}
                error={errors[fileBoxField]?.file?.message}
                defaultSelectedFile={value as File}
                onChange={(file) => {
                  clearErrors(fileBoxFileField);
                  onChange(file);
                }}
                onError={() => {
                  setError(fileBoxFileField, {
                    message: t('validation.file.type'),
                  });
                }}
                maxFileSizeMB={uploadMaxFileSizeMB}
                supportedMimeTypes={uploadSupportedMimeImageTypes}
              />
            )}
          />
        </FormCol>
      </FormRow>
      {!isCreate && (
        <FormRow>
          <FormCol>
            <TextField
              disabled
              error={errors[archiveNameField]?.message}
              label={t('imageEditForm.archiveName.label')}
              InputProps={{
                placeholder: t('imageEditForm.archiveName.placeholder'),
                ...register(archiveNameField),
              }}
            />
          </FormCol>
        </FormRow>
      )}
      <FormRow>
        <FormCol>
          <TextField
            required
            error={errors[captionRuField]?.message}
            label={t('imageEditForm.captionRu.label')}
            InputProps={{
              placeholder: t('imageEditForm.captionRu.placeholder'),
              ...register(captionRuField),
            }}
          />
        </FormCol>
      </FormRow>
      <FormRow>
        <FormCol>
          <TextField
            required
            error={errors[captionEnField]?.message}
            label={t('imageEditForm.captionEn.label')}
            InputProps={{
              placeholder: t('imageEditForm.captionEn.placeholder'),
              ...register(captionEnField),
            }}
          />
        </FormCol>
      </FormRow>
      <FormRow>
        <FormCol>
          <ReferenceBookFormController
            name={authorField}
            type={ReferenceBookType.AUTHORS}
            isCreatable
            AutocompleteFieldProps={{
              label: t('imageEditForm.author.label'),
              placeholder: t('imageEditForm.common.selectPlaceholder'),
            }}
          />
        </FormCol>
      </FormRow>
      <FormRow>
        <FormCol>
          <ReferenceBookFormController
            name={yearField}
            type={ReferenceBookType.YEARS}
            isCreatable
            AutocompleteFieldProps={{
              label: t('imageEditForm.year.label'),
              placeholder: t('imageEditForm.common.selectPlaceholder'),
            }}
          />
        </FormCol>
        <FormCol>
          <SelectFormController
            required
            name={statusField}
            label={t('imageEditForm.status.label')}
            placeholder={t('imageEditForm.common.selectPlaceholder')}
            options={imageStatusOptions}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
          />
        </FormCol>
      </FormRow>
      <CenturyAndHalfReferenceBookField
        centuryName={centuryField}
        centuryHalfName={centuryHalfField}
        isAbleToAddCentury
      />
      <FormRow>
        <FormCol>
          <ReferenceBookFormController
            name={materialsTechnicsField}
            type={ReferenceBookType.MATERIAL_TECHNIQUE}
            isCreatable
            AutocompleteFieldProps={{
              label: t('imageEditForm.materialsTechnics.label'),
              placeholder: t('imageEditForm.common.selectPlaceholder'),
            }}
          />
        </FormCol>
      </FormRow>
      <FormRow>
        <FormCol>
          <ReferenceBookFormController
            name={artFormField}
            type={ReferenceBookType.KINDS_ART}
            isCreatable
            AutocompleteFieldProps={{
              label: t('imageEditForm.artForm.label'),
              placeholder: t('imageEditForm.common.selectPlaceholder'),
            }}
          />
        </FormCol>
      </FormRow>
      <FormRow>
        <FormCol>
          <TextField
            label={t('imageEditForm.dimensionsRu.label')}
            error={errors[dimensionsRuField]?.message}
            InputProps={{
              placeholder: t('imageEditForm.dimensionsRu.placeholder'),
              ...register(dimensionsRuField),
            }}
          />
        </FormCol>
        <FormCol>
          <TextField
            label={t('imageEditForm.dimensionsEn.label')}
            error={errors[dimensionsEnField]?.message}
            InputProps={{
              placeholder: t('imageEditForm.dimensionsEn.placeholder'),
              ...register(dimensionsEnField),
            }}
          />
        </FormCol>
      </FormRow>
      <FormRow>
        <FormCol>
          <TagsFormController name={tagsField} />
        </FormCol>
      </FormRow>
      <FormRow>
        <FormCol>
          <FoldersFormController name={foldersField} type={CreateArtifactTypes.IMAGES} />
        </FormCol>
      </FormRow>
    </div>
  );
};
