export const validateFileType = (file: File, supportedMimeTypes: Record<string, string>) => {
  if (!Object.values(supportedMimeTypes).includes(file.type)) {
    const fileSplit = file.name.split('.');
    const fileExtension = fileSplit[fileSplit.length - 1];

    if (!Object.keys(supportedMimeTypes).includes(fileExtension)) {
      return false;
    }
  }

  return true;
};

const oneMegaByte = 1e6;
const byteToMegaByte = (byte: number) => byte / oneMegaByte;

export const validateFileSize = (file: File, maxFileSizeMB: number) => {
  if (byteToMegaByte(file.size) > maxFileSizeMB) {
    return false;
  }

  return true;
};

export const validateFile = (supportedMimeTypes: Record<string, string>, maxFileSizeMB: number) => {
  return (file: File) => {
    return validateFileType(file, supportedMimeTypes) && validateFileSize(file, maxFileSizeMB);
  };
};
