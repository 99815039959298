import { skipToken } from '@reduxjs/toolkit/dist/query';
import { FunctionComponent } from 'react';
import { useFetchFileDownloadTaskQuery } from 'features/file/fileApi';
import { FileDownloadDetailInfo } from '../FileDownloadDetailInfo/FileDownloadDetailInfo';
import { FileDownloadDetailDataViewProps } from './FileDownloadDetailDataViewProps';

const POLLING_INTERVAL_MS = 15000;

export const FileDownloadDetailDataView: FunctionComponent<FileDownloadDetailDataViewProps> = (props) => {
  const { taskId } = props;
  const query = useFetchFileDownloadTaskQuery(taskId ?? skipToken, {
    pollingInterval: POLLING_INTERVAL_MS,
  });

  return <FileDownloadDetailInfo {...query} />;
};
