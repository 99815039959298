import cn from 'classnames';
import { FunctionComponent } from 'react';
import { FilePreviewLabel, ImageFullViewer } from 'features/file';
import { useModalState } from 'features/modal';
import { ButtonKind } from 'ui-library/components/Button';
import { Icon } from 'ui-library/components/Icon';
import { IconButton, IconButtonVariant } from 'ui-library/components/IconButton';
import styles from './LayoutDetailImageButtonGroup.module.scss';
import { LayoutDetailImageButtonGroupProps } from './LayoutDetailImageButtonGroupProps';

export const LayoutDetailImageButtonGroup: FunctionComponent<LayoutDetailImageButtonGroupProps> = (props) => {
  const { open, ...restModalProps } = useModalState();
  const { className, layout } = props;

  const preview = layout?.file?.previews?.find((x) => x.label === FilePreviewLabel.LAYOUT_FULLSCREEN);

  return (
    <div className={cn(styles.root, className)}>
      <IconButton className={styles.button} as={ButtonKind.BUTTON} variant={IconButtonVariant.SECONDARY} onClick={open}>
        <Icon name='expand' />
      </IconButton>
      <ImageFullViewer src={preview?.url} {...restModalProps} />
    </div>
  );
};
