import * as yup from 'yup';
import { ReferenceBookType } from './referenceBookEntity';

export const referenceBookSchema = yup
  .object()
  .nullable()
  .shape({
    id: yup.string(),
    captionRu: yup.string(),
    captionEn: yup.string(),
    type: yup.mixed<ReferenceBookType>().optional().oneOf(Object.values(ReferenceBookType)),
  });
