import React from 'react';
import { FolderPartition } from 'features/folders';
import { FIRST_PAGE } from 'features/pagination';
import { userAccessRuleService } from 'features/role';
import { SearchScreenResultTab } from 'features/search/enums';
import { useSearchQueryParams } from 'features/search/hooks';
import {
  searchFolderActions,
  searchImageActions,
  searchLayoutActions,
  searchRootFolderActions,
} from 'features/search/slices';
import { useEnumOptions } from 'features/select';
import { useAppDispatch } from 'infrastructure/redux';
import { SingleValue } from 'ui-library/components/BaseSelect';
import { Select } from 'ui-library/v1/components/Select';
import styles from './SearchToggle.module.scss';

export const SearchToggle = () => {
  const { options: folderPartitionOptions } = useEnumOptions('FolderPartition', FolderPartition);
  const { updateParams, partition } = useSearchQueryParams();
  const dispatch = useAppDispatch();

  const filteredOptions = folderPartitionOptions.filter((option) => {
    if (option.value === FolderPartition.AI) {
      return userAccessRuleService.canUseAISearch();
    }

    return true;
  });

  const onChange = (option: SingleValue<FolderPartition>) => {
    if (!option) {
      return;
    }

    updateParams({
      partition: option,
      tab: SearchScreenResultTab.IMAGES,
    });
    dispatch(searchRootFolderActions.pageChanged({ page: FIRST_PAGE }));
    dispatch(searchFolderActions.pageChanged({ page: FIRST_PAGE }));
    dispatch(searchLayoutActions.pageChanged({ page: FIRST_PAGE }));
    dispatch(searchImageActions.pageChanged({ page: FIRST_PAGE }));
  };

  return (
    <div className={styles.root}>
      <Select
        className={styles.select}
        onChange={onChange}
        options={filteredOptions}
        value={partition as FolderPartition}
        getOptionLabel={(x) => x.label}
        getOptionValue={(x) => x.value}
        InputProps={{ className: styles.input }}
      />
    </div>
  );
};
