import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { foldersApi } from 'features/folders';
import { imagesApi } from 'features/images/imagesApi';
import { searchApi } from 'features/search';
import { useLanguageRelevantData } from 'infrastructure/i18n';
import { useAppDispatch } from 'infrastructure/redux';
import { GalleryImageItemDto, ImageGalleryFlatListItem } from '../imageEntity';
import styles from './useUnlinkImage.module.scss';

export const useUnlinkImage = () => {
  const { matchProp } = useLanguageRelevantData();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const buildSuccessToast = (content: JSX.Element) => {
    toast.success(<div className={styles.notification}>{content}</div>);
  };

  const buildErrorToast = (content: JSX.Element) => {
    toast.error(<div className={styles.notification}>{content}</div>);
  };

  const handleUnlinkImage = (image: GalleryImageItemDto, folder: ImageGalleryFlatListItem) => {
    if (!folder) {
      throw new Error('Destination folder is not selected');
    }
    const isRoot = folder.isRoot;

    dispatch(searchApi.util.invalidateTags(['SearchResult']));

    dispatch(foldersApi.util.invalidateTags(['Folder', 'FolderChildren', 'RootFolders']));
    dispatch(
      imagesApi.util.invalidateTags([
        { type: 'ImageGallery', id: folder.id },
        { type: 'FolderImages', id: folder.id },
      ]),
    );

    buildSuccessToast(
      <>
        {t(isRoot ? 'unlinkArtifact.success.unlinkImageFromRoot' : 'unlinkArtifact.success.unlinkImageFromFolder', {
          image: matchProp(image?.captionRu, image?.captionEn),
        })}
        &nbsp;«{matchProp(folder.captionRu, folder.captionEn)}»
      </>,
    );
  };

  const handleUnlinkImageError = () => {
    buildErrorToast(<>{t('unlinkArtifact.error.unlinkErrorImage')}</>);
  };

  return {
    handleUnlinkImage,
    handleUnlinkImageError,
  };
};
