import { skipToken } from '@reduxjs/toolkit/dist/query';
import { FunctionComponent } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate } from 'react-router-dom';
import { favoriteGroupRemoveActions, useDeleteGroupMutation, useFetchGroupQuery } from 'features/favorites';
import { NotDefinedParamException } from 'infrastructure/exceptions';
import { useLanguageRelevantData } from 'infrastructure/i18n';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux';
import { ROUTE_PATHS } from 'infrastructure/routes';
import { ActionButton } from 'ui-library/components/ActionButton';
import { ButtonKind, ButtonVariant } from 'ui-library/components/Button';
import { Modal } from 'ui-library/components/Modal';

export const FavoriteGroupRemoveConfirm: FunctionComponent = () => {
  const { matchProp } = useLanguageRelevantData();
  const dispatch = useAppDispatch();
  const { isOpen, selectedGroupId } = useAppSelector((state) => state.favoriteGroupRemove);
  const match = useMatch(ROUTE_PATHS.FAVORITES.GROUP_DETAIL);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [deleteGroupMutation, { isLoading }] = useDeleteGroupMutation();
  const { data } = useFetchGroupQuery(selectedGroupId ?? skipToken);

  const handleClose = () => {
    dispatch(favoriteGroupRemoveActions.selectGroupId(undefined));
    dispatch(favoriteGroupRemoveActions.close());
  };

  const handleRemove = async () => {
    if (!data) {
      throw new NotDefinedParamException('group');
    }

    try {
      await deleteGroupMutation(data?.id).unwrap();

      if (match) {
        navigate(ROUTE_PATHS.FAVORITES.GROUPS, { replace: true });
      }

      toast.success(
        t('favorites.group.deleteSucceed', {
          name: matchProp(data.captionRu, data.captionEn),
        }),
      );
      handleClose();
    } catch (e) {
      toast.error(
        t('favorites.group.deleteFailed', {
          name: matchProp(data.captionRu, data.captionEn),
        }),
      );
    }
  };

  return (
    <Modal variant='popup' open={isOpen} onClose={handleClose}>
      <Modal.Title>{t('favorites.group.remove.title')}</Modal.Title>
      <Modal.Content>{t('favorites.group.remove.description')}</Modal.Content>
      <Modal.Actions>
        <ActionButton as={ButtonKind.BUTTON} onClick={handleRemove} loading={isLoading}>
          {t('actions.remove')}
        </ActionButton>
        <ActionButton as={ButtonKind.BUTTON} variant={ButtonVariant.SECONDARY} onClick={handleClose}>
          {t('actions.cancel')}
        </ActionButton>
      </Modal.Actions>
    </Modal>
  );
};
