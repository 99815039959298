import { createSlice } from '@reduxjs/toolkit';
import { ActiveFileDownloadTaskState } from '../components/ActiveFileDownloadTasks/ActiveFileDownloadTaskState';
import { fileDownloadService } from '../services/fileDownloadService';

export type ActiveFileDownloadTasksState = {
  windowState: ActiveFileDownloadTaskState;
};

const initialState: ActiveFileDownloadTasksState = {
  windowState: fileDownloadService.activeTaskModalState ?? ActiveFileDownloadTaskState.CLOSED,
};

const activeFileDownloadTasksSlice = createSlice({
  name: 'activeFileDownloadTasks',
  initialState,
  reducers: {
    toggle: (state) => {
      state.windowState =
        state.windowState === ActiveFileDownloadTaskState.COLLAPSED
          ? ActiveFileDownloadTaskState.OPENED
          : ActiveFileDownloadTaskState.COLLAPSED;
    },
    open: (state) => {
      state.windowState = ActiveFileDownloadTaskState.OPENED;
    },
    close: (state) => {
      state.windowState = ActiveFileDownloadTaskState.CLOSED;
    },
  },
});

export const activeFileDownloadTasksActions = activeFileDownloadTasksSlice.actions;
export const activeFileDownloadTasksReducer = activeFileDownloadTasksSlice.reducer;
