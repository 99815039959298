import { FunctionComponent } from 'react';
import { ImagesFormController } from 'features/images';
import { LayoutEditFormFields } from 'features/layouts';
import { FormCol, FormRow } from 'ui-library/components/Form';
import styles from './LayoutEditFormImagesTab.module.scss';

const imageField: keyof LayoutEditFormFields = 'images';

export const LayoutEditFormImagesTab: FunctionComponent = () => {
  return (
    <div className={styles.root}>
      <FormRow>
        <FormCol>
          <ImagesFormController name={imageField} />
        </FormCol>
      </FormRow>
    </div>
  );
};
