import cn from 'classnames';
import { FunctionComponent } from 'react';
import styles from './Table.module.scss';
import { TableProps } from './TableProps';

export const Table: FunctionComponent<TableProps> = (props) => {
  const { children, className } = props;

  return <table className={cn(styles.root, className)}>{children}</table>;
};
