import cn from 'classnames';
import { capitalize, isNil } from 'lodash';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { FileDownloadStatus, FileDownloadViewRequestTypesTask, useHumanReadableFileSize } from 'features/file';
import { imagesApi } from 'features/images';
import { dateService } from 'infrastructure/date';
import { useAppDispatch } from 'infrastructure/redux';
import { Button, ButtonKind } from 'ui-library/components/Button';
import { Icon } from 'ui-library/components/Icon';
import { IconButton, IconButtonVariant } from 'ui-library/components/IconButton';
import { ListItem, ListItemKind } from 'ui-library/components/List';
import { ListItemIcon } from 'ui-library/components/List/ItemIcon/ListItemIcon';
import { ListItemText } from 'ui-library/components/List/ItemText/ListItemText';
import { Spinner } from 'ui-library/components/Spinner';
import styles from './FileTaskListItem.module.scss';
import { FileTaskListItemProps } from './FileTaskListItemProps';

const ERROR_STATUSES = [FileDownloadStatus.FAILED, FileDownloadStatus.CANCELLED];

export const FileTaskListItem: FunctionComponent<FileTaskListItemProps> = (props) => {
  const { task, onCancel, onDownload, onFailed } = props;
  const { convertToHumanReadable } = useHumanReadableFileSize();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const isFolderTaskType = task.name === FileDownloadViewRequestTypesTask.FILE_DOWNLOAD;
  const isMetadataTaskType = task.name === FileDownloadViewRequestTypesTask.IMAGE_METADATA;
  const isBulkTaskType = task.name === FileDownloadViewRequestTypesTask.IMAGE_BULK;

  const getIcon = () => {
    if (task.status === FileDownloadStatus.WORKING) {
      return <Spinner className={styles.working} size='small' />;
    } else if (task.status === FileDownloadStatus.DONE) {
      return <Icon className={styles.done} name='doneThin' />;
    } else if (ERROR_STATUSES.includes(task.status)) {
      return <Icon className={styles.failed} name='error' />;
    }

    return null;
  };

  const getSecondaryText = () => {
    if (task.status === FileDownloadStatus.WORKING) {
      if (isFolderTaskType) {
        return t('files.downloads.waiting');
      } else if (isBulkTaskType) {
        return t('files.downloads.waitingExcel');
      } else {
        return t('files.downloads.waitingMetadata');
      }
    } else if (task.status === FileDownloadStatus.DONE) {
      let remainingTime = task.validThrough
        ? t('files.downloads.remainingTime', {
            time: dateService.formatDistanceToNow(task.validThrough),
          })
        : '';
      remainingTime = capitalize(remainingTime);
      const fileFormat = task.arg
        ? t('files.downloads.formatFiles', {
            format: task.arg?.format,
          })
        : '';

      if (fileFormat && remainingTime && isFolderTaskType) {
        return `${fileFormat}. ${remainingTime}`;
      } else if (isBulkTaskType) {
        return t('files.downloads.excelAttributed');
      } else if (isMetadataTaskType) {
        return t('files.downloads.downloadMetadata');
      }

      return fileFormat || remainingTime || '';
    } else if (task.status === FileDownloadStatus.FAILED) {
      if (isFolderTaskType) {
        return t('files.downloads.failed');
      } else if (isBulkTaskType) {
        return t('files.downloads.excelFailedAttribution');
      } else {
        return t('files.downloads.failedMetadata');
      }
    } else if (task.status === FileDownloadStatus.CANCELLED) {
      if (isFolderTaskType) {
        return t('files.downloads.cancelled');
      } else if (isBulkTaskType) {
        return t('files.downloads.excelCancelled');
      } else {
        return t('files.downloads.metadataCancelled');
      }
    }

    return null;
  };

  const getLoadingTaskInfoText = () => {
    if (isFolderTaskType) {
      return t('files.downloads.formingArchive');
    } else if (isMetadataTaskType) {
      return t('files.downloads.formingMetadataFile');
    } else return '';
  };

  const getActions = () => {
    if (task.status === FileDownloadStatus.WORKING) {
      return (
        <>
          <span className={styles.actionCaption}>{getLoadingTaskInfoText()}</span>
          <IconButton
            as={ButtonKind.BUTTON}
            className={styles.iconButton}
            variant={IconButtonVariant.TRANSPARENT}
            size='small'
            onClick={onCancel}
          >
            <Icon name='cross' />
          </IconButton>
        </>
      );
    } else if (task.status === FileDownloadStatus.DONE) {
      if (isBulkTaskType) {
        dispatch(
          imagesApi.util.invalidateTags([{ type: 'ImageGallery' }, { type: 'Image' }, { type: 'FolderImages' }]),
        );
        return (
          <>
            <span className={cn(styles.validDate)}>{dateService.format(task.createTime)}</span>
          </>
        );
      } else {
        return (
          <>
            {!isNil(task.size) && <span className={cn(styles.actionCaption)}>{convertToHumanReadable(task.size)}</span>}
            <Button as={ButtonKind.BUTTON} className={styles.button} size='small' onClick={onDownload}>
              {t('actions.download')}
            </Button>
          </>
        );
      }
    } else if (task.status === FileDownloadStatus.FAILED) {
      if (isBulkTaskType) {
        return (
          <>
            {!isNil(task.size) && <span className={cn(styles.actionCaption)}>{convertToHumanReadable(task.size)}</span>}
            <Button as={ButtonKind.BUTTON} className={styles.button} size='small' onClick={onDownload}>
              {t('files.downloads.excelDownloadReport')}
            </Button>
          </>
        );
      } else {
        return (
          <IconButton as={ButtonKind.BUTTON} onClick={onFailed} variant={IconButtonVariant.TRANSPARENT} size='small'>
            <Icon className={styles.failed} name='refresh' />
          </IconButton>
        );
      }
    }

    return null;
  };

  return (
    <ListItem className={styles.root} kind={ListItemKind.LI} disableGutters>
      <ListItemIcon iconPosition='start'>{getIcon()}</ListItemIcon>
      <ListItemText
        className={styles.text}
        classes={{
          primary: styles.primary,
          secondary: cn(styles.secondary, {
            [styles.failed]: ERROR_STATUSES.includes(task.status),
          }),
        }}
        primary={task.label}
        secondary={getSecondaryText()}
      />
      <div className={styles.actions}>{getActions()}</div>
    </ListItem>
  );
};
