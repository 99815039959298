import { skipToken } from '@reduxjs/toolkit/dist/query';
import { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { Breadcrumbs } from 'features/breadcrumbs';
import { changeOrderActions } from 'features/changeOrder';
import {
  FolderDetailHeaderInfo,
  FolderDetailRouteQueryParams,
  FolderPartition,
  useFetchFolderDetailQuery,
} from 'features/folders';
import { NotDefinedParamException } from 'infrastructure/exceptions';
import { FolderArchiveDetailDataView } from './components/FolderArchiveDetailDataView/FolderArchiveDetailDataView';
import { FolderMuseumDetailDataView } from './components/FolderMuseumDetailDataView/FolderMuseumDetailDataView';
import styles from './FolderDetailScreen.module.scss';

export const FolderDetailScreen: FunctionComponent = () => {
  const { folderId, partition } = useParams<FolderDetailRouteQueryParams>();
  const { data } = useFetchFolderDetailQuery(folderId ?? skipToken);
  const dispatch = useDispatch();
  const location = useLocation();

  if (!folderId) {
    throw new NotDefinedParamException('Folder');
  }

  useEffect(() => {
    dispatch(changeOrderActions.setShowOrderMode(false));
    dispatch(changeOrderActions.dispose());
  }, [location.pathname]);

  return (
    <div className={styles.root}>
      <Breadcrumbs className={styles.breadcrumbs} />
      <FolderDetailHeaderInfo className={styles.info} folder={data} partition={partition} />
      {partition === FolderPartition.ARCHIVE && (
        <FolderArchiveDetailDataView folderId={folderId} childrenCount={data?.childrenCount} folder={data} />
      )}
      {partition === FolderPartition.MUSEUM && (
        <FolderMuseumDetailDataView folderId={folderId} childrenCount={data?.childrenCount} folder={data} />
      )}
    </div>
  );
};
