import { FunctionComponent } from 'react';
import { CardVariant } from 'ui-library/components/Card';
import { ImageCardRoot } from '../ImageCardRoot/ImageCardRoot';
import styles from './ImageCardSkeleton.module.scss';

export const ImageCardSkeleton: FunctionComponent = () => {
  return (
    <ImageCardRoot className={styles.card} variant={CardVariant.TRANSPARENT}>
      <></>
    </ImageCardRoot>
  );
};
