import cn from 'classnames';
import { FunctionComponent, HTMLAttributes } from 'react';
import { Link, LinkProps } from '../Link';
import styles from './ListItem.module.scss';
import { ListItemKind, ListItemProps } from './ListItemProps';

export const ListItem: FunctionComponent<ListItemProps> = (props) => {
  const { className, kind, disableGutters, padding = 'normal', children, divider, selected, classes, ...rest } = props;

  const finalClassName = cn(styles.listItem, styles[padding], className, {
    [styles.disableGutters]: disableGutters,
    [styles.selected]: selected,
    [classes?.selected ?? '']: selected,
  });
  const content = (
    <>
      {children}
      {divider && <hr className={classes?.divider} />}
    </>
  );

  switch (kind) {
    case ListItemKind.LI: {
      return (
        <li {...(rest as HTMLAttributes<HTMLLIElement>)} className={finalClassName}>
          {content}
        </li>
      );
    }
    case ListItemKind.LINK: {
      return (
        <Link {...(rest as LinkProps)} className={finalClassName}>
          {content}
        </Link>
      );
    }
    default:
      return null;
  }
};
