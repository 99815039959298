import { nanoid } from 'nanoid';
import { FunctionComponent, useLayoutEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FolderPartition } from 'features/folders';
import { imageApproverService } from 'features/images/services/imageApproverService';
import { imageGalleryActions } from 'features/images/slices';
import { linkingArtifactActions } from 'features/linking';
import { movingArtifactsActions } from 'features/movingArtifacts';
import { imageAccessRuleService } from 'features/role';
import { NotDefinedParamException } from 'infrastructure/exceptions';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux';
import { Button, ButtonKind, ButtonVariant } from 'ui-library/components/Button';
import { Checkbox } from 'ui-library/components/Checkbox';
import { Label } from 'ui-library/components/Label';
import styles from './ImageGalleryHeaderActions.module.scss';
import { ImageGalleryHeaderActionsProps } from './ImageGalleryHeaderActionsProps';

export const ImageGalleryHeaderActions: FunctionComponent<ImageGalleryHeaderActionsProps> = (props) => {
  const dispatch = useAppDispatch();
  const selected = useAppSelector((state) => state.imageGallery.selected);
  const labelId = useRef(nanoid()).current;
  const { t } = useTranslation();

  const handleMove = () => {
    if (selected.length === 0) {
      throw new NotDefinedParamException('images');
    }

    const toMove = selected.map((x) => ({ entityId: x.id, parentID: x.parentId }));
    dispatch(movingArtifactsActions.setMovingArtifacts(toMove));
    dispatch(movingArtifactsActions.setMovingType('image'));
    dispatch(movingArtifactsActions.selectFolderId(selected[0].parentId));
    dispatch(movingArtifactsActions.selectPartition(FolderPartition.MUSEUM));
    dispatch(movingArtifactsActions.open());
  };

  const handleLink = () => {
    if (selected.length === 0) {
      throw new NotDefinedParamException('images');
    }

    const toLink = selected.map((x) => ({ entityId: x.id, parentID: x.parentId }));
    dispatch(linkingArtifactActions.setLinkingArtifacts(toLink));
    dispatch(linkingArtifactActions.setLinkingType('image'));
    dispatch(linkingArtifactActions.selectFolderId(selected[0].parentId));
    dispatch(linkingArtifactActions.open());
  };

  const handleChange = () => {
    dispatch(imageGalleryActions.clearSelection());
  };

  useLayoutEffect(() => {
    dispatch(imageGalleryActions.clearSelection());
  }, [dispatch]);

  if (!imageApproverService.canSelectImage() || selected.length === 0) {
    return null;
  }

  return (
    <div className={styles.wrap}>
      <div className={styles.root}>
        <Checkbox id={labelId} defaultChecked={selected.length > 0} onChange={handleChange} />
        <Label
          htmlFor={labelId}
          className={styles.label}
          label={t('images.gallery.actions.selected', { count: selected.length })}
        />

        {imageAccessRuleService.canMove() && (
          <Button size={'small'} variant={ButtonVariant.SECONDARY} as={ButtonKind.BUTTON} onClick={handleMove}>
            {t('actions.move')}
          </Button>
        )}
        {imageAccessRuleService.canLink() && (
          <Button size={'small'} variant={ButtonVariant.SECONDARY} as={ButtonKind.BUTTON} onClick={handleLink}>
            {t('actions.link')}
          </Button>
        )}
      </div>
    </div>
  );
};
