import { FunctionComponent, useEffect, useMemo } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { FolderArtifactListFooter } from 'features/fileNavigator/folders';
import {
  FetchChildrenFolderQueryData,
  FolderListItem,
  FolderLoadingError,
  FolderResponseDto,
  foldersApi,
  useFetchFolderChildrenQuery,
  useLazyFetchFolderDetailQuery,
  useLazyFetchFolderPathQuery,
} from 'features/folders';
import { movingArtifactsActions } from 'features/movingArtifacts';
import { useInfiniteLoading } from 'features/pagination';
import { SortDirection } from 'features/sorting';
import { useLanguageRelevantData } from 'infrastructure/i18n';
import { ListApiResponse } from 'infrastructure/network';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux';
import { List } from 'ui-library/components/List';
import { MovingArtifactEmptyFolder } from '../MovingArtifactEmptyFolder/MovingArtifactEmptyFolder';
import styles from './MovingArtifactFolderList.module.scss';
import { MovingArtifactFolderListProps } from './MovingArtifactFolderListProps';

export const MovingArtifactFolderList: FunctionComponent<MovingArtifactFolderListProps> = (props) => {
  const { folderId, partition } = props;
  const movingArtifacts = useAppSelector((state) => state.movingArtifacts.movingArtifacts);
  const { matchProp } = useLanguageRelevantData();
  const dispatch = useAppDispatch();
  const [triggerFolderPath] = useLazyFetchFolderPathQuery();
  const [triggerFolderDetail] = useLazyFetchFolderDetailQuery();
  const { isError, refresh, readMore, isFetching, data } = useInfiniteLoading<
    FolderResponseDto,
    ListApiResponse<FolderResponseDto>,
    FetchChildrenFolderQueryData
  >(
    useFetchFolderChildrenQuery,
    (state) => ({
      ...state,
      folderId,
      sortFields: [matchProp('captionRu', 'captionEn')],
      sortDirection: SortDirection.ASC,
      partition,
    }),
    (result) => result,
    { pageSize: 30 },
  );

  const handleClick = (folder: FolderResponseDto) => {
    dispatch(movingArtifactsActions.selectFolderId(folder.id));
  };

  useEffect(() => {
    refresh();
  }, [folderId]);

  const handleRefresh = () => {
    refresh();
    dispatch(foldersApi.util.invalidateTags([{ type: 'LayoutFolders', id: folderId }]));
    triggerFolderPath(folderId);
    triggerFolderDetail(folderId);
  };

  const fetchData = useMemo(
    () => data?.filter((x) => movingArtifacts.some((artifact) => artifact.entityId !== x.id)) ?? [],
    [data, movingArtifacts],
  );
  const isEmpty = !isFetching && !isError && fetchData.length === 0;

  return (
    <div className={styles.root}>
      {isError ? (
        <FolderLoadingError onReload={handleRefresh} />
      ) : (
        <Virtuoso
          className={styles.list}
          data={fetchData}
          overscan={200}
          endReached={readMore}
          itemContent={(_, folder) => <FolderListItem onClick={() => handleClick(folder)} folder={folder} />}
          components={{
            List: List,
            EmptyPlaceholder: isEmpty ? MovingArtifactEmptyFolder : undefined,
            Footer: isFetching ? () => <FolderArtifactListFooter /> : undefined,
          }}
        />
      )}
    </div>
  );
};
