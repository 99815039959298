import { FolderPartition, FolderResponseDto, MaterialsKind } from 'features/folders';
import { ImageResponseDto } from 'features/images';
import { LayoutResponseDto } from 'features/layouts';
import { AvailableLanguages } from 'infrastructure/i18n/i18n';
import { ListApiPaginationData, ListApiResponse } from 'infrastructure/network';

export type SearchResponseDto = {
  items: Array<SearchResultItemDto>;
};

export type SearchResultItemDto = {
  topic?: ListApiResponse<FolderResponseDto>;
  folder?: ListApiResponse<FolderResponseDto>;
  layout?: ListApiResponse<LayoutResponseDto>;
  image?: ListApiResponse<ImageResponseDto>;
};

export type SearchAIRequestDto = ListApiPaginationData & {
  query: string;
};

export type SearchAIResponseDto = ListApiResponse<ImageResponseDto>;

export enum SearchEntityStatus {
  DRAFT = 'draft',
  DONE = 'done',
}

export type SearchRequestDto = {
  items: Array<SearchRequestItemDto>;
  language: AvailableLanguages;
  query: string;
  partition: FolderPartition;
  status?: SearchEntityStatus;
  isArchived?: boolean;
  authorID?: string;
  centuryID?: string;
  yearID?: string;
  artFormID?: string;
  centuryHalf?: number;
  materialsTechnicsID?: string;
  tags?: Array<string>;
  materialsKinds?: Array<MaterialsKind>;
};

export type MainSearchFilterFields = {
  status: SearchRequestDto['status'];
  materialsKinds: SearchRequestDto['materialsKinds'];
};

export type ImageSearchFilterFields = {
  authorID: SearchRequestDto['authorID'];
  centuryID: SearchRequestDto['centuryID'];
  yearID: SearchRequestDto['yearID'];
  centuryHalf: SearchRequestDto['centuryHalf'];
  artFormID: SearchRequestDto['artFormID'];
  materialsTechnicsID: SearchRequestDto['materialsTechnicsID'];
};

export type SearchType = 'topic' | 'folder' | 'layout' | 'image' | 'foldersAll';

export type SearchRequestItemDto = ListApiPaginationData & {
  type: SearchType;
};
