import { FunctionComponent } from 'react';
import { favoriteRootFolderListActions } from 'features/favorites/slices';
import { ListLayout, ListLayoutButtonGroup } from 'features/list';
import { SortButton } from 'features/sorting';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux';
import styles from './RootFolderListHeaderActions.module.scss';

export const RootFolderListHeaderActions: FunctionComponent = () => {
  const { sortDirection } = useAppSelector((state) => state.favoriteRootFolderList.sortingInfo);
  const layoutType = useAppSelector((state) => state.favoriteRootFolderList.layoutType);
  const dispatch = useAppDispatch();

  const toggleSort = () => {
    dispatch(
      favoriteRootFolderListActions.sortChanged({
        sortField: 'favouritesCreateTime',
      }),
    );
  };

  const handleChangeLayout = (layout: ListLayout) => {
    dispatch(favoriteRootFolderListActions.changeLayoutType(layout));
  };

  return (
    <div className={styles.root}>
      <ListLayoutButtonGroup active={layoutType} onLayoutChanged={handleChangeLayout} />

      <SortButton className={styles.active} direction={sortDirection} toggleSort={toggleSort} />
    </div>
  );
};
