import { FunctionComponent, useEffect } from 'react';
import { searchListActions } from 'features/search';
import { useAppDispatch } from 'infrastructure/redux';
import { SearchScreenContainer } from './components/v1/SearchScreenContainer/SearchScreenContainer';

export const SearchScreen: FunctionComponent = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(searchListActions.reset());
    };
  }, [dispatch]);

  return (
    <>
      <SearchScreenContainer />
    </>
  );
};
