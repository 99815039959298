import { TagsChip } from '../TagsChip';
import styles from './TagsList.module.scss';
import { TagsListProps } from './TagsListProps';

export const TagsList = (props: TagsListProps) => {
  const { tags, disableActions } = props;

  return (
    <div className={styles.root}>
      {tags.map((tag) => (
        <TagsChip key={tag.id} tag={tag} disableActions={disableActions} />
      ))}
    </div>
  );
};
