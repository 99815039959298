import { FunctionComponent } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { store } from '../../redux';
import { IAppProvidersProps } from './appProvidersProps';

export const AppProviders: FunctionComponent<IAppProvidersProps> = (props) => {
  return (
    <Provider store={store}>
      <BrowserRouter>{props.children}</BrowserRouter>
    </Provider>
  );
};
