import React, { ComponentPropsWithoutRef, ReactNode, ComponentPropsWithRef, HTMLAttributes } from 'react';

export enum InputLayoutVariant {
  FILLED = 'filled',
  STANDARD = 'standard',
}

export type InputLayoutControlProps = ComponentPropsWithRef<'input'>;

export type InputLayoutProps = {
  renderControl: (props: InputLayoutControlProps) => React.ReactNode;
  variant?: InputLayoutVariant;
  error?: boolean;
  startAdornment?: ReactNode;
  startAdornmentProps?: HTMLAttributes<HTMLDivElement>;
  endAdornment?: ReactNode;
  endAdornmentProps?: HTMLAttributes<HTMLDivElement>;
  disabled?: boolean;
  InputProps?: ComponentPropsWithRef<'div'>;
  ControlProps?: InputLayoutControlProps;
} & ComponentPropsWithoutRef<'div'>;
