import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate } from 'react-router-dom';
import { searchApi, useDebounce, useSearchQueryParams } from 'features/search';
import { useAppDispatch } from 'infrastructure/redux';
import { getSearchRoute, ROUTE_PATHS } from 'infrastructure/routes/paths';
import { ButtonKind } from 'ui-library/components/Button';
import { Icon } from 'ui-library/components/Icon';
import { IconButton, IconButtonVariant } from 'ui-library/components/IconButton';
import { Input, InputVariant } from 'ui-library/components/Input';
import styles from './SearchBar.module.scss';
import { SearchBarProps } from './SearchBarProps';

const skip = Symbol('skip');
const minLenToSearch = 1;

/**
 * @deprecated
 */

export const SearchBar: FunctionComponent<SearchBarProps> = (props) => {
  const { InputProps } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { tagIds, query: defaultQuery, filterData, tab, partition } = useSearchQueryParams();
  const [query, setQuery] = useState(defaultQuery || skip);
  const debouncedQuery = useDebounce(query);
  const searchRouteMatch = useMatch(ROUTE_PATHS.SEARCH);
  const isSearchRoute = Boolean(searchRouteMatch);

  useEffect(() => {
    if (debouncedQuery !== skip && (debouncedQuery.length >= minLenToSearch || debouncedQuery.length === 0)) {
      navigate(
        getSearchRoute({
          partition,
          ...filterData,
          tab,
          tags: tagIds,
          query: debouncedQuery,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedQuery, navigate]);

  const onClickSearch = () => {
    if (isSearchRoute) {
      dispatch(searchApi.util.invalidateTags(['SearchResult']));
    } else {
      navigate(getSearchRoute({}));
    }
  };

  return (
    <Input
      {...InputProps}
      LayoutProps={{
        className: styles.searchBar,
        variant: InputVariant.STANDARD,
        endAdornment: (
          <IconButton as={ButtonKind.BUTTON} variant={IconButtonVariant.TRANSPARENT} onClick={onClickSearch}>
            <Icon name='search' />
          </IconButton>
        ),
      }}
      value={query === skip ? '' : query}
      onChange={(event) => setQuery(event.target.value)}
      placeholder={t('header.search')}
    />
  );
};
