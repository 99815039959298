import cn from 'classnames';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReferenceBook } from 'features/referenceBook/referenceBookEntity';
import { useCurrentLanguage } from 'infrastructure/i18n';
import { TextCellRenderer } from 'ui-library/components/Table';
import { TableColumn } from 'ui-library/components/Table/TableColumn';
import { nameof } from 'utils';
import styles from './useAuthorTableColumns.module.scss';

export const useAuthorTableColumns = () => {
  const { t } = useTranslation();
  const currentLang = useCurrentLanguage();

  const columns = useMemo<Array<TableColumn<ReferenceBook>>>(
    () => [
      {
        fieldName: nameof<ReferenceBook>(currentLang === 'ru' ? 'captionRu' : 'captionEn'),
        width: '45%',
        cellRenderer: (data) => (
          <TextCellRenderer
            // textClassName={cn({
            //   [styles.textUserBlocked]: data.isBlocked,
            // })}
            value={currentLang === 'ru' ? data.captionRu : data.captionEn}
          />
        ),
      },
      {
        width: '45%',
        fieldName: nameof<ReferenceBook>(currentLang === 'ru' ? 'captionEn' : 'captionRu'),
        cellRenderer: (data) => (
          <TextCellRenderer
            textClassName={cn(styles.textAuthorSecond)}
            value={currentLang === 'ru' ? data.captionEn : data.captionRu}
          />
        ),
      },
    ],
    [t],
  );

  return columns;
};
