import React, { FunctionComponent } from 'react';
import { Popover, PopoverContent, PopoverTrigger } from '../Popover';
import styles from './Tooltip.module.scss';
import { TooltipProps } from './TooltipProps';

export const Tooltip: FunctionComponent<TooltipProps> = (props) => {
  const { children, className, message, ...restProps } = props;

  return (
    <Popover behavior='click' {...restProps}>
      <PopoverTrigger>
        <span>{children}</span>
      </PopoverTrigger>
      <PopoverContent className={className}>{() => <div className={styles.tooltip}>{message}</div>}</PopoverContent>
    </Popover>
  );
};
