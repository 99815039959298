import cn from 'classnames';
import { FunctionComponent } from 'react';
import { useTranslateEnum } from 'infrastructure/i18n';
import styles from './LayoutView.module.scss';
import { LayoutViewHeader } from './LayoutViewHeader/LayoutViewHeader';
import { LayoutViewImages } from './LayoutViewImages/LayoutViewImages';
import { LayoutViewInfo } from './LayoutViewInfo/LayoutViewInfo';
import { LayoutViewProps } from './LayoutViewProps';
import { LayoutViewTab } from './LayoutViewTab';

export const LayoutView: FunctionComponent<LayoutViewProps> = (props) => {
  const { layout, className } = props;
  const { translateValue } = useTranslateEnum('LayoutViewTab', LayoutViewTab);

  return (
    <div className={cn(styles.root, className)}>
      <LayoutViewHeader layout={layout} />
      <div className={styles.title}>{translateValue(LayoutViewTab.INFORMATION)}</div>
      <LayoutViewInfo layout={layout} />
      <div className={styles.imageTitle}>{translateValue(LayoutViewTab.IMAGES)}</div>
      <LayoutViewImages layout={layout} />
    </div>
  );
};
