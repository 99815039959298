import { FunctionComponent } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { FolderArtifactListFooter } from 'features/fileNavigator/folders';
import {
  FetchRootFoldersQueryData,
  FolderListItem,
  FolderResponseDto,
  useFetchRootFoldersQuery,
} from 'features/folders';
import { linkingArtifactActions } from 'features/linking';
import { useInfiniteLoading } from 'features/pagination';
import { ErrorLoadingRoot } from 'features/rootFolders';
import { SortDirection } from 'features/sorting';
import { useLanguageRelevantData } from 'infrastructure/i18n';
import { ListApiResponse } from 'infrastructure/network';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux';
import { List } from 'ui-library/components/List';
import styles from './LinkingArtifactRootFolderDefaultList.module.scss';

export const LinkingArtifactRootFolderDefaultList: FunctionComponent = () => {
  const { matchProp } = useLanguageRelevantData();
  const dispatch = useAppDispatch();
  const partition = useAppSelector((state) => state.toggleMuseum.partition);
  const { isError, refresh, readMore, data, isFetching } = useInfiniteLoading<
    FolderResponseDto,
    ListApiResponse<FolderResponseDto>,
    FetchRootFoldersQueryData
  >(
    useFetchRootFoldersQuery,
    (state) => ({
      ...state,
      sortFields: [matchProp('captionRu', 'captionEn')],
      sortDirection: SortDirection.ASC,
      partition,
    }),
    (result) => result,
    {
      pageSize: 30,
    },
  );

  const handleClick = (folder: FolderResponseDto) => {
    dispatch(linkingArtifactActions.selectFolderId(folder.id));
  };

  return (
    <div className={styles.root}>
      {isError ? (
        <ErrorLoadingRoot className={styles.placeholder} onReload={refresh} />
      ) : (
        <Virtuoso
          className={styles.list}
          data={data}
          overscan={200}
          endReached={readMore}
          itemContent={(_, folder) => <FolderListItem onClick={() => handleClick(folder)} folder={folder} />}
          components={{
            List: List,
            Footer: isFetching ? () => <FolderArtifactListFooter /> : undefined,
          }}
        />
      )}
    </div>
  );
};
