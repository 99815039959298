import { FunctionComponent } from 'react';
import { useFolderActions } from 'features/folders/hooks/useFolderActions';
import { TableRowActions } from 'ui-library/components/Table';
import { FolderTableActionsProps } from './FolderTableActionsProps';

export const FolderTableActions: FunctionComponent<FolderTableActionsProps> = (props) => {
  const { folder, className } = props;
  const { actions } = useFolderActions(folder);

  return <TableRowActions actions={actions} className={className} />;
};
