import * as yup from 'yup';
import { SchemaOf } from 'yup';
import { REGULAR_EXPRESSIONS } from 'utils';
import { ReferenceBookEditFormFields } from './types';

const referenceValidationMaskRu = REGULAR_EXPRESSIONS.CYRILLIC;
const referenceValidationMaskEn = REGULAR_EXPRESSIONS.LATIN;
export const ReferenceBookEditDtoValidationSchema = (): SchemaOf<ReferenceBookEditFormFields> =>
  yup.object({
    captionEn: yup.string().matches(referenceValidationMaskEn).required(),
    captionRu: yup.string().matches(referenceValidationMaskRu).required(),
  });
