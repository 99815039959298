import React, { FunctionComponent, useEffect, useState } from 'react';
import Placeholder from './assets/placeholder.svg';
import { ImageProps } from './ImageProps';

const FALLBACK_IMAGE = Placeholder;

// TODO add placeholder on load
/**
 * @deprecated
 */

export const Image: FunctionComponent<ImageProps> = (props) => {
  const { src, fallbackSrc = FALLBACK_IMAGE, ...rest } = props;
  const [imgSrc, setImgSrc] = useState(src);

  const handleError = () => {
    setImgSrc(fallbackSrc);
  };

  useEffect(() => {
    setImgSrc(src ?? fallbackSrc);
  }, [src, fallbackSrc]);

  return <img onError={handleError} loading='lazy' {...rest} src={imgSrc} />;
};
