import cn from 'classnames';
import React, { FunctionComponent } from 'react';
import styles from './FormCol.module.scss';
import { FormColProps } from './FormColProps';

export const FormCol: FunctionComponent<FormColProps> = (props) => {
  const { className, ...rest } = props;

  return <div {...rest} className={cn(styles.formCol, className)} />;
};
