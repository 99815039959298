import { FunctionComponent } from 'react';
import { createSearchParams, useLocation, useSearchParams } from 'react-router-dom';
import { changeOrderActions } from 'features/changeOrder';
import { FolderDetailQuerySearchParams } from 'features/folders';
import { useImageGalleryFlatListItems } from 'features/images/hooks';
import { GalleryImageItemDto } from 'features/images/imageEntity';
import { useFetchGalleryQuery } from 'features/images/imagesApi';
import { imageApproverService } from 'features/images/services/imageApproverService';
import { imageGalleryActions } from 'features/images/slices';
import { FIRST_PAGE } from 'features/pagination';
import { ImageGallery } from 'features/v1/image/ImageGallery/ImageGallery';
import { NotDefinedParamException } from 'infrastructure/exceptions';
import { useCurrentLanguage } from 'infrastructure/i18n';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux';
import { nameof } from 'utils';
import { ImageLoadingError } from '../ImageLoadingError/ImageLoadingError';
import styles from './ImageGalleryDataView.module.scss';
import { ImageGalleryDataViewProps } from './ImageGalleryDataViewProps';

export const ImageGalleryDataView: FunctionComponent<ImageGalleryDataViewProps> = (props) => {
  const { folderId, initialScrollOffsetY, onSaveScrollOffsetY } = props;
  const dispatch = useAppDispatch();
  const selected = useAppSelector((state) => state.imageGallery.selected);
  const isShowOrderMode = useAppSelector((state) => state.changeOrder.isShowOrderMode);
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get(nameof<FolderDetailQuerySearchParams>('tab'));
  const page = searchParams.get(nameof<FolderDetailQuerySearchParams>('page'));
  const params: FolderDetailQuerySearchParams = {
    tab: tab ?? '',
    page: page ?? String(FIRST_PAGE),
  };

  if (!folderId) {
    throw new NotDefinedParamException('Folder');
  }

  const currentLang = useCurrentLanguage();
  const { data, isError, isLoading, refetch } = useFetchGalleryQuery({
    folderID: folderId,
    language: currentLang,
    currentPage: 1,
    pageSize: Number.MAX_SAFE_INTEGER,
  });

  const onSelect = (checked: boolean, image: GalleryImageItemDto, parentId: string) => {
    if (checked) {
      dispatch(imageGalleryActions.selectImage({ id: image.id, parentId }));
      dispatch(imageGalleryActions.setShowSelectMode(true));
    } else {
      dispatch(imageGalleryActions.unselectImage(image.id));
    }
  };

  const onClickToChangeOrder = (image: GalleryImageItemDto, parentId: string) => {
    dispatch(changeOrderActions.setChangeArtifact({ imageID: image.id, order: image.order, parentId, type: 'image' }));
    dispatch(changeOrderActions.open());
  };

  const flatList = useImageGalleryFlatListItems(data);

  const getParams = (folderId: string) => {
    return {
      redirectUrl: location.pathname + `?${createSearchParams(params)}`,
      folderId: folderId,
    };
  };

  return (
    <div className={styles.root}>
      {isError ? (
        <ImageLoadingError onReload={refetch} />
      ) : (
        <ImageGallery
          getParams={getParams}
          isLoading={isLoading}
          data={flatList}
          onSelectImage={onSelect}
          isImageSelected={(image, parentId) => selected.some((x) => x.id === image.id && x.parentId === parentId)}
          canSelectImage={(image, parentId) =>
            imageApproverService.canSelectImage() &&
            (selected.length === 0 || selected[selected.length - 1].parentId === parentId)
          }
          isShowOrderMode={isShowOrderMode}
          onClickToChangeOrder={onClickToChangeOrder}
          isSelectionMode={selected.length > 0}
          initialScrollOffsetY={initialScrollOffsetY}
          onSaveScrollOffsetY={onSaveScrollOffsetY}
        />
      )}
    </div>
  );
};
