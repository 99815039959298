import { FunctionComponent, useMemo, useState } from 'react';
import { DEFAULT_PAGE_SIZE, FIRST_PAGE } from 'features/pagination';
import { useSearchReferenceBookQuery } from 'features/referenceBook/referenceBookApi';
import { ReferenceBookType } from 'features/referenceBook/referenceBookEntity';
import { useDebounce } from 'features/search';
import { SortDirection } from 'features/sorting';
import { useCurrentLanguage, useLanguageRelevantData } from 'infrastructure/i18n';
import { useAppDispatch } from 'infrastructure/redux';
import { authorListQueryActions } from '../../AuthorsReferenceBook/slices';
import { SearchReferenceAutocomplete } from '../../SearchReferenceAutocomplete/SearchReferenceAutocomplete';
import { AutocompleteOptionType } from '../../SearchReferenceAutocomplete/SearchReferenceAutocompleteTypes';
import styles from './AuthorsSearchBar.module.scss';
import { AuthorsSearchBarProps } from './AuthorsSearchBarProps';

export const AuthorsSearchBar: FunctionComponent<AuthorsSearchBarProps> = () => {
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce(query);
  const language = useCurrentLanguage();
  const { matchProp } = useLanguageRelevantData();
  const dispatch = useAppDispatch();

  const { data, isFetching } = useSearchReferenceBookQuery({
    query: debouncedQuery,
    type: ReferenceBookType.AUTHORS,
    language: language,
    sortDirection: SortDirection.ASC,
    sortFields: [matchProp('captionRu', 'captionEn')],
    pageSize: DEFAULT_PAGE_SIZE,
    currentPage: FIRST_PAGE,
  });

  const filteredOptions: Array<AutocompleteOptionType> = useMemo(() => {
    const authorsOptions =
      data?.content.map((item) => {
        let label = '';
        if (language === 'en') {
          label = item.captionEn;
        }
        if (language === 'ru') {
          label = item.captionRu;
        }
        return { label, artifact: ReferenceBookType.AUTHORS };
      }) ?? [];

    return authorsOptions;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onSearch = () => {
    if (!data) {
      return;
    }
    dispatch(authorListQueryActions.authorsData(debouncedQuery));
    dispatch(authorListQueryActions.dispose());
  };

  const setSearchQuery = (value: string) => {
    setQuery(value);
  };

  return (
    <>
      <div className={styles.root}>
        <SearchReferenceAutocomplete
          setSearchQuery={setSearchQuery}
          query={query}
          filteredOptions={filteredOptions}
          onSearch={onSearch}
          isLoading={isFetching}
          handleChangeOpiton={() => {}}
        />
      </div>
    </>
  );
};
