import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getDefaultModalState, getModalReducers, ModalState } from 'features/modal';
import { FavoriteArtifactEntity } from '../favoriteEntity';

export type AddToFavoritesState = ModalState & {
  favoriteArtifact?: FavoriteArtifactEntity;
};

const initialState: AddToFavoritesState = {
  ...getDefaultModalState(),
  favoriteArtifact: undefined,
};

const addToFavoritesSlice = createSlice({
  name: 'addToFavorites',
  initialState,
  reducers: {
    ...getModalReducers<AddToFavoritesState>((state) => state),
    setFavoriteArtifact: (state, action: PayloadAction<FavoriteArtifactEntity | undefined>) => {
      state.favoriteArtifact = action.payload;
    },
    dispose: (state) => {
      state.isOpen = false;
      state.favoriteArtifact = undefined;
    },
  },
});

export const addToFavoritesActions = addToFavoritesSlice.actions;
export const addToFavoritesReducer = addToFavoritesSlice.reducer;
