import { createSlice } from '@reduxjs/toolkit';
import { getListSortReducers, ListState } from 'features/list';
import { getListPaginationReducers } from 'features/list/listReducers';
import { getDefaultPaginationState } from 'features/pagination';
import { SortDirection } from 'features/sorting';
import { FavoriteContentSortingParams } from '../favoriteEntity';

type EntityType = FavoriteContentSortingParams;
export type FavoriteLayoutListState = ListState<EntityType>;

const initialState: FavoriteLayoutListState = {
  sortingInfo: {
    sortField: 'favouritesCreateTime',
    sortDirection: SortDirection.DESC,
  },
  paginationData: getDefaultPaginationState(),
};

const favoriteLayoutListSlice = createSlice({
  name: 'favoriteLayoutList',
  initialState,
  reducers: {
    ...getListSortReducers<FavoriteLayoutListState, EntityType>(),
    ...getListPaginationReducers<FavoriteLayoutListState>(),
  },
});

export const favoriteLayoutListActions = favoriteLayoutListSlice.actions;
export const favoriteLayoutListReducer = favoriteLayoutListSlice.reducer;
