import { createSlice } from '@reduxjs/toolkit';
import { getListSortReducers, ListState } from 'features/list';
import { getListPaginationReducers } from 'features/list/listReducers';
import { getDefaultPaginationState } from 'features/pagination';
import { SortDirection } from 'features/sorting';
import { FavoriteGroupDto } from '../favoriteEntity';

type EntityType = FavoriteGroupDto;
export type FavoriteGroupListState = ListState<EntityType>;

const initialState: FavoriteGroupListState = {
  sortingInfo: {
    sortField: 'createTime',
    sortDirection: SortDirection.DESC,
  },
  paginationData: getDefaultPaginationState(),
};

const favoriteGroupListSlice = createSlice({
  name: 'favoriteGroupList',
  initialState,
  reducers: {
    ...getListSortReducers<FavoriteGroupListState, EntityType>(),
    ...getListPaginationReducers<FavoriteGroupListState>(),
  },
});

export const favoriteGroupListActions = favoriteGroupListSlice.actions;
export const favoriteGroupListReducer = favoriteGroupListSlice.reducer;
