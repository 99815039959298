import React, { forwardRef, HTMLInputTypeAttribute, SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonKind } from 'ui-library/components/Button';
import { Icon } from 'ui-library/components/Icon';
import { IconButton } from 'ui-library/components/IconButton';
import { IconButtonVariant } from 'ui-library/components/IconButton';
import { TextField } from '../TextField';
import { TextFieldProps } from '../TextFieldProps';

export const PasswordTextField = forwardRef<HTMLInputElement, TextFieldProps>((props, ref) => {
  const { t } = useTranslation();
  const { InputProps, ...rest } = props;
  const [inputType, setInputType] = useState<HTMLInputTypeAttribute>('password');
  const isPasswordVisible = inputType === 'password';

  const toggleInputType = (event: SyntheticEvent) => {
    event.stopPropagation();

    setInputType(isPasswordVisible ? 'text' : 'password');
  };

  return (
    <TextField
      {...rest}
      ref={ref}
      LayoutProps={{
        endAdornment: (
          <IconButton as={ButtonKind.BUTTON} variant={IconButtonVariant.TRANSPARENT} onClick={toggleInputType}>
            <Icon
              name={isPasswordVisible ? 'visibilityOn' : 'visibilityOff'}
              title={
                isPasswordVisible
                  ? t('uiLibrary.textField.showPasswordHint')
                  : t('uiLibrary.textField.hidePasswordHint')
              }
            />
          </IconButton>
        ),
      }}
      InputProps={{
        ...InputProps,
        type: inputType,
      }}
    />
  );
});
