import { FunctionComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FilePreviewLabel } from 'features/file';
import { useLanguageRelevantData } from 'infrastructure/i18n';
import { CardActionsMenu } from 'ui-library/components/CardActionsMenu';
import { Icon } from 'ui-library/components/Icon';
import { Image } from 'ui-library/components/Image';
import { LinkKind } from 'ui-library/components/Link';
import { ListItem, ListItemKind } from 'ui-library/components/List';
import styles from './ImageMasonryItemBase.module.scss';
import { ImageMasonryItemBaseProps } from './ImageMasonryItemBaseProps';

export const ImageMasonryItemBase: FunctionComponent<ImageMasonryItemBaseProps> = (props) => {
  const { image, imageLink, disableActions, actions } = props;
  const { t } = useTranslation();
  const { matchProp } = useLanguageRelevantData();

  const preview = useMemo(() => image.file?.previews?.find((x) => x.label === FilePreviewLabel.IMAGE_SEARCH), [image]);

  return (
    <ListItem
      className={styles.item}
      kind={ListItemKind.LINK}
      as={LinkKind.INNER}
      to={imageLink}
      disableGutters
      padding={'none'}
    >
      <div className={styles.imageContainer}>
        {!disableActions && (
          <div className={styles.actionsWrap}>
            <CardActionsMenu className={styles.actions} actions={actions} />
          </div>
        )}
        {image.isFavourite && (
          <div className={styles.favoriteWrap}>
            <Icon className={styles.favorite} name='favoriteFilled' />
          </div>
        )}
        <Image className={styles.image} src={preview?.url} alt={t('images.imageAlt')} />
        <div className={styles.overlay}></div>
      </div>
      <div className={styles.info}>
        <span className={styles.caption}>{matchProp(image.captionRu, image.captionEn)}</span>
        <span className={styles.author}>{matchProp(image.author?.captionRu, image.author?.captionEn)}</span>
      </div>
    </ListItem>
  );
};
