import * as yup from 'yup';
import { SchemaOf } from 'yup';
import { FavoriteGroupRequestBody } from './favoriteEntity';

const maxLenCaption = 100;
const maxLenDescription = 15_000;

export const FavoriteGroupDtoValidationSchema = (): SchemaOf<FavoriteGroupRequestBody> => {
  return yup.object().shape({
    captionRu: yup.string().required().max(maxLenCaption),
    captionEn: yup.string().required().max(maxLenCaption),
    descriptionRu: yup.string().optional().max(maxLenDescription),
    descriptionEn: yup.string().optional().max(maxLenDescription),
  });
};
