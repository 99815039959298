import { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { createFileDownloadTaskActions, shareFileDownloadTaskActions } from 'features/file';
import { fileAccessRuleService, folderAccessRuleService } from 'features/role';
import { useAppDispatch } from 'infrastructure/redux';
import { Icon } from 'ui-library/components/Icon';
import { ActionListItem, ActionListItemVariant } from 'ui-library/v1/components/ActionList';
import { FavoriteGroupDto } from '../favoriteEntity';
import { favoriteGroupEditActions, favoriteGroupRemoveActions } from '../slices';

export const useFavoriteGroupActions = (group: FavoriteGroupDto) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleEdit = (event: SyntheticEvent) => {
    event.stopPropagation();
    event.preventDefault();

    dispatch(favoriteGroupEditActions.selectGroup(group?.id));
    dispatch(favoriteGroupEditActions.open());
  };

  const handleDelete = (event: SyntheticEvent) => {
    event.stopPropagation();
    event.preventDefault();

    dispatch(favoriteGroupRemoveActions.selectGroupId(group?.id));
    dispatch(favoriteGroupRemoveActions.open());
  };

  const handleDownload = (event: SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    dispatch(
      createFileDownloadTaskActions.setFileArtifact({
        entityId: group.id,
        type: 'favourites_group',
      }),
    );
    dispatch(createFileDownloadTaskActions.open());
  };

  const handleShare = (event: SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    dispatch(
      shareFileDownloadTaskActions.setFileArtifact({
        entityId: group.id,
        type: 'favourites_group',
      }),
    );
    dispatch(shareFileDownloadTaskActions.open());
  };

  const actions: Array<ActionListItem> = [
    {
      label: t('actions.edit'),
      onClick: handleEdit,
      hasAccess: () => folderAccessRuleService.canEdit(),
      startAdornment: <Icon name='edit' />,
    },
    {
      label: t('actions.share'),
      onClick: handleShare,
      hasAccess: () => fileAccessRuleService.canShare(),
      startAdornment: <Icon name='share' />,
    },
    {
      label: t('actions.download'),
      onClick: handleDownload,
      hasAccess: () => fileAccessRuleService.canDownload(),
      startAdornment: <Icon name='downloadAlt' />,
    },
    {
      label: t('actions.remove'),
      onClick: handleDelete,
      variant: ActionListItemVariant.DANGER,
      hasAccess: () => folderAccessRuleService.canDelete(),
      startAdornment: <Icon name='trash' />,
    },
  ];

  return { actions };
};
