import cn from 'classnames';
import React, { FunctionComponent } from 'react';
import styles from './PropertyInfo.module.scss';
import { PropertyInfoProps } from './PropertyInfoProps';

export const PropertyInfo: FunctionComponent<PropertyInfoProps> = (props) => {
  const { title, value } = props;

  return (
    <div className={cn(styles.root, { [styles.hidden]: !value })}>
      <span className={styles.title} title={title}>
        {title}
      </span>
      <span className={styles.value}>{value}</span>
    </div>
  );
};
