import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FolderPartition } from 'features/folders';
import { getDefaultModalState, getModalReducers, ModalState } from 'features/modal';

export type RootFolderCreationState = ModalState & {
  selectedPartition?: FolderPartition;
};

const initialState: RootFolderCreationState = getDefaultModalState();

const rootFolderCreationSlice = createSlice({
  name: 'rootFolderCreation',
  initialState,
  reducers: {
    ...getModalReducers<RootFolderCreationState>((state) => state),
    selectPartition: (state, action: PayloadAction<FolderPartition | undefined>) => {
      state.selectedPartition = action.payload;
    },
  },
});

export const rootFolderCreationActions = rootFolderCreationSlice.actions;
export const rootFolderCreationReducer = rootFolderCreationSlice.reducer;
