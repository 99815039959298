import { forwardRef, useImperativeHandle, useRef } from 'react';
import { InputLayout } from '../InputLayout';
import { InputControl } from './Control/InputControl';
import { InputProps } from './InputProps';

/**
 * @deprecated
 */
export const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const { disabled, LayoutProps, ...controlProps } = props;

  const inputRef = useRef<HTMLInputElement>(null);

  useImperativeHandle(ref, () => inputRef.current as HTMLInputElement);

  return (
    <InputLayout
      {...LayoutProps}
      className={LayoutProps?.className}
      disabled={disabled}
      onClick={() => {
        inputRef.current?.focus();
      }}
      ControlProps={controlProps}
      renderControl={(controlProps) => <InputControl ref={inputRef} {...controlProps} />}
    />
  );
});
