import { FunctionComponent } from 'react';
import { FolderCardBase } from 'features/v1/folder/FolderCardBase/FolderCardBase';
import { useRootFolderActions } from '../../../hooks';
import { RootFolderCardExternalProps } from './RootFolderCardProps';

export const RootFolderCard: FunctionComponent<RootFolderCardExternalProps> = (props) => {
  const { folder, groupId } = props;
  const { actions } = useRootFolderActions({
    folder,
    groupId,
  });

  return <FolderCardBase folder={folder} actions={actions} />;
};
