import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { SortDirection } from 'features/sorting/sortDirection';
import { ButtonKind } from 'ui-library/components/Button';
import { Icon } from 'ui-library/components/Icon';
import { IconButton } from 'ui-library/components/IconButton';
import { IconButtonVariant } from 'ui-library/components/IconButton';
import { SortButtonProps } from './SortButtonProps';

export const SortButton: FunctionComponent<SortButtonProps> = (props) => {
  const { t } = useTranslation();
  const { direction, toggleSort, className } = props;
  const isAsc = direction === SortDirection.ASC;

  return (
    <IconButton
      className={className}
      onClick={toggleSort}
      as={ButtonKind.BUTTON}
      title={isAsc ? t('sorting.asc') : t('sorting.desc')}
      variant={IconButtonVariant.TRANSPARENT}
      size='small'
    >
      <Icon name={isAsc ? 'sortA' : 'sortZ'} />
    </IconButton>
  );
};
