import { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { useGetFolderResponseGroupedData } from 'features/folders';
import { getFolderDetailRoute } from 'infrastructure/routes/paths';
import { FolderCard } from '../FolderCard/FolderCard';
import { FolderCardListLayout } from '../FolderCardListLayout/FolderCardListLayout';
import { FolderCardListSkeleton } from '../FolderCardListSkeleton/FolderCardListSkeleton';
import styles from './FolderCardList.module.scss';
import { FolderCardListProps } from './FolderCardListProps';

export const FolderCardList: FunctionComponent<FolderCardListProps> = (props) => {
  const { isFetching, data } = props;
  const groupedData = useGetFolderResponseGroupedData(data?.content);

  return (
    <div className={styles.root}>
      {isFetching ? (
        <FolderCardListSkeleton />
      ) : (
        Object.entries(groupedData).map(([key, items]) => (
          <FolderCardListLayout letter={key} key={key}>
            {items.map((x) => (
              <Link key={x.id} to={getFolderDetailRoute(x.id, x.partition)}>
                <FolderCard folder={x} />
              </Link>
            ))}
          </FolderCardListLayout>
        ))
      )}
    </div>
  );
};
