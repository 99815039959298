import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getDefaultModalState, getModalReducers, ModalState } from 'features/modal';
import { FolderResponseDto } from '../folderEntity';

type DeleteEmptyFolderState = ModalState & {
  folder?: FolderResponseDto;
};

const initialState: DeleteEmptyFolderState = {
  ...getDefaultModalState(),
  folder: undefined,
};

const folderDeleteEmptySlice = createSlice({
  name: 'folderDeleteEmpty',
  initialState,
  reducers: {
    ...getModalReducers<DeleteEmptyFolderState>((state) => state),
    setFolderForDeletion: (state, action: PayloadAction<FolderResponseDto | undefined>) => {
      state.folder = action.payload;
    },
  },
});

export const folderDeleteEmptyActions = folderDeleteEmptySlice.actions;
export const folderDeleteEmptyReducer = folderDeleteEmptySlice.reducer;
