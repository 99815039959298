import { createSlice } from '@reduxjs/toolkit';
import { getListSortReducers, ListState } from 'features/list';
import { getListPaginationReducers } from 'features/list/listReducers';
import { getDefaultPaginationState } from 'features/pagination';
import { SortDirection } from 'features/sorting';
import { FavoriteContentSortingParams } from '../favoriteEntity';

type EntityType = FavoriteContentSortingParams;
export type FavoriteImageListState = ListState<EntityType>;

const initialState: FavoriteImageListState = {
  sortingInfo: {
    sortField: 'favouritesCreateTime',
    sortDirection: SortDirection.DESC,
  },
  paginationData: getDefaultPaginationState(),
};

const favoriteImageListSlice = createSlice({
  name: 'favoriteImageList',
  initialState,
  reducers: {
    ...getListSortReducers<FavoriteImageListState, EntityType>(),
    ...getListPaginationReducers<FavoriteImageListState>(),
  },
});

export const favoriteImageListActions = favoriteImageListSlice.actions;
export const favoriteImageListReducer = favoriteImageListSlice.reducer;
