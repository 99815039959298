import { skipToken } from '@reduxjs/toolkit/dist/query';
import { foldersApi, useFetchFolderDetailQuery } from 'features/folders';
import { imagesApi } from 'features/images';
import { layoutsApi } from 'features/layouts';
import { searchApi } from 'features/search';
import { NotDefinedParamException } from 'infrastructure/exceptions';
import { useAppDispatch } from 'infrastructure/redux';
import { FavoriteArtifactEntity } from '../favoriteEntity';

export const useInvalidateFavoriteRelatedEntities = (favoriteArtifact: FavoriteArtifactEntity | undefined) => {
  const dispatch = useAppDispatch();
  const entityType = favoriteArtifact?.type;
  const entityId = favoriteArtifact?.entityId;
  const entityParentId = favoriteArtifact?.parentId;
  const { data: folderData } = useFetchFolderDetailQuery(entityType === 'folder' && entityId ? entityId : skipToken);

  const invalidateEntities = () => {
    if (!favoriteArtifact) {
      throw new NotDefinedParamException('Favorite artifact');
    }

    if (entityType === 'folder') {
      if (folderData?.isRoot) {
        dispatch(foldersApi.util.invalidateTags([{ type: 'Folder' as const, id: entityId }, 'RootFolders']));
      } else {
        dispatch(
          foldersApi.util.invalidateTags([
            { type: 'Folder' as const, id: entityId },
            {
              type: 'FolderChildren',
              id: folderData?.parentID,
            },
          ]),
        );
      }
    } else if (entityType === 'layout') {
      dispatch(layoutsApi.util.invalidateTags([{ type: 'Layout' as const, id: entityId }, 'FolderLayouts']));
    } else if (entityType === 'image') {
      dispatch(
        imagesApi.util.invalidateTags([
          { type: 'Image' as const, id: entityId },
          {
            type: 'ImageGallery',
            id: entityParentId,
          },
          'ImageGallery',
        ]),
      );
    }

    dispatch(searchApi.util.invalidateTags(['SearchResult']));
  };

  return {
    invalidateEntities,
  };
};
