import { Transition } from '@headlessui/react';
import { forwardRef, Fragment } from 'react';
import styles from './ModalBackdrop.module.scss';

export const ModalBackdrop = forwardRef<HTMLDivElement, object>((_, ref) => {
  return (
    <Transition.Child
      as={Fragment}
      enter={styles.animation}
      enterFrom={styles.from}
      enterTo={styles.to}
      leave={styles.animation}
      leaveFrom={styles.to}
      leaveTo={styles.from}
    >
      <div className={styles.root} ref={ref} />
    </Transition.Child>
  );
});
