import { FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { BaseQueryFn } from '@reduxjs/toolkit/query';
import jwt_decode from 'jwt-decode';
import { AuthResponseDto, authService } from 'features/auth';
import { signOut } from 'features/auth/authActions';
import { AuthTokenData } from 'features/auth/authEntity';
import { parseApiError } from 'infrastructure/network/helpers';
import { ApiErrorCode } from 'infrastructure/network/types';
import { API_URL, URL_FACTORY } from 'infrastructure/network/urlFactory';
import { HTTP_CODES } from '../constants/httpCodes';
import { fetchBaseQuery } from './fetchBaseQuery';

const baseAuthQuery = fetchBaseQuery({
  prepareHeaders: (headers) => {
    const accessToken = authService.accessToken;

    if (accessToken) {
      headers.set('authorization', `Bearer ${accessToken}`);
    }

    return headers;
  },
});

const refreshAuthQuery = async (refreshToken: string) => {
  const dataToken = { refreshToken: refreshToken };
  const response = await fetch(`${API_URL}/${URL_FACTORY.REFRESH}`, {
    headers: { 'Content-type': 'application/json' },
    method: 'POST',
    body: JSON.stringify(dataToken),
  });
  return response.json();
};

let refreshFlag = true;
export const fetchBaseAuthQuery = <ResultType>() =>
  (async (args, api, extraOptions) => {
    let result = await baseAuthQuery(args, api, extraOptions);
    if (result.error && result.error.status === HTTP_CODES.UNAUTHORIZED) {
      const refreshToken = authService.refreshToken;
      if (refreshToken && refreshFlag) {
        refreshFlag = false;
        try {
          const data: AuthResponseDto = await refreshAuthQuery(refreshToken).catch((e) => {
            const error = parseApiError(e);
            if (error?.code === ApiErrorCode.UNAUTHORIZED) {
              api.dispatch(signOut());
            }
          });
          const decodedToken = jwt_decode<AuthTokenData>(data.token);

          authService.setAccessToken(data.token);
          authService.setRefreshToken(data.refreshToken);
          authService.setTokenData(decodedToken);
          result = await baseAuthQuery(args, api, extraOptions);
          return result;
        } catch (e) {
          api.dispatch(signOut());
        } finally {
          refreshFlag = true;
        }
      }
    }

    return result;
  }) as BaseQueryFn<string | FetchArgs, ResultType, FetchBaseQueryError>;
