import { FunctionComponent, SyntheticEvent } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { useAuth } from 'features/auth';
import { changeOrderActions, NumLabel } from 'features/changeOrder';
import { FolderResponseDto, useFolderTableColumns } from 'features/folders';
import { UserRole } from 'features/users';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux';
import { getFolderDetailRoute } from 'infrastructure/routes/paths';
import { Table, TableBody, TableHead } from 'ui-library/components/Table';
import { FolderEmptyChildren } from '../FolderEmptyChildren/FolderEmptyChildren';
import { FolderTableActions } from '../FolderTableActions/FolderTableActions';
import { FolderTableSkeleton } from '../FolderTableSkeleton/FolderTableSkeleton';
import styles from './FolderTable.module.scss';
import { FolderTableProps } from './FolderTableProps';

export const FolderTable: FunctionComponent<FolderTableProps> = (props) => {
  const { data, params } = props;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isShowOrderMode = useAppSelector((state) => state.changeOrder.isShowOrderMode);
  const columns = useFolderTableColumns();
  const auth = useAuth();

  const handleRowClick = (event: SyntheticEvent, item: FolderResponseDto) => {
    !isShowOrderMode && navigate(getFolderDetailRoute(item.id, item.partition) + `?${createSearchParams(params)}`);

    if (isShowOrderMode) {
      dispatch(
        changeOrderActions.setChangeArtifact({
          folderID: item.id,
          parentId: item.parentID || '',
          order: item.order,
          type: 'folder',
        }),
      );
      dispatch(changeOrderActions.open());
    }
  };

  const isEmpty = data?.totalCount === 0;

  if (isEmpty) {
    return <FolderEmptyChildren />;
  }

  return (
    <Table>
      <TableHead columns={columns} />
      {props.isFetching ? (
        <FolderTableSkeleton />
      ) : (
        <TableBody
          columns={columns}
          items={data?.content ?? []}
          getRowActions={(x, options) => (
            <FolderTableActions
              folder={x}
              className={auth.user?.role === UserRole.GUEST ? styles.actionGuest : options?.className}
            />
          )}
          onRowClick={handleRowClick}
          conditionLastCell={isShowOrderMode}
          lastColumnCell={(item) => <NumLabel num={item.order} staticPosition />}
        />
      )}
    </Table>
  );
};
