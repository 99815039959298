import { saveAs } from 'file-saver';
import { SyntheticEvent, useMemo } from 'react';
import { addToFavoritesActions, removeFromFavoritesActions } from 'features/favorites';
import { FilePreviewLabel } from 'features/file';
import { FolderPartition } from 'features/folders';
import { imageDeleteActions } from 'features/images/slices';
import { linkingArtifactActions } from 'features/linking';
import { movingArtifactsActions } from 'features/movingArtifacts';
import { NotDefinedParamException } from 'infrastructure/exceptions';
import { useAppDispatch } from 'infrastructure/redux';
import { GalleryImageItemDto, ImageEditRequestDto, ImageGalleryFlatListItem } from '../imageEntity';
import { useEditImageMutation } from '../imagesApi';
import { imageEditActions } from '../slices/imageEditSlice';
import { useUnlinkImage } from './useUnlinkImage';

export type UseImageActionHandlersOptions = {
  image: GalleryImageItemDto | undefined;
  parentId: string | undefined;
  folder?: ImageGalleryFlatListItem | undefined;
};

export const useImageActionHandlers = (options: UseImageActionHandlersOptions) => {
  const dispatch = useAppDispatch();
  const { image, parentId, folder } = options;
  const originalFile = image?.file;
  const previewJpeg = useMemo(
    () => image?.file?.previews?.find((x) => x.label === FilePreviewLabel.IMAGE_ORIGINAL_JPEG),
    [image?.file],
  );
  const [unLinkImageMutation] = useEditImageMutation();
  const { handleUnlinkImage, handleUnlinkImageError } = useUnlinkImage();

  const handleEdit = (event: SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    dispatch(imageEditActions.selectImage(image?.id));
    dispatch(imageEditActions.open());
  };

  const handleMove = (event: SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (!image) {
      throw new NotDefinedParamException('image');
    }

    dispatch(
      movingArtifactsActions.setMovingArtifacts([
        {
          entityId: image.id,
          parentID: parentId,
        },
      ]),
    );
    dispatch(movingArtifactsActions.setMovingType('image'));
    dispatch(movingArtifactsActions.selectFolderId(parentId));
    dispatch(movingArtifactsActions.selectPartition(FolderPartition.MUSEUM));
    dispatch(movingArtifactsActions.open());
  };

  const handleLink = (event: SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (!image) {
      throw new NotDefinedParamException('image');
    }

    dispatch(
      linkingArtifactActions.setLinkingArtifacts([
        {
          entityId: image.id,
          parentID: parentId,
        },
      ]),
    );
    dispatch(linkingArtifactActions.setLinkingType('image'));
    dispatch(linkingArtifactActions.selectFolderId(parentId));
    dispatch(linkingArtifactActions.open());
  };

  const handleUnlink = async (event: SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (!image) {
      throw new NotDefinedParamException('Image');
    }
    if (!folder) {
      throw new NotDefinedParamException('Folder');
    }

    const dtoImage: ImageEditRequestDto = {
      imageID: image?.id,
      folderIDsToRemove: parentId ? [parentId] : undefined,
    };

    try {
      await unLinkImageMutation(dtoImage).unwrap();
      handleUnlinkImage(image, folder);
    } catch (e) {
      handleUnlinkImageError();
    }
  };

  const handleTifDownload = () => {
    if (originalFile?.url) {
      saveAs(originalFile.url, originalFile.name);
    }
  };

  const handleJpegDownload = () => {
    if (previewJpeg) {
      saveAs(previewJpeg.url, previewJpeg.name);
    }
  };

  const handleDownload = (event: SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (previewJpeg) {
      saveAs(previewJpeg.url, previewJpeg.name);
    } else if (originalFile?.url) {
      saveAs(originalFile.url, originalFile.name);
    }
  };

  const handleAddToFavorite = (event: SyntheticEvent) => {
    event.stopPropagation();
    event.preventDefault();

    if (!image) {
      throw new NotDefinedParamException('Image');
    }

    dispatch(
      addToFavoritesActions.setFavoriteArtifact({
        entityId: image.id,
        type: 'image',
        parentId,
        isFavorite: image.isFavourite,
      }),
    );
    dispatch(addToFavoritesActions.open());
  };

  const handleRemoveFromFavorite = (event: SyntheticEvent) => {
    event.stopPropagation();
    event.preventDefault();

    if (!image) {
      throw new NotDefinedParamException('Image');
    }

    dispatch(
      removeFromFavoritesActions.setFavoriteArtifact({
        entityId: image.id,
        type: 'image',
        parentId,
        isFavorite: image.isFavourite,
      }),
    );
    dispatch(removeFromFavoritesActions.open());
  };

  const handleDelete = (event: SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    dispatch(imageDeleteActions.setImageForDeletion(image?.id));
    dispatch(imageDeleteActions.open());
  };

  return {
    handleEdit,
    handleDelete,
    handleLink,
    handleUnlink,
    handleMove,
    handleTifDownload,
    handleJpegDownload,
    handleDownload,
    handleAddToFavorite,
    handleRemoveFromFavorite,
    isDownloadableTif: originalFile,
    isDownloadableJpeg: previewJpeg,
  };
};
