import cn from 'classnames';
import React, { FunctionComponent } from 'react';
import styles from './TableCell.module.scss';
import { TableCellProps } from './TableCellProps';

export const TableCell: FunctionComponent<TableCellProps> = (props) => {
  const { className, ...rest } = props;

  return <td className={cn(styles.root, className)} {...rest} />;
};
