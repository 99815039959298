import React from 'react';
import { mergeRefs } from 'react-merge-refs';
import { usePopoverState } from '../hooks/usePopoverState';
import { PopoverTriggerProps } from './PopoverTriggerProps';

export const PopoverTrigger = React.forwardRef<HTMLElement, React.HTMLProps<HTMLElement> & PopoverTriggerProps>(
  function PopoverTrigger({ children, ...props }, propRef) {
    const state = usePopoverState();
    const childrenRef = (children as any).ref;

    const ref = React.useMemo(
      () => mergeRefs([state.reference, propRef, childrenRef]),
      [state.reference, propRef, childrenRef],
    );

    if (!React.isValidElement(children)) {
      return null;
    }

    return React.cloneElement(
      children,
      state.getReferenceProps({
        ref,
        ...props,
        ...children.props,
        'data-state': state.open ? 'open' : 'closed',
      }),
    );
  },
);
