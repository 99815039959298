import { useTranslation } from 'react-i18next';
import { folderAccessRuleService } from 'features/role';
import { rootFolderCreationActions } from 'features/rootFolders';
import { useAppDispatch } from 'infrastructure/redux';
import { Icon } from 'ui-library/components/Icon';
import { Button, ButtonKind, ButtonVariant } from 'ui-library/v1/components/Button';
import { ZeroScreenWrapper } from 'ui-library/v1/components/ZeroScreen';
import { EmptyRootProps } from './EmptyRootProps';

export const EmptyRoot = (props: EmptyRootProps) => {
  const { className, theme = 'dark', partition } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleAdd = () => {
    dispatch(rootFolderCreationActions.open());
    dispatch(rootFolderCreationActions.selectPartition(partition));
  };

  const canCreate = folderAccessRuleService.canCreate();

  return (
    <ZeroScreenWrapper
      className={className}
      theme={theme}
      renderIcon={(props) => <Icon name='imagesIllustration' size='none' {...props} />}
      renderButton={(props) =>
        canCreate ? (
          <Button
            as={ButtonKind.BUTTON}
            variant={ButtonVariant.SECONDARY}
            onClick={handleAdd}
            size='small'
            theme={theme === 'dark' ? 'default' : 'secondary'}
            startIcon={<Icon name='plus' />}
            {...props}
          >
            {t('actions.add')}
          </Button>
        ) : undefined
      }
      message={canCreate ? t('folders.emptyRoot.createMessage') : t('folders.emptyRoot.viewMessage')}
    />
  );
};
