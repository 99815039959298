import { AuthTokenData } from 'features/auth/authEntity';

class AuthService {
  private readonly STORAGE_TYPE_EVENT = 'storage';
  private readonly accessTokenKey = 'access_token';
  private readonly refreshTokenKey = 'refresh_token';
  private readonly tokenDataKey = 'token_data';
  private readonly lastAuthorizedPathKey = 'last_authorized_path';

  constructor() {
    window.addEventListener(this.STORAGE_TYPE_EVENT, (e) => this._switchOtherTabStorageEvent(e), false);
  }

  get accessToken(): string | null {
    return localStorage.getItem(this.accessTokenKey);
  }

  get refreshToken(): string | null {
    return localStorage.getItem(this.refreshTokenKey);
  }

  get tokenData(): AuthTokenData | null {
    const json = localStorage.getItem(this.tokenDataKey);

    if (!json) {
      return null;
    }

    return JSON.parse(json);
  }

  get lastAuthorizedPath(): string | null {
    return localStorage.getItem(this.lastAuthorizedPathKey);
  }

  public setAccessToken(token: string): void {
    localStorage.setItem(this.accessTokenKey, token);
  }

  public setRefreshToken(token: string): void {
    localStorage.setItem(this.refreshTokenKey, token);
  }

  public setTokenData(data: AuthTokenData): void {
    localStorage.setItem(this.tokenDataKey, JSON.stringify(data));
  }

  public setLastAuthorizedPath(path: string): void {
    localStorage.setItem(this.lastAuthorizedPathKey, path);
  }

  public clearLastAuthorizedPath(): void {
    localStorage.removeItem(this.lastAuthorizedPathKey);
  }

  public clear(): void {
    localStorage.removeItem(this.accessTokenKey);
    localStorage.removeItem(this.refreshTokenKey);
    localStorage.removeItem(this.tokenDataKey);
  }

  private _switchOtherTabStorageEvent(event: StorageEvent): void {
    if (event.storageArea === localStorage) {
      switch (event.key) {
        case this.accessTokenKey:
          this._handleAccessTokenChange();
          break;
        default:
          break;
      }
    }
  }

  private _handleAccessTokenChange(): void {
    this.clearLastAuthorizedPath();
    window.location.reload();
  }
}

export const authService = new AuthService();
