import { FunctionComponent, useEffect } from 'react';
import { createSearchParams, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { changeOrderActions } from 'features/changeOrder';
import {
  FolderDetailQuerySearchParams,
  FolderListDataView,
  folderMuseumDetailActions,
  FolderMuseumDetailViewType,
  FolderPartition,
} from 'features/folders';
import { ImageGalleryDataView } from 'features/images';
import { AnchorButton } from 'features/navigation';
import { DEFAULT_PAGE_SIZE, FIRST_PAGE } from 'features/pagination';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux';
import { Tab } from 'ui-library/components/Tab';
import { nameof } from 'utils';
import { FolderMuseumDetailDataHeader } from '../FolderMuseumDetailDataHeader/FolderMuseumDetailDataHeader';
import styles from './FolderMuseumDetailDataView.module.scss';
import { FolderMuseumDetailDataViewProps } from './FolderMuseumDetailDataViewProps';

export const FolderMuseumDetailDataView: FunctionComponent<FolderMuseumDetailDataViewProps> = (props) => {
  const { folderId, childrenCount, folder } = props;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { currentFolderId, galleryScrollOffsetY } = useAppSelector((state) => state.folderMuseumDetail);
  const { layoutType, sortingInfo } = useAppSelector((state) => state.folderMuseumList);

  const getTab = () => {
    const tab = searchParams.get(nameof<FolderDetailQuerySearchParams>('tab'));
    if (tab !== null) {
      return Number(tab);
    } else {
      return FolderMuseumDetailViewType.IMAGES;
    }
  };

  const handleScrollOffsetYChange = (offset: number) => {
    dispatch(folderMuseumDetailActions.setGalleryScrollOffsetY(offset));
  };

  const getPaginationData = () => {
    const page = searchParams.get(nameof<FolderDetailQuerySearchParams>('page'));
    if (page !== null) {
      return { currentPage: Number(page), pageSize: DEFAULT_PAGE_SIZE };
    } else {
      return { currentPage: FIRST_PAGE, pageSize: DEFAULT_PAGE_SIZE };
    }
  };

  const handleTabChange = (selectedTab: FolderMuseumDetailViewType) => {
    dispatch(folderMuseumDetailActions.setGalleryScrollOffsetY(0));

    const page = searchParams.get(nameof<FolderDetailQuerySearchParams>('page'));
    const params: FolderDetailQuerySearchParams = {
      tab: String(selectedTab),
      page: page ?? String(FIRST_PAGE),
    };
    navigate({
      pathname: location.pathname,
      search: `?${createSearchParams(params)}`,
    });
    window.scrollTo({ top: 0, left: 0 });
    dispatch(changeOrderActions.dispose());
    dispatch(changeOrderActions.setShowOrderMode(false));
  };

  const handlePageChange = (selectedPage: number) => {
    const tab = searchParams.get(nameof<FolderDetailQuerySearchParams>('tab'));
    const params: FolderDetailQuerySearchParams = {
      tab: tab ?? '',
      page: String(selectedPage),
    };
    navigate({
      pathname: location.pathname,
      search: `?${createSearchParams(params)}`,
    });
  };

  useEffect(() => {
    if (childrenCount === 0) {
      const params: FolderDetailQuerySearchParams = {
        tab: String(FolderMuseumDetailViewType.IMAGES),
        page: String(FIRST_PAGE),
      };
      navigate({
        pathname: location.pathname,
        search: `?${createSearchParams(params)}`,
      });
    }
  }, [childrenCount]);

  useEffect(() => {
    const isSameDirectory = currentFolderId === folderId;

    if (isSameDirectory) {
      return;
    }

    window.scrollTo({ top: 0, left: 0 });
    dispatch(folderMuseumDetailActions.setGalleryScrollOffsetY(0));
    dispatch(folderMuseumDetailActions.setCurrentFolderId(folderId));
  }, [dispatch, folderId, currentFolderId]);

  return (
    <div className={styles.root}>
      <Tab.Group as='div' selectedIndex={getTab()} onChange={handleTabChange}>
        <AnchorButton />
        <FolderMuseumDetailDataHeader tab={getTab()} folder={folder} />
        <Tab.Panels className={styles.panels}>
          <Tab.Panel>
            <ImageGalleryDataView
              folderId={folderId}
              initialScrollOffsetY={galleryScrollOffsetY}
              onSaveScrollOffsetY={handleScrollOffsetYChange}
            />
          </Tab.Panel>

          <Tab.Panel>
            <FolderListDataView
              folderId={folderId}
              paginationState={getPaginationData()}
              sortingState={sortingInfo}
              layoutType={layoutType}
              partition={FolderPartition.MUSEUM}
              onChangePage={handlePageChange}
            />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};
