import { yupResolver } from '@hookform/resolvers/yup';
import { useForm as useFormRHF } from 'react-hook-form';
import { FieldValues, UseFormReturn } from 'react-hook-form/dist/types';
import { UseFormProps } from './useFormProps';

export function useForm<TFieldValues extends FieldValues = FieldValues, TContext = unknown>(
  props?: UseFormProps<TFieldValues, TContext>,
): UseFormReturn<TFieldValues, TContext> {
  return useFormRHF({
    ...props,
    resolver: props?.schema ? yupResolver(props.schema) : undefined,
  });
}
