import { folderAccessRuleService } from 'features/role';
import { FolderPartition, FolderResponseDto } from '../folderEntity';

export class FolderApproverService {
  public canRemove(folder?: FolderResponseDto) {
    if (!folder || !folderAccessRuleService.canDelete()) {
      return false;
    }

    return (
      (folder.partition === FolderPartition.MUSEUM && folder.imageCount === 0) ||
      (folder.partition === FolderPartition.ARCHIVE && folder.layoutCount === 0)
    );
  }
}

export const folderApproverService = new FolderApproverService();
