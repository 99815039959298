import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getDefaultModalState, getModalReducers, ModalState } from 'features/modal';

export type FolderViewState = ModalState & {
  selectedFolderId?: string;
};

const initialState: FolderViewState = {
  ...getDefaultModalState(),
  selectedFolderId: undefined,
};

const folderViewSlice = createSlice({
  name: 'folderView',
  initialState,
  reducers: {
    ...getModalReducers<FolderViewState>((state) => state),
    selectFolder: (state, action: PayloadAction<string | undefined>) => {
      state.selectedFolderId = action.payload;
    },
  },
});

export const folderViewActions = folderViewSlice.actions;
export const folderViewReducer = folderViewSlice.reducer;
