import * as yup from 'yup';
import { SchemaOf } from 'yup';
import { REGULAR_EXPRESSIONS } from 'utils';
import { UserCreateFormFields, UserRole } from './userEntity';
import { getStrongPasswordValidation } from './utils/strongPasswordValidation';

const maxLen = 50;
const emailValidationMask = REGULAR_EXPRESSIONS.EMAIL;

export const UserCreateDtoValidationSchema = (): SchemaOf<UserCreateFormFields> =>
  yup.object({
    name: yup.string().required().max(maxLen),
    surname: yup.string().optional().max(maxLen),
    email: yup.string().matches(emailValidationMask).required().max(maxLen),
    role: yup.mixed<UserRole>().oneOf(Object.values(UserRole)).required(),
    password: getStrongPasswordValidation(),
  });
