import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getDefaultModalState, getModalReducers, ModalState } from 'features/modal';

export type FavoriteGroupRemoveState = ModalState & {
  selectedGroupId?: string;
};

const initialState: FavoriteGroupRemoveState = {
  ...getDefaultModalState(),
  selectedGroupId: undefined,
};

const favoriteGroupRemoveSlice = createSlice({
  name: 'favoriteGroupRemove',
  initialState,
  reducers: {
    ...getModalReducers<FavoriteGroupRemoveState>((state) => state),
    selectGroupId: (state, action: PayloadAction<string | undefined>) => {
      state.selectedGroupId = action.payload;
    },
  },
});

export const favoriteGroupRemoveActions = favoriteGroupRemoveSlice.actions;
export const favoriteGroupRemoveReducer = favoriteGroupRemoveSlice.reducer;
