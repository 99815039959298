import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getDefaultModalState, getModalReducers, ModalState } from 'features/modal';
import { TagResponseDto } from '../tagEntity';

export type TagDeleteState = ModalState & {
  selectedTag?: TagResponseDto;
};

const initialState: TagDeleteState = {
  ...getDefaultModalState(),
  selectedTag: undefined,
};

const tagDeleteSlice = createSlice({
  name: 'tagDelete',
  initialState,
  reducers: {
    ...getModalReducers<TagDeleteState>((state) => state),
    selectTag: (state, action: PayloadAction<TagResponseDto | undefined>) => {
      state.selectedTag = action.payload;
    },
  },
});

export const tagDeleteActions = tagDeleteSlice.actions;
export const tagDeleteReducer = tagDeleteSlice.reducer;
