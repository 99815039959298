import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getDefaultModalState, getModalReducers, ModalState } from 'features/modal';
import { LayoutViewTab } from '../components/LayoutView/LayoutViewTab';

export type LayoutEditState = ModalState & {
  selectedLayoutId?: string;
  selectedTab: LayoutViewTab;
};

const initialState: LayoutEditState = {
  ...getDefaultModalState(),
  selectedLayoutId: undefined,
  selectedTab: LayoutViewTab.INFORMATION,
};

const layoutEditSlice = createSlice({
  name: 'layoutEdit',
  initialState,
  reducers: {
    ...getModalReducers<LayoutEditState>((state) => state),
    selectLayout: (state, action: PayloadAction<string | undefined>) => {
      state.selectedLayoutId = action.payload;
    },
    selectTab: (state, action: PayloadAction<LayoutViewTab>) => {
      state.selectedTab = action.payload;
    },
  },
});

export const layoutEditActions = layoutEditSlice.actions;
export const layoutEditReducer = layoutEditSlice.reducer;
