import { Controller, useFormContext } from 'react-hook-form';
import { FolderEditList } from 'features/folders/components/FolderEditList/FolderEditList';
import { ImageEditFormFields } from 'features/images';
import { FolderResponseDto } from '../../folderEntity';
import { FoldersAutocomplete } from '../FoldersAutocomplete/FoldersAutocomplete';
import styles from './FoldersFormController.module.scss';
import { FoldersFormControllerProps } from './FoldersFormControllerProps';

export const FoldersFormController = (props: FoldersFormControllerProps) => {
  const { name, type } = props;
  const { control } = useFormContext();
  const { setValue, getValues } = useFormContext<ImageEditFormFields>();

  const addFolderListItem = (value: FolderResponseDto) => {
    const concatArrayFolders = getValues()?.folders ?? [];
    setValue('folders', [value].concat(concatArrayFolders));
  };

  const removeFolderListItem = (folder: FolderResponseDto) => {
    return setValue(
      'folders',
      getValues()?.folders?.filter((x) => x.id !== folder.id),
    );
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value } }) => (
        <div className={styles.root}>
          <FoldersAutocomplete selectedFolders={value} onInternalChange={addFolderListItem} type={type} />
          {value.map((x: FolderResponseDto) => (
            <FolderEditList folder={x} key={x.id} onRemoveItem={removeFolderListItem} />
          ))}
        </div>
      )}
    />
  );
};
