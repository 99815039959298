import { PaginationState } from 'features/pagination';
import { SortState } from 'features/sorting';
import { ListPaginationState, ListState } from './listState';

export function selectSortingInfo<TEntity>(state: ListState<TEntity>): SortState<TEntity> {
  return state.sortingInfo;
}

export function selectPaginationData(state: ListPaginationState): PaginationState {
  return state.paginationData;
}
