import React, { FunctionComponent } from 'react';
import styles from './ListItemGroupLetter.module.scss';
import { ListItemGroupLetterProps } from './ListItemGroupLetterProps';

export const ListItemGroupLetter: FunctionComponent<ListItemGroupLetterProps> = (props) => {
  return (
    <div className={styles.root}>
      <span className={styles.text}>{props.children}</span>
    </div>
  );
};
