import { TableHeadProps } from './TableHeadProps';

export const TableHead = (props: TableHeadProps) => {
  const { columns } = props;

  return (
    <thead>
      <tr>
        {columns.map((col, idx) => (
          <th key={idx} style={{ width: col.width }} />
        ))}
      </tr>
    </thead>
  );
};
