import cn from 'classnames';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { FilePreviewLabel } from 'features/file';
import { CardVariant } from 'ui-library/components/Card';
import { Icon } from 'ui-library/components/Icon';
import { Image } from 'ui-library/components/Image';
import { ImageCardRoot } from '../ImagesCardRoot/ImageCardRoot';
import styles from './ImageCardBase.module.scss';
import { ImageCardBaseProps } from './ImageCardBaseProps';

export const ImageCardBase: FunctionComponent<ImageCardBaseProps> = (props) => {
  const { data, className, previewLabel = FilePreviewLabel.IMAGE_PREVIEW } = props;
  const { t } = useTranslation();

  return (
    <ImageCardRoot className={cn(styles.root, className)} variant={CardVariant.TRANSPARENT}>
      {data.isFavourite && <Icon className={styles.favorite} name='favoriteFilled' />}
      <Image
        className={styles.image}
        src={data.file?.previews?.find((data) => data.label === previewLabel)?.url}
        alt={t('layouts.imageAlt')}
      />
    </ImageCardRoot>
  );
};
