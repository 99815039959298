import { FunctionComponent } from 'react';
import { useAppSelector } from 'infrastructure/redux';
import { MovingArtifactFolderList } from '../MovingArtifactFolderList/MovingArtifactFolderList';
import { MovingArtifactRootFolderDataView } from '../MovingArtifactRootFolderDataView/MovingArtifactRootFolderDataView';
import { MovingArtifactFoldersTabProps } from './MovingArtifactFoldersTabProps';

export const MovingArtifactFoldersTab: FunctionComponent<MovingArtifactFoldersTabProps> = (props) => {
  const { partition } = props;
  const selectedFolderId = useAppSelector((state) => state.movingArtifacts.selectedFolderId);

  return (
    <>
      {selectedFolderId ? (
        <MovingArtifactFolderList folderId={selectedFolderId} partition={partition} />
      ) : (
        <MovingArtifactRootFolderDataView partition={partition} />
      )}
    </>
  );
};
