import { FunctionComponent, useEffect } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { FolderArtifactListFooter } from 'features/fileNavigator/folders';
import {
  FetchChildrenFolderQueryData,
  FolderListItem,
  FolderLoadingError,
  FolderResponseDto,
  foldersApi,
  useFetchFolderChildrenQuery,
  useLazyFetchFolderDetailQuery,
  useLazyFetchFolderPathQuery,
} from 'features/folders';
import { linkingArtifactActions } from 'features/linking';
import { useInfiniteLoading } from 'features/pagination';
import { SortDirection } from 'features/sorting';
import { useLanguageRelevantData } from 'infrastructure/i18n';
import { ListApiResponse } from 'infrastructure/network';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux';
import { List } from 'ui-library/components/List';
import { LinkingArtifactEmptyFolder } from '../LinkingArtifactEmptyFolder/LinkingArtifactEmptyFolder';
import styles from './LinkingArtifactFolderList.module.scss';
import { LinkingArtifactFolderListProps } from './LinkingArtifactFolderListProps';

export const LinkingArtifactFolderList: FunctionComponent<LinkingArtifactFolderListProps> = (props) => {
  const { folderId } = props;
  const { matchProp } = useLanguageRelevantData();
  const dispatch = useAppDispatch();
  const { linkingArtifacts, type } = useAppSelector((state) => state.linkingArtifacts);
  const partition = useAppSelector((state) => state.toggleMuseum.partition);
  const [triggerFolderPath] = useLazyFetchFolderPathQuery();
  const [triggerFolderDetail] = useLazyFetchFolderDetailQuery();
  const { isError, refresh, readMore, isFetching, data } = useInfiniteLoading<
    FolderResponseDto,
    ListApiResponse<FolderResponseDto>,
    FetchChildrenFolderQueryData
  >(
    useFetchFolderChildrenQuery,
    (state) => ({
      ...state,
      folderId,
      sortFields: [matchProp('captionRu', 'captionEn')],
      sortDirection: SortDirection.ASC,
      partition,
    }),
    (result) => result,
    { pageSize: 30 },
  );

  const handleClick = (folder: FolderResponseDto) => {
    dispatch(linkingArtifactActions.selectFolderId(folder.id));
  };

  useEffect(() => {
    refresh();
  }, [folderId]);

  const handleRefresh = () => {
    refresh();

    for (const x of linkingArtifacts) {
      if (type === 'layout') {
        dispatch(foldersApi.util.invalidateTags([{ type: 'LayoutFolders', id: x.entityId }]));
      } else if (type === 'image') {
        dispatch(foldersApi.util.invalidateTags([{ type: 'ImageFolders', id: x.entityId }]));
      }
    }

    triggerFolderPath(folderId);
    triggerFolderDetail(folderId);
  };

  const isEmpty = !isFetching && !isError && data?.length === 0;

  return (
    <div className={styles.root}>
      {isError ? (
        <FolderLoadingError onReload={handleRefresh} />
      ) : (
        <Virtuoso
          className={styles.list}
          data={data}
          overscan={200}
          endReached={readMore}
          itemContent={(_, folder) => <FolderListItem onClick={() => handleClick(folder)} folder={folder} />}
          components={{
            List: List,
            EmptyPlaceholder: isEmpty ? LinkingArtifactEmptyFolder : undefined,
            Footer: isFetching ? () => <FolderArtifactListFooter /> : undefined,
          }}
        />
      )}
    </div>
  );
};
