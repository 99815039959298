import { skipToken } from '@reduxjs/toolkit/query';
import { useTranslation } from 'react-i18next';
import { useFetchFolderDetailQuery } from 'features/folders';
import { favoritesAccessRuleService, layoutAccessRuleService } from 'features/role';
import { Icon } from 'ui-library/components/Icon';
import { ActionListItem, ActionListItemVariant } from 'ui-library/v1/components/ActionList';
import { LayoutResponseDto } from '../layoutEntity';
import { useLayoutActionHandlers } from './useLayoutActionHandlers';

export type UseLayoutActionsOptions = {
  layout: LayoutResponseDto;
  parentId: string;
};

export const useLayoutActions = (options: UseLayoutActionsOptions) => {
  const { t } = useTranslation();
  const { layout, parentId } = options;
  const { data: selectedFolderData } = useFetchFolderDetailQuery(parentId ?? skipToken);
  const {
    handleAddToFavorite,
    handleEdit,
    handleMove,
    handleLink,
    handleRemoveFromFavorite,
    handleUnlink,
    handleDelete,
  } = useLayoutActionHandlers({
    layout,
    parentId,
    folder: selectedFolderData,
  });

  const actions: Array<ActionListItem> = [
    {
      label: t('actions.edit'),
      onClick: handleEdit,
      hasAccess: () => layoutAccessRuleService.canEdit(),
      startAdornment: <Icon name='edit' />,
    },
    {
      label: t('actions.move'),
      onClick: handleMove,
      hasAccess: () => layoutAccessRuleService.canMove(),
      startAdornment: <Icon name='toFolder' />,
    },
    {
      label: t('actions.link'),
      onClick: handleLink,
      hasAccess: () => layoutAccessRuleService.canLink(),
      startAdornment: <Icon name='link' />,
    },
  ];

  if (layout.foldersCount > 1) {
    actions.push({
      label: t('actions.unlink'),
      onClick: handleUnlink,
      hasAccess: () => layoutAccessRuleService.canUnLink(),
      startAdornment: <Icon name='unLink' />,
    });
  }

  if (layout?.isFavourite) {
    actions.push({
      label: t('actions.removeFromFavorite'),
      onClick: handleRemoveFromFavorite,
      hasAccess: () => favoritesAccessRuleService.canDelete(),
      startAdornment: <Icon name='favoriteDelete' />,
    });
  } else {
    actions.push({
      label: t('actions.addToFavorite'),
      onClick: handleAddToFavorite,
      hasAccess: () => favoritesAccessRuleService.canCreate(),
      startAdornment: <Icon name='favorite' />,
    });
  }

  actions.push({
    label: t('actions.remove'),
    onClick: handleDelete,
    variant: ActionListItemVariant.DANGER,
    hasAccess: () => layoutAccessRuleService.canDelete(),
    startAdornment: <Icon name='trash' />,
  });

  return { actions };
};
