import { FunctionComponent } from 'react';
import { Icon } from 'ui-library/components/Icon';
import { ZeroScreenWrapper } from 'ui-library/components/ZeroScreen';
import { AutocompleteSearchProps } from './AutocompleteSearchProps';

export const AutocompleteEmptySearch: FunctionComponent<AutocompleteSearchProps> = (props) => {
  const { className, theme, message } = props;

  return (
    <ZeroScreenWrapper
      theme={theme}
      className={className}
      renderIcon={(props) => <Icon name='searchEmptyIllustration' size='none' {...props} />}
      message={message}
    />
  );
};
