import { FunctionComponent } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import {
  FileArtifactEntity,
  FileDownloadImageFormat,
  shareFileDownloadTaskActions,
  useDownloadFilesMutation,
} from 'features/file';
import { NotDefinedParamException } from 'infrastructure/exceptions';
import { useCurrentLanguage } from 'infrastructure/i18n';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux';
import { getDownloadDetailRoute } from 'infrastructure/routes/paths';
import { Modal } from 'ui-library/components/Modal';
import { FileFormatCardList } from '../FileFormatCardList/FileFormatCardList';
import styles from './ShareFileDownloadTask.module.scss';

export const ShareFileDownloadTask: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const currentLang = useCurrentLanguage();
  const { isOpen, selectedArtifact } = useAppSelector((state) => state.shareFileDownloadTask);
  const { t } = useTranslation();
  const [trigger] = useDownloadFilesMutation();

  const handleClose = () => {
    dispatch(shareFileDownloadTaskActions.setFileArtifact(undefined));
    dispatch(shareFileDownloadTaskActions.close());
  };

  const handleGetLink = async (imageFormat: FileDownloadImageFormat, artifact: FileArtifactEntity) => {
    const result = await trigger({
      language: currentLang,
      imageFormat,
      artifactID: artifact.entityId,
      artifactType: artifact.type,
      fetch: ['file', 'metadata_excel'],
    }).unwrap();

    const link = `${window.location.origin}${getDownloadDetailRoute(result.taskID)}`;

    return link;
  };

  const handleCopySuccess = () => {
    toast.success(t('files.shareDownloadTask.linkCopySuccess'));
    handleClose();
  };

  const handleCopyFailed = () => {
    toast.error(t('files.shareDownloadTask.linkCopyFailed'));
  };

  const getBlobLink = async (imageFormat: FileDownloadImageFormat, artifact: FileArtifactEntity) => {
    const link = await handleGetLink(imageFormat, artifact);

    return new Blob([link], {
      type: 'text/plain',
    });
  };

  const handleDownload = async (imageFormat: FileDownloadImageFormat) => {
    if (!selectedArtifact) {
      throw new NotDefinedParamException('artifact is not selected');
    }

    if (window.ClipboardItem) {
      const item = [
        new ClipboardItem({
          'text/plain': getBlobLink(imageFormat, selectedArtifact),
        }),
      ];

      navigator.clipboard.write(item).then(handleCopySuccess).catch(handleCopyFailed);
    } else {
      const link = await handleGetLink(imageFormat, selectedArtifact);

      if (!link) {
        toast.error(t('files.shareDownloadTask.linkCopyFailed'));
        return;
      }

      navigator.clipboard.writeText(link).then(handleCopySuccess).catch(handleCopyFailed);
    }
  };

  return (
    <Modal variant='popup' open={isOpen} onClose={handleClose} closeIconName='cross'>
      <Modal.Title>{t('files.shareDownloadTask.title')}</Modal.Title>
      <Modal.Content className={styles.root}>
        <FileFormatCardList onChoose={handleDownload} />
      </Modal.Content>
    </Modal>
  );
};
