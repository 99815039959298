import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { foldersApi } from 'features/folders';
import { imagesApi } from 'features/images';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux';
import styles from './useChangeOrder.module.scss';

export const useChangeOrder = () => {
  const { t } = useTranslation();
  const { changedArtifacts } = useAppSelector((state) => state.changeOrder);
  const dispatch = useAppDispatch();

  const buildSuccessToast = (content: JSX.Element) => {
    toast.success(<div className={styles.notification}>{content}</div>);
  };

  const buildErrorToast = (content: JSX.Element) => {
    toast.error(<div className={styles.notification}>{content}</div>);
  };

  const handleOrderSuccessful = () => {
    if (changedArtifacts?.type === 'image') {
      dispatch(imagesApi.util.invalidateTags(['ImageGallery']));
      dispatch(imagesApi.util.invalidateTags(['FolderImages']));
      buildSuccessToast(<>{t('changeOrder.successfulImageToast')}</>);
    }
    if (changedArtifacts?.type === 'folder') {
      dispatch(foldersApi.util.invalidateTags(['FolderChildren']));
      buildSuccessToast(<>{t('changeOrder.successfulFolderToast')}</>);
    }
  };

  const handleOrderError = () => {
    if (changedArtifacts?.type === 'image') {
      buildErrorToast(<>{t('changeOrder.errorToastImage')}</>);
    }
    if (changedArtifacts?.type === 'folder') {
      buildErrorToast(<>{t('changeOrder.errorToastFolder')}</>);
    }
  };

  return {
    handleOrderSuccessful,
    handleOrderError,
  };
};
