import cn from 'classnames';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './FavoriteAllGroupsHeaderInfo.module.scss';
import { FavoriteAllGroupsHeaderInfoProps } from './FavoriteAllGroupsHeaderInfoProps';

export const FavoriteAllGroupsHeaderInfo: FunctionComponent<FavoriteAllGroupsHeaderInfoProps> = (props) => {
  const { t } = useTranslation();
  const { className } = props;

  return (
    <div className={cn(styles.root, className)}>
      <h1 className={styles.title}>{t('favorites.group.all')}</h1>
    </div>
  );
};
