import { FunctionComponent } from 'react';
import { LayoutView } from 'features/layouts';
import styles from './LayoutDetailInfoPanel.module.scss';
import { LayoutDetailInfoPanelProps } from './LayoutDetailInfoPanelProps';

export const LayoutDetailInfoPanel: FunctionComponent<LayoutDetailInfoPanelProps> = (props) => {
  const { layout } = props;

  return (
    <div className={styles.root}>
      <LayoutView className={styles.info} layout={layout} />
    </div>
  );
};
