import { PasswordValidations } from 'features/users/types';
import { TranslateKey } from 'infrastructure/i18n';

type Rule = {
  validation: PasswordValidations;
  label: TranslateKey;
};

export const rules: Array<Rule> = [
  {
    validation: PasswordValidations.MIN_LEN,
    label: 'users.passwordRules.minLen',
  },
  {
    validation: PasswordValidations.UPPERCASE_LETTER,
    label: 'users.passwordRules.uppercaseLetter',
  },
  {
    validation: PasswordValidations.LOWERCASE_LETTER,
    label: 'users.passwordRules.lowercaseLetter',
  },
  {
    validation: PasswordValidations.ONE_DIGIT,
    label: 'users.passwordRules.oneDigit',
  },
];
