import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Footer.module.scss';

export const Footer: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <footer className={styles.root}>
      <span className={styles.copyright}>{t('footer.copyright')}</span>
    </footer>
  );
};
