import { FunctionComponent } from 'react';
import { FolderCardRoot } from 'features/folders';
import { CardVariant } from 'ui-library/components/Card';
import { Skeleton } from 'ui-library/v1/components/Skeleton';
import styles from './FolderCardListSkeleton.module.scss';

const amountOfCards = 5;

export const FolderCardListSkeleton: FunctionComponent = () => {
  return (
    <>
      {[...Array(amountOfCards)].map((_, idx) => (
        <FolderCardRoot key={idx} className={styles.card} variant={CardVariant.TRANSPARENT}>
          <div>
            <Skeleton width={120} />
            <Skeleton className={styles.secondRow} width={80} />
          </div>
          <div>
            <Skeleton width={80} />
          </div>
        </FolderCardRoot>
      ))}
    </>
  );
};
