import cn from 'classnames';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useFolderActionHandlers } from 'features/folders';
import { favoritesAccessRuleService } from 'features/role';
import { ButtonKind } from 'ui-library/components/Button';
import { Icon } from 'ui-library/components/Icon';
import { IconButton, IconButtonVariant } from 'ui-library/v1/components/IconButton';
import styles from './FolderTableFavoriteButton.module.scss';
import { FolderTableFavoriteButtonProps } from './FolderTableFavoriteButtonProps';

export const FolderTableFavoriteButton: FunctionComponent<FolderTableFavoriteButtonProps> = (props) => {
  const { folder, className } = props;
  const isFavorite = folder.isFavourite;
  const { t } = useTranslation();
  const { handleAddToFavorite, handleRemoveFromFavorite } = useFolderActionHandlers(folder);

  return (
    <>
      {!isFavorite && favoritesAccessRuleService.canCreate() && (
        <IconButton
          className={cn(styles.notFavoriteButton)}
          variant={IconButtonVariant.TRANSPARENT}
          as={ButtonKind.BUTTON}
          title={t('actions.addToFavorite')}
          onClick={handleAddToFavorite}
        >
          <Icon className={cn(styles.notFavorite)} name='favoriteFilled' />
        </IconButton>
      )}
      {isFavorite && favoritesAccessRuleService.canDelete() && (
        <IconButton
          className={cn(styles.favoriteButton, className)}
          variant={IconButtonVariant.TRANSPARENT}
          as={ButtonKind.BUTTON}
          title={t('actions.removeFromFavorite')}
          onClick={handleRemoveFromFavorite}
        >
          <Icon className={cn(styles.favorite)} name='favoriteFilled' />
        </IconButton>
      )}
    </>
  );
};
