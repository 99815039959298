import cn from 'classnames';
import { CSSProperties, FunctionComponent } from 'react';
import styles from './Skeleton.module.scss';
import { SkeletonProps } from './SkeletonProps';

export const Skeleton: FunctionComponent<SkeletonProps> = (props) => {
  const { radius, width, height, className } = props;

  return (
    <div
      style={
        {
          '--border-radius': radius,
          '--width': width,
          '--height': height,
        } as CSSProperties
      }
      className={cn(className, styles.line)}
    ></div>
  );
};
