import { Controller, useFormContext } from 'react-hook-form';
import { RenderControl } from './RenderControl/RenderControl';
import { SelectFormControllerProps } from './SelectFormControllerProps';

export const SelectFormController = <Option, OptionValue = Option, IsMulti extends boolean = false>(
  props: SelectFormControllerProps<Option, OptionValue, IsMulti>,
) => {
  const { name, ...restProps } = props;

  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <RenderControl error={errors[name]?.message as string} onChange={onChange} value={value} {...restProps} />
      )}
    />
  );
};
