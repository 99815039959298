import { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { FolderCardListGrid, FolderCardListSkeleton, FolderEmptyChildren } from 'features/folders';
import { getFolderDetailRoute } from 'infrastructure/routes/paths';
import { FolderCard } from '../FolderCard/FolderCard';
import { FolderCardListProps } from './FolderCardListProps';

export const FolderCardList: FunctionComponent<FolderCardListProps> = (props) => {
  const { data, isFetching, groupId } = props;

  const isEmpty = data?.totalCount === 0;

  if (isEmpty) {
    return <FolderEmptyChildren />;
  }

  return (
    <FolderCardListGrid>
      {isFetching ? (
        <FolderCardListSkeleton />
      ) : (
        data?.content.map((x) => (
          <Link key={x.id} to={getFolderDetailRoute(x.id, x.partition)}>
            <FolderCard folder={x} groupId={groupId} />
          </Link>
        ))
      )}
    </FolderCardListGrid>
  );
};
