import { FunctionComponent } from 'react';
import { FavoriteButtonGroup } from 'features/favorites';
import { useLayoutActionHandlers } from 'features/layouts/hooks';
import { LayoutFavoriteButtonGroupProps } from './LayoutFavoriteButtonGroupProps';

export const LayoutFavoriteButtonGroup: FunctionComponent<LayoutFavoriteButtonGroupProps> = (props) => {
  const { layout, ...rest } = props;
  const { handleAddToFavorite, handleRemoveFromFavorite } = useLayoutActionHandlers({ layout, parentId: undefined });

  return (
    <FavoriteButtonGroup
      {...rest}
      onAddToFavorites={handleAddToFavorite}
      onRemoveFromFavorites={handleRemoveFromFavorite}
      isFavorite={!!layout?.isFavourite}
    />
  );
};
