import { setLocale } from 'yup';
import { LocaleObject } from 'yup/lib/locale';
import { NumberFormatter } from 'utils';
import { ValidationError, ValidationErrorKeyType, ValidationErrorValueType } from './validationError';

export const buildLocalization = (key: ValidationErrorKeyType, values?: ValidationErrorValueType): ValidationError => ({
  key,
  values,
});

const YUP_LOCALE: LocaleObject = {
  mixed: {
    required: buildLocalization('validation.required'),
    defined: buildLocalization('validation.required'),
    default: buildLocalization('validation.invalid.common'),
  },
  number: {
    max: ({ max }) =>
      buildLocalization('validation.number.max', {
        value: NumberFormatter.getLocalizedNumberString(max),
      }),
    min: ({ min }) =>
      buildLocalization('validation.number.min', {
        value: NumberFormatter.getLocalizedNumberString(min),
      }),
  },
  string: {
    email: buildLocalization('validation.invalid.email'),
    matches: buildLocalization('validation.invalid.common'),
    max: ({ max }) => buildLocalization('validation.string.max', { count: max }),
    min: ({ min }) => buildLocalization('validation.string.min', { count: min }),
  },
  date: {
    max: ({ max }) =>
      buildLocalization('validation.date.max', {
        date: max.toLocaleString(),
      }),
    min: ({ min }) =>
      buildLocalization('validation.date.min', {
        date: min.toLocaleString(),
      }),
  },
  array: {
    max: ({ max }) => buildLocalization('validation.array.max', { count: max }),
    min: ({ min }) => buildLocalization('validation.array.min', { count: min }),
  },
  object: {
    noUnknown: buildLocalization('validation.invalid.common'),
  },
};

export const initializeYup = () => {
  setLocale(YUP_LOCALE);
};
