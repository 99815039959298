import { useTranslation } from 'react-i18next';
import { useImageActionHandlers } from 'features/images/hooks/useImageActionHandlers';
import { imageApproverService } from 'features/images/services/imageApproverService';
import { imageAccessRuleService } from 'features/role';
import { Icon } from 'ui-library/components/Icon';
import { ActionListItem, ActionListItemVariant } from 'ui-library/v1/components/ActionList';
import { GalleryImageItemDto, ImageGalleryFlatListItem } from '../imageEntity';

export type UseImageActionsOptions = {
  image: GalleryImageItemDto;
  parentId: string;
  folder?: ImageGalleryFlatListItem;
};

export const useImageActions = (options: UseImageActionsOptions) => {
  const { image, parentId, folder } = options;
  const { handleEdit, handleDownload, handleLink, handleMove, handleUnlink, handleDelete } = useImageActionHandlers({
    image,
    parentId,
    folder,
  });
  const { t } = useTranslation();

  const actions: Array<ActionListItem> = [
    {
      label: t('actions.edit'),
      onClick: handleEdit,
      hasAccess: () => imageAccessRuleService.canEdit(),
      startAdornment: <Icon name='edit' />,
    },
    {
      label: t('actions.move'),
      onClick: handleMove,
      hasAccess: () => imageAccessRuleService.canMove(),
      startAdornment: <Icon name='toFolder' />,
    },
    {
      label: t('actions.link'),
      onClick: handleLink,
      hasAccess: () => imageAccessRuleService.canLink(),
      startAdornment: <Icon name='link' />,
    },
  ];

  actions.push({
    label: t('actions.unlink'),
    onClick: handleUnlink,
    hasAccess: () => imageApproverService.canUnlink(image),
    startAdornment: <Icon name='unLink' />,
  });

  actions.push(
    {
      label: t('actions.download'),
      onClick: handleDownload,
      hasAccess: () => imageAccessRuleService.canView(),
      startAdornment: <Icon name='share' />,
    },
    {
      label: t('actions.remove'),
      onClick: handleDelete,
      variant: ActionListItemVariant.DANGER,
      hasAccess: () => imageAccessRuleService.canDelete(),
      startAdornment: <Icon name='trash' />,
    },
  );

  return { actions };
};
