type UriComponent = string | number | boolean;
type RouteQueryValueType = UriComponent | undefined | null;

export class RoutePathBuilder {
  public static buildRoute(baseUrl: string, restUrl: string, appendSlash = true): string {
    return `${baseUrl}${appendSlash ? '/' : ''}${restUrl}`;
  }

  public static buildRouteWithParamNames<T>(baseUrl: string, paramsNames: Array<keyof T>): string {
    const bakedUrl = paramsNames.map((x) => `/:${String(x)}`).join('');

    return RoutePathBuilder.buildRoute(baseUrl, bakedUrl, false);
  }

  public static buildRouteWithParams(baseUrl: string, params: Array<string>): string {
    const bakedUrl = params.map((x) => `/${x}`).join('');

    return RoutePathBuilder.buildRoute(baseUrl, bakedUrl, false);
  }

  public static buildRouteWithQueryParams<T extends Record<string, RouteQueryValueType | Array<RouteQueryValueType>>>(
    baseUrl: string,
    params: T,
  ): string {
    const bakedUrl = `?${RoutePathBuilder.serialize(params)}`;

    return RoutePathBuilder.buildRoute(baseUrl, bakedUrl, false);
  }

  public static buildListRoute(baseUrl: string): string {
    return RoutePathBuilder.buildRoute(baseUrl, '');
  }

  public static buildCreateRoute(baseUrl: string): string {
    return RoutePathBuilder.buildRoute(baseUrl, 'create');
  }

  public static buildViewRoute(baseUrl: string): string {
    return RoutePathBuilder.buildRoute(baseUrl, 'view');
  }

  public static buildEditorRoute(baseUrl: string): string {
    return RoutePathBuilder.buildRoute(baseUrl, 'edit');
  }

  public static serialize(obj: Record<string, RouteQueryValueType | Array<RouteQueryValueType>>): string {
    const str = Object.keys(obj)
      .map((objKey) => {
        if (Array.isArray(obj[objKey])) {
          return RoutePathBuilder.serializeArray(objKey, obj[objKey] as Array<RouteQueryValueType>);
        } else {
          if (obj[objKey]) {
            const value = encodeURIComponent(obj[objKey] as UriComponent);

            return encodeURIComponent(objKey) + '=' + value;
          } else {
            return null;
          }
        }
      })
      .filter(Boolean);

    return str.join('&');
  }

  private static serializeArray(key: string, array: Array<RouteQueryValueType>): string {
    return array
      .map((value) => {
        if (value) {
          return encodeURIComponent(key) + '=' + encodeURIComponent(value);
        } else {
          return null;
        }
      })
      .filter(Boolean)
      .join('&');
  }
}
