import { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { FolderCardListGrid } from 'features/folders';
import { FolderCardListSkeleton } from 'features/rootFolders';
import { getFolderDetailRoute } from 'infrastructure/routes/paths';
import { RootFolderCard } from '../RootFolderCard/RootFolderCard';
import styles from './RootFolderCardList.module.scss';
import { RootFolderCardListProps } from './RootFolderCardListProps';

export const RootFolderCardList: FunctionComponent<RootFolderCardListProps> = (props) => {
  const { isFetching, data, groupId } = props;

  return (
    <div className={styles.root}>
      {isFetching ? (
        <FolderCardListSkeleton />
      ) : (
        <FolderCardListGrid>
          {data?.content.map((x) => (
            <Link key={x.id} to={getFolderDetailRoute(x.id, x.partition)}>
              <RootFolderCard folder={x} groupId={groupId} />
            </Link>
          ))}
        </FolderCardListGrid>
      )}
    </div>
  );
};
