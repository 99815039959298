import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonKind, ButtonVariant } from 'ui-library/components/Button';
import { Icon } from 'ui-library/components/Icon';
import { ZeroScreenWrapper } from 'ui-library/components/ZeroScreen';
import styles from './UserEmptyList.module.scss';

export const UserEmptyList: FunctionComponent = () => {
  const { t } = useTranslation();

  const handleAdd = () => {};

  return (
    <ZeroScreenWrapper
      className={styles.root}
      theme='dark'
      renderIcon={(props) => <Icon name='documentIllustration' size='none' {...props} />}
      renderButton={(props) => (
        <Button
          as={ButtonKind.BUTTON}
          variant={ButtonVariant.SECONDARY}
          onClick={handleAdd}
          size='small'
          startIcon={<Icon name='plus' />}
          {...props}
        >
          {t('actions.add')}
        </Button>
      )}
      message={t('users.emptyMessage')}
    />
  );
};
