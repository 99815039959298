import cn from 'classnames';
import { FunctionComponent, MouseEvent } from 'react';
import { List, ListItem, ListItemKind } from '../List';
import { ListItemIcon } from '../List/ItemIcon/ListItemIcon';
import { ListItemText } from '../List/ItemText/ListItemText';
import styles from './ActionList.module.scss';
import { ActionListItem, ActionListItemVariant, ActionListProps } from './ActionListProps';
import { getAvailableListActions } from './actionListUtils';

/**
 * @deprecated
 */
export const ActionList: FunctionComponent<ActionListProps> = (props) => {
  const { actions, className, onClose } = props;

  const handleClick = (x: ActionListItem, event: MouseEvent<HTMLElement>) => {
    x.onClick?.(event as never);

    onClose();
  };

  return (
    <List className={cn(styles.list, className)}>
      {getAvailableListActions(actions).map((x) => (
        <ListItem
          className={cn(styles.listItem, {
            [styles.listItemDanger]: x.variant === ActionListItemVariant.DANGER,
          })}
          key={x.label}
          padding='large'
          kind={(x.kind ?? ListItemKind.LI) as never}
          onClick={(event: never) => handleClick(x, event)}
        >
          {x.icon && <ListItemIcon className={styles.icon}>{x.icon}</ListItemIcon>}
          <ListItemText primary={x.label} classes={{ primary: styles.label }} />
        </ListItem>
      ))}
    </List>
  );
};
