import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ListLayout } from 'features/list';

export type SearchListState = {
  layoutType: ListLayout;
};

const initialState: SearchListState = {
  layoutType: ListLayout.CARD,
};

const searchListSlice = createSlice({
  name: 'searchList',
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    changeLayoutType: (state, action: PayloadAction<ListLayout>) => {
      state.layoutType = action.payload;
    },
  },
});

export const searchListActions = searchListSlice.actions;
export const searchListReducer = searchListSlice.reducer;
