import cn from 'classnames';
import { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FolderPartition } from 'features/folders/folderEntity';
import { Icon } from 'ui-library/components/Icon';
import { IconButton } from 'ui-library/components/IconButton';
import { ActionList, ActionListItem } from 'ui-library/v1/components/ActionList';
import { ButtonKind } from 'ui-library/v1/components/Button';
import { Popover, PopoverContent, PopoverTrigger } from 'ui-library/v1/components/Popover';
import styles from './ChooseCreateArtifactButton.module.scss';
import { ChooseCreateArtifactButtonProps } from './ChooseCreateArtifactButtonProps';

export const ChooseCreateArtifactButton: FunctionComponent<ChooseCreateArtifactButtonProps> = (props) => {
  const { className, onCreateImage, onCreateFolder, onCreateLayout, partition } = props;
  const [isOpen, setOpen] = useState(false);
  const { t } = useTranslation();

  const actions: Array<ActionListItem> = [
    {
      label: t('createArtifacts.action.folder'),
      onClick: onCreateFolder,
      startAdornment: <Icon name='folderAlt' />,
    },
  ];

  if (partition === FolderPartition.MUSEUM) {
    actions.push({
      onClick: onCreateImage,
      label: t('createArtifacts.action.image'),
      startAdornment: <Icon name='image' />,
    });
  }

  if (partition === FolderPartition.ARCHIVE) {
    actions.push({
      onClick: onCreateLayout,
      label: t('createArtifacts.action.layout'),
      startAdornment: <Icon name='layout' />,
    });
  }

  return (
    <Popover behavior='click' placement={'bottom-end'} offset={8} open={isOpen} onOpenChange={setOpen}>
      <PopoverTrigger>
        <IconButton className={cn(className)} as={ButtonKind.BUTTON}>
          <Icon name='plus' />
        </IconButton>
      </PopoverTrigger>
      <PopoverContent portal>
        {({ close }) => (
          <div>
            <ActionList actions={actions} onClose={close} className={styles.action} />
          </div>
        )}
      </PopoverContent>
    </Popover>
  );
};
