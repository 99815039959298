import { createApi } from '@reduxjs/toolkit/query/react';
import { fetchBaseAuthQuery, HTTP_METHODS, URL_FACTORY } from 'infrastructure/network';
import {
  GetTagsListRequestData,
  SearchTagsRequestData,
  TagDeleteRequestDto,
  TagEditRequestDto,
  TagResponseDto,
  TagsCreateRequestDto,
} from './tagEntity';

export const tagApi = createApi({
  reducerPath: 'tagApi',
  tagTypes: ['SearchTags'],
  baseQuery: fetchBaseAuthQuery(),
  endpoints: (builder) => ({
    searchTags: builder.query<Array<TagResponseDto>, SearchTagsRequestData>({
      query: (params) => {
        const { query } = params;

        return {
          url: URL_FACTORY.TAGS.SEARCH(),
          method: HTTP_METHODS.POST,
          body: {
            query,
          },
        };
      },
      providesTags: ['SearchTags'],
    }),
    getTagsList: builder.query<Array<TagResponseDto>, GetTagsListRequestData>({
      query: (params) => {
        const { IDs } = params;

        return {
          url: URL_FACTORY.TAGS.LIST(),
          method: HTTP_METHODS.POST,
          body: {
            IDs,
          },
        };
      },
    }),
    createTags: builder.mutation<Array<TagResponseDto>, TagsCreateRequestDto>({
      query: (data) => ({
        url: URL_FACTORY.TAGS.ROOT,
        method: HTTP_METHODS.POST,
        body: data,
      }),
      invalidatesTags: (result, error) => (error ? [] : ['SearchTags']),
    }),
    editTag: builder.mutation<boolean, TagEditRequestDto>({
      query: ({ id, nameRu, nameEn }) => ({
        url: URL_FACTORY.TAGS.DETAIL(id),
        method: HTTP_METHODS.PATCH,
        body: {
          nameRu,
          nameEn,
        },
      }),
      invalidatesTags: (result, error) => (error ? [] : ['SearchTags']),
    }),
    deleteTag: builder.mutation<boolean, TagDeleteRequestDto>({
      query: ({ id }) => ({
        url: URL_FACTORY.TAGS.DETAIL(id),
        method: HTTP_METHODS.DELETE,
      }),
      invalidatesTags: (result, error) => (error ? [] : ['SearchTags']),
    }),
  }),
});

export const {
  useSearchTagsQuery,
  useLazyGetTagsListQuery,
  useCreateTagsMutation,
  useEditTagMutation,
  useDeleteTagMutation,
} = tagApi;
