import { ButtonKindProps, InnerAnchorKindProps, OuterAnchorKindProps } from '../Button';

export enum IconButtonVariant {
  MAIN = 'main',
  SECONDARY = 'secondary',
  TRANSPARENT = 'transparent',
}

export type IconButtonSize = 'small' | 'normal' | 'large';

/**
 * @deprecated
 */
export type IconButtonProps = (ButtonKindProps | OuterAnchorKindProps | InnerAnchorKindProps) & CommonProps;

type CommonProps = {
  variant?: IconButtonVariant;
  size?: IconButtonSize;
};
