import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { ListLayout } from 'features/list';
import { ErrorLoadingRoot } from 'features/rootFolders';
import { searchRootFolderActions } from 'features/search/slices';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux';
import { Pagination } from 'ui-library/components/Pagination';
import { EmptySearch } from '../EmptySearch/EmptySearch';
import { RootFolderCardList } from './RootFolderCardList/RootFolderCardList';
import styles from './RootFoldersResult.module.scss';
import { RootFoldersResultProps } from './RootFoldersResultProps';
import { RootFolderTable } from './RootFolderTable/RootFolderTable';

export const RootFoldersResult: FunctionComponent<RootFoldersResultProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { currentPage, pageSize } = useAppSelector((state) => state.searchRootFolder.paginationData);

  const { query, layoutType } = props;
  const isEmpty = !query.isError && query.data?.totalCount === 0;

  const layoutView =
    layoutType === ListLayout.CARD ? <RootFolderCardList {...query} /> : <RootFolderTable {...query} />;

  return (
    <div className={styles.root}>
      <div className={styles.listWrap}>
        <>
          {isEmpty ? <EmptySearch className={styles.placeholder} message={t('search.emptyRootFolders')} /> : layoutView}
          {query.isError && <ErrorLoadingRoot className={styles.placeholder} onReload={query.refetch} />}
        </>
      </div>
      <div className={styles.paginationWrap}>
        <Pagination
          totalItems={query.data?.totalCount}
          pageSize={pageSize}
          onPageChanged={(page) => dispatch(searchRootFolderActions.pageChanged({ page }))}
          currentPage={currentPage}
          scrollToStartAfterPageChanged
        />
      </div>
    </div>
  );
};
