import { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { LayoutResponseDto } from 'features/layouts';
import { useLayoutActionHandlers } from 'features/layouts/hooks';
import { favoritesAccessRuleService } from 'features/role';
import { NotDefinedParamException } from 'infrastructure/exceptions';
import { Icon } from 'ui-library/components/Icon';
import { ActionListItem } from 'ui-library/v1/components/ActionList';
import { FavoriteArtifactEntity } from '../favoriteEntity';
import { useDeleteFavoriteMutation } from '../favoritesApi';
import { useInvalidateFavoriteRelatedEntities } from './useInvalidateFavoriteRelatedEntities';
import { useRemoveArtifactFromFavoriteGroup } from './useRemoveArtifactFromFavoriteGroup';
import { useRemoveArtifactFromFavoriteToast } from './useRemoveArtifactFromFavoriteToast';

type UseLayoutActionsOptions = {
  layout: LayoutResponseDto;
  groupId?: string;
};

export const useLayoutActions = (options: UseLayoutActionsOptions) => {
  const { layout, groupId } = options;
  const { t } = useTranslation();
  const { handleAddToFavorite } = useLayoutActionHandlers({
    layout: layout,
    parentId: groupId,
  });
  const favoriteArtifact: FavoriteArtifactEntity = {
    type: 'layout',
    entityId: layout.id,
    parentId: groupId,
    isFavorite: layout.isFavourite,
  };
  const { handleRemove: handleRemoveFromGroup } = useRemoveArtifactFromFavoriteGroup(favoriteArtifact, groupId);
  const [trigger] = useDeleteFavoriteMutation();
  const { invalidateEntities } = useInvalidateFavoriteRelatedEntities(favoriteArtifact);
  const { showToast } = useRemoveArtifactFromFavoriteToast(favoriteArtifact);

  const handleRemoveFromFavorite = async (event: SyntheticEvent) => {
    event.stopPropagation();
    event.preventDefault();

    if (!layout) {
      throw new NotDefinedParamException('favorite layout');
    }

    try {
      await trigger({
        artifactType: 'layout',
        artifactID: layout.id,
      }).unwrap();
      invalidateEntities();
      showToast('Success');
    } catch (e) {
      showToast('Failed');
    }
  };

  const handleRemoveFromFavoriteGroup = (event: SyntheticEvent) => {
    event.stopPropagation();
    event.preventDefault();

    handleRemoveFromGroup();
  };

  const actions: Array<ActionListItem> = [
    {
      label: groupId ? t('actions.addToAnotherFavoriteGroup') : t('actions.addToFavoriteGroup'),
      onClick: handleAddToFavorite,
      hasAccess: () => favoritesAccessRuleService.canCreate(),
      startAdornment: <Icon name='folderAlt' />,
    },
    {
      label: groupId ? t('actions.removeFromFavoriteGroup') : t('actions.removeFromFavorite'),
      onClick: groupId ? handleRemoveFromFavoriteGroup : handleRemoveFromFavorite,
      hasAccess: () => favoritesAccessRuleService.canDelete(),
      startAdornment: <Icon name={groupId ? 'collectionDelete' : 'favoriteDelete'} />,
    },
  ];

  return { actions };
};
