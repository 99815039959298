import React from 'react';
import { usePopover } from './usePopover';

type ContextType =
  | (ReturnType<typeof usePopover> & {
      setLabelId: React.Dispatch<React.SetStateAction<string | undefined>>;
    })
  | null;

export const PopoverContext = React.createContext<ContextType>(null);

export const usePopoverState = () => {
  const context = React.useContext(PopoverContext);

  if (context == null) {
    throw new Error('Popover components must be wrapped in <Popover />');
  }

  return context;
};
