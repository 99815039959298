import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getDefaultModalState, getModalReducers, ModalState } from 'features/modal';

type DeleteLayoutState = ModalState & {
  layoutID?: string;
};

const initialState: DeleteLayoutState = {
  ...getDefaultModalState(),
  layoutID: undefined,
};

const layoutDeleteSlice = createSlice({
  name: 'layoutDelete',
  initialState,
  reducers: {
    ...getModalReducers<DeleteLayoutState>((state) => state),
    setLayoutForDeletion: (state, action: PayloadAction<string | undefined>) => {
      state.layoutID = action.payload;
    },
  },
});

export const layoutDeleteActions = layoutDeleteSlice.actions;
export const layoutDeleteReducer = layoutDeleteSlice.reducer;
