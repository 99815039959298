import { createApi } from '@reduxjs/toolkit/query/react';
import { listQuery } from 'features/list';
import {
  fetchBaseAuthQuery,
  HTTP_METHODS,
  ListApiFetchArgs,
  ListApiResponse,
  URL_FACTORY,
} from 'infrastructure/network';
import {
  FavoriteDeleteRequestDto,
  FavoriteGroupCreateRequestDto,
  FavoriteGroupDto,
  FavoriteGroupEditRequestDto,
  FavoriteRequestDto,
  FavoriteResponseDto,
  FavoriteUpdateRequestDto,
} from './favoriteEntity';

export const favoritesApi = createApi({
  reducerPath: 'favoritesApi',
  baseQuery: fetchBaseAuthQuery(),
  tagTypes: ['FavoriteGroup', 'FavoriteContent', 'FavoriteGroups'],
  endpoints: (builder) => ({
    fetchFavorites: builder.query<FavoriteResponseDto, FavoriteRequestDto>({
      query: (body) => ({
        url: URL_FACTORY.FAVORITES.CONTENTS(),
        method: HTTP_METHODS.POST,
        body,
      }),
      providesTags: () => ['FavoriteContent'],
    }),
    fetchGroups: builder.query<ListApiResponse<FavoriteGroupDto>, ListApiFetchArgs<FavoriteGroupDto>>({
      query: (params) =>
        listQuery({
          url: URL_FACTORY.FAVORITES.GROUPS(),
          params,
        }),
      providesTags: (result) =>
        result
          ? [
              'FavoriteGroups',
              ...result.content.map(({ id }) => ({
                type: 'FavoriteGroup' as const,
                id,
              })),
            ]
          : ['FavoriteGroups'],
    }),
    fetchGroup: builder.query<FavoriteGroupDto, string>({
      query: (groupID) => URL_FACTORY.FAVORITES.GROUP_DETAIL(groupID),
      providesTags: (result, error, groupID) => (error ? [] : [{ type: 'FavoriteGroup', id: groupID }]),
    }),
    createGroup: builder.mutation<unknown, FavoriteGroupCreateRequestDto>({
      query: (data) => ({
        url: URL_FACTORY.FAVORITES.GROUPS(),
        method: HTTP_METHODS.POST,
        body: data,
      }),
      invalidatesTags: () => ['FavoriteGroups'],
    }),
    editGroup: builder.mutation<unknown, FavoriteGroupEditRequestDto>({
      query: ({ groupID, ...restData }) => ({
        url: URL_FACTORY.FAVORITES.GROUP_DETAIL(groupID),
        method: HTTP_METHODS.PATCH,
        body: restData,
      }),
      invalidatesTags: (_result, _error, args) => [
        'FavoriteGroups',
        { type: 'FavoriteGroup' as const, id: args.groupID },
      ],
    }),
    deleteGroup: builder.mutation<unknown, string>({
      query: (favouritesGroupID) => ({
        url: URL_FACTORY.FAVORITES.GROUP_DETAIL(favouritesGroupID),
        method: HTTP_METHODS.DELETE,
      }),
      invalidatesTags: () => ['FavoriteGroups'],
    }),
    updateFavorites: builder.mutation<unknown, FavoriteUpdateRequestDto>({
      query: (data) => ({
        url: URL_FACTORY.FAVORITES.ROOT,
        method: HTTP_METHODS.POST,
        body: data,
      }),
      invalidatesTags: (_result, _error, args) => [
        'FavoriteContent',
        ...(args.groupIDsToAddTo ?? []).map((groupId) => ({
          type: 'FavoriteGroup' as const,
          id: groupId,
        })),
        ...(args.groupIDsToRemoveFrom ?? []).map((groupId) => ({
          type: 'FavoriteGroup' as const,
          id: groupId,
        })),
      ],
    }),
    deleteFavorite: builder.mutation<unknown, FavoriteDeleteRequestDto>({
      query: (data) => ({
        url: URL_FACTORY.FAVORITES.ROOT,
        method: HTTP_METHODS.DELETE,
        body: data,
      }),
      invalidatesTags: () => ['FavoriteContent'],
    }),
  }),
});

export const {
  useFetchGroupsQuery,
  useFetchGroupQuery,
  useFetchFavoritesQuery,
  useCreateGroupMutation,
  useUpdateFavoritesMutation,
  useDeleteFavoriteMutation,
  useEditGroupMutation,
  useDeleteGroupMutation,
} = favoritesApi;
