import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FolderEditLinkedImagesRequestDto,
  FolderEditRequestDto,
  useEditFolderLinkedImagesMutation,
  useEditFolderMutation,
  useFetchFolderDetailQuery,
} from 'features/folders';
import { useFetchFolderImagesQuery } from 'features/images';
import { LayoutEditRequestDto, useEditLayoutMutation, useFetchFolderLayoutsQuery } from 'features/layouts';
import { movingArtifactsActions, useMoveArtifact } from 'features/movingArtifacts';
import { NotDefinedParamException } from 'infrastructure/exceptions';
import { useLanguageRelevantData } from 'infrastructure/i18n';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux';
import { ActionButton } from 'ui-library/components/ActionButton';
import { ButtonKind, ButtonVariant } from 'ui-library/components/Button';
import styles from './MovingArtifactNavigatorActions.module.scss';

export const MovingArtifactNavigatorActions = () => {
  const dispatch = useAppDispatch();
  const { matchProp } = useLanguageRelevantData();
  const { t } = useTranslation();
  const { selectedFolderId, movingArtifacts, type } = useAppSelector((state) => state.movingArtifacts);
  const { data: selectedFolderData } = useFetchFolderDetailQuery(selectedFolderId ? selectedFolderId : skipToken);
  const shouldFetchLinkedLayoutsData = type === 'layout' && selectedFolderId;
  const shouldFetchLinkedImagesData = type === 'image' && selectedFolderId;
  const singleArtifact = movingArtifacts.length === 1 && movingArtifacts[0];

  const { data: linkedLayoutsData } = useFetchFolderLayoutsQuery(
    shouldFetchLinkedLayoutsData
      ? { folderID: selectedFolderId, pageSize: Number.MAX_SAFE_INTEGER, currentPage: 1 }
      : skipToken,
  );
  const { data: linkedImagesData } = useFetchFolderImagesQuery(
    shouldFetchLinkedImagesData
      ? { folderID: selectedFolderId, pageSize: Number.MAX_SAFE_INTEGER, currentPage: 1 }
      : skipToken,
  );
  const [editLayoutMutation] = useEditLayoutMutation();
  const [editFolderMutation] = useEditFolderMutation();
  const [editFolderLinkedImagesMutation] = useEditFolderLinkedImagesMutation();
  const {
    handleMovingFolder,
    handleMovingFolderError,
    handleMovingLayout,
    handleMovingLayoutError,
    handleMovingImage,
    handleMovingImageError,
  } = useMoveArtifact();

  const handleCancel = () => {
    dispatch(movingArtifactsActions.dispose());
  };

  const isSameFolderDestination = useMemo(() => {
    return (
      (type === 'folder' || type === 'rootFolder') && movingArtifacts.every((x) => x.parentID === selectedFolderId)
    );
  }, [movingArtifacts, selectedFolderId, type]);

  const isSameLayoutDestination = useMemo(
    () => movingArtifacts.every((x) => linkedLayoutsData?.content.some((layout) => layout.id === x.entityId)),
    [linkedLayoutsData?.content, movingArtifacts],
  );

  const isSameImageDestination = useMemo(
    () => movingArtifacts.every((x) => linkedImagesData?.content.some((layout) => layout.id === x.entityId)),
    [linkedImagesData?.content, movingArtifacts],
  );

  const isLayoutInRoot = type === 'layout' && selectedFolderId === undefined;
  const isImageInRoot = type === 'image' && selectedFolderId === undefined;
  const isSameDestination = isSameFolderDestination || isSameLayoutDestination || isSameImageDestination;

  const canSubmit = () => {
    return !(isLayoutInRoot || isImageInRoot || isSameDestination);
  };

  const handleMoveLayout = async () => {
    if (!singleArtifact) {
      throw new NotDefinedParamException('Layout');
    }

    const dtoLayout: LayoutEditRequestDto = {
      layoutID: singleArtifact.entityId,
      folderIDsToAdd: selectedFolderId ? [selectedFolderId] : [],
      folderIDsToRemove: singleArtifact.parentID ? [singleArtifact.parentID] : [],
    };

    try {
      await editLayoutMutation(dtoLayout).unwrap();
      handleMovingLayout();
    } catch (e) {
      handleMovingLayoutError();
    }
  };

  const handleMoveImage = async () => {
    if (!movingArtifacts || movingArtifacts.length === 0) {
      throw new NotDefinedParamException('images');
    }

    if (!movingArtifacts[0].parentID) {
      throw new NotDefinedParamException('parent ID');
    }

    if (!selectedFolderId) {
      throw new NotDefinedParamException('selected folder');
    }

    const dtoImage: FolderEditLinkedImagesRequestDto = {
      imageIDs: movingArtifacts.map((x) => x.entityId),
      folderID: selectedFolderId,
      correspondingFolderID: movingArtifacts[0].parentID,
      unlinkFromCorrespondingFolder: true,
    };

    try {
      await editFolderLinkedImagesMutation(dtoImage).unwrap();
      handleMovingImage();
    } catch (e) {
      handleMovingImageError();
    }
  };

  const handleMoveFolder = async () => {
    if (!singleArtifact) {
      throw new NotDefinedParamException('Folder');
    }

    const dtoFolder: FolderEditRequestDto = {
      folderID: singleArtifact.entityId,
      parentID: selectedFolderId,
      isRoot: !selectedFolderId,
    };

    try {
      await editFolderMutation(dtoFolder).unwrap();
      handleMovingFolder();
    } catch (e) {
      handleMovingFolderError();
    }
  };

  const handleMove = async () => {
    if (type === 'folder' || type === 'rootFolder') {
      await handleMoveFolder();
    } else if (type === 'image') {
      await handleMoveImage();
    } else if (type === 'layout') {
      await handleMoveLayout();
    }
  };

  const getButtonTooltip = () => {
    if (isSameDestination) {
      return t('movingArtifacts.error.sameFolder');
    }

    return undefined;
  };

  return (
    <div className={styles.root}>
      {isSameImageDestination && (
        <span className={styles.text}>
          {t(
            selectedFolderData?.isRoot
              ? 'movingArtifacts.actions.alreadyLinkImageToRoot'
              : 'movingArtifacts.actions.alreadyLinkImageToFolder',
            {
              name: matchProp(selectedFolderData?.captionRu, selectedFolderData?.captionEn),
              count: movingArtifacts.length,
            },
          )}
        </span>
      )}
      {isSameLayoutDestination && (
        <span className={styles.text}>
          {t(
            selectedFolderData?.isRoot
              ? 'movingArtifacts.actions.alreadyLinkLayoutToRoot'
              : 'movingArtifacts.actions.alreadyLinkLayoutToFolder',
            {
              name: matchProp(selectedFolderData?.captionRu, selectedFolderData?.captionEn),
              count: movingArtifacts.length,
            },
          )}
        </span>
      )}
      <div className={styles.buttonGroup}>
        <ActionButton as={ButtonKind.BUTTON} disabled={!canSubmit()} onClick={handleMove} title={getButtonTooltip()}>
          {t('movingArtifacts.actions.move')}
        </ActionButton>
        <ActionButton as={ButtonKind.BUTTON} variant={ButtonVariant.SECONDARY} onClick={handleCancel}>
          {t('actions.cancel')}
        </ActionButton>
      </div>
    </div>
  );
};
