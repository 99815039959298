import { FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';
import { userAccessRuleService } from 'features/role';
import { RootFolderDataView } from 'features/rootFolders';
import { ToggleMuseumRouteQueryParams } from 'features/toggleMuseum';
import { SearchBar } from 'features/v1/search/SearchBar';
import { NotDefinedParamException } from 'infrastructure/exceptions';
import { useScrollToTop } from 'infrastructure/routes';
import styles from './GalleryScreen.module.scss';

export const GalleryScreen: FunctionComponent = () => {
  const { partition } = useParams<ToggleMuseumRouteQueryParams>();

  if (!partition) {
    throw new NotDefinedParamException('Folder Partition');
  }

  useScrollToTop();

  return (
    <div className={styles.root}>
      {userAccessRuleService.canViewUI() && (
        <div className={styles.instructionListWrap}>
          <SearchBar />
        </div>
      )}
      <RootFolderDataView partition={partition} />
    </div>
  );
};
