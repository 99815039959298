import { FunctionComponent, SyntheticEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { FolderResponseDto } from 'features/folders';
import { FolderTableSkeleton } from 'features/rootFolders';
import { useRootFolderTableColumns } from 'features/rootFolders/hooks';
import { getFolderDetailRoute } from 'infrastructure/routes/paths';
import { Table, TableBody, TableHead } from 'ui-library/components/Table';
import { RootFolderTableActions } from '../RootFolderTableActions/RootFolderTableActions';
import { RootFolderTableProps } from './RootFolderTableProps';

export const RootFolderTable: FunctionComponent<RootFolderTableProps> = (props) => {
  const { data, isFetching, groupId } = props;
  const navigate = useNavigate();
  const columns = useRootFolderTableColumns();

  const handleRowClick = (event: SyntheticEvent, item: FolderResponseDto) => {
    navigate(getFolderDetailRoute(item.id, item.partition));
  };

  return (
    <Table>
      <TableHead columns={columns} />
      {isFetching ? (
        <FolderTableSkeleton />
      ) : (
        <TableBody
          columns={columns}
          items={data?.content ?? []}
          getRowActions={(x, options) => (
            <RootFolderTableActions folder={x} groupId={groupId} className={options?.className} />
          )}
          onRowClick={handleRowClick}
        />
      )}
    </Table>
  );
};
