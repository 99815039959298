import cn from 'classnames';
import React, { FunctionComponent, useMemo } from 'react';
import { useAuth } from 'features/auth';
import { FilePreviewLabel } from 'features/file';
import { imageAccessRuleService } from 'features/role';
import { UserRole } from 'features/users';
import { useLanguageRelevantData } from 'infrastructure/i18n';
import { CardActionsMenu } from 'ui-library/v1/components/CardActionsMenu';
import { Image } from 'ui-library/v1/components/Image';
import { ImageCardFavoriteButton } from '../ImageCardFavoriteButton/ImageCardFavoriteButton';
import { ImageCardRoot } from '../ImageCardRoot/ImageCardRoot';
import styles from './ImageCardBase.module.scss';
import { ImageCardBaseProps } from './ImageCardBaseProps';

export const ImageCardBase: FunctionComponent<ImageCardBaseProps> = (props) => {
  const { image, folder, actions, checked, isShowOrderMode } = props;
  const { matchProp } = useLanguageRelevantData();
  const auth = useAuth();
  const preview = useMemo(() => image.file?.previews?.find((x) => x.label === FilePreviewLabel.IMAGE_SEARCH), [image]);

  return (
    <ImageCardRoot
      className={cn(styles.root, {
        [styles.active]: checked,
      })}
    >
      {actions && !isShowOrderMode && auth.user?.role !== UserRole.GUEST && (
        <CardActionsMenu className={styles.actions} actions={actions} placement={'bottom-start'} />
      )}
      {imageAccessRuleService.canView() && !isShowOrderMode && (
        <ImageCardFavoriteButton image={image} folderId={folder.id} className={styles.favorite} />
      )}
      {image.author && (
        <span className={styles.author}>{matchProp(image.author?.captionRu, image.author?.captionEn)}</span>
      )}
      <Image className={styles.image} src={preview?.url} alt='' />
    </ImageCardRoot>
  );
};
