import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ListPaginationState } from 'features/list';
import { getListPaginationReducers } from 'features/list/listReducers';
import { getDefaultPaginationState } from 'features/pagination';
import { ReferenceBook } from 'features/referenceBook/referenceBookEntity';

export type AuthorListQueryState = ListPaginationState & {
  authorsQuery: string;
  author?: ReferenceBook;
};

const initialState: AuthorListQueryState = {
  authorsQuery: '',
  paginationData: getDefaultPaginationState(),
};

const authorListSliceQuery = createSlice({
  name: 'authorListQuery',
  initialState,
  reducers: {
    authorsData: (state, action: PayloadAction<string>) => {
      state.authorsQuery = action.payload;
    },
    setAuthor: (state, action: PayloadAction<ReferenceBook | undefined>) => {
      state.author = action.payload;
    },
    ...getListPaginationReducers<AuthorListQueryState>(),
    dispose: (state) => {
      state.paginationData = getDefaultPaginationState();
    },
  },
});

export const authorListQueryActions = authorListSliceQuery.actions;
export const authorListQueryReducer = authorListSliceQuery.reducer;
