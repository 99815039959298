import cn from 'classnames';
import { nanoid } from 'nanoid';
import { forwardRef, useRef } from 'react';
import { Checkbox } from '../Checkbox/Checkbox';
import { FormControl } from '../FormControl';
import { Label } from '../Label';
import styles from './CheckboxField.module.scss';
import { CheckboxFieldProps } from './CheckboxFieldProps';

export const CheckboxField = forwardRef<HTMLInputElement, CheckboxFieldProps>((props, ref) => {
  const { className, id, label, disabled, LabelProps, CheckboxProps } = props;

  const inputId = useRef(id ?? nanoid()).current;

  return (
    <FormControl className={cn(styles.root, className)}>
      <Checkbox ref={ref} id={inputId} {...CheckboxProps} />
      <Label disabled={disabled} htmlFor={inputId} label={label} className={styles.label} {...LabelProps} />
    </FormControl>
  );
});
