import React, { ChangeEvent, FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { alertActions } from 'features/alert/slice';
import {
  FILE_FORMATS,
  uploadMaxFileSizeMB,
  uploadSupportedExcelTypes,
  uploadSupportedMimeExcelTypes,
} from 'features/file/constants';
import { fileApi } from 'features/file/fileApi';
import { validateFile } from 'features/file/utils';
import { useUploadExcelFileMutation } from 'features/images';
import { ApiErrorCode, parseApiError } from 'infrastructure/network';
import { useAppDispatch } from 'infrastructure/redux';
import { Button, ButtonKind, ButtonVariant } from 'ui-library/components/Button';
import { Icon } from 'ui-library/components/Icon';
import styles from './FileExcelUploader.module.scss';

export const FileExcelUploader: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [uploadExcelFile] = useUploadExcelFileMutation();

  const getErrorMessage = (error: ApiErrorCode) => {
    if (error === ApiErrorCode.WRONG_FORMAT) {
      return t('file.alert.errorFormat');
    } else {
      return t('file.alert.systemError');
    }
  };

  const onUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (!files || (files && !files[0])) {
      return;
    }

    const file = files[0];
    event.target.value = '';

    const maxFileSizeMB = uploadMaxFileSizeMB;
    const supportedExcelTypes = uploadSupportedExcelTypes;

    const isValidFile = validateFile(supportedExcelTypes, maxFileSizeMB);

    if (isValidFile(file)) {
      await uploadExcelFile({ file: file }).catch((e) => {
        const error = parseApiError(e);

        if (error) {
          dispatch(alertActions.setMessage(getErrorMessage(error.code)));
          dispatch(alertActions.open());
        }
      });

      dispatch(fileApi.util.invalidateTags([{ type: 'FileTasks' }]));
    } else {
      dispatch(alertActions.setMessage(getErrorMessage(ApiErrorCode.WRONG_FORMAT)));
      dispatch(alertActions.open());
    }
  };

  const inputAcceptTypes = uploadSupportedMimeExcelTypes[FILE_FORMATS.xlsx];

  return (
    <Button
      as={ButtonKind.BUTTON}
      variant={ButtonVariant.TRANSPARENT}
      startIcon={<Icon name={'plus'} />}
      className={styles.button}
      size='small'
    >
      <input className={styles.input} type='file' onChange={onUpload} accept={inputAcceptTypes} />
      {t('file.uploader.excelFileLabel')}
    </Button>
  );
};
