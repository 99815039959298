import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'infrastructure/redux';
import { Icon } from 'ui-library/components/Icon';
import { ZeroScreenWrapper } from 'ui-library/components/ZeroScreen';
import styles from './MovingArtifactEmptyFolder.module.scss';

export const MovingArtifactEmptyFolder: FunctionComponent = () => {
  const { t } = useTranslation();
  const { type, movingArtifacts } = useAppSelector((state) => state.movingArtifacts);

  const getMessageZeroScreen = () => {
    if (type === 'image') {
      return t('movingArtifacts.empty.folderImage', { count: movingArtifacts.length });
    } else if (type === 'layout') {
      return t('movingArtifacts.empty.folderLayout', { count: movingArtifacts.length });
    } else {
      return t('movingArtifacts.empty.folder');
    }
  };

  return (
    <ZeroScreenWrapper
      className={styles.root}
      theme='dark'
      renderIcon={(props) => <Icon name='documentIllustration' size='none' {...props} />}
      message={getMessageZeroScreen()}
    />
  );
};
