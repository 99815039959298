import cn from 'classnames';
import { FunctionComponent, useRef } from 'react';
import { hasNestedInfo } from 'features/folders';
import { useIsSticky } from 'features/tracking';
import { useTranslateEnum } from 'infrastructure/i18n';
import { Tab } from 'ui-library/components/Tab';
import { FavoriteContentTab } from '../FavoriteContent/FavoriteContentTab';
import { FolderListHeaderActions } from '../FolderList/FolderListHeaderActions/FolderListHeaderActions';
import { ImageListHeaderActions } from '../ImageList/ImageListHeaderActions/ImageListHeaderActions';
import { LayoutListHeaderActions } from '../LayoutList/LayoutListHeaderActions/LayoutListHeaderActions';
import { RootFolderListHeaderActions } from '../RootFolderList/RootFolderListHeaderActions/RootFolderListHeaderActions';
import styles from './FavoriteContentHeader.module.scss';
import { FavoriteContentHeaderProps } from './FavoriteContentHeaderProps';

export const FavoriteContentHeader: FunctionComponent<FavoriteContentHeaderProps> = (props) => {
  const { group, selectedTab } = props;
  const headerRef = useRef<HTMLDivElement | null>(null);
  const isSticky = useIsSticky(headerRef);
  const { translateValue } = useTranslateEnum('FavoriteContentTab', FavoriteContentTab);

  const isTabHidden = (artifactCount?: number) => {
    return group !== undefined && !hasNestedInfo(artifactCount);
  };

  return (
    <Tab.List ref={(node) => (headerRef.current = node)} className={cn(styles.root, { [styles.sticky]: isSticky })}>
      <Tab
        className={cn({
          [styles.hidden]: isTabHidden(group?.topicCount),
        })}
      >
        {translateValue(FavoriteContentTab.ROOT_FOLDERS)}
      </Tab>
      <Tab
        className={cn({
          [styles.hidden]: isTabHidden(group?.folderCount),
        })}
      >
        {translateValue(FavoriteContentTab.FOLDERS)}
      </Tab>
      <Tab
        className={cn({
          [styles.hidden]: isTabHidden(group?.layoutCount),
        })}
      >
        {translateValue(FavoriteContentTab.LAYOUTS)}
      </Tab>
      <Tab
        className={cn({
          [styles.hidden]: isTabHidden(group?.imageCount),
        })}
      >
        {translateValue(FavoriteContentTab.IMAGES)}
      </Tab>

      <div className={styles.actions}>
        {selectedTab === FavoriteContentTab.ROOT_FOLDERS && <RootFolderListHeaderActions />}
        {selectedTab === FavoriteContentTab.FOLDERS && <FolderListHeaderActions />}
        {selectedTab === FavoriteContentTab.LAYOUTS && <LayoutListHeaderActions />}
        {selectedTab === FavoriteContentTab.IMAGES && <ImageListHeaderActions />}
      </div>
    </Tab.List>
  );
};
