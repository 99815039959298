import { skipToken } from '@reduxjs/toolkit/dist/query';
import { FunctionComponent } from 'react';
import { ImageLoadingError } from 'features/images';
import { ListLayout, ListLayoutButtonGroup } from 'features/list';
import { calculateOffset } from 'features/pagination';
import { ImagesResult, searchListActions, SearchScreenResultTab, useSearchAIQuery } from 'features/search';
import { useTranslateEnum } from 'infrastructure/i18n';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux';
import styles from './SearchScreenResultAI.module.scss';
import { SearchScreenResultAIProps } from './SearchScreenResultAIProps';

const defaultData = {
  content: [],
  totalCount: 0,
  limit: 0,
  offset: 0,
};

export const SearchScreenResultAI: FunctionComponent<SearchScreenResultAIProps> = (props) => {
  const { query } = props;
  const { layoutType } = useAppSelector((state) => state.searchList);
  const dispatch = useAppDispatch();
  const viewType = useAppSelector((state) => state.searchList.layoutType);
  const imagePaginationData = useAppSelector((state) => state.searchImage.paginationData);
  const { translateValue } = useTranslateEnum('SearchScreenResultTab', SearchScreenResultTab);

  const result = useSearchAIQuery(
    query && query.length > 0
      ? {
          offset: calculateOffset(imagePaginationData.currentPage, imagePaginationData.pageSize),
          limit: imagePaginationData.pageSize,
          query: query ?? '',
        }
      : skipToken,
  );

  const handleChangeLayout = (layout: ListLayout) => {
    dispatch(searchListActions.changeLayoutType(layout));
  };

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={styles.buttonGroup}>
          <ListLayoutButtonGroup active={layoutType} onLayoutChanged={handleChangeLayout} />
        </div>
        <div className={styles.label}>
          {translateValue(SearchScreenResultTab.IMAGES)} ({result.data?.totalCount ?? 0})
        </div>
      </div>
      {result.isError ? (
        <ImageLoadingError onReload={result.refetch} />
      ) : (
        <ImagesResult
          query={{
            data: result.data ?? defaultData,
            isFetching: result.isFetching,
            isError: result.isError,
          }}
          layoutType={viewType}
        />
      )}
    </div>
  );
};
