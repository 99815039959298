import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { FolderLoadingError } from 'features/folders';
import { ListLayout } from 'features/list';
import { searchFolderActions } from 'features/search/slices';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux';
import { Pagination } from 'ui-library/components/Pagination';
import { EmptySearch } from '../EmptySearch/EmptySearch';
import { FolderCardList } from './FolderCardList/FolderCardList';
import styles from './FoldersResult.module.scss';
import { FoldersResultProps } from './FoldersResultProps';
import { FolderTable } from './FolderTable/FolderTable';

export const FoldersResult: FunctionComponent<FoldersResultProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { currentPage, pageSize } = useAppSelector((state) => state.searchFolder.paginationData);

  const { query, layoutType } = props;
  const isEmpty = !query.isError && query.data?.totalCount === 0;

  const layoutView = layoutType === ListLayout.CARD ? <FolderCardList {...query} /> : <FolderTable {...query} />;

  return (
    <div className={styles.root}>
      <div className={styles.listWrap}>
        <>
          {isEmpty ? <EmptySearch className={styles.placeholder} message={t('search.emptyFolders')} /> : layoutView}
          {query.isError && <FolderLoadingError onReload={query.refetch} />}
        </>
      </div>
      <div className={styles.paginationWrap}>
        <Pagination
          totalItems={query.data?.totalCount}
          pageSize={pageSize}
          onPageChanged={(page) => dispatch(searchFolderActions.pageChanged({ page }))}
          currentPage={currentPage}
          scrollToStartAfterPageChanged
        />
      </div>
    </div>
  );
};
