import { createApi } from '@reduxjs/toolkit/query/react';
import { listQuery } from 'features/list';
import { fetchBaseAuthQuery, HTTP_METHODS, ListApiResponse, URL_FACTORY } from 'infrastructure/network';
import {
  FetchFolderImagesQueryData,
  FetchGalleryQueryData,
  FetchLayoutImagesQueryData,
  GalleryResponseDto,
  ImageCreateRequestDto,
  ImageEditRequestDto,
  ImageFileResponseDto,
  ImageFileUploadRequestDto,
  ImageResponseDto,
  ImagesDeleteRequestDto,
  ImagesMetadataRequestDto,
  ImagesMetadataResponseDto,
} from './imageEntity';

export const imagesApi = createApi({
  reducerPath: 'imagesApi',
  baseQuery: fetchBaseAuthQuery(),
  tagTypes: ['Image', 'ImageGallery', 'FolderImages', 'LayoutImages'],
  endpoints: (builder) => ({
    fetchLayoutImages: builder.query<ListApiResponse<ImageResponseDto>, FetchLayoutImagesQueryData>({
      query: ({ layoutID, ...rest }) =>
        listQuery({
          url: URL_FACTORY.LAYOUTS.LINKED_IMAGES(layoutID),
          params: rest,
        }),
      providesTags: (result, error, { layoutID }) =>
        result
          ? [
              'LayoutImages',
              { type: 'LayoutImages', id: layoutID },
              ...result.content.map(({ id }) => ({ type: 'Image' as const, id })),
            ]
          : ['LayoutImages', { type: 'LayoutImages', id: layoutID }],
    }),
    fetchFolderImages: builder.query<ListApiResponse<ImageResponseDto>, FetchFolderImagesQueryData>({
      query: ({ folderID, ...rest }) =>
        listQuery({
          url: URL_FACTORY.FOLDERS.LINKED_IMAGES(folderID),
          params: rest,
        }),
      providesTags: (result, error, { folderID }) =>
        result
          ? [
              'FolderImages',
              { type: 'FolderImages', id: folderID },
              ...result.content.map(({ id }) => ({ type: 'Image' as const, id })),
            ]
          : ['FolderImages', { type: 'FolderImages', id: folderID }],
    }),
    fetchGallery: builder.query<GalleryResponseDto, FetchGalleryQueryData>({
      query: ({ folderID, ...rest }) =>
        listQuery({
          url: URL_FACTORY.FOLDERS.GALLERY(folderID),
          params: rest,
        }),
      providesTags: (result, error, { folderID }) =>
        error
          ? []
          : [
              {
                type: 'ImageGallery',
                id: folderID,
              },
              'ImageGallery',
            ],
    }),
    fetchImageDetail: builder.query<ImageResponseDto, string>({
      query: (imageID) => URL_FACTORY.IMAGES.DETAIL(imageID),
      providesTags: (result, error, imageID) => (error ? [] : [{ type: 'Image', id: imageID }, 'Image']),
    }),
    editImage: builder.mutation<ImageResponseDto, ImageEditRequestDto>({
      query: ({ imageID, ...restData }) => ({
        url: URL_FACTORY.IMAGES.DETAIL(imageID),
        method: HTTP_METHODS.PATCH,
        body: restData,
      }),
      invalidatesTags: (result, error, { imageID }) => (error ? [] : [{ type: 'Image', id: imageID }, 'ImageGallery']),
    }),
    createImage: builder.mutation<ImageResponseDto, ImageCreateRequestDto>({
      query: ({ ...restData }) => ({
        url: URL_FACTORY.IMAGES.ROOT,
        method: HTTP_METHODS.POST,
        body: restData,
      }),
      invalidatesTags: (result, error) => (error ? [] : ['ImageGallery', 'FolderImages', 'LayoutImages']),
    }),
    deleteImage: builder.mutation<unknown, ImagesDeleteRequestDto>({
      query: ({ imageID }) => ({
        url: URL_FACTORY.IMAGES.DETAIL(imageID),
        method: HTTP_METHODS.DELETE,
      }),
      invalidatesTags: (result, error) => (error ? [] : ['ImageGallery', 'FolderImages', 'LayoutImages']),
    }),
    downloadImagesMetadata: builder.mutation<ImagesMetadataResponseDto, ImagesMetadataRequestDto>({
      query: (data) => ({
        url: URL_FACTORY.IMAGES.DOWNLOAD_METADATA(),
        method: HTTP_METHODS.POST,
        body: data,
      }),
    }),
    uploadExcelFile: builder.mutation<ImageFileResponseDto, ImageFileUploadRequestDto>({
      query: ({ file }) => {
        const formData = new FormData();
        formData.append('file', file);

        return {
          url: URL_FACTORY.IMAGES.UPLOAD(),
          method: HTTP_METHODS.POST,
          body: formData,
        };
      },
    }),
  }),
});

export const {
  useFetchLayoutImagesQuery,
  useCreateImageMutation,
  useDeleteImageMutation,
  useUploadExcelFileMutation,
  useFetchImageDetailQuery,
  useDownloadImagesMetadataMutation,
  useEditImageMutation,
  useFetchGalleryQuery,
  useFetchFolderImagesQuery,
} = imagesApi;
