import { Alert } from 'features/alert';
import { ChangeOrderModal } from 'features/changeOrder';
import { FolderCreate, ImageCreate, LayoutCreate } from 'features/createArtifact';
import {
  AddToFavorites,
  FavoriteGroupCreate,
  FavoriteGroupEdit,
  FavoriteGroupRemoveConfirm,
  FavoriteRemoveConfirm,
} from 'features/favorites';
import { ActiveFileDownloadTasks, CreateFileDownloadTask, ShareFileDownloadTask } from 'features/file';
import { FolderDeleteEmptyModal, FolderEditModal, FolderView } from 'features/folders';
import { ImageDeleteModal, ImageEdit } from 'features/images';
import { LayoutDeleteModal, LayoutEdit } from 'features/layouts';
import { LinkingArtifact } from 'features/linking';
import { MovingArtifact } from 'features/movingArtifacts';
import { CreateRootFolder } from 'features/rootFolders';
import { ToggleMuseumModal } from 'features/toggleMuseum';

export const GlobalModals = () => {
  return (
    <>
      <FolderView />
      <CreateRootFolder />
      <ChangeOrderModal />
      <FolderEditModal />
      <FolderCreate />
      <LayoutEdit />
      <LayoutCreate />
      <LayoutDeleteModal />
      <ImageEdit />
      <ImageCreate />
      <ImageDeleteModal />
      <MovingArtifact />
      <LinkingArtifact />
      <ToggleMuseumModal />
      <FavoriteGroupCreate />
      <FavoriteGroupEdit />
      <FavoriteRemoveConfirm />
      <FavoriteGroupRemoveConfirm />
      <AddToFavorites />
      <CreateFileDownloadTask />
      <Alert />
      <ShareFileDownloadTask />
      <FolderDeleteEmptyModal />
      <ActiveFileDownloadTasks />
    </>
  );
};
