import { FunctionComponent } from 'react';
import { useSearchReferenceBookQuery } from 'features/referenceBook/referenceBookApi';
import { ReferenceBookType } from 'features/referenceBook/referenceBookEntity';
import { SortDirection } from 'features/sorting';
import { UserLoadingError } from 'features/users';
import { useCurrentLanguage, useLanguageRelevantData } from 'infrastructure/i18n';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux';
import { Pagination } from 'ui-library/components/Pagination';
import { AuthorsScreenEmptyQuery } from '../AuthorsReferenceBook/AuthorsScreenEmptyQuery/AuthorsScreenEmptyQuery';
import { authorListQueryActions } from '../AuthorsReferenceBook/slices';
import styles from './AuthorsDataView.module.scss';
import { AuthorsDataViewProps } from './AuthorsDataViewProps';
import { AuthorsTable } from './AuthorsTable/AuthorsTable';

export const AuthorsDataView: FunctionComponent<AuthorsDataViewProps> = (props) => {
  const { currentPage, pageSize } = useAppSelector((state) => state.authorListQuery.paginationData);
  const { authorsQuery, isOpen } = props;
  const dispatch = useAppDispatch();
  const language = useCurrentLanguage();
  const { matchProp } = useLanguageRelevantData();

  const { data, isFetching, isError, refetch } = useSearchReferenceBookQuery({
    query: authorsQuery,
    type: ReferenceBookType.AUTHORS,
    language: language,
    sortDirection: SortDirection.ASC,
    sortFields: [matchProp('captionRu', 'captionEn')],
    pageSize: pageSize,
    currentPage: currentPage,
  });

  const handleCreate = () => {
    isOpen();
  };

  const isEmpty = !isError && data?.totalCount === 0;

  if (isEmpty) {
    return <AuthorsScreenEmptyQuery />;
  }

  return (
    <div className={styles.root}>
      <div className={styles.listWrap}>
        {isError && !data ? (
          <UserLoadingError onReload={refetch} />
        ) : (
          <AuthorsTable onCreate={handleCreate} isError={isError} data={data} isFetching={isFetching} />
        )}
      </div>
      <div className={styles.paginationWrap}>
        <Pagination
          totalItems={data?.totalCount}
          pageSize={pageSize}
          onPageChanged={(page) => dispatch(authorListQueryActions.pageChanged({ page }))}
          currentPage={currentPage}
          scrollToStartAfterPageChanged
        />
      </div>
    </div>
  );
};
