import { SyntheticEvent, useContext } from 'react';
import { NotDefinedParamException } from 'infrastructure/exceptions';
import { useAppDispatch } from 'infrastructure/redux';
import { TagsManageContext } from '../contexts';
import { tagDeleteActions, tagEditActions } from '../slices';
import { TagResponseDto } from '../tagEntity';

export const useTagActionHandlers = (tag?: TagResponseDto) => {
  const dispatch = useAppDispatch();
  const { extendTag, undoExtendTag, removeTag, undoRemoveTag } = useContext(TagsManageContext);

  const handleEdit = (event: SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    dispatch(tagEditActions.selectTag(tag));
    dispatch(tagEditActions.open());
  };

  const handleExtend = (event: SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (!tag) {
      throw new NotDefinedParamException('tag');
    }

    extendTag(tag);
  };

  const handleUndoExtend = (event: SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (!tag) {
      throw new NotDefinedParamException('tag');
    }

    undoExtendTag(tag);
  };

  const handleRemove = (event: SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (!tag) {
      throw new NotDefinedParamException('tag');
    }

    removeTag(tag);
  };

  const handleUndoRemove = (event: SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (!tag) {
      throw new NotDefinedParamException('tag');
    }

    undoRemoveTag(tag);
  };

  const handleDelete = (event: SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    dispatch(tagDeleteActions.selectTag(tag));
    dispatch(tagDeleteActions.open());
  };

  return {
    handleEdit,
    handleExtend,
    handleUndoExtend,
    handleRemove,
    handleUndoRemove,
    handleDelete,
  };
};
