import cn from 'classnames';
import { MouseEvent, useMemo } from 'react';
import { Chip } from 'ui-library/components/Chip';
import styles from './BaseSelectMultiValueChips.module.scss';
import { BaseSelectMultiValueChipsProps } from './BaseSelectMultiValueChipsProps';

export const BaseSelectMultiValueChips = <Option, OptionValue = Option>(
  props: BaseSelectMultiValueChipsProps<Option, OptionValue, true>,
) => {
  const { value, options, getOptionLabel, getOptionValue, onChange, disabled } = props;

  const onDelete = (option: Option) => {
    return (event: MouseEvent<HTMLSpanElement>) => {
      event.stopPropagation();

      onChange?.(value.filter((optionValue) => optionValue !== getOptionValue(option)));
    };
  };

  const selectedOptions = useMemo(
    () => options.filter((option) => value.includes(getOptionValue(option) as OptionValue)),
    [getOptionValue, options, value],
  );

  return (
    <>
      {selectedOptions.map((option, index) => (
        <Chip
          key={index}
          className={styles.tag}
          labelClassName={cn({
            [styles.disabled]: disabled,
          })}
          label={getOptionLabel(option)}
          onDelete={disabled ? undefined : onDelete(option)}
        />
      ))}
    </>
  );
};
