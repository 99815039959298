import { roleService } from 'features/role/roleService';
import { UserRole } from 'features/users';
import { AccessRuleService } from './accessRuleService';

class UsersAccessRuleService extends AccessRuleService {
  protected canViewRoles: Array<UserRole> = [];
  protected canCreateRoles: Array<UserRole> = [];
  protected canEditRoles: Array<UserRole> = [];
  protected canDeleteRoles: Array<UserRole> = [];
  protected canEditReferencesRole: Array<UserRole> = [UserRole.SUPERADMIN, UserRole.ADMIN];

  public canEditReferences() {
    return roleService.hasAccess(this.canEditReferencesRole);
  }
}

export const usersAccessRuleService = new UsersAccessRuleService();
