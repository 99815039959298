import { TableHeadColumn } from 'ui-library/components/Table';

export const useRootFolderTableHeadColumns = () => {
  const columns: Array<TableHeadColumn> = [
    {
      width: '65%',
    },
    {
      width: '50px',
    },
    {
      width: '70px',
    },
    {
      width: '70px',
    },
    {
      width: '60px',
    },
  ];

  return columns;
};
