import { FunctionComponent } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { favoriteGroupCreateActions, FavoriteGroupRequestBody, useCreateGroupMutation } from 'features/favorites';
import { ModalSubmitActions } from 'features/modal';
import { parseApiError } from 'infrastructure/network';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux';
import { Modal } from 'ui-library/components/Modal';
import { FavoriteGroupForm } from '../FavoriteGroupForm/FavoriteGroupForm';

export const FavoriteGroupCreate: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const { isOpen } = useAppSelector((state) => state.favoriteGroupCreate);

  const [createGroupMutation] = useCreateGroupMutation();

  const { t } = useTranslation();

  const handleClose = () => {
    dispatch(favoriteGroupCreateActions.close());
  };

  const onSubmit = async (values: FavoriteGroupRequestBody) => {
    try {
      await createGroupMutation({
        captionRu: values.captionRu,
        captionEn: values.captionEn,
        descriptionRu: values.descriptionRu,
        descriptionEn: values.descriptionEn,
      }).unwrap();

      handleClose();
    } catch (e) {
      const error = parseApiError(e);

      toast.error(error?.message || t('favorites.group.createFailed'));
    }
  };

  const defaultValues: FavoriteGroupRequestBody = {
    captionEn: '',
    captionRu: '',
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Modal.Title>{t('favorites.group.create')}</Modal.Title>
      <FavoriteGroupForm
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        renderActions={({ isSubmitting }) => <ModalSubmitActions loading={isSubmitting} onCancel={handleClose} />}
      />
    </Modal>
  );
};
