import { useMemo } from 'react';
import { FolderResponseDtoGrouped } from 'features/folders';
import { useLanguageRelevantData } from 'infrastructure/i18n';
import { FolderResponseDto } from '../folderEntity';

export const useGetFolderResponseGroupedData = (items?: Array<FolderResponseDto>): FolderResponseDtoGrouped => {
  const { matchProp } = useLanguageRelevantData();

  const groupedData = useMemo<FolderResponseDtoGrouped>(() => {
    const groups: FolderResponseDtoGrouped = {};

    if (!items) {
      return groups;
    }

    for (const x of items) {
      const prop = matchProp(x.captionRu, x.captionEn);
      const key = prop.charAt(0).toUpperCase();

      if (Array.isArray(groups[key])) {
        groups[key].push(x);
      } else {
        groups[key] = [x];
      }
    }

    return groups;
  }, [items, matchProp]);

  return groupedData;
};
