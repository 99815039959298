import { FunctionComponent } from 'react';
import { FormProvider } from 'react-hook-form';
import {
  addToFavoritesActions,
  AddToFavoritesFormFields,
  FavoriteArtifactEntityType,
  FavoriteUpdateRequestDto,
  useUpdateFavoritesMutation,
} from 'features/favorites';
import { useForm } from 'infrastructure/form';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux';
import { Modal } from 'ui-library/components/Modal';
import { useAddToFavoriteToast, useInvalidateFavoriteRelatedEntities } from '../../hooks';
import { AddToFavoritesActions } from '../AddToFavoritesActions/AddToFavoritesActions';
import { FavoriteGroupListDataView } from '../FavoriteGroupListDataView/FavoriteGroupListDataView';
import styles from './AddToFavoritesForm.module.scss';

export const AddToFavoritesForm: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const [trigger] = useUpdateFavoritesMutation();

  const favoriteArtifact = useAppSelector((state) => state.addToFavorites.favoriteArtifact);
  const { invalidateEntities } = useInvalidateFavoriteRelatedEntities(favoriteArtifact);
  const methods = useForm<AddToFavoritesFormFields>({
    defaultValues: {
      groupIDsToAddTo: [],
    },
  });
  const { getValues } = methods;
  const { groupIDsToAddTo } = getValues();

  const { showToast } = useAddToFavoriteToast(favoriteArtifact, groupIDsToAddTo);

  const { handleSubmit } = methods;

  const submit = async (values: AddToFavoritesFormFields) => {
    const dto: FavoriteUpdateRequestDto = {
      artifactID: favoriteArtifact?.entityId as string,
      artifactType: favoriteArtifact?.type as FavoriteArtifactEntityType,
      makeCommon: values.groupIDsToAddTo.length === 0,
      groupIDsToAddTo: values.groupIDsToAddTo.map((x) => x.id),
      groupIDsToRemoveFrom: [],
    };

    try {
      await trigger(dto).unwrap();

      invalidateEntities();
      showToast('Success');

      dispatch(addToFavoritesActions.dispose());
    } catch (e) {
      showToast('Failed');
    }
  };

  return (
    <FormProvider {...methods}>
      <Modal.Form onSubmit={handleSubmit(submit)}>
        <Modal.Content className={styles.content}>
          <FavoriteGroupListDataView />
        </Modal.Content>
        <Modal.Actions>
          <AddToFavoritesActions favoriteArtifact={favoriteArtifact} />
        </Modal.Actions>
      </Modal.Form>
    </FormProvider>
  );
};
