import { FunctionComponent, useState } from 'react';
import { fileDownloadService } from 'features/file/services';
import { FileDownloadDetailAgreementActions } from '../FileDownloadDetailAgreementActions/FileDownloadDetailAgreementActions';
import { FileDownloadDetailArchiveActions } from '../FileDownloadDetailArchiveActions/FileDownloadDetailArchiveActions';
import { FileDownloadDetailDescription } from '../FileDownloadDetailDescription/FileDownloadDetailDescription';
import { FileDownloadDetailHeader } from '../FileDownloadDetailHeader/FileDownloadDetailHeader';
import { FileDownloadDetailLoading } from '../FileDownloadDetailLoading/FileDownloadDetailLoading';
import { FileDownloadDetailLoadingError } from '../FileDownloadDetailLoadingError/FileDownloadDetailLoadingError';
import styles from './FileDownloadDetailInfo.module.scss';
import { FileDownloadDetailInfoProps } from './FileDownloadDetailInfoProps';

export const FileDownloadDetailInfo: FunctionComponent<FileDownloadDetailInfoProps> = (props) => {
  const { data, isLoading, isError, refetch } = props;
  const [showArchiveActions, setShowArchiveActions] = useState(fileDownloadService.isUserAgree);

  const handleAgree = () => {
    fileDownloadService.setUserAgreement(true);
    setShowArchiveActions(true);
  };

  if (isError) {
    return <FileDownloadDetailLoadingError onReload={refetch} />;
  }

  if (isLoading) {
    return <FileDownloadDetailLoading />;
  }

  return (
    <div className={styles.root}>
      <FileDownloadDetailHeader task={data} />
      <FileDownloadDetailDescription task={data} />
      {showArchiveActions ? (
        <FileDownloadDetailArchiveActions task={data} />
      ) : (
        <FileDownloadDetailAgreementActions onDone={handleAgree} />
      )}
    </div>
  );
};
