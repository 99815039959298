import { FunctionComponent } from 'react';
import { useAuth } from 'features/auth';
import { imageEditActions } from 'features/images';
import { imageAccessRuleService } from 'features/role';
import { UserRole } from 'features/users';
import { useCurrentLanguage } from 'infrastructure/i18n';
import { useAppDispatch } from 'infrastructure/redux';
import { Link, LinkKind } from 'ui-library/components/Link';
import { EmptySection } from 'ui-library/components/ZeroScreen';
import { isValidUrl, REGULAR_EXPRESSIONS } from 'utils';
import { ImageEditTab } from '../../ImageEditForm/ImageEditTab';
import styles from './ImageViewMaterials.module.scss';
import { ImageViewMaterialsProps } from './ImageViewMaterialsProps';

export const ImageViewMaterials: FunctionComponent<ImageViewMaterialsProps> = (props) => {
  const dispatch = useAppDispatch();
  const { image } = props;
  const currentLang = useCurrentLanguage();
  const auth = useAuth();

  const handleAddInfo = () => {
    dispatch(imageEditActions.selectImage(image?.id));
    dispatch(imageEditActions.selectTab(ImageEditTab.MATERIALS));
    dispatch(imageEditActions.open());
  };

  if (!image?.additionalInformationRu && !image?.additionalInformationEn) {
    return (
      <EmptySection
        iconName='documentIllustration'
        onAdd={imageAccessRuleService.canEdit() ? handleAddInfo : undefined}
      />
    );
  }

  const getParsedMaterials = (): Array<string> => {
    if (currentLang === 'ru' && image.additionalInformationRu) {
      return image.additionalInformationRu.split(REGULAR_EXPRESSIONS.URL).filter(Boolean);
    }
    if (currentLang === 'en' && image.additionalInformationEn) {
      return image.additionalInformationEn.split(REGULAR_EXPRESSIONS.URL).filter(Boolean);
    }

    return [];
  };

  return (
    <div className={styles.root}>
      {getParsedMaterials()?.map((x) =>
        isValidUrl(x) && auth.user?.role !== UserRole.GUEST ? (
          <Link className={styles.link} key={x} as={LinkKind.OUTER} href={x}>
            {x}
          </Link>
        ) : (
          <span key={x}>{x}</span>
        ),
      )}
    </div>
  );
};
