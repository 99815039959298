import { Dialog, Transition } from '@headlessui/react';
import { Fragment, FunctionComponent, PropsWithChildren, useMemo } from 'react';
import { ButtonKind } from 'ui-library/components/Button';
import { Icon } from 'ui-library/components/Icon';
import { IconButton, IconButtonVariant } from 'ui-library/components/IconButton';
import styles from './ModalPopup.module.scss';
import { ModalPopupProps } from './ModalPopupProps';

export const ModalPopup: FunctionComponent<PropsWithChildren<ModalPopupProps>> = (props) => {
  const { children, onClose, closeIconName, animation: animationProp = 'appear' } = props;

  const animation = useMemo(() => {
    let from;
    let to;

    if (animationProp === 'appear') {
      from = styles.animationOpacityFrom;
      to = styles.animationOpacityTo;
    } else {
      from = styles.animationSlideFrom;
      to = styles.animationSlideTo;
    }

    return {
      from,
      to,
    };
  }, [animationProp]);

  return (
    <Transition.Child
      as={Fragment}
      enter={styles.animation}
      enterFrom={animation.from}
      enterTo={animation.to}
      leave={styles.animation}
      leaveFrom={animation.to}
      leaveTo={animation.from}
    >
      <Dialog.Panel className={styles.popupPanel}>
        {closeIconName && (
          <IconButton
            variant={IconButtonVariant.TRANSPARENT}
            className={styles.closeButton}
            onClick={() => onClose(true)}
            as={ButtonKind.BUTTON}
          >
            <Icon name={closeIconName} />
          </IconButton>
        )}
        {children}
      </Dialog.Panel>
    </Transition.Child>
  );
};
