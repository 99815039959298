import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { apiErrorMessageMatcher } from 'features/file';
import { ImageEditDtoValidationSchema } from 'features/images/imageEditValidation';
import { ModalSubmitActions } from 'features/modal';
import { TagsManageContext, TagsOrigin, useTagsManage } from 'features/tags';
import { useForm } from 'infrastructure/form';
import { useTranslateEnum } from 'infrastructure/i18n';
import { parseApiError } from 'infrastructure/network';
import { Modal } from 'ui-library/components/Modal';
import { Tab } from 'ui-library/components/Tab';
import { ImageEditFormFields } from '../../imageEntity';
import { ImageEditFormInfoTab } from './ImageEditFormInfoTab/ImageEditFormInfoTab';
import { ImageEditFormLayoutsTab } from './ImageEditFormLayoutsTab/ImageEditFormLayoutsTab';
import { ImageEditFormMaterialsTab } from './ImageEditFormMaterialsTab/ImageEditFormMaterialsTab';
import { ImageEditFormProps } from './ImageEditFormProps';
import { ImageEditTab } from './ImageEditTab';

export const ImageEditForm = (props: ImageEditFormProps) => {
  const { onClose, onSubmit, defaultValues, selectedTab, onTabChange, isCreate } = props;
  const { translateValue } = useTranslateEnum('ImageEditTab', ImageEditTab);

  const { t } = useTranslation();
  const methods = useForm<ImageEditFormFields>({
    defaultValues,
    schema: ImageEditDtoValidationSchema(),
  });

  const {
    handleSubmit,
    formState: { isSubmitting, isValid },
    setError,
  } = methods;

  const tagsManage = useTagsManage({
    initialTags: defaultValues?.tags || [],
    disableExtend: true,
  });
  const { tags, isRemoved } = tagsManage;

  const submit = async (values: ImageEditFormFields) => {
    return onSubmit({
      ...values,
      tags: tags.filter((currentTag) => !isRemoved(currentTag) && currentTag.origin === TagsOrigin.TAG_USER),
    }).catch((e) => {
      const error = parseApiError(e);
      const messageKey = apiErrorMessageMatcher(error);

      if (messageKey) {
        setError('fileBox.file', {
          message: t(messageKey as never),
        });
      }
    });
  };

  return (
    <FormProvider {...methods}>
      <TagsManageContext.Provider value={tagsManage}>
        <Modal.Form onSubmit={handleSubmit(submit)}>
          <Modal.Content>
            <Tab.Group as='div' selectedIndex={selectedTab} onChange={onTabChange}>
              <Tab.List>
                <Tab>{translateValue(ImageEditTab.INFORMATION)}</Tab>
                <Tab>{translateValue(ImageEditTab.LAYOUTS)}</Tab>
                <Tab>{translateValue(ImageEditTab.MATERIALS)}</Tab>
              </Tab.List>
              <Tab.Panels>
                <Tab.Panel>
                  <ImageEditFormInfoTab isCreate={isCreate} />
                </Tab.Panel>
                <Tab.Panel>
                  <ImageEditFormLayoutsTab />
                </Tab.Panel>
                <Tab.Panel>
                  <ImageEditFormMaterialsTab />
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          </Modal.Content>
          <Modal.Actions>
            <ModalSubmitActions loading={isSubmitting} onCancel={onClose} disabled={!isValid} />
          </Modal.Actions>
        </Modal.Form>
      </TagsManageContext.Provider>
    </FormProvider>
  );
};
