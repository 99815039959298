import { PayloadAction } from '@reduxjs/toolkit';
import { SortDirection } from './sortDirection';
import { SortChangedPayload } from './sortingPayloads';
import { SortState } from './sortState';

export const getSortReducers = <TState, TEntity>(selector: (state: TState) => SortState<TEntity>) => ({
  sortChanged: (state: TState, action: PayloadAction<SortChangedPayload<TEntity>>) => {
    const sortingInfo = selector(state);
    const { payload } = action;

    if (payload.direction) {
      sortingInfo.sortDirection = payload.direction;
    } else {
      if (sortingInfo.sortField !== payload.sortField) {
        sortingInfo.sortDirection = SortDirection.ASC;
      } else if (sortingInfo.sortDirection === SortDirection.ASC) {
        sortingInfo.sortDirection = SortDirection.DESC;
      } else {
        sortingInfo.sortDirection = SortDirection.ASC;
      }
    }

    sortingInfo.sortField = payload.sortField;
  },
});
