import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMessageProps } from './ErrorMessageProps';

export const ErrorMessage: FunctionComponent<ErrorMessageProps> = (props) => {
  const { error } = props;
  const { t, i18n } = useTranslation();

  if (error === undefined) {
    return null;
  } else if (typeof error === 'string') {
    return <>{i18n.exists(error) ? t(error as never) : error}</>;
  } else {
    const { key, values } = error;

    return <>{t(key as never, values as never)}</>;
  }
};
