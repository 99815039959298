import { FunctionComponent } from 'react';
import { FolderLoadingError } from 'features/folders';
import { ImageLoadingError } from 'features/images';
import { LayoutLoadingError } from 'features/layouts';
import { ErrorLoadingRoot } from 'features/rootFolders';
import { FoldersResult, ImagesResult, LayoutDataView, RootFoldersResult } from 'features/search';
import { useAppSelector } from 'infrastructure/redux';
import { getSearchResultItem } from 'screens/search/utils/getSearchResultItem';
import { Spinner } from 'ui-library/components/Spinner';
import { Tab } from 'ui-library/components/Tab';
import styles from './SearchScreenResultPanels.module.scss';
import { SearchScreenResultPanelsProps } from './SearchScreenResultPanelsProps';

export const SearchScreenResultPanels: FunctionComponent<SearchScreenResultPanelsProps> = (props) => {
  const { isLoading, data, isError, refetch } = props;
  const viewType = useAppSelector((state) => state.searchList.layoutType);

  return isLoading ? (
    <div className={styles.loader}>
      <Spinner size='large' color='secondary' />
    </div>
  ) : (
    <Tab.Panels>
      <Tab.Panel>
        {isError ? (
          <ImageLoadingError onReload={refetch} />
        ) : (
          <ImagesResult
            query={{
              ...props,
              data: getSearchResultItem(data?.items, 'image'),
            }}
            layoutType={viewType}
          />
        )}
      </Tab.Panel>
      <Tab.Panel>
        {isError ? (
          <ErrorLoadingRoot className={styles.placeholder} onReload={refetch} />
        ) : (
          <RootFoldersResult
            query={{
              ...props,
              data: getSearchResultItem(data?.items, 'topic'),
            }}
            layoutType={viewType}
          />
        )}
      </Tab.Panel>
      <Tab.Panel>
        {isError ? (
          <FolderLoadingError onReload={refetch} />
        ) : (
          <FoldersResult
            query={{
              ...props,
              data: getSearchResultItem(data?.items, 'folder'),
            }}
            layoutType={viewType}
          />
        )}
      </Tab.Panel>
      <Tab.Panel>
        {isError ? (
          <LayoutLoadingError onReload={refetch} />
        ) : (
          <LayoutDataView
            query={{
              ...props,
              data: getSearchResultItem(data?.items, 'layout'),
            }}
          />
        )}
      </Tab.Panel>
    </Tab.Panels>
  );
};
