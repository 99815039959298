import { skipToken } from '@reduxjs/toolkit/dist/query';
import { FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';
import { LayoutArrowNavigation, LayoutDetailRouteQueryParams, useFetchLayoutDetailQuery } from 'features/layouts';
import { LayoutDetailImagePanel } from '../LayoutDetailImagePanel/LayoutDetailImagePanel';
import { LayoutDetailInfoPanel } from '../LayoutDetailInfoPanel/LayoutDetailInfoPanel';

export const LayoutDetailDataView: FunctionComponent = () => {
  const { layoutId } = useParams<LayoutDetailRouteQueryParams>();
  const { data } = useFetchLayoutDetailQuery(layoutId ?? skipToken);

  return (
    <LayoutArrowNavigation layout={data}>
      <LayoutDetailImagePanel layout={data} />
      <LayoutDetailInfoPanel layout={data} />
    </LayoutArrowNavigation>
  );
};
