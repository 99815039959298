import * as yup from 'yup';
import { SchemaOf } from 'yup';
import { getFileBoxValidator, uploadMaxFileSizeMB, uploadSupportedMimeImageTypes } from 'features/file';
import { MaterialsKind } from 'features/folders';
import { LayoutEditFormFields, LayoutStatus } from './layoutEntity';

const maxLenDescription = 15_000;

export const LayoutEditDtoValidationSchema = (): SchemaOf<LayoutEditFormFields> => {
  return yup.object().shape({
    layoutNumber: yup.number().optional(),
    archiveName: yup.string().optional(),
    descriptionRu: yup.string().optional().max(maxLenDescription),
    descriptionEn: yup.string().optional().max(maxLenDescription),
    status: yup.mixed<LayoutStatus>().oneOf(Object.values(LayoutStatus)),
    materialsKinds: yup
      .array()
      .of(yup.mixed<MaterialsKind>().oneOf(Object.values(MaterialsKind)))
      .notRequired(),
    tags: yup.array().of(yup.mixed()).notRequired(),
    images: yup.array().of(yup.mixed()).notRequired(),
    folders: yup.array().of(yup.mixed()).notRequired(),
    fileBox: getFileBoxValidator({
      mimeTypes: uploadSupportedMimeImageTypes,
      fileSizeMB: uploadMaxFileSizeMB,
    }),
  });
};
