import cn from 'classnames';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { favoritesAccessRuleService } from 'features/role';
import { ButtonKind } from 'ui-library/components/Button';
import { Icon } from 'ui-library/components/Icon';
import { IconButton } from 'ui-library/components/IconButton';
import styles from './FavoriteButtonGroup.module.scss';
import { FavoriteButtonGroupProps } from './FavoriteButtonGroupProps';

export const FavoriteButtonGroup: FunctionComponent<FavoriteButtonGroupProps> = (props) => {
  const { isFavorite, onAddToFavorites, onRemoveFromFavorites, className, variant } = props;
  const { t } = useTranslation();

  return (
    <>
      {isFavorite === false && favoritesAccessRuleService.canCreate() && (
        <IconButton
          className={cn(className)}
          variant={variant}
          as={ButtonKind.BUTTON}
          title={t('actions.addToFavorite')}
          onClick={onAddToFavorites}
        >
          <Icon name='favorite' />
        </IconButton>
      )}
      {isFavorite === true && favoritesAccessRuleService.canDelete() && (
        <IconButton
          className={cn(className, styles.active)}
          variant={variant}
          as={ButtonKind.BUTTON}
          title={t('actions.removeFromFavorite')}
          onClick={onRemoveFromFavorites}
        >
          <Icon name='favorite' />
        </IconButton>
      )}
    </>
  );
};
