import { FunctionComponent } from 'react';
import { LayoutLoadingError } from 'features/layouts';
import { searchLayoutActions } from 'features/search';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux';
import { Pagination } from 'ui-library/components/Pagination';
import { LayoutCardList } from '../LayoutCardList/LayoutCardList';
import styles from './LayoutDataView.module.scss';
import { LayoutDataViewProps } from './LayoutDataViewProps';

export const LayoutDataView: FunctionComponent<LayoutDataViewProps> = (props) => {
  const { query } = props;
  const dispatch = useAppDispatch();
  const { currentPage, pageSize } = useAppSelector((state) => state.searchLayout.paginationData);

  return (
    <div className={styles.root}>
      <div className={styles.listWrap}>
        {query.isError ? <LayoutLoadingError onReload={query.refetch} /> : <LayoutCardList {...query} />}
      </div>
      <div className={styles.paginationWrap}>
        <Pagination
          totalItems={query.data?.totalCount}
          pageSize={pageSize}
          onPageChanged={(page) => dispatch(searchLayoutActions.pageChanged({ page }))}
          currentPage={currentPage}
          scrollToStartAfterPageChanged
        />
      </div>
    </div>
  );
};
