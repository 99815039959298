import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { errorBoundaryActions } from 'features/errorBoundary';
import { useAppDispatch } from 'infrastructure/redux';
import { ROUTE_PATHS } from 'infrastructure/routes';
import { Icon } from 'ui-library/components/Icon';
import { Button, ButtonKind, ButtonVariant } from 'ui-library/v1/components/Button';
import { ZeroScreenWrapper } from 'ui-library/v1/components/ZeroScreen';
import styles from './NotFoundScreen.module.scss';

export const NotFoundScreen: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleClick = () => {
    dispatch(errorBoundaryActions.setHttpStatusCode(undefined));
    navigate(ROUTE_PATHS.HOME);
  };

  return (
    <div className={styles.root}>
      <ZeroScreenWrapper
        renderIcon={(props) => <Icon name='error404' size='none' {...props} />}
        renderButton={({ className }) => (
          <Button
            className={className}
            as={ButtonKind.BUTTON}
            onClick={handleClick}
            variant={ButtonVariant.SECONDARY}
            size='small'
          >
            {t('actions.goToMain')}
          </Button>
        )}
        title={t('commonPages.notFound.title')}
        message={t('commonPages.notFound.message')}
      />
    </div>
  );
};
