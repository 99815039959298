import { FC, useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CenturyPeriod, ReferenceBookEditFormCenturyFormFields } from 'features/referenceBook/types';
import { useForm } from 'infrastructure/form';
import { FormCol, FormRow } from 'ui-library/components/Form';
import { Modal } from 'ui-library/components/Modal';
import { RadioField } from 'ui-library/components/RadioField';
import { RadioGroup } from 'ui-library/components/RadioGroup';
import { NumberTextField } from 'ui-library/components/TextField';
import { nameof } from 'utils';
import styles from './ReferenceBookEditFormCentury.module.scss';
import { ReferenceBookEditFormCenturyProps } from './ReferenceBookEditFormCenturyProps';
import { ReferenceBookEditFormCenturyFormValidationSchema } from './ReferenceBookEditFormCenturyValidation';

const periodField = nameof<ReferenceBookEditFormCenturyFormFields>('period');
const centuryField = nameof<ReferenceBookEditFormCenturyFormFields>('century');

export const ReferenceBookEditFormCentury: FC<ReferenceBookEditFormCenturyProps> = ({ renderActions, onSubmit }) => {
  const { t } = useTranslation();
  const methods = useForm<ReferenceBookEditFormCenturyFormFields>({
    defaultValues: { period: CenturyPeriod.POSITIVE },
    schema: ReferenceBookEditFormCenturyFormValidationSchema(),
  });

  const { handleSubmit, formState, register, watch, setFocus } = methods;

  const submit = async ({ period, century }: ReferenceBookEditFormCenturyFormFields) => {
    const valueToSubmit = period === CenturyPeriod.NEGATIVE ? `-${century}` : century;

    return onSubmit({
      captionRu: valueToSubmit,
      captionEn: valueToSubmit,
    });
  };

  useEffect(() => {
    setFocus(centuryField);
  }, [setFocus]);

  return (
    <FormProvider {...methods}>
      <Modal.Form stopPropagation onSubmit={handleSubmit(submit)}>
        <Modal.Content className={styles.root}>
          <FormRow>
            <FormCol>
              <RadioGroup>
                <RadioField
                  label={t('referenceBook.form.century.label.period.current')}
                  RadioProps={{
                    ...register(periodField),
                    value: CenturyPeriod.POSITIVE,
                  }}
                />
                <RadioField
                  label={t('referenceBook.form.century.label.period.previous.rich')}
                  RadioProps={{
                    ...register(periodField),
                    value: CenturyPeriod.NEGATIVE,
                  }}
                />
              </RadioGroup>
            </FormCol>
          </FormRow>
          <FormRow>
            <FormCol>
              <NumberTextField
                label={t('referenceBook.form.century.label.century')}
                error={formState.errors[centuryField]?.message}
                required
                LayoutProps={{
                  endAdornment: watch(periodField) === CenturyPeriod.NEGATIVE && (
                    <span>{t('referenceBook.form.century.label.period.previous.short')}</span>
                  ),
                }}
                InputProps={{ ...register(centuryField) }}
              />
            </FormCol>
          </FormRow>
        </Modal.Content>
        <Modal.Actions>{renderActions(formState)}</Modal.Actions>
      </Modal.Form>
    </FormProvider>
  );
};
