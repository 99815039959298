import cn from 'classnames';
import React, { forwardRef, PropsWithChildren } from 'react';
import { Button, ButtonProps } from '../Button';
import styles from './ActionButton.module.scss';

/**
 * @deprecated
 */
export const ActionButton = forwardRef<HTMLAnchorElement | HTMLButtonElement, PropsWithChildren<ButtonProps>>(
  (props, ref) => {
    const { className, ...rest } = props;

    return <Button {...rest} className={cn(styles.actionButton, className)} ref={ref} />;
  },
);
