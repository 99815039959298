import { FunctionComponent } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { addToFavoritesActions, AddToFavoritesFormFields } from 'features/favorites';
import { useAppDispatch } from 'infrastructure/redux';
import { ActionButton } from 'ui-library/components/ActionButton';
import { ButtonKind, ButtonVariant } from 'ui-library/components/Button';
import styles from './AddToFavoritesActions.module.scss';
import { AddToFavoritesActionsProps } from './AddToFavoritesActionsProps';

export const AddToFavoritesActions: FunctionComponent<AddToFavoritesActionsProps> = (props) => {
  const { favoriteArtifact } = props;
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const {
    getValues,
    watch,
    formState: { isSubmitting },
  } = useFormContext<AddToFavoritesFormFields>();
  const { groupIDsToAddTo } = getValues();

  const handleCancel = () => {
    dispatch(addToFavoritesActions.dispose());
  };

  const getSubmitTitle = () => {
    if (groupIDsToAddTo.length > 1) {
      return t('favorites.addToFavorite.actions.toGroupMany');
    } else if (groupIDsToAddTo.length === 1) {
      return t('favorites.addToFavorite.actions.toGroupOne');
    }

    return t('favorites.addToFavorite.actions.toFavorite');
  };

  watch('groupIDsToAddTo');
  const alreadyInFavorites = groupIDsToAddTo.length === 0 && favoriteArtifact?.isFavorite;

  return (
    <>
      <ActionButton
        as={ButtonKind.BUTTON}
        type='submit'
        disabled={alreadyInFavorites}
        loading={isSubmitting}
        title={alreadyInFavorites ? t('favorites.addToFavorite.alreadyAdded') : undefined}
        className={styles.addToFavoritesButton}
      >
        {getSubmitTitle()}
      </ActionButton>
      <ActionButton as={ButtonKind.BUTTON} variant={ButtonVariant.SECONDARY} onClick={handleCancel}>
        {t('actions.cancel')}
      </ActionButton>
    </>
  );
};
