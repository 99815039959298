import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { apiErrorMessageMatcher } from 'features/file';
import { LayoutEditFormFields } from 'features/layouts';
import { LayoutEditDtoValidationSchema } from 'features/layouts/layoutEditValidation';
import { TagsManageContext, useTagsManage } from 'features/tags';
import { useForm } from 'infrastructure/form';
import { useTranslateEnum } from 'infrastructure/i18n';
import { parseApiError } from 'infrastructure/network';
import { Modal } from 'ui-library/components/Modal';
import { Tab } from 'ui-library/components/Tab';
import { LayoutEditFormImagesTab } from '../LayoutEditForm/LayoutEditFormImagesTab/LayoutEditFormImagesTab';
import { LayoutViewTab } from '../LayoutView/LayoutViewTab';
import { LayoutEditFormInfoTab } from './LayoutEditFormInfoTab/LayoutEditFormInfoTab';
import { LayoutEditFormProps } from './LayoutEditFormProps';

export const LayoutEditForm = (props: LayoutEditFormProps) => {
  const {
    renderActions,
    onSubmit,
    isCreate,
    selectedTab,
    onTabChange,
    defaultValues = {
      tags: [],
    },
  } = props;
  const { t } = useTranslation();
  const { translateValue } = useTranslateEnum('LayoutEditTab', LayoutViewTab);

  const methods = useForm<LayoutEditFormFields>({
    defaultValues,
    schema: LayoutEditDtoValidationSchema(),
  });

  const { handleSubmit, formState, setError } = methods;

  const tagsManage = useTagsManage({
    initialTags: defaultValues.tags || [],
    disableExtend: true,
  });
  const { tags, isRemoved } = tagsManage;

  const submit = async (values: LayoutEditFormFields) => {
    return onSubmit({
      ...values,
      tags: tags.filter((currentTag) => !isRemoved(currentTag)),
    }).catch((e) => {
      const error = parseApiError(e);
      const messageKey = apiErrorMessageMatcher(error);

      if (messageKey) {
        setError('fileBox.file', {
          message: t(messageKey as never),
        });
      }
    });
  };

  return (
    <FormProvider {...methods}>
      <TagsManageContext.Provider value={tagsManage}>
        <Modal.Form onSubmit={handleSubmit(submit)}>
          <Modal.Content>
            <Tab.Group as='div' selectedIndex={selectedTab} onChange={onTabChange}>
              <Tab.List>
                <Tab>{translateValue(LayoutViewTab.INFORMATION)}</Tab>
                <Tab>{translateValue(LayoutViewTab.IMAGES)}</Tab>
              </Tab.List>
              <Tab.Panels>
                <Tab.Panel>
                  <LayoutEditFormInfoTab isCreate={isCreate} />
                </Tab.Panel>
                <Tab.Panel>
                  <LayoutEditFormImagesTab />
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          </Modal.Content>
          <Modal.Actions>{renderActions(formState)}</Modal.Actions>
        </Modal.Form>
      </TagsManageContext.Provider>
    </FormProvider>
  );
};
