import cn from 'classnames';
import _ from 'lodash';
import { FunctionComponent, useRef } from 'react';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import {
  FolderArchiveDetailViewType,
  folderArchiveListActions,
  FolderDetailQuerySearchParams,
  FolderListHeaderActions,
  FolderResponseDto,
} from 'features/folders';
import { ListLayout } from 'features/list';
import { FIRST_PAGE } from 'features/pagination';
import { useIsSticky } from 'features/tracking';
import { useLanguageRelevantData, useTranslateEnum } from 'infrastructure/i18n';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux';
import { Icon } from 'ui-library/components/Icon';
import { Tab } from 'ui-library/components/Tab';
import styles from './FolderArchiveDetailDataHeader.module.scss';
import { FolderArchiveDetailDataHeaderProps } from './FolderArchiveDetailDataHeaderProps';

export const FolderArchiveDetailDataHeader: FunctionComponent<FolderArchiveDetailDataHeaderProps> = (props) => {
  const dispatch = useAppDispatch();
  const { tab, folder } = props;
  const { translateValue } = useTranslateEnum('FolderArchiveDetailViewType', FolderArchiveDetailViewType);
  const { matchProp } = useLanguageRelevantData();
  const headerRef = useRef<HTMLDivElement | null>(null);
  const isSticky = useIsSticky(headerRef);
  const { sortDirection } = useAppSelector((state) => state.folderArchiveList.sortingInfo);
  const layoutType = useAppSelector((state) => state.folderArchiveList.layoutType);
  const location = useLocation();
  const navigate = useNavigate();

  const toggleSort = () => {
    dispatch(
      folderArchiveListActions.sortChanged({
        sortField: matchProp<keyof FolderResponseDto>('captionRu', 'captionEn'),
      }),
    );
    const params: FolderDetailQuerySearchParams = {
      tab: String(tab),
      page: String(FIRST_PAGE),
    };
    navigate({
      pathname: location.pathname,
      search: `?${createSearchParams(params)}`,
    });
  };

  const handleChangeLayout = (layout: ListLayout) => {
    dispatch(folderArchiveListActions.changeLayoutType(layout));
  };

  const getFolderTitle = () => {
    return _.truncate(matchProp(folder?.captionRu, folder?.captionEn), { length: 40 });
  };

  return (
    <Tab.List ref={(node) => (headerRef.current = node)} className={cn(styles.root, { [styles.sticky]: isSticky })}>
      <Tab className={isSticky ? styles.tab : undefined}>{translateValue(FolderArchiveDetailViewType.FOLDERS)}</Tab>
      <Tab className={isSticky ? styles.tab : undefined}>{translateValue(FolderArchiveDetailViewType.LAYOUTS)}</Tab>
      {isSticky && <Icon className={styles.icon} name='logoHeader' size='none' />}
      {isSticky && <h1 className={styles.title}>{getFolderTitle()}</h1>}
      {tab === FolderArchiveDetailViewType.FOLDERS && (
        <FolderListHeaderActions
          onChangeLayout={handleChangeLayout}
          onToggleSort={toggleSort}
          layoutType={layoutType}
          sortDirection={sortDirection}
        />
      )}
    </Tab.List>
  );
};
