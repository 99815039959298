import { MutableRefObject, useEffect, useState } from 'react';

export const useIsSticky = (element: MutableRefObject<HTMLElement | null>) => {
  const [isSticky, setSticky] = useState(false);

  useEffect(() => {
    const el = element.current;

    if (!el) {
      return;
    }

    const observer = new IntersectionObserver(([e]) => setSticky(e.intersectionRatio < 1), { threshold: [1] });

    observer.observe(el);

    return () => {
      observer.unobserve(el);
    };
  }, [element]);

  return isSticky;
};
