import { ChangeEvent, forwardRef, useState } from 'react';
import { InputProps } from 'ui-library/components/Input';
import { TextField } from '..';
import { TextFieldProps } from '../TextFieldProps';
import { isInputValid } from './NumberTextFieldValidation';

export const NumberTextField = forwardRef<HTMLInputElement, TextFieldProps>(({ InputProps, ...rest }, ref) => {
  const [inputVal, setInputValue] = useState<InputProps['value']>(InputProps?.value || '');

  const onChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    return isInputValid(value) && setInputValue(value);
  };

  return (
    <TextField
      {...rest}
      ref={ref}
      InputProps={{
        ...InputProps,
        type: 'tel',
        inputMode: 'tel',
        autoComplete: 'off',
        value: inputVal,
        onChange,
      }}
    />
  );
});
