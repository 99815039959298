import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useModalState } from 'features/modal';
import { tagDeleteActions, tagEditActions, TagsOrigin } from 'features/tags';
import { TagsManageContext } from 'features/tags/contexts';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux';
import { Label } from 'ui-library/components/Label';
import { useUpdateEffect } from 'utils/hooks/useUpdateEffect';
import { TagsAutocomplete } from '../TagsAutocomplete';
import { TagsCreate } from '../TagsCreate';
import { TagsDelete } from '../TagsDelete';
import { TagsEdit } from '../TagsEdit';
import { TagsList } from '../TagsList';
import styles from './TagsEditField.module.scss';
import { TagsEditFieldProps } from './TagsEditFieldProps';

export const TagsEditField = (props: TagsEditFieldProps) => {
  const { onChange } = props;

  const tagEditState = useAppSelector((state) => state.tagEdit);
  const tagDeleteState = useAppSelector((state) => state.tagDelete);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const createModalState = useModalState();
  const tagsManage = useContext(TagsManageContext);
  const { tags } = tagsManage;

  useUpdateEffect(() => {
    onChange(tags);
  }, [tags]);

  const getAiTags = () => {
    return tags.filter((tag) => tag.origin === TagsOrigin.TAG_AI);
  };

  const getUserTags = () => {
    return tags.filter((tag) => tag.origin === TagsOrigin.TAG_USER);
  };

  const handleCloseTagsEdit = () => {
    dispatch(tagEditActions.close());
    dispatch(tagEditActions.selectTag(undefined));
  };

  const handleCloseTagsDelete = () => {
    dispatch(tagDeleteActions.close());
    dispatch(tagDeleteActions.selectTag(undefined));
  };

  return (
    <TagsManageContext.Provider value={tagsManage}>
      <div className={styles.root}>
        <TagsAutocomplete selectedTags={tags} onCreate={createModalState.open} />
        {getUserTags().length !== 0 ? (
          <>
            <Label label={t('tags.form.labelEditable')} className={styles.label} />
            <TagsList tags={getUserTags()} />
          </>
        ) : undefined}
        {getAiTags().length !== 0 ? (
          <>
            <Label label={t('tags.form.labelUnEditable')} className={styles.label} />
            <TagsList tags={getAiTags()} disableActions={true} />
          </>
        ) : undefined}

        <TagsCreate isOpen={createModalState.isOpen} close={createModalState.close} />

        {tagEditState.selectedTag && (
          <TagsEdit tag={tagEditState.selectedTag} isOpen={tagEditState.isOpen} close={handleCloseTagsEdit} />
        )}

        {tagDeleteState.selectedTag && (
          <TagsDelete tag={tagDeleteState.selectedTag} isOpen={tagDeleteState.isOpen} close={handleCloseTagsDelete} />
        )}
      </div>
    </TagsManageContext.Provider>
  );
};
