import { Fragment, FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { BreadcrumbComponentProps } from 'use-react-router-breadcrumbs';
import { BreadcrumbDivider } from 'features/breadcrumbs';
import { FolderDetailRouteQueryParams, useFolderBreadcrumbs } from 'features/folders';
import { useLanguageRelevantData } from 'infrastructure/i18n';
import { getFolderDetailRoute } from 'infrastructure/routes/paths';
import styles from './FolderDetailBreadcrumb.module.scss';

export const FolderDetailBreadcrumb: FunctionComponent<BreadcrumbComponentProps<keyof FolderDetailRouteQueryParams>> = (
  props,
) => {
  const { matchProp } = useLanguageRelevantData();
  const { folderId } = props.match.params;
  const breadcrumbs = useFolderBreadcrumbs({ folderId });

  return (
    <>
      {breadcrumbs.map((x, idx) => (
        <Fragment key={x.id}>
          <Link className={styles.link} to={getFolderDetailRoute(x.id, x.partition)}>
            {matchProp(x.captionRu, x.captionEn)}
          </Link>
          {breadcrumbs.length - 1 !== idx && <BreadcrumbDivider className={styles.breadcrumbColor} />}
        </Fragment>
      ))}
    </>
  );
};
