import classNames from 'classnames';
import { FormEvent, FunctionComponent } from 'react';
import { Form } from 'ui-library/components/Form';
import styles from './ModalForm.module.scss';
import { ModalFormProps } from './ModalFormProps';

export const ModalForm: FunctionComponent<ModalFormProps> = (props) => {
  const { className, stopPropagation, onSubmit, ...restProps } = props;

  const onInternalSubmit = (event: FormEvent<HTMLFormElement>) => {
    if (stopPropagation) {
      event.stopPropagation();
    }

    onSubmit?.(event);
  };

  return <Form className={classNames(className, styles.root)} onSubmit={onInternalSubmit} {...restProps} />;
};
