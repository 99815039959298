import { FunctionComponent, useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { changeOrderActions } from 'features/changeOrder';
import { useChangeOrder } from 'features/changeOrder/hooks';
import { NotDefinedParamException } from 'infrastructure/exceptions';
import { useForm } from 'infrastructure/form';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux';
import { ActionButton } from 'ui-library/components/ActionButton';
import { ButtonKind, ButtonVariant } from 'ui-library/components/Button';
import { FormCol, FormRow } from 'ui-library/components/Form';
import { Modal } from 'ui-library/components/Modal';
import { NumberTextField } from 'ui-library/components/TextField';
import { useUpdateFolderOrderMutation, useUpdateImageOrderMutation } from '../../changeOrderApi';
import styles from './ChangeOrderModal.module.scss';

type FormFields = {
  order: number;
};

export const ChangeOrderModal: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const { isOpen } = useAppSelector((state) => state.changeOrder);
  const { changedArtifacts } = useAppSelector((state) => state.changeOrder);
  const { t } = useTranslation();
  const methods = useForm<FormFields>({
    schema: yup.object({
      order: yup.number().typeError(t('validation.required')).min(1),
    }),
  });

  const { handleSubmit, formState, register, setValue, setError } = methods;
  const [triggerImage] = useUpdateImageOrderMutation();
  const [triggerFolder] = useUpdateFolderOrderMutation();
  const { handleOrderSuccessful, handleOrderError } = useChangeOrder();

  const handleClose = () => {
    dispatch(changeOrderActions.dispose());
  };

  useEffect(() => {
    changedArtifacts && setValue('order', changedArtifacts.order);
    setError('order', {});
  }, [changedArtifacts]);

  const submit = async ({ order }: FormFields) => {
    if (!changedArtifacts) {
      throw new NotDefinedParamException('favorite artifact');
    }
    try {
      if (changedArtifacts.type === 'image') {
        await triggerImage({
          folderID: changedArtifacts.parentId,
          imageID: changedArtifacts.imageID,
          order,
        }).unwrap();
        handleOrderSuccessful();
      }

      if (changedArtifacts.type === 'folder') {
        await triggerFolder({
          parentID: changedArtifacts.parentId,
          folderID: changedArtifacts.folderID,
          order,
        }).unwrap();
        handleOrderSuccessful();
      }

      handleClose();
    } catch (e) {
      handleOrderError();
    }
  };

  return (
    <Modal variant='popup' open={isOpen} onClose={handleClose} closeOnChangeLocation>
      <FormProvider {...methods}>
        <Modal.Form stopPropagation onSubmit={handleSubmit(submit)}>
          <Modal.Title>{t('actions.changeOrder')}</Modal.Title>
          <Modal.Content className={styles.root}>
            <FormRow>
              <FormCol>
                <NumberTextField
                  error={formState.errors['order']?.message}
                  required
                  InputProps={{ ...register('order') }}
                  LayoutProps={{ className: styles.fieldCol }}
                />
              </FormCol>
            </FormRow>
          </Modal.Content>
          <Modal.Actions>
            <ActionButton as={ButtonKind.BUTTON} type='submit' loading={false}>
              {t('actions.save')}
            </ActionButton>
            <ActionButton as={ButtonKind.BUTTON} variant={ButtonVariant.SECONDARY} onClick={handleClose}>
              {t('actions.cancel')}
            </ActionButton>
          </Modal.Actions>
        </Modal.Form>
      </FormProvider>
    </Modal>
  );
};
