export const API_URL = process.env.REACT_APP_API_HOST;

export const URL_FACTORY = {
  AUTH: 'auth',
  REFRESH: 'auth/token_refresh',
  USERS: {
    ROOT: '/users',
    DETAIL: (userID: string) => `${URL_FACTORY.USERS.ROOT}/${userID}`,
  },
  FOLDERS: {
    ROOT: '/folders',
    CHILDREN: (folderID: string) => `${URL_FACTORY.FOLDERS.ROOT}/${folderID}/children`,
    DETAIL: (folderID: string) => `${URL_FACTORY.FOLDERS.ROOT}/${folderID}`,
    SEARCH: () => `${URL_FACTORY.FOLDERS.ROOT}/search`,
    PATH: (folderID: string) => `${URL_FACTORY.FOLDERS.ROOT}/${folderID}/path`,
    GALLERY: (folderID: string) => `${URL_FACTORY.FOLDERS.ROOT}/${folderID}/gallery`,
    LINKED_LAYOUTS: (folderID: string) => `${URL_FACTORY.FOLDERS.ROOT}/${folderID}/layouts`,
    LINKED_IMAGES: (folderID: string) => `${URL_FACTORY.FOLDERS.ROOT}/${folderID}/images`,
  },
  LAYOUTS: {
    ROOT: '/layouts',
    DETAIL: (layoutID: string) => `${URL_FACTORY.LAYOUTS.ROOT}/${layoutID}`,
    LINKED_IMAGES: (layoutID: string) => `${URL_FACTORY.LAYOUTS.ROOT}/${layoutID}/images`,
    LINKED_FOLDERS: (layoutID: string) => `${URL_FACTORY.LAYOUTS.ROOT}/${layoutID}/folders`,
  },
  FILES: {
    ROOT: '/files',
    UPLOAD: () => URL_FACTORY.FILES.ROOT,
    DOWNLOADS: () => `${URL_FACTORY.FILES.ROOT}/downloads`,
    VIEW_DOWNLOADS: () => `${URL_FACTORY.FILES.DOWNLOADS()}/view`,
    DOWNLOAD_DETAIL: (taskID: string) => `${URL_FACTORY.FILES.DOWNLOADS()}/${taskID}`,
    DOWNLOAD_RETRY: (taskID: string) => `${URL_FACTORY.FILES.DOWNLOAD_DETAIL(taskID)}/retry`,
  },
  IMAGES: {
    ROOT: '/images',
    DETAIL: (imageID: string) => `${URL_FACTORY.IMAGES.ROOT}/${imageID}`,
    DOWNLOAD_METADATA: () => `${URL_FACTORY.IMAGES.ROOT}/metadata`,
    UPLOAD: () => `${URL_FACTORY.IMAGES.ROOT}/update-bulk`,
    LINKED_LAYOUTS: (imageID: string) => `${URL_FACTORY.IMAGES.ROOT}/${imageID}/layouts`,
    LINKED_FOLDERS: (imageID: string) => `${URL_FACTORY.IMAGES.ROOT}/${imageID}/folders`,
  },
  FAVORITES: {
    ROOT: '/favourites',
    CONTENTS: () => `${URL_FACTORY.FAVORITES.ROOT}/contents`,
    GROUPS: () => `${URL_FACTORY.FAVORITES.ROOT}/groups`,
    GROUP_DETAIL: (groupID: string) => `${URL_FACTORY.FAVORITES.GROUPS()}/${groupID}`,
  },
  SEARCH: 'search',
  SEARCHAI: 'search-ai',
  REFERENCE_BOOKS: {
    ROOT: '/reference_books',
    SEARCH: () => `${URL_FACTORY.REFERENCE_BOOKS.ROOT}/search`,
    DETAIL: (referenceBookId: string) => `${URL_FACTORY.REFERENCE_BOOKS.ROOT}/${referenceBookId}`,
    LIST: () => `${URL_FACTORY.REFERENCE_BOOKS.ROOT}/list`,
  },
  TAGS: {
    ROOT: '/tags',
    SEARCH: () => `${URL_FACTORY.TAGS.ROOT}/search`,
    LIST: () => `${URL_FACTORY.TAGS.ROOT}/list`,
    DETAIL: (tagID: string) => `${URL_FACTORY.TAGS.ROOT}/${tagID}`,
  },
};
