import * as yup from 'yup';
import { SchemaOf } from 'yup';
import { getFileBoxValidator, uploadMaxFileSizeMB, uploadSupportedMimeImageTypes } from 'features/file';
import { referenceBookSchema } from 'features/referenceBook';
import { ImageCenturyHalf, ImageEditFormFields, ImageStatus } from './imageEntity';

const maxLenAdditional = 15_000;
const maxLenDimensions = 100;

export const ImageEditDtoValidationSchema = (): SchemaOf<ImageEditFormFields> => {
  return yup.object().shape({
    fileBox: getFileBoxValidator({
      mimeTypes: uploadSupportedMimeImageTypes,
      fileSizeMB: uploadMaxFileSizeMB,
    }),
    archiveName: yup.string().optional(),
    captionRu: yup.string().required(),
    captionEn: yup.string().required(),
    centuryHalf: yup
      .mixed()
      .oneOf([...Object.values(ImageCenturyHalf), null])
      .optional(),
    dimensionsRu: yup.string().optional().max(maxLenDimensions),
    dimensionsEn: yup.string().optional().max(maxLenDimensions),
    additionalInformationRu: yup.string().optional().max(maxLenAdditional),
    additionalInformationEn: yup.string().optional().max(maxLenAdditional),
    additionalInformationOriginal: yup.string().optional().max(maxLenAdditional),
    relatedMaterial: yup.string().optional().max(maxLenAdditional),
    author: referenceBookSchema.optional(),
    century: referenceBookSchema.optional(),
    year: referenceBookSchema.optional(),
    artForm: referenceBookSchema.optional(),
    materialsTechnics: referenceBookSchema.optional(),
    status: yup.mixed<ImageStatus>().oneOf(Object.values(ImageStatus)).required(),
    tags: yup.array().of(yup.mixed()).notRequired(),
    layouts: yup.array().of(yup.mixed()).notRequired(),
    folders: yup.array().of(yup.mixed()).notRequired(),
  });
};
