/* eslint-disable no-magic-numbers */
import { FunctionComponent } from 'react';
import { VirtuosoGrid } from 'react-virtuoso';
import { FolderArtifactListFooter } from 'features/fileNavigator/folders';
import { ArtifactImageList, ArtifactImageListItem } from 'features/fileNavigator/images';
import {
  FetchFolderImagesQueryData,
  ImageLoadingError,
  ImageResponseDto,
  ImageScaleCard,
  useFetchFolderImagesQuery,
} from 'features/images';
import { ImageCardSkeleton } from 'features/images/components';
import { useInfiniteLoading } from 'features/pagination';
import { SortDirection } from 'features/sorting';
import { NotDefinedParamException } from 'infrastructure/exceptions';
import { useLanguageRelevantData } from 'infrastructure/i18n';
import { ListApiResponse } from 'infrastructure/network';
import { useAppSelector } from 'infrastructure/redux';
import { LinkingArtifactEmptyFolder } from '../LinkingArtifactEmptyFolder/LinkingArtifactEmptyFolder';
import styles from './LinkingArtifactImagesTab.module.scss';

export const LinkingArtifactImagesTab: FunctionComponent = () => {
  const { selectedFolderId } = useAppSelector((state) => state.linkingArtifacts);
  const { matchProp } = useLanguageRelevantData();

  if (!selectedFolderId) {
    throw new NotDefinedParamException('Folder should be selected');
  }

  const { readMore, isError, isFetching, isLoading, refresh, data } = useInfiniteLoading<
    ImageResponseDto,
    ListApiResponse<ImageResponseDto>,
    FetchFolderImagesQueryData
  >(
    useFetchFolderImagesQuery,
    (state) => ({
      ...state,
      folderID: selectedFolderId,
      sortFields: [matchProp('captionRu', 'captionEn')],
      sortDirection: SortDirection.DESC,
    }),
    (result) => result,
    {
      pageSize: 40,
    },
    {
      skip: !selectedFolderId,
    },
  );

  const handleRefresh = () => {
    refresh();
  };

  const fetchData = data ?? [];
  const isEmpty = !isError && fetchData.length === 0 && !isFetching;

  if (isLoading) {
    return <FolderArtifactListFooter className={styles.root} />;
  }

  if (isEmpty) {
    return <LinkingArtifactEmptyFolder />;
  }

  return isError ? (
    <ImageLoadingError onReload={handleRefresh} />
  ) : (
    <VirtuosoGrid
      className={styles.root}
      totalCount={fetchData.length}
      overscan={200}
      endReached={readMore}
      components={{
        Item: ArtifactImageListItem,
        List: ArtifactImageList,
        ScrollSeekPlaceholder: ImageCardSkeleton,
      }}
      itemContent={(index) => <ImageScaleCard image={fetchData[index]} />}
      scrollSeekConfiguration={{
        enter: (velocity) => Math.abs(velocity) > 200,
        exit: (velocity) => Math.abs(velocity) < 30,
      }}
    />
  );
};
