import { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  activeFileDownloadTasksActions,
  createFileDownloadTaskActions,
  FileDownloadAgreement,
  FileDownloadImageFormat,
  useDownloadFilesMutation,
} from 'features/file';
import { useCreateFileDownloadTaskToast } from 'features/file/hooks';
import { fileDownloadService } from 'features/file/services';
import { useModalState } from 'features/modal';
import { NotDefinedParamException } from 'infrastructure/exceptions';
import { useCurrentLanguage } from 'infrastructure/i18n';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux';
import { Modal } from 'ui-library/components/Modal';
import { FileFormatCardList } from '../FileFormatCardList/FileFormatCardList';
import styles from './CreateFileDownloadTask.module.scss';

export const CreateFileDownloadTask: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const currentLang = useCurrentLanguage();
  const { isOpen, selectedArtifact } = useAppSelector((state) => state.createFileDownloadTask);
  const [selectedImageFormat, setSelectedImageFormat] = useState<FileDownloadImageFormat | undefined>(undefined);
  const {
    isOpen: isFileDownloadAgreementOpen,
    close: closeFileDownloadAgreement,
    open: openFileDownloadAgreement,
  } = useModalState();
  const { t } = useTranslation();
  const [trigger] = useDownloadFilesMutation();
  const { showToast } = useCreateFileDownloadTaskToast(selectedArtifact);

  const handleClose = () => {
    dispatch(createFileDownloadTaskActions.setFileArtifact(undefined));
    dispatch(createFileDownloadTaskActions.close());
  };

  const handleChooseFormat = (imageFormat: FileDownloadImageFormat) => {
    setSelectedImageFormat(imageFormat);

    if (fileDownloadService.isUserAgree) {
      handleDownload(imageFormat);
    } else {
      openFileDownloadAgreement();
    }
  };

  const handleDownload = async (imageFormat: FileDownloadImageFormat) => {
    if (!selectedArtifact) {
      throw new NotDefinedParamException('artifact is not selected');
    }

    try {
      await trigger({
        language: currentLang,
        imageFormat,
        artifactID: selectedArtifact.entityId,
        artifactType: selectedArtifact.type,
        fetch: ['file', 'metadata_excel'],
      }).unwrap();
      handleClose();
      dispatch(activeFileDownloadTasksActions.open());
    } catch (e) {
      showToast();
    }
  };

  const handleAgree = () => {
    if (!selectedImageFormat) {
      throw new NotDefinedParamException('image format is not selected');
    }

    handleDownload(selectedImageFormat);
    setSelectedImageFormat(undefined);
  };

  return (
    <>
      <Modal variant='popup' open={isOpen} onClose={handleClose} closeIconName='cross'>
        <Modal.Title>{t('files.createDownloadTask.title')}</Modal.Title>
        <Modal.Content className={styles.root}>
          <FileFormatCardList onChoose={handleChooseFormat} />
        </Modal.Content>
      </Modal>
      <FileDownloadAgreement
        handleAgree={handleAgree}
        close={closeFileDownloadAgreement}
        isOpen={isFileDownloadAgreementOpen}
      />
    </>
  );
};
