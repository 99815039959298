import throttle from 'lodash/throttle';
import { MutableRefObject, useLayoutEffect, useState } from 'react';

const shadowSize = 48;
const divider = 2;
const shadowSizeOffset = shadowSize / divider;
const throttleWaitMs = 16;

export const useShadowCorner = (ref: MutableRefObject<HTMLElement | null>) => {
  const [shadowed, setShadowed] = useState(true);

  useLayoutEffect(() => {
    const control = ref.current;

    if (!control) {
      return;
    }

    const handleScroll = () => {
      const width = control.scrollWidth - control.clientWidth;

      if (control.scrollLeft >= width - shadowSizeOffset) {
        setShadowed(false);
      } else {
        setShadowed(true);
      }
    };

    const throttleHandleScroll = throttle(handleScroll, throttleWaitMs);

    control.addEventListener('scroll', throttleHandleScroll);

    return () => control.removeEventListener('scroll', throttleHandleScroll);
  }, [ref]);

  return shadowed;
};
