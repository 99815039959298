import { skipToken } from '@reduxjs/toolkit/query';
import { FunctionComponent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FilePreviewLabel } from 'features/file';
import { FolderPartition } from 'features/folders/folderEntity';
import { LayoutListItem } from 'features/layouts/components/LayoutListItem/LayoutListItem';
import { SearchRequestItemDto, useDebounce, useSearchQuery } from 'features/search';
import { useCurrentLanguage } from 'infrastructure/i18n';
import { AutocompleteField } from 'ui-library/components/AutocompleteField';
import { LayoutsAutocompleteProps } from '../LayoutsAutocomplete/LayoutsAutocompleteProps';
import styles from './LayoutsAutocomplete.module.scss';

export const LayoutsAutocomplete: FunctionComponent<LayoutsAutocompleteProps> = (props) => {
  const { selectedLayouts, onInternalChange } = props;
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState('');
  const currentLang = useCurrentLanguage();
  const debouncedSearchQuery = useDebounce(searchQuery);
  const searchLayoutsItemQuery: SearchRequestItemDto = {
    type: 'layout',
    limit: 50,
    offset: 0,
  };

  const result = useSearchQuery(
    {
      items: [searchLayoutsItemQuery],
      partition: FolderPartition.ARCHIVE,
      language: currentLang,
      query: debouncedSearchQuery ?? '',
    } ?? skipToken,
  );

  const filteredOptions = useMemo(() => {
    return result?.data?.items[0].layout?.content.filter((layout) => {
      const foundFolder = selectedLayouts.find((currentLayout) => currentLayout.id === layout.id);

      return foundFolder === undefined;
    });
  }, [result, selectedLayouts]);

  return (
    <AutocompleteField
      value={undefined}
      onChange={onInternalChange as never}
      label={t('imageEditForm.layouts.label')}
      placeholder={t('imageEditForm.layouts.placeholder')}
      className={styles.root}
      getOptionLabel={(option) => (
        <LayoutListItem layout={option} view='search' previewLabel={FilePreviewLabel.LAYOUT_ICON} />
      )}
      onChangeInput={setSearchQuery}
      options={filteredOptions ? filteredOptions : []}
      AutocompleteProps={{
        loading: result.isFetching,
        hideArrowIcon: true,
        showNoOptionsMessage: true,
      }}
    />
  );
};
