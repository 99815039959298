import { Combobox, Listbox } from '@headlessui/react';
import { Fragment } from 'react';
import { BaseSelectOptionContainerProps } from './BaseSelectOptionContainerProps';

export const BaseSelectOptionContainer =
  (Component: typeof Listbox.Option | typeof Combobox.Option) =>
  <Option, OptionValue>(props: BaseSelectOptionContainerProps<Option, OptionValue>) => {
    const { option, getOptionValue, children } = props;

    return (
      <Component as={Fragment} value={getOptionValue ? getOptionValue(option) : option}>
        {children}
      </Component>
    );
  };
