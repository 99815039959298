import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { FolderPartition, FolderStatus, MaterialsKind } from 'features/folders';
import { folderAccessRuleService } from 'features/role';
import { SearchScreenResultTab } from 'features/search';
import { TagsSearchLinks } from 'features/tags';
import { useTranslateEnum } from 'infrastructure/i18n';
import { PropertyInfo } from 'ui-library/components/PropertyInfo';
import { hasNestedInfo } from '../../../utils';
import styles from './FolderViewInfoTab.module.scss';
import { FolderViewInfoTabProps } from './FolderViewInfoTabProps';

export const FolderViewInfoTab: FunctionComponent<FolderViewInfoTabProps> = (props) => {
  const { t } = useTranslation();
  const { translateValue: translateFolderStatusValue } = useTranslateEnum('FolderStatus', FolderStatus);
  const { translateValue: translateMaterialsKindValue } = useTranslateEnum('MaterialsKind', MaterialsKind);
  const { folder } = props;

  const nestingChildren = hasNestedInfo(folder?.childrenCount)
    ? t('folders.count', {
        count: folder?.childrenCount,
      })
    : '';
  const nestingLayouts = hasNestedInfo(folder?.layoutCount)
    ? t('layouts.count', {
        count: folder?.layoutCount,
      })
    : '';

  const nestingInfo = [nestingChildren, nestingLayouts].filter(Boolean);

  return (
    <div className={styles.root}>
      {folderAccessRuleService.canViewUI() && (
        <PropertyInfo title={t('folders.properties.status')} value={translateFolderStatusValue(folder?.status)} />
      )}
      <PropertyInfo
        title={t('folders.properties.materialsKind')}
        value={(folder?.materialsKinds ?? []).map((x) => translateMaterialsKindValue(x)).join(', ')}
      />
      <PropertyInfo title={t('folders.properties.nesting')} value={nestingInfo.join(', ')} />
      {folderAccessRuleService.canViewOriginalInfo() && (
        <PropertyInfo
          title={t('folders.properties.tags')}
          value={
            folder?.tags?.length ? (
              <TagsSearchLinks
                tags={folder?.tags}
                tab={folder.isRoot ? SearchScreenResultTab.ROOT_FOLDERS : SearchScreenResultTab.FOLDERS}
                partition={
                  folder.partition === FolderPartition.ARCHIVE ? FolderPartition.ARCHIVE : FolderPartition.MUSEUM
                }
              />
            ) : null
          }
        />
      )}
    </div>
  );
};
