import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const getEnumKeys = (enumObject: Record<string, string | number>) =>
  Object.keys(enumObject).filter((item) => isNaN(Number(item)));

export const getEnumOnlyString = (enumObject: Record<string, string | number>) =>
  getEnumKeys(enumObject).reduce((prev: Record<string, string | number>, curr) => {
    prev[curr] = enumObject[curr];

    return prev;
  }, {});

const getEnumValueKeyMap = (enumObject: Record<string, string | number>) => {
  const keys = getEnumKeys(enumObject);
  const map = new Map<string | number, string>();

  for (const x of keys) {
    map.set(enumObject[x], x);
  }

  return map;
};

export function useTranslateEnum(enumName: string, enumObj: Record<string, string | number>) {
  const { t } = useTranslation();

  const valueKeyMap = useMemo(() => getEnumValueKeyMap(enumObj), [enumObj]);

  const translateValue = (value: string | number | undefined) => {
    if (value === undefined) {
      return '';
    }

    return t(`enums.${enumName}.${valueKeyMap.get(value)}` as never);
  };

  return {
    translateValue,
  };
}
