import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useCreateUserMutation, UserCreateFormFields, UserRole } from 'features/users';
import { userCreateActions } from 'features/users/slices';
import { apiErrorMessageMatcher } from 'features/users/utils';
import { useTranslateEnum } from 'infrastructure/i18n';
import { parseApiError } from 'infrastructure/network';
import { useAppSelector, useAppDispatch } from 'infrastructure/redux';
import { Modal } from 'ui-library/components/Modal';
import { UserCreateForm } from './UserCreateForm/UserCreateForm';

export const UserCreate = () => {
  const dispatch = useAppDispatch();
  const { isOpen } = useAppSelector((state) => state.userCreate);
  const [createUserMutation] = useCreateUserMutation();
  const { translateValue } = useTranslateEnum('UserRole', UserRole);
  const { t } = useTranslation();

  const handleClose = () => {
    dispatch(userCreateActions.close());
  };

  const submit = async (values: UserCreateFormFields) => {
    try {
      const user = await createUserMutation(values).unwrap();

      toast.success(
        t('users.create.successToastMessage', {
          role: translateValue(user.role),
          name: user.name,
          surname: user.surname,
        }),
      );
      handleClose();
    } catch (rawError) {
      const error = parseApiError(rawError);
      const messageKey = apiErrorMessageMatcher(error);

      toast.error(messageKey || 'users.create.failedToastMessage');
    }
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Modal.Title>{t('users.create.title')}</Modal.Title>
      <UserCreateForm onSubmit={submit} onClose={handleClose} />
    </Modal>
  );
};
