import { AnyAction, combineReducers } from '@reduxjs/toolkit';
import { alertReducer } from 'features/alert/slice';
import { AUTH__SIGN_OUT, authApi, authReducer } from 'features/auth';
import { changeOrderApi, changeOrderReducer } from 'features/changeOrder';
import { folderCreateReducer, imageCreateReducer, layoutCreateReducer } from 'features/createArtifact';
import { errorBoundaryReducer } from 'features/errorBoundary';
import {
  addToFavoritesReducer,
  favoriteContentReducer,
  favoriteFolderListReducer,
  favoriteGroupCreateReducer,
  favoriteGroupEditReducer,
  favoriteGroupListReducer,
  favoriteGroupRemoveReducer,
  favoriteImageListReducer,
  favoriteLayoutListReducer,
  favoriteRootFolderListReducer,
  favoritesApi,
  removeFromFavoritesReducer,
} from 'features/favorites';
import {
  activeFileDownloadTaskListReducer,
  activeFileDownloadTasksReducer,
  createFileDownloadTaskReducer,
  fileApi,
  fileDownloadTaskHistoryListReducer,
  shareFileDownloadTaskReducer,
} from 'features/file';
import {
  folderArchiveDetailReducer,
  folderArchiveListReducer,
  folderDeleteEmptyReducer,
  folderEditReducer,
  folderMuseumDetailReducer,
  folderMuseumListReducer,
  foldersApi,
  folderViewReducer,
} from 'features/folders';
import { imageDeleteReducer, imageEditReducer, imageGalleryReducer, imagesApi } from 'features/images';
import { layoutDeleteReducer, layoutEditReducer, layoutListReducer, layoutsApi } from 'features/layouts';
import { linkingArtifactReducer } from 'features/linking';
import { movingArtifactsReducer } from 'features/movingArtifacts';
import { referenceBookApi } from 'features/referenceBook';
import {
  authorDeleteReducer,
  authorEditReducer,
  authorListQueryReducer,
  authorListReducer,
} from 'features/referenceBook/components/AuthorsReferenceBook/slices';
import { rootFolderCreationReducer, rootFolderListReducer } from 'features/rootFolders';
import {
  searchApi,
  searchFolderReducer,
  searchImageReducer,
  searchLayoutReducer,
  searchListReducer,
  searchRootFolderReducer,
} from 'features/search';
import { tagApi, tagDeleteReducer, tagEditReducer } from 'features/tags';
import { toggleMuseumModalReducer, toggleMuseumReducer } from 'features/toggleMuseum';
import {
  userBlockReducer,
  userCreateReducer,
  userEditReducer,
  userListReducer,
  usersApi,
  userUnblockReducer,
} from 'features/users';

export const REDUCERS = {
  auth: authReducer,
  errorBoundary: errorBoundaryReducer,

  rootFolderList: rootFolderListReducer,
  rootFolderCreation: rootFolderCreationReducer,

  folderArchiveList: folderArchiveListReducer,
  folderMuseumList: folderMuseumListReducer,
  folderArchiveDetail: folderArchiveDetailReducer,
  folderMuseumDetail: folderMuseumDetailReducer,
  folderView: folderViewReducer,
  folderEdit: folderEditReducer,
  folderCreate: folderCreateReducer,
  folderDeleteEmpty: folderDeleteEmptyReducer,

  createFileDownloadTask: createFileDownloadTaskReducer,
  shareFileDownloadTask: shareFileDownloadTaskReducer,
  activeFileDownloadTasks: activeFileDownloadTasksReducer,
  fileDownloadTaskHistoryList: fileDownloadTaskHistoryListReducer,
  activeFileDownloadTaskList: activeFileDownloadTaskListReducer,
  alert: alertReducer,

  layoutList: layoutListReducer,
  layoutEdit: layoutEditReducer,
  layoutCreate: layoutCreateReducer,
  layoutDelete: layoutDeleteReducer,

  favoriteGroupCreate: favoriteGroupCreateReducer,
  favoriteGroupEdit: favoriteGroupEditReducer,
  favoriteGroupList: favoriteGroupListReducer,
  favoriteGroupRemove: favoriteGroupRemoveReducer,
  favoriteContent: favoriteContentReducer,
  favoriteFolderList: favoriteFolderListReducer,
  favoriteRootFolderList: favoriteRootFolderListReducer,
  favoriteLayoutList: favoriteLayoutListReducer,
  favoriteImageList: favoriteImageListReducer,
  addToFavorites: addToFavoritesReducer,
  removeFromFavorites: removeFromFavoritesReducer,

  imageEdit: imageEditReducer,
  imageGallery: imageGalleryReducer,
  imageCreate: imageCreateReducer,
  imageDelete: imageDeleteReducer,

  movingArtifacts: movingArtifactsReducer,
  linkingArtifacts: linkingArtifactReducer,
  changeOrder: changeOrderReducer,

  toggleMuseum: toggleMuseumReducer,
  toggleMuseumModal: toggleMuseumModalReducer,

  searchList: searchListReducer,
  searchRootFolder: searchRootFolderReducer,
  searchFolder: searchFolderReducer,
  searchLayout: searchLayoutReducer,
  searchImage: searchImageReducer,

  userList: userListReducer,
  userBlock: userBlockReducer,
  userUnblock: userUnblockReducer,
  userCreate: userCreateReducer,
  userEdit: userEditReducer,

  authorList: authorListReducer,
  authorListQuery: authorListQueryReducer,
  authorEdit: authorEditReducer,
  authorDelete: authorDeleteReducer,

  tagEdit: tagEditReducer,
  tagDelete: tagDeleteReducer,

  /* RTK Query specific */
  [authApi.reducerPath]: authApi.reducer,
  [usersApi.reducerPath]: usersApi.reducer,
  [foldersApi.reducerPath]: foldersApi.reducer,
  [layoutsApi.reducerPath]: layoutsApi.reducer,
  [imagesApi.reducerPath]: imagesApi.reducer,
  [searchApi.reducerPath]: searchApi.reducer,
  [fileApi.reducerPath]: fileApi.reducer,
  [referenceBookApi.reducerPath]: referenceBookApi.reducer,
  [tagApi.reducerPath]: tagApi.reducer,
  [favoritesApi.reducerPath]: favoritesApi.reducer,
  [changeOrderApi.reducerPath]: changeOrderApi.reducer,
};

const combinedReducer = combineReducers(REDUCERS);

/* eslint-disable  @typescript-eslint/no-explicit-any */
export const rootReducer = (rootState: any, action: AnyAction) => {
  let state = rootState;

  if (action.type === AUTH__SIGN_OUT) {
    state = undefined;
  }

  return combinedReducer(state, action);
};
