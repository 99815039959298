import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { changeOrderActions } from 'features/changeOrder/changeOrderSlice';
import { useAppDispatch } from 'infrastructure/redux';
import { Button, ButtonKind, ButtonVariant } from 'ui-library/v1/components/Button';
import styles from './ChangeOrderHeaderActions.module.scss';

export const ChangeOrderHeaderActions: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleClose = () => {
    dispatch(changeOrderActions.setShowOrderMode(false));
  };

  return (
    <div className={styles.wrap}>
      <div className={styles.root}>
        <div className={styles.label}>{t('changeOrder.headerLabel')}</div>

        <Button size={'small'} variant={ButtonVariant.SECONDARY} as={ButtonKind.BUTTON} onClick={handleClose}>
          {t('actions.close')}
        </Button>
      </div>
    </div>
  );
};
