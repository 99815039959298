import cn from 'classnames';
import React, { FunctionComponent } from 'react';
import { Spinner } from 'ui-library/components/Spinner';
import styles from './FolderArtifactListFooter.module.scss';
import { FolderArtifactListFooterProps } from './FolderArtifactListFooterProps';

export const FolderArtifactListFooter: FunctionComponent<FolderArtifactListFooterProps> = (props) => {
  const { className } = props;

  return (
    <div className={cn(styles.root, className)}>
      <Spinner size='small' className={styles.spinner} />
    </div>
  );
};
