import cn from 'classnames';
import React, { FunctionComponent } from 'react';
import { Card, CardProps } from 'ui-library/components/Card';
import styles from './FavoriteGroupCardRoot.module.scss';

export const FavoriteGroupCardRoot: FunctionComponent<CardProps> = (props) => {
  const { className, ...rest } = props;

  return <Card className={cn(styles.root, className)} {...rest} />;
};
