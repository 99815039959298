import { Tab as BuiltinTab } from '@headlessui/react';
import cn from 'classnames';
import { FunctionComponent } from 'react';
import { TabGroup } from './Group';
import { TabList } from './List';
import { TabPanel } from './Panel';
import { TabPanels } from './Panels';
import styles from './Tab.module.scss';
import { TabProps } from './TabProps';

const TabRoot: FunctionComponent<TabProps> = (props) => {
  const { className, ...restProps } = props;

  return (
    <BuiltinTab
      className={({ selected }) =>
        cn(className, styles.root, {
          [styles.selected]: selected,
        })
      }
      as='button'
      {...restProps}
    />
  );
};

export const Tab = Object.assign(TabRoot, {
  Group: TabGroup,
  List: TabList,
  Panels: TabPanels,
  Panel: TabPanel,
});
