import { useTranslation } from 'react-i18next';
import { LayoutResponseDto } from 'features/layouts';
import { useLayoutActionHandlers } from 'features/layouts/hooks';
import { favoritesAccessRuleService, layoutAccessRuleService } from 'features/role';
import { Icon } from 'ui-library/components/Icon';
import { ActionListItem } from 'ui-library/v1/components/ActionList';

export type UseLayoutActionsOptions = {
  layout: LayoutResponseDto;
};

export const useLayoutActions = (options: UseLayoutActionsOptions) => {
  const { t } = useTranslation();
  const { layout } = options;
  const { handleAddToFavorite, handleEdit, handleRemoveFromFavorite } = useLayoutActionHandlers({
    layout,
    parentId: undefined,
  });

  const actions: Array<ActionListItem> = [
    {
      label: t('actions.edit'),
      onClick: handleEdit,
      hasAccess: () => layoutAccessRuleService.canEdit(),
      startAdornment: <Icon name='edit' />,
    },
  ];

  if (layout.isFavourite) {
    actions.push({
      label: t('actions.removeFromFavorite'),
      onClick: handleRemoveFromFavorite,
      hasAccess: () => favoritesAccessRuleService.canDelete(),
      startAdornment: <Icon name='favoriteDelete' />,
    });
  } else {
    actions.push({
      label: t('actions.addToFavorite'),
      onClick: handleAddToFavorite,
      hasAccess: () => favoritesAccessRuleService.canCreate(),
      startAdornment: <Icon name='favorite' />,
    });
  }

  return { actions };
};
