import {
  autoUpdate,
  flip,
  offset,
  Placement,
  safePolygon,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useHover,
  useInteractions,
  useRole,
} from '@floating-ui/react-dom-interactions';
import React from 'react';

export type PopoverBehavior = 'click' | 'hover';

export type PopoverOptions = {
  initialOpen?: boolean;
  placement?: Placement;
  open?: boolean;
  offset?: Parameters<typeof offset>[0];
  onOpenChange?: (open: boolean) => void;
  behavior?: PopoverBehavior;
};

const DEFAULT_POPOVER_OFFSET_PX = 8;

export function usePopover({
  initialOpen = false,
  placement,
  open: controlledOpen,
  onOpenChange: setControlledOpen,
  offset: offsetPx = DEFAULT_POPOVER_OFFSET_PX,
  behavior = 'click',
}: PopoverOptions) {
  const [uncontrolledOpen, setUncontrolledOpen] = React.useState(initialOpen);
  const [labelId, setLabelId] = React.useState<string | undefined>();

  const open = controlledOpen ?? uncontrolledOpen;
  const setOpen = setControlledOpen ?? setUncontrolledOpen;

  const data = useFloating({
    open,
    onOpenChange: setOpen,
    middleware: [offset(offsetPx), flip(), shift()],
    placement,
    whileElementsMounted: autoUpdate,
  });
  const context = data.context;

  const interactions = useInteractions([
    useHover(context, {
      enabled: behavior === 'hover',
      restMs: 10,
      handleClose: safePolygon({ buffer: 8 }),
    }),
    useClick(context, {
      enabled: behavior === 'click',
    }),
    useRole(context),
    useDismiss(context),
  ]);

  return React.useMemo(
    () => ({
      open,
      setOpen,
      ...interactions,
      ...data,
      labelId,
      setLabelId,
    }),
    [open, setOpen, interactions, data, labelId, setLabelId],
  );
}
