import cn from 'classnames';
import { FunctionComponent, SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonKind } from 'ui-library/components/Button';
import { Icon } from 'ui-library/components/Icon';
import { IconButton, IconButtonVariant } from 'ui-library/components/IconButton';
import { ActionList, hasAvailableListActions } from 'ui-library/v1/components/ActionList';
import { Popover, PopoverContent, PopoverTrigger } from 'ui-library/v1/components/Popover';
import styles from './TableRowActions.module.scss';
import { TableRowActionsProps } from './TableRowActionsProps';

export const TableRowActions: FunctionComponent<TableRowActionsProps> = (props) => {
  const { t } = useTranslation();
  const { actions, className, portal } = props;
  const [isContextMenuOpened, setContextMenuOpen] = useState(false);

  if (!hasAvailableListActions(actions)) {
    return null;
  }

  const handleActionClick = (event: SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleContextMenu = (event: SyntheticEvent) => {
    event.preventDefault();

    setContextMenuOpen(true);
  };

  return (
    <div className={styles.wrap} onContextMenu={handleContextMenu}>
      <Popover
        offset={2}
        behavior='click'
        placement={'bottom-end'}
        open={isContextMenuOpened}
        onOpenChange={setContextMenuOpen}
      >
        <PopoverTrigger>
          <IconButton
            className={cn(className)}
            as={ButtonKind.BUTTON}
            variant={IconButtonVariant.TRANSPARENT}
            title={t('uiLibrary.table.actions')}
            onClick={handleActionClick}
          >
            <Icon className={styles.icon} name='dots' />
          </IconButton>
        </PopoverTrigger>
        <PopoverContent portal={portal}>
          {({ close }) => <ActionList actions={actions} onClose={close} />}
        </PopoverContent>
      </Popover>
    </div>
  );
};
