import { FunctionComponent } from 'react';
import { Image } from 'ui-library/components/Image';
import backgroundJPEG from '../../../assets/BG.jpg';
import backgroundWEBP from '../../../assets/BG.webp';
import styles from './AnonymousLayout.module.scss';
import { AnonymousLayoutProps } from './AnonymousLayoutProps';

export const AnonymousLayout: FunctionComponent<AnonymousLayoutProps> = (props) => {
  return (
    <div className={styles.wrap}>
      <picture>
        <source srcSet={backgroundWEBP} type='image/webp' />
        <source srcSet={backgroundJPEG} type='image/jpeg' />
        <Image className={styles.background} src={backgroundJPEG} loading='eager' />
      </picture>

      <main className={styles.main}>{props.children}</main>
    </div>
  );
};
