import { FunctionComponent } from 'react';
import { ListLayoutButtonGroup } from 'features/list';
import { SortButton } from 'features/sorting';
import styles from './FolderListHeaderActions.module.scss';
import { FolderListHeaderActionsProps } from './FolderListHeaderActionsProps';

export const FolderListHeaderActions: FunctionComponent<FolderListHeaderActionsProps> = (props) => {
  const { sortDirection, layoutType, onChangeLayout, onToggleSort } = props;

  return (
    <div className={styles.root}>
      <ListLayoutButtonGroup active={layoutType} onLayoutChanged={onChangeLayout} />

      <SortButton className={styles.active} direction={sortDirection} toggleSort={onToggleSort} />
    </div>
  );
};
