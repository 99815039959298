import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'infrastructure/redux';
import { Icon } from 'ui-library/components/Icon';
import { ZeroScreenWrapper } from 'ui-library/components/ZeroScreen';
import styles from './LinkingArtifactEmptyFolder.module.scss';

export const LinkingArtifactEmptyFolder: FunctionComponent = () => {
  const { t } = useTranslation();
  const { type, linkingArtifacts } = useAppSelector((state) => state.linkingArtifacts);

  return (
    <ZeroScreenWrapper
      className={styles.root}
      theme='dark'
      renderIcon={(props) => <Icon name='documentIllustration' size='none' {...props} />}
      message={
        type === 'layout'
          ? t('linkingArtifacts.empty.folderLayout', { count: linkingArtifacts.length })
          : t('linkingArtifacts.empty.folderImage', { count: linkingArtifacts.length })
      }
    />
  );
};
