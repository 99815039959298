import cn from 'classnames';
import React, { FunctionComponent, SyntheticEvent } from 'react';
import { createSearchParams } from 'react-router-dom';
import { ListRange, Virtuoso } from 'react-virtuoso';
import { NumLabel } from 'features/changeOrder';
import { GalleryImageItemDto, ImagesEmpty } from 'features/images';
import { ImageGalleryFolderHeader } from 'features/images/components/ImageGalleryFolderHeader/ImageGalleryFolderHeader';
import { ImageGalleryGrid } from 'features/images/components/ImageGalleryGrid/ImageGalleryGrid';
import { ImageGalleryScrollSeekPlaceholder } from 'features/images/components/ImageGalleryScrollSeekPlaceholder/ImageGalleryScrollSeekPlaceholder';
import { ImageGallerySkeleton } from 'features/images/components/ImageGallerySkeleton/ImageGallerySkeleton';
import { ImageCard } from 'features/v1/image/ImageCard/ImageCard';
import { getImageDetailRoute } from 'infrastructure/routes/paths';
import { Link, LinkKind } from 'ui-library/components/Link';
import { Checkbox } from 'ui-library/v1/components/Checkbox';
import { CARD_IMAGE_HEIGHT } from '../ImageCardRoot/ImageCardRoot';
import styles from './ImageGallery.module.scss';
import { ImageGalleryProps } from './ImageGalleryProps';

export const ImageGallery: FunctionComponent<ImageGalleryProps> = (props) => {
  const {
    data,
    isLoading,
    onSelectImage,
    isImageSelected,
    canSelectImage,
    isSelectionMode,
    isShowOrderMode,
    getParams,
    onSaveScrollOffsetY,
    initialScrollOffsetY,
    onClickToChangeOrder,
  } = props;

  const onRangeChanged = (range: ListRange) => {
    const delta = range.endIndex - range.startIndex;
    const middle = range.startIndex + Math.floor(delta / 2);

    onSaveScrollOffsetY(middle);
  };

  if (isLoading) {
    return <ImageGallerySkeleton />;
  }

  const isEmpty = data?.length === 0;

  if (isEmpty) {
    return <ImagesEmpty />;
  }

  const handleClick = (isRoot: boolean, image: GalleryImageItemDto, parentId: string) => (event: SyntheticEvent) => {
    isSelectionMode && event.preventDefault();

    if (isShowOrderMode && onClickToChangeOrder) {
      event.preventDefault();
      isRoot && onClickToChangeOrder(image, parentId);
    }
  };

  return (
    <Virtuoso
      className={styles.root}
      data={data ?? []}
      useWindowScroll={true}
      initialTopMostItemIndex={initialScrollOffsetY}
      rangeChanged={onRangeChanged}
      defaultItemHeight={CARD_IMAGE_HEIGHT}
      components={{
        ScrollSeekPlaceholder: ImageGalleryScrollSeekPlaceholder,
      }}
      itemContent={(index, item) => {
        return (
          <>
            <ImageGalleryFolderHeader folder={item} />
            <ImageGalleryGrid>
              {item.images.map((image, index) => {
                const checked = isImageSelected?.(image, item.id);
                const isRootFolder = item.parent === undefined;
                return (
                  <>
                    <div
                      className={cn(styles.imageGridItem, {
                        [styles.active]: checked,
                      })}
                    >
                      <Link
                        key={image.id}
                        onClick={handleClick(isRootFolder, image, item.id)}
                        as={LinkKind.INNER}
                        to={getImageDetailRoute(image.id) + `?${createSearchParams(getParams(item.id))}`}
                      >
                        <ImageCard image={image} folder={item} isShowOrderMode={isShowOrderMode} checked={checked} />
                      </Link>
                      {canSelectImage?.(image, item.id) && !isShowOrderMode && (
                        <Checkbox
                          className={styles.checkbox}
                          checked={checked}
                          onChange={(e) => {
                            e.stopPropagation();
                            onSelectImage?.(e.target.checked, image, item.id);
                          }}
                          onClick={(e) => e.stopPropagation()}
                        />
                      )}
                      {isRootFolder && isShowOrderMode && <NumLabel num={image.order} />}
                    </div>
                  </>
                );
              })}
            </ImageGalleryGrid>
          </>
        );
      }}
      scrollSeekConfiguration={{
        enter: (velocity) => Math.abs(velocity) > 400,
        exit: (velocity) => Math.abs(velocity) < 30,
      }}
    />
  );
};
