import { skipToken } from '@reduxjs/toolkit/dist/query';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { FolderPartition, foldersApi, useFetchFolderDetailQuery } from 'features/folders';
import { imageGalleryActions, imagesApi } from 'features/images';
import { layoutsApi } from 'features/layouts';
import { searchApi } from 'features/search';
import { NotDefinedParamException } from 'infrastructure/exceptions';
import { useLanguageRelevantData } from 'infrastructure/i18n';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux';
import { getFolderDetailRoute, getGalleryRoute } from 'infrastructure/routes/paths';
import { Link, LinkKind, LinkVariant } from 'ui-library/v1/components/Link';
import { movingArtifactsActions } from '../movingArtifactsSlice';
import styles from './useMoveArtifact.module.scss';

export const useMoveArtifact = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { matchProp } = useLanguageRelevantData();
  const { selectedFolderId, movingArtifacts, selectedPartition, type } = useAppSelector(
    (state) => state.movingArtifacts,
  );
  const { data: selectedFolderData } = useFetchFolderDetailQuery(selectedFolderId ?? skipToken);

  const buildSuccessToast = (content: JSX.Element) => {
    toast.success(<div className={styles.notification}>{content}</div>);
  };

  const buildErrorToast = (content: JSX.Element) => {
    toast.error(<div className={styles.notification}>{content}</div>);
  };

  const getToastLink = () => {
    if (!selectedFolderData) {
      throw new NotDefinedParamException('Folder');
    }

    return (
      <Link
        as={LinkKind.INNER}
        variant={LinkVariant.STYLED}
        className={styles.link}
        to={getFolderDetailRoute(selectedFolderData?.id, selectedFolderData?.partition)}
      >
        «{matchProp(selectedFolderData?.captionRu, selectedFolderData?.captionEn)}»
      </Link>
    );
  };

  const handleMovingFolder = () => {
    if (!movingArtifacts) {
      throw new Error('Moving artifacts are not defined');
    }

    dispatch(foldersApi.util.invalidateTags(['SearchFolder']));
    dispatch(searchApi.util.invalidateTags(['SearchResult']));

    if (!selectedFolderId) {
      dispatch(foldersApi.util.invalidateTags(['RootFolders']));
    }

    dispatch(
      foldersApi.util.invalidateTags([
        {
          type: 'Folder',
          id: selectedFolderData?.id,
        },
        {
          type: 'Folder',
          id: selectedFolderData?.parentID,
        },
        {
          type: 'FolderChildren',
          id: selectedFolderData?.id,
        },
      ]),
    );

    for (const artifact of movingArtifacts) {
      dispatch(
        foldersApi.util.invalidateTags([
          {
            type: 'FolderPath',
            id: artifact.entityId,
          },
          {
            type: 'Folder',
            id: artifact.entityId,
          },
          {
            type: 'FolderChildren',
            id: artifact.parentID,
          },
        ]),
      );
      dispatch(imagesApi.util.invalidateTags([{ type: 'ImageGallery', id: artifact.parentID }]));
    }

    dispatch(imagesApi.util.invalidateTags([{ type: 'ImageGallery', id: selectedFolderData?.parentID }]));

    const isFolderToRoot = type === 'folder' && selectedFolderId === undefined;
    const isFolderToFolder = type === 'folder' && selectedFolderId;

    if (isFolderToRoot) {
      buildSuccessToast(
        <>
          {t('movingArtifacts.success.movingFolderToRoot', {
            count: movingArtifacts.length,
          })}
          &nbsp;
          <Link
            as={LinkKind.INNER}
            variant={LinkVariant.STYLED}
            className={styles.link}
            to={getGalleryRoute(selectedPartition as FolderPartition)}
          >
            {t('movingArtifacts.rootDirectory')}
          </Link>
        </>,
      );
    }

    if (isFolderToFolder) {
      buildSuccessToast(
        <>
          {t('movingArtifacts.success.movingFolderToFolder', { count: movingArtifacts.length })}
          &nbsp; {getToastLink()}
        </>,
      );
    }

    if (type === 'rootFolder') {
      buildSuccessToast(
        <>
          {t(
            selectedFolderData?.isRoot
              ? 'movingArtifacts.success.movingRootToRoot'
              : 'movingArtifacts.success.movingRootToFolder',
            {
              count: movingArtifacts.length,
            },
          )}
          &nbsp; {getToastLink()}
        </>,
      );
    }

    dispatch(movingArtifactsActions.dispose());
  };

  const handleMovingLayout = () => {
    if (!movingArtifacts) {
      throw new Error('Moving artifacts are not defined');
    }

    dispatch(searchApi.util.invalidateTags(['SearchResult']));
    dispatch(foldersApi.util.invalidateTags(['Folder', 'FolderChildren', 'RootFolders']));

    for (const artifact of movingArtifacts) {
      dispatch(
        layoutsApi.util.invalidateTags([
          {
            type: 'Layout',
            id: artifact.entityId,
          },
          { type: 'FolderLayouts', id: artifact.parentID },
        ]),
      );
    }

    dispatch(layoutsApi.util.invalidateTags([{ type: 'FolderLayouts', id: selectedFolderId }]));

    buildSuccessToast(
      <>
        {t(
          selectedFolderData?.isRoot
            ? 'movingArtifacts.success.movingLayoutToRoot'
            : 'movingArtifacts.success.movingLayoutToFolder',
          {
            count: movingArtifacts.length,
          },
        )}
        &nbsp; {getToastLink()}
      </>,
    );

    dispatch(movingArtifactsActions.dispose());
  };

  const handleMovingImage = () => {
    if (!movingArtifacts) {
      throw new Error('Moving artifacts are not defined');
    }

    dispatch(searchApi.util.invalidateTags(['SearchResult']));
    dispatch(foldersApi.util.invalidateTags(['Folder', 'FolderChildren', 'RootFolders']));

    for (const artifact of movingArtifacts) {
      dispatch(imagesApi.util.invalidateTags([{ type: 'FolderImages', id: artifact.parentID }]));
    }

    dispatch(imagesApi.util.invalidateTags([{ type: 'FolderImages', id: selectedFolderId }]));
    dispatch(imagesApi.util.invalidateTags(['ImageGallery']));

    buildSuccessToast(
      <>
        {t(
          selectedFolderData?.isRoot
            ? 'movingArtifacts.success.movingImageToRoot'
            : 'movingArtifacts.success.movingImageToFolder',
          {
            count: movingArtifacts.length,
          },
        )}
        &nbsp; {getToastLink()}
      </>,
    );

    dispatch(imageGalleryActions.clearSelection());
    dispatch(movingArtifactsActions.dispose());
  };

  const handleMovingLayoutError = () => {
    buildErrorToast(<>{t('movingArtifacts.error.movedErrorLayout', { count: movingArtifacts.length })}</>);
  };

  const handleMovingImageError = () => {
    buildErrorToast(<>{t('movingArtifacts.error.movedErrorImage', { count: movingArtifacts.length })}</>);
  };

  const handleMovingFolderError = () => {
    if (type === 'rootFolder') {
      buildErrorToast(<>{t('movingArtifacts.error.movedErrorRootFolder', { count: movingArtifacts.length })}</>);
    } else if (type === 'folder') {
      buildErrorToast(<>{t('movingArtifacts.error.movedErrorFolder', { count: movingArtifacts.length })}</>);
    }
  };

  return {
    handleMovingFolder,
    handleMovingLayout,
    handleMovingLayoutError,
    handleMovingImage,
    handleMovingImageError,
    handleMovingFolderError,
  };
};
