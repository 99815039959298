export type ListApiResponse<T> = PaginationApiResponse & {
  content: Array<T>;
};

export type PaginationApiResponse = {
  totalCount: number;
  offset: number;
  limit: number;
};

export type ApiErrorResponse = {
  code: ApiErrorCode;
  message: string;
  attribute?: string;
};

export type ApiTagsErrorResponse = {
  errors?: Array<ApiErrorResponse>;
};

export type TagsError = ApiTagsErrorResponse | ApiErrorResponse;

export enum ApiErrorCode {
  BAD_REQUEST = 'BAD_REQUEST',
  VALIDATION_FAILED = 'VALIDATION_FAILED',
  WRONG_FORMAT = 'WRONG_FORMAT',
  AMBIGUOUS_REQUEST = 'AMBIGUOUS_REQUEST',
  PASSWORD_WEAK = 'PASSWORD_WEAK',
  PASSWORD_WRONG_CONFIRMATION = 'PASSWORD_WRONG_CONFIRMATION',
  WRONG_SORTING_PARAMS = 'WRONG_SORTING_PARAMS',
  WRONG_QUERY_PARAMS = 'WRONG_QUERY_PARAMS',
  FOLDER_UNKNOWN = 'FOLDER_UNKNOWN',
  IMAGE_UNKNOWN = 'IMAGE_UNKNOWN',
  LAYOUT_UNKNOWN = 'LAYOUT_UNKNOWN',
  REF_BOOK_UNKNOWN = 'REF_BOOK_UNKNOWN',
  FILENAME_UNKNOWN = 'FILENAME_UNKNOWN',
  EXCEL_COLUMN_DUPLICATES = 'EXCEL_COLUMN_DUPLICATES',
  EXCEL_COLUMN_NOT_FOUND = 'EXCEL_COLUMN_NOT_FOUND',
  EXCEL_SHEET_NOT_FOUND = 'EXCEL_SHEET_NOT_FOUND',
  BOX_NAME_IS_NOT_SPECIFIED = 'BOX_NAME_IS_NOT_SPECIFIED',
  TOO_MANY_TAGS_BOUND = 'TOO_MANY_TAGS_BOUND',
  BAD_TOKEN = 'BAD_TOKEN',
  UNAUTHORIZED = 'UNAUTHORIZED',
  FORBIDDEN = 'FORBIDDEN',
  NOT_FOUND = 'NOT_FOUND',
  CONFLICT = 'CONFLICT',
  MAX_FOLDING_EXCEEDED = 'MAX_FOLDING_EXCEEDED',
  CYCLIC_FOLDING = 'CYCLIC_FOLDING',
  FILE_INCORRECT = 'FILE_INCORRECT',
  FILE_DUPLICATES = 'FILE_DUPLICATES',
  FILE_ALREADY_EXISTS = 'FILE_ALREADY_EXISTS',
  FILE_ALREADY_BOUND = 'FILE_ALREADY_BOUND',
  BOX_NAME_COLLIDES = 'BOX_NAME_COLLIDES',
  HAS_REFERENCES = 'HAS_REFERENCES',
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
}
