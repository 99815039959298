import cn from 'classnames';
import { nanoid } from 'nanoid';
import { forwardRef, useRef } from 'react';
import { FormControl } from '../FormControl';
import { Label } from '../Label';
import { Radio } from '../Radio';
import styles from './RadioField.module.scss';
import { RadioFieldProps } from './RadioFieldProps';

export const RadioField = forwardRef<HTMLInputElement, RadioFieldProps>(
  ({ className, id, name, RadioProps, disabled, label, LabelProps }, ref) => {
    const inputId = useRef(id ?? nanoid()).current;

    return (
      <FormControl className={cn(styles.root, className)}>
        <Radio ref={ref} id={inputId} name={name} {...RadioProps} />
        <Label disabled={disabled} htmlFor={inputId} label={label} className={styles.label} {...LabelProps} />
      </FormControl>
    );
  },
);
