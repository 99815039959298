import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getDefaultModalState, getModalReducers, ModalState } from 'features/modal';
import { FavoriteArtifactEntity } from '../favoriteEntity';

export type RemoveFromFavoritesState = ModalState & {
  favoriteArtifact?: FavoriteArtifactEntity;
};

const initialState: RemoveFromFavoritesState = {
  ...getDefaultModalState(),
  favoriteArtifact: undefined,
};

const removeFromFavoritesSlice = createSlice({
  name: 'removeFromFavorites',
  initialState,
  reducers: {
    ...getModalReducers<RemoveFromFavoritesState>((state) => state),
    setFavoriteArtifact: (state, action: PayloadAction<FavoriteArtifactEntity | undefined>) => {
      state.favoriteArtifact = action.payload;
    },
  },
});

export const removeFromFavoritesActions = removeFromFavoritesSlice.actions;
export const removeFromFavoritesReducer = removeFromFavoritesSlice.reducer;
