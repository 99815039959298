import { skipToken } from '@reduxjs/toolkit/dist/query';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FolderPartition } from 'features/folders';
import { SearchScreenResultTab, SearchType, useDebounce, useSearchQuery, useSearchQueryParams } from 'features/search';
import { SearchToggle } from 'features/v1/search/SearchToggle/SearchToggle';
import { useCurrentLanguage } from 'infrastructure/i18n';
import { getSearchRoute } from 'infrastructure/routes/paths';
import { SearchAutocomplete } from '../SearchAutocomplete/SearchAutocomplete';
import { AutocompleteOptionType } from '../SearchAutocomplete/SearchAutocompleteTypes';
import styles from './SearchBar.module.scss';
import { SearchBarProps } from './SearchBarProps';

export const SearchBar: FunctionComponent<SearchBarProps> = () => {
  const navigate = useNavigate();
  const { tagIds, query: defaultQuery, filterData, tab, partition } = useSearchQueryParams();
  const [query, setQuery] = useState(defaultQuery || '');
  const debouncedQuery = useDebounce(query);
  const language = useCurrentLanguage();

  const searchParams = {
    items: [
      {
        type: 'foldersAll' as SearchType,
        limit: 50,
        offset: 0,
      },
    ],
    partition,
    language,
    query: debouncedQuery || '',
  };

  const isAISearch = partition === FolderPartition.AI;

  const params = isAISearch ? skipToken : searchParams;

  const result = useSearchQuery(params);

  const filteredOptions: Array<AutocompleteOptionType> = useMemo(() => {
    const foldersOptions =
      result?.data?.items[0].folder?.content.map((item) => {
        let label = '';
        if (language === 'en') {
          label = item.captionEn;
        }
        if (language === 'ru') {
          label = item.captionRu;
        }
        return { label, artifact: item.isRoot ? SearchScreenResultTab.ROOT_FOLDERS : SearchScreenResultTab.FOLDERS };
      }) ?? [];

    return foldersOptions;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result]);

  useEffect(() => {
    setQuery(defaultQuery);
  }, [defaultQuery]);

  const onSearch = () => {
    navigate(getSearchRoute({ tab, partition, query }));
  };

  const handleChangeOpiton = (value: AutocompleteOptionType) => {
    navigate(
      getSearchRoute({
        partition,
        ...filterData,
        tab: value.artifact,
        tags: tagIds,
        query: value.label,
      }),
    );
  };

  const setSearchQuery = (value: string) => {
    setQuery(value);
  };

  return (
    <>
      <div className={styles.root}>
        <SearchAutocomplete
          setSearchQuery={setSearchQuery}
          query={query}
          filteredOptions={filteredOptions}
          onSearch={onSearch}
          isLoading={result.isFetching}
          handleChangeOpiton={handleChangeOpiton}
        />
        <span className={styles.divider}></span>
        <SearchToggle />
      </div>
    </>
  );
};
