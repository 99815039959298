import { Fragment, FunctionComponent } from 'react';
import { TagsOrigin } from 'features/tags/tagEntity';
import { useLanguageRelevantData } from 'infrastructure/i18n';
import { getSearchRoute } from 'infrastructure/routes/paths';
import { Link, LinkKind } from 'ui-library/v1/components/Link';
import styles from './TagsSearchLinks.module.scss';
import { TagsSearchLinksProps } from './TagsSearchLinksProps';

export const TagsSearchLinks: FunctionComponent<TagsSearchLinksProps> = (props) => {
  const { tags, tab, partition } = props;
  const { matchProp } = useLanguageRelevantData();

  return (
    <>
      {(tags ?? []).map((x, index, arr) => (
        <Fragment key={x.id}>
          {x.origin === TagsOrigin.TAG_USER ? (
            <Link
              className={styles.link}
              as={LinkKind.INNER}
              to={getSearchRoute({ tags: [x.id], tab, partition: partition })}
            >
              {matchProp(x.nameRu, x.nameEn)}
            </Link>
          ) : (
            <span>{matchProp(x.nameRu, x.nameEn)}</span>
          )}
          {index < arr.length - 1 && ', '}
        </Fragment>
      ))}
    </>
  );
};
