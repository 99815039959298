import { UserRole } from 'features/users';
import { AccessRuleService } from './accessRuleService';

class FavoritesAccessRuleService extends AccessRuleService {
  protected canViewRoles: Array<UserRole> = [UserRole.ADMIN, UserRole.USER];
  protected canCreateRoles: Array<UserRole> = [UserRole.ADMIN, UserRole.USER];
  protected canEditRoles: Array<UserRole> = [UserRole.ADMIN, UserRole.USER];
  protected canDeleteRoles: Array<UserRole> = [UserRole.ADMIN, UserRole.USER];
}

export const favoritesAccessRuleService = new FavoritesAccessRuleService();
