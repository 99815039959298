import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { ApiErrorResponse } from './types/responseTypes';

/**
 * Type predicate to narrow an unknown error to `FetchBaseQueryError`
 */
export function isFetchBaseQueryError(error: unknown): error is FetchBaseQueryError {
  return typeof error === 'object' && error !== null && 'status' in error;
}

/**
 * Type predicate to narrow an unknown error to an object with a string 'message' property
 */
export function isErrorWithMessage(error: unknown): error is { message: string } {
  return (
    typeof error === 'object' &&
    error !== null &&
    'message' in error &&
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    typeof (error as any).message === 'string'
  );
}

export function isErrorWithCode(error: unknown): error is { code: string } {
  return (
    typeof error === 'object' &&
    error !== null &&
    'code' in error &&
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    typeof (error as any).code === 'string'
  );
}

export function parseApiError(error: unknown): ApiErrorResponse | undefined {
  if (isFetchBaseQueryError(error) && isErrorWithCode(error.data)) {
    return error.data as unknown as ApiErrorResponse;
  } else {
    return undefined;
  }
}
