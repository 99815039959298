import { createSlice } from '@reduxjs/toolkit';
import { ListPaginationState } from 'features/list';
import { getListPaginationReducers } from 'features/list/listReducers';
import { getDefaultPaginationState } from 'features/pagination';

export type SearchFolderState = ListPaginationState;

const initialState: SearchFolderState = {
  paginationData: getDefaultPaginationState(),
};

const searchFolderSlice = createSlice({
  name: 'searchFolder',
  initialState,
  reducers: getListPaginationReducers<SearchFolderState>(),
});

export const searchFolderActions = searchFolderSlice.actions;
export const searchFolderReducer = searchFolderSlice.reducer;
