import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from 'ui-library/components/Icon';
import { ZeroScreenWrapper } from 'ui-library/components/ZeroScreen';
import styles from './ImagesEmpty.module.scss';

export const ImagesEmpty: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <ZeroScreenWrapper
      className={styles.root}
      theme='dark'
      renderIcon={(props) => <Icon name='documentIllustration' size='none' {...props} />}
      message={t('images.empty.message')}
    />
  );
};
