import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FolderPartition } from 'features/folders';
import { toggleMuseumModalActions } from 'features/toggleMuseum';
import { NotDefinedParamException } from 'infrastructure/exceptions';
import { useTranslateEnum } from 'infrastructure/i18n';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux';
import { ActionButton } from 'ui-library/components/ActionButton';
import { ButtonKind, ButtonVariant } from 'ui-library/components/Button';
import { Modal } from 'ui-library/components/Modal';
import styles from './ToggleMuseumModal.module.scss';

export const ToggleMuseumModal: FunctionComponent = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { translateValue } = useTranslateEnum('FolderPartition', FolderPartition);
  const { isOpen, query, selectedPartition } = useAppSelector((state) => state.toggleMuseumModal);

  const handleClose = () => {
    dispatch(toggleMuseumModalActions.close());
    dispatch(toggleMuseumModalActions.setQuery(undefined));
  };

  const handleApproveToggle = () => {
    if (!query) {
      throw new NotDefinedParamException('Query link');
    }

    navigate(query);
    handleClose();
  };

  return (
    <Modal variant='popup' open={isOpen} onClose={handleClose} closeIconName='cross'>
      <Modal.Title className={styles.title}>
        {t('toggleMuseum.toggleAgreement.title', {
          from: translateValue(selectedPartition),
          to: translateValue(
            selectedPartition === FolderPartition.MUSEUM ? FolderPartition.ARCHIVE : FolderPartition.MUSEUM,
          ),
        })}
      </Modal.Title>
      <Modal.Actions>
        <ActionButton as={ButtonKind.BUTTON} onClick={handleApproveToggle}>
          {t('toggleMuseum.toggleAgreement.actions.confirm')}
        </ActionButton>
        <ActionButton as={ButtonKind.BUTTON} variant={ButtonVariant.SECONDARY} onClick={handleClose}>
          {t('actions.cancel')}
        </ActionButton>
      </Modal.Actions>
    </Modal>
  );
};
