import { Combobox } from '@headlessui/react';
import cn from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { BaseSelectMultiValueChips, BaseSelectMultiValueContainer } from 'ui-library/components/BaseSelect';
import { InputControl } from 'ui-library/components/Input/Control/InputControl';
import { InputLayout, InputLayoutVariant } from 'ui-library/components/InputLayout';
import styles from './MultiValueContainer.module.scss';
import { MultiValueContainerProps } from './MultiValueContainerProps';

export const MultiValueContainer = <Option, OptionValue = Option>(
  props: MultiValueContainerProps<Option, OptionValue>,
) => {
  const {
    open,
    placeholder,
    value,
    options,
    getOptionValue,
    getOptionLabel,
    onChange,
    disabled,
    onChangeInput,
    variant = InputLayoutVariant.FILLED,
    oneLine = false,
    chipsData = options,
  } = props;

  const inputRef = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState('');

  const comboboxBtnRef = useRef<HTMLButtonElement>(null);

  const showPlaceholder = !inputValue && value?.length === 0;

  const prevValue = useRef(value);
  useEffect(() => {
    if (value.length > prevValue.current.length) {
      inputRef.current?.focus();
    }

    prevValue.current = value;
  }, [value]);

  useEffect(() => {
    setInputValue('');
  }, [chipsData]);

  return (
    <InputLayout
      variant={variant}
      tabIndex={0}
      className={cn(styles.root, {
        [styles.active]: open,
      })}
      InputProps={{
        className: cn({
          [styles.standardFilled]: variant === InputLayoutVariant.STANDARD && value?.length > 0,
        }),
      }}
      onClick={() => {
        inputRef.current?.focus();
        comboboxBtnRef.current?.click();
      }}
      renderControl={() => (
        <BaseSelectMultiValueContainer variant={variant} oneLine={oneLine}>
          <BaseSelectMultiValueChips
            value={value}
            options={chipsData}
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
            onChange={onChange}
            disabled={disabled}
          />
          <div
            onClick={() => {
              inputRef.current?.focus();
            }}
            className={styles.inputContainer}
            data-value={showPlaceholder ? placeholder : inputValue}
          >
            <InputControl
              ref={inputRef}
              className={styles.input}
              placeholder={showPlaceholder ? placeholder : undefined}
              value={inputValue}
              onChange={(event) => {
                setInputValue(event.target.value);
                onChangeInput?.(event.target.value);
              }}
              onBlur={() => {
                setInputValue('');
              }}
            />
          </div>
          <Combobox.Button ref={comboboxBtnRef} className={styles.button} />
        </BaseSelectMultiValueContainer>
      )}
    />
  );
};
