import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LayoutViewTab } from 'features/layouts/components/LayoutView/LayoutViewTab';
import { getDefaultModalState, getModalReducers, ModalState } from 'features/modal';

export type LayoutCreateState = ModalState & {
  parentFolderId?: string;
  selectedTab: LayoutViewTab;
};

const initialState: LayoutCreateState = {
  ...getDefaultModalState(),
  parentFolderId: undefined,
  selectedTab: LayoutViewTab.INFORMATION,
};

const layoutCreateSlice = createSlice({
  name: 'layoutCreate',
  initialState,
  reducers: {
    ...getModalReducers<LayoutCreateState>((state) => state),
    selectParentFolder: (state, action: PayloadAction<string | undefined>) => {
      state.parentFolderId = action.payload;
    },
    selectTab: (state, action: PayloadAction<LayoutViewTab>) => {
      state.selectedTab = action.payload;
    },
  },
});

export const layoutCreateActions = layoutCreateSlice.actions;
export const layoutCreateReducer = layoutCreateSlice.reducer;
