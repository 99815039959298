import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { FileDownloadImageFormat } from 'features/file/fileEntity';
import { Icon } from 'ui-library/components/Icon';
import { FileFormatCard } from '../FileFormatCard/FileFormatCard';
import styles from './FileFormatCardList.module.scss';
import { FileFormatCardListProps } from './FileFormatCardListProps';

export const FileFormatCardList: FunctionComponent<FileFormatCardListProps> = (props) => {
  const { t } = useTranslation();
  const { onChoose } = props;

  return (
    <div className={styles.root}>
      <FileFormatCard
        icon={<Icon name='tifDoc' />}
        title={t('files.formats.tif.title')}
        description={t('files.formats.tif.description')}
        onClick={() => onChoose(FileDownloadImageFormat.TIF)}
      />
      <FileFormatCard
        icon={<Icon name='jpegDoc' />}
        title={t('files.formats.jpeg.title')}
        description={t('files.formats.jpeg.description')}
        onClick={() => onChoose(FileDownloadImageFormat.JPEG)}
      />
    </div>
  );
};
