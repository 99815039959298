import { FC } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { foldersApi } from 'features/folders';
import { useDeleteImageMutation } from 'features/images/imagesApi';
import { imageDeleteActions } from 'features/images/slices';
import { NotDefinedParamException } from 'infrastructure/exceptions';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux';
import { ActionButton } from 'ui-library/components/ActionButton';
import { ButtonKind, ButtonVariant } from 'ui-library/components/Button';
import { Modal } from 'ui-library/components/Modal';

export const ImageDeleteModal: FC = () => {
  const dispatch = useAppDispatch();
  const { isOpen, imageID } = useAppSelector((state) => state.imageDelete);
  const [deleteImageMutation, { isLoading }] = useDeleteImageMutation();
  const { t } = useTranslation();

  const handleClose = () => {
    dispatch(imageDeleteActions.setImageForDeletion(undefined));
    dispatch(imageDeleteActions.close());
  };

  const handleDelete = async () => {
    if (!imageID) {
      throw new NotDefinedParamException('image');
    }

    try {
      await deleteImageMutation({
        imageID: imageID,
      }).unwrap();

      dispatch(foldersApi.util.invalidateTags(['RootFolders', 'FolderChildren']));

      handleClose();

      toast.success(t('images.delete.deleteSucceed'));
    } catch (e) {
      toast.error(t('images.delete.deleteFailed'));
    }
  };

  return (
    <Modal open={isOpen} onClose={handleClose} variant='popup'>
      <Modal.Title>{t('images.delete.title')}</Modal.Title>
      <Modal.Content>{t('images.delete.description')}</Modal.Content>
      <Modal.Actions>
        <ActionButton as={ButtonKind.BUTTON} onClick={handleDelete} loading={isLoading}>
          {t('actions.remove')}
        </ActionButton>
        <ActionButton as={ButtonKind.BUTTON} onClick={handleClose} variant={ButtonVariant.SECONDARY}>
          {t('actions.cancel')}
        </ActionButton>
      </Modal.Actions>
    </Modal>
  );
};
