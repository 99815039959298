import { FunctionComponent } from 'react';
import { useImageActionHandlers, useImageDownloadActions } from 'features/images/hooks';
import { imageAccessRuleService } from 'features/role';
import { useLanguageRelevantData } from 'infrastructure/i18n';
import { ActionList } from 'ui-library/components/ActionList';
import { ButtonKind } from 'ui-library/components/Button';
import { Icon } from 'ui-library/components/Icon';
import { IconButton, IconButtonVariant } from 'ui-library/components/IconButton';
import { Popover, PopoverContent, PopoverTrigger } from 'ui-library/components/Popover';
import { ImageFavoriteButtonGroup } from '../../ImageFavoriteButtonGroup/ImageFavoriteButtonGroup';
import styles from './ImageViewHeader.module.scss';
import { ImageViewHeaderProps } from './ImageViewHeaderProps';

export const ImageViewHeader: FunctionComponent<ImageViewHeaderProps> = (props) => {
  const { matchProp } = useLanguageRelevantData();
  const { image } = props;
  const { handleEdit } = useImageActionHandlers({ image, parentId: undefined });
  const { actions } = useImageDownloadActions(image);

  return (
    <div className={styles.header}>
      <span className={styles.title}>{matchProp(image?.captionRu, image?.captionEn)}</span>
      <div className={styles.buttonGroup}>
        {imageAccessRuleService.canView() && <ImageFavoriteButtonGroup image={image} className={styles.button} />}

        {imageAccessRuleService.canEdit() && (
          <IconButton as={ButtonKind.BUTTON} onClick={handleEdit} className={styles.button}>
            <Icon name='edit' />
          </IconButton>
        )}

        {actions.length > 0 && imageAccessRuleService.canViewUI() && (
          <Popover behavior='click' placement='bottom-end'>
            <PopoverTrigger>
              <IconButton as={ButtonKind.BUTTON} variant={IconButtonVariant.SECONDARY}>
                <Icon name='downloadAlt' />
              </IconButton>
            </PopoverTrigger>
            <PopoverContent>{({ close }) => <ActionList actions={actions} onClose={close} />}</PopoverContent>
          </Popover>
        )}
      </div>
    </div>
  );
};
