import { ComponentsType } from '../BaseSelect';
import { AutocompleteLabelOption } from './LabelOption/AutocompleteLabelOption';

const components: ComponentsType<unknown, unknown> = {
  Option: AutocompleteLabelOption,
};

export const getDefaultComponents = <Option, OptionValue>(
  overrides?: Partial<ComponentsType<Option, OptionValue>>,
): ComponentsType<Option, OptionValue> =>
  ({
    ...components,
    ...overrides,
  } as ComponentsType<Option, OptionValue>);
