import { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { ImageResponseDto } from 'features/images';
import { useImageActionHandlers } from 'features/images/hooks';
import { favoritesAccessRuleService } from 'features/role';
import { NotDefinedParamException } from 'infrastructure/exceptions';
import { Icon } from 'ui-library/components/Icon';
import { ActionListItem } from 'ui-library/v1/components/ActionList';
import { FavoriteArtifactEntity } from '../favoriteEntity';
import { useDeleteFavoriteMutation } from '../favoritesApi';
import { useInvalidateFavoriteRelatedEntities } from './useInvalidateFavoriteRelatedEntities';
import { useRemoveArtifactFromFavoriteGroup } from './useRemoveArtifactFromFavoriteGroup';
import { useRemoveArtifactFromFavoriteToast } from './useRemoveArtifactFromFavoriteToast';

type UseImageActionsOptions = {
  image: ImageResponseDto;
  groupId?: string;
};

export const useImageActions = (options: UseImageActionsOptions) => {
  const { image, groupId } = options;
  const { handleAddToFavorite } = useImageActionHandlers({ image, parentId: groupId });
  const { t } = useTranslation();
  const favoriteArtifact: FavoriteArtifactEntity = {
    type: 'image',
    entityId: image.id,
    parentId: groupId,
    isFavorite: image.isFavourite,
  };
  const { handleRemove: handleRemoveFromGroup } = useRemoveArtifactFromFavoriteGroup(favoriteArtifact, groupId);
  const [trigger] = useDeleteFavoriteMutation();
  const { invalidateEntities } = useInvalidateFavoriteRelatedEntities(favoriteArtifact);
  const { showToast } = useRemoveArtifactFromFavoriteToast(favoriteArtifact);

  const handleRemoveFromFavorite = async (event: SyntheticEvent) => {
    event.stopPropagation();
    event.preventDefault();

    if (!image) {
      throw new NotDefinedParamException('favorite image');
    }

    try {
      await trigger({
        artifactType: 'image',
        artifactID: image.id,
      }).unwrap();
      invalidateEntities();
      showToast('Success');
    } catch (e) {
      showToast('Failed');
    }
  };

  const handleRemoveFromFavoriteGroup = (event: SyntheticEvent) => {
    event.stopPropagation();
    event.preventDefault();

    handleRemoveFromGroup();
  };

  const actions: Array<ActionListItem> = [
    {
      label: groupId ? t('actions.addToAnotherFavoriteGroup') : t('actions.addToFavoriteGroup'),
      onClick: handleAddToFavorite,
      hasAccess: () => favoritesAccessRuleService.canCreate(),
      startAdornment: <Icon name='folderAlt' />,
    },
    {
      label: groupId ? t('actions.removeFromFavoriteGroup') : t('actions.removeFromFavorite'),
      onClick: groupId ? handleRemoveFromFavoriteGroup : handleRemoveFromFavorite,
      hasAccess: () => favoritesAccessRuleService.canDelete(),
      startAdornment: <Icon name={groupId ? 'collectionDelete' : 'favoriteDelete'} />,
    },
  ];

  return { actions };
};
