import { skipToken } from '@reduxjs/toolkit/query';
import { FunctionComponent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CreateArtifactTypes } from 'features/createArtifact';
import { FolderPartition } from 'features/folders/folderEntity';
import { useDebounce, useSearchQuery } from 'features/search';
import { useCurrentLanguage, useLanguageRelevantData } from 'infrastructure/i18n';
import { AutocompleteField } from 'ui-library/components/AutocompleteField';
import { FoldersAutocompleteProps } from '../FoldersAutocomplete/FoldersAutocompleteProps';
import styles from './FoldersAutocomplete.module.scss';

export const FoldersAutocomplete: FunctionComponent<FoldersAutocompleteProps> = (props) => {
  const { selectedFolders, onInternalChange, type } = props;
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState('');
  const { matchProp } = useLanguageRelevantData();
  const currentLang = useCurrentLanguage();
  const debouncedSearchQuery = useDebounce(searchQuery);

  const result = useSearchQuery(
    {
      items: [
        {
          type: 'foldersAll',
          limit: 50,
          offset: 0,
        },
      ],
      partition: type === CreateArtifactTypes.IMAGES ? FolderPartition.MUSEUM : FolderPartition.ARCHIVE,
      language: currentLang,
      query: debouncedSearchQuery ?? '',
    } ?? skipToken,
  );

  const filteredOptions = useMemo(() => {
    return result?.data?.items[0].folder?.content.filter((folder) => {
      const foundFolder = selectedFolders.find((currentFolder) => currentFolder.id === folder.id);

      return foundFolder === undefined;
    });
  }, [result, selectedFolders]);

  return (
    <AutocompleteField
      value={undefined}
      onChange={onInternalChange as never}
      label={t('imageEditForm.folders.label')}
      placeholder={t('imageEditForm.folders.label')}
      className={styles.root}
      getOptionLabel={(option) => matchProp(option.captionRu, option.captionEn)}
      onChangeInput={setSearchQuery}
      options={filteredOptions ? filteredOptions : []}
      AutocompleteProps={{
        loading: result.isFetching,
        hideArrowIcon: true,
        showNoOptionsMessage: true,
      }}
    />
  );
};
