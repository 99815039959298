import cn from 'classnames';
import { useEffect } from 'react';
import { createSearchParams, useNavigate, useParams } from 'react-router-dom';
import { FolderPartition } from 'features/folders';
import { rootFolderListActions } from 'features/rootFolders';
import { toggleMuseumService } from 'features/toggleMuseum/services';
import { toggleMuseumActions } from 'features/toggleMuseum/toggleMuseumSlice';
import { ToggleMuseumRouteQueryParams } from 'features/toggleMuseum/types';
import { useTranslateEnum } from 'infrastructure/i18n';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux';
import { getGalleryRoute } from 'infrastructure/routes/paths';
import styles from './MuseumToggle.module.scss';

export const MuseumToggle = () => {
  const dispatch = useAppDispatch();
  const { translateValue } = useTranslateEnum('FolderPartition', FolderPartition);
  const partition = useAppSelector((state) => state.toggleMuseum.partition);
  const { partition: partitionQuery } = useParams<ToggleMuseumRouteQueryParams>();
  const navigate = useNavigate();

  const onChange = (partition: FolderPartition) => {
    navigate({ pathname: getGalleryRoute(partition), search: `?${createSearchParams({ partition })}` });
    dispatch(rootFolderListActions.pageChanged({ page: 1 }));
  };

  useEffect(() => {
    toggleMuseumService.setPartition(partition);
  }, [partition]);

  useEffect(() => {
    if (!partitionQuery || partition === partitionQuery) {
      return;
    }

    dispatch(toggleMuseumActions.changePartition(partitionQuery));
  }, [dispatch, partition, partitionQuery]);

  return (
    <div className={styles.root}>
      <button
        className={cn(styles.button, { [styles.active]: partition === FolderPartition.MUSEUM })}
        onClick={() => onChange(FolderPartition.MUSEUM)}
      >
        {translateValue(FolderPartition.MUSEUM)}
      </button>
      <div className={styles.divider}></div>
      <button
        className={cn(styles.button, { [styles.active]: partition === FolderPartition.ARCHIVE })}
        onClick={() => onChange(FolderPartition.ARCHIVE)}
      >
        {translateValue(FolderPartition.ARCHIVE)}
      </button>
    </div>
  );
};
