import { UserRole } from 'features/users';
import { roleService } from '../roleService';
import { AccessRuleService } from './accessRuleService';

class UserAccessRuleService extends AccessRuleService {
  protected canViewRoles: Array<UserRole> = [];
  protected canCreateRoles: Array<UserRole> = [];
  protected canEditRoles: Array<UserRole> = [];
  protected canDeleteRoles: Array<UserRole> = [];
  protected canUseAISearchRoles: Array<UserRole> = [UserRole.ADMIN, UserRole.SUPERADMIN];
  protected canViewUIElem: Array<UserRole> = [UserRole.ADMIN, UserRole.SUPERADMIN, UserRole.USER];

  public canUseAISearch() {
    return roleService.hasAccess(this.canUseAISearchRoles);
  }

  public canViewUI() {
    return roleService.hasAccess(this.canViewUIElem);
  }
}

export const userAccessRuleService = new UserAccessRuleService();
