import saveAs from 'file-saver';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { FileDownloadStatus } from 'features/file';
import { NotDefinedParamException } from 'infrastructure/exceptions';
import { Button, ButtonKind } from 'ui-library/components/Button';
import { FileDownloadTaskDetailItem } from '../FileDownloadTaskDetailItem/FileDownloadTaskDetailItem';
import styles from './FileDownloadDetailArchiveActions.module.scss';
import { FileDownloadDetailArchiveActionsProps } from './FileDownloadDetailArchiveActionsProps';

export const FileDownloadDetailArchiveActions: FunctionComponent<FileDownloadDetailArchiveActionsProps> = (props) => {
  const { task } = props;
  const { t } = useTranslation();

  const handleDownload = () => {
    if (!task) {
      throw new NotDefinedParamException('Download task is not defined');
    }

    saveAs(task.URL, task.label);
  };

  return (
    <div className={styles.root}>
      <FileDownloadTaskDetailItem task={task} />
      {task?.status === FileDownloadStatus.DONE && (
        <Button className={styles.button} onClick={handleDownload} as={ButtonKind.BUTTON}>
          {t('actions.download')}
        </Button>
      )}
    </div>
  );
};
