import React, { FunctionComponent } from 'react';
import { useFolderActions } from 'features/folders/hooks';
import { FolderCardBase } from 'features/v1/folder/FolderCardBase/FolderCardBase';
import { FolderCardBaseExternalProps } from '../FolderCardBase/FolderCardBaseProps';

export const FolderCard: FunctionComponent<FolderCardBaseExternalProps> = (props) => {
  const { folder, isShowOrderMode } = props;
  const { actions } = useFolderActions(folder);

  return <FolderCardBase folder={folder} actions={actions} isShowOrderMode={isShowOrderMode} />;
};
