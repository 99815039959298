import cn from 'classnames';
import React, { FunctionComponent } from 'react';
import styles from './ListItemText.module.scss';
import { ListItemTextProps } from './ListItemTextProps';

/**
 * @deprecated
 */
export const ListItemText: FunctionComponent<ListItemTextProps> = (props) => {
  const { primary, secondary, classes, inset, className } = props;

  return (
    <div className={cn(className, { [styles.inset]: inset })}>
      <div className={cn(styles.primary, classes?.primary)}>{primary}</div>
      {secondary && <div className={cn(styles.secondary, classes?.secondary)}>{secondary}</div>}
    </div>
  );
};
