import { FunctionComponent } from 'react';
import { favoriteLayoutListActions } from 'features/favorites';
import { SortButton } from 'features/sorting';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux';
import styles from './LayoutListHeaderActions.module.scss';

export const LayoutListHeaderActions: FunctionComponent = () => {
  const { sortDirection } = useAppSelector((state) => state.favoriteLayoutList.sortingInfo);
  const dispatch = useAppDispatch();

  const toggleSort = () => {
    dispatch(
      favoriteLayoutListActions.sortChanged({
        sortField: 'favouritesCreateTime',
      }),
    );
  };

  return (
    <div className={styles.root}>
      <SortButton className={styles.active} direction={sortDirection} toggleSort={toggleSort} />
    </div>
  );
};
