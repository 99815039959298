import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getListSortReducers, ListLayout, ListState } from 'features/list';
import { getListPaginationReducers } from 'features/list/listReducers';
import { getDefaultPaginationState } from 'features/pagination';
import { SortDirection } from 'features/sorting';
import { FavoriteContentSortingParams } from '../favoriteEntity';

type EntityType = FavoriteContentSortingParams;
export type FavoriteFolderListState = ListState<EntityType> & {
  layoutType: ListLayout;
};

const initialState: FavoriteFolderListState = {
  layoutType: ListLayout.CARD,
  sortingInfo: {
    sortField: 'favouritesCreateTime',
    sortDirection: SortDirection.DESC,
  },
  paginationData: getDefaultPaginationState(),
};

const favoriteFolderListSlice = createSlice({
  name: 'favoriteFolderList',
  initialState,
  reducers: {
    ...getListSortReducers<FavoriteFolderListState, EntityType>(),
    ...getListPaginationReducers<FavoriteFolderListState>(),
    changeLayoutType: (state, action: PayloadAction<ListLayout>) => {
      state.layoutType = action.payload;
    },
  },
});

export const favoriteFolderListActions = favoriteFolderListSlice.actions;
export const favoriteFolderListReducer = favoriteFolderListSlice.reducer;
