import { FilePreviewLabel } from 'features/file';
import { FolderResponseDto } from 'features/folders';
import { ImageResponseDto } from 'features/images';
import { ListApiResponse } from 'infrastructure/network';
import { LayoutEditFormFields, LayoutResponseDto } from '../layoutEntity';

export const useMapLayoutEntityToFormValues = (
  data?: LayoutResponseDto,
  images?: ListApiResponse<ImageResponseDto>,
  folders?: ListApiResponse<FolderResponseDto>,
) => {
  const preview = data?.file?.previews?.find((x) => x.label === FilePreviewLabel.LAYOUT_MAIN);

  const defaultValues: LayoutEditFormFields = {
    layoutNumber: data?.number,
    archiveName: data?.archiveName,
    descriptionRu: data?.descriptionRu,
    descriptionEn: data?.descriptionEn,
    status: data?.status,
    materialsKinds: data?.materialsKinds || [],
    tags: data?.tags,
    fileBox: {
      previewUrl: preview?.url,
    },
    images: images ? images.content : [],
    folders: folders ? folders.content : [],
  };

  return defaultValues;
};
