import cn from 'classnames';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonKind } from 'ui-library/components/Button';
import { Icon } from 'ui-library/components/Icon';
import { IconButton, IconButtonVariant } from 'ui-library/components/IconButton';
import { ActiveFileDownloadTaskState } from '../ActiveFileDownloadTasks/ActiveFileDownloadTaskState';
import styles from './ActiveFileDownloadTasksHeader.module.scss';
import { ActiveFileDownloadTasksHeaderProps } from './ActiveFileDownloadTasksHeaderProps';

export const ActiveFileDownloadTasksHeader: FunctionComponent<ActiveFileDownloadTasksHeaderProps> = (props) => {
  const { t } = useTranslation();
  const { windowState, onCloseClick, onToggleClick } = props;

  return (
    <div className={styles.root}>
      <span className={styles.title}>{t('files.downloads.title')}</span>
      <div className={styles.actions}>
        <IconButton as={ButtonKind.BUTTON} variant={IconButtonVariant.TRANSPARENT} onClick={onToggleClick} size='small'>
          <Icon
            className={cn(styles.button, {
              [styles.collapsed]: windowState === ActiveFileDownloadTaskState.COLLAPSED,
              [styles.opened]: windowState === ActiveFileDownloadTaskState.OPENED,
            })}
            name='chevronRight'
          />
        </IconButton>
        <IconButton
          className={styles.button}
          as={ButtonKind.BUTTON}
          variant={IconButtonVariant.TRANSPARENT}
          onClick={onCloseClick}
          size='small'
        >
          <Icon name='cross' />
        </IconButton>
      </div>
    </div>
  );
};
