import { FunctionComponent } from 'react';
import { favoriteFolderListActions } from 'features/favorites';
import { ListLayout, ListLayoutButtonGroup } from 'features/list';
import { SortButton } from 'features/sorting';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux';
import styles from './FolderListHeaderActions.module.scss';

export const FolderListHeaderActions: FunctionComponent = () => {
  const { sortDirection } = useAppSelector((state) => state.favoriteFolderList.sortingInfo);
  const layoutType = useAppSelector((state) => state.favoriteFolderList.layoutType);
  const dispatch = useAppDispatch();

  const toggleSort = () => {
    dispatch(
      favoriteFolderListActions.sortChanged({
        sortField: 'favouritesCreateTime',
      }),
    );
  };

  const handleChangeLayout = (layout: ListLayout) => {
    dispatch(favoriteFolderListActions.changeLayoutType(layout));
  };

  return (
    <div className={styles.root}>
      <ListLayoutButtonGroup active={layoutType} onLayoutChanged={handleChangeLayout} />

      <SortButton className={styles.active} direction={sortDirection} toggleSort={toggleSort} />
    </div>
  );
};
