import { FunctionComponent } from 'react';
import { AccountMenu } from 'features/account';
import { ToggleLanguageButton } from 'features/localization/v1/components';
import { userAccessRuleService } from 'features/role';
import { rootFolderListActions } from 'features/rootFolders';
import { MuseumToggle } from 'features/toggleMuseum/v1/components';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux';
import { getGalleryRoute } from 'infrastructure/routes/paths';
import { Icon } from 'ui-library/components/Icon';
import { Link, LinkKind } from 'ui-library/v1/components/Link';
import styles from './Header.module.scss';

export const Header: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const partition = useAppSelector((state) => state.toggleMuseum.partition);

  const handleLogoClick = () => {
    dispatch(rootFolderListActions.pageChanged({ page: 1 }));
  };

  return (
    <header className={styles.root}>
      {userAccessRuleService.canViewUI() && <MuseumToggle />}
      <Link className={styles.logo} as={LinkKind.INNER} to={getGalleryRoute(partition)} onClick={handleLogoClick}>
        <Icon name='logoHeader' size='none' className={styles.icon} />
      </Link>
      <ToggleLanguageButton className={styles.language} />
      {userAccessRuleService.canViewUI() && <AccountMenu className={styles.menu} />}
    </header>
  );
};
