import cn from 'classnames';
import { forwardRef } from 'react';
import { ImageCardBase } from '../ImageScaleCard/ImageCardBase/ImageCardBase';
import styles from './ImageScaleCard.module.scss';
import { ImageScaleCardProps } from './ImageScaleCardProps';

export const ImageScaleCard = forwardRef<HTMLDivElement, ImageScaleCardProps>((props, ref) => {
  const { image, className } = props;

  return <ImageCardBase className={cn(styles.card, className)} data={image} />;
});
