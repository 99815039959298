import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useFetchFolderPathQuery } from '../foldersApi';

type UseFolderBreadcrumbsOptions = {
  folderId: string | undefined;
};

export const useFolderBreadcrumbs = (options: UseFolderBreadcrumbsOptions) => {
  const { folderId } = options;
  const { data: folderPath } = useFetchFolderPathQuery(folderId ?? skipToken);

  return folderPath || [];
};
