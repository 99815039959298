import { UserRole } from 'features/users';
import { roleService } from '../roleService';
import { AccessRuleService } from './accessRuleService';

class FileAccessRuleService extends AccessRuleService {
  protected canViewRoles: Array<UserRole> = [UserRole.ADMIN];
  protected canCreateRoles: Array<UserRole> = [UserRole.ADMIN];
  protected canEditRoles: Array<UserRole> = [UserRole.ADMIN];
  protected canDeleteRoles: Array<UserRole> = [UserRole.ADMIN];
  protected canDownloadRoles: Array<UserRole> = [UserRole.ADMIN];
  protected canDownloadExcelRoles: Array<UserRole> = [UserRole.ADMIN];
  protected canShareRoles: Array<UserRole> = [UserRole.ADMIN];
  protected canChangeOrder: Array<UserRole> = [UserRole.ADMIN];

  public canDownload() {
    return roleService.hasAccess(this.canDownloadRoles);
  }

  public canDownloadExcel() {
    return roleService.hasAccess(this.canDownloadExcelRoles);
  }

  public canShare() {
    return roleService.hasAccess(this.canShareRoles);
  }

  public canChange() {
    return roleService.hasAccess(this.canChangeOrder);
  }
}

export const fileAccessRuleService = new FileAccessRuleService();
