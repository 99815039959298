import { authService } from 'features/auth';
import { UserRole } from 'features/users';

class RoleService {
  public hasAccess(acceptableRoles: Array<UserRole>) {
    const role = authService.tokenData?.role;

    if (!role) {
      return false;
    }

    if (role === UserRole.SUPERADMIN) {
      return true;
    }

    return acceptableRoles.includes(role);
  }
}

export const roleService = new RoleService();
