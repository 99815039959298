import cn from 'classnames';
import { FunctionComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FilePreviewLabel } from 'features/file';
import { useLanguageRelevantData } from 'infrastructure/i18n';
import { Image } from 'ui-library/components/Image';
import { LinkKind } from 'ui-library/components/Link';
import { ListItem, ListItemKind } from 'ui-library/components/List';
import styles from './ImageAutocompleteList.module.scss';
import { ImageAutocompleteListProps } from './ImageAutocompleteListProps';

export const ImageAutocompleteList: FunctionComponent<ImageAutocompleteListProps> = (props) => {
  const { image, previewLabel = FilePreviewLabel.IMAGE_ICON, view } = props;
  const { t } = useTranslation();
  const { matchProp } = useLanguageRelevantData();

  const preview = useMemo(() => image.file?.previews?.find((x) => x.label === previewLabel), [previewLabel, image]);

  return (
    <ListItem
      className={cn(styles.root, {
        [styles.viewSearch]: view === 'search',
      })}
      kind={ListItemKind.LI}
      as={LinkKind.INNER}
      disableGutters
      padding={'none'}
    >
      <div>
        <Image className={styles.image} src={preview?.url} alt={t('images.imageAlt')} />
      </div>
      <div className={styles.info}>
        <span className={styles.caption}>{matchProp(image.captionRu, image.captionEn)}</span>
      </div>
    </ListItem>
  );
};
