import { PayloadAction } from '@reduxjs/toolkit';
import { getPaginationReducers } from 'features/pagination';
import { getSortReducers } from 'features/sorting';
import { SortChangedPayload } from 'features/sorting';
import { selectPaginationData, selectSortingInfo } from './listSelectors';
import { ListPaginationState, ListState } from './listState';

export function getListSortReducers<TState extends ListState<TEntity>, TEntity>() {
  const { sortChanged, ...rest } = getSortReducers<TState, TEntity>(selectSortingInfo);

  return {
    ...rest,
    sortChanged: (state: TState, action: PayloadAction<SortChangedPayload<TEntity>>) => {
      sortChanged(state, action);
      state.paginationData.currentPage = 1;
    },
  };
}

export function getListPaginationReducers<TState extends ListPaginationState>() {
  return getPaginationReducers<TState>(selectPaginationData);
}
