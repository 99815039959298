import { FunctionComponent } from 'react';
import { Tab } from 'ui-library/components/Tab';
import { hasFavoriteContent } from '../../utils/favoriteContentUtils';
import { FavoriteContentEmpty } from '../FavoriteContentEmpty/FavoriteContentEmpty';
import { FolderDataView } from '../FolderList/FolderDataView/FolderDataView';
import { ImageDataView } from '../ImageList/ImageDataView/ImageDataView';
import { LayoutDataView } from '../LayoutList/LayoutDataView/LayoutDataView';
import { RootFolderDataView } from '../RootFolderList/RootFolderDataView/RootFolderDataView';
import styles from './FavoriteContentDataView.module.scss';
import { FavoriteContentDataViewProps } from './FavoriteContentDataViewProps';

export const FavoriteContentDataView: FunctionComponent<FavoriteContentDataViewProps> = (props) => {
  const { group } = props;

  const showZeroBlock = group !== undefined && !hasFavoriteContent(group);

  if (showZeroBlock) {
    return <FavoriteContentEmpty />;
  }

  return (
    <Tab.Panels className={styles.panels}>
      <Tab.Panel>
        <RootFolderDataView groupId={group?.id} />
      </Tab.Panel>
      <Tab.Panel>
        <FolderDataView groupId={group?.id} />
      </Tab.Panel>
      <Tab.Panel>
        <LayoutDataView groupId={group?.id} />
      </Tab.Panel>
      <Tab.Panel>
        <ImageDataView groupId={group?.id} />
      </Tab.Panel>
    </Tab.Panels>
  );
};
