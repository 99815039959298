import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { removeFromFavoritesActions, useDeleteFavoriteMutation } from 'features/favorites';
import { useInvalidateFavoriteRelatedEntities, useRemoveArtifactFromFavoriteToast } from 'features/favorites/hooks';
import { NotDefinedParamException } from 'infrastructure/exceptions';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux';
import { ROUTE_PATHS } from 'infrastructure/routes';
import { ActionButton } from 'ui-library/components/ActionButton';
import { ButtonKind, ButtonVariant } from 'ui-library/components/Button';
import { Modal } from 'ui-library/components/Modal';
import { Link, LinkKind, LinkVariant } from 'ui-library/v1/components/Link';
import styles from './FavoriteRemoveConfirm.module.scss';

export const FavoriteRemoveConfirm: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const { isOpen, favoriteArtifact } = useAppSelector((state) => state.removeFromFavorites);
  const { invalidateEntities } = useInvalidateFavoriteRelatedEntities(favoriteArtifact);
  const { showToast } = useRemoveArtifactFromFavoriteToast(favoriteArtifact);
  const { t } = useTranslation();
  const [trigger, { isLoading }] = useDeleteFavoriteMutation();

  const handleClose = () => {
    dispatch(removeFromFavoritesActions.setFavoriteArtifact(undefined));
    dispatch(removeFromFavoritesActions.close());
  };

  const handleRemove = async () => {
    if (!favoriteArtifact) {
      throw new NotDefinedParamException('favorite artifact');
    }

    try {
      await trigger({
        artifactType: favoriteArtifact.type,
        artifactID: favoriteArtifact.entityId,
      }).unwrap();
      invalidateEntities();
      showToast('Success');
      handleClose();
    } catch (e) {
      showToast('Failed');
    }
  };

  return (
    <Modal variant='popup' open={isOpen} onClose={handleClose} closeOnChangeLocation>
      <Modal.Title>{t('favorites.removeFromFavorite.title')}</Modal.Title>
      <Modal.Content className={styles.content}>
        {t('favorites.removeFromFavorite.description')}
        <Link variant={LinkVariant.STYLED} as={LinkKind.INNER} to={ROUTE_PATHS.FAVORITES.ROOT}>
          {t('favorites.content.title')}
        </Link>
        .
      </Modal.Content>
      <Modal.Actions>
        <ActionButton as={ButtonKind.BUTTON} onClick={handleRemove} loading={isLoading}>
          {t('actions.remove')}
        </ActionButton>
        <ActionButton as={ButtonKind.BUTTON} variant={ButtonVariant.SECONDARY} onClick={handleClose}>
          {t('actions.cancel')}
        </ActionButton>
      </Modal.Actions>
    </Modal>
  );
};
