import { forwardRef } from 'react';
import styles from './Radio.module.scss';
import { RadioProps } from './RadioProps';

export const Radio = forwardRef<HTMLInputElement, RadioProps>((props, ref) => (
  <span className={styles.root}>
    <input {...props} ref={ref} type='radio' className={styles.input} />
    <span className={styles.circle}>
      <span className={styles.check} />
    </span>
  </span>
));
