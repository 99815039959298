import { translateKey } from 'infrastructure/i18n';
import { FolderResponseDto } from '../folderEntity';

export class DeleteFolderTranslationsService {
  public getTranslations(folder?: FolderResponseDto) {
    if (!folder) {
      return undefined;
    }

    if (folder.isRoot) {
      return {
        title: translateKey('folders.deleteEmptyRootFolderModal.title'),
        description:
          folder.childrenCount > 0
            ? translateKey('folders.deleteEmptyRootFolderModal.content.withChildren')
            : translateKey('folders.deleteEmptyRootFolderModal.content.withoutChildren'),
      };
    } else {
      return {
        title: translateKey('folders.deleteEmptyFolderModal.title'),
        description:
          folder.childrenCount > 0
            ? translateKey('folders.deleteEmptyFolderModal.content.withChildren')
            : translateKey('folders.deleteEmptyFolderModal.content.withoutChildren'),
      };
    }
  }
}

export const deleteFolderTranslationsService = new DeleteFolderTranslationsService();
