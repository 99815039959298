import cn from 'classnames';
import React, { forwardRef } from 'react';
import { ListProps } from './ListProps';

/**
 * @deprecated
 */
export const List = forwardRef<HTMLDivElement, ListProps>((props, ref) => {
  const { className, children, as = 'ul', ...rest } = props;

  return React.createElement(
    as,
    {
      ...rest,
      ref,
      className: cn(className),
    },
    children,
  );
});
