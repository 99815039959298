import cn from 'classnames';
import { forwardRef } from 'react';
import styles from './Textarea.module.scss';
import { TextareaProps } from './TextareaProps';

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>((props, ref) => {
  const { className, error, ...rest } = props;

  return (
    <div className={styles.root}>
      <textarea
        className={cn(styles.textarea, className, {
          [styles.error]: !!error,
        })}
        ref={ref}
        {...rest}
      />
    </div>
  );
});
