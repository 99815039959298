import { FunctionComponent } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AddToFavoritesFormFields, FavoriteGroupDto, useFetchGroupsQuery } from 'features/favorites';
import { useInfiniteLoading } from 'features/pagination';
import { SortDirection } from 'features/sorting';
import { ListApiFetchArgs, ListApiResponse } from 'infrastructure/network';
import { FavoriteGroupList } from '../FavoriteGroupList/FavoriteGroupList';
import styles from './FavoriteGroupListDataView.module.scss';

export const FavoriteGroupListDataView: FunctionComponent = () => {
  const { t } = useTranslation();
  const { getValues, setValue, control } = useFormContext<AddToFavoritesFormFields>();

  const { data, isError, isFetching, readMore, refresh } = useInfiniteLoading<
    FavoriteGroupDto,
    ListApiResponse<FavoriteGroupDto>,
    ListApiFetchArgs<FavoriteGroupDto>
  >(
    useFetchGroupsQuery,
    (state) => ({
      pageSize: state.pageSize,
      currentPage: state.currentPage,
      sortDirection: SortDirection.DESC,
      sortFields: ['createTime'],
    }),
    (result) => result,
    {
      pageSize: 30,
    },
  );

  const toggle = (group: FavoriteGroupDto) => {
    const { groupIDsToAddTo } = getValues();

    if (groupIDsToAddTo.some((x) => x.id === group.id)) {
      const filteredData = groupIDsToAddTo.filter((x) => x.id !== group.id);
      setValue('groupIDsToAddTo', filteredData);
    } else {
      setValue('groupIDsToAddTo', [...groupIDsToAddTo, group]);
    }
  };

  const showHint = data && data?.length > 0;

  return (
    <div className={styles.root}>
      {showHint && <p className={styles.hint}>{t('favorites.addToFavorite.hint')}</p>}

      <Controller
        control={control}
        name={'groupIDsToAddTo'}
        render={({ field: { value } }) => (
          <FavoriteGroupList
            isError={isError}
            isFetching={isFetching}
            data={data}
            readMore={readMore}
            refetch={refresh}
            onItemClick={toggle}
            isItemSelected={(x) => value.some((group) => group.id === x.id)}
          />
        )}
      />
    </div>
  );
};
