import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FolderPartition } from 'features/folders';
import { getDefaultModalState, getModalReducers, ModalState } from 'features/modal';

export type ToggleMuseumModalState = ModalState & {
  query?: string;
  selectedPartition?: FolderPartition;
};

const initialState: ToggleMuseumModalState = {
  ...getDefaultModalState(),
  query: undefined,
  selectedPartition: undefined,
};

const toggleMuseumModalSlice = createSlice({
  name: 'toggleMuseumModal',
  initialState,
  reducers: {
    ...getModalReducers<ToggleMuseumModalState>((state) => state),
    setQuery: (state, action: PayloadAction<string | undefined>) => {
      state.query = action.payload;
    },
    setPartition: (state, action: PayloadAction<FolderPartition | undefined>) => {
      state.selectedPartition = action.payload;
    },
  },
});

export const toggleMuseumModalActions = toggleMuseumModalSlice.actions;
export const toggleMuseumModalReducer = toggleMuseumModalSlice.reducer;
