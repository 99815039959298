import { FunctionComponent, useRef } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { ModalSubmitActions } from 'features/modal';
import {
  referenceBookApi,
  ReferenceBookEditFormFields,
  ReferenceBookType,
  useCreateReferenceBookMutation,
} from 'features/referenceBook';
import { formTranslationsService } from 'features/referenceBook/services';
import { apiErrorMessageMatcher } from 'features/referenceBook/utils';
import { searchApi } from 'features/search';
import { parseApiError } from 'infrastructure/network';
import { useAppDispatch } from 'infrastructure/redux';
import { Modal } from 'ui-library/components/Modal';
import { ReferenceBookEditForm } from '../ReferenceBookEditForm';
import { ReferenceBookEditFormCentury } from '../ReferenceBookEditFormCentury';
import { ReferenceBookCreateProps } from './ReferenceBookCreateProps';

export const ReferenceBookCreate: FunctionComponent<ReferenceBookCreateProps> = (props) => {
  const { type, onCreated, onClose, isOpen } = props;
  const [createReferenceBookMutation] = useCreateReferenceBookMutation();
  const dispatch = useAppDispatch();

  const { t } = useTranslation();
  const translations = useRef(formTranslationsService.getTranslations(type)).current;

  const onSubmit = async (values: ReferenceBookEditFormFields) => {
    try {
      const result = await createReferenceBookMutation({
        captionRu: values.captionRu,
        captionEn: values.captionEn,
        type: type,
      }).unwrap();

      onCreated(result);
      dispatch(
        referenceBookApi.util.invalidateTags([
          {
            type: 'ReferenceBooks',
            id: ReferenceBookType.AUTHORS,
          },
        ]),
      );
      dispatch(searchApi.util.invalidateTags(['SearchResult']));
      toast.success(t(translations.createSuccessToastMessage));
      onClose();
    } catch (e) {
      const error = parseApiError(e);

      toast.error(t(apiErrorMessageMatcher(error, type)));
    }
  };

  return (
    <Modal hideBackdrop open={isOpen} onClose={onClose}>
      <Modal.Title>{t(translations.createTitle)}</Modal.Title>
      {type === ReferenceBookType.CENTURY ? (
        <ReferenceBookEditFormCentury
          onSubmit={onSubmit}
          renderActions={({ isSubmitting }) => <ModalSubmitActions loading={isSubmitting} onCancel={onClose} />}
        />
      ) : (
        <ReferenceBookEditForm
          type={type}
          onSubmit={onSubmit}
          renderActions={({ isSubmitting }) => <ModalSubmitActions loading={isSubmitting} onCancel={onClose} />}
        />
      )}
    </Modal>
  );
};
