import cn from 'classnames';
import { FunctionComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FilePreviewLabel } from 'features/file';
import { Image } from 'ui-library/components/Image';
import { LinkKind } from 'ui-library/components/Link';
import { ListItem, ListItemKind } from 'ui-library/components/List';
import styles from './LayoutListItem.module.scss';
import { LayoutListItemProps } from './LayoutListItemProps';

export const LayoutListItem: FunctionComponent<LayoutListItemProps> = (props) => {
  const { layout, previewLabel = FilePreviewLabel.LAYOUT_ICON, view } = props;
  const { t } = useTranslation();

  const preview = useMemo(() => layout.file?.previews?.find((x) => x.label === previewLabel), [previewLabel, layout]);

  return (
    <ListItem
      className={cn(styles.root, {
        [styles.viewSearch]: view === 'search',
      })}
      kind={ListItemKind.LI}
      as={LinkKind.INNER}
      disableGutters
      padding={'none'}
    >
      <div>
        <Image className={styles.image} src={preview?.url} alt={t('layouts.imageAlt')} />
      </div>
      <div className={styles.info}>
        <span className={styles.caption}>{layout.archiveName}</span>
      </div>
    </ListItem>
  );
};
