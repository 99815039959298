import * as yup from 'yup';
import { SchemaOf } from 'yup';
import { getFileBoxValidator, uploadMaxFileSizeMB, uploadSupportedMimeImageTypes } from 'features/file';
import { FolderEditFormFields, FolderStatus, MaterialsKind } from './folderEntity';

const maxLenDescription = 15_000;

const getCommonValidators = () => ({
  fileBox: getFileBoxValidator({
    mimeTypes: uploadSupportedMimeImageTypes,
    fileSizeMB: uploadMaxFileSizeMB,
    optional: true,
  }),
  captionEn: yup.string().required(),
  captionRu: yup.string().required(),
  descriptionRu: yup.string().optional().max(maxLenDescription),
  descriptionEn: yup.string().optional().max(maxLenDescription),
  status: yup.mixed<FolderStatus>().oneOf(Object.values(FolderStatus)),
  materialsKinds: yup
    .array()
    .of(yup.mixed<MaterialsKind>().oneOf(Object.values(MaterialsKind)))
    .notRequired(),
  tags: yup.array().of(yup.mixed()).notRequired(),
  tagsExtend: yup.array().of(yup.string()).notRequired(),
});

export const FolderEditDtoValidationSchema = (): SchemaOf<FolderEditFormFields> => yup.object(getCommonValidators());
