import { Listbox } from '@headlessui/react';
import cn from 'classnames';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseSelectOptionProps, getOptionValueDefault, MultiValue, SingleValue } from '../BaseSelect';
import { getDefaultComponents } from './getDefaultComponents';
import { MultiValueContainer } from './MultiValueContainer/MultiValueContainer';
import styles from './Select.module.scss';
import { SelectProps } from './SelectProps';
import { SingleValueContainer } from './SingleValueContainer/SingleValueContainer';

/**
 * @deprecated
 */
export const Select = <Option, OptionValue, IsMulti extends boolean = false>(
  props: SelectProps<Option, OptionValue, IsMulti>,
) => {
  const {
    value,
    options,
    placeholder,
    onChange,
    className,
    disabled,
    error,
    getOptionLabel,
    getOptionValue = getOptionValueDefault,
    components: componentOverrides,
    multiple,
    canUnselect,
    headOptions,
    InputProps,
    ...restProps
  } = props;

  const { t } = useTranslation();

  const components = useRef(getDefaultComponents<Option, OptionValue>(componentOverrides));
  const OptionComponent = components.current.Option;

  return (
    <Listbox value={value} disabled={disabled} onChange={onChange as never} multiple={multiple} {...restProps}>
      {({ open }) => (
        <div className={cn(styles.root, className)}>
          {multiple ? (
            <MultiValueContainer
              error={error}
              disabled={disabled}
              options={options}
              value={value as MultiValue<OptionValue>}
              placeholder={placeholder}
              onChange={onChange as BaseSelectOptionProps<Option, OptionValue>['onChange']}
              getOptionLabel={getOptionLabel}
              getOptionValue={getOptionValue}
              open={open}
              InputProps={InputProps}
            />
          ) : (
            <SingleValueContainer
              error={error}
              disabled={disabled}
              options={options}
              value={value as SingleValue<OptionValue>}
              placeholder={placeholder}
              getOptionLabel={getOptionLabel}
              getOptionValue={getOptionValue}
              open={open}
              InputProps={InputProps}
            />
          )}
          <Listbox.Options className={styles.options}>
            {canUnselect && (
              <OptionComponent
                option={null as never}
                getOptionLabel={() => t('form.unselect')}
                getOptionValue={() => null as never}
              />
            )}
            {headOptions?.map((option, index) => (
              <OptionComponent key={index} {...(option as never as BaseSelectOptionProps<Option, OptionValue>)} />
            ))}
            {options.map((option, index) => (
              <OptionComponent
                key={index}
                option={option}
                getOptionLabel={getOptionLabel}
                getOptionValue={getOptionValue as BaseSelectOptionProps<Option, OptionValue>['getOptionValue']}
              />
            ))}
          </Listbox.Options>
        </div>
      )}
    </Listbox>
  );
};
