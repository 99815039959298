import cn from 'classnames';
import { FunctionComponent, SyntheticEvent, useState } from 'react';
import { ButtonKind } from 'ui-library/components/Button';
import { Icon } from 'ui-library/components/Icon';
import { IconButton, IconButtonVariant } from 'ui-library/components/IconButton';
import { ActionList, getAvailableListActions } from 'ui-library/v1/components/ActionList';
import { Popover, PopoverContent, PopoverTrigger } from 'ui-library/v1/components/Popover';
import styles from './ActionsMenu.module.scss';
import { ActionsMenuProps } from './ActionsMenuProps';

export const ActionsMenu: FunctionComponent<ActionsMenuProps> = (props) => {
  const { className, actions, menuClassName } = props;
  const [isContextMenuOpened, setContextMenuOpen] = useState(false);

  const handleContextMenu = (event: SyntheticEvent) => {
    event.preventDefault();

    setContextMenuOpen(true);
  };

  const handleActionClick = (event: SyntheticEvent) => {
    event.preventDefault();
  };

  return (
    <div className={styles.wrap} onContextMenu={handleContextMenu}>
      {getAvailableListActions(actions).length > 0 && (
        <Popover
          behavior='click'
          placement='bottom-end'
          offset={8}
          open={isContextMenuOpened}
          onOpenChange={setContextMenuOpen}
        >
          <PopoverTrigger>
            <IconButton
              className={cn(styles.button, className)}
              as={ButtonKind.BUTTON}
              variant={IconButtonVariant.SECONDARY}
              onClick={handleActionClick}
            >
              <Icon name='dots' />
            </IconButton>
          </PopoverTrigger>
          <PopoverContent className={menuClassName}>
            {({ close }) => <ActionList actions={actions} onClose={close} />}
          </PopoverContent>
        </Popover>
      )}
    </div>
  );
};
