import { FunctionComponent } from 'react';
import { useLayoutActionHandlers, useLayoutDownloadActions } from 'features/layouts/hooks';
import { layoutAccessRuleService } from 'features/role';
import { ActionList } from 'ui-library/components/ActionList';
import { ButtonKind } from 'ui-library/components/Button';
import { Icon } from 'ui-library/components/Icon';
import { IconButton, IconButtonVariant } from 'ui-library/components/IconButton';
import { Popover, PopoverContent, PopoverTrigger } from 'ui-library/components/Popover';
import { LayoutFavoriteButtonGroup } from '../../LayoutFavoriteButtonGroup/LayoutFavoriteButtonGroup';
import styles from './LayoutViewHeader.module.scss';
import { LayoutViewHeaderProps } from './LayoutViewHeaderProps';

export const LayoutViewHeader: FunctionComponent<LayoutViewHeaderProps> = (props) => {
  const { layout } = props;
  const { handleEdit } = useLayoutActionHandlers({ layout, parentId: undefined });
  const { actions: downloadActions } = useLayoutDownloadActions({
    layout,
  });

  return (
    <div className={styles.header}>
      <span className={styles.title}>{layout?.archiveName}</span>
      <div className={styles.buttonGroup}>
        {layoutAccessRuleService.canView() && <LayoutFavoriteButtonGroup layout={layout} className={styles.button} />}

        {layoutAccessRuleService.canEdit() && (
          <IconButton as={ButtonKind.BUTTON} onClick={handleEdit} className={styles.button}>
            <Icon name='edit' />
          </IconButton>
        )}
        {layoutAccessRuleService.canViewUI() && (
          <Popover behavior='click' placement='bottom-end'>
            <PopoverTrigger>
              <IconButton as={ButtonKind.BUTTON} variant={IconButtonVariant.SECONDARY}>
                <Icon name='downloadAlt' />
              </IconButton>
            </PopoverTrigger>
            <PopoverContent>{({ close }) => <ActionList actions={downloadActions} onClose={close} />}</PopoverContent>
          </Popover>
        )}
      </div>
    </div>
  );
};
