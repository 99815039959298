import { Icon } from 'ui-library/components/Icon';
import styles from './CreateOptionLabel.module.scss';
import { CreateOptionLabelProps } from './CreateOptionLabelProps';

export const CreateOptionLabel = (props: CreateOptionLabelProps) => {
  const { label } = props;

  return (
    <>
      <Icon name='plus' />
      <span className={styles.label}>{label}</span>
    </>
  );
};
