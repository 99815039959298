import { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { ReferenceBook } from 'features/referenceBook/referenceBookEntity';
import { usersAccessRuleService } from 'features/role';
import { NotDefinedParamException } from 'infrastructure/exceptions';
import { useAppDispatch } from 'infrastructure/redux';
import { ActionListItem } from 'ui-library/v1/components/ActionList';
import { authorDeleteActions, authorEditActions } from '../slices';

export const useAuthorActions = (author: ReferenceBook | undefined) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleEdit = (event: SyntheticEvent) => {
    event.stopPropagation();
    event.preventDefault();

    if (!author) {
      throw new NotDefinedParamException('author');
    }

    dispatch(authorEditActions.setAuthor(author));
    dispatch(authorEditActions.open());
  };

  const handleDelete = (event: SyntheticEvent) => {
    event.stopPropagation();
    event.preventDefault();

    if (!author) {
      throw new NotDefinedParamException('author');
    }

    dispatch(authorDeleteActions.setAuthor(author));
    dispatch(authorDeleteActions.open());
  };

  const actions: Array<ActionListItem> = [
    {
      label: t('actions.edit'),
      onClick: handleEdit,
      hasAccess: () => usersAccessRuleService.canEditReferences(),
    },
    {
      label: t('actions.remove'),
      onClick: handleDelete,
      hasAccess: () => usersAccessRuleService.canEditReferences(),
    },
  ];

  return { actions };
};
