import { useTranslation } from 'react-i18next';
import { ActionButton } from 'ui-library/components/ActionButton';
import { ButtonKind, ButtonVariant } from 'ui-library/components/Button';
import { ModalSubmitActionsProps } from './ModalSubmitActionsProps';

export const ModalSubmitActions = (props: ModalSubmitActionsProps) => {
  const { t } = useTranslation();
  const { submitLabel, onCancel, loading, disabled } = props;

  return (
    <>
      <ActionButton as={ButtonKind.BUTTON} loading={loading} type='submit' disabled={disabled}>
        {submitLabel || t('actions.save')}
      </ActionButton>
      <ActionButton as={ButtonKind.BUTTON} variant={ButtonVariant.SECONDARY} disabled={loading} onClick={onCancel}>
        {t('actions.cancel')}
      </ActionButton>
    </>
  );
};
