import { FunctionComponent } from 'react';
import { ButtonKind } from 'ui-library/components/Button';
import { CardVariant } from 'ui-library/components/Card';
import { Icon } from 'ui-library/components/Icon';
import { IconButton, IconButtonVariant } from 'ui-library/components/IconButton';
import { FavoriteGroupCardRoot } from '../FavoriteGroupCardRoot/FavoriteGroupCardRoot';
import styles from './FavoriteGroupAddNewCard.module.scss';
import { FavoriteGroupAddNewCardProps } from './FavoriteGroupAddNewCardProps';

export const FavoriteGroupAddNewCard: FunctionComponent<FavoriteGroupAddNewCardProps> = (props) => {
  return (
    <FavoriteGroupCardRoot className={styles.root} variant={CardVariant.TRANSPARENT} onClick={props.onClick}>
      <IconButton size='large' as={ButtonKind.BUTTON} variant={IconButtonVariant.TRANSPARENT}>
        <Icon name='plus' className={styles.icon} />
      </IconButton>
    </FavoriteGroupCardRoot>
  );
};
