import { FunctionComponent } from 'react';
import { useAppSelector } from 'infrastructure/redux';
import { LinkingArtifactFolderList } from '../LinkingArtifactFolderList/LinkingArtifactFolderList';
import { LinkingArtifactRootFolderDataView } from '../LinkingArtifactRootFolderDataView/LinkingArtifactRootFolderDataView';

export const LinkingArtifactFoldersTab: FunctionComponent = () => {
  const selectedFolderId = useAppSelector((state) => state.linkingArtifacts.selectedFolderId);

  return (
    <>
      {selectedFolderId ? (
        <LinkingArtifactFolderList folderId={selectedFolderId} />
      ) : (
        <LinkingArtifactRootFolderDataView />
      )}
    </>
  );
};
