import { NotDefinedParamException } from 'infrastructure/exceptions';
import { FavoriteArtifactEntity } from '../favoriteEntity';
import { useUpdateFavoritesMutation } from '../favoritesApi';
import { useInvalidateFavoriteRelatedEntities } from './useInvalidateFavoriteRelatedEntities';
import { useRemoveArtifactFromFavoriteGroupToast } from './useRemoveArtifactFromFavoriteGroupToast';

export const useRemoveArtifactFromFavoriteGroup = (favoriteArtifact?: FavoriteArtifactEntity, groupId?: string) => {
  const { invalidateEntities } = useInvalidateFavoriteRelatedEntities(favoriteArtifact);
  const { showToast } = useRemoveArtifactFromFavoriteGroupToast(favoriteArtifact);
  const [trigger] = useUpdateFavoritesMutation();

  const handleRemove = async () => {
    if (!favoriteArtifact) {
      throw new NotDefinedParamException('favorite artifact');
    }

    if (!groupId) {
      throw new NotDefinedParamException('group id');
    }

    try {
      await trigger({
        artifactType: favoriteArtifact.type,
        artifactID: favoriteArtifact.entityId,
        groupIDsToRemoveFrom: [groupId],
      }).unwrap();
      invalidateEntities();
      showToast('Success');
    } catch (e) {
      showToast('Failed');
    }
  };

  return { handleRemove };
};
