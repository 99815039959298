export class NameFormatter {
  public static getFullNameAbbreviation(name: string | undefined, surName: string | undefined): string {
    if (!name || !surName) {
      return name || surName || '';
    }

    const nameFirstLetter = name.charAt(0).toUpperCase();
    const surNameFirstLetterCapitalized = surName.charAt(0).toUpperCase() + surName.slice(1);

    const abbreviation = `${nameFirstLetter}. ${surNameFirstLetterCapitalized}`;

    return abbreviation;
  }
}
