import { FunctionComponent } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CreateArtifactTypes } from 'features/createArtifact';
import { FileBox, FileUploader, uploadMaxFileSizeMB, uploadSupportedMimeImageTypes } from 'features/file';
import { FoldersFormController, MaterialsKind } from 'features/folders';
import { LayoutEditFormFields, LayoutStatus } from 'features/layouts';
import { useEnumOptions } from 'features/select';
import { TagsFormController } from 'features/tags';
import { SelectFormController } from 'infrastructure/form';
import { FormCol, FormRow } from 'ui-library/components/Form';
import { TextField } from 'ui-library/components/TextField';
import { nameof } from 'utils';
import styles from './LayoutEditFormInfoTab.module.scss';
import { LayoutEditFormInfoTabProps } from './LayoutEditFormInfoTabProps';

const fileBoxField: keyof LayoutEditFormFields = 'fileBox';
const fileBoxFileField = `${fileBoxField}.${nameof<FileBox>('file')}` as const;
const statusField: keyof LayoutEditFormFields = 'status';
const descriptionRuField: keyof LayoutEditFormFields = 'descriptionRu';
const descriptionEnField: keyof LayoutEditFormFields = 'descriptionEn';
const materialsKindsField: keyof LayoutEditFormFields = 'materialsKinds';
const tagsField: keyof LayoutEditFormFields = 'tags';
const foldersField: keyof LayoutEditFormFields = 'folders';

export const LayoutEditFormInfoTab: FunctionComponent<LayoutEditFormInfoTabProps> = (props) => {
  const { t } = useTranslation();
  const { isCreate } = props;
  const { options: folderStatusOptions } = useEnumOptions('LayoutStatus', LayoutStatus);
  const { options: materialKindsOptions } = useEnumOptions('MaterialsKind', MaterialsKind);

  const {
    control,
    register,
    getValues,
    formState: { errors },
    setError,
    clearErrors,
  } = useFormContext<LayoutEditFormFields>();

  return (
    <div className={styles.root}>
      <FormRow>
        <FormCol>
          <Controller
            control={control}
            name={fileBoxFileField}
            render={({ field: { value, onChange } }) => (
              <FileUploader
                previewUrl={getValues().fileBox?.previewUrl}
                error={errors[fileBoxField]?.file?.message}
                defaultSelectedFile={value as File}
                onChange={(file) => {
                  clearErrors(fileBoxFileField);
                  onChange(file);
                }}
                onError={() => {
                  setError(fileBoxFileField, {
                    message: t('validation.file.type'),
                  });
                }}
                maxFileSizeMB={uploadMaxFileSizeMB}
                supportedMimeTypes={uploadSupportedMimeImageTypes}
              />
            )}
          />
        </FormCol>
      </FormRow>
      {!isCreate && getValues().archiveName !== undefined && (
        <FormRow>
          <FormCol>
            <TextField
              readOnly
              label={t('layoutEditForm.archiveName.label')}
              InputProps={{
                defaultValue: getValues().archiveName,
                placeholder: t('layoutEditForm.archiveName.placeholder'),
              }}
            />
          </FormCol>
        </FormRow>
      )}
      <FormRow>
        <FormCol>
          <TextField
            error={errors[descriptionRuField]?.message}
            label={t('layoutEditForm.description.labelRu')}
            InputProps={{
              placeholder: t('layoutEditForm.description.placeholder'),
              ...register(descriptionRuField),
            }}
          />
        </FormCol>
      </FormRow>
      <FormRow>
        <FormCol>
          <TextField
            error={errors[descriptionEnField]?.message}
            label={t('layoutEditForm.description.labelEn')}
            InputProps={{
              placeholder: t('layoutEditForm.description.placeholder'),
              ...register(descriptionEnField),
            }}
          />
        </FormCol>
      </FormRow>
      <FormRow>
        <FormCol>
          <SelectFormController
            name={statusField}
            label={t('layoutEditForm.status.label')}
            placeholder={t('layoutEditForm.status.placeholder')}
            options={folderStatusOptions}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
            required
          />
        </FormCol>
        <FormCol>
          <SelectFormController
            name={materialsKindsField}
            label={t('layoutEditForm.materialsKinds.label')}
            placeholder={t('layoutEditForm.materialsKinds.placeholder')}
            options={materialKindsOptions}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
            SelectProps={{
              multiple: true,
            }}
          />
        </FormCol>
      </FormRow>
      <FormRow>
        <FormCol>
          <TagsFormController name={tagsField} />
        </FormCol>
      </FormRow>
      <FormRow>
        <FormCol>
          <FoldersFormController name={foldersField} type={CreateArtifactTypes.LAYOUTS} />
        </FormCol>
      </FormRow>
    </div>
  );
};
