import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { FilePreviewLabel } from 'features/file';
import { Image } from 'ui-library/v1/components/Image';
import Placeholder from 'ui-library/v1/components/Image/assets/PlaceholderFill.svg';
import { ImageDetailImageButtonGroup } from '../ImageDetailImageButtonGroup/ImageDetailImageButtonGroup';
import styles from './ImageDetailImagePanel.module.scss';
import { ImageDetailImagePanelProps } from './ImageDetailImagePanelProps';

const FALLBACK_IMAGE = Placeholder;
export const ImageDetailImagePanel: FunctionComponent<ImageDetailImagePanelProps> = (props) => {
  const { t } = useTranslation();
  const { image } = props;

  const preview = image?.file?.previews?.find((x) => x.label === FilePreviewLabel.IMAGE_PREVIEW);

  return (
    <div className={styles.root}>
      <ImageDetailImageButtonGroup className={styles.buttonGroup} image={image} />

      <Image className={styles.image} src={preview?.url} alt={t('layouts.imageAlt')} fallbackSrc={FALLBACK_IMAGE} />
    </div>
  );
};
