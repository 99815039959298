import { useState } from 'react';
import { TagResponseDto } from '../tagEntity';

export type UseTagsManageProps = {
  initialTags: Array<TagResponseDto>;
  disableExtend?: boolean;
};

export type UseTagsManageReturn = {
  tags: Array<TagResponseDto>;
  extendedTagIds: Array<string>;
  removedTagIds: Array<string>;
  disableExtend?: boolean;
  isExtended: (tag: TagResponseDto) => boolean;
  isRemoved: (tag: TagResponseDto) => boolean;
  addTags: (tags: Array<TagResponseDto>) => void;
  editTag: (tag: TagResponseDto) => void;
  extendTag: (tag: TagResponseDto) => void;
  undoExtendTag: (tag: TagResponseDto) => void;
  removeTag: (tag: TagResponseDto) => void;
  undoRemoveTag: (tag: TagResponseDto) => void;
  deleteTag: (tagId: string) => void;
};

export const useTagsManage = (props: UseTagsManageProps): UseTagsManageReturn => {
  const { initialTags, disableExtend } = props;
  const [tags, setTags] = useState(initialTags);
  const [extendedTagIds, setExtendedTagIds] = useState<Array<string>>([]);
  const [removedTagIds, setRemovedTagIds] = useState<Array<string>>([]);

  const addTags = (tags: Array<TagResponseDto>) => {
    setTags((prevState) => prevState.concat(tags));
  };

  const editTag = (tag: TagResponseDto) => {
    setTags((prevState) =>
      prevState.map((currentTag) => {
        if (currentTag.id === tag.id) {
          return tag;
        } else {
          return currentTag;
        }
      }),
    );
  };

  const extendTag = (tag: TagResponseDto) => {
    setExtendedTagIds((prevState) => prevState.concat(tag.id));
  };

  const undoExtendTag = (tag: TagResponseDto) => {
    setExtendedTagIds((prevState) => prevState.filter((tagId) => tagId !== tag.id));
  };

  const removeTag = (tag: TagResponseDto) => {
    setRemovedTagIds((prevState) => prevState.concat(tag.id));
  };

  const undoRemoveTag = (tag: TagResponseDto) => {
    setRemovedTagIds((prevState) => prevState.filter((currentTag) => currentTag !== tag.id));
  };

  const deleteTag = (tagId: string) => {
    setTags((prevState) => prevState.filter((currentTag) => currentTag.id !== tagId));
  };

  const isExtended = (tag: TagResponseDto) => extendedTagIds.includes(tag.id);

  const isRemoved = (tag: TagResponseDto) => removedTagIds.includes(tag.id);

  return {
    tags,
    extendedTagIds,
    removedTagIds,
    disableExtend,
    isExtended,
    isRemoved,
    addTags,
    editTag,
    extendTag,
    undoExtendTag,
    removeTag,
    undoRemoveTag,
    deleteTag,
  };
};
