import { createSlice } from '@reduxjs/toolkit';
import { ListState, getListPaginationReducers, getListSortReducers } from 'features/list';
import { getDefaultPaginationState } from 'features/pagination';
import { SortDirection } from 'features/sorting';
import { FileDownloadResponseDto } from '../fileEntity';

type EntityType = FileDownloadResponseDto;
export type FileDownloadTaskHistoryListState = ListState<EntityType>;

const initialState: FileDownloadTaskHistoryListState = {
  paginationData: getDefaultPaginationState(),
  sortingInfo: {
    sortDirection: SortDirection.DESC,
    sortField: 'createTime',
  },
};

const fileDownloadTaskHistoryList = createSlice({
  name: 'fileDownloadTaskHistoryList',
  initialState,
  reducers: {
    ...getListSortReducers<FileDownloadTaskHistoryListState, EntityType>(),
    ...getListPaginationReducers<FileDownloadTaskHistoryListState>(),
  },
});

export const fileDownloadTaskHistoryListActions = fileDownloadTaskHistoryList.actions;
export const fileDownloadTaskHistoryListReducer = fileDownloadTaskHistoryList.reducer;
