import { nanoid } from 'nanoid';
import { forwardRef, useRef } from 'react';
import { ErrorMessage } from 'infrastructure/form/components';
import { FormControl } from '../FormControl';
import { FormHelperText } from '../FormHelperText';
import { Label } from '../Label';
import { Textarea } from '../Textarea';
import { TextareaFieldProps } from './TextareaFieldProps';

export const TextareaField = forwardRef<HTMLTextAreaElement, TextareaFieldProps>((props, ref) => {
  const { id, error, disabled, readOnly, label, required, FormHelperTextProps, TextareaProps, LabelProps } = props;
  const inputId = useRef(id ?? nanoid()).current;
  const helperTextId = useRef(nanoid()).current;

  return (
    <FormControl>
      <Label disabled={disabled || readOnly} htmlFor={inputId} label={label} required={required} {...LabelProps} />
      <Textarea
        disabled={disabled}
        readOnly={readOnly}
        required={required}
        id={inputId}
        error={!!error}
        ref={ref}
        aria-describedby={helperTextId}
        aria-invalid={error ? 'true' : 'false'}
        {...TextareaProps}
      />
      <FormHelperText id={helperTextId} error={!!error} {...FormHelperTextProps}>
        {error && <ErrorMessage error={error} />}
      </FormHelperText>
    </FormControl>
  );
});
