import { skipToken } from '@reduxjs/toolkit/dist/query';
import { FunctionComponent } from 'react';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import { ImageCardSkeleton, ImageScaleCard, useFetchLayoutImagesQuery } from 'features/images';
import { LayoutDetailRouteSearchQueryParams } from 'features/layouts';
import { getImageDetailRoute } from 'infrastructure/routes/paths';
import { Link, LinkKind } from 'ui-library/components/Link';
import { EmptySection } from 'ui-library/components/ZeroScreen';
import { nameof } from 'utils';
import styles from './LayoutViewImages.module.scss';
import { LayoutViewImagesProps } from './LayoutViewImagesProps';

const IMAGES_COUNT = Number.MAX_SAFE_INTEGER;

export const LayoutViewImages: FunctionComponent<LayoutViewImagesProps> = (props) => {
  const { layout } = props;
  const [searchParams] = useSearchParams();
  const redirectUrl = searchParams.get(nameof<LayoutDetailRouteSearchQueryParams>('redirectUrl'));
  const params: LayoutDetailRouteSearchQueryParams = { redirectUrl: redirectUrl as string };
  const { data, isFetching } = useFetchLayoutImagesQuery(
    layout ? { layoutID: layout?.id, pageSize: IMAGES_COUNT, currentPage: 1 } : skipToken,
  );

  if (data?.totalCount === 0) {
    return <EmptySection iconName='imagesIllustration' />;
  }

  return (
    <div className={styles.root}>
      {isFetching ? (
        <ImageCardSkeleton />
      ) : (
        data?.content.map((x) => (
          <Link key={x.id} as={LinkKind.INNER} to={getImageDetailRoute(x.id) + `?${createSearchParams(params)}`}>
            <ImageScaleCard className={styles.image} image={x} />
          </Link>
        ))
      )}
    </div>
  );
};
