import { ChangeEvent, FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { movingArtifactsActions } from 'features/movingArtifacts';
import { useDebounce } from 'features/search';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux';
import { ButtonKind } from 'ui-library/components/Button';
import { Icon } from 'ui-library/components/Icon';
import { IconButton, IconButtonVariant } from 'ui-library/components/IconButton';
import { Input, InputVariant } from 'ui-library/components/Input';
import { MovingArtifactRootFolderDefaultList } from '../MovingArtifactRootFolderDefaultList/MovingArtifactRootFolderDefaultList';
import { MovingArtifactRootFolderSearchList } from '../MovingArtifactRootFolderSearchList/MovingArtifactRootFolderSearchList';
import styles from './MovingArtifactRootFolderDataView.module.scss';
import { MovingArtifactRootFolderDataViewProps } from './MovingArtifactRootFolderDataViewProps';

export const MovingArtifactRootFolderDataView: FunctionComponent<MovingArtifactRootFolderDataViewProps> = (props) => {
  const { partition } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const query = useAppSelector((state) => state.movingArtifacts.query);
  const debouncedQuery = useDebounce(query);
  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(movingArtifactsActions.setQuery(event.target.value));
  };

  const handleClear = () => {
    dispatch(movingArtifactsActions.setQuery(''));
  };
  return (
    <div className={styles.root}>
      <div className={styles.inputWrap}>
        <Input
          value={query}
          onChange={handleSearch}
          LayoutProps={{
            variant: InputVariant.STANDARD,
            endAdornment: query && (
              <IconButton as={ButtonKind.BUTTON} variant={IconButtonVariant.TRANSPARENT} onClick={handleClear}>
                <Icon name='cross' />
              </IconButton>
            ),
          }}
          placeholder={t('folders.searchRootFolder')}
        />
      </div>
      {debouncedQuery ? (
        <MovingArtifactRootFolderSearchList query={debouncedQuery} partition={partition} />
      ) : (
        <MovingArtifactRootFolderDefaultList partition={partition} />
      )}
    </div>
  );
};
