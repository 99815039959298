import { skipToken } from '@reduxjs/toolkit/dist/query';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { foldersApi, useFetchFolderDetailQuery } from 'features/folders';
import { imageGalleryActions, imagesApi } from 'features/images';
import { layoutsApi } from 'features/layouts';
import { linkingArtifactActions } from 'features/linking';
import { searchApi } from 'features/search';
import { NotDefinedParamException } from 'infrastructure/exceptions';
import { useLanguageRelevantData } from 'infrastructure/i18n';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux';
import { getFolderDetailRoute } from 'infrastructure/routes/paths';
import { Link, LinkKind, LinkVariant } from 'ui-library/v1/components/Link';
import styles from './useLinkingArtifact.module.scss';

export const useLinkingArtifact = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { matchProp } = useLanguageRelevantData();
  const { selectedFolderId, linkingArtifacts } = useAppSelector((state) => state.linkingArtifacts);
  const { data: selectedFolderData } = useFetchFolderDetailQuery(selectedFolderId ?? skipToken);

  const buildSuccessToast = (content: JSX.Element) => {
    toast.success(<div className={styles.notification}>{content}</div>);
  };

  const buildErrorToast = (content: JSX.Element) => {
    toast.error(<div className={styles.notification}>{content}</div>);
  };

  const getToastLink = () => {
    if (!selectedFolderData) {
      throw new NotDefinedParamException('Selected folder is not defined');
    }

    return (
      <Link
        as={LinkKind.INNER}
        variant={LinkVariant.STYLED}
        className={styles.link}
        to={getFolderDetailRoute(selectedFolderData.id, selectedFolderData.partition)}
      >
        «{matchProp(selectedFolderData.captionRu, selectedFolderData.captionEn)}»
      </Link>
    );
  };

  const handleLinkingLayoutSuccessful = () => {
    dispatch(searchApi.util.invalidateTags(['SearchResult']));
    dispatch(
      layoutsApi.util.invalidateTags([
        {
          type: 'FolderLayouts',
          id: selectedFolderId,
        },
      ]),
    );
    dispatch(
      foldersApi.util.invalidateTags([
        {
          type: 'Folder',
          id: selectedFolderId,
        },
        'FolderChildren',
        'RootFolders',
      ]),
    );

    for (const artifact of linkingArtifacts) {
      dispatch(
        layoutsApi.util.invalidateTags([
          {
            type: 'Layout',
            id: artifact.entityId,
          },
        ]),
      );
      dispatch(
        foldersApi.util.invalidateTags([
          {
            type: 'LayoutFolders',
            id: artifact.entityId,
          },
        ]),
      );
    }

    buildSuccessToast(
      <>
        {t(
          selectedFolderData?.isRoot
            ? 'linkingArtifacts.success.linkingLayoutToRoot'
            : 'linkingArtifacts.success.linkingLayoutToFolder',
          {
            count: linkingArtifacts.length,
          },
        )}
        &nbsp;
        {getToastLink()}
      </>,
    );

    dispatch(linkingArtifactActions.dispose());
  };

  const handleLinkingImageSuccessful = () => {
    dispatch(searchApi.util.invalidateTags(['SearchResult']));
    dispatch(
      imagesApi.util.invalidateTags([
        {
          type: 'ImageGallery',
          id: selectedFolderId,
        },
        {
          type: 'FolderImages',
          id: selectedFolderId,
        },
      ]),
    );
    dispatch(
      foldersApi.util.invalidateTags([
        {
          type: 'Folder',
          id: selectedFolderId,
        },
        'FolderChildren',
        'RootFolders',
      ]),
    );

    for (const artifact of linkingArtifacts) {
      dispatch(
        foldersApi.util.invalidateTags([
          {
            type: 'ImageFolders',
            id: artifact.entityId,
          },
        ]),
      );
    }
    dispatch(imagesApi.util.invalidateTags(['ImageGallery']));

    buildSuccessToast(
      <>
        {t(
          selectedFolderData?.isRoot
            ? 'linkingArtifacts.success.linkingImageToRoot'
            : 'linkingArtifacts.success.linkingImageToFolder',
          {
            count: linkingArtifacts.length,
          },
        )}
        &nbsp;
        {getToastLink()}
      </>,
    );

    dispatch(imageGalleryActions.clearSelection());
    dispatch(linkingArtifactActions.dispose());
  };

  const handleLinkingLayoutError = () => {
    buildErrorToast(<>{t('linkingArtifacts.error.linkedErrorLayout', { count: linkingArtifacts.length })}</>);
  };

  const handleLinkingImageError = () => {
    buildErrorToast(<>{t('linkingArtifacts.error.linkedErrorImage', { count: linkingArtifacts.length })}</>);
  };

  return {
    handleLinkingLayoutSuccessful,
    handleLinkingImageSuccessful,
    handleLinkingLayoutError,
    handleLinkingImageError,
  };
};
