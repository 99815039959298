import { FunctionComponent } from 'react';
import { useImageActions } from 'features/images/hooks/useImageActions';
import { ImageCardBase } from '../ImageCardBase/ImageCardBase';
import { ImageCardBaseExternalProps } from '../ImageCardBase/ImageCardBaseProps';

export const ImageCard: FunctionComponent<ImageCardBaseExternalProps> = (props) => {
  const { image, folder, checked, isShowOrderMode } = props;
  const { actions } = useImageActions({ image, parentId: folder.id, folder });

  return (
    <ImageCardBase
      image={image}
      actions={actions}
      folder={folder}
      checked={checked}
      isShowOrderMode={isShowOrderMode}
    />
  );
};
