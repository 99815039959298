import { skipToken } from '@reduxjs/toolkit/dist/query';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { FilePreviewLabel } from 'features/file';
import { useFetchFolderDetailQuery } from 'features/folders';
import { useFetchImageDetailQuery } from 'features/images';
import { useFetchLayoutDetailQuery } from 'features/layouts';
import { useLanguageRelevantData } from 'infrastructure/i18n';
import { useAppSelector } from 'infrastructure/redux';
import { Icon } from 'ui-library/components/Icon';
import { Image } from 'ui-library/components/Image';
import { Modal } from 'ui-library/components/Modal';
import styles from './AddToFavoritesHeader.module.scss';

export const AddToFavoritesHeader: FunctionComponent = () => {
  const { t } = useTranslation();
  const { matchProp } = useLanguageRelevantData();
  const favoriteArtifact = useAppSelector((state) => state.addToFavorites.favoriteArtifact);
  const entityId = favoriteArtifact?.entityId;
  const entityType = favoriteArtifact?.type;

  const { data: layoutData } = useFetchLayoutDetailQuery(entityType === 'layout' && entityId ? entityId : skipToken);
  const { data: folderData } = useFetchFolderDetailQuery(entityType === 'folder' && entityId ? entityId : skipToken);
  const { data: imageData } = useFetchImageDetailQuery(entityType === 'image' && entityId ? entityId : skipToken);

  const getTitle = () => {
    if (layoutData) {
      return t('layouts.properties.number', { value: layoutData.archiveName });
    } else if (folderData) {
      return matchProp(folderData.captionRu, folderData.captionEn);
    } else if (imageData) {
      return matchProp(imageData.captionRu, imageData.captionEn);
    }

    return '';
  };

  const getIcon = () => {
    if (layoutData) {
      const preview = layoutData.file?.previews?.find((x) => x.label === FilePreviewLabel.LAYOUT_ICON);

      return <Image className={styles.layoutIcon} src={preview?.url} loading='eager' />;
    } else if (folderData) {
      const preview = folderData.file?.previews?.find((x) => x.label === FilePreviewLabel.FOLDER_ICON);

      return preview ? (
        <Image className={styles.folderIcon} src={preview?.url} loading='eager' />
      ) : (
        <Icon className={styles.folderIcon} name='folderAlt' />
      );
    } else if (imageData) {
      const preview = imageData.file?.previews?.find((x) => x.label === FilePreviewLabel.IMAGE_ICON);

      return <Image className={styles.imageIcon} src={preview?.url} loading='eager' />;
    }

    return undefined;
  };

  return (
    <Modal.Title className={styles.root}>
      <>
        <span className={styles.icon}>{getIcon()}</span>
        <span className={styles.title}>{getTitle()}</span>
      </>
    </Modal.Title>
  );
};
