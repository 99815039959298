import { FunctionComponent } from 'react';
import { ListLayout, ListLayoutButtonGroup } from 'features/list';
import { searchListActions, SearchScreenResultTab, useSearchQueryParams, useSearchResult } from 'features/search';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux';
import { Tab } from 'ui-library/components/Tab';
import { SearchScreenEmptyQuery } from '../../SearchScreenEmptyQuery/SearchScreenEmptyQuery';
import { SearchScreenResultPanels } from '../../SearchScreenResultPanels/SearchScreenResultPanels';
import { SearchScreenSelectType } from '../../SearchScreenSelectType/SearchScreenSelectType';
import { SearchFilterAccordion } from '../SearchFilterAccordion/SearchFilterAccordion';
import styles from './SearchScreenResult.module.scss';
import { SearchScreenResultProps } from './SearchScreenResultProps';

export const SearchScreenResult: FunctionComponent<SearchScreenResultProps> = (props) => {
  const { query } = props;
  const { layoutType } = useAppSelector((state) => state.searchList);
  const { tab: selectedTab, updateParams } = useSearchQueryParams();
  const dispatch = useAppDispatch();

  const { result, isFilledParams } = useSearchResult(query);

  const handleChangeLayout = (layout: ListLayout) => {
    dispatch(searchListActions.changeLayoutType(layout));
  };

  const handleChangeTab = (tab: number) => {
    updateParams({ tab });
  };

  return (
    <div className={styles.root}>
      <Tab.Group selectedIndex={selectedTab} className={styles.tabGroup} as='div'>
        {({ selectedIndex }) => (
          <>
            <div className={styles.tabs}>
              <div className={styles.buttonGroup}>
                {selectedIndex !== SearchScreenResultTab.LAYOUTS && (
                  <ListLayoutButtonGroup active={layoutType} onLayoutChanged={handleChangeLayout} />
                )}
              </div>
              <SearchScreenSelectType selectedIndex={selectedIndex} onSelectIndex={handleChangeTab} result={result} />
            </div>
            <div className={styles.filter}>
              <SearchFilterAccordion />
            </div>
            {isFilledParams ? <SearchScreenResultPanels {...result} /> : <SearchScreenEmptyQuery />}
          </>
        )}
      </Tab.Group>
    </div>
  );
};
