import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthRequestDto, AuthRequestDtoValidationSchema } from 'features/auth';
import { useForm } from 'infrastructure/form';
import { HTTP_CODES, isFetchBaseQueryError } from 'infrastructure/network';
import { ActionButton } from 'ui-library/components/ActionButton';
import { ButtonKind } from 'ui-library/components/Button';
import { Form, FormCol, FormRow } from 'ui-library/components/Form';
import { PasswordTextField, TextField } from 'ui-library/components/TextField';
import { useLazyGetCurrentUserQuery, useSignInMutation } from '../../authApi';
import styles from './SignInForm.module.scss';

const emailField: keyof AuthRequestDto = 'email';
const passwordField: keyof AuthRequestDto = 'password';

export const SignInForm: FunctionComponent = () => {
  const { t } = useTranslation();
  const [signInMutation] = useSignInMutation();
  const [triggerGetCurrentUserQuery] = useLazyGetCurrentUserQuery();
  const {
    register,
    formState: { errors, isSubmitting },
    handleSubmit,
    setError,
    setValue,
  } = useForm<AuthRequestDto>({
    schema: AuthRequestDtoValidationSchema(),
  });

  const onSubmit = async (values: AuthRequestDto) => {
    try {
      await signInMutation(values).unwrap();
      triggerGetCurrentUserQuery();
    } catch (e) {
      if (isFetchBaseQueryError(e) && e.status === HTTP_CODES.UNAUTHORIZED) {
        setError('password', {
          message: 'validation.invalid.emailOrPassword',
        });
      }
    }
  };

  const handleFocusPassword = () => {
    if (errors.password) {
      setValue('password', '');
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormRow>
        <FormCol>
          <TextField
            error={errors[emailField]?.message}
            label={t('auth.form.email')}
            InputProps={{
              ...register(emailField),
              type: 'email',
              autoComplete: 'username',
              placeholder: t('auth.form.emailPlaceholder'),
            }}
          />
        </FormCol>
      </FormRow>
      <FormRow>
        <FormCol>
          <PasswordTextField
            error={errors[passwordField]?.message}
            label={t('auth.form.password')}
            InputProps={{
              ...register(passwordField),
              autoComplete: 'current-password',
              onFocus: handleFocusPassword,
              placeholder: t('auth.form.passwordPlaceholder'),
            }}
          />
        </FormCol>
      </FormRow>

      <ActionButton className={styles.submitButton} as={ButtonKind.BUTTON} type='submit' loading={isSubmitting}>
        {t('auth.form.submit')}
      </ActionButton>
    </Form>
  );
};
