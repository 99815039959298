import { BaseSelectLabelOption, BaseSelectOptionProps } from 'ui-library/components/BaseSelect';
import { SelectOptionContainer } from '../OptionContainer/SelectOptionContainer';

export const SelectLabelOption = <Option, OptionValue>(props: BaseSelectOptionProps<Option, OptionValue>) => {
  const { getOptionLabel, getOptionValue, option } = props;

  return (
    <SelectOptionContainer option={option} getOptionValue={getOptionValue}>
      {(stateProps) => <BaseSelectLabelOption getOptionLabel={getOptionLabel} option={option} {...stateProps} />}
    </SelectOptionContainer>
  );
};
