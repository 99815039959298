import React, { FunctionComponent } from 'react';
import { FavoriteContent, FavoriteGroupAccordion } from 'features/favorites';
import { useScrollToTop } from 'infrastructure/routes';
import styles from './FavoritesScreen.module.scss';

export const FavoritesScreen: FunctionComponent = () => {
  useScrollToTop();

  return (
    <>
      <div className={styles.accordion}>
        <FavoriteGroupAccordion />
      </div>
      <FavoriteContent />
    </>
  );
};
