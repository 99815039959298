import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ImageCenturyHalf } from 'features/images';
import { useEnumOptions } from 'features/select';
import { SelectFormController } from 'infrastructure/form/components';
import { getEnumOnlyString } from 'infrastructure/i18n';
import { FormCol, FormRow } from 'ui-library/components/Form';
import { CenturyReferenceBookSelectField } from '../CenturyReferenceBookSelectField/CenturyReferenceBookSelectField';
import styles from './CenturyAndHalfReferenceBookField.module.scss';
import { CenturyAndHalfReferenceBookProps } from './CenturyAndHalfReferenceBookProps';

export const CenturyAndHalfReferenceBookField: FC<CenturyAndHalfReferenceBookProps> = ({
  centuryName,
  centuryHalfName,
  isAbleToAddCentury,
}) => {
  const { t } = useTranslation();

  const { options: imageCenturyHalfOptions } = useEnumOptions('ImageCenturyHalf', getEnumOnlyString(ImageCenturyHalf));

  const { getValues, setValue, watch } = useFormContext();

  const onChangeHandler = () => {
    if (!getValues(centuryName)) {
      setValue(centuryHalfName, null);
    }
  };

  return (
    <FormRow className={styles.century}>
      <FormCol>
        <CenturyReferenceBookSelectField
          name={centuryName}
          isCreatable={isAbleToAddCentury}
          AutocompleteFieldProps={{
            label: t('referenceBook.form.century.label.century'),
            placeholder: t('referenceBook.form.century.placeholder'),
            hideHelperText: true,
            onChange: onChangeHandler,
          }}
        />
      </FormCol>
      <FormCol className={styles.centuryHalf}>
        <SelectFormController
          name={centuryHalfName}
          label={t('referenceBook.form.century.label.centuryHalf')}
          placeholder={t('referenceBook.form.century.placeholder')}
          options={imageCenturyHalfOptions}
          getOptionLabel={(option) => option.label}
          getOptionValue={(option) => option.value}
          hideHelperText
          SelectProps={{
            canUnselect: true,
          }}
          disabled={!watch(centuryName)}
        />
      </FormCol>
    </FormRow>
  );
};
