import cn from 'classnames';
import { FunctionComponent, SyntheticEvent, useState } from 'react';
import { Icon } from 'ui-library/components/Icon';
import { ActionList, hasAvailableListActions } from '../ActionList';
import { ButtonKind } from '../Button';
import { IconButton, IconButtonVariant } from '../IconButton';
import { Popover, PopoverContent, PopoverTrigger } from '../Popover';
import styles from './CardActionsMenu.module.scss';
import { CardActionsMenuProps } from './CardActionsMenuProps';

export const CardActionsMenu: FunctionComponent<CardActionsMenuProps> = (props) => {
  const [isContextMenuOpened, setContextMenuOpen] = useState(false);
  const { className, actions, portal, placement = 'bottom-end' } = props;

  const handleActionClick = (event: SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleContextMenu = (event: SyntheticEvent) => {
    event.preventDefault();

    setContextMenuOpen(true);
  };

  return (
    <div className={styles.wrap} onContextMenu={handleContextMenu}>
      {hasAvailableListActions(actions) && (
        <Popover
          behavior='click'
          placement={placement}
          offset={8}
          open={isContextMenuOpened}
          onOpenChange={setContextMenuOpen}
        >
          <PopoverTrigger>
            <IconButton
              className={cn(styles.button, className)}
              as={ButtonKind.BUTTON}
              variant={IconButtonVariant.SECONDARY}
              onClick={handleActionClick}
            >
              <Icon name='dots' />
            </IconButton>
          </PopoverTrigger>
          <PopoverContent portal={portal}>
            {({ close }) => <ActionList actions={actions} onClose={close} />}
          </PopoverContent>
        </Popover>
      )}
    </div>
  );
};
