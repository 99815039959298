import { ComponentsType } from 'ui-library/components/BaseSelect';
import { SelectLabelOption } from './LabelOption/SelectLabelOption';

const components: ComponentsType<unknown, unknown> = {
  Option: SelectLabelOption,
};

export const getDefaultComponents = <Option, OptionValue>(
  overrides?: Partial<ComponentsType<Option, OptionValue>>,
): ComponentsType<Option, OptionValue> =>
  ({
    ...components,
    ...overrides,
  } as ComponentsType<Option, OptionValue>);
