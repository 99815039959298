import classNames from 'classnames';
import { FunctionComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getPassedValidations } from 'features/users/utils/strongPasswordValidation';
import { Icon } from 'ui-library/components/Icon';
import styles from './PasswordRules.module.scss';
import { PasswordRulesProps } from './PasswordRulesProps';
import { rules } from './rules';

export const PasswordRules: FunctionComponent<PasswordRulesProps> = (props) => {
  const { value } = props;
  const { t } = useTranslation();

  const passedValidations = useMemo(() => getPassedValidations(value), [value]);

  return (
    <div className={styles.root}>
      <p className={styles.title}>{t('users.passwordRules.title')}</p>
      <ul className={styles.list}>
        {rules.map((rule) => (
          <li
            key={rule.validation}
            className={classNames(styles.item, {
              [styles.passed]: passedValidations.includes(rule.validation),
            })}
          >
            <>
              <Icon name='done' /> {t(rule.label as never)}
            </>
          </li>
        ))}
      </ul>
    </div>
  );
};
