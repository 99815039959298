import { skipToken } from '@reduxjs/toolkit/dist/query';
import { FunctionComponent } from 'react';
import { useSearchParams } from 'react-router-dom';
import { FolderDetailQuerySearchParams, useFetchFolderChildrenQuery } from 'features/folders';
import { ListLayout } from 'features/list';
import { FIRST_PAGE } from 'features/pagination';
import { useCurrentLanguage } from 'infrastructure/i18n';
import { nameof } from 'utils';
import { FolderCardList } from '../FolderCardList/FolderCardList';
import { FolderLoadingError } from '../FolderLoadingError/FolderLoadingError';
import { FolderTable } from '../FolderTable/FolderTable';
import styles from './FolderListDataView.module.scss';
import { FolderListDataViewProps } from './FolderListDataViewProps';

export const FolderListDataView: FunctionComponent<FolderListDataViewProps> = (props) => {
  const { folderId, sortingState, layoutType, partition } = props;
  const { sortDirection, sortField } = sortingState;
  const [searchParams] = useSearchParams();
  const currentLang = useCurrentLanguage();
  const tab = searchParams.get(nameof<FolderDetailQuerySearchParams>('tab'));
  const params: FolderDetailQuerySearchParams = { tab: tab ?? '', page: String(FIRST_PAGE) };

  const query = useFetchFolderChildrenQuery(
    folderId
      ? {
          folderId,
          sortDirection: sortDirection ? sortDirection : undefined,
          sortFields: sortField !== 'order' ? (currentLang === 'ru' ? ['captionRu'] : ['captionEn']) : undefined,
          currentPage: FIRST_PAGE,
          pageSize: Number.MAX_SAFE_INTEGER,
          partition,
        }
      : skipToken,
  );

  const folderView = (
    <>
      {layoutType === ListLayout.CARD && <FolderCardList {...query} params={params} />}
      {layoutType === ListLayout.TABLE && <FolderTable {...query} params={params} />}
    </>
  );

  return (
    <div className={styles.root}>
      <div className={styles.listWrap}>
        {query.isError ? <FolderLoadingError onReload={query.refetch} /> : folderView}
      </div>
    </div>
  );
};
