import { skipToken } from '@reduxjs/toolkit/query';
import { FunctionComponent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FilePreviewLabel } from 'features/file';
import { FolderPartition } from 'features/folders/folderEntity';
import { SearchRequestItemDto, useDebounce, useSearchQuery } from 'features/search';
import { useCurrentLanguage } from 'infrastructure/i18n';
import { AutocompleteField } from 'ui-library/components/AutocompleteField';
import { ImageAutocompleteList } from './ImageAutocompleteList/ImageAutocompleteList';
import styles from './ImagesAutocomplete.module.scss';
import { ImagesAutocompleteProps } from './ImagesAutocompleteProps';

export const ImagesAutocomplete: FunctionComponent<ImagesAutocompleteProps> = (props) => {
  const { selectedImages, onInternalChange } = props;
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState('');
  const currentLang = useCurrentLanguage();
  const debouncedSearchQuery = useDebounce(searchQuery);
  const searchImagesItemQuery: SearchRequestItemDto = {
    type: 'image',
    limit: 50,
    offset: 0,
  };

  const result = useSearchQuery(
    {
      items: [searchImagesItemQuery],
      partition: FolderPartition.MUSEUM,
      language: currentLang,
      query: debouncedSearchQuery ?? '',
    } ?? skipToken,
  );

  const filteredOptions = useMemo(() => {
    return result?.data?.items[0].image?.content.filter((image) => {
      const foundFolder = selectedImages.find((currentImage) => currentImage.id === image.id);

      return foundFolder === undefined;
    });
  }, [result, selectedImages]);

  return (
    <AutocompleteField
      value={undefined}
      onChange={onInternalChange as never}
      label={t('layoutEditForm.images.label')}
      placeholder={t('layoutEditForm.images.placeholder')}
      className={styles.root}
      getOptionLabel={(option) => (
        <ImageAutocompleteList image={option} view='search' previewLabel={FilePreviewLabel.IMAGE_ICON} />
      )}
      onChangeInput={setSearchQuery}
      options={filteredOptions ? filteredOptions : []}
      AutocompleteProps={{
        loading: result.isFetching,
        hideArrowIcon: true,
        showNoOptionsMessage: true,
      }}
    />
  );
};
