import { forwardRef, PropsWithChildren } from 'react';
import styles from './ImageGalleryGrid.module.scss';

export const ImageGalleryGrid = forwardRef<HTMLDivElement, PropsWithChildren<unknown>>((props, ref) => {
  const { children } = props;

  return (
    <div className={styles.container} ref={ref}>
      {children}
    </div>
  );
});
