import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AdditionalOption, getOptionValueDefault, MultiValue, OnChangeValue } from 'ui-library/components/BaseSelect';
import { SelectCheckboxOption } from 'ui-library/components/Select';
import { SelectField } from 'ui-library/components/SelectField';
import { RenderControlProps } from './RenderControlProps';

const toggleAll = Symbol();

export const RenderControl = <Option, OptionValue = Option, IsMulti extends boolean = false>(
  props: RenderControlProps<Option, OptionValue, IsMulti>,
) => {
  const { value, onChange, SelectProps = {}, options, getOptionValue = getOptionValueDefault, ...restProps } = props;
  const { multiple } = SelectProps;
  const { t } = useTranslation();

  const headOptions: Array<AdditionalOption> | undefined = useMemo(() => {
    if (multiple) {
      type ValueType = MultiValue<OptionValue>;

      return [
        {
          selected: (value as ValueType).length > 0 && (value as ValueType).length === options.length,
          option: toggleAll,
          getOptionLabel: () => t('form.selectAll'),
        },
      ];
    } else {
      return undefined;
    }
  }, [multiple, options.length, t, value]);

  const onInternalChange = (option: OnChangeValue<OptionValue, IsMulti>) => {
    type MultiOptionType = OnChangeValue<OptionValue | typeof toggleAll, true>;

    if (multiple && (option as MultiOptionType).includes(toggleAll)) {
      if ((value as MultiValue<OptionValue>).length === options.length) {
        onChange([] as never);
      } else {
        onChange(options.map(getOptionValue as never) as never);
      }
    } else {
      onChange(option);
    }
  };

  return (
    <SelectField
      value={value}
      onChange={onInternalChange}
      options={options}
      SelectProps={{
        ...SelectProps,
        headOptions,
        components: multiple && {
          Option: SelectCheckboxOption,
        },
      }}
      getOptionValue={getOptionValue}
      {...restProps}
    />
  );
};
