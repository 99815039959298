import { FunctionComponent } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { List } from 'ui-library/components/List';
import { FavoriteGroupEmpty } from '../FavoriteGroupEmpty/FavoriteGroupEmpty';
import { FavoriteGroupListFooter } from '../FavoriteGroupListFooter/FavoriteGroupListFooter';
import { FavoriteGroupListItem } from '../FavoriteGroupListItem/FavoriteGroupListItem';
import { FavoriteGroupLoadingError } from '../FavoriteGroupLoadingError/FavoriteGroupLoadingError';
import styles from './FavoriteGroupList.module.scss';
import { FavoriteGroupListProps } from './FavoriteGroupListProps';

export const FavoriteGroupList: FunctionComponent<FavoriteGroupListProps> = (props) => {
  const { isError, isFetching, readMore, refetch, data, onItemClick, isItemSelected } = props;

  if (isError) {
    return <FavoriteGroupLoadingError onReload={refetch} />;
  }

  return (
    <Virtuoso
      className={styles.list}
      data={data}
      overscan={200}
      endReached={readMore}
      itemContent={(_, group) => (
        <FavoriteGroupListItem group={group} onClick={() => onItemClick?.(group)} selected={isItemSelected?.(group)} />
      )}
      components={{
        List: List,
        Footer: isFetching ? FavoriteGroupListFooter : undefined,
        EmptyPlaceholder: () => (isFetching ? null : <FavoriteGroupEmpty />),
      }}
    />
  );
};
