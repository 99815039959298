import { skipToken } from '@reduxjs/toolkit/dist/query';
import { FunctionComponent, useEffect } from 'react';
import { useFetchFolderDetailQuery } from 'features/folders';
import { linkingArtifactActions } from 'features/linking/linkingSlice';
import { useTranslateEnum } from 'infrastructure/i18n';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux';
import { Modal } from 'ui-library/components/Modal';
import { Tab } from 'ui-library/components/Tab';
import { LinkingArtifactFoldersTab } from '../LinkingArtifactFoldersTab/LinkingArtifactFoldersTab';
import { LinkingArtifactImagesTab } from '../LinkingArtifactImagesTab/LinkingArtifactImagesTab';
import { LinkingArtifactLayoutsTab } from '../LinkingArtifactLayoutsTab/LinkingArtifactLayoutsTab';
import { LinkingArtifactNavigatorActions } from '../LinkingArtifactNavigatorActions/LinkingArtifactNavigatorActions';
import styles from './LinkingArtifactNavigator.module.scss';
import { LinkingArtifactNavigatorTab } from './LinkingArtifactNavigatorTab';

export const LinkingArtifactNavigator: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const selectedFolderId = useAppSelector((state) => state.linkingArtifacts.selectedFolderId);
  const selectedTab = useAppSelector((state) => state.linkingArtifacts.tab);
  const { data } = useFetchFolderDetailQuery(selectedFolderId ?? skipToken);
  const { translateValue } = useTranslateEnum('LinkingArtifactNavigatorTab', LinkingArtifactNavigatorTab);
  const shouldShowLayoutTab = data && data.layoutCount > 0;
  const shouldShowImageTab = data && data.imageCount > 0;
  const shouldShowFolderTab = data && data.childrenCount > 0;

  const handleTabChange = (selectedTab: LinkingArtifactNavigatorTab) => {
    dispatch(linkingArtifactActions.changeTab(selectedTab));
  };

  const tabView = (
    <Tab.Group as='div' selectedIndex={selectedTab} onChange={handleTabChange}>
      <Tab.List>
        <Tab>{translateValue(LinkingArtifactNavigatorTab.FOLDERS)}</Tab>
        {shouldShowLayoutTab && <Tab>{translateValue(LinkingArtifactNavigatorTab.LAYOUTS)}</Tab>}
        {shouldShowImageTab && <Tab>{translateValue(LinkingArtifactNavigatorTab.IMAGES)}</Tab>}
      </Tab.List>
      <Tab.Panels>
        <Tab.Panel>
          <LinkingArtifactFoldersTab />
        </Tab.Panel>
        {shouldShowLayoutTab && (
          <Tab.Panel>
            <LinkingArtifactLayoutsTab />
          </Tab.Panel>
        )}
        {shouldShowImageTab && (
          <Tab.Panel>
            <LinkingArtifactImagesTab />
          </Tab.Panel>
        )}
      </Tab.Panels>
    </Tab.Group>
  );

  const getContent = () => {
    if (selectedFolderId && data) {
      if (shouldShowFolderTab && (shouldShowLayoutTab || shouldShowImageTab)) {
        return tabView;
      } else if (shouldShowFolderTab) {
        return <LinkingArtifactFoldersTab />;
      } else if (shouldShowLayoutTab) {
        return <LinkingArtifactLayoutsTab />;
      } else if (shouldShowImageTab) {
        return <LinkingArtifactImagesTab />;
      }

      return <LinkingArtifactFoldersTab />;
    }

    return <LinkingArtifactFoldersTab />;
  };

  useEffect(() => {
    dispatch(linkingArtifactActions.changeTab(LinkingArtifactNavigatorTab.FOLDERS));
  }, [dispatch, selectedFolderId]);

  return (
    <>
      <Modal.Content className={styles.root}>{getContent()}</Modal.Content>
      <Modal.Actions>
        <LinkingArtifactNavigatorActions />
      </Modal.Actions>
    </>
  );
};
