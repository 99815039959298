import { FunctionComponent } from 'react';
import { useUserActions } from 'features/users/hooks';
import { TableRowActions } from 'ui-library/components/Table';
import { UserTableActionsProps } from './UserTableActionsProps';

export const UserTableActions: FunctionComponent<UserTableActionsProps> = (props) => {
  const { user, className } = props;
  const { actions } = useUserActions(user);

  return <TableRowActions actions={actions} className={className} />;
};
