import { skipToken } from '@reduxjs/toolkit/dist/query';
import { ReactNode } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useFetchFolderDetailQuery } from 'features/folders';
import { useFetchImageDetailQuery } from 'features/images';
import { useFetchLayoutDetailQuery } from 'features/layouts';
import { useLanguageRelevantData } from 'infrastructure/i18n';
import { getFavoriteGroupDetailRoute, ROUTE_PATHS } from 'infrastructure/routes/paths';
import { Link, LinkKind, LinkVariant } from 'ui-library/v1/components/Link';
import { FavoriteArtifactEntity, FavoriteGroupDto } from '../../favoriteEntity';
import styles from './useAddToFavoriteToast.module.scss';

type ToastEntityType = 'Theme' | 'Folder' | 'Image' | 'Layout';
type ToastMessageType = 'Success' | 'Failed';

export const useAddToFavoriteToast = (
  favoriteArtifact: FavoriteArtifactEntity | undefined,
  favoriteGroups: Array<FavoriteGroupDto>,
) => {
  const { t } = useTranslation();
  const { matchProp } = useLanguageRelevantData();
  const entityId = favoriteArtifact?.entityId;
  const entityType = favoriteArtifact?.type;

  const { data: layoutData } = useFetchLayoutDetailQuery(entityType === 'layout' && entityId ? entityId : skipToken);
  const { data: folderData } = useFetchFolderDetailQuery(entityType === 'folder' && entityId ? entityId : skipToken);
  const { data: imageData } = useFetchImageDetailQuery(entityType === 'image' && entityId ? entityId : skipToken);

  const buildSuccessToast = (entityName: string | undefined, entityType: ToastEntityType) => {
    let message: ReactNode | undefined;

    if (favoriteGroups.length === 0) {
      message = (
        <>
          {t(`favorites.addToFavorite.add${entityType}ToFavoriteSuccess`, {
            name: entityName,
          })}
          &nbsp;
          <Link
            as={LinkKind.INNER}
            variant={LinkVariant.STYLED}
            className={styles.link}
            to={ROUTE_PATHS.FAVORITES.ROOT}
          >
            {t('favorites.content.title')}
          </Link>
          .
        </>
      );
    } else if (favoriteGroups.length === 1) {
      const group = favoriteGroups[0];

      message = (
        <>
          {t(`favorites.addToFavorite.add${entityType}ToGroupOneSuccess`, {
            name: entityName,
          })}
          &nbsp;
          <Link
            as={LinkKind.INNER}
            variant={LinkVariant.STYLED}
            className={styles.link}
            to={getFavoriteGroupDetailRoute(group.id)}
          >
            «{matchProp(group.captionRu, group.captionEn)}»
          </Link>
        </>
      );
    } else {
      message = (
        <>
          {t(`favorites.addToFavorite.add${entityType}ToGroupManySuccess`, {
            name: entityName,
          })}
          {favoriteGroups.map((x, idx) => (
            <>
              &nbsp;
              <Link
                as={LinkKind.INNER}
                variant={LinkVariant.STYLED}
                className={styles.link}
                to={getFavoriteGroupDetailRoute(x.id)}
              >
                «{matchProp(x.captionRu, x.captionEn)}»
              </Link>
              {idx !== favoriteGroups.length - 1 && ','}
            </>
          ))}
        </>
      );
    }

    toast.success(<div className={styles.notification}>{message}</div>);
  };

  const buildFailedToast = (entityName: string | undefined, entityType: ToastEntityType) => {
    const message = t(`favorites.addToFavorite.add${entityType}ToFavoriteFailed`, {
      name: entityName,
    });

    toast.error(message);
  };

  const buildAppropriateToast = (
    type: ToastMessageType,
    entityName: string | undefined,
    entityType: ToastEntityType,
  ) => {
    if (type === 'Success') {
      buildSuccessToast(entityName, entityType);
    } else if (type === 'Failed') {
      buildFailedToast(entityName, entityType);
    }
  };

  const showFolderToast = (type: ToastMessageType) => {
    const name = matchProp(folderData?.captionRu, folderData?.captionEn);

    if (folderData?.isRoot) {
      buildAppropriateToast(type, name, 'Theme');
    } else {
      buildAppropriateToast(type, name, 'Folder');
    }
  };

  const showLayoutToast = (type: ToastMessageType) => {
    const name = t('layouts.properties.number', { value: layoutData?.archiveName });

    buildAppropriateToast(type, name, 'Layout');
  };

  const showImageToast = (type: ToastMessageType) => {
    const name = matchProp(imageData?.captionRu, imageData?.captionEn);

    buildAppropriateToast(type, name, 'Image');
  };

  const showToast = (type: ToastMessageType) => {
    if (favoriteArtifact?.type === 'folder') {
      showFolderToast(type);
    } else if (favoriteArtifact?.type === 'layout') {
      showLayoutToast(type);
    } else if (favoriteArtifact?.type === 'image') {
      showImageToast(type);
    }
  };

  return { showToast };
};
