import { AvailableLanguages } from 'infrastructure/i18n/i18n';
import { ListApiFetchArgs } from 'infrastructure/network';

export enum ReferenceBookType {
  AUTHORS = 'authors',
  KINDS_ART = 'kinds_art',
  YEARS = 'years',
  CENTURY = 'century',
  MATERIAL_TECHNIQUE = 'materials_techniques',
}

export type ReferenceBook = {
  id: string;
  captionRu: string;
  captionEn: string;
  type: ReferenceBookType;
};

export type FetchReferenceBookQueryData = ListApiFetchArgs<ReferenceBook> & {
  referenceBookType: ReferenceBookType;
};

export type SearchReferenceBookRequestData = ListApiFetchArgs<ReferenceBook> & {
  query: string;
  language: AvailableLanguages;
  type: ReferenceBookType;
};

export type GetReferenceBookListRequestData = {
  IDs: Array<string>;
};

export type ReferenceBookCreateRequestDto = {
  captionRu: ReferenceBook['captionRu'];
  captionEn: ReferenceBook['captionEn'];
  type: ReferenceBook['type'];
};

export type ReferenceBookEditRequestDto = ReferenceBookCreateRequestDto & {
  id: ReferenceBook['id'];
};

export type ReferenceBookDeleteRequestDto = {
  id: ReferenceBook['id'];
};
