import cn from 'classnames';
import { Fragment, FunctionComponent } from 'react';
import { Skeleton } from 'ui-library/v1/components/Skeleton';
import { ImageMasonryLayout } from '../ImageMasonryLayout/ImageMasonryLayout';
import styles from './ImageMasonrySkeleton.module.scss';

const amountOfItems = 2;

export const ImageMasonrySkeleton: FunctionComponent = () => {
  return (
    <ImageMasonryLayout>
      {[...Array(amountOfItems)].map((_, idx) => (
        <Fragment key={idx}>
          <Skeleton className={cn(styles.skeleton, styles.skeleton1)} />
          <Skeleton className={cn(styles.skeleton, styles.skeleton2)} />
          <Skeleton className={cn(styles.skeleton, styles.skeleton3)} />
        </Fragment>
      ))}
    </ImageMasonryLayout>
  );
};
