import { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { FilePreviewLabel } from 'features/file';
import { FolderCardListGrid } from 'features/folders';
import { FolderCard } from 'features/rootFolders';
import { getFolderDetailRoute } from 'infrastructure/routes/paths';
import { FolderCardListSkeleton } from '../FolderCardListSkeleton/FolderCardListSkeleton';
import { RootFolderCardListProps } from './RootFolderCardListProps';

export const RootFolderCardList: FunctionComponent<RootFolderCardListProps> = (props) => {
  const { data, isFetching } = props;

  return isFetching ? (
    <FolderCardListSkeleton />
  ) : (
    <FolderCardListGrid>
      {data?.content.map((x) => (
        <Link key={x.id} to={getFolderDetailRoute(x.id, x.partition)}>
          <FolderCard folder={x} previewLabel={FilePreviewLabel.FOLDER_SEARCH} />
        </Link>
      ))}
    </FolderCardListGrid>
  );
};
