import cn from 'classnames';
import { FunctionComponent, useRef } from 'react';
import { useIsSticky } from 'features/tracking';
import { Icon } from 'ui-library/components/Icon';
import { FolderMainListHeaderButtonGroup } from '../FolderMainListHeaderButtonGroup/FolderMainListHeaderButtonGroup';
import styles from './FolderMainListHeader.module.scss';
import { FolderMainListHeaderProps } from './FolderMainListHeaderProps';

export const FolderMainListHeader: FunctionComponent<FolderMainListHeaderProps> = (props) => {
  const headerRef = useRef<HTMLDivElement | null>(null);
  const isSticky = useIsSticky(headerRef);

  return (
    <div ref={(node) => (headerRef.current = node)} className={cn(styles.container, { [styles.sticky]: isSticky })}>
      <div className={styles.header}>
        <FolderMainListHeaderButtonGroup />
        {isSticky && <Icon className={styles.icon} name='logoHeader' size='none' />}
      </div>
    </div>
  );
};
