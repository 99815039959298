import { useTranslation } from 'react-i18next';
import { fileAccessRuleService, folderAccessRuleService } from 'features/role';
import { Icon } from 'ui-library/components/Icon';
import { ActionListItem } from 'ui-library/v1/components/ActionList';
import { FolderPartition, FolderResponseDto } from '../folderEntity';
import { folderApproverService } from '../services';
import { useFolderActionHandlers } from './useFolderActionHandlers';

export const useFolderDetailsActions = (folder?: FolderResponseDto, partition?: FolderPartition) => {
  const { t } = useTranslation();
  const { handleMove, handleDelete, handleDownload, handleShare, handleDownloadExcel, handleChangeOrder } =
    useFolderActionHandlers(folder);

  const actions: Array<ActionListItem> = [
    {
      label: t('actions.move'),
      onClick: handleMove,
      hasAccess: () => folderAccessRuleService.canMove(),
      startAdornment: <Icon name='toFolder' />,
    },
    {
      label: t('actions.remove'),
      onClick: handleDelete,
      hasAccess: () => folderApproverService.canRemove(folder),
      startAdornment: <Icon name='trash' />,
    },
    {
      label: t('actions.share'),
      onClick: handleShare,
      hasAccess: () => fileAccessRuleService.canShare(),
      startAdornment: <Icon name='share' />,
    },
    {
      label: t('actions.download'),
      onClick: handleDownload,
      hasAccess: () => fileAccessRuleService.canDownload(),
      startAdornment: <Icon name='downloadAlt' />,
    },
    {
      label: t('actions.changeOrder'),
      onClick: handleChangeOrder,
      hasAccess: () => fileAccessRuleService.canChange(),
      startAdornment: <Icon name='changeOrder' />,
    },
    {
      label: t('actions.downloadExcel'),
      onClick: handleDownloadExcel,
      hasAccess: () => fileAccessRuleService.canDownloadExcel(),
      startAdornment: <Icon name='doc' />,
    },
  ];

  const filterActions = actions.filter((action) => {
    if (partition === FolderPartition.ARCHIVE) {
      return action.label !== t('actions.changeOrder');
    }

    return true;
  });

  return { actions: filterActions };
};
