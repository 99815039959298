import cn from 'classnames';
import { FunctionComponent } from 'react';
import styles from './Spinner.module.scss';
import { SpinnerProps } from './SpinnerProps';

export const Spinner: FunctionComponent<SpinnerProps> = (props) => {
  const { size = 'medium', color = 'main', className } = props;

  return <div className={cn(styles.spinner, className, styles[size], styles[color])}></div>;
};
