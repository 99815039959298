import cn from 'classnames';
import { FunctionComponent, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';
import { calculateTotalPages, FIRST_PAGE } from 'features/pagination';
import { Icon } from '../Icon';
import styles from './Pagination.module.scss';
import { PaginationProps } from './PaginationProps';

/**
 * @deprecated
 */
export const Pagination: FunctionComponent<PaginationProps> = (props) => {
  const { t } = useTranslation();
  const {
    currentPage,
    pageSize,
    totalItems,
    onPageChanged: onPageChangedProp,
    scrollToStartAfterPageChanged: scrollToTopAfterPageChanged,
  } = props;

  const totalPages = totalItems ? calculateTotalPages(totalItems, pageSize) : 0;

  const PrevLabel = <Icon name='chevronLeft' />;

  const NextLabel = <Icon name='chevronRight' />;

  const onPageChanged = useCallback(
    (page: number) => {
      onPageChangedProp(page + 1);

      if (scrollToTopAfterPageChanged) {
        window.scrollTo(0, 0);
      }
    },
    [onPageChangedProp, scrollToTopAfterPageChanged],
  );

  useEffect(() => {
    if (totalPages === 0) {
      return;
    }

    if (currentPage > totalPages) {
      onPageChanged(totalPages);
    } else if (currentPage < FIRST_PAGE) {
      onPageChanged(FIRST_PAGE);
    }
  }, [totalPages, currentPage, onPageChanged]);

  if (totalPages <= 1) {
    return null;
  }

  return (
    <ReactPaginate
      className={styles.root}
      breakLabel={'...'}
      previousLabel={PrevLabel}
      nextLabel={NextLabel}
      previousAriaLabel={t('pagination.prevPage')}
      nextAriaLabel={t('pagination.nextPage')}
      onPageChange={({ selected }) => onPageChanged(selected)}
      pageClassName={cn(styles.button, styles.page)}
      activeClassName={styles.active}
      pageLinkClassName={styles.link}
      breakLinkClassName={styles.link}
      previousLinkClassName={styles.link}
      nextLinkClassName={styles.link}
      breakClassName={styles.button}
      nextClassName={cn(styles.button, styles.navigate)}
      previousClassName={cn(styles.button, styles.navigate)}
      disabledClassName={styles.disabled}
      pageRangeDisplayed={2}
      marginPagesDisplayed={2}
      pageCount={totalPages}
      forcePage={currentPage - 1}
      renderOnZeroPageCount={undefined}
    />
  );
};
