import React, { FunctionComponent } from 'react';
import { FavoriteButtonGroup } from 'features/favorites';
import { useFolderActionHandlers } from 'features/folders';
import { folderAccessRuleService } from 'features/role';
import { useLanguageRelevantData } from 'infrastructure/i18n';
import { ButtonKind } from 'ui-library/components/Button';
import { Icon } from 'ui-library/components/Icon';
import { IconButton } from 'ui-library/components/IconButton';
import styles from './FolderViewHeader.module.scss';
import { FolderViewHeaderProps } from './FolderViewHeaderProps';

export const FolderViewHeader: FunctionComponent<FolderViewHeaderProps> = (props) => {
  const { folder } = props;
  const { matchProp } = useLanguageRelevantData();
  const { handleRemoveFromFavorite, handleAddToFavorite, handleEdit } = useFolderActionHandlers(folder);

  return (
    <div className={styles.header}>
      <span className={styles.title}>{matchProp(folder?.captionRu, folder?.captionEn)}</span>
      <div className={styles.buttonGroup}>
        <FavoriteButtonGroup
          onAddToFavorites={handleAddToFavorite}
          onRemoveFromFavorites={handleRemoveFromFavorite}
          isFavorite={!!folder?.isFavourite}
        />
        {folderAccessRuleService.canEdit() && (
          <IconButton as={ButtonKind.BUTTON} onClick={handleEdit}>
            <Icon name='edit' />
          </IconButton>
        )}
      </div>
    </div>
  );
};
