import cn from 'classnames';
import { ForwardedRef, forwardRef } from 'react';
import { Icon } from 'ui-library/components/Icon';
import { BaseSelectOptionProps } from '../OptionContainer/BaseSelectOptionContainerProps';
import styles from './BaseSelectLabelOption.module.scss';

const BaseSelectLabelOptionWithoutRef = <Option, OptionValue>(
  props: BaseSelectOptionProps<Option, OptionValue>,
  ref: ForwardedRef<HTMLLIElement>,
) => {
  const { getOptionLabel, option, active, selected, ...restProps } = props;

  return (
    <li
      ref={ref}
      className={cn(styles.root, {
        [styles.active]: active,
        [styles.selected]: selected && option,
      })}
      {...restProps}
    >
      {getOptionLabel(option)}
      <Icon name={'done'} size={'normal'} className={styles.icon} />
    </li>
  );
};

export const BaseSelectLabelOption = forwardRef(BaseSelectLabelOptionWithoutRef);
