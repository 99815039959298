import { Menu as BuiltinMenu } from '@headlessui/react';
import { Fragment, FunctionComponent, ReactNode } from 'react';
import { Icon } from 'ui-library/components/Icon';
import styles from './MenuArrowButton.module.scss';
import { MenuArrowButtonProps } from './MenuArrowButtonProps';

export const MenuArrowButton: FunctionComponent<MenuArrowButtonProps> = (props) => {
  const { children, className } = props;

  return (
    <BuiltinMenu.Button as={Fragment}>
      {({ open }) => (
        <span className={styles.root}>
          <span className={className}>{children as ReactNode}</span>
          <span className={styles.arrow}>
            <Icon name={open ? 'arrowUp' : 'arrowDown'} />
          </span>
        </span>
      )}
    </BuiltinMenu.Button>
  );
};
