import { FunctionComponent } from 'react';
import { favoriteRootFolderListActions, useFetchFavoritesQuery } from 'features/favorites';
import { ListLayout } from 'features/list';
import { calculateOffset } from 'features/pagination';
import { ErrorLoadingRoot } from 'features/rootFolders';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux';
import { Pagination } from 'ui-library/components/Pagination';
import { EmptyRoot } from '../EmptyRoot/EmptyRoot';
import { RootFolderCardList } from '../RootFolderCardList/RootFolderCardList';
import { RootFolderTable } from '../RootFolderTable/RootFolderTable';
import styles from './RootFolderDataView.module.scss';
import { RootFolderDataViewProps } from './RootFolderDataViewProps';

export const RootFolderDataView: FunctionComponent<RootFolderDataViewProps> = (props) => {
  const { groupId } = props;
  const dispatch = useAppDispatch();
  const layoutType = useAppSelector((state) => state.favoriteRootFolderList.layoutType);
  const { sortDirection, sortField } = useAppSelector((state) => state.favoriteRootFolderList.sortingInfo);
  const { currentPage, pageSize } = useAppSelector((state) => state.favoriteRootFolderList.paginationData);

  const query = useFetchFavoritesQuery({
    groupID: groupId,
    items: [
      {
        type: 'topic',
        limit: pageSize,
        sortDirection,
        sortFields: [sortField],
        offset: calculateOffset(currentPage, pageSize),
      },
    ],
  });

  const listData = query.data?.items[0].topic;
  const totalCount = listData?.totalCount;
  const isEmpty = totalCount === 0;

  const layoutView = (
    <>
      {layoutType === ListLayout.CARD && <RootFolderCardList {...query} data={listData} groupId={groupId} />}
      {layoutType === ListLayout.TABLE && <RootFolderTable {...query} data={listData} groupId={groupId} />}
    </>
  );

  const getContent = () => {
    if (query.isError) {
      return <ErrorLoadingRoot className={styles.error} onReload={query.refetch} />;
    }

    return isEmpty ? <EmptyRoot className={styles.error} /> : layoutView;
  };

  return (
    <div className={styles.root}>
      <div className={styles.listWrap}>{getContent()}</div>
      <div className={styles.paginationWrap}>
        <Pagination
          totalItems={totalCount}
          pageSize={pageSize}
          onPageChanged={(page) => dispatch(favoriteRootFolderListActions.pageChanged({ page }))}
          currentPage={currentPage}
          scrollToStartAfterPageChanged
        />
      </div>
    </div>
  );
};
