import * as yup from 'yup';
import { SchemaOf } from 'yup';
import { TagEditFormFields } from 'features/tags';
import { REGULAR_EXPRESSIONS } from 'utils';

const tagsValidationMaskRu = REGULAR_EXPRESSIONS.CYRILLIC;
const tagsValidationMaskEn = REGULAR_EXPRESSIONS.LATIN;

export const TagEditDtoValidationSchema = (): SchemaOf<TagEditFormFields> => {
  return yup.object().shape({
    nameRu: yup.string().matches(tagsValidationMaskRu).trim().required(),
    nameEn: yup.string().matches(tagsValidationMaskEn).trim().required(),
  });
};
