import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TagEditDtoValidationSchema, TagEditFormFields } from 'features/tags';
import { useForm } from 'infrastructure/form';
import { ApiErrorCode, parseApiError, TagsError } from 'infrastructure/network';
import { FormCol, FormRow } from 'ui-library/components/Form';
import { Modal } from 'ui-library/components/Modal';
import { TextField } from 'ui-library/components/TextField';
import styles from './TagEditForm.module.scss';
import { TagEditFormProps } from './TagEditFormProps';

const nameFieldRu: keyof TagEditFormFields = 'nameRu';
const nameFieldEn: keyof TagEditFormFields = 'nameEn';

export const TagEditForm = (props: TagEditFormProps) => {
  const { renderActions, onSubmit, defaultValues } = props;
  const { t } = useTranslation();

  const methods = useForm<TagEditFormFields>({
    defaultValues,
    schema: TagEditDtoValidationSchema(),
  });

  const { handleSubmit, formState, register, setError } = methods;
  const { errors } = formState;

  const submit = async (values: TagEditFormFields) => {
    return onSubmit(values).catch((e) => {
      const errorValidation: TagsError = e.data;

      if ('errors' in errorValidation) {
        errorValidation?.errors?.forEach((error) => {
          if (error.code === ApiErrorCode.CONFLICT) {
            if (error.message.includes('nameRu')) {
              setError('nameRu', {
                message: 'tags.errors.databaseDuplicate',
              });
            } else if (error.message.includes('nameEn')) {
              setError('nameEn', {
                message: 'tags.errors.databaseDuplicate',
              });
            }
          }
        });
      } else {
        const error = parseApiError(e);
        setError('nameRu', {
          message: error?.message,
        });
        setError('nameEn', {
          message: error?.message,
        });
      }
    });
  };

  return (
    <FormProvider {...methods}>
      <Modal.Form stopPropagation onSubmit={handleSubmit(submit)}>
        <Modal.Content className={styles.content}>
          <div className={styles.root}>
            <FormRow>
              <FormCol>
                <TextField
                  error={errors[nameFieldRu]?.message}
                  label={t('tags.form.labelRu')}
                  required
                  InputProps={{
                    ...register(nameFieldRu),
                    placeholder: t('tags.form.placeholder'),
                  }}
                />
              </FormCol>
            </FormRow>
            <FormRow>
              <FormCol>
                <TextField
                  error={errors[nameFieldEn]?.message}
                  label={t('tags.form.labelEn')}
                  required
                  InputProps={{
                    ...register(nameFieldEn),
                    placeholder: t('tags.form.placeholder'),
                  }}
                />
              </FormCol>
            </FormRow>
          </div>
        </Modal.Content>
        <Modal.Actions className={styles.actions}>{renderActions(formState)}</Modal.Actions>
      </Modal.Form>
    </FormProvider>
  );
};
