import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FILE_FORMATS } from 'features/file';
import { ActionListItem } from 'ui-library/components/ActionList';
import { ImageResponseDto } from '../imageEntity';
import { useImageActionHandlers } from './useImageActionHandlers';

export const useImageDownloadActions = (image?: ImageResponseDto) => {
  const { isDownloadableJpeg, isDownloadableTif, handleJpegDownload, handleTifDownload } = useImageActionHandlers({
    image,
    parentId: undefined,
  });
  const { t } = useTranslation();

  const actions = useMemo(() => {
    const actions: Array<ActionListItem> = [];

    if (isDownloadableTif) {
      actions.push({
        label: t('files.downloadInFormat', {
          format: FILE_FORMATS.tif,
        }),
        onClick: handleTifDownload,
      });
    }

    if (isDownloadableJpeg) {
      actions.push({
        label: t('files.downloadInFormat', {
          format: FILE_FORMATS.jpeg,
        }),
        onClick: handleJpegDownload,
      });
    }

    return actions;
  }, [handleJpegDownload, handleTifDownload, isDownloadableJpeg, isDownloadableTif, t]);

  return { actions };
};
