import { skipToken } from '@reduxjs/toolkit/dist/query';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { folderEditActions, useFetchFolderDetailQuery } from 'features/folders';
import { ModalSubmitActions } from 'features/modal';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux';
import { Modal } from 'ui-library/components/Modal';
import { FolderEdit } from '../FolderEdit/FolderEdit';

export const FolderEditModal: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const { isOpen, selectedFolderId } = useAppSelector((state) => state.folderEdit);
  const { isOpen: isOpenView } = useAppSelector((state) => state.folderView);
  const { t } = useTranslation();
  const { data } = useFetchFolderDetailQuery(selectedFolderId ?? skipToken);

  const handleClose = () => {
    dispatch(folderEditActions.close());
    dispatch(folderEditActions.selectFolder(undefined));
  };

  return (
    <Modal
      hideBackdrop={isOpenView}
      closeIconName={isOpenView ? 'arrowLeft' : undefined}
      open={isOpen}
      animation={isOpenView ? 'appear' : 'slide'}
      onClose={handleClose}
    >
      <Modal.Title>{data?.isRoot ? t('folders.editRootFolder') : t('folders.editFolder')}</Modal.Title>
      <FolderEdit
        onClose={handleClose}
        data={data}
        selectedFolderId={selectedFolderId}
        isRoot={data?.isRoot}
        renderActions={({ isSubmitting }) => <ModalSubmitActions loading={isSubmitting} onCancel={handleClose} />}
      />
    </Modal>
  );
};
