import { skipToken } from '@reduxjs/toolkit/dist/query';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useFetchGroupQuery } from 'features/favorites';
import { useFetchFolderDetailQuery } from 'features/folders';
import { useLanguageRelevantData } from 'infrastructure/i18n';
import { FileArtifactEntity } from '../fileEntity';

export const useCreateFileDownloadTaskToast = (fileArtifact: FileArtifactEntity | undefined) => {
  const { t } = useTranslation();
  const { matchProp } = useLanguageRelevantData();
  const entityId = fileArtifact?.entityId;
  const entityType = fileArtifact?.type;

  const { data: favoriteGroupData } = useFetchGroupQuery(
    entityType === 'favourites_group' && entityId ? entityId : skipToken,
  );
  const { data: folderData } = useFetchFolderDetailQuery(entityType === 'folder' && entityId ? entityId : skipToken);

  const buildToast = (name: string | undefined) => {
    toast.error(t('files.createDownloadTask.error', { name }));
  };

  const showFolderToast = () => {
    const name = matchProp(folderData?.captionRu, folderData?.captionEn);

    buildToast(name);
  };

  const showFavoriteGroupToast = () => {
    const name = matchProp(favoriteGroupData?.captionRu, favoriteGroupData?.captionEn);

    buildToast(name);
  };

  const showToast = () => {
    if (fileArtifact?.type === 'folder') {
      showFolderToast();
    } else if (fileArtifact?.type === 'favourites_group') {
      showFavoriteGroupToast();
    }
  };

  return { showToast };
};
