import { UserRole } from 'features/users';
import { roleService } from '../roleService';

export abstract class AccessRuleService {
  protected abstract canViewRoles: Array<UserRole>;
  protected abstract canCreateRoles: Array<UserRole>;
  protected abstract canEditRoles: Array<UserRole>;
  protected abstract canDeleteRoles: Array<UserRole>;

  public canView() {
    return roleService.hasAccess(this.canViewRoles);
  }

  public canCreate() {
    return roleService.hasAccess(this.canCreateRoles);
  }

  public canEdit() {
    return roleService.hasAccess(this.canEditRoles);
  }

  public canDelete() {
    return roleService.hasAccess(this.canDeleteRoles);
  }
}
