import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { FolderLinks, FolderPartition, useFetchImageFoldersQuery } from 'features/folders';
import { ImageStatus } from 'features/images';
import { imageAccessRuleService } from 'features/role';
import { SearchScreenResultTab } from 'features/search';
import { TagsSearchLinks } from 'features/tags';
import { useLanguageRelevantData, useTranslateEnum } from 'infrastructure/i18n';
import { PropertyInfo } from 'ui-library/components/PropertyInfo';
import styles from './ImageViewInfo.module.scss';
import { ImageViewInfoProps } from './ImageViewInfoProps';

export const ImageViewInfo: FunctionComponent<ImageViewInfoProps> = (props) => {
  const { t } = useTranslation();
  const { matchProp } = useLanguageRelevantData();
  const { translateValue: translateImageStatusValue } = useTranslateEnum('ImageStatus', ImageStatus);
  const { image } = props;
  const { data: foldersData } = useFetchImageFoldersQuery(
    image ? { imageID: image.id, pageSize: Number.MAX_SAFE_INTEGER, currentPage: 1 } : skipToken,
  );

  return (
    <div className={styles.root}>
      <PropertyInfo
        title={t('images.properties.author')}
        value={matchProp(image?.author?.captionRu, image?.author?.captionEn)}
      />
      <PropertyInfo
        title={t('images.properties.date')}
        value={matchProp(image?.year?.captionRu, image?.year?.captionEn)}
      />
      <PropertyInfo
        title={t('images.properties.artForm')}
        value={matchProp(image?.artForm?.captionRu, image?.artForm?.captionEn)}
      />
      <PropertyInfo
        title={t('images.properties.materialsTechnics')}
        value={matchProp(image?.materialsTechnics?.captionRu, image?.materialsTechnics?.captionEn)}
      />
      {imageAccessRuleService.canViewOriginalInfo() && (
        <PropertyInfo title={t('images.properties.archiveName')} value={image?.archiveName} />
      )}
      <PropertyInfo
        title={t('images.properties.dimensions')}
        value={matchProp(image?.dimensionsRu, image?.dimensionsEn)}
      />
      {imageAccessRuleService.canViewUI() && (
        <PropertyInfo title={t('images.properties.status')} value={translateImageStatusValue(image?.status)} />
      )}
      {imageAccessRuleService.canViewOriginalInfo() && (
        <PropertyInfo
          title={t('images.properties.tags')}
          value={
            image?.tags?.length ? (
              <TagsSearchLinks
                tags={image?.tags}
                tab={SearchScreenResultTab.IMAGES}
                partition={FolderPartition.MUSEUM}
              />
            ) : null
          }
        />
      )}
      <PropertyInfo title={t('images.properties.themes')} value={<FolderLinks folders={foldersData?.content} />} />
    </div>
  );
};
