import { Dialog, Transition } from '@headlessui/react';
import { Fragment, FunctionComponent, PropsWithChildren, useMemo } from 'react';
import { ButtonKind } from '../../Button';
import { Icon } from '../../Icon';
import { IconButton } from '../../IconButton';
import styles from './ModalDrawer.module.scss';
import { ModalDrawerProps } from './ModalDrawerProps';

export const ModalDrawer: FunctionComponent<PropsWithChildren<ModalDrawerProps>> = (props) => {
  const { children, closeIconName = 'cross', animation: animationProp = 'slide', onClose } = props;

  const animation = useMemo(() => {
    let from;
    let to;

    if (animationProp === 'appear') {
      from = styles.animationOpacityFrom;
      to = styles.animationOpacityTo;
    } else if (animationProp === 'slide') {
      from = styles.animationSlideFrom;
      to = styles.animationSlideTo;
    }

    return {
      from,
      to,
    };
  }, [animationProp]);

  return (
    <Transition.Child
      as={Fragment}
      enter={styles.animation}
      enterFrom={animation.from}
      enterTo={animation.to}
      leave={styles.animation}
      leaveFrom={animation.to}
      leaveTo={animation.from}
    >
      <div className={styles.drawer}>
        <Transition.Child
          as={Fragment}
          enterFrom={styles.actionFrom}
          enterTo={styles.actionTo}
          leaveFrom={styles.actionTo}
          leaveTo={styles.actionFrom}
        >
          <div className={styles.action}>
            <IconButton onClick={() => onClose(true)} as={ButtonKind.BUTTON}>
              <Icon name={closeIconName} />
            </IconButton>
          </div>
        </Transition.Child>
        <Dialog.Panel className={styles.drawerPanel}>{children}</Dialog.Panel>
      </div>
    </Transition.Child>
  );
};
