import cn from 'classnames';
import { FunctionComponent } from 'react';
import styles from './NumberCellRenderer.module.scss';
import { NumberCellRendererProps } from './NumberCellRendererProps';

export const NumberCellRenderer: FunctionComponent<NumberCellRendererProps> = (props) => {
  const { value, icon, className } = props;

  return (
    <div className={styles.root}>
      {icon && <span className={styles.icon}>{icon}</span>}
      <span className={cn(styles.text, className)}>{value}</span>
    </div>
  );
};
