import { FunctionComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'features/auth';
import { NumLabel } from 'features/changeOrder';
import { FilePreviewLabel } from 'features/file';
import { FolderStatus, hasNestedInfo } from 'features/folders';
import { folderAccessRuleService } from 'features/role';
import { FolderCardRoot } from 'features/rootFolders/components/FolderCardRoot/FolderCardRoot';
import { UserRole } from 'features/users';
import styles from 'features/v1/folder/FolderCardBase/FolderCardBase.module.scss';
import { FolderCardBaseProps } from 'features/v1/folder/FolderCardBase/FolderCardBaseProps';
import { FolderCardFavoriteButton } from 'features/v1/folder/FolderCardFavoriteButton/FolderCardFavoriteButton';
import { useLanguageRelevantData, useTranslateEnum } from 'infrastructure/i18n';
import { CardVariant } from 'ui-library/components/Card';
import { Icon } from 'ui-library/components/Icon';
import { CardActionsMenu } from 'ui-library/v1/components/CardActionsMenu';
import { Image } from 'ui-library/v1/components/Image';
import { FolderCardCaption } from '../FolderCardCaption/FolderCardCaption';

export const FolderCardBase: FunctionComponent<FolderCardBaseProps> = (props) => {
  const { t } = useTranslation();
  const { matchProp } = useLanguageRelevantData();
  const auth = useAuth();
  const { folder, previewLabel = FilePreviewLabel.FOLDER_PREVIEW, actions, isShowOrderMode } = props;
  const { translateValue } = useTranslateEnum('FolderStatus', FolderStatus);

  const preview = useMemo(() => folder.file?.previews?.find((x) => x.label === previewLabel), [folder, previewLabel]);

  const hasChildren = hasNestedInfo(folder.childrenCount);
  const hasLayouts = hasNestedInfo(folder.layoutCount);
  const hasImages = hasNestedInfo(folder.imageCount);
  const showCaption = hasChildren || hasLayouts || hasImages;

  return (
    <FolderCardRoot className={styles.root} variant={CardVariant.TRANSPARENT}>
      <div className={styles.imageContainer}>
        {!isShowOrderMode && auth.user?.role !== UserRole.GUEST && (
          <CardActionsMenu className={styles.actions} actions={actions} />
        )}
        {!isShowOrderMode && <FolderCardFavoriteButton folder={folder} className={styles.favoriteIconHover} />}
        {isShowOrderMode && <NumLabel num={folder.order} position='topLeft' />}
        <Image className={styles.image} src={preview?.url} alt='' />
        {folderAccessRuleService.canViewUI() && <div className={styles.status}>{translateValue(folder.status)}</div>}
      </div>
      <span title={matchProp(folder.captionRu, folder.captionEn)} className={styles.title}>
        {matchProp(folder.captionRu, folder.captionEn)}
      </span>
      {showCaption && (
        <section className={styles.captionWrap}>
          {hasChildren && (
            <FolderCardCaption
              icon={<Icon className={styles.captionIcon} name='folderAlt' title={t('folders.title')} />}
              text={folder.childrenCount}
            />
          )}
          {hasLayouts && (
            <FolderCardCaption
              icon={<Icon className={styles.captionIcon} name='layout' title={t('layouts.title')} />}
              text={folder.layoutCount}
            />
          )}
          {hasImages && (
            <FolderCardCaption
              icon={<Icon className={styles.captionIcon} name='image' title={t('images.title')} />}
              text={folder.imageCount}
            />
          )}
        </section>
      )}
    </FolderCardRoot>
  );
};
