import React, { FunctionComponent } from 'react';
import { useLanguageRelevantData } from 'infrastructure/i18n';
import { Checkbox } from 'ui-library/components/Checkbox';
import { ListItem, ListItemKind } from 'ui-library/components/List';
import { ListItemIcon } from 'ui-library/components/List/ItemIcon/ListItemIcon';
import { ListItemText } from 'ui-library/components/List/ItemText/ListItemText';
import styles from './FavoriteGroupListItem.module.scss';
import { FavoriteGroupListItemProps } from './FavoriteGroupListItemProps';

export const FavoriteGroupListItem: FunctionComponent<FavoriteGroupListItemProps> = (props) => {
  const { matchProp } = useLanguageRelevantData();
  const { group, selected, onClick } = props;

  return (
    <ListItem className={styles.listItem} kind={ListItemKind.LI} onClick={onClick} disableGutters>
      <ListItemIcon iconPosition='start'>
        <Checkbox checked={selected} readOnly />
      </ListItemIcon>

      <ListItemText classes={{ primary: styles.primary }} primary={matchProp(group.captionRu, group.captionEn)} />
    </ListItem>
  );
};
