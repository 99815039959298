import { FloatingFocusManager, FloatingPortal, useId } from '@floating-ui/react-dom-interactions';
import { Transition } from '@headlessui/react';
import cn from 'classnames';
import React, { Fragment } from 'react';
import { mergeRefs } from 'react-merge-refs';
import { usePopoverState } from '../hooks/usePopoverState';
import styles from './PopoverContent.module.scss';
import { PopoverContentProps } from './PopoverContentProps';

export const PopoverContent = React.forwardRef<HTMLDivElement, PopoverContentProps>(function PopoverContent(
  props,
  propRef,
) {
  const { portal, children, className, ...restProps } = props;
  const state = usePopoverState();
  const { setLabelId } = state;

  const ref = React.useMemo(() => mergeRefs([state.floating, propRef]), [state.floating, propRef]);
  const Container = portal ? FloatingPortal : Fragment;

  const id = useId();

  const close = () => {
    state.setOpen(false);
  };

  // Only sets `aria-describedby` on the Popover root element
  // if this component is mounted inside it.
  React.useLayoutEffect(() => {
    setLabelId('popover-label-' + id);

    return () => setLabelId(undefined);
  }, [id, setLabelId]);

  return (
    <Container>
      <Transition show={!!state.open}>
        <FloatingFocusManager context={state.context} modal={true} order={['reference', 'content']} returnFocus={false}>
          <Transition.Child
            as={Fragment}
            enter={styles.animation}
            enterFrom={styles.animationFrom}
            enterTo={styles.animationTo}
            leave={styles.animation}
            leaveFrom={styles.animationTo}
            leaveTo={styles.animationFrom}
          >
            <div
              ref={ref}
              className={cn(styles.popover, className)}
              style={{
                position: state.strategy,
                top: state.y ?? 0,
                left: state.x ?? 0,
                ...props.style,
              }}
              aria-labelledby={state.labelId}
              {...state.getFloatingProps(restProps)}
            >
              <Transition.Child
                className={styles.wrapper}
                enter={styles.animation}
                enterFrom={styles.animationOpacityFrom}
                enterTo={styles.animationOpacityTo}
                leave={styles.animation}
                leaveFrom={styles.animationOpacityTo}
                leaveTo={styles.animationOpacityFrom}
              >
                {children({ close })}
              </Transition.Child>
            </div>
          </Transition.Child>
        </FloatingFocusManager>
      </Transition>
    </Container>
  );
});
