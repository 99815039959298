/* eslint-disable no-magic-numbers */
import { forwardRef } from 'react';
import { LayoutCard } from 'features/fileNavigator/layouts';
import styles from './LayoutScaleCard.module.scss';
import { LayoutScaleCardProps } from './LayoutScaleCardProps';

export const LayoutScaleCard = forwardRef<HTMLDivElement, LayoutScaleCardProps>((props, ref) => {
  const { layout } = props;

  return <LayoutCard className={styles.card} layout={layout} />;
});
