import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getDefaultModalState, getModalReducers, ModalState } from 'features/modal';
import { UserResponseDto } from '../userEntity';

export type UserUnblockState = ModalState & {
  user?: UserResponseDto;
};

const initialState: UserUnblockState = {
  ...getDefaultModalState(),
};

const userUnblockSlice = createSlice({
  name: 'userUnblock',
  initialState,
  reducers: {
    ...getModalReducers<UserUnblockState>((state) => state),
    setUser: (state, action: PayloadAction<UserResponseDto | undefined>) => {
      state.user = action.payload;
    },
  },
});

export const userUnblockActions = userUnblockSlice.actions;
export const userUnblockReducer = userUnblockSlice.reducer;
