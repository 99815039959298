import { FunctionComponent, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalSubmitActions } from 'features/modal';
import { TagEditFormFields, useCreateTagsMutation } from 'features/tags';
import { TagsManageContext } from 'features/tags/contexts';
import { ApiLikeErrorException } from 'infrastructure/exceptions';
import { ApiErrorCode } from 'infrastructure/network';
import { Modal } from 'ui-library/components/Modal';
import { TagEditForm } from '../TagEditForm';
import { TagsCreateProps } from './TagsCreateProps';

export const TagsCreate: FunctionComponent<TagsCreateProps> = (props) => {
  const { close, isOpen } = props;
  const [createTagsMutation] = useCreateTagsMutation();
  const { tags, addTags } = useContext(TagsManageContext);

  const { t } = useTranslation();

  const onSubmit = async (values: TagEditFormFields) => {
    const result = await createTagsMutation([
      {
        nameRu: values.nameRu,
        nameEn: values.nameEn,
      },
    ]).unwrap();

    const existsArtifactTag = tags.some((currentTag) => currentTag.id === result[0].id);

    if (existsArtifactTag) {
      throw new ApiLikeErrorException(ApiErrorCode.VALIDATION_FAILED, t('tags.errors.duplicate'));
    } else {
      addTags(result);
      close();
    }
  };

  return (
    <Modal variant='popup' open={isOpen} onClose={close}>
      <Modal.Title>{t('tags.create.title')}</Modal.Title>
      <TagEditForm
        onSubmit={onSubmit}
        renderActions={({ isSubmitting }) => (
          <ModalSubmitActions submitLabel={t('actions.add')} loading={isSubmitting} onCancel={close} />
        )}
      />
    </Modal>
  );
};
