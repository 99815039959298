import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getDefaultModalState, getModalReducers, ModalState } from 'features/modal';

export type FolderEditState = ModalState & {
  selectedFolderId?: string;
};

const initialState: FolderEditState = {
  ...getDefaultModalState(),
  selectedFolderId: undefined,
};

const folderEditSlice = createSlice({
  name: 'folderEdit',
  initialState,
  reducers: {
    ...getModalReducers<FolderEditState>((state) => state),
    selectFolder: (state, action: PayloadAction<string | undefined>) => {
      state.selectedFolderId = action.payload;
    },
  },
});

export const folderEditActions = folderEditSlice.actions;
export const folderEditReducer = folderEditSlice.reducer;
