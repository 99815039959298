import { Fragment, FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import { toggleMuseumModalActions } from 'features/toggleMuseum';
import { useLanguageRelevantData } from 'infrastructure/i18n';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux';
import { getFolderDetailRoute } from 'infrastructure/routes/paths';
import { Button, ButtonKind, ButtonVariant } from 'ui-library/components/Button';
import { FolderResponseDto } from '../../folderEntity';
import styles from './FolderLinks.module.scss';
import { FolderLinksProps } from './FolderLinksProps';

export const FolderLinks: FunctionComponent<FolderLinksProps> = (props) => {
  const { matchProp } = useLanguageRelevantData();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { folders } = props;
  const partition = useAppSelector((state) => state.toggleMuseum.partition);

  const handleOpenModal = (x: FolderResponseDto) => {
    if (partition !== x.partition) {
      dispatch(toggleMuseumModalActions.open());
      dispatch(toggleMuseumModalActions.setPartition(partition));
      dispatch(toggleMuseumModalActions.setQuery(getFolderDetailRoute(x.id, x.partition)));
    } else {
      navigate(getFolderDetailRoute(x.id, x.partition));
    }
  };

  return (
    <>
      {folders?.map((x, index, arr) => (
        <Fragment key={x.id}>
          <Button
            as={ButtonKind.BUTTON}
            variant={ButtonVariant.TRANSPARENT}
            onClick={() => handleOpenModal(x)}
            className={styles.link}
          >
            {matchProp(x.captionRu, x.captionEn)}
          </Button>
          {index < arr.length - 1 && ', '}
        </Fragment>
      ))}
    </>
  );
};
