import { HTMLAttributes } from 'react';
import { LinkProps } from '../Link';

export enum ListItemKind {
  LINK = 'LINK',
  LI = 'LI',
}

export type ListItemProps = (LinkListItemProps | LiListItemProps) & CommonProps;

export type LinkListItemProps = {
  kind: ListItemKind.LINK;
} & LinkProps;

export type LiListItemProps = {
  kind: ListItemKind.LI;
} & HTMLAttributes<HTMLLIElement>;

type CommonProps = {
  padding?: 'none' | 'small' | 'normal' | 'large';
  disableGutters?: boolean;
  divider?: boolean;
  selected?: boolean;
  classes?: {
    selected?: string;
    divider?: string;
  };
};
