import cn from 'classnames';
import _ from 'lodash';
import { FunctionComponent, useRef } from 'react';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { ChangeOrderHeaderActions } from 'features/changeOrder';
import {
  FolderDetailQuerySearchParams,
  FolderListHeaderActions,
  FolderMuseumDetailViewType,
  folderMuseumListActions,
  FolderResponseDto,
} from 'features/folders';
import { ImageGalleryHeaderActions } from 'features/images';
import { ListLayout } from 'features/list';
import { FIRST_PAGE } from 'features/pagination';
import { useIsSticky } from 'features/tracking';
import { useLanguageRelevantData, useTranslateEnum } from 'infrastructure/i18n';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux';
import { Icon } from 'ui-library/components/Icon';
import { Tab } from 'ui-library/components/Tab';
import styles from './FolderMuseumDetailDataHeader.module.scss';
import { FolderMuseumDetailDataHeaderProps } from './FolderMuseumDetailDataHeaderProps';

export const FolderMuseumDetailDataHeader: FunctionComponent<FolderMuseumDetailDataHeaderProps> = (props) => {
  const dispatch = useAppDispatch();
  const { tab, folder } = props;
  const { translateValue } = useTranslateEnum('FolderMuseumDetailViewType', FolderMuseumDetailViewType);
  const { matchProp } = useLanguageRelevantData();
  const headerRef = useRef<HTMLDivElement | null>(null);
  const isSticky = useIsSticky(headerRef);
  const { sortDirection } = useAppSelector((state) => state.folderMuseumList.sortingInfo);
  const layoutType = useAppSelector((state) => state.folderMuseumList.layoutType);
  const isShowOrderMode = useAppSelector((state) => state.changeOrder.isShowOrderMode);
  const isShowSelectMode = useAppSelector((state) => state.imageGallery.isShowSelectMode);
  const location = useLocation();
  const navigate = useNavigate();

  const toggleFolderSort = () => {
    dispatch(
      folderMuseumListActions.sortChanged({
        sortField: matchProp<keyof FolderResponseDto>('captionRu', 'captionEn'),
      }),
    );
    const params: FolderDetailQuerySearchParams = {
      tab: String(tab),
      page: String(FIRST_PAGE),
    };
    navigate({
      pathname: location.pathname,
      search: `?${createSearchParams(params)}`,
    });
  };

  const handleChangeLayout = (layout: ListLayout) => {
    dispatch(folderMuseumListActions.changeLayoutType(layout));
  };

  const getFolderTitle = () => {
    return _.truncate(matchProp(folder?.captionRu, folder?.captionEn), { length: 40 });
  };

  return (
    <Tab.List ref={(node) => (headerRef.current = node)} className={cn(styles.root, { [styles.sticky]: isSticky })}>
      <Tab className={isSticky && !isShowOrderMode && !isShowSelectMode ? styles.tab : undefined}>
        {translateValue(FolderMuseumDetailViewType.IMAGES)}
      </Tab>
      <Tab className={isSticky && !isShowOrderMode && !isShowSelectMode ? styles.tab : undefined}>
        {translateValue(FolderMuseumDetailViewType.FOLDERS)}
      </Tab>
      {isSticky && !isShowOrderMode && !isShowSelectMode && (
        <Icon className={styles.icon} name='logoHeader' size='none' />
      )}
      {isSticky && !isShowOrderMode && !isShowSelectMode && <h1 className={styles.title}>{getFolderTitle()}</h1>}
      {tab === FolderMuseumDetailViewType.IMAGES && <ImageGalleryHeaderActions />}
      {tab === FolderMuseumDetailViewType.FOLDERS && (
        <FolderListHeaderActions
          onChangeLayout={handleChangeLayout}
          onToggleSort={toggleFolderSort}
          layoutType={layoutType}
          sortDirection={sortDirection}
        />
      )}
      {isShowOrderMode && <ChangeOrderHeaderActions />}
    </Tab.List>
  );
};
