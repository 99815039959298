import { FunctionComponent } from 'react';
import { FolderCardListGrid } from 'features/folders';
import { ListItemGroupLetter } from 'features/list';
import styles from './FolderCardListLayout.module.scss';
import { FolderCardListLayoutProps } from './FolderCardListLayoutProps';

export const FolderCardListLayout: FunctionComponent<FolderCardListLayoutProps> = (props) => {
  const { letter, children } = props;

  return (
    <div className={styles.wrap}>
      <ListItemGroupLetter>{letter}</ListItemGroupLetter>
      <FolderCardListGrid>{children}</FolderCardListGrid>
    </div>
  );
};
