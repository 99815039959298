import cn from 'classnames';
import React, { forwardRef } from 'react';
import styles from './Card.module.scss';
import { CardProps, CardVariant } from './CardProps';

export const Card = forwardRef<HTMLDivElement, CardProps>((props, ref) => {
  const { variant = CardVariant.MAIN, as = 'div', children, padding = 'normal', className, ...rest } = props;

  return React.createElement(
    as,
    {
      className: cn(styles.container, styles[variant], styles[padding], className),
      ref: ref,
      ...rest,
    },
    children,
  );
});
