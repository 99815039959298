import { hasNestedInfo } from 'features/folders';
import { FavoriteGroupDto } from '../favoriteEntity';

export function hasFavoriteContent(group?: FavoriteGroupDto) {
  return (
    hasNestedInfo(group?.topicCount) ||
    hasNestedInfo(group?.folderCount) ||
    hasNestedInfo(group?.layoutCount) ||
    hasNestedInfo(group?.imageCount)
  );
}
