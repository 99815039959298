import { FunctionComponent, useContext } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { ModalSubmitActions } from 'features/modal';
import { TagDeleteRequestDto, useDeleteTagMutation, TagsChip } from 'features/tags';
import { TagsManageContext } from 'features/tags/contexts';
import { Modal } from 'ui-library/components/Modal';
import styles from './TagsDelete.module.scss';
import { TagsDeleteProps } from './TagsDeleteProps';

export const TagsDelete: FunctionComponent<TagsDeleteProps> = (props) => {
  const { tag, close, isOpen } = props;
  const [deleteTagsMutation] = useDeleteTagMutation();
  const { deleteTag } = useContext(TagsManageContext);

  const { t } = useTranslation();

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();

  const submit = async () => {
    try {
      const dto: TagDeleteRequestDto = {
        id: tag.id,
      };

      await deleteTagsMutation(dto).unwrap();

      deleteTag(dto.id);
    } catch (err) {
      toast.error(t('tags.errors.delete'));
    } finally {
      close();
    }
  };

  return (
    <Modal variant='popup' open={isOpen} onClose={close}>
      <Modal.Title>{t('tags.delete.title')}</Modal.Title>
      <p className={styles.description}>{t('tags.delete.description')}</p>
      <Modal.Form stopPropagation onSubmit={handleSubmit(submit)}>
        <Modal.Content className={styles.content}>
          <div className={styles.root}>
            <TagsChip disableActions tag={tag} />
          </div>
        </Modal.Content>
        <Modal.Actions className={styles.actions}>
          <ModalSubmitActions submitLabel={t('actions.save')} loading={isSubmitting} onCancel={close} />
        </Modal.Actions>
      </Modal.Form>
    </Modal>
  );
};
