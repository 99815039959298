import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { FavoriteGroupCardList, useFetchGroupsQuery } from 'features/favorites';
import { FIRST_PAGE } from 'features/pagination';
import { SortDirection } from 'features/sorting';
import { ROUTE_PATHS } from 'infrastructure/routes';
import { Accordion } from 'ui-library/components/Accordion';
import { Button, ButtonKind, ButtonVariant } from 'ui-library/components/Button';
import styles from './FavoriteGroupAccordion.module.scss';

const MAX_VISIBLE_CARDS = 3;

export const FavoriteGroupAccordion: FunctionComponent = () => {
  const { t } = useTranslation();
  const query = useFetchGroupsQuery({
    pageSize: MAX_VISIBLE_CARDS,
    currentPage: FIRST_PAGE,
    sortDirection: SortDirection.DESC,
    sortFields: ['createTime'],
  });
  const { data } = query;
  const totalCount = data?.totalCount ?? 0;
  const isShowAllActionActive = totalCount > MAX_VISIBLE_CARDS;

  return (
    <Accordion
      defaultOpen={true}
      label={t('favorites.group.title')}
      classes={{ label: styles.label, contentOpened: styles.contentOpened }}
      actions={
        isShowAllActionActive && (
          <Button
            className={styles.action}
            as={ButtonKind.INNER_ANCHOR}
            to={ROUTE_PATHS.FAVORITES.GROUPS}
            variant={ButtonVariant.TRANSPARENT}
          >
            {t('favorites.group.showAll', { value: totalCount })}
          </Button>
        )
      }
    >
      <FavoriteGroupCardList {...query} />
    </Accordion>
  );
};
