import { FunctionComponent, SyntheticEvent } from 'react';
import { createSearchParams, Link } from 'react-router-dom';
import { changeOrderActions } from 'features/changeOrder';
import { FolderResponseDto } from 'features/folders/folderEntity';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux';
import { getFolderDetailRoute } from 'infrastructure/routes/paths';
import { FolderCard } from '../FolderCard/FolderCard';
import { FolderCardListGrid } from '../FolderCardListGrid/FolderCardListGrid';
import { FolderCardListSkeleton } from '../FolderCardListSkeleton/FolderCardListSkeleton';
import { FolderEmptyChildren } from '../FolderEmptyChildren/FolderEmptyChildren';
import { FolderCardListProps } from './FolderCardListProps';

export const FolderCardList: FunctionComponent<FolderCardListProps> = (props) => {
  const { data, isFetching, params } = props;
  const dispatch = useAppDispatch();
  const isShowOrderMode = useAppSelector((state) => state.changeOrder.isShowOrderMode);
  const isEmpty = data?.totalCount === 0;

  if (isEmpty) {
    return <FolderEmptyChildren />;
  }

  const handleClick = (folder: FolderResponseDto) => (ev: SyntheticEvent) => {
    isShowOrderMode && ev.preventDefault();
    dispatch(
      changeOrderActions.setChangeArtifact({
        folderID: folder.id,
        parentId: folder.parentID || '',
        order: folder.order,
        type: 'folder',
      }),
    );
    dispatch(changeOrderActions.open());
  };

  return (
    <FolderCardListGrid>
      {isFetching ? (
        <FolderCardListSkeleton />
      ) : (
        data?.content.map((x) => (
          <Link
            key={x.id}
            onClick={isShowOrderMode ? handleClick(x) : undefined}
            to={getFolderDetailRoute(x.id, x.partition) + `?${createSearchParams(params)}`}
          >
            <FolderCard folder={x} isShowOrderMode={isShowOrderMode} />
          </Link>
        ))
      )}
    </FolderCardListGrid>
  );
};
