import * as yup from 'yup';
import { SchemaOf } from 'yup';
import { REGULAR_EXPRESSIONS } from 'utils';
import { UserEditFormFields, UserRole } from './userEntity';
import { getStrongPasswordValidation } from './utils/strongPasswordValidation';

const maxLen = 50;
const emailValidationMask = REGULAR_EXPRESSIONS.EMAIL;

export const UserEditDtoValidationSchema = (): SchemaOf<UserEditFormFields> =>
  yup.object({
    name: yup.string().required().max(maxLen),
    surname: yup.string().optional().max(maxLen),
    email: yup.string().matches(emailValidationMask).required().max(maxLen),
    role: yup.mixed<UserRole>().oneOf(Object.values(UserRole)).required(),
    isBlocked: yup.boolean().required(),
    passwordCurrent: yup.string().when('password', {
      is: (val: string | undefined) => val && val.length > 0,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.optional(),
    }),
    password: yup.lazy((value) => {
      if (value) {
        return getStrongPasswordValidation();
      } else {
        return yup.string().optional();
      }
    }) as never,
    passwordConfirm: yup.string().oneOf([yup.ref('password')], 'validation.invalid.confirmPassword'),
  });
