import { nanoid } from 'nanoid';
import { useRef } from 'react';
import { ErrorMessage } from 'infrastructure/form';
import { FileInput } from '../FileInput/FileInput';
import { FormControl } from '../FormControl';
import { FormHelperText } from '../FormHelperText';
import { FileInputFieldProps } from './FileInputFieldProps';

export const FileInputField = (props: FileInputFieldProps) => {
  const { id, error, label, FileInputProps, FormHelperTextProps } = props;
  const { InputProps, ...restFileInputProps } = FileInputProps || {};

  const inputId = useRef(id ?? nanoid()).current;
  const helperTextId = useRef(nanoid()).current;

  return (
    <FormControl>
      <FileInput
        label={label}
        InputProps={{
          id: inputId,
          'aria-describedby': helperTextId,
          'aria-invalid': error ? 'true' : 'false',
          ...InputProps,
        }}
        {...restFileInputProps}
      />
      <FormHelperText id={helperTextId} error={!!error} {...FormHelperTextProps}>
        {error && <ErrorMessage error={error} />}
      </FormHelperText>
    </FormControl>
  );
};
