import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getDefaultModalState, getModalReducers, ModalState } from 'features/modal';
import { FileArtifactEntity } from '../fileEntity';

export type ShareFileDownloadTaskState = ModalState & {
  selectedArtifact: FileArtifactEntity | undefined;
};

const initialState: ShareFileDownloadTaskState = {
  ...getDefaultModalState(),
  selectedArtifact: undefined,
};

const shareFileDownloadTaskSlice = createSlice({
  name: 'shareFileDownloadTask',
  initialState,
  reducers: {
    ...getModalReducers<ShareFileDownloadTaskState>((state) => state),
    setFileArtifact: (state, action: PayloadAction<FileArtifactEntity | undefined>) => {
      state.selectedArtifact = action.payload;
    },
  },
});

export const shareFileDownloadTaskActions = shareFileDownloadTaskSlice.actions;
export const shareFileDownloadTaskReducer = shareFileDownloadTaskSlice.reducer;
