import { BaseSelectCheckboxOption, BaseSelectOptionProps } from 'ui-library/components/BaseSelect';
import { SelectOptionContainer } from '../OptionContainer/SelectOptionContainer';

export const SelectCheckboxOption = <Option, OptionValue>(props: BaseSelectOptionProps<Option, OptionValue>) => {
  const { getOptionLabel, getOptionValue, option, selected, active, disabled } = props;

  return (
    <SelectOptionContainer option={option} getOptionValue={getOptionValue}>
      {(stateProps) => (
        <BaseSelectCheckboxOption
          getOptionLabel={getOptionLabel}
          option={option}
          selected={selected ?? stateProps.selected}
          active={active ?? stateProps.active}
          disabled={disabled ?? stateProps.disabled}
        />
      )}
    </SelectOptionContainer>
  );
};
