import React, { FunctionComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FilePreviewLabel } from 'features/file';
import { Button, ButtonKind, ButtonVariant } from 'ui-library/components/Button';
import { Icon } from 'ui-library/components/Icon';
import { Image } from 'ui-library/components/Image';
import { ListItem, ListItemKind } from 'ui-library/components/List';
import { ListItemText } from 'ui-library/components/List/ItemText/ListItemText';
import styles from './LayoutEditList.module.scss';
import { LayoutEditListProps } from './LayoutEditListProps';

export const LayoutEditList: FunctionComponent<LayoutEditListProps> = (props) => {
  const { t } = useTranslation();
  const { layout, onRemoveItem } = props;
  const preview = useMemo(
    () => layout.file?.previews?.find((x) => x.label === FilePreviewLabel.LAYOUT_SEARCH),
    [layout],
  );

  const removeListItem = () => {
    onRemoveItem(layout);
  };

  return (
    <ListItem kind={ListItemKind.LI} disableGutters>
      <div>
        <Image className={styles.icon} src={preview?.url} alt={t('layouts.imageAlt')} />
      </div>
      <div className={styles.layoutName}>
        <ListItemText classes={{ primary: styles.primary }} primary={layout.archiveName ? layout.archiveName : ''} />
      </div>
      <Button
        className={styles.action}
        as={ButtonKind.BUTTON}
        onClick={removeListItem}
        variant={ButtonVariant.TRANSPARENT}
      >
        <Icon className={styles.cross} name='cross' />
      </Button>
    </ListItem>
  );
};
