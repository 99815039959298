import { FunctionComponent, useRef } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { imagesApi } from 'features/images';
import { ModalSubmitActions } from 'features/modal';
import { referenceBookApi, useDeleteReferenceDeleteMutation } from 'features/referenceBook/referenceBookApi';
import { ReferenceBookDeleteRequestDto, ReferenceBookType } from 'features/referenceBook/referenceBookEntity';
import { formTranslationsService } from 'features/referenceBook/services';
import { searchApi } from 'features/search';
import { useLanguageRelevantData } from 'infrastructure/i18n';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux';
import { Modal } from 'ui-library/components/Modal';
import { authorDeleteActions } from '../slices/authorDeleteSlice';
import styles from './AuthorDelete.module.scss';

export const AuthorDelete: FunctionComponent = () => {
  const [deleteAuthorsMutation] = useDeleteReferenceDeleteMutation();
  const { isOpen, author } = useAppSelector((state) => state.authorDelete);
  const dispatch = useAppDispatch();
  const { matchProp } = useLanguageRelevantData();
  const { t } = useTranslation();
  const translations = useRef(formTranslationsService.getTranslations(ReferenceBookType.AUTHORS)).current;

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();

  const handleClose = () => {
    dispatch(authorDeleteActions.close());
  };

  const submit = async () => {
    try {
      const dto: ReferenceBookDeleteRequestDto = {
        id: author?.id || '',
      };

      await deleteAuthorsMutation(dto).unwrap();
      dispatch(
        referenceBookApi.util.invalidateTags([
          {
            type: 'ReferenceBooks',
            id: ReferenceBookType.AUTHORS,
          },
        ]),
      );
      dispatch(imagesApi.util.invalidateTags(['Image', 'ImageGallery']));
      dispatch(searchApi.util.invalidateTags(['SearchResult']));
      toast.success(t(translations.editSuccessDeleteToastMessage));
      handleClose();
    } catch (e) {
      toast.error(t(translations.editFailedDeleteToastMessage));
    }
  };

  return (
    <Modal variant='popup' open={isOpen} onClose={handleClose}>
      <Modal.Title>{t(translations.deleteTitle)}</Modal.Title>
      <p className={styles.description}>{t(translations.deleteDescription)}</p>
      <Modal.Form stopPropagation onSubmit={handleSubmit(submit)}>
        <Modal.Content className={styles.content}>
          <div className={styles.root}>
            <span>{matchProp(author?.captionRu, author?.captionEn)}</span>
          </div>
        </Modal.Content>
        <Modal.Actions className={styles.actions}>
          <ModalSubmitActions submitLabel={t('actions.remove')} loading={isSubmitting} onCancel={handleClose} />
        </Modal.Actions>
      </Modal.Form>
    </Modal>
  );
};
