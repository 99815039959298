import { FunctionComponent } from 'react';
import { TableBodySkeleton } from 'ui-library/components/Table';
import { TableColumnSkeleton } from 'ui-library/components/Table/TableColumnSkeleton';
import { Skeleton } from 'ui-library/v1/components/Skeleton';

// eslint-disable-next-line no-magic-numbers
const groupsAmounts = [7, 4, 5];

const columns: Array<TableColumnSkeleton> = [
  {
    cellRenderer: () => <Skeleton width={450} height={20} />,
  },
  {
    cellRenderer: () => <Skeleton width={100} height={20} />,
  },
  {
    cellRenderer: () => <Skeleton width={100} height={20} />,
  },
];

export const AuthorsTableSkeleton: FunctionComponent = () => {
  return (
    <>
      {groupsAmounts.map((amount, index) => (
        <TableBodySkeleton key={index} columns={columns} rows={amount} />
      ))}
    </>
  );
};
