import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FolderResponseDto, FolderStatus, hasNestedInfo } from 'features/folders';
import { useLanguageRelevantData } from 'infrastructure/i18n';
import { getFolderDetailRoute } from 'infrastructure/routes/paths';
import { Icon } from 'ui-library/components/Icon';
import { EnumCellRenderer, IconCellRenderer, NumberCellRenderer, TextCellRenderer } from 'ui-library/components/Table';
import { TableColumn } from 'ui-library/components/Table/TableColumn';
import { nameof } from 'utils';
import styles from './useFolderTableColumns.module.scss';

export const useFolderTableColumns = () => {
  const { t } = useTranslation();
  const { matchProp } = useLanguageRelevantData();

  const columns = useMemo<Array<TableColumn<FolderResponseDto>>>(() => {
    const columns: Array<TableColumn<FolderResponseDto>> = [
      {
        fieldName: 'caption',
        width: '50%',
        cellRenderer: (data) => (
          <Link to={getFolderDetailRoute(data.id, data.partition)}>
            <TextCellRenderer
              value={matchProp(data.captionRu, data.captionEn)}
              icon={<Icon className={styles.icon} name='folderAlt' />}
            />
          </Link>
        ),
      },
      {
        fieldName: nameof<FolderResponseDto>('isFavourite'),
        width: '6%',
        className: (data) => (data.isFavourite ? undefined : styles.hidden),
        cellRenderer: () => (
          <IconCellRenderer>
            <Icon className={styles.favorite} name='favoriteFilled' />
          </IconCellRenderer>
        ),
      },
      {
        fieldName: nameof<FolderResponseDto>('status'),
        width: '15%',
        cellRenderer: (data) => (
          <EnumCellRenderer enumName='FolderStatus' enumObject={FolderStatus} value={data.status} />
        ),
      },
      {
        fieldName: nameof<FolderResponseDto>('childrenCount'),
        width: '12%',
        className: (data) => (hasNestedInfo(data.childrenCount) ? undefined : styles.hidden),
        cellRenderer: (data) => (
          <NumberCellRenderer
            value={data.childrenCount}
            icon={<Icon className={styles.icon} name={'folderAlt'} title={t('folders.title')} />}
          />
        ),
      },
      {
        fieldName: 'artifactCount',
        width: '12%',
        className: (data) =>
          hasNestedInfo(data.layoutCount) || hasNestedInfo(data.imageCount) ? undefined : styles.hidden,
        cellRenderer: (data) => {
          return (
            <NumberCellRenderer
              value={hasNestedInfo(data.layoutCount) ? data.layoutCount : data.imageCount}
              icon={
                hasNestedInfo(data.layoutCount) ? (
                  <Icon className={styles.icon} name={'layout'} title={t('layouts.title')} />
                ) : (
                  <Icon className={styles.icon} name={'image'} title={t('images.title')} />
                )
              }
            />
          );
        },
      },
    ];

    return columns;
  }, [matchProp, t]);

  return columns;
};
