import { createSlice } from '@reduxjs/toolkit';
import { ListPaginationState } from 'features/list';
import { getListPaginationReducers } from 'features/list/listReducers';
import { getDefaultPaginationState } from 'features/pagination';

export type SearchLayoutState = ListPaginationState;

const initialState: SearchLayoutState = {
  paginationData: getDefaultPaginationState(),
};

const searchLayoutSlice = createSlice({
  name: 'searchLayout',
  initialState,
  reducers: getListPaginationReducers<SearchLayoutState>(),
});

export const searchLayoutActions = searchLayoutSlice.actions;
export const searchLayoutReducer = searchLayoutSlice.reducer;
