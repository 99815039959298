import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { layoutCreateActions } from 'features/createArtifact';
import { FileType, useUploadFile } from 'features/file';
import { FolderResponseDto, foldersApi } from 'features/folders';
import { ImageResponseDto, imagesApi } from 'features/images';
import {
  LayoutCreateRequestDto,
  LayoutEditForm,
  LayoutEditFormFields,
  layoutsApi,
  LayoutStatus,
  useCreateLayoutMutation,
} from 'features/layouts';
import { LayoutViewTab } from 'features/layouts/components/LayoutView/LayoutViewTab';
import { useCreateLayout } from 'features/layouts/hooks';
import { ModalSubmitActions } from 'features/modal';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux';
import { Modal } from 'ui-library/components/Modal';

export const LayoutCreate: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const { isOpen, parentFolderId, selectedTab } = useAppSelector((state) => state.layoutCreate);
  const { t } = useTranslation();

  const handleClose = () => {
    dispatch(layoutCreateActions.close());
    dispatch(layoutCreateActions.selectTab(LayoutViewTab.INFORMATION));
  };

  const [createLayoutMutation] = useCreateLayoutMutation();
  const uploadFile = useUploadFile(FileType.LAYOUT);
  const { handleCreateLayout, handleCreateLayoutError } = useCreateLayout();

  const getFolderIDsToAdd = (values: Array<FolderResponseDto>) => {
    return values.map((x) => x.id).concat([parentFolderId ? parentFolderId : '']);
  };

  const getImageIDsToAdd = (values: Array<ImageResponseDto>) => {
    return values.map((x) => x.id);
  };

  const onSubmit = async (values: LayoutEditFormFields) => {
    const { fileBox } = values;

    let fileID;
    if (fileBox.file) {
      fileID = await uploadFile(fileBox.file);
    }

    const dto: LayoutCreateRequestDto = {
      fileID,
      status: values.status,
      materialsKinds: values.materialsKinds,
      tags: values.tags?.map((tag) => tag.id),
      descriptionRu: values.descriptionRu,
      descriptionEn: values.descriptionEn,
      imageIDsToAdd: getImageIDsToAdd(values.images),
      folderIDsToAdd: getFolderIDsToAdd(values.folders),
    };

    try {
      await createLayoutMutation(dto).unwrap();
      handleCreateLayout();
      dispatch(layoutsApi.util.invalidateTags(['Layout']));
      dispatch(foldersApi.util.invalidateTags(['LayoutFolders']));
      dispatch(imagesApi.util.invalidateTags(['LayoutImages']));
    } catch (e) {
      handleCreateLayoutError();
    }

    handleClose();
  };

  const defaultValues: LayoutEditFormFields = {
    fileBox: {},
    status: LayoutStatus.DRAFT,
    materialsKinds: [],
    images: [],
    folders: [],
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Modal.Title>{t('createArtifacts.createLayout')}</Modal.Title>
      <LayoutEditForm
        isCreate
        onTabChange={(tab) => dispatch(layoutCreateActions.selectTab(tab))}
        selectedTab={selectedTab}
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        renderActions={({ isSubmitting, isValid }) => (
          <ModalSubmitActions loading={isSubmitting} disabled={!isValid} onCancel={handleClose} />
        )}
      />
    </Modal>
  );
};
