import cn from 'classnames';
import { Fragment, FunctionComponent } from 'react';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { useRouteConfig } from 'infrastructure/routes';
import styles from './Breadcrumbs.module.scss';
import { BreadcrumbsProps } from './BreadcrumbsProps';
import { BreadcrumbDivider } from './components/BreadcrumbDivider/BreadcrumbDivider';

export const Breadcrumbs: FunctionComponent<BreadcrumbsProps> = (props) => {
  const { className } = props;
  const { protectedRoutes } = useRouteConfig();
  const breadcrumbs = useBreadcrumbs(protectedRoutes, {
    disableDefaults: true,
  });

  return (
    <div className={cn(styles.root, className)}>
      {breadcrumbs.map(({ breadcrumb, match }, idx) => (
        <Fragment key={match.pathname}>
          {breadcrumb}
          {breadcrumbs.length - 1 !== idx && <BreadcrumbDivider className={styles.breadcrumbColor} />}
        </Fragment>
      ))}
    </div>
  );
};
