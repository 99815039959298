import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { authService } from 'features/auth';
import { useGetCurrentUserQuery } from 'features/auth/authApi';
import { ErrorBoundary } from 'features/errorBoundary';
import { GlobalModals } from 'features/modal';
import { useCurrentLanguage } from 'infrastructure/i18n';
import { useAppSelector } from 'infrastructure/redux';
import { useRouteConfig } from 'infrastructure/routes';
import { Notifications } from 'ui-library/v1/components/Notifications';

function App() {
  const { buildRoutes } = useRouteConfig();
  useGetCurrentUserQuery(undefined, {
    skip: !authService.tokenData?.user_id,
  });

  const currentLang = useCurrentLanguage();
  const { t } = useTranslation();
  useEffect(() => {
    document.title = t('headTitle');
  }, [currentLang]);

  const { isAuthorized } = useAppSelector((state) => state.auth);

  return (
    <ErrorBoundary>
      <>
        {buildRoutes()}
        {isAuthorized && <GlobalModals />}
        <Notifications />
      </>
    </ErrorBoundary>
  );
}

export default App;
