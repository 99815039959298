import React, { Suspense, useMemo } from 'react';
import { isExtension } from 'features/file';
import { FILE_FORMATS } from 'features/file/constants';
import { ImagePreview } from '../ImagePreview/ImagePreview';
import { FilePreviewProps } from './FilePreviewProps';

const TiffViewer = React.lazy(async () => ({
  default: (await import('../TiffViewer/TiffViewer')).TiffViewer,
}));

const isTiffFile = (file: File) => isExtension(file.name, [FILE_FORMATS.tiff, FILE_FORMATS.tif]);

export const FilePreview = (props: FilePreviewProps) => {
  const { file, previewUrl, onReady, onError } = props;

  const isSelectedTiffFile = file && isTiffFile(file);

  const imageSrc = useMemo(() => {
    if (file && !isSelectedTiffFile) {
      return URL.createObjectURL(file);
    } else {
      return undefined;
    }
  }, [file, isSelectedTiffFile]);

  if (isSelectedTiffFile) {
    return (
      <Suspense>
        <TiffViewer file={file} onReady={onReady} onError={onError} />
      </Suspense>
    );
  }

  if (imageSrc) {
    return <ImagePreview src={imageSrc} onLoad={onReady} />;
  }

  if (previewUrl) {
    return <ImagePreview src={previewUrl} onLoad={onReady} />;
  }

  return null;
};
