import React, { FunctionComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FilePreviewLabel } from 'features/file';
import styles from 'features/images/components/ImageEditList/ImageEditList.module.scss';
import { ImageEditListProps } from 'features/images/components/ImageEditList/ImageEditListProps';
import { useLanguageRelevantData } from 'infrastructure/i18n';
import { Button, ButtonKind, ButtonVariant } from 'ui-library/components/Button';
import { Icon } from 'ui-library/components/Icon';
import { Image } from 'ui-library/components/Image';
import { ListItem, ListItemKind } from 'ui-library/components/List';
import { ListItemText } from 'ui-library/components/List/ItemText/ListItemText';

export const ImageEditList: FunctionComponent<ImageEditListProps> = (props) => {
  const { t } = useTranslation();
  const { image, onRemoveItem } = props;
  const { matchProp } = useLanguageRelevantData();
  const preview = useMemo(() => image.file?.previews?.find((x) => x.label === FilePreviewLabel.IMAGE_SEARCH), [image]);

  const removeListItem = () => {
    onRemoveItem(image);
  };

  return (
    <ListItem kind={ListItemKind.LI} disableGutters>
      <div>
        <Image className={styles.icon} src={preview?.url} alt={t('layouts.imageAlt')} />
      </div>
      <div className={styles.imageName} title={matchProp(image.captionRu, image.captionEn)}>
        <ListItemText classes={{ primary: styles.primary }} primary={matchProp(image.captionRu, image.captionEn)} />
      </div>
      <Button
        className={styles.action}
        as={ButtonKind.BUTTON}
        onClick={removeListItem}
        variant={ButtonVariant.TRANSPARENT}
      >
        <Icon className={styles.cross} name='cross' />
      </Button>
    </ListItem>
  );
};
