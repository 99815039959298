import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getDefaultModalState, getModalReducers, ModalState } from 'features/modal';
import { TagResponseDto } from '../tagEntity';

export type TagEditState = ModalState & {
  selectedTag?: TagResponseDto;
};

const initialState: TagEditState = {
  ...getDefaultModalState(),
  selectedTag: undefined,
};

const tagEditSlice = createSlice({
  name: 'tagEdit',
  initialState,
  reducers: {
    ...getModalReducers<TagEditState>((state) => state),
    selectTag: (state, action: PayloadAction<TagResponseDto | undefined>) => {
      state.selectedTag = action.payload;
    },
  },
});

export const tagEditActions = tagEditSlice.actions;
export const tagEditReducer = tagEditSlice.reducer;
