import { FunctionComponent } from 'react';
import { FileDownloadsHeader, FileDownloadsTabGroup } from 'features/file';
import styles from './DownloadsScreen.module.scss';

export const DownloadsScreen: FunctionComponent = () => {
  return (
    <div className={styles.root}>
      <FileDownloadsHeader />
      <FileDownloadsTabGroup />
    </div>
  );
};
