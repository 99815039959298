import cn from 'classnames';
import React, { forwardRef } from 'react';
import { Card, CardProps } from 'ui-library/components/Card';
import styles from './LayoutCardRoot.module.scss';

export const LayoutCardRoot = forwardRef<HTMLDivElement, CardProps>((props, ref) => {
  const { className, ...rest } = props;

  return <Card className={cn(styles.root, className)} {...rest} ref={ref} />;
});
