import { FolderPartition } from 'features/folders';

class ToggleMuseumService {
  private readonly partitionKey = 'partition';

  public setPartition(state: FolderPartition): void {
    localStorage.setItem(this.partitionKey, JSON.stringify(state));
  }

  get activePartition(): FolderPartition | null {
    const value = localStorage.getItem(this.partitionKey);

    if (!value) {
      return null;
    }

    return JSON.parse(value);
  }

  public clear(): void {
    localStorage.removeItem(this.partitionKey);
  }
}

export const toggleMuseumService = new ToggleMuseumService();
