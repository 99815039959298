import React, { FunctionComponent } from 'react';
import { useFetchGroupsQuery } from 'features/favorites/';
import { favoriteGroupListActions } from 'features/favorites/slices';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux';
import { Pagination } from 'ui-library/components/Pagination';
import { FavoriteGroupCardList } from '../FavoriteGroupCardList/FavoriteGroupCardList';
import styles from './FavoriteGroupsDataView.module.scss';

export const FavoriteGroupsDataView: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const { currentPage, pageSize } = useAppSelector((state) => state.favoriteGroupList.paginationData);
  const { sortDirection, sortField } = useAppSelector((state) => state.favoriteGroupList.sortingInfo);
  const query = useFetchGroupsQuery({
    pageSize,
    currentPage,
    sortDirection,
    sortFields: [sortField],
  });

  return (
    <>
      <div className={styles.listWrap}>
        <FavoriteGroupCardList {...query} />
      </div>
      <div className={styles.paginationWrap}>
        <Pagination
          totalItems={query.data?.totalCount}
          pageSize={pageSize}
          onPageChanged={(page) => dispatch(favoriteGroupListActions.pageChanged({ page }))}
          currentPage={currentPage}
          scrollToStartAfterPageChanged
        />
      </div>
    </>
  );
};
