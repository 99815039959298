import { ChangeEvent, FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { linkingArtifactActions } from 'features/linking';
import { useDebounce } from 'features/search';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux';
import { ButtonKind } from 'ui-library/components/Button';
import { Icon } from 'ui-library/components/Icon';
import { IconButton, IconButtonVariant } from 'ui-library/components/IconButton';
import { Input, InputVariant } from 'ui-library/components/Input';
import { LinkingArtifactRootFolderDefaultList } from '../LinkingArtifactRootFolderDefaultList/LinkingArtifactRootFolderDefaultList';
import { LinkingArtifactRootFolderSearchList } from '../LinkingArtifactRootFolderSearchList/LinkingArtifactRootFolderSearchList';
import styles from './LinkingArtifactRootFolderDataView.module.scss';

export const LinkingArtifactRootFolderDataView: FunctionComponent = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const query = useAppSelector((state) => state.linkingArtifacts.query);
  const debouncedQuery = useDebounce(query);

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(linkingArtifactActions.setQuery(event.target.value));
  };

  const handleClear = () => {
    dispatch(linkingArtifactActions.setQuery(''));
  };

  return (
    <div className={styles.root}>
      <div className={styles.inputWrap}>
        <Input
          value={query}
          onChange={handleSearch}
          LayoutProps={{
            variant: InputVariant.STANDARD,
            endAdornment: query && (
              <IconButton as={ButtonKind.BUTTON} variant={IconButtonVariant.TRANSPARENT} onClick={handleClear}>
                <Icon name='cross' />
              </IconButton>
            ),
          }}
          placeholder={t('folders.searchRootFolder')}
        />
      </div>
      {debouncedQuery ? (
        <LinkingArtifactRootFolderSearchList query={debouncedQuery} />
      ) : (
        <LinkingArtifactRootFolderDefaultList />
      )}
    </div>
  );
};
