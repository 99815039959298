import { FC } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { deleteFolderTranslationsService, useDeleteFolderMutation } from 'features/folders';
import { folderDeleteEmptyActions } from 'features/folders/slices/folderDeleteEmptySlice';
import { NotDefinedParamException } from 'infrastructure/exceptions';
import { useLanguageRelevantData } from 'infrastructure/i18n/hooks';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux';
import { getFolderDetailRoute, ROUTE_PATHS } from 'infrastructure/routes/paths';
import { ActionButton } from 'ui-library/components/ActionButton';
import { ButtonKind, ButtonVariant } from 'ui-library/components/Button';
import { Modal } from 'ui-library/components/Modal';

export const FolderDeleteEmptyModal: FC = () => {
  const dispatch = useAppDispatch();
  const { isOpen, folder } = useAppSelector((state) => state.folderDeleteEmpty);
  const [deleteFolderMutation, { isLoading }] = useDeleteFolderMutation();
  const { matchProp } = useLanguageRelevantData();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleClose = () => {
    dispatch(folderDeleteEmptyActions.setFolderForDeletion(undefined));
    dispatch(folderDeleteEmptyActions.close());
  };

  const handleDelete = async () => {
    if (!folder) {
      throw new NotDefinedParamException('folder');
    }

    try {
      await deleteFolderMutation({
        folderID: folder.id,
        parentID: folder.parentID,
      }).unwrap();

      handleClose();

      const nextUrl = folder.parentID ? getFolderDetailRoute(folder.parentID, folder.partition) : ROUTE_PATHS.HOME;
      navigate(nextUrl, { replace: true });

      toast.success(
        t('folders.deleteSucceed', {
          name: matchProp(folder.captionRu, folder.captionEn),
        }),
      );
    } catch (e) {
      toast.error(
        t('folders.deleteFailed', {
          name: matchProp(folder.captionRu, folder.captionEn),
        }),
      );
    }
  };

  const translations = deleteFolderTranslationsService.getTranslations(folder);

  return (
    <Modal open={isOpen} onClose={handleClose} variant='popup'>
      <Modal.Title>{translations && t(translations.title)}</Modal.Title>
      <Modal.Content>{translations && t(translations.description)}</Modal.Content>
      <Modal.Actions>
        <ActionButton as={ButtonKind.BUTTON} onClick={handleDelete} loading={isLoading}>
          {t('actions.remove')}
        </ActionButton>
        <ActionButton as={ButtonKind.BUTTON} onClick={handleClose} variant={ButtonVariant.SECONDARY}>
          {t('actions.cancel')}
        </ActionButton>
      </Modal.Actions>
    </Modal>
  );
};
