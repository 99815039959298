import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FavoriteGroupDtoValidationSchema, FavoriteGroupRequestBody } from 'features/favorites';
import { useForm } from 'infrastructure/form';
import { FormCol, FormRow } from 'ui-library/components/Form';
import { Modal } from 'ui-library/components/Modal';
import { TextareaField } from 'ui-library/components/TextareaField';
import { TextField } from 'ui-library/components/TextField';
import { FavoriteGroupFormProps } from './FavoriteGroupFormProps';

const captionRuField: keyof FavoriteGroupRequestBody = 'captionRu';
const captionEnField: keyof FavoriteGroupRequestBody = 'captionEn';
const descriptionRuField: keyof FavoriteGroupRequestBody = 'descriptionRu';
const descriptionEnField: keyof FavoriteGroupRequestBody = 'descriptionEn';

export const FavoriteGroupForm = (props: FavoriteGroupFormProps) => {
  const { renderActions, onSubmit, defaultValues } = props;
  const { t } = useTranslation();

  const methods = useForm<FavoriteGroupRequestBody>({
    defaultValues,
    schema: FavoriteGroupDtoValidationSchema(),
  });

  const { handleSubmit, formState, register } = methods;
  const { errors } = formState;

  const submit = async (values: FavoriteGroupRequestBody) => {
    return onSubmit(values);
  };

  return (
    <FormProvider {...methods}>
      <Modal.Form onSubmit={handleSubmit(submit)}>
        <Modal.Content>
          <FormRow>
            <FormCol>
              <TextField
                error={errors[captionRuField]?.message}
                label={t('favorites.group.properties.captionRu.label')}
                required
                InputProps={{
                  ...register(captionRuField),
                  placeholder: t('favorites.group.properties.captionRu.placeholder'),
                }}
              />
            </FormCol>
          </FormRow>
          <FormRow>
            <FormCol>
              <TextField
                error={errors[captionEnField]?.message}
                label={t('favorites.group.properties.captionEn.label')}
                required
                InputProps={{
                  ...register(captionEnField),
                  placeholder: t('favorites.group.properties.captionEn.placeholder'),
                }}
              />
            </FormCol>
          </FormRow>
          <FormRow>
            <FormCol>
              <TextareaField
                error={errors[descriptionRuField]?.message}
                label={t('favorites.group.properties.descriptionRu.label')}
                TextareaProps={{
                  ...register(descriptionRuField),
                  placeholder: t('favorites.group.properties.descriptionRu.placeholder'),
                }}
              />
            </FormCol>
          </FormRow>
          <FormRow>
            <FormCol>
              <TextareaField
                error={errors[descriptionEnField]?.message}
                label={t('favorites.group.properties.descriptionEn.label')}
                TextareaProps={{
                  ...register(descriptionEnField),
                  placeholder: t('favorites.group.properties.descriptionEn.placeholder'),
                }}
              />
            </FormCol>
          </FormRow>
        </Modal.Content>
        <Modal.Actions>{renderActions(formState)}</Modal.Actions>
      </Modal.Form>
    </FormProvider>
  );
};
