import React, { FunctionComponent } from 'react';
import { useFetchViewDownloadFilesQuery } from 'features/file';
import { SortDirection } from 'features/sorting';
import { FileTaskList } from '../FileTaskList/FileTaskList';
import { FileTaskListEmpty } from '../FileTaskListEmpty/FileTaskListEmpty';
import { FileTaskListLoadingError } from '../FileTaskListLoadingError/FileTaskListLoadingError';
import styles from './ActiveFileDownloadTasksContent.module.scss';

const MAX_ITEMS = 5;
const POLLING_INTERVAL_MS = 15000;

export const ActiveFileDownloadTasksContent: FunctionComponent = () => {
  const query = useFetchViewDownloadFilesQuery(
    {
      items: [
        {
          section: 'active',
          offset: 0,
          limit: MAX_ITEMS,
          sortDirection: SortDirection.DESC,
          sortFields: ['createTime'],
        },
      ],
    },
    { pollingInterval: POLLING_INTERVAL_MS },
  );
  const { isError, refetch, data } = query;

  const fetchData = data?.items[0].active;
  const isEmpty = fetchData?.totalCount === 0;

  const getContent = () => {
    if (isError) {
      return <FileTaskListLoadingError className={styles.zero} onReload={refetch} />;
    }

    if (isEmpty) {
      return <FileTaskListEmpty className={styles.zero} />;
    }

    return <FileTaskList {...query} data={fetchData} />;
  };

  return <div className={styles.root}>{getContent()}</div>;
};
