import { createSlice } from '@reduxjs/toolkit';
import { ListState, getListPaginationReducers, getListSortReducers } from 'features/list';
import { getDefaultPaginationState } from 'features/pagination';
import { SortDirection } from 'features/sorting';
import { FileDownloadResponseDto } from '../fileEntity';

type EntityType = FileDownloadResponseDto;
export type ActiveFileDownloadTaskListState = ListState<EntityType>;

const initialState: ActiveFileDownloadTaskListState = {
  paginationData: getDefaultPaginationState(),
  sortingInfo: {
    sortDirection: SortDirection.DESC,
    sortField: 'createTime',
  },
};

const activeFileDownloadTaskList = createSlice({
  name: 'activeFileDownloadTaskList',
  initialState,
  reducers: {
    ...getListSortReducers<ActiveFileDownloadTaskListState, EntityType>(),
    ...getListPaginationReducers<ActiveFileDownloadTaskListState>(),
  },
});

export const activeFileDownloadTaskListActions = activeFileDownloadTaskList.actions;
export const activeFileDownloadTaskListReducer = activeFileDownloadTaskList.reducer;
