import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { ReferenceBook, ReferenceBookType } from 'features/referenceBook';
import { ReferenceBookFormController } from 'infrastructure/form';
import { useLanguageRelevantData } from 'infrastructure/i18n';
import { isNumeric } from 'utils';
import { CenturyReferenceBookSelectFieldProps } from './CenturyReferenceBookSelectFieldProps';

export const CenturyReferenceBookSelectField: FunctionComponent<CenturyReferenceBookSelectFieldProps> = (props) => {
  const { t } = useTranslation();
  const { matchProp } = useLanguageRelevantData();

  const getOptionLabel = (x: ReferenceBook) => {
    const caption = matchProp(x.captionRu, x.captionEn);
    const parsedNumber = parseInt(caption);

    if (isNumeric(parsedNumber) && parsedNumber < 0) {
      return t('imageEditForm.century.negative', {
        value: Math.abs(parsedNumber),
      });
    }

    return caption;
  };

  return <ReferenceBookFormController {...props} getOptionLabel={getOptionLabel} type={ReferenceBookType.CENTURY} />;
};
