import { FunctionComponent, SyntheticEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { FolderResponseDto } from 'features/folders';
import { FolderTableActions, FolderTableSkeleton } from 'features/rootFolders';
import { useRootFolderTableHeadColumns } from 'features/search/hooks';
import { getFolderDetailRoute } from 'infrastructure/routes/paths';
import { Table, TableBody, TableHead } from 'ui-library/components/Table';
import { useRootFolderTableColumns } from '../useRootFolderTableColumns/useRootFolderTableColumns';
import styles from './RootFolderTable.module.scss';
import { RootFolderTableProps } from './RootFolderTableProps';

export const RootFolderTable: FunctionComponent<RootFolderTableProps> = (props) => {
  const navigate = useNavigate();
  const columns = useRootFolderTableColumns();
  const headColumns = useRootFolderTableHeadColumns();

  const handleRowClick = (event: SyntheticEvent, item: FolderResponseDto) => {
    navigate(getFolderDetailRoute(item.id, item.partition));
  };

  return (
    <Table>
      <TableHead columns={headColumns} />
      {props.isFetching ? (
        <FolderTableSkeleton />
      ) : (
        <TableBody
          columns={columns}
          rowClassName={styles.row}
          items={props.data?.content || []}
          getRowActions={(x, options) => <FolderTableActions folder={x} className={options?.className} />}
          onRowClick={handleRowClick}
        />
      )}
    </Table>
  );
};
