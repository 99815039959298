import { useTranslation } from 'react-i18next';
import { Icon } from 'ui-library/components/Icon';
import { ZeroScreenWrapper } from 'ui-library/components/ZeroScreen';
import { EmptyRootProps } from './EmptyRootProps';

export const EmptyRoot = (props: EmptyRootProps) => {
  const { className, theme = 'dark' } = props;
  const { t } = useTranslation();

  return (
    <ZeroScreenWrapper
      className={className}
      theme={theme}
      renderIcon={(props) => <Icon name='imagesIllustration' size='none' {...props} />}
      renderButton={(props) => undefined}
      message={t('folders.emptyRoot.viewMessage')}
    />
  );
};
