import { useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { UserEditDtoValidationSchema } from 'features/users/userEditValidation';
import { UserEditFormFields } from 'features/users/userEntity';
import { useForm } from 'infrastructure/form';
import { useTranslateEnum } from 'infrastructure/i18n';
import { ApiErrorCode, parseApiError } from 'infrastructure/network';
import { Modal } from 'ui-library/components/Modal';
import { Tab } from 'ui-library/components/Tab';
import { UserEditFormInfoTab } from './UserEditFormInfoTab/UserEditFormInfoTab';
import { UserEditFormPasswordTab } from './UserEditFormPasswordTab/UserEditFormPasswordTab';
import { UserEditFormProps } from './UserEditFormProps';
import { UserEditFormTab } from './UserEditFormTab';

export const UserEditForm = (props: UserEditFormProps) => {
  const { renderActions, onSubmit, defaultValues } = props;
  const { translateValue } = useTranslateEnum('UserEditFormTab', UserEditFormTab);
  const [selectedTab, setSelectedTab] = useState(UserEditFormTab.INFORMATION);

  const methods = useForm<UserEditFormFields>({
    defaultValues,
    schema: UserEditDtoValidationSchema(),
  });

  const { handleSubmit, formState, setError } = methods;

  const submit = async (values: UserEditFormFields) => {
    return onSubmit(values).catch((rawError) => {
      const error = parseApiError(rawError);

      if (error) {
        if (error.code === ApiErrorCode.PASSWORD_WRONG_CONFIRMATION) {
          setSelectedTab(UserEditFormTab.PASSWORD);
          setError('passwordCurrent', {
            message: 'validation.invalid.password',
          });
        } else if (error.code === ApiErrorCode.PASSWORD_WEAK) {
          setSelectedTab(UserEditFormTab.PASSWORD);
          setError('password', {
            message: 'validation.invalid.weakPassword',
          });
        }
      }
    });
  };

  return (
    <FormProvider {...methods}>
      <Modal.Form onSubmit={handleSubmit(submit)}>
        <Modal.Content>
          <Tab.Group selectedIndex={selectedTab} onChange={setSelectedTab} as='div'>
            <Tab.List>
              <Tab>{translateValue(UserEditFormTab.INFORMATION)}</Tab>
              <Tab>{translateValue(UserEditFormTab.PASSWORD)}</Tab>
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel>
                <UserEditFormInfoTab />
              </Tab.Panel>
              <Tab.Panel>
                <UserEditFormPasswordTab />
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </Modal.Content>
        <Modal.Actions>{renderActions(formState)}</Modal.Actions>
      </Modal.Form>
    </FormProvider>
  );
};
