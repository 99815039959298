import { FunctionComponent, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Virtuoso } from 'react-virtuoso';
import { FolderArtifactListFooter } from 'features/fileNavigator/folders';
import { FolderListItem, FolderResponseDto, FolderSearchRequestDto, useSearchFolderQuery } from 'features/folders';
import { movingArtifactsActions } from 'features/movingArtifacts';
import { useInfiniteLoading } from 'features/pagination';
import { ErrorLoadingRoot } from 'features/rootFolders';
import { EmptySearch } from 'features/search';
import { SortDirection } from 'features/sorting';
import { useCurrentLanguage, useLanguageRelevantData } from 'infrastructure/i18n';
import { ListApiResponse } from 'infrastructure/network';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux';
import { List } from 'ui-library/components/List';
import styles from './MovingArtifactRootFolderSearchList.module.scss';
import { MovingArtifactRootFolderSearchListProps } from './MovingArtifactRootFolderSearchListProps';

export const MovingArtifactRootFolderSearchList: FunctionComponent<MovingArtifactRootFolderSearchListProps> = (
  props,
) => {
  const { query, partition } = props;
  const { t } = useTranslation();
  const { matchProp } = useLanguageRelevantData();
  const currentLang = useCurrentLanguage();
  const movingArtifacts = useAppSelector((state) => state.movingArtifacts.movingArtifacts);
  const dispatch = useAppDispatch();

  const { data, isError, isFetching, readMore, refresh } = useInfiniteLoading<
    FolderResponseDto,
    ListApiResponse<FolderResponseDto>,
    FolderSearchRequestDto
  >(
    useSearchFolderQuery,
    (state) => ({
      pageSize: state.pageSize,
      currentPage: state.currentPage,
      language: currentLang,
      sortDirection: SortDirection.ASC,
      sortFields: [matchProp('captionRu', 'captionEn')],
      query,
      partition,
    }),
    (result) => result,
    {
      pageSize: 30,
    },
  );

  const handleClick = (folder: FolderResponseDto) => {
    dispatch(movingArtifactsActions.selectFolderId(folder.id));
  };

  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const fetchData = useMemo(
    () => data?.filter((x) => movingArtifacts.some((artifact) => artifact.entityId !== x.id)) ?? [],
    [data, movingArtifacts],
  );

  return (
    <div className={styles.root}>
      {isError ? (
        <ErrorLoadingRoot className={styles.placeholder} onReload={refresh} />
      ) : (
        <Virtuoso
          className={styles.list}
          data={fetchData}
          overscan={200}
          endReached={readMore}
          itemContent={(_, folder) => <FolderListItem onClick={() => handleClick(folder)} folder={folder} />}
          components={{
            List: List,
            EmptyPlaceholder: isFetching
              ? undefined
              : () => <EmptySearch className={styles.placeholder} message={t('search.emptyResults')} />,
            Footer: isFetching ? () => <FolderArtifactListFooter /> : undefined,
          }}
        />
      )}
    </div>
  );
};
