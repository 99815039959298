import { UserRole } from 'features/users';
import { roleService } from '../roleService';
import { AccessRuleService } from './accessRuleService';

class LayoutAccessRuleService extends AccessRuleService {
  protected canViewRoles: Array<UserRole> = [UserRole.ADMIN, UserRole.USER, UserRole.GUEST];
  protected canCreateRoles: Array<UserRole> = [UserRole.ADMIN];
  protected canEditRoles: Array<UserRole> = [UserRole.ADMIN];
  protected canDeleteRoles: Array<UserRole> = [UserRole.SUPERADMIN];
  protected canMoveRoles: Array<UserRole> = [UserRole.ADMIN];
  protected canLinkRoles: Array<UserRole> = [UserRole.ADMIN];
  protected canUnLinkRoles: Array<UserRole> = [UserRole.ADMIN];
  protected canViewOriginalInfoRoles: Array<UserRole> = [UserRole.ADMIN, UserRole.SUPERADMIN];
  protected canViewUIRoles: Array<UserRole> = [UserRole.ADMIN, UserRole.USER];

  public canMove() {
    return roleService.hasAccess(this.canMoveRoles);
  }

  public canDelete() {
    return roleService.hasAccess(this.canDeleteRoles);
  }

  public canLink() {
    return roleService.hasAccess(this.canLinkRoles);
  }

  public canUnLink() {
    return roleService.hasAccess(this.canUnLinkRoles);
  }

  public canViewOriginalInfo() {
    return roleService.hasAccess(this.canViewOriginalInfoRoles);
  }

  public canViewUI() {
    return roleService.hasAccess(this.canViewUIRoles);
  }
}

export const layoutAccessRuleService = new LayoutAccessRuleService();
