import { FunctionComponent, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { SearchFilterFormFields, SearchScreenResultTab, useSearchQueryParams } from 'features/search';
import { ImageFilter } from 'features/v1/search/ImageFilter/ImageFilter';
import { MainFilter } from 'features/v1/search/MainFilter/MainFilter';
import { Form, FormRow } from 'ui-library/components/Form';
import styles from './SearchScreenFilterForm.module.scss';
import { SearchScreenFilterFormProps } from './SearchScreenFilterFormProps';

export const SearchScreenFilterForm: FunctionComponent<SearchScreenFilterFormProps> = ({ defaultValues }) => {
  const { updateFilterData, tab, query } = useSearchQueryParams();
  const methods = useForm<SearchFilterFormFields>({
    defaultValues,
  });

  const { watch, formState, reset } = methods;

  useEffect(() => {
    if (!formState.isDirty) {
      reset(defaultValues);
    }
  }, [defaultValues, formState.isDirty, reset]);

  useEffect(() => {
    reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    const subscription = watch((data) => {
      updateFilterData(data as Partial<SearchFilterFormFields>);
    });

    return () => subscription.unsubscribe();
  }, [updateFilterData, watch]);

  return (
    <FormProvider {...methods}>
      <Form>
        <FormRow className={styles.row}>
          <MainFilter />
          {tab === SearchScreenResultTab.IMAGES && <ImageFilter />}
        </FormRow>
      </Form>
    </FormProvider>
  );
};
