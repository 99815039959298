import { createSlice } from '@reduxjs/toolkit';
import { getDefaultModalState, getModalReducers, ModalState } from 'features/modal';

export type FavoriteGroupCreateState = ModalState;

const initialState: FavoriteGroupCreateState = {
  ...getDefaultModalState(),
};

const favoriteGroupCreateSlice = createSlice({
  name: 'favoriteGroupCreateSlice',
  initialState,
  reducers: {
    ...getModalReducers<FavoriteGroupCreateState>((state) => state),
  },
});

export const favoriteGroupCreateActions = favoriteGroupCreateSlice.actions;
export const favoriteGroupCreateReducer = favoriteGroupCreateSlice.reducer;
