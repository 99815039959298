import { FunctionComponent } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FileDownloadAgreementFormFields } from 'features/file/fileEntity';
import { ActionButton } from 'ui-library/components/ActionButton';
import { ButtonKind, ButtonVariant } from 'ui-library/components/Button';
import { CheckboxField } from 'ui-library/components/CheckboxField';
import styles from './FileDownloadAgreementActions.module.scss';
import { FileDownloadAgreementActionsProps } from './FileDownloadAgreementActionsProps';

export const FileDownloadAgreementActions: FunctionComponent<FileDownloadAgreementActionsProps> = (props) => {
  const { t } = useTranslation();
  const { handleClose } = props;
  const { register } = useFormContext<FileDownloadAgreementFormFields>();

  return (
    <div className={styles.root}>
      <CheckboxField
        CheckboxProps={{ ...register('doNotShowAnymore') }}
        label={t('files.downloadAgreement.doNotShowAnymore')}
      />
      <div className={styles.actions}>
        <ActionButton as={ButtonKind.BUTTON} type='submit'>
          {t('files.downloadAgreement.actions.agree')}
        </ActionButton>
        <ActionButton as={ButtonKind.BUTTON} variant={ButtonVariant.SECONDARY} onClick={handleClose}>
          {t('files.downloadAgreement.actions.cancelDownload')}
        </ActionButton>
      </div>
    </div>
  );
};
