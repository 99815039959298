import { useMemo } from 'react';
import { FileDownloadResponseDto, FileDownloadViewRequestTypesTask, useHumanReadableFileSize } from 'features/file';
import { dateService } from 'infrastructure/date';
import { Icon } from 'ui-library/components/Icon';
import { TextCellRenderer } from 'ui-library/components/Table';
import { TableColumn } from 'ui-library/components/Table/TableColumn';
import { nameof } from 'utils';
import styles from './useFileTaskHistoryColumns.module.scss';

export const useFileTaskHistoryColumns = () => {
  const { convertToHumanReadable } = useHumanReadableFileSize();

  const columns = useMemo<Array<TableColumn<FileDownloadResponseDto>>>(() => {
    const columns: Array<TableColumn<FileDownloadResponseDto>> = [
      {
        fieldName: nameof<FileDownloadResponseDto>('label'),
        width: '50%',
        cellRenderer: (data) => (
          <TextCellRenderer
            textClassName={styles.primary}
            iconClassName={styles.icon}
            value={data.label}
            icon={
              data.name === FileDownloadViewRequestTypesTask.FILE_DOWNLOAD ? (
                <Icon name='folderAlt' />
              ) : (
                <Icon name='doc' />
              )
            }
          />
        ),
      },
      {
        fieldName: nameof<FileDownloadResponseDto>('size'),
        width: '11%',
        cellRenderer: (data) => (
          <TextCellRenderer textClassName={styles.secondary} value={convertToHumanReadable(data.size)} />
        ),
      },
      {
        fieldName: nameof<FileDownloadResponseDto>('validThrough'),
        width: '12%',
        cellRenderer: (data) => (
          <TextCellRenderer textClassName={styles.secondary} value={dateService.format(data.createTime)} />
        ),
      },
    ];

    return columns;
  }, [convertToHumanReadable]);

  return columns;
};
