import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FileBox, FileUploader, uploadMaxFileSizeMB, uploadSupportedMimeImageTypes } from 'features/file';
import { FolderEditFormFields, FolderStatus, MaterialsKind } from 'features/folders';
import { useEnumOptions } from 'features/select';
import { TagsFormController } from 'features/tags';
import { SelectFormController } from 'infrastructure/form';
import { FormCol, FormRow } from 'ui-library/components/Form';
import { TextField } from 'ui-library/components/TextField';
import { nameof } from 'utils';
import styles from './FolderEditFormInfoTab.module.scss';

const fileBoxField: keyof FolderEditFormFields = 'fileBox';
const fileBoxFileField = `${fileBoxField}.${nameof<FileBox>('file')}` as const;
const captionRuField: keyof FolderEditFormFields = 'captionRu';
const captionEnField: keyof FolderEditFormFields = 'captionEn';
const statusField: keyof FolderEditFormFields = 'status';
const materialsKindsField: keyof FolderEditFormFields = 'materialsKinds';
const tagsField: keyof FolderEditFormFields = 'tags';

export const FolderEditFormInfoTab = () => {
  const { t } = useTranslation();
  const { options: folderStatusOptions } = useEnumOptions('FolderStatus', FolderStatus);
  const { options: materialKindsOptions } = useEnumOptions('MaterialsKind', MaterialsKind);

  const {
    register,
    control,
    getValues,
    formState: { errors },
    setError,
    clearErrors,
  } = useFormContext<FolderEditFormFields>();

  return (
    <div className={styles.root}>
      <FormRow>
        <FormCol>
          <Controller
            control={control}
            name={fileBoxFileField}
            render={({ field: { value, onChange } }) => (
              <FileUploader
                previewUrl={getValues().fileBox?.previewUrl}
                error={errors[fileBoxField]?.file?.message}
                defaultSelectedFile={value as File}
                onChange={(file) => {
                  clearErrors(fileBoxFileField);
                  onChange(file);
                }}
                onError={() => {
                  setError(fileBoxFileField, {
                    message: t('validation.file.type'),
                  });
                }}
                maxFileSizeMB={uploadMaxFileSizeMB}
                supportedMimeTypes={uploadSupportedMimeImageTypes}
              />
            )}
          />
        </FormCol>
      </FormRow>
      <FormRow>
        <FormCol>
          <TextField
            error={errors[captionRuField]?.message}
            label={t('folderEditForm.form.captionRu.label')}
            required
            InputProps={{
              ...register(captionRuField),
              placeholder: t('folderEditForm.form.captionRu.placeholder'),
            }}
          />
        </FormCol>
      </FormRow>
      <FormRow>
        <FormCol>
          <TextField
            error={errors[captionEnField]?.message}
            label={t('folderEditForm.form.captionEn.label')}
            required
            InputProps={{
              ...register(captionEnField),
              placeholder: t('folderEditForm.form.captionEn.placeholder'),
            }}
          />
        </FormCol>
      </FormRow>
      <FormRow>
        <FormCol>
          <SelectFormController
            name={statusField}
            label={t('folderEditForm.form.status.label')}
            placeholder={t('folderEditForm.form.status.placeholder')}
            options={folderStatusOptions}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
          />
        </FormCol>
        <FormCol>
          <SelectFormController
            name={materialsKindsField}
            label={t('folderEditForm.form.materialsKinds.label')}
            placeholder={t('folderEditForm.form.materialsKinds.placeholder')}
            options={materialKindsOptions}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
            SelectProps={{
              multiple: true,
            }}
          />
        </FormCol>
      </FormRow>
      <FormRow>
        <FormCol>
          <TagsFormController name={tagsField} />
        </FormCol>
      </FormRow>
    </div>
  );
};
