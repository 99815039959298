import cn from 'classnames';
import { Fragment, FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { BreadcrumbDivider } from 'features/breadcrumbs';
import { useFolderBreadcrumbs } from 'features/folders';
import { useLanguageRelevantData } from 'infrastructure/i18n';
import { ButtonKind } from 'ui-library/components/Button';
import { Icon } from 'ui-library/components/Icon';
import { IconButton, IconButtonVariant } from 'ui-library/components/IconButton';
import styles from './FileNavigatorFolderBreadcrumbs.module.scss';
import { FileNavigatorFolderBreadcrumbsProps } from './FileNavigatorFolderBreadcrumbsProps';

export const FileNavigatorFolderBreadcrumbs: FunctionComponent<FileNavigatorFolderBreadcrumbsProps> = (props) => {
  const { selectedFolderId, onClickBreadcrumb, onClickGallery, onClickBack } = props;
  const { t } = useTranslation();
  const { matchProp } = useLanguageRelevantData();
  const breadcrumbs = useFolderBreadcrumbs({ folderId: selectedFolderId });

  const isBackButtonVisible = !!selectedFolderId;

  return (
    <div className={styles.root}>
      {isBackButtonVisible && (
        <IconButton
          className={styles.backButton}
          variant={IconButtonVariant.TRANSPARENT}
          size='small'
          as={ButtonKind.BUTTON}
          onClick={onClickBack}
        >
          <Icon name='arrowLeft' />
        </IconButton>
      )}
      <span className={styles.breadcrumb} onClick={onClickGallery}>
        {t('breadcrumb.gallery')}
      </span>
      {selectedFolderId && (
        <>
          <BreadcrumbDivider className={styles.divider} />
          <div className={styles.folderBreadcrumbs}>
            {breadcrumbs.map((x, idx) => (
              <Fragment key={x.id}>
                <span className={styles.breadcrumb} onClick={() => onClickBreadcrumb(x)}>
                  {matchProp(x.captionRu, x.captionEn)}
                </span>
                {breadcrumbs.length - 1 !== idx && (
                  <BreadcrumbDivider className={cn(styles.divider, styles.folderDivider)} />
                )}
              </Fragment>
            ))}
          </div>
        </>
      )}
    </div>
  );
};
