import { FunctionComponent } from 'react';
import { fileDownloadTaskHistoryListActions, useFetchViewDownloadFilesQuery } from 'features/file';
import { calculateOffset } from 'features/pagination';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux';
import { Pagination } from 'ui-library/components/Pagination';
import { FileTaskHistoryTable } from '../FileTaskHistoryTable/FileTaskHistoryTable';
import { FileTaskListEmpty } from '../FileTaskListEmpty/FileTaskListEmpty';
import { FileTaskListLoadingError } from '../FileTaskListLoadingError/FileTaskListLoadingError';
import styles from './FileHistoryDownloadsTab.module.scss';

export const FileHistoryDownloadsTab: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const { paginationData, sortingInfo } = useAppSelector((state) => state.fileDownloadTaskHistoryList);
  const { currentPage, pageSize } = paginationData;
  const query = useFetchViewDownloadFilesQuery({
    items: [
      {
        section: 'archive',
        offset: calculateOffset(currentPage, pageSize),
        limit: pageSize,
        sortDirection: sortingInfo.sortDirection,
        sortFields: [sortingInfo.sortField],
      },
    ],
  });
  const { isError, data, refetch } = query;

  const fetchData = data?.items[0].archive;
  const isEmpty = fetchData?.totalCount === 0;

  const getContent = () => {
    if (isError) {
      return <FileTaskListLoadingError className={styles.zero} onReload={refetch} />;
    }

    if (isEmpty) {
      return <FileTaskListEmpty className={styles.zero} />;
    }

    return <FileTaskHistoryTable {...query} data={fetchData} />;
  };

  return (
    <div className={styles.root}>
      <div className={styles.listWrap}>{getContent()}</div>
      <div className={styles.paginationWrap}>
        <Pagination
          totalItems={fetchData?.totalCount}
          pageSize={pageSize}
          onPageChanged={(page) => dispatch(fileDownloadTaskHistoryListActions.pageChanged({ page }))}
          currentPage={currentPage}
          scrollToStartAfterPageChanged
        />
      </div>
    </div>
  );
};
