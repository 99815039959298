import { useRef } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReferenceBookEditFormFields } from 'features/referenceBook';
import { ReferenceBookEditDtoValidationSchema } from 'features/referenceBook/referenceBookEditValidation';
import { formTranslationsService } from 'features/referenceBook/services';
import { useForm } from 'infrastructure/form';
import { FormCol, FormRow } from 'ui-library/components/Form';
import { Modal } from 'ui-library/components/Modal';
import { TextField } from 'ui-library/components/TextField';
import styles from './ReferenceBookEditForm.module.scss';
import { ReferenceBookEditFormProps } from './ReferenceBookEditFormProps';

const captionRuField: keyof ReferenceBookEditFormFields = 'captionRu';
const captionEnField: keyof ReferenceBookEditFormFields = 'captionEn';

export const ReferenceBookEditForm = (props: ReferenceBookEditFormProps) => {
  const { renderActions, onSubmit, type, defaultValues } = props;
  const { t } = useTranslation();
  const translations = useRef(formTranslationsService.getTranslations(type)).current;

  const methods = useForm<ReferenceBookEditFormFields>({
    defaultValues,
    schema: ReferenceBookEditDtoValidationSchema(),
  });

  const { handleSubmit, formState, register } = methods;
  const { errors } = formState;

  const submit = async (values: ReferenceBookEditFormFields) => {
    return onSubmit(values);
  };

  return (
    <FormProvider {...methods}>
      <Modal.Form stopPropagation onSubmit={handleSubmit(submit)}>
        <Modal.Content>
          <div className={styles.root}>
            <FormRow>
              <FormCol>
                <TextField
                  error={errors[captionRuField]?.message}
                  label={t(translations.captionRuLabel)}
                  required
                  InputProps={{
                    ...register(captionRuField),
                    placeholder: t(translations.captionPlaceholder),
                  }}
                />
              </FormCol>
            </FormRow>
            <FormRow>
              <FormCol>
                <TextField
                  error={errors[captionEnField]?.message}
                  label={t(translations.captionEnLabel)}
                  required
                  InputProps={{
                    ...register(captionEnField),
                    placeholder: t(translations.captionPlaceholder),
                  }}
                />
              </FormCol>
            </FormRow>
          </div>
        </Modal.Content>
        <Modal.Actions>{renderActions(formState)}</Modal.Actions>
      </Modal.Form>
    </FormProvider>
  );
};
