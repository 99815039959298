import { createApi } from '@reduxjs/toolkit/query/react';
import { fetchBaseAuthQuery, HTTP_METHODS, URL_FACTORY } from 'infrastructure/network';
import { SearchAIRequestDto, SearchAIResponseDto, SearchRequestDto, SearchResponseDto } from './searchEntity';

export const searchApi = createApi({
  reducerPath: 'searchApi',
  tagTypes: ['SearchResult'],
  baseQuery: fetchBaseAuthQuery(),
  endpoints: (builder) => ({
    search: builder.query<SearchResponseDto, SearchRequestDto>({
      query: (body) => ({
        url: URL_FACTORY.SEARCH,
        method: HTTP_METHODS.POST,
        body,
      }),
      providesTags: (result, error) => (error ? [] : ['SearchResult']),
    }),
    searchAI: builder.query<SearchAIResponseDto, SearchAIRequestDto>({
      query: (body) => ({
        url: URL_FACTORY.SEARCHAI + `?offset=${body.offset}&limit=${body.limit}`,
        method: HTTP_METHODS.POST,
        body: {
          query: body.query,
        },
      }),
      providesTags: (result, error) => (error ? [] : ['SearchResult']),
    }),
  }),
});

export const { useLazySearchQuery, useSearchQuery, useSearchAIQuery } = searchApi;
