import { forwardRef, useImperativeHandle, useRef } from 'react';
import { InputLayout, InputLayoutVariant } from '../InputLayout';
import { InputControl } from './Control/InputControl';
import { InputProps } from './InputProps';

export const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const { disabled, LayoutProps, ...controlProps } = props;

  const inputRef = useRef<HTMLInputElement>(null);

  useImperativeHandle(ref, () => inputRef.current as HTMLInputElement);

  return (
    <InputLayout
      {...LayoutProps}
      disabled={disabled}
      onClick={() => {
        inputRef.current?.focus();
      }}
      variant={InputLayoutVariant.FILLED}
      ControlProps={controlProps}
      renderControl={(controlProps) => <InputControl ref={inputRef} {...controlProps} />}
    />
  );
});
