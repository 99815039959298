import { saveAs } from 'file-saver';
import { FunctionComponent } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useDeleteFileDownloadTaskMutation, useRetryFileDownloadTaskMutation } from 'features/file/fileApi';
import { FileDownloadResponseDto } from 'features/file/fileEntity';
import { List } from 'ui-library/components/List';
import { FileTaskListItem } from '../FileTaskListItem/FileTaskListItem';
import { FileTaskListLoading } from '../FileTaskListLoading/FileTaskListLoading';
import styles from './FileTaskList.module.scss';
import { FileTaskListProps } from './FileTaskListProps';

export const FileTaskList: FunctionComponent<FileTaskListProps> = (props) => {
  const { t } = useTranslation();
  const { isLoading, data } = props;
  const [triggerCancel] = useDeleteFileDownloadTaskMutation();
  const [triggerRetry] = useRetryFileDownloadTaskMutation();

  const handleFailed = async (task: FileDownloadResponseDto) => {
    try {
      await triggerRetry(task.ID).unwrap();
    } catch (e) {
      toast.error(t('files.downloads.retry.error'));
    }
  };

  const handleDownload = (task: FileDownloadResponseDto) => {
    saveAs(task.URL, task.label);
  };

  const handleCancel = async (task: FileDownloadResponseDto) => {
    try {
      await triggerCancel(task.ID).unwrap();
    } catch (e) {
      toast.error(t('files.downloads.cancel.error'));
    }
  };

  if (isLoading) {
    return <FileTaskListLoading className={styles.loading} />;
  }

  return (
    <List className={styles.root}>
      {data?.content.map((x) => (
        <FileTaskListItem
          key={x.ID}
          task={x}
          onFailed={async () => handleFailed(x)}
          onDownload={() => handleDownload(x)}
          onCancel={async () => handleCancel(x)}
        />
      ))}
    </List>
  );
};
