import { useTranslation } from 'react-i18next';
import { Button, ButtonKind, ButtonVariant } from 'ui-library/components/Button';
import { Icon } from 'ui-library/components/Icon';
import { ZeroScreenWrapper } from 'ui-library/components/ZeroScreen';
import styles from './EmptySection.module.scss';
import { EmptySectionProps } from './EmptySectionProps';

/**
 * @deprecated
 */
export const EmptySection = (props: EmptySectionProps) => {
  const { onAdd, iconName } = props;
  const { t } = useTranslation();

  return (
    <ZeroScreenWrapper
      className={styles.root}
      renderIcon={(props) => <Icon name={iconName} size='none' {...props} />}
      renderButton={(props) =>
        onAdd ? (
          <Button
            as={ButtonKind.BUTTON}
            variant={ButtonVariant.SECONDARY}
            onClick={onAdd}
            size='small'
            startIcon={<Icon name='plus' />}
            {...props}
          >
            {t('actions.add')}
          </Button>
        ) : undefined
      }
      message={onAdd ? t('zeroScreen.emptySection.editMessage') : t('zeroScreen.emptySection.viewMessage')}
    />
  );
};
