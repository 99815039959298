import { FunctionComponent } from 'react';
import { FavoriteButtonGroup } from 'features/favorites';
import { useFolderActionHandlers } from '../../hooks';
import { FolderFavoriteButtonGroupProps } from './FolderFavoriteButtonGroupProps';

export const FolderFavoriteButtonGroup: FunctionComponent<FolderFavoriteButtonGroupProps> = (props) => {
  const { folder, ...rest } = props;
  const { handleRemoveFromFavorite, handleAddToFavorite } = useFolderActionHandlers(folder);

  return (
    <FavoriteButtonGroup
      {...rest}
      onAddToFavorites={handleAddToFavorite}
      onRemoveFromFavorites={handleRemoveFromFavorite}
      isFavorite={!!folder?.isFavourite}
    />
  );
};
