import { ApiErrorCode, ApiErrorResponse } from 'infrastructure/network';

export class ApiLikeErrorException extends Error {
  data: ApiErrorResponse;
  status = 'ok';

  constructor(code: ApiErrorCode, message: string) {
    super(message);

    this.data = {
      code,
      message,
    };
  }
}
