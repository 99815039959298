import { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { FolderCard, FolderCardListGrid } from 'features/folders';
import { getFolderDetailRoute } from 'infrastructure/routes/paths';
import { FolderCardListSkeleton } from '../../FolderCardListSkeleton/FolderCardListSkeleton';
import { FolderCardListProps } from './FolderCardListProps';

export const FolderCardList: FunctionComponent<FolderCardListProps> = (props) => {
  const { data, isFetching } = props;

  return (
    <FolderCardListGrid>
      {isFetching ? (
        <FolderCardListSkeleton />
      ) : (
        data?.content.map((x) => (
          <Link key={x.id} to={getFolderDetailRoute(x.id, x.partition)}>
            <FolderCard folder={x} />
          </Link>
        ))
      )}
    </FolderCardListGrid>
  );
};
