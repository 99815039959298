import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionListItem, ActionListItemVariant } from 'ui-library/components/ActionList';
import { Icon } from 'ui-library/components/Icon';
import { TagsManageContext } from '../contexts';
import { TagResponseDto } from '../tagEntity';
import { useTagActionHandlers } from './useTagActionHandlers';

export const useTagActions = (tag: TagResponseDto) => {
  const { t } = useTranslation();
  const { handleEdit, handleExtend, handleUndoExtend, handleDelete, handleRemove, handleUndoRemove } =
    useTagActionHandlers(tag);
  const { isExtended, disableExtend, isRemoved } = useContext(TagsManageContext);

  const extendAction: ActionListItem = isExtended(tag)
    ? {
        label: t('actions.undoExtendTag'),
        onClick: handleUndoExtend,
        icon: <Icon name='undo' />,
      }
    : {
        label: t('actions.extendTag'),
        onClick: handleExtend,
        icon: <Icon name='copy' />,
      };

  const actions: Array<ActionListItem> = [
    {
      label: t('actions.edit'),
      onClick: handleEdit,
      icon: <Icon name='edit' />,
    },
    disableExtend ? null : extendAction,
    isRemoved(tag)
      ? {
          label: t('actions.undoLinkTag'),
          onClick: handleUndoRemove,
          icon: <Icon name='copy' />,
        }
      : {
          label: t('actions.removeTag'),
          onClick: handleRemove,
          icon: <Icon name='cross' />,
        },
    {
      label: t('actions.deleteTag'),
      onClick: handleDelete,
      icon: <Icon name='trash' />,
      variant: ActionListItemVariant.DANGER,
    },
  ].filter(Boolean) as Array<ActionListItem>;

  return { actions };
};
