import { Dialog, Transition } from '@headlessui/react';
import cn from 'classnames';
import { Fragment, FunctionComponent, ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ModalActions } from './Actions/ModalActions';
import { ModalBackdrop } from './Backdrop/ModalBackdrop';
import { ModalContent } from './Content/ModalContent';
import { ModalDrawer } from './Drawer/ModalDrawer';
import { ModalForm } from './Form/ModalForm';
import styles from './Modal.module.scss';
import { ModalProps } from './ModalProps';
import { ModalPopup } from './Popup/ModalPopup';
import { ModalTitle } from './Title/ModalTitle';

const ModalRoot: FunctionComponent<ModalProps> = (props) => {
  const {
    variant = 'drawer',
    hideBackdrop,
    className,
    open,
    animation,
    onClose,
    children,
    closeIconName,
    closeOnChangeLocation,
    ...restProps
  } = props;

  const location = useLocation();

  useEffect(() => {
    if (closeOnChangeLocation) {
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <Transition show={open} as={Fragment}>
      <Dialog className={cn(className, styles.root)} onClose={onClose} {...restProps}>
        {!hideBackdrop && <ModalBackdrop />}

        {variant === 'drawer' && (
          <ModalDrawer closeIconName={closeIconName} onClose={onClose} animation={animation}>
            {children as ReactNode}
          </ModalDrawer>
        )}
        {variant === 'popup' && (
          <ModalPopup closeIconName={closeIconName} onClose={onClose} animation={animation}>
            {children as ReactNode}
          </ModalPopup>
        )}
      </Dialog>
    </Transition>
  );
};

export const Modal = Object.assign(ModalRoot, {
  Title: ModalTitle,
  Content: ModalContent,
  Actions: ModalActions,
  Form: ModalForm,
});
