import { FunctionComponent } from 'react';
import { Navigate } from 'react-router';
import { authService } from 'features/auth';
import { AnonymousLayout } from 'features/layout';
import { useAppSelector } from 'infrastructure/redux/reduxHooks';
import { ROUTE_PATHS } from '../../paths/routePaths';
import { AnonymousRouteWrapperProps } from './anonymousRouteWrapperProps';

export const AnonymousRouteWrapper: FunctionComponent<AnonymousRouteWrapperProps> = (props) => {
  const isAuthorized = useAppSelector((state) => state.auth.isAuthorized);

  const to = authService.lastAuthorizedPath || ROUTE_PATHS.HOME;

  return isAuthorized ? <Navigate to={to} /> : <AnonymousLayout>{props.screen}</AnonymousLayout>;
};
