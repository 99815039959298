import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { SignInForm } from 'features/auth';
import { ToggleLanguageButton } from 'features/localization';
import styles from './SignInScreen.module.scss';

export const SignInScreen: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrap}>
      <div className={styles.languageToggler}>
        <ToggleLanguageButton />
      </div>
      <div className={styles.formContainer}>
        <h1 className={styles.title}>{t('auth.welcome')}</h1>
        <p className={styles.subtitle}>{t('auth.helpText')}</p>

        <div className={styles.formWrapper}>
          <SignInForm />
        </div>
      </div>
    </div>
  );
};
