import { FunctionComponent } from 'react';
import { useImageActions } from 'features/favorites/hooks';
import { ImageMasonryItemBase } from 'features/images';
import { ImageMasonryItemProps } from './ImageMasonryItemProps';

export const ImageMasonryItem: FunctionComponent<ImageMasonryItemProps> = (props) => {
  const { image, groupId } = props;
  const { actions } = useImageActions({ image, groupId });

  return <ImageMasonryItemBase {...props} actions={actions} />;
};
