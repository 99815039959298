import cn from 'classnames';
import { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAuth, useSignOut } from 'features/auth';
import {
  favoritesAccessRuleService,
  fileAccessRuleService,
  folderAccessRuleService,
  usersAccessRuleService,
} from 'features/role';
import { rootFolderCreationActions } from 'features/rootFolders';
import { NameFormatter } from 'features/users';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux';
import { ROUTE_PATHS } from 'infrastructure/routes/paths';
import { Icon } from 'ui-library/components/Icon';
import { Image } from 'ui-library/components/Image';
import { ActionList, ActionListItem } from 'ui-library/v1/components/ActionList';
import { ButtonKind } from 'ui-library/v1/components/Button';
import { IconButton, IconButtonVariant } from 'ui-library/v1/components/IconButton';
import { Popover, PopoverContent, PopoverTrigger } from 'ui-library/v1/components/Popover';
import styles from './AccountMenu.module.scss';
import { AccountMenuProps } from './AccountMenuProps';
import AvatarPNG from './assets/pic.png';

export const AccountMenu: FunctionComponent<AccountMenuProps> = (props) => {
  const { className } = props;
  const auth = useAuth();
  const [isOpen, setOpen] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { handleSignOut } = useSignOut();
  const dispatch = useAppDispatch();
  const partition = useAppSelector((state) => state.toggleMuseum.partition);

  const handleCreateTheme = () => {
    dispatch(rootFolderCreationActions.open());
    dispatch(rootFolderCreationActions.selectPartition(partition));
  };

  const actions: Array<ActionListItem> = [
    {
      label: t('header.user.actions.downloads'),
      onClick: () => navigate(ROUTE_PATHS.DOWNLOADS.ROOT),
      hasAccess: () => fileAccessRuleService.canView(),
      hasDivider: true,
    },
    {
      onClick: () => navigate(ROUTE_PATHS.FAVORITES.ROOT),
      label: t('header.user.actions.favorites'),
      hasAccess: () => favoritesAccessRuleService.canView(),
    },
    {
      onClick: handleCreateTheme,
      label: t('header.user.actions.addTheme'),
      hasAccess: () => folderAccessRuleService.canCreate(),
    },
    {
      label: t('header.user.actions.users'),
      onClick: () => navigate(ROUTE_PATHS.USERS),
      hasAccess: () => usersAccessRuleService.canView(),
    },
    {
      label: t('header.user.actions.authors'),
      onClick: () => navigate(ROUTE_PATHS.AUTHORS),
      hasAccess: () => usersAccessRuleService.canEditReferences(),
      hasDivider: true,
    },
    {
      label: t('header.user.actions.signOut'),
      onClick: handleSignOut,
      endAdornment: <Icon name='exit' />,
      hasDivider: true,
    },
  ];

  return (
    <Popover behavior='click' placement={'top-end'} offset={16} open={isOpen} onOpenChange={setOpen}>
      <PopoverTrigger>
        <IconButton
          className={cn(styles.button, className)}
          as={ButtonKind.BUTTON}
          variant={IconButtonVariant.TRANSPARENT}
        >
          {isOpen ? <Icon name='cross' /> : <Icon name='menu' />}
        </IconButton>
      </PopoverTrigger>
      <PopoverContent portal>
        {({ close }) => (
          <div className={styles.content}>
            <Image className={styles.avatar} alt={t('header.user.avatar')} src={AvatarPNG} loading='eager' />
            <span className={styles.username}>
              {NameFormatter.getFullNameAbbreviation(auth.user?.name, auth.user?.surname)}
            </span>
            <span className={styles.email}>{auth.user?.email}</span>
            <ActionList actions={actions} onClose={close} />
          </div>
        )}
      </PopoverContent>
    </Popover>
  );
};
