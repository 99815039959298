import { authApi } from 'features/auth';
import { changeOrderApi } from 'features/changeOrder';
import { favoritesApi } from 'features/favorites';
import { fileApi } from 'features/file';
import { foldersApi } from 'features/folders';
import { imagesApi } from 'features/images';
import { layoutsApi } from 'features/layouts';
import { referenceBookApi } from 'features/referenceBook';
import { searchApi } from 'features/search';
import { tagApi } from 'features/tags';
import { usersApi } from 'features/users/usersApi';

const API_MIDDLEWARES = [
  authApi.middleware,
  fileApi.middleware,
  usersApi.middleware,
  foldersApi.middleware,
  layoutsApi.middleware,
  imagesApi.middleware,
  searchApi.middleware,
  referenceBookApi.middleware,
  favoritesApi.middleware,
  tagApi.middleware,
  changeOrderApi.middleware,
];

export const MIDDLEWARES = [...API_MIDDLEWARES];
