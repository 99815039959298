import cn from 'classnames';
import { FunctionComponent } from 'react';
import { useLanguageRelevantData } from 'infrastructure/i18n';
import { FavoriteGroupButtonGroup } from '../FavoriteGroupButtonGroup/FavoriteGroupButtonGroup';
import styles from './FavoriteGroupHeaderInfo.module.scss';
import { FavoriteGroupHeaderInfoProps } from './FavoriteGroupHeaderInfoProps';

export const FavoriteGroupHeaderInfo: FunctionComponent<FavoriteGroupHeaderInfoProps> = (props) => {
  const { matchProp } = useLanguageRelevantData();
  const { group, className } = props;

  return (
    <div className={cn(styles.root, className)}>
      <h1 className={styles.title}>{matchProp(group?.captionRu, group?.captionEn)}</h1>
      <FavoriteGroupButtonGroup group={group} />
    </div>
  );
};
