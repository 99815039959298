import cn from 'classnames';
import { FunctionComponent } from 'react';
import styles from './TextCellRenderer.module.scss';
import { TextCellRendererProps } from './TextCellRendererProps';

export const TextCellRenderer: FunctionComponent<TextCellRendererProps> = (props) => {
  const { value, icon, iconClassName, textClassName } = props;

  return (
    <div className={styles.root}>
      {icon && <span className={cn(styles.icon, iconClassName)}>{icon}</span>}
      <span className={cn(styles.text, textClassName)}>{value}</span>
    </div>
  );
};
