import { Dialog } from '@headlessui/react';
import cn from 'classnames';
import { FunctionComponent } from 'react';
import styles from './ModalTitle.module.scss';

export const ModalTitle: FunctionComponent<Parameters<typeof Dialog['Title']>[0]> = (props) => {
  const { className, ...restProps } = props;

  return <Dialog.Title as='h3' className={cn(styles.root, className)} {...restProps} />;
};
