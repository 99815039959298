import { useTranslation } from 'react-i18next';
import { Icon } from 'ui-library/components/Icon';
import { Button, ButtonKind, ButtonVariant } from 'ui-library/v1/components/Button';
import { ZeroScreenWrapper } from 'ui-library/v1/components/ZeroScreen';
import styles from './ErrorLoadingRoot.module.scss';
import { ErrorLoadingRootProps } from './ErrorLoadingRootProps';

export const ErrorLoadingRoot = (props: ErrorLoadingRootProps) => {
  const { className, theme = 'dark', onReload } = props;
  const { t } = useTranslation();

  return (
    <ZeroScreenWrapper
      className={className}
      theme={theme}
      renderIcon={(props) => <Icon name='imagesErrorIllustration' size='none' {...props} className={styles.icon} />}
      renderButton={(props) => (
        <Button
          as={ButtonKind.BUTTON}
          variant={ButtonVariant.SECONDARY}
          onClick={onReload}
          size='small'
          theme={theme === 'dark' ? 'default' : 'secondary'}
          {...props}
        >
          {t('actions.refresh')}
        </Button>
      )}
      message={t('folders.errorLoadingRoot.message')}
    />
  );
};
