import { createApi } from '@reduxjs/toolkit/query/react';
import { listQuery } from 'features/list';
import { fetchBaseAuthQuery, HTTP_METHODS, ListApiResponse, URL_FACTORY } from 'infrastructure/network';
import {
  FetchReferenceBookQueryData,
  GetReferenceBookListRequestData,
  ReferenceBook,
  ReferenceBookCreateRequestDto,
  ReferenceBookDeleteRequestDto,
  ReferenceBookEditRequestDto,
  SearchReferenceBookRequestData,
} from './referenceBookEntity';

export const referenceBookApi = createApi({
  reducerPath: 'referenceBookApi',
  tagTypes: ['ReferenceBooks'],
  baseQuery: fetchBaseAuthQuery(),
  endpoints: (builder) => ({
    fetchReferenceBook: builder.query<ListApiResponse<ReferenceBook>, FetchReferenceBookQueryData>({
      query: (params) =>
        listQuery({
          url: URL_FACTORY.REFERENCE_BOOKS.ROOT,
          params,
        }),
    }),
    searchReferenceBook: builder.query<ListApiResponse<ReferenceBook>, SearchReferenceBookRequestData>({
      query: (params) => {
        const { query, language, type, ...restParams } = params;

        const list = listQuery({
          url: URL_FACTORY.REFERENCE_BOOKS.SEARCH(),
          params: restParams,
        });

        return {
          ...list,
          method: HTTP_METHODS.POST,
          body: {
            query,
            language,
            type,
          },
        };
      },
      providesTags: (result, error, args) => [{ type: 'ReferenceBooks', id: args.type }],
    }),
    getReferenceBookList: builder.query<Array<ReferenceBook>, GetReferenceBookListRequestData>({
      query: (params) => {
        const { IDs } = params;

        return {
          url: URL_FACTORY.REFERENCE_BOOKS.LIST(),
          method: HTTP_METHODS.POST,
          body: {
            IDs,
          },
        };
      },
    }),
    createReferenceBook: builder.mutation<ReferenceBook, ReferenceBookCreateRequestDto>({
      query: (data) => ({
        url: URL_FACTORY.REFERENCE_BOOKS.ROOT,
        method: HTTP_METHODS.POST,
        body: data,
      }),
      invalidatesTags: (arg, error, { type }) => [{ type: 'ReferenceBooks', id: type }],
    }),
    editReferenceBook: builder.mutation<ReferenceBook, ReferenceBookEditRequestDto>({
      query: ({ id, ...data }) => ({
        url: URL_FACTORY.REFERENCE_BOOKS.DETAIL(id),
        method: HTTP_METHODS.PATCH,
        body: data,
      }),
      invalidatesTags: (arg, error, { type }) => [{ type: 'ReferenceBooks', id: type }],
    }),
    deleteReferenceDelete: builder.mutation<undefined, ReferenceBookDeleteRequestDto>({
      query: ({ id }) => ({
        url: URL_FACTORY.REFERENCE_BOOKS.DETAIL(id),
        method: HTTP_METHODS.DELETE,
      }),
    }),
  }),
});

export const {
  useFetchReferenceBookQuery,
  useSearchReferenceBookQuery,
  useLazyGetReferenceBookListQuery,
  useCreateReferenceBookMutation,
  useEditReferenceBookMutation,
  useDeleteReferenceDeleteMutation,
} = referenceBookApi;
