import cn from 'classnames';
import React, { FunctionComponent } from 'react';
import { ButtonKind } from 'ui-library/components/Button';
import { Icon } from 'ui-library/components/Icon';
import { IconButton, IconButtonVariant } from 'ui-library/components/IconButton';
import styles from './NavigationArrowButton.module.scss';
import { NavigationArrowButtonProps } from './NavigationArrowButtonProps';

export const NavigationArrowButton: FunctionComponent<NavigationArrowButtonProps> = (props) => {
  const { direction, className, onClick, disabled } = props;

  return (
    <div className={cn(styles.root, className)}>
      <IconButton
        disabled={disabled}
        className={cn(styles.button)}
        as={ButtonKind.BUTTON}
        variant={IconButtonVariant.TRANSPARENT}
        onClick={onClick}
      >
        <Icon className={styles.icon} name={direction === 'left' ? 'chevronThinLeft' : 'chevronThinRight'} />
      </IconButton>
    </div>
  );
};
