import { translateKey } from 'infrastructure/i18n';
import { ApiErrorCode, ApiErrorResponse } from 'infrastructure/network';

export const apiErrorMessageMatcher = (error: ApiErrorResponse | undefined) => {
  if (!error) {
    return undefined;
  }

  const messageByCode: Partial<Record<ApiErrorCode, string>> = {
    [ApiErrorCode.CONFLICT]: translateKey('users.errors.emailConflict'),
  };

  return messageByCode[error.code];
};
