import { FunctionComponent } from 'react';
import { activeFileDownloadTaskListActions, useFetchViewDownloadFilesQuery } from 'features/file';
import { FileExcelUploader } from 'features/file/components/FileExcelUploader/FileExcelUploader';
import { calculateOffset } from 'features/pagination';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux';
import { Pagination } from 'ui-library/components/Pagination';
import { FileTaskList } from '../FileTaskList/FileTaskList';
import { FileTaskListEmpty } from '../FileTaskListEmpty/FileTaskListEmpty';
import { FileTaskListLoadingError } from '../FileTaskListLoadingError/FileTaskListLoadingError';
import styles from './FileActiveDownloadsTab.module.scss';

const POLLING_INTERVAL_MS = 25000;

export const FileActiveDownloadsTab: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const { paginationData, sortingInfo } = useAppSelector((state) => state.activeFileDownloadTaskList);
  const { pageSize, currentPage } = paginationData;
  const query = useFetchViewDownloadFilesQuery(
    {
      items: [
        {
          section: 'active',
          offset: calculateOffset(currentPage, pageSize),
          limit: pageSize,
          sortDirection: sortingInfo.sortDirection,
          sortFields: [sortingInfo.sortField],
        },
      ],
    },
    {
      pollingInterval: POLLING_INTERVAL_MS,
    },
  );
  const { isError, refetch, data } = query;

  const fetchData = data?.items[0].active;
  const isEmpty = fetchData?.totalCount === 0;

  const getContent = () => {
    if (isError) {
      return <FileTaskListLoadingError className={styles.zero} onReload={refetch} />;
    }

    if (isEmpty) {
      return <FileTaskListEmpty className={styles.zero} />;
    }

    return <FileTaskList {...query} data={fetchData} />;
  };

  return (
    <div className={styles.root}>
      <FileExcelUploader />
      <div className={styles.listWrap}>{getContent()}</div>
      <div className={styles.paginationWrap}>
        <Pagination
          totalItems={fetchData?.totalCount}
          pageSize={pageSize}
          onPageChanged={(page) => dispatch(activeFileDownloadTaskListActions.pageChanged({ page }))}
          currentPage={currentPage}
          scrollToStartAfterPageChanged
        />
      </div>
    </div>
  );
};
