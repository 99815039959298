import { FunctionComponent } from 'react';
import { useLocation } from 'react-router-dom';
import { favoriteLayoutListActions, useFetchFavoritesQuery } from 'features/favorites';
import { LayoutDetailRouteSearchQueryParams, LayoutLoadingError } from 'features/layouts';
import { calculateOffset } from 'features/pagination';
import { useAppDispatch, useAppSelector } from 'infrastructure/redux';
import { Pagination } from 'ui-library/components/Pagination';
import { LayoutCardList } from '../LayoutCardList/LayoutCardList';
import styles from './LayoutDataView.module.scss';
import { LayoutDataViewProps } from './LayoutDataViewProps';

export const LayoutDataView: FunctionComponent<LayoutDataViewProps> = (props) => {
  const { groupId } = props;
  const dispatch = useAppDispatch();
  const { sortDirection, sortField } = useAppSelector((state) => state.favoriteLayoutList.sortingInfo);
  const { currentPage, pageSize } = useAppSelector((state) => state.favoriteLayoutList.paginationData);

  const query = useFetchFavoritesQuery({
    groupID: groupId,
    items: [
      {
        type: 'layout',
        limit: pageSize,
        sortDirection,
        sortFields: [sortField],
        offset: calculateOffset(currentPage, pageSize),
      },
    ],
  });
  const listData = query.data?.items[0].layout;
  const totalCount = listData?.totalCount;
  const location = useLocation();
  const params: LayoutDetailRouteSearchQueryParams = {
    redirectUrl: location.pathname + location.search,
  };

  return (
    <div className={styles.root}>
      <div className={styles.listWrap}>
        {query.isError ? (
          <LayoutLoadingError onReload={query.refetch} />
        ) : (
          <LayoutCardList {...query} data={listData} groupId={groupId} params={params} />
        )}
      </div>
      <div className={styles.paginationWrap}>
        <Pagination
          totalItems={totalCount}
          pageSize={pageSize}
          onPageChanged={(page) => dispatch(favoriteLayoutListActions.pageChanged({ page }))}
          currentPage={currentPage}
          scrollToStartAfterPageChanged
        />
      </div>
    </div>
  );
};
