import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATHS } from 'infrastructure/routes';
import { Icon } from 'ui-library/components/Icon';
import { Button, ButtonKind, ButtonVariant } from 'ui-library/v1/components/Button';
import { ZeroScreenWrapper } from 'ui-library/v1/components/ZeroScreen';
import styles from './AccessDeniedScreen.module.scss';

export const AccessDeniedScreen: FunctionComponent = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleClick = () => navigate(ROUTE_PATHS.HOME);

  return (
    <div className={styles.root}>
      <ZeroScreenWrapper
        renderIcon={(props) => <Icon name='error403' size='none' {...props} />}
        renderButton={({ className }) => (
          <Button
            className={className}
            as={ButtonKind.BUTTON}
            onClick={handleClick}
            variant={ButtonVariant.SECONDARY}
            size='small'
          >
            {t('actions.goToMain')}
          </Button>
        )}
        title={t('commonPages.accessDenied.title')}
        message={t('commonPages.accessDenied.message')}
      />
    </div>
  );
};
