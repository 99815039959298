import { createApi } from '@reduxjs/toolkit/query/react';
import {
  FetchFolderLayoutsQueryData,
  FetchImageLayoutsQueryData,
  LayoutCreateRequestDto,
  LayoutDeleteRequestDto,
  LayoutEditRequestDto,
  LayoutResponseDto,
} from 'features/layouts';
import { listQuery } from 'features/list';
import { fetchBaseAuthQuery, HTTP_METHODS, ListApiResponse, URL_FACTORY } from 'infrastructure/network';

export const layoutsApi = createApi({
  reducerPath: 'layoutsApi',
  baseQuery: fetchBaseAuthQuery(),
  tagTypes: ['FolderLayouts', 'ImageLayouts', 'Layout'],
  endpoints: (builder) => ({
    fetchFolderLayouts: builder.query<ListApiResponse<LayoutResponseDto>, FetchFolderLayoutsQueryData>({
      query: ({ folderID, ...rest }) =>
        listQuery({
          url: URL_FACTORY.FOLDERS.LINKED_LAYOUTS(folderID),
          params: rest,
        }),
      providesTags: (result, error, { folderID }) =>
        result
          ? [
              'FolderLayouts',
              { type: 'FolderLayouts', id: folderID },
              ...result.content.map(({ id }) => ({ type: 'Layout' as const, id })),
            ]
          : ['FolderLayouts', { type: 'FolderLayouts', id: folderID }],
    }),
    fetchImageLayouts: builder.query<ListApiResponse<LayoutResponseDto>, FetchImageLayoutsQueryData>({
      query: ({ imageID, ...rest }) =>
        listQuery({
          url: URL_FACTORY.IMAGES.LINKED_LAYOUTS(imageID),
          params: rest,
        }),
      providesTags: (result, error, { imageID }) =>
        result
          ? [
              'ImageLayouts',
              { type: 'ImageLayouts', id: imageID },
              ...result.content.map(({ id }) => ({ type: 'Layout' as const, id })),
            ]
          : ['ImageLayouts', { type: 'ImageLayouts', id: imageID }],
    }),
    fetchLayoutDetail: builder.query<LayoutResponseDto, string>({
      query: (layoutID) => URL_FACTORY.LAYOUTS.DETAIL(layoutID),
      providesTags: (result, error, layoutID) => (error ? [] : [{ type: 'Layout', id: layoutID }]),
    }),
    editLayout: builder.mutation<LayoutResponseDto, LayoutEditRequestDto>({
      query: ({ layoutID, ...restData }) => ({
        url: URL_FACTORY.LAYOUTS.DETAIL(layoutID),
        method: HTTP_METHODS.PATCH,
        body: restData,
      }),
      invalidatesTags: (result, error, { layoutID }) => (error ? [] : [{ type: 'Layout', id: layoutID }]),
    }),
    createLayout: builder.mutation<LayoutResponseDto, LayoutCreateRequestDto>({
      query: ({ ...restData }) => ({
        url: URL_FACTORY.LAYOUTS.ROOT,
        method: HTTP_METHODS.POST,
        body: restData,
      }),
      invalidatesTags: (result, error) => (error ? [] : ['FolderLayouts']),
    }),
    deleteLayout: builder.mutation<unknown, LayoutDeleteRequestDto>({
      query: ({ layoutID }) => ({
        url: URL_FACTORY.LAYOUTS.DETAIL(layoutID),
        method: HTTP_METHODS.DELETE,
      }),
      invalidatesTags: (result, error) => (error ? [] : ['FolderLayouts', 'ImageLayouts']),
    }),
  }),
});

export const {
  useFetchFolderLayoutsQuery,
  useFetchImageLayoutsQuery,
  useFetchLayoutDetailQuery,
  useEditLayoutMutation,
  useCreateLayoutMutation,
  useDeleteLayoutMutation,
} = layoutsApi;
