import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getDefaultModalState, getModalReducers, ModalState } from 'features/modal';
import { UserResponseDto } from '../userEntity';

export type UserEditState = ModalState & {
  user?: UserResponseDto;
};

const initialState: UserEditState = {
  ...getDefaultModalState(),
};

const userEditSlice = createSlice({
  name: 'userEdit',
  initialState,
  reducers: {
    ...getModalReducers<UserEditState>((state) => state),
    setUser: (state, action: PayloadAction<UserResponseDto | undefined>) => {
      state.user = action.payload;
    },
  },
});

export const userEditActions = userEditSlice.actions;
export const userEditReducer = userEditSlice.reducer;
