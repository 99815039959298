import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ImageEditTab } from 'features/images/components/ImageEditForm/ImageEditTab';
import { getDefaultModalState, getModalReducers, ModalState } from 'features/modal';

export type ImageCreateState = ModalState & {
  parentFolderId?: string;
  selectedTab: ImageEditTab;
};

const initialState: ImageCreateState = {
  ...getDefaultModalState(),
  parentFolderId: undefined,
  selectedTab: ImageEditTab.INFORMATION,
};

const imageCreateSlice = createSlice({
  name: 'imageCreate',
  initialState,
  reducers: {
    ...getModalReducers<ImageCreateState>((state) => state),
    selectParentFolder: (state, action: PayloadAction<string | undefined>) => {
      state.parentFolderId = action.payload;
    },
    selectTab: (state, action: PayloadAction<ImageEditTab>) => {
      state.selectedTab = action.payload;
    },
  },
});

export const imageCreateActions = imageCreateSlice.actions;
export const imageCreateReducer = imageCreateSlice.reducer;
