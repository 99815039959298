import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FolderPartition } from 'features/folders/folderEntity';
import { getDefaultModalState, getModalReducers, ModalState } from 'features/modal';

export type FolderCreateState = ModalState & {
  parentFolderId?: string;
  selectedPartition?: FolderPartition;
};

const initialState: FolderCreateState = {
  ...getDefaultModalState(),
  parentFolderId: undefined,
  selectedPartition: undefined,
};

const folderCreateSlice = createSlice({
  name: 'folderCreate',
  initialState,
  reducers: {
    ...getModalReducers<FolderCreateState>((state) => state),
    selectParentFolder: (state, action: PayloadAction<string | undefined>) => {
      state.parentFolderId = action.payload;
    },
    selectPartition: (state, action: PayloadAction<FolderPartition | undefined>) => {
      state.selectedPartition = action.payload;
    },
  },
});

export const folderCreateActions = folderCreateSlice.actions;
export const folderCreateReducer = folderCreateSlice.reducer;
