import { FunctionComponent } from 'react';
import { FileType, useUploadFile } from 'features/file';
import {
  FolderEditForm,
  FolderEditFormFields,
  FolderEditRequestDto,
  foldersApi,
  useEditFolderMutation,
} from 'features/folders';
import { layoutsApi } from 'features/layouts';
import { useAppDispatch } from 'infrastructure/redux';
import { mapFolderDtoToFolderFormFields } from '../../folderMapper';
import { FolderEditProps } from './FolderEditProps';

export const FolderEdit: FunctionComponent<FolderEditProps> = (props) => {
  const { data, selectedFolderId, selectedParentId, isRoot, onClose, renderActions } = props;
  const dispatch = useAppDispatch();

  const [editFolderMutation] = useEditFolderMutation();
  const uploadFile = useUploadFile(FileType.FOLDER);

  const onSubmit = async (values: FolderEditFormFields) => {
    const { fileBox } = values;

    let fileID;
    if (fileBox.file) {
      fileID = await uploadFile(fileBox.file);
    }

    const dto: FolderEditRequestDto = {
      fileID,
      tags: values.tags?.map((tag) => tag.id),
      tagsExtend: values.tagsExtend,
      captionRu: values.captionRu,
      captionEn: values.captionEn,
      descriptionRu: values.descriptionRu,
      descriptionEn: values.descriptionEn,
      status: values.status,
      materialsKinds: values.materialsKinds,
      folderID: selectedFolderId as string,
      parentID: selectedParentId,
      isRoot,
    };

    await editFolderMutation(dto).unwrap();

    if (isRoot) {
      dispatch(foldersApi.util.invalidateTags(['RootFolders']));
    } else {
      dispatch(
        foldersApi.util.invalidateTags([
          {
            type: 'FolderChildren',
            id: data?.parentID,
          },
        ]),
      );
    }

    // Инвалидируем макеты и папки, т.к. мы могли пронаследовать теги по вложенным в папку сущностям
    dispatch(layoutsApi.util.invalidateTags(['Layout']));
    dispatch(foldersApi.util.invalidateTags(['Folder']));

    onClose(dto);
  };

  const defaultValues = mapFolderDtoToFolderFormFields(data);

  return (
    <>
      {data?.id === selectedFolderId && (
        <FolderEditForm defaultValues={defaultValues} onSubmit={onSubmit} renderActions={renderActions} />
      )}
    </>
  );
};
