import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum FolderMuseumDetailViewType {
  IMAGES,
  FOLDERS,
}

export type FolderMuseumDetailState = {
  currentFolderId: string | undefined;
  galleryScrollOffsetY: number;
};

const initialState: FolderMuseumDetailState = {
  currentFolderId: undefined,
  galleryScrollOffsetY: 0,
};

const folderMuseumDetailSlice = createSlice({
  name: 'folderMuseumDetail',
  initialState,
  reducers: {
    setCurrentFolderId: (state, action: PayloadAction<string | undefined>) => {
      state.currentFolderId = action.payload;
    },
    setGalleryScrollOffsetY: (state, action: PayloadAction<number>) => {
      state.galleryScrollOffsetY = action.payload;
    },
  },
});

export const folderMuseumDetailActions = folderMuseumDetailSlice.actions;
export const folderMuseumDetailReducer = folderMuseumDetailSlice.reducer;
